<template>
  <b-form-input
    size="sm"
    v-model="value"
    type="number"
    step="any"
    @change="$emit('updateRow', rowId, payload)"
    :required="required"
  ></b-form-input>
</template>

<script>
export default {
  props: ["rowId", "field", "cellData", "required"],
  data() {
    return {
      value: null,
    };
  },
  created() {
    this.value = this.cellData;
  },
  computed: {
    payload() {
      let payload = {};
      payload[this.field] = this.value ? this.value : null;
      return payload;
    },
  },
  watch: {
    cellData() {
      this.value = this.cellData;
    },
  },
};
</script>

<style lang="scss" scoped></style>