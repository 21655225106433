<template>
  <div>
    <Treatment
      :endpoint="InputStockApi.reportInputsStockState"
      :async="false"
      :title="$t('inputStock.reports.inputstock_state')"
      :startButton="$t('commons.exports_reports.export_button')"
      :finishButton="$t('commons.exports_reports.finish')"
      :exportAllowedFormats="['xlsx', 'json']"
      :isDirectDownload="true"
      :endpointPath="`/reports/inputsStock/state/`"
      :onBack="onBack"
      :onClose="onClose"
    >
      <TC_Farm />
      <TC_Date :titleOption="this.titleOption" :returnedAttribute="this.returnedAttribute"/>
      <TC_Farm_NoTemplate />
      <TC_FileFormat />
    </Treatment>
  </div>
</template>

<script>
import Treatment from "@/components/commons/treatments/TreatmentExecution";
import TC_Farm from "@/components/commons/treatments/TC_Farm";
import TC_FileFormat from "@/components/commons/treatments/TC_FileFormat";
import TC_Date from "@/components/commons/treatments/TC_Date";

import InputStockApi from "@/api/inputstock.api";

export default {
  data() {
    return {
      InputStockApi: InputStockApi,
      returnedAttribute: 'date',
      titleOption: "inputStock.reports.value_date"
    };
  },
  methods: {
    onBack() {
      this.$router.push({ name: "inputStock.reports" });
    },
    onClose() {
      this.$router.push({ name: "inputStock.reports" });
    },
  },
  components: { Treatment, TC_Farm, TC_FileFormat, TC_Date },
};
</script>
