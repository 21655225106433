import Gateway, { Request } from "./base";

export default class FertilizationPlanApi2 {
  /**
   * Get fertilizationPlan 
   */
  static getAllFertilizationPlan(params) {
    return Gateway.get(`/api/fertilizationPlan/`, params);
  }
  /**
   * Get fertilizationPlan by id
   *
   * @param id
   *
   */
  static getfertilizationPlan(id) {
    return Gateway.get(`/api/fertilizationPlan/${id}/`);
  }

  /**
   * Get FertilizationPlanList by id
   *
   * @param id
   *
   */
  static getfertilizationPlanList(id) {
    return Gateway.get(`/api/fertilizationPlan/${id}/formlists/`);
  }

  /**
   * Get FertilizationPlanOptions by id
   *
   * @param id
   *
   */
  static getfertilizationPlanOptions(id) {
    return Gateway.get(`/api/fertilizationPlan/${id}/formoptions/`);
  }

   /**
   * Get helpText
   */
   static getHelpText() {
    return Gateway.get("/api/fertilizationPlan/helptexts/");
  }

  /**
   * Get filters
   */
  static getFilters(query, params) {
    return Gateway.get("/api/fertilizationPlan/filtre/", { attrib: query, ...params });
  }




  /**
   * Put FertilizationPlanOptions by id
   *
   * @param id
   * 
   *
   */
  static updatefertilizationPlan(id, body){
    return Gateway.put(`/api/fertilizationPlan/${id}/`, body)
  }

  static getDuplicableFertilizationPlan(params) {
    return Gateway.get(`/api/fertilizationPlan/`, params);
  }

  static duplicateFertilizationPlan(id, body){
    return Gateway.put(`/api/fertilizationPlan/${id}/duplicate/`, body)
  }

  /**
   * Get fertilizers put on the cropfield since the assessment opening date
   * @param {int} id
   * @returns
   */
  static getFertilizersSinceAssessmentOpeningDate(id) {
    return Gateway.get(`/api/fertilizationPlan/${id}/fertilizersSinceAssessmentOpeningDate/`)
  }

  static recalculateFertilizationPlan(id){
    return Gateway.get(`/api/fertilizationPlan/${id}/recalculate/`)
  }

}
