import IFTChart from "./AnalysisView.vue";
import AnalysisOptions from "./AnalysisOptions.vue";
import { config } from "./config";

export default [
  {
    component: IFTChart,
		props: config,
    path: "/analysis",
    name: "analysis",
    children: [
      {
				components: {
					options: AnalysisOptions,
				},
				path: ":analysis_id",
				name: "analysis.options"
			}
    ]
  },
];
