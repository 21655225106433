import ProductTypeApi from "@/api/productType.api";

export const SET_PRODUCT_TYPE = "SET PRODUCT TYPE";
export const ONE_UNITS = "GET UNITS";
export const GET_UNITS = "GET UNITS ALREADY LOADED BY ID";
export const CLEAN_UNITS = "CLEAN CURRENT UNITS";
export const CLEAN_PRODUCT_TYPE = "CLEAN CURRENT PRODUCT TYPE";
export const PRODUCTTYPE_LOADING = "LOAD PRODUCT TYPE";
export const GET_PRODUCT_TYPE_BY_FAMILY = "GET_PRODUCT_TYPE_BY_FAMILY";
export const GET_INPUTFORM_BY_PRODUCT_TYPE = "GET INPUTFORM BY PRODUCT TYPE";
export const CLEAN_INPUTFORM_BY_PRODUCT_TYPE = "CLEAN INPUTFORM BY PRODUCT TYPE";
export const CLEAN_PRODUCT_TYPE_BY_FAMILY = "CLEAN PRODUCT TYPE BY FAMILY";

export default {
  state: {
    currentProductType: "",
    productTypesByFamily: [],
    inputFormByPtype: {},
    units: [],
    theUnitSelected: "",
    allUnits: [],
    productType_loaded: true,
  },
  getters: {
    currentProductType: state => state.currentProductType,
    units: state => state.units,
    allUnits: state => state.allUnits,
    theUnitSelected: state => state.theUnitSelected,
    productTypesByFamily: state => state.productTypesByFamily,
    inputFormByPtype: state => state.inputFormByPtype,
  },
  mutations: {
    [GET_UNITS]: (state, payload) => {
      let productTypeId = payload.id;

      let units = state.allUnits.filter(function(elem) {
        if (elem.id == productTypeId) return elem;
      });

      state.units = units[0];
    },

    [ONE_UNITS]: (state, payload) => {
      state.units = payload.units;
      state.allUnits.push(payload.units);
    },

    [SET_PRODUCT_TYPE]: (state, payload) => {
      state.currentProductType = payload.id;
    },

    [GET_PRODUCT_TYPE_BY_FAMILY]: (state, payload) => {
      state.productTypesByFamily = payload;
    },

    [CLEAN_PRODUCT_TYPE_BY_FAMILY]: state => {
      state.productTypesByFamily = [];
    },

    [GET_INPUTFORM_BY_PRODUCT_TYPE]: (state, payload) => {
      state.inputFormByPtype = payload;
    },

    [CLEAN_INPUTFORM_BY_PRODUCT_TYPE]: state => {
      state.inputFormByPtype = {};
    },

    [CLEAN_UNITS]: state => {
      state.units = [];
    },

    [CLEAN_PRODUCT_TYPE]: state => {
      state.currentProductType = null;
    },

    [PRODUCTTYPE_LOADING]: (state, status) => {
      state.productType_loaded = !status;
    },
  },
  actions: {
    async getUnitsProductType({ commit }, payload) {
      commit(PRODUCTTYPE_LOADING, true);
      const response = await ProductTypeApi.getUnitsProductType(payload.id);
      commit(ONE_UNITS, { units: response.data });
      commit(PRODUCTTYPE_LOADING, false);
      return response.data;
    },

    async getProductTypesByFamily({ commit }, payload) {
      commit(PRODUCTTYPE_LOADING, true);
      const response = await ProductTypeApi.getProductTypesByFamily(payload.idProdFamily);
      commit(GET_PRODUCT_TYPE_BY_FAMILY, response.data);
      commit(PRODUCTTYPE_LOADING, false);
      return response.data;
    },

    async getinputFormByProductType({ commit }, payload) {
      commit(PRODUCTTYPE_LOADING, true);
      const response = await ProductTypeApi.getinputFormByProductType(payload.id);
      commit(GET_INPUTFORM_BY_PRODUCT_TYPE, response.data);
      commit(PRODUCTTYPE_LOADING, false);
      return response.data;
    },
  },
};
