import NewCampaign from "./NewCampaign.vue";
import NewCampaignInitialization from "./NewCampaignInitialization.vue";

export default [
  {
    component: NewCampaign,
    path: "/campaign",
    children: [
      {
        components: {
          default: NewCampaignInitialization,
        },
        path: "init",
        name: "campaign.init",
      },
    ],
  },
];
