<template>
  <div class="home-widget">
    <b-container>
      <!-- Bouton pour commuter entre le graphe des 1eres Cultures et celui des 2ndes Cultures -->
      <b-row class="mt-2" v-if="secondGraph && secondGraph.values && secondGraph.values.length > 0">
        <b-col>
          <form>
            <input type="radio" id="firstCrops" name="choice" class="toggle toggle-left" value="firstCrops" checked @click="changeGraph(1)" />
            <label for="firstCrops" class="btn">{{ $t("home.crops.firstCrops")}}</label>

            <input type="radio" id="secondCrops" name="choice" class="toggle toggle-right" value="secondCrops" @click="changeGraph(2)"/>
            <label for="secondCrops" class="btn">{{ $t("home.crops.secondCrops")}}</label>
          </form>
        </b-col>
      </b-row>
      <!-- Graphes -->
      <b-row>
        <b-col>
          <CropsGraphWidget :cropOrder="cropOrder"/>
        </b-col>
        <b-col>
          <SeedingProgressGraphWidget :cropOrder="cropOrder"/>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CropsGraphWidget from "@/components/home/CropsGraphWidget";
import SeedingProgressGraphWidget from "@/components/home/SeedingProgressGraphWidget";

export default {
  components: {
    CropsGraphWidget,
    SeedingProgressGraphWidget,
  },

  data() {
    return {
      cropOrder: 1
    }
  },

  computed: {
    ...mapGetters({
      secondGraph: 'home/secondCropsGraphData',
    }),
  },

  methods: {
    changeGraph(cropOrder) {
      this.cropOrder = cropOrder;
    }
  }
};
</script>

<style lang="scss" scoped>
.btn {
  border: 3px solid $secondary-color;
  display: inline-block;
  padding: 5px;
  position: relative;
  text-align: center;
  font-weight: bold;
  transition: background 600ms ease, color 600ms ease;
}

input[type="radio"].toggle {
  display: none;
  & + label {
    cursor: pointer;
    min-width: 60px;
    &:hover {
      background: none;
      color: $secondary-color;
    }
    &:after {
      background: $secondary-color;
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
      transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
    }
  }
  &.toggle-left + label {
    padding-left: 10px;
    padding-right: 10px;
    border-right: 0;
    &:after {
      left: 100%;
    }
  }
  &.toggle-right + label {
    padding-left: 7px;
    padding-right: 7px;
    margin-left: -4px;
    &:after {
      left: -100%;
    }
  }
  &:checked + label {
    cursor: default;
    color: #fff;
    transition: color 200ms;
    &:after {
      left: 0;
    }
  }
}
</style>
