<template>
  <div>
    <div class="custom-control custom-switch align-available">
      <input
        :checked="cellData"
        type="checkbox"
        class="custom-control-input"
        :id="rowId + 'available'"
        @click="patchAvailableInput(rowId, !cellData)"
      />
      <label class="custom-control-label" :for="rowId + 'available'"></label>
    </div>
  </div>
</template>

<script>
export default {
  props: ["rowId", "rowData"],
  computed: {
    cellData() {
      return this.rowData.available;
    },
  },
  methods: {
    /**
     * Description: Patch the available attribut of the inputStock and
     * update inputStocks list.
     * @param {number}       inputId inputStock ID.
     * @param {boolean}      status Available attribut value of the inputStock.
     */
    async patchAvailableInput(inputId, status) {
      await this.$store.dispatch("inputStock/updateEntry", {
        id: inputId,
        data: { available: status },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
