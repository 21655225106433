import Gateway from "./base";

export default class CropTypesApi {
  /**
   * Get All cropTypes
   */
  static getCropTypes() {
    return Gateway.get("/refapi/cropTypes/");
  }

  /**
   * Get one croptype by id
   * @param id
   */
  static getOneCropType(id) {
    return Gateway.get(`/refapi/cropTypes/${id}/`);
  }

}
