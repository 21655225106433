import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";

import auth from "./modules/auth";
import campaign from "./modules/campaign";
import farm from "./modules/farm";
import pageTile from "./modules/page-tile";
import user from "./modules/user";
// import culturalprocess from "./modules/culturalprocess";
import island from "./modules/island";
import fertilizationPlanInputs from "./modules/fertilizationPlanInputs";
import stockStores from "./modules/stockStores";
import operators from "./modules/operator";

const debug = process.env.NODE_ENV !== "production";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth,
    pageTile,
    campaign,
    farm,
    user,
    // culturalprocess,
    island,
    fertilizationPlanInputs,
    stockStores,
    operators
  },
  strict: true,
  //plugins: debug ? [createLogger({})] : [],
});

export default store;
