<template>
  <div>
    <!-- Treatment Component : CheckedCropfields -->
    <!-- Implicit component : no template but add to form the list of the checked cropfields -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import TreatmentFormPartMixin from "@/components/commons/treatments/TreatmentFormPartMixin";
import { TreatmentFormPart } from "@/models/treatments/Treatment.js";

export default {
  mixins: [TreatmentFormPartMixin],
  methods: {
    validateForm() {
      this.isValid = true;

      return new TreatmentFormPart("cropfields", this.checkedCropfieldsForPayload);
    },
  },
  computed: {
    ...mapGetters({
      entriesChecked: "cropfield/entriesChecked",
    }),
    // format the checked cropfields for the payload
    checkedCropfieldsForPayload() {
      return this.entriesChecked.filter((e) => e != undefined).join(",");
    },
  },
};
</script>

<style></style>
