import ProductsApi from "@/api/products.api";

export const ONE_PRODUCT = "GET PRODUCT BY ID";
export const SET_READ_ONLY = "SET READ ONLY";
export const GET_PRODUCT = "GET PRODUCT ALREADY LOADED BY ID";
export const CLEAN_PRODUCT = "CLEAN CURRENT PRODUCT";
export const PRODUCT_LOADING = "LOAD PRODUCT";
export const GET_PRODUCT_INFO = "GET PRODUCT INFO";
export const GET_PRODUCTS_BY_CROP_TYPE = "GET PRODUCTS BY CROP TYPE"
export const CLEAN_PRODUCT_INFO = "CLEAN PRODUCT INFO";

export default {
  state: {
    allProducts: [],
    currentProduct: {},
    readOnly: false,
    productInfo: null,
    product_loaded: true,
    product_id: null
  },
  getters: {
    currentProduct: state => state.productInfo,
    allProducts: state => state.allProducts,
    readOnly: state => state.readOnly,
    productInfo: state => state.productInfo,
    productLoaded: state => state.product_loaded,
    product_id: state => state.product_id,
  },
  mutations: {
    [GET_PRODUCT]: (state, payload) => {
      let productId = payload.id;

      let product = state.allProducts.filter(function(elem) {
        if (elem.id == productId) return elem;
      });

      state.currentProduct = product[0];
    },

    [GET_PRODUCT_INFO]: (state, payload) => {
      state.productInfo = payload.product;
    },

    [GET_PRODUCTS_BY_CROP_TYPE]: (state, payload) => {
      if(payload.products.length) {
        state.allProducts = payload.products;
      } else {
        state.allProducts = []
      }
    },

    [ONE_PRODUCT]: (state, payload) => {
      state.currentProduct = payload.product;
    },

    [CLEAN_PRODUCT]: state => {
      state.currentProduct = {};
    },

    [CLEAN_PRODUCT_INFO]: state => {
      state.productInfo = null;
    },

    [SET_READ_ONLY]: (state, payload) => {
      state.readOnly = payload.value;
    },

    [PRODUCT_LOADING]: (state, status) => {
      state.product_loaded = !status;
    },

    updateProductId: (state, data) => {
      state.product_id = data.product_id;
    },

    resetProductInfo: (state) => {
      state.productInfo = null
    }
  },
  actions: {
    async getOneProduct({ commit }, payload) {
      //commit(PRODUCT_LOADING, true);
      const response = await ProductsApi.getProduct(payload.id);
      commit(ONE_PRODUCT, { product: response.data });
      //commit(PRODUCT_LOADING, false);
      return response.data;
    },

    async getProductInfo({ commit }, payload) {
      //commit(PRODUCT_LOADING, true);
      const response = await ProductsApi.getProduct(payload.id);
      commit(GET_PRODUCT_INFO, { product: response.data });
      //commit(PRODUCT_LOADING, false);
      return response.data;
    },

    async getProductsByCropType({ commit }, payload) {
      commit(PRODUCT_LOADING, true);
      const response = await ProductsApi.getProductsByCropType(payload);
      commit(GET_PRODUCTS_BY_CROP_TYPE, { products: response.data });
      commit(PRODUCT_LOADING, false);
      return response.data;
    },
  },
};
