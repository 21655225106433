<template>
  <div class="d-flex">
    <ActivityImage
      :activityIcon="activityIcon"
      :activityBgColor="activityBgColor"
      :activityStrokeColor="activityStrokeColor"
    />
    <div class="d-flex flex-column justify-content-center">
      <div class="position-relative">
        {{ activityType }}
        <span class="tracker-icon">
          <img
            v-if="showTrackerIcon"
            v-b-tooltip.hover
            :title="$t('activities.list.created_by_tracker')"
            src="@/assets/images/activity_tracker_on.svg"/>
            <!--<img
              v-else
            src="@/assets/images/activity_tracker_off.svg"/>-->
          <img
            v-if="showFeatherIcon"
            v-b-tooltip.hover
            :title="$t('activities.list.note_taken_in_activity')"
            src="@/assets/images/activity_note.svg"/>
          <img
            v-if="showAlertIcon"
            v-b-tooltip.hover
            class="pt-1 pl-1"
            :title="$t('activities.list.alert_on_activity')"
            src="@/assets/images/activity_alert.svg"/>
        </span>
      </div>
      <div class="d-block" style="font-weight: normal; font-size: 0.7rem; font-style: italic;">
        {{ firstInputs(3) }}
      </div>
      <span class="d-inline-block font-12" v-if="inputStocks && inputStocks.length > 3">
        <button
          type="button"
          class="badge badge-pill badge-secondary"
          data-toggle="tooltip"
          data-html="true"
          title="<em>Tooltip</em> <u>with</u> <b>HTML</b>"
        >
          <span>+{{ inputStocks.length - 3 }}</span>
        </button>
      </span>
    </div>
  </div>
</template>

<script>
import ActivityImage from "@/components/activity/list/ActivityImage";

const trackerRefs = ["RTB", "TRK"];

export default {
  props: ["rowId", "rowData"],
  computed: {
    activityIcon() {
      return this.rowData.activityType_iconPath;
    },
    activityBgColor() {
      return this.rowData.activityType_iconBGColor;
    },
    activityStrokeColor() {
      return this.rowData.activityType_iconStrokeColor;
    },
    activityType() {
      return this.rowData.activityType_designation;
    },
    inputStocks() {
      return this.rowData.inputs;
    },
    showTrackerIcon() {
      return trackerRefs.includes(this.rowData.createdBy);
    },
    showFeatherIcon() {
      return this.rowData.notes;
    },
    showAlertIcon() {
      return this.rowData.alerts;
    }
  },
  components: {
    ActivityImage,
  },
  methods: {
    firstInputs(n) {
      if (!this.inputStocks) return "";
      return this.inputStocks
        .slice(0, n)
        .map(i => i.inputsStock_name)
        .join(", ");
    },
  },
};
</script>

<style lang="scss" scoped>
.tracker-icon img {
  width: 18px;
  margin-right: 3px;
  margin-top: -20px
}

.feather-icon img {
  width: 18px;
}

.feather-icon {
  position: relative;
  top: -26px;
  right: -80px;
}
</style>
