<template>
  <div>
    <!-- close button -->
    <div class="details-buttons">
      <closeButton :on-click="onBack" />
    </div>

    <div>
      <b-container class="p-0 mb-4">
        <b-row no-gutters>
          <b-col cols="auto" class="pr-3">
            <h3 class="title-form">
              {{ $t("cropfields.reports.title") }}
            </h3>
          </b-col>
        </b-row>
      </b-container>

      <b-container class="mt-5 px-5">
        <b-row>
          <b-col cols="12" class="mb-3" v-if="$userCan('cropfields.reports.carnet')">
            <router-link
              :to="{ name: isOnMap ? 'cropfield.map.reports.plain_report' : 'cropfield.reports.plain_report'}"
              class="btn button-secondary btn-text-small text-center py-3"
            >
              {{ $t("cropfields.reports.plain_report") }}<br />
              <span v-if="areEntriesChecked">({{ $t("cropfields.reports.selected_cropfields") }})</span>
              <span v-else>({{ $t("cropfields.reports.all_cropfields") }})</span>
            </router-link>
          </b-col>
          <b-col cols="12" class="mb-3" v-if="$userCan('cropfields.reports.fertilization')">
            <button class="btn button-secondary btn-text-small text-center py-3" @click="generateReport('fertilizationplanpdf')">
              {{ $t("cropfields.reports.fertilization_report") }}<br />

              <span v-if="areEntriesChecked">({{ $t("cropfields.reports.selected_cropfields") }})</span>
              <span v-else> ({{ $t("cropfields.reports.all_cropfields") }})</span>
            </button>
          </b-col>
          <b-col cols="12" class="mb-3" v-if="$userCan('cropfields.reports.fertilizers-bring')">
            <router-link
                :to="{ name: isOnMap ? 'cropfield.map.reports.fertilizer_inputs_summary' : 'cropfield.reports.fertilizer_inputs_summary'}"
                class="btn button-secondary btn-text-small text-center py-3"
              >
              {{ $t("cropfields.reports.fertilizer_inputs_report") }}<br />
              <span v-if="areEntriesChecked">({{ $t("cropfields.reports.selected_cropfields") }})</span>
              <span v-else>({{ $t("cropfields.reports.all_cropfields") }})</span>
              </router-link>
          </b-col>
          <b-col cols="12" class="mb-3">
            <router-link
                :to="{ name: isOnMap ? 'cropfield.map.reports.crop_rotation' : 'cropfield.reports.crop_rotation'}"
                class="btn button-secondary btn-text-small text-center py-3"
              >
              {{ $t("cropfields.reports.crop_rotation") }}<br />
              <span v-if="areEntriesChecked">({{ $t("cropfields.reports.selected_cropfields") }})</span>
              <span v-else>({{ $t("cropfields.reports.all_cropfields") }})</span>
              </router-link>
          </b-col>
          <b-col cols="12" class="mb-3" v-if="$userCan('cropfields.reports.ift')">
            <router-link
                :to="{ name: isOnMap ? 'cropfield.map.reports.ift' : 'cropfield.reports.ift'}"
                class="btn button-secondary btn-text-small text-center py-3"
              >
              {{ $t("cropfields.reports.ift") }}<br />
              <span v-if="areEntriesChecked">({{ $t("cropfields.reports.selected_cropfields") }})</span>
              <span v-else>({{ $t("cropfields.reports.all_cropfields") }})</span>
              </router-link>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import closeButton from "@/components/commons/CloseButton.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { formatServerUrl } from "@/utils/commons.js";

export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    onBack() {
      this.$router.push({ name: this.isOnMap ? "cropfield.map" : "cropfield.list" });
    },
    generateReport(reportType) {
      let queryParams = {};
      if (this.areEntriesChecked) {
        queryParams["id"] = this.selectedAsString;
      } else if (this.areEntriesFiltered) {
        queryParams["id"] = this.cropfieldsIdAsString;
      } else {
        queryParams["campaign"] = this.currentCampaign.id;
      }

      queryParams["farm_id"] = (this.currentFarm && this.currentFarm.id) || null;

      queryParams = new URLSearchParams(queryParams).toString();

      window.open(formatServerUrl(`/reports/${reportType}/`) + "?" + queryParams, "_blank");
    },
  },
  computed: {
    ...mapGetters({
      entriesChecked: "cropfield/entriesChecked",
      currentCampaign: "getCampaign",
      currentFarm: "farm/currentFarm",
      cropfields: "cropfield/entries",
      filterSelectionLabels: "cropfield/filterSelectionLabels"
    }),
    selectedAsString() {
      return this.entriesChecked.join(",");
    },
    areEntriesChecked() {
      return this.entriesChecked.length > 0;
    },
    areEntriesFiltered() {
      return this.filterSelectionLabels.length > 0;
    },
    cropfieldsIdAsString() {
      return this.cropfields.map(cf => cf.id).join(",");
    },
    isOnMap() {
      return this.$route.name.includes("map");
    },
  },
  components: {
    closeButton,
  },
};
</script>

<style lang="scss"></style>
