// Vue
import Vue from "vue";

// router
import { routes } from "./router";
import VueRouter from "vue-router";
const isProd = process.env.NODE_ENV === "production";

Vue.use(VueRouter);

Vue.config.devtools = !isProd;
const mode = isProd ? "history" : "hash";

export const router = new VueRouter({ mode, routes });

// jquery
// const { $, jQuery } = require("jquery");
// global.$ = $;
// global.jQuery = jQuery;

// bootstrap
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// search-select
import "vue-search-select/dist/VueSearchSelect.css";

import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

// custom alert / confirm dialog
import VueSimpleAlert from "vue-simple-alert";
Vue.use(VueSimpleAlert);

// styles
require("./sass/style.scss");

// filters
import "./filters";

// cookies
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
Vue.$cookies.config("30d");

// i18n
import { i18n, userLanguage } from "./i18n.js";

// axios
import axios from "axios";
import { AxiosInterceptorSuccess, AxiosInterceptorError } from "@/api/base";

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Content-Language"] = userLanguage;

axios.interceptors.response.use(AxiosInterceptorSuccess, AxiosInterceptorError);

axios.interceptors.request.use(function (config) {
  // do not overwrite if config has already 'withCredentials' attribute
  config.withCredentials = config.withCredentials != null ? config.withCredentials : true;
  return config;
});

// how the csrf cookie and headers should be named to be understood by Django
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

// store
import store from "./store";

// authorization plugin, so it's easy access user authorization from everywhere
import Authorizations from "./plugins/authorizations"
Vue.use(Authorizations)

// initialize vue app
import App from "./views/Index.vue";

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
