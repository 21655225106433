<template>
  <div class="position-relative">
    <h3 class="home-widget-title my-3">{{ mainGraph.title }}</h3>
    <div ref="chart"></div>
    <div ref="tooltip" class="tooltip">
      {{ tooltipData.label }}<br />
      {{ tooltipData.display_value1 }}
      ({{ Math.round(seedPercentage(tooltipData) * 100) }}%)
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

import { mapGetters, mapActions } from 'vuex';

import { EventBus } from '@/eventBus.js';

export default {
  data() {
    return {
      tooltipData: {},
      total: null,
      mainGraph: {},
    };
  },
  async mounted() {
    EventBus.$on('globalFiltersLoaded', async ({ hasFarmSelected }) => {
      // if there is farm selected, plot will be generated on initialization by the watchers, so we return
      if (hasFarmSelected) return;
      this.refreshData();
    });

    // if components loads (tab changed) and filters are already loaded
    if (this.globalFiltersLoaded) {
      this.refreshData();
    }
  },
  props: {
    cropOrder: {
      type: Number,
      default: 1
    }
  },
  methods: {
    ...mapActions({
      fetchSeedingProgressGraphData: 'home/fetchSeedingProgressGraphData',
    }),
    plotChart() {
      d3.select(this.$refs.chart)
        .selectAll('*')
        .remove();

      if (!this.mainGraph.values || this.mainGraph.values.length == 0) return;

      // set the dimensions and margins of the graph
      var width = 300;
      let height = 300;
      let margin = 10;

      // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
      var radius = Math.min(width, height) / 2 - margin;

      let tooltip = d3.select(this.$refs.tooltip).style('opacity', 0);

      var svg = d3
        .select(this.$refs.chart)
        .append('svg')
        .attr('preserveAspectRatio', 'xMinYMin meet')
        .attr('viewBox', `0 0 ${width} ${height}`)
        .append('g');

      const spacing = 15;
      const padding = 20;
      const defaultColor = 'whitesmoke';
      const fontColor = 'black';
      const labelFontSize = '12px';
      const valueFontSize = '12px';
      const minSeedPercentageLabel = 0.2;
      const maxSeedPercentageLabel = 0.85;

      const data = this.mainGraph.values;
      const barHeight = Math.round((height - 2 * padding) / data.length) - spacing;

      const computeYPos = i => padding + i * (barHeight + spacing);

      const computeWidth = d => this.seedPercentage(d) * width;

      let t = this;

      let g = svg
        .selectAll('mySlices')
        .data(data)
        .enter()
        .append('g')
        .attr('x', 0)
        .attr('y', function(d, i) {
          return computeYPos(i);
        })
        .on('mousemove', function(d, i) {
          tooltip.style('left', d3.event.pageX + 10 + 'px').style('top', d3.event.pageY - 15 + 'px');
        })
        .on('mouseover', function(d, i) {
          t.tooltipData = d;

          d3.select(this)
            .transition()
            .duration('50')
            .attr('opacity', '.80');
          tooltip
            .transition()
            .duration('50')
            .style('opacity', 1);
        })
        .on('mouseout', function(d, i) {
          d3.select(this)
            .transition()
            .duration('50')
            .attr('opacity', '1');
          tooltip
            .transition()
            .duration('50')
            .style('opacity', 0);
        });

      g.append('rect')
        .attr('x', 0)
        .attr('y', function(d, i) {
          return computeYPos(i);
        })
        .attr('width', width)
        .attr('height', barHeight)
        .attr('fill', function(d) {
          return defaultColor;
        });

      g.append('rect')
        .attr('x', 0)
        .attr('y', function(d, i) {
          return computeYPos(i);
        })
        .attr('width', function(d) {
          return computeWidth(d);
        })
        .attr('height', barHeight)
        .attr('fill', function(d) {
          return d.color;
        });

      g.append('text')
        .text(function(d) {
          return d.label;
        })
        .attr('x', 10)
        .attr('y', function(d, i) {
          return computeYPos(i) + barHeight / 2 + 5;
        })
        .style('font-size', labelFontSize)
        .style('fill', fontColor);

      g.append('rec')
        .attr('x', d => computeWidth(d) - 10)
        .attr('y', function(d, i) {
          return computeYPos(i) + barHeight / 2 + 5;
        })
        .style('text-anchor', 'end')
        .style('font-size', valueFontSize)
        .style('fill', fontColor);


      g.append("text")
        .text(function (d) {
          return t.seedPercentage(d) > minSeedPercentageLabel && t.seedPercentage(d) < maxSeedPercentageLabel
            ? d.display_value2
            : "";
        })
        .attr('x', width - 10)
        .attr('y', function(d, i) {
          return computeYPos(i) + barHeight / 2 + 5;
        })
        .style('text-anchor', 'end')
        .style('font-size', valueFontSize)
        .style('fill', fontColor);
    },
    seedPercentage(d) {
      return parseFloat(d.value1) / (parseFloat(d.value1) + parseFloat(d.value2));
    },
    /**
     * Permet de passer du graphe des 1eres Cultures à celui des 2ndes Cultures
     */
    changeGraph() {
      if(this.cropOrder == 1)
        this.mainGraph = this.graph;
      else
        this.mainGraph = this.secondGraph;
    },
    /**
     * Rafraichit les graphes
     */
    async refreshData() {
      await this.fetchSeedingProgressGraphData({cropOrder: 1});
      await this.fetchSeedingProgressGraphData({cropOrder: 2});
      this.changeGraph();
      this.plotChart();
    }
  },
  computed: {
    ...mapGetters({
      graph: 'home/seedingProgressGraphData',
      secondGraph: 'home/secondSeedingProgressGraphData',
      currentCampaign: 'getCampaign',
      currentFarm: 'farm/currentFarm',
      globalFiltersLoaded: 'user/globalFiltersLoaded',
    }),
  },
  watch: {
    currentCampaign() {
      if (!this.globalFiltersLoaded) return;

      this.refreshData();
    },
    currentFarm() {
      this.refreshData();
    },
    cropOrder: {
      handler() {
        this.changeGraph();
        this.plotChart();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.tooltip {
  position: fixed;
  text-align: center;
  padding: 5px 10px;
  background: #ffffff;
  border: 1px solid rgb(130, 130, 130);
  color: #313639;
  border-radius: 4px;
  pointer-events: none;
  font-size: 1rem;
}

text.tooltip-label {
  stroke: black;
  stroke-width: 4px;
}
</style>
