<template>
  <b-form-checkbox v-model="value" @input="onInput"></b-form-checkbox>
</template>

<script>
import { EventBus } from "@/eventBus.js";
import { mapGetters } from "vuex";

export default {
  props: ["rowId", "field", "cellData", "initialValue"],
  data() {
    return {
      value: false,
      group: null,
    };
  },
  created() {
    this.value = this.cellData;
    if (this.value === undefined) this.value = false;

    // override in case of : new mode + we have a default value
    if (this.initialValue) this.value = this.initialValue;

    this.initDependentBoxesBehaviour();

    // if this box is 'receivedInExchange'
    if (this.field == "receivedInExchange") {
      // be able to change the checkbox value if another component ask to
      EventBus.$on("setReceivedInExchangeCheckboxValue", this.setValue);
    }
  },
  methods: {
    uncheck() {
      this.value = false;
    },
    onInput() {
      if (this.group && this.value == true) {
        EventBus.$emit("uncheck", { group: this.group, from: this.field });
      }

      this.$emit("updateRow", this.rowId, this.payload);

      // if the value changed and we are "received in exchange" box, update the pacage dropdown
      if (this.field == "receivedInExchange") this.onInputPacageDropdownBehaviour();
    },
    setValue(value) {
      this.value = value;
    },
    initDependentBoxesBehaviour() {
      // if we want dependency between some checkboxes, we can assign a common group
      // we'll have a 'radio button' behavior
      if (this.field == "givenInExchange" || this.field == "receivedInExchange") {
        this.group = "cropfieldInExchange"; // choose an identifier
      }

      let t = this;

      // if another checkbox of the group emit this event, we turn to false
      EventBus.$on("uncheck", function ({ group, from }) {
        if (group == t.group && from != t.field) {
          t.uncheck();
        }
      });
    },
    /**
     * Change the pacage dropdown value when the checkbox value changed
     */
    onInputPacageDropdownBehaviour() {
      // update the pacage dropdown accordingly :
      // if the box in unchecked, set the pacage value as the current farm (null if none selected)
      // else, set the pacage value as none
      const pacageValue = this.value ? null : this.currentFarm;
      EventBus.$emit("setPacageDropdownValue", pacageValue);
    },
  },
  computed: {
    payload() {
      let payload = {};
      payload[this.field] = this.value;

      return payload;
    },
    ...mapGetters({
      currentFarm: "farm/currentFarm",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
