import Vue from "vue";

import CampaignApi from "@/api/campaign.api";

export const ALL_CAMPAIGNS = "GET ALL CAMPAIGNS";
export const SELECT_CAMPAIGN = "SET CAMPAIGN";
export const CAMPAIGN_LOADING = "LOAD CAMPAIGN";

export default {
  state: {
    campaigns: [],
    campaign_selected: {},
    campaign_loaded: true,
    // new campaign
    newCampaign: {},
  },
  getters: {
    campaigns: (state) => state.campaigns,
    getCampaign: (state) => state.campaign_selected,
    hasCurrentCampaign: (state) => Object.keys(state.campaign_selected).length > 0,
    newCampaign: (state) => state.newCampaign,
  },
  mutations: {
    [ALL_CAMPAIGNS]: (state, campaigns) => {
      state.campaigns = campaigns;
    },

    [SELECT_CAMPAIGN]: (state, payload) => {
      state.campaign_selected = payload.campaign;
    },

    [CAMPAIGN_LOADING]: (state, status) => {
      state.campaign_loaded = !status;
    },

    updateNewCampaign: (state, { field, value }) => {
      Vue.set(state.newCampaign, field, value);
    },

    addCampaign: (state, { campaign }) => {
      const index = state.campaigns.findIndex((c) => c.name == campaign.name);
      if (index >= 0) state.campaigns[index] = campaign;
      else state.campaigns.push(campaign);
    },

    resetNewCampaign: (state) => {
      state.newCampaign = {};
    },
  },
  actions: {
    async getAllCampaigns(
      { commit, rootGetters },
      { filterOnFarm, commitAfterFetch } = { commitAfterFetch: true, filterOnFarm: true },
    ) {
      commit(CAMPAIGN_LOADING, true);

      const payload = {};

      if (filterOnFarm) {
        const currentFarm = rootGetters["farm/currentFarm"];
        if (currentFarm) payload["farm_id"] = currentFarm.id;
      }

      const response = await CampaignApi.getCampaigns(payload);

      if (commitAfterFetch) {
        commit(ALL_CAMPAIGNS, response.data);
        commit(CAMPAIGN_LOADING, false);
      }

      return response.data;
    },

    async saveCampaign({ commit, state, rootGetters }, { campaign_id } = { campaign_id: null }) {
      // prepare the data to send to the API
      let payload = {
        reference_campaign: state.newCampaign.referenceCampaignId,
        name: state.newCampaign.targetCampaignName,
        endDate: state.newCampaign.endDate,
        startDate: state.newCampaign.startDate,
      };

      // if we have a farm selected, add it to payload
      if (rootGetters["farm/currentFarm"]) {
        payload["farm_id"] = rootGetters["farm/currentFarm"].id;
      }

      let newCampaign;
      // do we have a campaign id ?
      if (campaign_id) {
        // API call to update the campaign
        newCampaign = await CampaignApi.updateCampaign(payload, campaign_id);
      } else {
        // API call to create the campaign
        newCampaign = await CampaignApi.createCampaign(payload);
      }

      // get the result payload and update the local data
      newCampaign = newCampaign.data;
      commit("addCampaign", { campaign: newCampaign });

      // set the current campaign as the one we just updated
      commit(SELECT_CAMPAIGN, { campaign: newCampaign });
      // and set the cookie
      Vue.$cookies.set("campaign", campaign_id);

      commit("resetNewCampaign");
    },

    async setCurrentCampaign({ commit, dispatch }, campaign) {
      await commit(SELECT_CAMPAIGN, { campaign: campaign });

      Vue.$cookies.set("campaign", campaign?.id);
    },
  },
};
