<template>
  <div>
    <b-form @submit="save">
      <!-- If there is a geometry -->
      <MCT_Loc_ModifyGeometry ref="modifyView" v-if="currentLocationHasGeometry" :modifyMode.sync="modifyMode" />
      <!-- If no geometry, define the geometry -->
      <MCT_Loc_DefineGeometry ref="defineView" v-else :modifyMode.sync="modifyMode" />

      <!-- Cancel / save buttons -->
      <b-container class="mt-5 p-0" v-if="modifyMode || mapCutMode > 0">
        <b-row align-h="end">
          <b-col cols="6">
            <b-button class="button-default align-bottom" @click="reset">
              {{ $t("cropfields.details.cancel") }}
            </b-button>
          </b-col>
          <b-col cols="6">
            <b-button class="button-default align-bottom" type="submit">
              {{ $t("cropfields.details.save") }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import MapCutToolsMixin from "@/components/cropfields/details/map-cut-tools/MapCutToolsMixin";
import MCT_Loc_ModifyGeometry from "./MCT_Loc_ModifyGeometry";
import MCT_Loc_DefineGeometry from "./MCT_Loc_DefineGeometry";

export default {
  data() {
    return {
      modifyMode: false,
    };
  },
  mixins: [MapCutToolsMixin],
  components: { MCT_Loc_DefineGeometry, MCT_Loc_ModifyGeometry },
  methods: {
    ...mapMutations({
      setMapCutMode: "location/setMapCutMode",
      resetEditedEntry: "location/resetEditedEntry",
      setCurrentId: "location/setCurrentId",
    }),
    ...mapActions({
      createEntry: "location/createEntry",
      updateEntry: "location/updateEntry",
      updateGeometry: "location/updateGeometry",
    }),
    // cancel modifications
    reset() {
      if (this.currentLocationHasGeometry) {
        this.$refs.modifyView.reset();
      } else {
        this.$refs.defineView.reset();
      }
    },
    // save !
    async save(event) {
      event.preventDefault();

      if (this.currentLocationHasGeometry) {
        this.$refs.modifyView.save();
      } else {
        this.$refs.defineView.save();
      }
    },
  },
  computed: {
    ...mapGetters({
      mapCutMode: "location/mapCutMode",
      currentLocationHasGeometry: "location/currentLocationHasGeometry",
    }),
  },
};
</script>

<style lang="scss" scoped>
.field {
  position: relative;
  background: #ebebeb;
  border-radius: 10px;
}

.new-cropfield {
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  font-size: 0.8rem;
  color: $dark-grey;
  font-style: italic;
}

.guide-text {
  font-size: 0.9rem;
}

.color-dark {
  color: $text-color;
}
</style>
