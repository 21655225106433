import Gateway from "./base";

export default class MiscellaneousApi {
  /**
   * Get All Miscellaneous
   */
  static getAllMiscellaneous() {
    return Gateway.get(`/external/miscellaneous/`);
  }

  /**
   * Get one Miscellaneous by key
   *
   * @param param
   */
  static getMiscellaneousByKey(param) {
    return Gateway.get(`/external/miscellaneous/`, param);
  }

  /**
   * Create Miscellaneous
   *
   * @param data
   */
  static createMiscellaneous(data) {
    return Gateway.post(`/external/miscellaneous/`, data);
  }

  /**
   * Update Miscellaneous
   *
   * @param data
   * @param id
   */
  static updateMiscellaneous(data, id) {
    return Gateway.put(`/external/miscellaneous/${id}/`, data);
  }

  /**
   * delete Miscellaneous
   *
   * @param id
   */
  static deleteMiscellaneous(id) {
    return Gateway.delete(`/external/miscellaneous/${id}/`);
  }

  /**
   * get smag application id
   */
  static getSmagApplicationId() {
    return Gateway.get(`/external/smagapplicationid`);
  }

  static getXarvioAuthParams() {
    return Gateway.get(`/external/xarvioauthparams`);
  }
}
