<template>
  <div>
    <b-row>
      <b-col class="col-8">
        <h4 class="mb-3">
          <i>Irrigation</i>
        </h4>
      </b-col>
    </b-row>

    <b-row no-gutters>
        <b-col class="mb-3 pr-4 col-4" >
            <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.irrigated">{{$t('cropfields.fertilization.needsCalculation.irrigating')}}</h4>
            <b-form-checkbox size="sm" v-model="irrigated" @input="updateIrrigated()" :disabled="!edit_mode" />
        </b-col>

        <b-col class="mb-3 pl-4 pr-4 col-4" >
            <h4
                class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.waterQuantity"
            >{{$t('cropfields.fertilization.needsCalculation.waterQuantity')}}</h4>
            <NumericInput
            inputRef="input"
            :value="waterQuantity"
            @blur="updateWaterQuantity()"
            @input="i => {waterQuantity = i}"
            :class="{ 'disabled-input': !edit_mode }"
            :disabled="!irrigated || !edit_mode"
            :numberOfDigits="0"
          />
        </b-col>

        <b-col class="mb-3 pr-4 col-4"> 
            <h4
                class="subtitle-form mb-2"  v-b-tooltip.hover.html="fertiToolTips.nitrateDose"
            >{{$t('cropfields.fertilization.needsCalculation.nitrateDose')}}</h4>   
            <NumericInput
              inputRef="input"
              :value="nitrateDose"
              @blur="updateNitrateDose()"
              @input="i => {nitrateDose = i}"
              :class="{ 'disabled-input': !edit_mode }"
              :disabled="!irrigated || !edit_mode"
              :numberOfDigits="0"
            />
        </b-col>

    </b-row>
  </div>
</template>

<script>
import NumericInput from "@/components/commons/NumericInput.vue";

export default {
  name: "fertilization-irrigation",
  data(){
    return{
      edit_mode: false,
      irrigated: false,
      waterQuantity: null,
      nitrateDose: null,
    }
  },
  created() {
    this.init();
  },
  props: [
    'editMode', 
    'fertilisationNeedsCalculation', 
    'fertilisationNeedsCalculationList', 
    'fertilisationNeedsCalculationOption',
    'fertiToolTips',
  ],
  methods: {
    init: async function() {
      this.irrigated = this.fertilisationNeedsCalculation.irrigated
      await this.loadData();
    },
    async loadData(){
      if(typeof this.fertilisationNeedsCalculation != 'undefined') {
        await this.editCheck()
        this.waterQuantity = this.fertilisationNeedsCalculation.waterQuantity
        this.nitrateDose = this.fertilisationNeedsCalculation.nitrateDose
      }
    },
    async editCheck(){
      this.edit_mode = this.editMode
    },
    async updateIrrigated(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "irrigated", value: this.irrigated });
    },
    async updateWaterQuantity(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "waterQuantity", value: this.waterQuantity });
    },
    async updateNitrateDose(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "nitrateDose", value: this.nitrateDose });
    },
  },
  watch: {
    fertilisationNeedsCalculation: {
      handler: 'loadData',
      immediate: true
    },
    editMode: {
      handler: 'editCheck',
      immediate: true
    }
  },
  components: {
    NumericInput,
  },
};

</script>

<style>
.disabled-input:disabled {
  background-color: white !important;
  border: none;
}
</style>