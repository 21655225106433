<template>
    <div>
      <!-- Treatment Component : Cropfield -->
      <!-- An implicit component, that does not show a cropfields selector, but add the current cropfields selected name to treatment form -->
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  
  import TreatmentFormPartMixin from "@/components/commons/treatments/TreatmentFormPartMixin";
  import { TreatmentFormPart } from "@/models/treatments/Treatment.js";
  
  export default {
    mixins: [TreatmentFormPartMixin],
    methods: {
      validateForm() {
        this.isValid = true;
        return new TreatmentFormPart("id", this.entries);
      },
    },
    computed: {
      ...mapGetters({
        entriesChecked: "cropfield/entriesChecked",
        cropfields: "cropfield/entries",
        filterSelectionLabels: "cropfield/filterSelectionLabels"
      }),
      areEntriesChecked() {
        return this.entriesChecked.length > 0;
      },
      areEntriesFiltered() {
        return this.filterSelectionLabels.length > 0;
      },
      cropfieldsId() {
        return this.cropfields.map(cf => cf.id);
      },
      entries() {
        return this.areEntriesChecked ? this.entriesChecked : (this.areEntriesFiltered ? this.cropfieldsId : [])
      }
    },
  };
  </script>
  
  <style></style>
  