<template>
  <div class="app">
    <ag-header />
    <ag-tile />
    <router-view />
  </div>
</template>

<script>
import { EventBus } from "../eventBus.js";

import header from "@/components/Header.vue";
import tile from "../components/PageTitle.vue";

export default {
  components: {
    agHeader: header,
    agTile: tile,
  },
  mounted() {
    EventBus.$on("toast", this.showToast);
  },
  methods: {
    showToast({ message, variant }) {
      this.$bvToast.toast(message, {
        variant: variant,
        allowHtml: true,
        solid: true,
        noCloseButton: true,
        autoHideDelay: 1000,
        toaster: "b-toaster-bottom-right",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.app {
  max-height: 70%;
}
</style>
