import LocationsDetails from "./LocationsDetails.vue";
import LocationsMap from "./LocationsMap.vue";

import CommonViewIndex from "@/views/common/CommonViewIndex.vue";
import CommonViewList from "@/views/common/CommonViewList.vue";
import CommonViewFiltering from "@/views/common/CommonViewFiltering.vue";

import { config } from "./config";

export default [
  {
    component: CommonViewIndex,
    path: "/locations",
    props: config,
    children: [
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
        },
        path: "",
        name: "location.list",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: LocationsDetails,
        },
        path: "new",
        name: "location.new",
      },
      {
        components: {
          default: LocationsMap,
          filtering: CommonViewFiltering,
        },
        path: "map",
        name: "location.map",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: LocationsDetails,
        },
        path: ":location_id",
        name: "location.details",
      },
      {
        components: {
          default: LocationsMap,
          filtering: CommonViewFiltering,
          sidebar: LocationsDetails,
        },
        path: "map/new",
        name: "location.map.new",
      },
      {
        components: {
          default: LocationsMap,
          filtering: CommonViewFiltering,
          sidebar: LocationsDetails,
        },
        path: "map/:location_id/:mode?",
        name: "location.map.details",
      },
    ],
  },
];
