<template>
  <div class="home-widget bg-grey">
    <b-container>
      <b-row align-v="center" align-h="start">
        <b-col class="align-items-center">
          <div class="d-flex justify-content-start align-items-center">
            <h3 class="home-widget-title my-3 mr-3">{{ $t("home.news.title") }}</h3>
            <div class="d-flex justify-content-start align-items-center">
              <a href="javascript:void(0)" class="mr-2" @click="prev"
                ><img :class="{ light: isFirstSlide }" src="@/assets/images/left_arrow.svg"
              /></a>
              <a href="javascript:void(0)" @click="next"
                ><img :class="{ light: isLastSlide }" src="@/assets/images/right_arrow.svg"
              /></a>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="news-row">
        <b-col>
          <b-carousel v-model="currentSlide" ref="carousel" img-width="400" img-height="250">
            <b-carousel-slide v-for="n in upgradeNews" :key="n.id" img-blank style="max-height: 20vh">
              <h3 class="mb-2">{{ n.title }}</h3>
                {{ n.body }}
            </b-carousel-slide>
          </b-carousel>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      currentId: null,
      currentSlide: 0,
    };
  },
  methods: {
    prev() {
      this.$refs.carousel.prev();
    },
    next() {
      this.$refs.carousel.next();
    },
  },
  computed: {
    ...mapGetters({
      upgradeNews: "upgradeNews"
    }),
    isFirstSlide() {
      return this.currentSlide == 0;
    },
    isLastSlide() {
      return this.currentSlide == this.upgradeNews.length - 1;
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  font-weight: bold;
}

.bg-grey {
  background: $light-grey;
}

.news-row {
  height: 20vh;
}

.active {
  background: transparent;
}

.light {
  opacity: 0.6;
}
</style>

<style lang="scss">
.carousel-caption {
  color: $primary-color !important;
  font-size: 0.8rem !important;
  text-align: left !important;
  width: 100% !important;
  height: 100% !important;
  left: 0 !important;
  top: 0 !important;
}

.carousel-indicators li {
  background-color: $dark-grey !important;
  width: 10px !important;
  height: 10px !important;
  border-radius: 100% !important;
}
</style>
