import Vue from "vue";
import FarmApi from "@/api/farm.api";

export default {
  namespaced: true,
  state: {
    // farm selected in the global filters
    currentFarmId: null,
    // all the farms as fetched from api
    farms: [],
  },
  getters: {
    currentFarmId: (state) => {
      return state.currentFarmId
    },
    currentFarm: (state) => {
      if (!state.currentFarmId) return;
      return state.farms.find((f) => f.id == state.currentFarmId);
    },
    currentFarmFullAddress: (state, getters) => {
      // make full address string with street, postcode and city
      return [
        getters.currentFarm?.street,
        getters.currentFarm?.postCode,
        getters.currentFarm?.city]
      // remove null values
      .filter(e => e != null)
      // join with spaces
      .join(" ")
    },
    farms: (state) => state.farms.filter((f) => f.id != null),
  },
  mutations: {
    setFarms: (state, farms) => {
      Vue.set(state, "farms", farms);
    },
    setCurrentFarm: (state, farmId) => {
      state.currentFarmId = farmId;
    },
  },
  actions: {
    /**
     * fetch all the farms
     *
     */
    async fetchFarms({ commit }) {
      const response = await FarmApi.getFarms();

      commit("setFarms", response.data);
    },

    /**
     * create a new farm
     * need at list an id in the data object
     *
     */
    async createFarm({}, data) {
      return FarmApi.createFarm(data);
    },

    /**
     * update a new farm
     *
     */
    async updateFarm({}, { id, data }) {
      return FarmApi.updateFarm(id, data);
    },

    /**
     * set the user current farm
     *
     */
    async setCurrentFarm({ commit, dispatch }, farmId) {
      commit("setCurrentFarm", farmId);

      if (farmId) Vue.$cookies.set("farm", farmId);
      else Vue.$cookies.remove("farm");

      await dispatch("getAllCampaigns", { commitAfterFetch: true, filterOnFarm: true }, { root: true });
    },
  },
};
