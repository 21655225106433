<template>
  <div>
    <b-overlay :show="importStatus == 'pending'" rounded="sm">
      <!-- close button -->
      <div class="details-buttons">
        <closeButton :on-click="onBack" />
      </div>

      <div>
        <b-container class="p-0 mb-4">
          <b-row no-gutters>
            <b-col cols="auto" class="pr-3">
              <h3 class="title-form">
                {{ $t("cropfields.import_export.title") }}
              </h3>
            </b-col>
          </b-row>
        </b-container>

        <b-container class="mt-5 px-5">
          <b-row>
            <b-col cols="12" class="mb-3">
              <router-link
                :to="{ name: isOnMap ? 'cropfield.map.import' : 'cropfield.import' }"
                class="btn button-secondary btn-text-small text-center py-3"
              >
                {{ $t("cropfields.import_export.import_cropfields") }}<br />
              </router-link>
            </b-col>
            <b-col cols="12" class="mb-3" v-if="$userCan('cropfields.export_import.import_smag')">
              <button class="btn button-secondary btn-text-small text-center py-3" @click="importFromSmag">
                {{ $t("cropfields.import_export.import_from_smag") }}
              </button>
            </b-col>
            <b-col cols="12" class="mb-3" v-if="$userCan('cropfields.export_import.xarviofm')">
              <button class="btn button-secondary btn-text-small text-center py-3" @click="exportToXarvio">
                {{ $t("cropfields.import_export.export_to_xarvio") }}
              </button>
            </b-col>
            <b-col cols="12" class="mb-3">
              <button class="btn button-secondary btn-text-small text-center py-3" @click="exportCropfields">
                <span v-if="areEntriesChecked">{{ $t("cropfields.import_export.export_selected_cropfields") }}</span>
                <span v-else>{{ $t("cropfields.import_export.export_all_cropfields") }}</span>
              </button>
            </b-col>
            <b-col cols="12">
              <button class="btn button-secondary btn-text-small text-center py-3" @click="exportAgroEDI">
                {{ $t("cropfields.import_export.export_agro_edi") }}<br />
                <span v-if="areEntriesChecked">({{ $t("cropfields.reports.selected_cropfields") }})</span>
                <span v-else> ({{ $t("cropfields.reports.all_cropfields") }})</span>
              </button>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-overlay>
    <b-modal ref="thirdPartyService" hide-header centered>
      <div class="row text-center">
        <div class="col">
          {{ thirdPartyService == "smag" ? $t("cropfields.import_export.redirecting_to_smag_farmer") : $t("cropfields.import_export.redirecting_to_xarvio") }}
        </div>
      </div>
      <template v-slot:modal-footer>
        <div class="w-100 d-flex align-items-center">
          <div class="col-6 text-right">
            <b-button @click="hideModal">
              {{ $t("cancel") }}
            </b-button>
          </div>
          <div class="col-6 text-left" v-if="thirdPartyService == 'xarvio'">
            <form :action="xarvioAuthParams.auth_url" method="post">
              <input type="hidden" id="client_id" name="client_id" :value="xarvioAuthParams.client_id">
              <input type="hidden" id="client_secret" name="client_secret" :value="xarvioAuthParams.client_secret">
              <input type="hidden" id="redirect_uri" name="redirect_uri" :value="xarvioAuthParams.redirect_uri">
              <input type="hidden" id="response_type" name="response_type" :value="xarvioAuthParams.response_type">
              <b-button type="submit" variant="success"> {{ $t('home.notifications.ok') }} </b-button>
            </form>
          </div>

          <div class="col-6 text-left" v-if="thirdPartyService == 'smag'">
            <a :href="smagLoginUrl">
              <b-button
                variant="success"
                class=""
              >
                {{ $t('home.notifications.ok') }}
              </b-button>
            </a>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import closeButton from "@/components/commons/CloseButton.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import CropfieldApi from "@/api/cropfield.api";

export default {
  data() {
    return {
      isLoading: false,
      importFile: null,
      checkInterval: null,
      task_id: null,
      importStatus: null,
      smagLoginUrl: null,
      xarvioAuthParams: {},
      root: null,
      returnPath: null,
      thirdPartyService: null,
      smagToken: [],
      xarvioToken: [],
    };
  },
  beforeRouteLeave(to, from, next) {
    if((to.name.includes("smag") && !this.smagToken.length) || (to.name.includes("xarvio") && !this.xarvioToken.length)) {
      this.returnPath = "/" + this.root + to.fullPath;
      this.$cookies.set("return_path", this.returnPath, "1h");
      next(false);
    }else {
      next();
    }
  },
  async mounted() {
    console.log('farm', this.currentFarmId);
    this.smagToken = await this.$store.dispatch("getMiscellaneousByKey", {key: "smag_refresh_token", farm: this.currentFarmId});
    this.xarvioToken = await this.$store.dispatch("getMiscellaneousByKey", {key: "xarvio_refresh_token", farm: this.currentFarmId});
    await this.$store.dispatch("getSmagApplicationId");
    this.root = window.location.pathname.split('/')[1];
    if(this.$route.query.search === 'xarvio'){this.exportToXarvio()}
    if(this.$route.query.search === 'smag'){this.importFromSmag()}
  },
  methods: {
    onBack() {
      this.$router.push({ name: "cropfield.list" });
    },
    exportCropfields() {
      this.$router.push({ name: "cropfield.export" });
    },
    exportAgroEDI() {
      this.$router.push({ name: "cropfield.exporttoagroedi" });
    },
    importSmag() {
      this.$alert(this.$t("feature_soon_available"));
    },
    hideModal() {
      this.$refs.thirdPartyService.hide();
    },
    importFromSmag() {
      this.thirdPartyService = "smag";
      if(this.smagToken.length) {
        this.$router.push({name: this.isOnMap ? "cropfield.map.importfromsmag" : "cropfield.importfromsmag"});
        return;
      }
      this.$router.push({name: this.isOnMap ? "cropfield.map.importfromsmag" : "cropfield.importfromsmag"});
      this.fetchSmagLoginUrl();
      this.$refs.thirdPartyService.show();
    },
    async fetchSmagLoginUrl() {
      if(!this.smagLoginUrl)
        this.smagLoginUrl = await this.$store.dispatch("cropfield/getSmagLoginUrl", {application_id: this.smagApplicationId.smagApplicationId.id});
    },
    exportToXarvio() {
      this.thirdPartyService = "xarvio";
      if(this.xarvioToken.length) {
        this.$router.push({name: this.isOnMap ? "cropfield.map.exporttoxarvio" : "cropfield.exporttoxarvio"});
        return;
      }
      this.$router.push({name: this.isOnMap ? "cropfield.map.exporttoxarvio" : "cropfield.exporttoxarvio"});
      this.getXarvioAuthParams();
      this.$refs.thirdPartyService.show();
    },
    async getXarvioAuthParams() {
      if(Object.keys(this.xarvioAuthParams).length == 0)
        this.xarvioAuthParams = await this.$store.dispatch("getXarvioAuthParams");
    },
  },
  computed: {
    ...mapGetters({
      entriesChecked: "cropfield/entriesChecked",
      smagApplicationId: "smagApplicationId",
      currentFarmId: "farm/currentFarmId",
    }),
    areEntriesChecked() {
      return this.entriesChecked.length > 0;
    },
    isOnMap() {
      return this.$route.name.includes("map");
    },
  },
  components: {
    closeButton,

  },
};
</script>

<style lang="scss" scoped>
.btn {
  white-space: normal;
}
</style>
