<template>
    <div class="d-flex">
      <div class="d-flex flex-column justify-content-center">
        <div class="font-14 position-relative">
          {{ crop_name() }}
        </div>
        <div class="d-block" style="font-weight: normal; font-size: 0.7rem; font-style: italic;">
          {{ firstVariety(3) }}
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex/dist/vuex.common.js';

  export default {
    props: ["rowId", "rowData"],
    computed: {
      ...mapGetters({
        crops: "crop/crops"
      }),
    },
    methods: {
      firstVariety(n) {
        if (!this.rowData.varieties) return "";
        return this.rowData.varieties
          .slice(0, n)
          .map(i => i.variety_name)
          .join(", ");
      },
      crop_name(){
        const crop = this.crops.find(e => e.id === this.rowData.crop)
        if(crop) return crop.name
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .tracker-icon img {
    width: 18px;
    margin-right: 3px;
    margin-top: -20px
  }
  </style>
  