<template>
	<div>
		<!-- Cette boîte s'affiche lorsqu'on clique sur des parcelles superposées -->
		<!-- Demande la sélection de la parcelle dont on veut obtenir le détail -->
		<b-modal ref="chooseCropfield" centered hide-header hide-footer>
			<h4 class="mb-3">
				{{ $t("cropfields.map.many_cropfields_at_same_place") }}
			</h4>
			<div align-v="center" class="bg-white text-infos m-0 p-0">
				<b-list-group>
					<!-- Liste des parcelles sélectionnées -->
					<b-list-group-item v-for="cf in cropFields" :key="cf.id" button @click="selectCropfield(cf.id)">
						{{ cf.name }}
						{{ cf.cropOrder && cf.cropOrder > 1 ? ("(" + $t("cropfields.details.second_crop") + ")") : "" }}
						{{ "(" + cf.surface + " ha)" }} {{ " - " + (cf.crop_name ? cf.crop_name : $t("cropfields.map.no_crop"))}}
 					</b-list-group-item>
					<!-- Option supplémentaire pour aucune sélection -->
					<b-list-group-item button @click="selectCropfield(null)">
						{{ $t("no_one_feminine") }}
					</b-list-group-item>
				</b-list-group>
			</div>
		</b-modal>
	</div>
</template>
<script>
export default {
	data() {
		return {
			check: false, // check est mis à true lorsqu'il s'agit d'une sélection groupée,
			evt: false  // The original click event on the map
		}
	},
	props: {
		cropFields: {
			type: Array,
			default: () => []
		}
	},
	methods: {
		selectCropfield(id) {
			this.$emit('select', {id: id, check: this.check}, this.evt);
			this.$refs.chooseCropfield.hide();
		},
		showModal(check, evt) {
			this.check = check;
			this.evt = evt;
			this.$refs.chooseCropfield.show();
		}
	}
}
</script>
<style lang="scss" scoped>

</style>