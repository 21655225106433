<template>
  <v-select
    @input="$emit('updateRow', rowId, payload)"
    :options="crops"
    label="name"
    v-model="value"
    :appendToBody="true"
    :clearable="clearable"
    :selectable="option => !option.hasOwnProperty('group')"
    :disabled="disabled"
  >
    <template #option="{ group, name }">
      <div v-if="group" class="group">
        {{ group }}
      </div>
      {{ name }}
    </template>
    <template #no-options>
      {{ $t("dropdown.no_option") }}
    </template>
  </v-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["rowId", "field", "cellData", "disabled", "clearable"],
  data() {
    return {
      value: null,
    };
  },
  async created() {
    if (this.crops.length == 0) await this.fetchCrops();
    let crop = this.crops.find(e => e.id == this.cellData);
    if (crop) this.value = crop.name;
  },
  computed: {
    ...mapGetters({ crops: "crop/cropsByExisting" }),
    payload() {
      let payload = {};
      if (this.value){
        payload["crop"] = this.value.id;
        payload["crop_name"] = this.value.name;
      }

      if(!this.value) {
        payload["crop"] = null;
        payload["crop_name"] = "";
      }

      if (!this.value || !this.value.needRowWidth)
        payload["rowWidth"] = null;

      return payload;
    },
  },
  methods: {
    ...mapActions({ fetchCrops: "crop/fetchCrops" }),
    setValue(value) {
      this.value = value;
    },
  },
  watch: {
    value: {
      handler: async function() {
        // utile pour réinitialiser la largeur du rang
        if(this.value && this.value.id)
          await this.$store.dispatch("getOneCropType", {id: this.value.id});
        else
          await this.$store.dispatch("resetCurrentCropType");
      }
    }
  }
};
</script>

<style lang="scss">
.vs__selected {
  word-break: keep-all;
  white-space: nowrap;

  max-width: 60px;
}

.vs__selected-options {
  overflow: hidden;
}

.vs__dropdown-toggle {
  height: calc(1.5em + 0.5rem + 2px);
  // padding: 0.25rem 0.5rem;
  padding: 0 0 4px;
  font-size: 0.9rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.vs__dropdown-option {
  font-size: 0.9rem;
  padding: 2px 5px !important;
  white-space: normal !important;
  border-bottom: 1px solid #ededed;
}

.vs__dropdown-option--disabled {
  background: #ededed !important;
  color: #2d2d2d;
  font-weight: bold;
  padding: 4px 5px !important;
}

.vs--disabled .vs__selected {
  color: $dark-grey;
  font-style: italic;
}

.vs--disabled input {
  opacity: 0;
}


.vs-inline .v-select,
.vs-inline .vs__dropdown-toggle,
.vs-inline .vs__search:focus {
  width: 100% !important;
}
</style>
