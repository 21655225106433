<template>
  <!-- Treatment Component : File Upload -->
  <!-- Displays a component to upload file for form treatment. You can add constraint on file format with the treatment uploadFileAllowedFormats attribute. -->
  <div>
    <h4 class="subtitle-form mb-2">{{ $t("commons.imports.import_file") }}</h4>

    <b-form-group label-for="file-import">
      <b-form-file
        ref="fileInput"
        id="file-import"
        v-model="importFile"
        :no-drop="true"
        :browse-text="$t('commons.imports.select_file')"
        :placeholder="$t('commons.imports.browse_placeholder')"
        :accept="acceptedExtensions"
        :required="true"
      ></b-form-file>
    </b-form-group>

    <!-- File features to select, only show if there are options to display -->
    <div v-if="hasFeatures">
      <h4 class="subtitle-form mb-2">{{ fileFeatures.label }}</h4>
      <b-form-group label-for="file-features">
        <b-form-select
          id="file-features"
          v-model="selectedFeature"
          :options="fileFeatures.features"
          :required="true"
        ></b-form-select>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import TreatmentFormPartMixin from "@/components/commons/treatments/TreatmentFormPartMixin";
import { TreatmentFormPart } from "@/models/treatments/Treatment.js";

export default {
  mixins: [TreatmentFormPartMixin],
  data() {
    return {
      importFile: null,
      selectedFeature: null,
    };
  },
  props: {
    fileFeatures: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    // if we have features in list, set the default value to the first one of the list
    if (this.fileFeatures && this.fileFeatures.features && this.fileFeatures.features.length > 0) {
      this.selectedFeature = this.fileFeatures.features[0].value;
    }
  },
  methods: {
    validateForm() {
      // get file extension
      let extension;
      try {
        // try to get extension
        extension = this.importFile.name.split(".").pop();
      } catch (e) {
        // if error : not valid !
        this.isValid = false;
        this.$alert(this.$t("cropfields.import_export.alert_unknown_file_extension"));
        return;
      }

      this.treatment.uploadFileFormat = this.extension;

      this.isValid = true;

      return [
        new TreatmentFormPart("file", this.importFile, this.importFile.name),
        new TreatmentFormPart("filefeature", this.selectedFeature),
      ];
    },
  },
  computed: {
    acceptedExtensions() {
      return (this.treatment.uploadFileAllowedFormats || []).map((e) => "." + e).join(", ");
    },
    hasFeatures() {
      return this.fileFeatures && Object.keys(this.fileFeatures).length > 0;
    },
  },
};
</script>

<style></style>
