import Gateway from "./base";

export default class AuthApi {
  /**
   * Get user data
   *
   */
  static getUser() {
    return Gateway.get(`/api/auth/user/`);
  }

  /**
   * Get Customer
   */
  static getCustomer() {
    return Gateway.get(`/api/customer/`);
  }
}
