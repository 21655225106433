import TreatmentApi from "@/api/treatment.api";
import { dateToISOString } from "@/utils/commons";

/*
 * Treatment
 * --------------------
 * This class is abstract, do not instantiate it
 *
 */
export class Treatment {
  /* start of configuation */

  // form components
  form = [];

  // which format to allow for export ?
  // this will narrow the TC_FileFormat component
  // example : ["csv", "xml"]
  exportAllowedFormats = [];
  // example : ["csv", "xml"]
  exportAllowedOptions = [];
  // same but when we upload a file. This will narrow the TC_UploadFile component
  uploadFileAllowedFormats = [];

  /* end of configuation, do not edit below */

  exportedFile;
  status;
  hasTimeout;

  exportedFileFormat = null;
  exportedOption = null;
  uploadFileFormat = null;

  // references to form components, built
  formParts = [];

  // data to be sent to server
  formData;

  // received logs from server
  logs;

  // callbacks
  onTaskFail = () => {};
  onFetch = () => {};

  // call each validateForm in the form components (TC_****.vue)
  validateForm() {
    // construct a formData
    this.formData = new FormData();
    for (let i = 0; i < this.formParts.length; i++) {
      // call the validateForm method
      let formPartData = this.formParts[i].validateForm();

      // if the result is not valid, stop the data aggregation
      if (!this.formParts[i].isValid) return false;

      if (!Array.isArray(formPartData)) formPartData = [formPartData];

      for (let j = 0; j < formPartData.length; j++) {
        if (formPartData[j].filename)
          this.formData.set(formPartData[j].name, formPartData[j].value, formPartData[j].filename);
        else this.formData.set(formPartData[j].name, formPartData[j].value);
      }
    }

    return this.customValidation(this);
  }

  getFormDataAsDict() {
    var object = {};
    this.formData.forEach((value, key) => (object[key] = value));
    return object;
  }

  beforeImport() {}

  onTreatmentDone() {}

  customValidation() {
    return true;
  }
}

/*
 * Synchrone Treatment
 * --------------------
 * A simple treatment that call directly a route to get data or download a file
 *
 */
export class SyncTreatment extends Treatment {
  /* start of configuration */

  // api endpoint to call, it sould be a function, for example 'ActivityApi.exportActivitiesWithMachines'
  endpoint = null;

  // do we want to direct download response from server ?
  isDirectDownload = true;

  /* end of configuration */

  async start() {
    this.status = "pending";
    this.hasTimeout = false;

    if (!this.endpoint) {
      console.error("treatment " + this.name + " : missing endpoint");
      return;
    }

    let result;
    try {
      result = await this.endpoint(this.getFormDataAsDict());
      console.log('result --> ', result)
    } catch (error) {
      console.error(error);
      return;
    }

    this.status = "success";

    if (this.getFormDataAsDict().format != 'html' && this.isDirectDownload) {
      this.downloadFile(result, this.exportedFileFormat);
    }

    if (this.getFormDataAsDict().format === 'html') return result;
    else return null
  }

  downloadFile(httpResponse, extension) {
    const contentDisposition = httpResponse.request.getResponseHeader("Content-Disposition");
    let filename=null;
    if(contentDisposition)
      filename = contentDisposition.match(/filename=(.+);?/)[1];
    else
      filename = "report." + extension
    const downloadUrl = window.URL.createObjectURL(new Blob([httpResponse.data]));
    console.log(downloadUrl);
    const link = document.createElement("a");
    link.href = downloadUrl;
    // const fileName = httpResponse.headers
    link.setAttribute("download", filename); //any other extension

    document.body.appendChild(link);

    link.click();

    link.remove();
  }
}

/*
 * Asynchrone Treatment
 * --------------------
 * A treatment that will use DjangoQ on back-end
 *
 */
export class AsyncTreatment extends Treatment {
  /* start of configuration */

  // backend task name as described in models>treatments.py>TREATMENTS
  taskName;

  // delay between two calls to check task status
  taskCheckInterval = 2000;
  // max timeout
  taskTimeout = 80000;

  /* end of configuration, do not edit below */

  // recevied taskId from backend
  taskId;

  // reference of the setInterval
  checkInterval;

  onTaskFail = () => {};
  onFetch = () => {};

  beforeImport() {}

  onTreatmentDone() {}

  customValidation() {
    return true;
  }

  async start() {
    this.status = "pending";

    let result;
    try {
      // create the task on backend
      result = await TreatmentApi.createTreatment(this.taskName, this.formData);
      // save the taskId
      this.taskId = result.data.task_id;
    } catch (error) {
      // if error, parse erorr message and show it
      this.status = "fail";
      const data = error.response.data;

      const colorCode = "\x1B[91m"; // red, same as on backend
      // parse error message
      this.logs =
        colorCode +
        Object.keys(data)
          .map((e) => e + ": " + data[e])
          .join(" | ");

      this.onFetch();
      this.onTaskFail();

      return;
    }
    this.checkInterval = setInterval(() => this.fetch(this), this.taskCheckInterval);

    // for safety, stop afer timeout
    this.timeout = setTimeout(() => {
      clearInterval(this.checkInterval);
      this.hasTimeout = true;
    }, this.taskTimeout);
  }

  async fetch(context) {
    let result;
    let data;

    try {
      result = await TreatmentApi.getTreatment({ task_id: context.taskId });
      data = result.data;
    } catch (error) {
      data = error.response.data;

      this.onTaskFail();
    }

    context.status = data.status;

    if (data.file) {
      context.exportedFile = data.file;
    }

    context.logs = data.message || "";

    context.onFetch();

    if (context.status == "pending") return;

    clearInterval(context.checkInterval);
    clearTimeout(context.timeout);

    context.onTreatmentDone();
  }
}

/* contains the data of a form component */
export class TreatmentFormPart {
  // the name as it will be sent to server
  name;
  // value of the field
  value;
  // in case of a file, we need to add filename (we follow 'formData' js structure)
  filename;

  constructor(name, value, filename = "") {
    this.name = name;
    this.value = value;
    this.filename = filename;
  }
}
