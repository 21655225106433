import SoilAnalyzesApi from "../../api/soilanalyzes.api";

export const ALL_SOIL_ANALYZES = "GET ALL SOIL ANALYZES";
export const SOIL_ANALYZES_LOADING = "LOAD SOIL ANALYZES";
export const CREATE_SOIL_ANALYZES = "CREATE SOIL ANALYZES"

export default {
  state: {
    soilAnalyzes: [],
    soilAnalyzes_loaded: true,
  },
  getters: {
    soilAnalyzes: state => state.soilAnalyzes,
    soilAnalyzesLoaded: state => state.soilAnalyzes_loaded
  },
  mutations: {
    [ALL_SOIL_ANALYZES]: (state, soilAnalyzes) => {
      state.soilAnalyzes = soilAnalyzes;
    },

    [SOIL_ANALYZES_LOADING]: (state, status) => {
      state.soilAnalyzesLoaded = !status;
    },

    [CREATE_SOIL_ANALYZES]: (state, soilAnalyzes) => {
      state.soilAnalyzes = soilAnalyzes;
    },
  },
  actions: {
    async getSoilAnalyzes({ commit }) {
      commit(SOIL_ANALYZES_LOADING, true);
      const response = await SoilAnalyzesApi.getSoilAnalyzes();
      commit(ALL_SOIL_ANALYZES, response.data);
      commit(SOIL_ANALYZES_LOADING, false);
      return response.data;
    },

    async updateSoilAnalyzes({ commit }, payload) {
      commit(SOIL_ANALYZES_LOADING, true);

      const response = await SoilAnalyzesApi.updateSoilAnalyzes(payload.data, payload.id);
      //commit("updateEntry", { id: payload.id, data: response.data });

      commit(SOIL_ANALYZES_LOADING, false);

      return response.data;
    },

    async createSoilAnalyzes({ commit }, payload) {
      commit(SOIL_ANALYZES_LOADING, true);
      const response = await SoilAnalyzesApi.createSoilAnalyzes(payload.data);
      commit(CREATE_SOIL_ANALYZES, { soilAnalyzes: response.data });
      commit(SOIL_ANALYZES_LOADING, false);
      return response.data;
    },
  },
};
