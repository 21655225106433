<template>
  <header class="navbar p-0 align-items-stretch">
    <div class="col-md-3 d-flex align-items-center h-100">
      <a class="navbar-brand col-5 pl-0 pr-0 d-block text-center h-100" href="#">
        <!-- <img v-if="(window.location.hostname).include('agrinity')" :src="logoAgrinity" class="align-middle logo" alt="logo agrinity" />
        <img v-else :src="logoScopix" class="align-middle logo" alt="logo scopix" /> -->
        <img :src="logo" class="align-middle logo" alt="logo" />
      </a>
      <b-button id="global-filters" variant="primary">
        <div v-if="getCampaign">{{ $t("global_filters.campaign") }} {{ getCampaign.name }}</div>
        <div v-else>{{ $t('global_filters.no_campaign') }}</div>

        <span v-if="currentFarm">{{ displayFarm(currentFarm) }}</span>
        <span v-else>{{ $t("global_filters.all_farms") }}</span>
      </b-button>
      <b-popover :show.sync="showFilters" target="global-filters" placement="bottom" triggers="hover focus">
        <h6>{{ $t("global_filters.campaign") }}</h6>

        <b-dropdown :text="getCampaign ? $t('global_filters.campaign') + ' ' + getCampaign.name : $t('global_filters.no_campaign')" class="pl-0 pr-0">
          <b-dropdown-item :to="{ name: 'campaign.init' }">
            <!-- <img :src="plusIcon" class="btn-icon" :alt="$t('new_campaign.header_button')" /> -->
            {{ $t("global_filters.add_campaign") }}</b-dropdown-item
          >
          <b-dropdown-item @click="onSelectCampaign(campaign)" v-for="campaign in campaigns" :key="campaign.id">
            {{ $t("global_filters.campaign") }} {{ campaign.name }}
          </b-dropdown-item>
        </b-dropdown>

        <h6 class="mt-3">{{ $t("global_filters.farm") }}</h6>

        <b-dropdown :text="currentFarm ? currentFarm.name : $t('global_filters.all_farms_short')" class="pl-0 pr-0 custom-dropdown">
          <b-dropdown-item @click="showFilters = false" :to="{ name: 'farm.new' }">
            {{ $t("global_filters.add_farm") }}
          </b-dropdown-item>
          <b-dropdown-item v-if="currentFarm" @click="showFilters = false" :to="{ name: 'farm.edit-current' }">
            {{ $t("global_filters.edit_current_farm") }}
          </b-dropdown-item>
          <b-dropdown-item
            @click="
              setCurrentFarm(null);
              showFilters = false;
            "
          >
            {{ $t("global_filters.all_farms_short") }}</b-dropdown-item
          >
          <hr/>
          <!-- search form -->
          <div class="px-4">
            <b-input-group size="sm" class="mb-2 input-group-border">
              <b-input-group-prepend>
                <img :src="searchIcon" class="w-100" alt="filter search" />
              </b-input-group-prepend>
              <b-form-input
                v-model="query"
                type="search"
                :placeholder="$t('search')"
              ></b-form-input>
            </b-input-group>
          </div>
          <b-dropdown-item
            @click="
              setCurrentFarm(farm.id);
              showFilters = false;
            "
            v-for="farm in farmResults"
            :key="farm.id"
          >
           {{ farm.name }} - <span class="text-secondary">{{ farm.pacage }}</span> 
          </b-dropdown-item>
        </b-dropdown>
      </b-popover>
    </div>
    <div class="col-8 p-0">
      <ul class="nav d-flex align-items-stretch pt-2">
        <li
          v-for="(link, index) in links"
          :key="index"
          class="text-center d-flex align-items-stretch"
          :class="{ 'hide-active': link.route == 'home' && $route.name != 'home' }"
        >
          <!-- if we have already a vue route -->
          <router-link v-if="link.route" :to="{ name: link.route }">{{ $t(link.display) }}</router-link>

          <!-- otherwise (temporary disabled links) -->
          <a v-else href="javascript:void(0)">{{ $t(link.display) }}</a>
        </li>
      </ul>
    </div>
    <div class="col-1 d-flex justify-content-end align-items-center">
      <a class="nofication-icon" @click="showNotificationMenu" ref="notificationIcon">
        <img src="@/assets/images/notification.svg"/>
      </a>
      <a v-if="$userCan('menu.admin')" v-bind:href="getAdminRoute()" class="header-icon">
        <img src="@/assets/images/header_icon_folder.svg" />
      </a>
      <b-dropdown right variant="null" class="header-icon">
        <!-- Using 'button-content' slot -->
        <template v-slot:button-content>
          <img src="@/assets/images/header_icon_portrait.svg" />
        </template>
        <b-dropdown-item href="/deconnexion">{{ $t("login.logout") }}</b-dropdown-item>
        <b-dropdown-item href="https://download.teamviewer.com/QS"> {{$t('header.remote_support_tool')}} </b-dropdown-item>
        <b-dropdown-item href="/password-change/">{{ $t('header.change_password') }}</b-dropdown-item>
      </b-dropdown>
    </div>
    <div v-if="showNotifMenu" ref="notifContainer">
        <Notification  />
    </div>
    <SplashScreen />
  </header>
</template>

<script>

import logoAgrinity from "@/assets/images/logo-agrinity.svg";
import logoScopix from "@/assets/images/logo.svg";
import plusIcon from "@/assets/images/plus-sign-to-add.svg";
import searchIcon from "@/assets/images/filter-search.svg";
import { EventBus } from "@/eventBus.js";
import Fuse from "fuse.js";

import { cookieIsDefined } from "@/utils/commons";

import { mapGetters, mapActions, mapMutations } from "vuex";
import { SELECT_CAMPAIGN } from "@/store/modules/campaign";
import Notification from "./Notification.vue";
import SplashScreen from "@/components/commons/SplashScreen.vue";

const isProd = process.env.NODE_ENV === "production";
const logo = window.location.hostname.includes('agrinity') ? logoAgrinity : logoScopix;

export default {

  data: () => ({
    logo,
    plusIcon,
    searchIcon,
    query: "",
    showFilters: false,
    showUserMenu: false,
    links: [],
    showNotifMenu: false,
  }),
  async created() {
    await this.fetchFarms();

    let hasFarmSelected = false;
    if (cookieIsDefined("farm")) {
      let farm = this.$cookies.get("farm");
      hasFarmSelected = true;
      await this.setCurrentFarm(farm);
    }

    // Initialisation de la liste des liens des différents thèmes
    this.refreshRouteLinks();

    await this.initializeCampaigns();

    await this.fetchUser();
    await this.fetchCustomer();

    this.setGlobalFiltersLoaded();

    // event to tell the filters are loaded on page initialization
    EventBus.$emit("globalFiltersLoaded", { hasFarmSelected: hasFarmSelected });

    // use to hide global filters when we click outside
    EventBus.$on("hideGlobalFilters", () => (this.showFilters = false));

    // Set default cropfield view
    EventBus.$on("setCropfieldView", this.refreshRouteLinks)
  },
  methods: {
    ...mapActions({
      setCurrentCampaign: "setCurrentCampaign",
      setCurrentFarm: "farm/setCurrentFarm",
      fetchFarms: "farm/fetchFarms",
      fetchUser: "user/fetchUser",
      fetchCustomer: "user/fetchCustomer",
    }),
    ...mapActions(["getAllNews", "setClosedNews", "setVueNews"]),
    ...mapMutations({
      setGlobalFiltersLoaded: "user/setGlobalFiltersLoaded",
    }),
    onSelectCampaign(value) {
      this.setCurrentCampaign(value);
      this.showFilters = false;
    },
    getAdminRoute() {
      return isProd ? "/admin/" : "http://localhost:8000/admin/";
    },
    displayFarm(farm) {
      if (farm.pacage == farm.name) return this.$t("global_filters.farm_number") + " " + farm.pacage;
      return farm.name;
    },
    async initializeCampaigns() {
      return this.$store.dispatch("getAllCampaigns").then((data) => {
        // if we have campaign cookie, then get it
        if (this.$cookies.isKey("campaign")) {
          const campaignId = parseInt(this.$cookies.get("campaign"));

          const currentCampaign = this.campaigns.find((c) => c.id == campaignId);

          if (currentCampaign) {
            this.setCurrentCampaign(currentCampaign);
          } else {
            this.setCurrentCampaign(data[data.length - 1]);
          }
        } else {
          this.setCurrentCampaign(data[data.length - 1]);
        }
      });
    },
    refreshRouteLinks() {
      // Détermine si on affiche les parcelles en mode liste ou carte
      // Mode liste par défaut
      let cropfield_route = "cropfield.list"
      if (cookieIsDefined("last_cropfield_view")) {
        let cropfield_view = this.$cookies.get("last_cropfield_view");
        if (cropfield_view === "map") {
          cropfield_route = "cropfield.map"
        } else if (cropfield_view === "list") {
          cropfield_route = "cropfield.list"
        }
      }

      this.links = [
        { route: "home", display: "header.menu.dashboard" },
        { route: "activity.list", display: "header.menu.activities" },
        { route: cropfield_route, display: "header.menu.parcels" },
        { route: "inputStock.list", display: "header.menu.inputs" },
        { route: "yield.list", display: "header.menu.crops" },
        { route: "machines.list", display: "header.menu.equipments" },
        { route: "location.list", display: "header.menu.locations" },
        { route: "analysis", display: "header.menu.analysis" },
      ];
    },

    showNotificationMenu(){
      this.showNotifMenu = !this.showNotifMenu
    },

    handleClickOutside(event) {
      if (!this.showNotifMenu) return;
      
      if (this.$refs.notificationIcon && this.$refs.notificationIcon.contains(event.target)) {
        return;
      }

      if (this.$refs.notifContainer && this.$refs.notifContainer.contains(event.target)) {
        return;
      }

      this.showNotifMenu = false;
    },
  },
  computed: {
    ...mapGetters(["campaigns", "getCampaign"]),
    ...mapGetters({
      user: "user/user",
      farms: "farm/farms",
      currentFarm: "farm/currentFarm",
      allNews: "allNews",
      upgradeNews: "upgradeNews"
    }),

    // list of farms returned from search, possibly empty list
    farmResults() {
      if (this.query == "") return this.farms;

      const fuse = new Fuse(this.farms, {
        keys: ["name", "pacage"],
        // see https://fusejs.io/api/options.html#basic-options
        ignoreLocation: true,
        threshold: 0,
      });

      return fuse.search(this.query).map((i) => i.item);
    },
  },
  watch: {
    // when we select a new farm, initialize campaigns again and query search
    currentFarm() {
      this.initializeCampaigns();
      this.query = "";
    }
  },
  components: {
    Notification,
    SplashScreen
  },
  async mounted() {
    document.addEventListener('click', this.handleClickOutside);
    await this.getAllNews();
        this.newsInterval = setInterval(async () => {
        await this.getAllNews();
        }, 300000);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
    clearInterval(this.newsInterval);
  }
};
</script>
<style lang="scss" scoped>
header {
  height: $headerHeight;
  background: $primary-color;
  // useful to display it above some modals
  z-index: 150;

  img.logo {
    width: 7rem;
  }

  .btn-icon {
    width: 0.8em;
    margin-right: 10px;
  }

  .nav {
    height: 100%;

    li {
      font-size: 0.8rem;
    }
  }

  a {
    border-radius: 15px 15px 0 0;
    padding: 0.8em 1.2em 1em 1.2em;
    display: flex;
    align-items: center;
  }
  a,
  a:hover,
  .hide-active .router-link-active,
  .hide-active .router-link-active:hover {
    color: $reverse-color;
    background: transparent;
  }

  a:hover,
  .hide-active .router-link-active:hover {
    background: $primary-color-light;
    text-decoration: none;
  }

  .router-link-active,
  .router-link-active:hover {
    background: $secondary-color;
    font-weight: bold;
  }

  .header-icon,
  .header-icon:hover {
    background: transparent;
  }
  .header-icon img {
    width: 30px;
  }

  #global-filters {
    background: transparent;
    border-color: $grey;
    line-height: 1;
    text-align: left;
  }
  #global-filters span {
    font-size: 0.7em;
  }
}
.custom-dropdown /deep/ .dropdown-menu{
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.nofication-icon {
  z-index: 150;
  cursor: pointer;
}

.notifMenu {
  position: absolute;
  z-index: 999;
  right: 0.5%;
  top: 100%;
  height: 90vh;
  width: 40vh;
}

.twoblock {
  justify-content: space-evenly;
  display: flex;
  height: 1%;
}

.blueBlock {
  width: 2vh;
  height: 100%;
  background-color: $primary-color;
}

.mainBodyNotif {
  background-color: $primary-color;
  border-radius: 20px;
  height: 100%;
  width: 100%;
}

.textNotifMenu {
  text-align: center;
  align-content: center;
  height: 8%;
  font-size: 1.2rem;
  color: white;
}

.cadreNotif {
  background-color: $primary-color-light;
  margin-right: 5%;
  margin-left: 5%;
  height: 91%;
  border-radius: 15px;
  overflow-y: hidden;
}
</style>
