<template>
  <b-overlay :show="treatment && treatment.status == 'pending' && !treatment.hasTimeout" rounded="sm">
    <div v-if="treatment">
      <!-- close button -->
      <div class="details-buttons">
        <backButton :on-click="onBack" />
      </div>
      <div>
        <!-- treatment's title -->
        <b-container class="p-0 mb-4">
          <b-row no-gutters>
            <b-col cols="auto" class="pr-3">
              <h3 class="title-form">
                {{ title }}
              </h3>
            </b-col>
          </b-row>
        </b-container>

        <!-- treatment's form parts (all components) -->
        <b-container v-if="treatment" class="mb-3">
          <b-row>
            <b-col cols="12">
              <!-- template slot for the form parts -->
              <slot></slot>
            </b-col>
          </b-row>
        </b-container>

        <!-- treatment's logs (if there is one) -->
        <b-container>
          <b-row class="mt-4">
            <b-col v-if="treatment.status && (logs || treatment.hasTimeout)" cols="12">
              <div class="mt-2 mb-4">
                <div class="bg-white text-infos logs" ref="logs">
                  <div v-html="logs"></div>
                  <div v-if="treatment.hasTimeout" class="text-danger">
                    {{ $t("commons.treatments.timeout") }}
                  </div>
                  <div ref="endLogs"></div>
                </div>
              </div>
            </b-col>

            <!-- action buttons (start, export, finish etc.) -->
            <b-col cols="12">
              <b-button class="button-default" v-if="treatment.status != 'success'" @click="startTreatment">
                {{ startButton }}
              </b-button>

              <a
                :href="treatment.exportedFile.fileObject"
                class="btn button-default mt-4"
                v-else-if="treatment.exportedFile && treatment.status == 'success'"
              >
                {{ downloadButton }}
              </a>

              <b-button class="button-default" v-else @click="onClose">
                {{ finishButton }}
              </b-button>
            </b-col>
          </b-row>
        </b-container>
        <ModalReport :minimal="true" :downloadBlob="this.downloadBlob" ref="modalReport"></ModalReport>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { i18n } from "@/i18n.js";

import { EventBus } from "@/eventBus.js";

import backButton from "@/components/commons/BackButton.vue";

import { SyncTreatment, AsyncTreatment } from "@/models/treatments/Treatment";
import ModalReport from "@/components/commons/treatments/ModalReport.vue";

const AU = require("ansi_up");
const ansi_up = new AU.default();

export default {
  data() {
    return {
      logs: null,
      treatment: null,
      showModal: false,
      downloadUrl: "",
      downloadBlob: null,
    };
  },
  /**
   * will inject treatment data to children
   */
  provide() {
    // by default 'provide' data is not reactive
    // this workaround makes it reactive
    const treatmentData = {};
    Object.defineProperty(treatmentData, "treatment", {
      enumerable: true,
      get: () => this.treatment,
    });
    return { treatmentData };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // build the treatment object based on props
      if (this.async) {
        // async treatment
        this.treatment = new AsyncTreatment();
        this.treatment.taskName = this.taskName;
        this.treatment.taskCheckInterval = this.taskCheckInterval;
        this.treatment.taskTimeout = this.taskTimeout;
      } else {
        // sync treatment
        this.treatment = new SyncTreatment();
        this.treatment.isDirectDownload = this.isDirectDownload;
        this.treatment.endpoint = this.endpoint;
      }

      // commons parameters
      this.treatment.customValidation = this.customValidation;

      this.treatment.exportAllowedFormats = this.exportAllowedFormats;
      this.treatment.exportAllowedOptions = this.exportAllowedOptions;
      this.treatment.uploadFileAllowedFormats = this.uploadFileAllowedFormats;

      this.treatment.onTaskFail = this.onTaskFail;
      this.treatment.onFetch = this.onFetch;
      this.treatment.onTreatmentDone = this.onTreatmentDone;
    },
    async startTreatment() {
      // when treatment start, first we check all data is valid
      if (!this.treatment.validateForm()) return;

      // then we start treatment (api call)
      let result = await this.treatment.start();
      if (result) {
        this.showModal = true;
        this.downloadBlob = new Blob([result.data], {
          type: "text/html",
        });

        this.downloadUrl = (window.URL ? URL : webkitURL).createObjectURL(this.downloadBlob);
        this.$refs.modalReport.modalAddReport();
      }
    },
    onTaskFail() {
      EventBus.$emit("toast", {
        message: this.treatment.failMessage,
        variant: "danger",
      });
    },
    onFetch() {
      // display logs with ansi colors
      let logs = this.treatment.logs;
      logs = ansi_up.ansi_to_html(logs);
      logs = logs.replace(/\n/g, "<br />");
      this.logs = logs;
      let container = this.$refs.logs;
      this.$nextTick(() => {
        if (container) container.scrollTop = container.scrollHeight;
      });
    },
  },
  props: {
    taskName: {
      type: String,
      default: "",
    },
    endpoint: {
      type: Function,
      default: () => {},
    },
    async: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: () => i18n.t("commons.treatments.title"),
    },
    startButton: {
      type: String,
      default: () => i18n.t("commons.treatments.start"),
    },
    downloadButton: {
      type: String,
      default: () => i18n.t("commons.treatments.download"),
    },
    finishButton: {
      type: String,
      default: () => i18n.t("commons.treatments.finish"),
    },
    failMessage: {
      type: String,
      default: () => i18n.t("commons.treatments.fail"),
    },
    onBack: {
      type: Function,
      default: () => {},
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    onTreatmentDone: {
      type: Function,
      default: () => {},
    },
    customValidation: {
      type: Function,
      default: () => {
        return true;
      },
    },
    exportAllowedFormats: {
      type: Array,
      default: () => [],
    },
    exportAllowedOptions: {
      type: Array,
      default: () => [],
    },
    exportAllowedOptions: {
      type: Array,
      default: () => [],
    },
    uploadFileAllowedFormats: {
      type: Array,
      default: () => [],
    },
    isDirectDownload: {
      type: Boolean,
      default: false,
    },
    taskCheckInterval: {
      type: Number,
      default: 2000,
    },
    taskTimeout: {
      type: Number,
      default: 60000,
    },
  },
  components: {
    backButton,
    ModalReport,
  },
};
</script>

<style lang="scss" scoped>
.btn {
  white-space: normal;
}

.logs {
  max-height: 250px;
  overflow-y: scroll;
}
</style>

<style lang="scss">
.custom-file-label {
  white-space: nowrap;
  overflow: hidden;
}
</style>
