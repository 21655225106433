import NewsApi from "@/api/news.api";
import NotificationApi from "@/api/notification.api";

export const GET_ALL_NEWS = "GET_ALL_NEWS";
export const GET_ALL_NOTIFICATION = "GET_ALL_NOTIFICATION";

export default {
  state: {
    allNews: [],
    allNotification: [],
    upgradeNews: [],
    notificationActivity: [],
  },
  getters: {
    allNews: state => state.allNews,
    allNotification: state => state.allNotification,
    upgradeNews: state => state.upgradeNews,
    notificationActivity: state => state.notificationActivity,
  },
  mutations: {
    [GET_ALL_NEWS]: (state, payload) => {
        //filter avec STOCK, NEWS, OTHER
      state.allNews = payload.filter(n => n && n.closed == false && n.category !== "NEWS");
      state.upgradeNews = payload.filter(n => n.category === "NEWS")
    },

    [GET_ALL_NOTIFICATION]: (state, payload) => {
      state.allNotification = payload.filter(n => n && n.closed == false);
    },

    removeClosedNews: (state, payload) => {
      state.allNews = state.allNews.filter(news => news.id !== payload);
    },

    setReadtoNews: (state, payload) => {
      if(state.allNews.find(news => news.id === payload)){
        state.allNews.find(news => news.id === payload).readed = true
      } else {
        state.upgradeNews.find(news => news.id === payload).readed = true
      }
    }
  },
  actions: {
    async getAllNews({ commit }) {
      //commit(PRODUCT_LOADING, true);
      const response = await NewsApi.getNews();
      commit(GET_ALL_NEWS, response.data);
      //commit(PRODUCT_LOADING, false);
      return response.data;
    },

    async setVueNews({commit}, payload){
        await NewsApi.updateNews(payload, { readed: true });
        await commit("setReadtoNews", payload)
    },

    async setClosedNews({commit}, payload){
        await NewsApi.updateNews(payload, { closed: true });
        commit("removeClosedNews", payload)
    },
    
    async getAllNotification({commit}, payload){
        const response = await NotificationApi.getNotifications({
            campaign: payload.campaign,
            farm_id: payload.farm_id,
        })
        commit(GET_ALL_NOTIFICATION, response.data)

        return response.data
    },

    async getNotificationActivity({}, payload){
        const response = await NotificationApi.getNotifications({
            campaign: payload.currentCampaign.id,
            farm_id: payload.currentFarm && payload.currentFarm.id,
            activity: payload.currentEntry.id
        })
        return response.data
    },

    async setVueNotification({commit}, payload){
         await NotificationApi.updateNotification(payload, { readed: true });
    },

    async setClosedNotification({commit}, payload){
         await NotificationApi.updateNotification(payload, { closed: true });
    },
  },
};
