import FertilizationDetail from "./FertilizationDetail.vue";
import CommonViewIndex from "@/views/common/CommonViewIndex.vue";
import CommonViewList from "@/views/common/CommonViewList.vue";
import CommonViewFiltering from "@/views/common/CommonViewFiltering.vue";
import CommonViewDetail from "@/views/common/CommonViewDetail.vue";
import { generateDetailViews } from "@/views/common/details/commons";


import { config } from "./config";

export default [
  {
    component: CommonViewIndex,
    path: "/cropfield/fertilization",
    props: config,
    children: [
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering
        },
        path: "",
        name: "fertilization.list",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: FertilizationDetail,
        },
        path: ":fertilization_id",
        name: "fertilization.details",
      },
    ],
  },
];
