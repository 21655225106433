import { DateTime } from "luxon";

import { EventBus } from "@/eventBus.js";

import { storeDispatch, storeCommit } from "@/views/common/details/commons";

import {
  tourDetail,
  tourDuplicate,
  tourEdit,
  tourNew,
  tourNewEdit
} from "./view";

const routes = {
  detail: "tour.details",
  new: "tour.new",
  edit: "tour.edit",
  list: "tour.list",
  duplicate: 'tour.duplicate'
};

const init = async ({ route }) => {
  let id = route.params.tour_id;
  storeCommit("tour/resetEditedEntry");
  if (id) await storeDispatch("tour/getOneTour", { id });
};
const storeEditAction = "tour/setEditedEntryValue";
const storeReadGetter = "tour/currentEntry";
const storeEditedGetter = "tour/editedEntry";

const onBack = ({ entry, router, isEditMode }) => {
  if (router.history.current.name == "tour.new.edit") {
    router.push({ name: "tour.new" });
    return;
  }
  if (router.history.current.name == "tour.edit") {
    router.push({ name: "tour.details", params: { tour_id: entry.id } });
    return;
  }
  onClose({ entry, router });
};
const onEdit = ({ entry, router }) => router.push({ name: routes.edit, params: { tour_id: entry.id } });

const onPin = async({ entry, router }) => {
  EventBus.$emit("detailLoadingOn")
  await storeDispatch(storeEditAction, { isTemplate: !entry.isTemplate });
  await storeDispatch("tour/updateEditedEntry");
  EventBus.$emit("detailLoadingOff")
};

const onDuplicate = async({ entry, router }) => {
  EventBus.$emit("detailLoadingOn")
  
  router.push({ name: routes.duplicate, params: { tour_id: entry.id } })

  EventBus.$emit("detailLoadingOff")
};

const onClose = ({ entry, router }) => router.push({ name: routes.list });
const goToDetail = ({ entry, router }) => router.push({ name: routes.detail, params: { tour_id: entry.id } });
const goToList = ({ router }) => router.push({ name: routes.list });

const commonParams = {
  storeReadGetter: storeReadGetter,
  storeEditAction: storeEditAction,
  storeEditedGetter: storeEditedGetter,
  onInit: init,
  onBack: onBack,
  onPin: onPin,
  onDuplicate: onDuplicate,
  onEdit: onEdit,
  onClose: onClose,
  goToList: goToList,
  goToDetail: goToDetail,
};

export const views = [
  {
    path: ":tour_id",
    name: "tour.details",
    view: tourDetail,
    isEditMode: () => false,
    showPinButton: () => true,
    showDuplicateButton: () => true,
    showEditButton: () => true,
    showBackButton: () => false,
    showCloseButton: () => true,
    ...commonParams,
  },
  {
    path: ":tour_id/edit",
    name: "tour.edit",
    view: tourEdit,
    isEditMode: () => true,
    showBackButton: () => true,
    showCloseButton: () => false,
    ...commonParams,
  },
  {
    path: "new",
    name: "tour.new",
    view: tourNew,
    isEditMode: () => true,
    showBackButton: () => true,
    showCloseButton: () => false,
    ...commonParams,
  },
  {
    path: "duplicate/:tour_id",
    name: "tour.duplicate",
    view: tourDuplicate,
    isEditMode: () => true,
    showBackButton: () => true,
    showCloseButton: () => false,
    ...commonParams,
  },
  {
    path: "new/:groupCode",
    name: "tour.new.edit",
    view: tourNewEdit,
    isNewMode: () => true,
    isEditMode: () => true,
    showBackButton: () => true,
    showCloseButton: () => false,
    ...commonParams,
  },
];
