import PeriodsApi from "@/api/periods.api";

export const ALL_PERIODS = "GET ALL PERIODS";
export const PERIODS_LOADING = "LOAD PERIODS";

export default {
  state: {
    periods: [],
    periods_loaded: true,
  },
  getters: {
    periods: state => state.periods,
    periodsLoaded: state => state.periods_loaded
  },
  mutations: {
    [ALL_PERIODS]: (state, periods) => {
      state.periods = periods;
    },

    [PERIODS_LOADING]: (state, status) => {
      state.periods_loaded = !status;
    },
  },
  actions: {
    async getPeriods({ commit }) {
      commit(PERIODS_LOADING, true);
      const response = await PeriodsApi.getPeriods();
      commit(ALL_PERIODS, response.data);
      commit(PERIODS_LOADING, false);
      return response.data;
    },
    async getSortedPeriods({ commit }, payload) {
      commit(PERIODS_LOADING, true);
      const response = await PeriodsApi.getSortedPeriods(payload);
      commit(PERIODS_LOADING, false);
      return response.data;
    },
  },
};
