import Gateway from "./base";

export default class SeedTreatmentApi {
    /**
     * Get All seedTreatments by cropType_id
     * @param cropType_id
     */
    static getSeedTreatments(cropTypes_id) {
        return Gateway.get("/refapi/productAliases/seedtreatments/", {cropType_id: cropTypes_id.join(",")});
    }
}