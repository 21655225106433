<template>
  <!-- Treatment Component : common checkbox list -->
  <!-- Displays a selector -->
  <div class="mt-4">
    <h4 class="subtitle-form mb-2">
      {{ title }}
    </h4>
    <div class="bg-white text-infos">
      <!-- checkbox "Select all" -->
      <b-form-checkbox v-model="allSelected" :indeterminate="indeterminate" @change="toggleAll">
        {{ allLabel }}
      </b-form-checkbox>

      <!-- checkboxes item -->
      <b-form-checkbox-group
        v-model="choice"
        :options="options"
        name="choice"
        class="ml-3"
        stacked
      ></b-form-checkbox-group>
    </div>
  </div>
</template>
<script>
import TreatmentFormPartMixin from "@/components/commons/treatments/TreatmentFormPartMixin";
import { TreatmentFormPart } from "@/models/treatments/Treatment.js";

const ALL = "all";

export default {
	mixins: [TreatmentFormPartMixin],
	data() {
    return {
      // the selected item(s)
      choice: [],
      // two variables to store the 'select all' state
      // see the watcher below
      allSelected: false,
      indeterminate: false,
			storeOptions: [],
    };
  },
	props: {
		storeAction: {
			type: String,
			default: ""
		},
    storeActionParams: {
      type: Object,
      default: () => {}
    },
		name: {
			type: String,
			default: ""
		},
		title: {
			type: String,
			default: ""
		},
		allLabel: {
			type: String,
			default: ""
		},
    preselectedOptions: {
      type: Array,
      default: () => []
    }
	},
	async mounted() {
    this.storeOptions = await this.$store.dispatch(this.storeAction, this.storeActionParams);
    // S'il y a des options préselectionnées, elles sont cochées
    if (this.preselectedOptions.length > 0) {
      this.choice.push(...this.preselectedOptions);
    } else {
      // Sinon, on coche toutes les options
      this.choice = this.options.map((e) => e.value);
    }
  },
  methods: {
    validateForm() {
      this.isValid = true;

      return new TreatmentFormPart(this.name, this.choiceForPayload);
    },
    // when we click on "select all" button
    toggleAll(checked) {
      this.choice = checked ? this.options.map((e) => e.value) : [];
    },
  },
	computed: {
    // List of items for the checkboxes
    options() {
      return this.storeOptions.map((e) => {
        if(Array.isArray(e))
          return {text: e[1], value: e[0]};
        else if (typeof e === 'object')
          return { text: e.designation, value: e.id };
        else
          return {text: null, value: null}
      }).filter(e => e.value != null);
    },
    // format the selected items for the api payload
    choiceForPayload() {
      return this.choice.length == this.options.length
        ? ALL
        : this.choice.filter((e) => e != undefined).join(",");
    },
  },
  watch: {
    // this is used to update the "select all" button state depending on what we do
    choice(newValue, oldValue) {
      // case where no item is selected -> "select all" is unchecked
      if (newValue.length === 0) {
        this.indeterminate = false;
        this.allSelected = false;
        // case where all items are selected -> "select all" is checked
      } else if (newValue.length === this.options.length) {
        this.indeterminate = false;
        this.allSelected = true;
        // otherwise, "select all" is half-checked (a crossing line)
      } else {
        this.indeterminate = true;
        this.allSelected = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>

</style>