import { i18n } from "@/i18n.js";

import { t, comp, choice, container, storeGet, storeDispatch, storeCommit } from "@/views/common/details/commons";

import { addDuration } from "@/utils/commons";
import { intervalMinutes } from "@/utils/commons";

const storeEditAction = "tour/setEditedEntryValue";

/* Read-only Detail View */
export const tourDetail = async ({ entry }) => {

  await storeDispatch("getDoseUnits");

  return {
    title: entry.activityType_designation,
    subtitle: entry.status_designation,
    actions: {
      delete: ({ entry }) => storeDispatch("tour/deleteEntry", { id: entry.id }),
    },
    rows: [
      /* Activity Type */
      comp({ component: "ActivityType", title: i18n.t("commons.list.activity_type") }),
      /* Crop list */
      comp({ component: "CropsList", title: i18n.t("commons.list.crops") }),
      /* Cropfields */
      comp({ component: "CropfieldList", title: i18n.t("commons.list.cropfields") }),
      /* Stock Store */
      comp({
        component: "FieldInfo",
        title: i18n.t("inputStock.details.stock_store"),
        props: { value: entry.stockStore_name },
        condition: () => entry.stockStore_name
      }),
      /* Dates */
      comp({ component: "TourDates", title: i18n.t("tours.details.dates") }),
      /* GrowthStage */
      comp({
        component: "FieldInfo",
        title: i18n.t("commons.list.growth_stage"),
        props: { value: entry.growthStage_designation },
        condition: () => entry.activityType_needGrowthStage
      }),
      /* Inputs */
      comp({
        component: "Inputs",
        title: i18n.t("components.Inputs.title"),
        props: { showVolumes: true },
        condition: () => entry.activityType_needInputs
      }),
      /* Targets */
      comp({
        component: "FieldInfo",
        title: i18n.t("commons.list.targets"),
        props: { value: entry.target_list?.map((t) => t.designation).join(", ") },
        condition: () => entry.activityType_needTargets
      }),
      /* Reason */
      comp({
        component: "FieldInfo",
        title: i18n.t("commons.list.reasons"),
        props: { value: entry.reason_designation },
        condition: () => entry.reason_designation
      }),
      /* Machines */
      comp({ component: "Machines", title: i18n.t("commons.list.machines") }),
      /* Opérateur */
      comp({
        component: "FieldInfo",
        title: i18n.t("activities.details.operator"),
        props: { value: entry.operator_fullName },
        condition: () => entry.operator
      }),
      /* Editable from tracker ? */
      comp({
        component: "FieldInfo",
        title: i18n.t("tours.details.editable"),
        props: { value: i18n.t("tours.details.is_editable_from_tracker") },
        condition: () => entry.editable,
      }),
      /* Validation automatique des activités */
      comp({
        component: "FieldInfo",
        title: i18n.t("tours.details.auto_confirm_title"),
        props: { value: i18n.t("tours.details.auto_confirm_text") },
        condition: () => entry.autoConfirmTour,
      }),
      /* Comment */
      comp({
        component: "FieldInfo",
        title: i18n.t("tours.details.comment"),
        props: { value: entry.comment },
        condition: () => entry.comment,
      }),
      /* Nom de la tournée */
      comp({
        component: "FieldInfo",
        title: i18n.t("tours.details.name"),
        props: { value: entry.name },
        condition: () => entry.name,
      }),
    ],
  };
};

/* Edit Detail View */
export const tourEdit = async ({ entry, editedEntry, title, subtitle, newMode, route }) => {

  // need to fetch stock stores here, so we can hide the component if no result in list
  await storeDispatch("stockStores/fetchStockStores");

  await storeDispatch("tour/getAllInputForm");

  // need to fetch operators here, so we can hide the component if no result in list
  await storeDispatch("operators/fetchOperators");

  await storeDispatch("getDoseUnits");

  if(newMode) {
    await storeDispatch(storeEditAction, {
      startDate: new Date(),
      endDate: addDuration({ startDate: new Date(), minutes: 5 * 24 * 60 })
    })
  }

  return {
    title: title || entry.activityType_designation,
    subtitle: subtitle || entry.status_designation,
    actions: {
      save: async () => await storeDispatch("tour/updateEditedEntry"),
      delete: ({ entry }) => storeDispatch("tour/deleteEntry", { id: entry.id }),
    },
    rows: [
      /* Activity Type */
      comp({ component: "EditActivityType", title: i18n.t("commons.list.activity_type") }),
      /* Crop list */
      comp({ component: "CropsList", title: i18n.t("commons.list.crops") }),
      /* Stock store */
      comp({
        component: "EditStockStore",
        title: i18n.t("inputStock.details.stock_store"),
        props: {
          filterCropfields: true
        },
        condition: () => storeGet("stockStores/stockStores").length > 0 && ( storeGet("tour/editedEntry")?.activityType_needInputs === true || storeGet("tour/currentEntry")?.activityType_needInputs === true)
      }),
      /* Cropfields */
      comp({ component: "EditCropfields"}),
      /* Start Date */
      comp({
        component: "EditDate",
        title: i18n.t("tours.list.start_date"),
        cols: 6,
        props: {
          value: storeGet("tour/editedEntry").startDate ? new Date(storeGet("tour/editedEntry").startDate) : new Date(entry.startDate),
          field: "startDate",
          disabled: entry.status && entry.status != "NS" && route.name !== "tour.duplicate",
          onChange: (startDate, oldStartDate) => {
            const current = storeGet("tour/currentEntry")
            const endDate = storeGet("tour/editedEntry").endDate || storeGet("tour/currentEntry").endDate;
            const currentDate = new Date(); 
            const startDateObj = new Date(startDate);
            
            // Calculer la différence entre la date actuelle et la nouvelle date de départ en jours
            const timeDifferenceInDays = (startDateObj - currentDate) / (1000 * 60 * 60 * 24);
          
            // Déterminer la durée à ajouter en fonction de la différence/
            let daysToAdd;
            if(Object.keys(current).length > 1){

              const initialEndDateObj = new Date(endDate);
              const initialStartDateObj = new Date(storeGet("tour/currentEntry").startDate)
              const timeDifferenceEndStart = (initialEndDateObj - initialStartDateObj) / (1000 * 60 * 60 * 24)
              daysToAdd = Math.ceil(timeDifferenceEndStart)
            } else {
              if (timeDifferenceInDays < 0) {
                daysToAdd = 1
              }  else if (timeDifferenceInDays < 14) {
                daysToAdd = 5; // Moins de 15 jours
              } else if (timeDifferenceInDays <= 59) {
                daysToAdd = 7; // Entre 15 jours et 2 mois
              } else {
                daysToAdd = 14; // Plus de 2 mois
              }
            }

            // Convertir les jours en minutes pour addDuration
            const minutesToAdd = daysToAdd * 24 * 60;
          
            // Modifier la date de fin
            storeDispatch(storeEditAction, {
              endDate: addDuration({ startDate: startDateObj, minutes: minutesToAdd })
            });
          }
        },
      }),
      /* End Date */
      comp({
        component: "EditDate",
        title: i18n.t("tours.details.end_date"),
        cols: 6,
        props: {
          // by default, add current date + 5 days
          value: storeGet("tour/editedEntry").endDate ? new Date(storeGet("tour/editedEntry").endDate) : new Date(entry.endDate),
          field: "endDate",
          // verify the end date is after the start date
          onValidate: (input, endDate) => {
            const startDate = storeGet("tour/editedEntry").startDate || storeGet("tour/currentEntry").startDate
            if(startDate >= endDate) {
              input.setCustomValidity(i18n.t("tours.validation.end-date-before-start"))
              input.reportValidity()
            } else {
              input.setCustomValidity("")
            }
          }
        },
      }),
      /* Growth Stage */
      comp({
        component: "EditActivityGrowthStage",
        title: i18n.t("commons.list.growth_stage"),
        condition: () => (storeGet("tour/editedEntry")?.activityType_needGrowthStage === true || storeGet("tour/currentEntry")?.activityType_needGrowthStage === true) && storeGet("tour/inputForm")?.growthStages?.length > 0
      }),
      /* Inputs */
      comp({
        component: "EditInputs",
        title: i18n.t("commons.list.inputs"),
        props: { inputFormPath: "tour/inputForm", showVolumes: true },
        condition: () => storeGet("tour/editedEntry")?.activityType_needInputs === true || storeGet("tour/currentEntry")?.activityType_needInputs === true
      }),
      /* Reasons */
      comp({
        component: "EditReasons",
        title: i18n.t("components.EditReasons.title"),
        props: { inputFormPath: "tour/inputForm" },
        condition: () => (storeGet("tour/editedEntry")?.activityType_needTargets === true || storeGet("tour/currentEntry")?.activityType_needTargets === true)
      }),
      /* Targets */
      comp({
        component: "EditTargets",
        title: i18n.t("commons.list.targets"),
        props: { inputFormPath: "tour/inputForm" },
        condition: () => storeGet("tour/editedEntry")?.activityType_needTargets === true || storeGet("tour/currentEntry")?.activityType_needTargets === true
      }),
      /* Editable from tracker ? */
      comp({
        component: "EditCheckbox",
        title: "",
        props: {
          value: entry.editable,
          field: "editable",
          label: i18n.t("tours.details.is_editable_from_tracker"),
        },
      }),
      /* Validation automatique des activités */
      comp({
        component: "EditCheckbox",
        title: "",
        props: {
          value: entry.autoConfirmTour,
          field: "autoConfirmTour",
          label: i18n.t("tours.details.auto_confirm_title"),
          helpText: i18n.t("tours.details.auto_confirm_help_text")
        },
        condition: () => storeGet("tour/editedEntry")?.activityType_needInputs === true || storeGet("tour/currentEntry")?.activityType_needInputs === true
      }),
      /* Machines */
      comp({
        component: "EditMachines",
        title: i18n.t('components.EditMachines.title'),
        props: {
          showSubcontracted: false,
        }
      }),
      /* Opérateurs */
      comp({
        component: "EditOperator",
        title: i18n.t("activities.details.operator"),
        condition: () => storeGet("operators/operators").length > 0
      }),
      /* Comment */
      comp({
        component: "EditTextarea",
        title: i18n.t('components.Comments.title'),
        props: {
          value: entry.comment,
          field: "comment",
          placeholder: i18n.t('components.Comments.placeholder')
        },
      }),
      /* Nom de la tournée */
      comp({
        component: "EditText",
        title: i18n.t("tours.details.name"),
        props: {
          value: entry.name,
          field: "name",
          max: 50
        },
      })
    ],
  };
};

/* New View
   Display the activity family picker
   Then the edit view
*/
export const tourNew = ({ entry, editedEntry }) => {
  storeCommit("tour/resetEditedEntry");
  storeCommit("tour/resetCurrentId");

  return {
    title: i18n.t("tours.add.title"),
    rows: [comp("ActivityFamilyPicker")],
  };
};

/* New view (2)
   Actually returns the normal edit view
   But with the activity family selected
*/
export const tourNewEdit = async ({ entry, editedEntry, route }) => {
  const groupCode = route.params.groupCode;

  storeCommit("tour/resetEditedEntry");
  storeCommit("tour/resetCurrentId");
  storeDispatch(storeEditAction, {
    groupCode: groupCode
  });
  
  const group = storeGet("activityTypeGroups").find(group => group.code === groupCode) || null

  const title = i18n.t("tours.add.title");

  const subtitle = i18n.t("tours.add.subtitle_prefix") + " " +  group.designation;

  return tourEdit({ entry, editedEntry, title, subtitle, newMode: true, route });
};

export const tourDuplicate = async ({ entry, editedEntry, route }) => {
  storeCommit("tour/resetEditedEntry");
  storeCommit("tour/resetCurrentId");

  const tourId = parseInt(route.params.tour_id);

  const tour = storeGet("tour/entries").find(e => e.id == tourId);

  await storeDispatch(storeEditAction, {
    ...tour,
    targetsHydrated: tour.target_list,
    id: null,
    startDate: null,
    endDate: null,
    createdAt: null,
    updatedAt: null,
    isTemplate: false,
  });


  const title = i18n.t("tours.add.title");

  return tourEdit({ entry, editedEntry, title, newMode: true, route });
};
