<template>
  <div class="container-fluid">
    <b-row class="container p-0">
      <b-col cols="8" class="m-0 pr-0" @click="update">
        <b-row v-if="lastSupplyDate">
            <b-col cols="10" class="p-0 text-right">
                <span>{{ lastSupplyDate }}</span>
            </b-col>
            <b-col cols="2"></b-col>
            <b-col cols="10" class="pl-0 pr-0 text-right">
              <span>{{ lastSupplyPrice }} €</span>
            </b-col>
            <b-col cols="2"></b-col>
        </b-row>
        <b-row v-else>
          <b-col cols="10" class="p-0 text-right">
               {{ $t('is_empty') }}
          </b-col>
          <b-col cols="2"></b-col>
        </b-row>
      </b-col>
      <b-col cols="4" class="m-0">
        <button 
          v-b-tooltip.hover
          :title="$t('inputStock.list.register_new_supply')" 
          type="button" 
          class="btn btn-light pt-0 mt-0" 
          @click="onClick">
          <img src="@/assets/images/plus_add_supply.svg"/>
        </button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { EventBus } from "@/eventBus.js";
import { dateToSlashString } from "@/utils/commons";
import { mapGetters } from "vuex";

export default {
  props: ["rowId", "rowData"],
  computed: {
    lastSupplyDate() {
      if(this.rowData.lastSupplyDate)
        return dateToSlashString( new Date(this.rowData.lastSupplyDate));
      return ;
    },
    lastSupplyPrice() {
      return this.rowData.lastSupplyPrice;
    }
  },
  methods: {

    /**
     * @description ouverture du formulaire d'ajout d'un approvisionnement dans le panneau de détail
     */
		async onClick() {
			if (this.$route.params['inputStock_id'] !== this.rowId) {
				let params = {};
				params['inputStock_id'] = this.rowId;
        params['mode'] = "addSupply";
				await this.$router.push({ name: "inputStock.details", params: params });
      }else{
        EventBus.$emit("addSupply", { show: true });
      }
		},

    /**
     * @description ouverture du formulaire de modification du dernier approvisionnement
     */
    async update(){
      if (this.$route.params['inputStock_id'] !== this.rowId) {
				let params = {};
				params['inputStock_id'] = this.rowId;
        params['mode'] = "updateSupply";

				await this.$router.push({ name: "inputStock.details", params: params });
      }else{
        EventBus.$emit("updateSupply", {show: true});
      }
    }
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 18px;
  
}
button {
	margin-left: 10px;
  margin-top: -5px
}

</style>
