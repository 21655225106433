<template>
  <div>
    <b-row>
      <b-col class="col-8">
        <h4 class="mb-3">
          <i>Historique de la parcelle</i>
        </h4>
      </b-col>
    </b-row>

    <!--Previous Crop-->
    <b-row> 
      <b-col class="col-8" >
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.previousCrop_name">{{$t('cropfields.fertilization.needsCalculation.previousCrop')}}</h4>
          <p class="text-infos bg-white">
            <span>{{ previousCrop_name ? previousCrop_name : "/" }}</span>
          </p>
      </b-col>
      <!--buriedStraw -->
      <b-col class="col-4 subtitle-form" v-if="fertilisationNeedsCalculationOption.buriedStraw_show" >
        <h4 class="subtitle-form mb-2"  v-b-tooltip.hover.html="fertiToolTips.buriedStraw">{{$t('cropfields.fertilization.needsCalculation.buriedStraw')}}</h4>
        <b-form-checkbox size="sm" v-model="buriedStraw" @input="updateBuriedStraw()" :disabled="!edit_mode"/>
      </b-col>

    </b-row>

    
    <b-row class="mb-1">
      <!--luzerneTurnedInSpring-->
      <b-col class="col-6" v-if="fertilisationNeedsCalculationOption.luzerneTurnedInSpring_show">
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.luzerneTurnedInSpring">{{$t('cropfields.fertilization.needsCalculation.luzerneTurnedInSpring')}}</h4>
        <b-form-checkbox size="sm" v-model="luzerneTurnedInSpring" @input="updateLuzerneTurnedInSpring()" :disabled="!edit_mode"/>
      </b-col>

      <!--luzerneTwoYearsAgo-->
      <b-col class="col-6" >
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.luzerneTwoYearsAgo">{{$t('cropfields.fertilization.needsCalculation.luzerneTwoYearsAgo')}}</h4>
        <b-form-checkbox size="sm" v-model="luzerneTwoYearsAgo" @input="updateLuzerneTwoYearsAgo()" :disabled="!edit_mode"/>
      </b-col>

      <!--fsystOtherfeatures-->
      <b-col v-for="(factor, index) in fertilisationNeedsCalculationOption.fsystOtherFactors" :key="index" class="col-6">
        <h4 class="subtitle-form mb-2">{{ factor }}</h4>
        <b-form-checkbox size="sm" :value="factor" v-model="selectedFactors" @change="updateFSysFactors()" :disabled="!edit_mode" />
      </b-col>
    </b-row>

    <!--previousCropIsFallow -->
    <b-row v-if="this.fertilisationNeedsCalculationOption.fallowCrop_show">
      <!--fallowCropFamily-->
      <b-col class="col-6" >
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.fallowCropFamily">{{$t('cropfields.fertilization.needsCalculation.fallowCropFamily')}}</h4>
        <template v-if="edit_mode">
        <b-form-select
          :options="this.fertilisationNeedsCalculationList.fallowCropFamilies"
          value-field="code"
          text-field="designation"
          v-model="fallowCropFamily"
          size="sm"
          @change="updateFallowCropFamily()"
        />
      </template>
      <template v-else>
          <p class="text-infos bg-white">
            <span> {{ this.fertilisationNeedsCalculation.fallowCropFamily_designation ? this.fertilisationNeedsCalculation.fallowCropFamily_designation : "-" }} </span>
         </p>
        </template>
      </b-col>

      <!--fallowDestructionAges-->
      <b-col class="col-6" >
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.fallowDestructionAge">{{$t('cropfields.fertilization.needsCalculation.fallowDestructionAge')}}</h4>
        <template v-if="edit_mode">
        <b-form-select
          value-field="code"
          text-field="designation"
          :options="this.fertilisationNeedsCalculationList.fallowDestructionAges"
          v-model="fallowDestructionAge"
          @change="updateFallowDestructionAge()"
          size="sm"
        />
      </template>
      <template v-else>
          <p class="text-infos bg-white">
            <span> {{ this.fertilisationNeedsCalculation.fallowDestructionAge ? this.fertilisationNeedsCalculation.fallowDestructionAge : "-" }} </span>
         </p>
        </template>
      </b-col>

      <!--fallowDestructionPeriods-->
      <b-col class="col-6" >
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.fallowDestructionPeriod">{{$t('cropfields.fertilization.needsCalculation.fallowDestructionPeriod')}}</h4>
        <template v-if="edit_mode">
        <b-form-select
          :options="this.fertilisationNeedsCalculationList.fallowDestructionPeriods"
          value-field="code"
          text-field="designation"
          v-model="fallowDestructionPeriod"
          size="sm"
          @change="updateFallowDestructionPeriod()"
        />
      </template>
      <template v-else>
          <p class="text-infos bg-white">
            <span> {{ this.fertilisationNeedsCalculation.fallowDestructionPeriod_designation ? this.fertilisationNeedsCalculation.fallowDestructionPeriod_designation : "-" }} </span>
         </p>
      </template>
      </b-col>
    </b-row>

     <!--organicFertilizerFrequency--> 
     <b-row>
      <b-col class="col-6" >
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.organicFertilizerFrequency">{{$t('cropfields.fertilization.needsCalculation.organicFertilizerFrequency')}}</h4>
        <template v-if="edit_mode">
        <b-form-select
          :options="this.fertilisationNeedsCalculationList.organicFertilizerFrequencies"
          value-field="code"
          text-field="designation"
          v-model="organicFertilizerFrequency"
          size="sm"
          @change="updateOrganicFertilizerFrequency()"
        />
      </template>
      <template v-else>
          <p class="text-infos bg-white">
            <span> {{ this.fertilisationNeedsCalculation.organicFertilizerFrequency_designation ? this.fertilisationNeedsCalculation.organicFertilizerFrequency_designation : "-" }} </span>
         </p>
        </template>
      </b-col>

      <!--organicFertilizerGroups-->
      <b-col class="col-6" > 
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.organicFertilizerGroup">{{$t('cropfields.fertilization.needsCalculation.organicFertilizerGroup')}}</h4>
        <template v-if="edit_mode">
        <b-form-select
          value-field="code"
          text-field="designation"
          :options="this.fertilisationNeedsCalculationList.organicFertilizerGroups"
          v-model="organicFertilizerGroup"
          @change="updateOrganicFertilizerGroup()"
          size="sm"
          :disabled="organicFertilizerFrequency === '001'"
        />
      </template>
      <template v-else>
          <p class="text-infos bg-white">
            <span> {{ this.fertilisationNeedsCalculation.organicFertilizerGroup_designation ? this.fertilisationNeedsCalculation.organicFertilizerGroup_designation : "-" }} </span>
         </p>
        </template>
      </b-col>
    </b-row>

     <!--resdiusespracticise-->
    <b-row>
     <b-col class="col-6" >
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.residuesPratice">{{$t('cropfields.fertilization.needsCalculation.residuesPratice')}}</h4>
        <template v-if="edit_mode">
        <b-form-select
          value-field="code"
          text-field="designation"
          :options="this.fertilisationNeedsCalculationList.residuesPratices"
          v-model="residuesPratice"
          @change="updateResiduesPratice()"
          size="sm"
        />
      </template>
      <template v-else>
          <p class="text-infos bg-white">
            <span> {{ this.fertilisationNeedsCalculation.residuesPratice_designation ? this.fertilisationNeedsCalculation.residuesPratice_designation : "-" }} </span>
         </p>
        </template>
      </b-col>
    </b-row>

    <hr v-if="this.previousMeadow"/>
    
    <b-row class="mb-1">
      <!--previousMeadow-->
      <b-col class="col-6" >
          <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.previousMeadow">{{$t('cropfields.fertilization.needsCalculation.previousMeadow')}}</h4>
          <b-form-checkbox size="sm" v-model="previousMeadow" @input="updatePreviousMeadow()" :disabled="!edit_mode"/>
      </b-col>

      <!--meadowTurnedYearAndPeriod-->
      <b-col class="col-6" v-if="this.previousMeadow">
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.meadowTurnedYearAndPeriod">{{$t('cropfields.fertilization.needsCalculation.meadowTurnedYearAndPeriod')}}</h4>
        <template v-if="edit_mode">
        <b-form-select
          :options="this.fertilisationNeedsCalculationList.meadowTurnedYearsAndPeriods"
          v-model="meadowTurnedYearAndPeriod"
          size="sm"
          @change="updateMeadowTurnedYearAndPeriod()"
        />
      </template>
      <template v-else>
          <p class="text-infos bg-white">
            <span> {{ this.fertilisationNeedsCalculation.meadowTurnedYearAndPeriod ? this.fertilisationNeedsCalculation.meadowTurnedYearAndPeriod : "-" }} </span>
         </p>
        </template>
      </b-col>

      <!--meadowSpecies-->
      <b-col class="col-6" v-if="this.previousMeadow" >
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.meadowSpecies">{{$t('cropfields.fertilization.needsCalculation.meadowSpecies')}}</h4>
        <template v-if="edit_mode">
        <b-form-select
          :options="this.fertilisationNeedsCalculationList.meadowSpecies_list"
          v-model="meadowSpecies"
          size="sm"
          @change="updateMeadowSpecies()"
        />
      </template>
      <template v-else>
          <p class="text-infos bg-white">
            <span> {{ this.fertilisationNeedsCalculation.meadowSpecies ? this.fertilisationNeedsCalculation.meadowSpecies : "-" }} </span>
         </p>
        </template>
      </b-col>

      <!--meadowOperatingMode-->
      <b-col class="col-6" v-if="this.previousMeadow" >
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.meadowOperatingMode">{{$t('cropfields.fertilization.needsCalculation.meadowOperatingMode')}}</h4>
        <template v-if="edit_mode">
        <b-form-select
          :options="this.fertilisationNeedsCalculationList.meadowOperatingModes"
          v-model="meadowOperatingMode"
          size="sm"
          @change="updateMeadowOperatingMode()"
        />
      </template>
      <template v-else>
          <p class="text-infos bg-white">
            <span> {{ this.fertilisationNeedsCalculation.meadowOperatingMode ? this.fertilisationNeedsCalculation.meadowOperatingMode : "-" }} </span>
         </p>
        </template>
      </b-col>

      <!--meadowAge-->
      <b-col class="col-6" v-if="this.previousMeadow" >
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.meadowAge">{{$t('cropfields.fertilization.needsCalculation.meadowAge')}}</h4>
        <template v-if="edit_mode">
        <b-form-select
          :options="this.fertilisationNeedsCalculationList.meadowAges"
          v-model="meadowAge"
          size="sm"
          @change="updateMeadowAge()"
        />
      </template>
      <template v-else>
          <p class="text-infos bg-white">
            <span> {{ this.fertilisationNeedsCalculation.meadowAge ? this.fertilisationNeedsCalculation.meadowAge : "-" }} </span>
         </p>
        </template>
      </b-col>

      <!--Mhp-->
      <b-col class="col-6" v-if="this.previousMeadow" >
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.Mhp">{{$t('cropfields.fertilization.needsCalculation.Mhp')}}</h4>
        <p class="text-infos bg-white">
          <span> {{ this.Mhp !== null ? this.Mhp : " - " }} </span>
        </p>
      </b-col>
    </b-row>     

  </div>
</template>

<script>

export default {
  name: "fertilization-story",
  data(){
    return{
      edit_mode: false,
      previousCrop_name: "",
      buriedStraw: false,  
      fallowDestructionAge: null,
      fallowCropFamily: null,
      fallowDestructionPeriod: null,
      organicFertilizerFrequency: null,
      organicFertilizerGroup: null,
      residuesPratice: null,
      selectedFactors: [],
      fsystOtherFeatures: "",
      luzerneTurnedInSpring: false,
      luzerneTwoYearsAgo: false,
      previousMeadow: false,
      meadowTurnedYearAndPeriod: null,
      meadowSpecies: null,
      meadowOperatingMode: null, 
      meadowAge: null,
      Mhp: null
    } 
  },
  created() {
    this.init();
  },
  props: [
    'editMode', 
    'fertilisationNeedsCalculation', 
    'fertilisationNeedsCalculationList', 
    'fertilisationNeedsCalculationOption',
    'fertiToolTips',
  ],
  methods: {
    init: async function() {
      this.buriedStraw = this.fertilisationNeedsCalculation.buriedStraw
      this.luzerneTurnedInSpring = this.fertilisationNeedsCalculation.luzerneTurnedInSpring
      this.luzerneTwoYearsAgo = this.fertilisationNeedsCalculation.luzerneTwoYearsAgo
      this.previousMeadow = this.fertilisationNeedsCalculation.previousMeadow
      await this.loadData();
    },
    async loadData(){
      if(typeof this.fertilisationNeedsCalculation != 'undefined') {
        await this.editCheck()
        this.previousCrop_name = this.fertilisationNeedsCalculation.previousCrop_name
        this.fallowDestructionAge = this.fertilisationNeedsCalculation.fallowDestructionAge
        this.fallowCropFamily = this.fertilisationNeedsCalculation.fallowCropFamily
        this.fallowDestructionPeriod = this.fertilisationNeedsCalculation.fallowDestructionPeriod
        this.organicFertilizerFrequency = this.fertilisationNeedsCalculation.organicFertilizerFrequency
        this.organicFertilizerGroup = this.fertilisationNeedsCalculation.organicFertilizerGroup
        this.residuesPratice = this.fertilisationNeedsCalculation.residuesPratice
        this.fsystOtherFeatures = this.fertilisationNeedsCalculation.fsystOtherFactors
        this.meadowTurnedYearAndPeriod = this.fertilisationNeedsCalculation.meadowTurnedYearAndPeriod
        this.meadowSpecies = this.fertilisationNeedsCalculation.meadowSpecies
        this.meadowOperatingMode = this.fertilisationNeedsCalculation.meadowOperatingMode
        this.meadowAge = this.fertilisationNeedsCalculation.meadowAge
        this.Mhp = this.fertilisationNeedsCalculation.Mhp
        if(this.fsystOtherFeatures){
          const elements = this.fsystOtherFeatures.split(',');
          this.selectedFactors = elements.map(element => element.trim());
        }
      }
    },
    async editCheck(){
      this.edit_mode = this.editMode
    },
    async updateBuriedStraw(){
      if(this.edit_mode){
        await this.$store.dispatch("fertilization/addAtributeChange", { name: "buriedStraw", value: this.buriedStraw });
      }
    },
    async updateFallowDestructionAge(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "fallowDestructionAge", value: this.fallowDestructionAge });
    },
    async updateFallowCropFamily(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "fallowCropFamily", value: this.fallowCropFamily });
    },
    async updateFallowDestructionPeriod(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "fallowDestructionPeriod", value: this.fallowDestructionPeriod });
    },
    async updateOrganicFertilizerFrequency(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "organicFertilizerFrequency", value: this.organicFertilizerFrequency });
    },
    async updateOrganicFertilizerGroup(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "organicFertilizerGroup", value: this.organicFertilizerGroup });
    },
    async updateResiduesPratice(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "residuesPratice", value: this.residuesPratice });
    }, 
    async updateFSysFactors(){
      this.fsystOtherFeatures = this.selectedFactors.join(",");
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "fsystOtherFactors", value: this.fsystOtherFeatures });
    },
    async updateLuzerneTurnedInSpring(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "luzerneTurnedInSpring", value: this.luzerneTurnedInSpring });
    },
    async updateLuzerneTwoYearsAgo(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "luzerneTwoYearsAgo", value: this.luzerneTwoYearsAgo });
    },
    async updatePreviousMeadow(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "previousMeadow", value: this.previousMeadow });
    },
    async updateMeadowTurnedYearAndPeriod(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "meadowTurnedYearAndPeriod", value: this.meadowTurnedYearAndPeriod });
    },
    async updateMeadowSpecies(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "meadowSpecies", value: this.meadowSpecies });
    },
    async updateMeadowAge(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "meadowAge", value: this.meadowAge });
    },
    async updateMeadowOperatingMode(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "meadowOperatingMode", value: this.meadowOperatingMode });
    },
  },
  watch: {
    fertilisationNeedsCalculation: {
      handler: 'loadData',
      immediate: true
    },
    editMode: {
      handler: 'editCheck',
      immediate: true
    }
  }
}; 

</script>

<style>
.disabled-input:disabled {
  background-color: white !important;
  border: none;
}
</style>