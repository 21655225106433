import { i18n } from "@/i18n.js";

export const config = {
  title: i18n.t("machines.title"),
  countingElements: i18n.t("machines.list.countingElements"),
  addButton: i18n.t("machines.list.add"),
  module: "machine",
  scrollToSelection: true,
  searchField: "model",
  routes: {
    index: {
      name: "machines",
    },
    list: {
      name: "machines.list",
    },
    new: {
      name: "machines.new",
    },
    details: {
      name: "machines.details",
    },
    params: {
      id: "machines_id",
    },
  },
  defaultPivot: 1,
  columns: {
    0: {
      name: "model",
      sort: true,
      title: i18n.t("machines.list.model"),
      isAuthorized: false,
      isAvailable: false,
      hideOnDetails: false,
      pivot: {
        enable: true,
        groupDisplay: {},
        displayHeader: false,
      },
      show: true,
      priority: 9,
    },
    1: {
      name: "machineType_designation",
      sort: true,
      title: i18n.t("machines.list.equipment_type"),
      isAuthorized: false,
      isAvailable: false,
      hideOnDetails: false,
      pivot: {
        enable: true,
        groupDisplay: {},
        displayHeader: true,
      },
      show: true,
      priority: 9,
    },
    2: {
      name: "width",
      sort: false,
      title: i18n.t("machines.list.width"),
      cellComponent: "WidthAndUnit",
      isAuthorized: true,
      isAvailable: false,
      hideOnDetails: false,
      pivot: {
        enable: true,
        groupDisplay: {},
        displayHeader: true,
      },
      show: true,
      priority: 9,
    },
    3: {
      name: "motorized",
      sort: true,
      title: i18n.t("machines.list.motorized"),
      cellDisplay: {
        true: i18n.t("yes"),
        false: i18n.t("no"),
      },
      isAuthorized: false,
      isAvailable: true,
      hideOnDetails: false,
      // on indique quel composant on veut pour gérer l'affichage
      // plus poussé que le cellDisplay
      // ne pas oublier d'importer le composant dans <CommonViewList/>
      pivot: {
        enable: true,
        groupDisplay: {
          true: i18n.t("machines.list.values.motorized.motorized_machines"),
          false: i18n.t("machines.list.values.motorized.tools"),
        },
        sortHeaderField: [group => group.name.toLowerCase()],
        sortHeaderOrder: ["asc"],
        displayHeader: true,
      },
      show: true,
      priority: 9,
      orderByKey: "machineType_designation",
    },
    4: {
      name: "maintenance_lastdate",
      sort: true,
      title: i18n.t("machines.list.maintenance_lastdate"),
      cellComponent: "LastMaintenance",
      isAuthorized: false,
      isAvailable: true,
      hideOnDetails: false,
      pivot: {
        enable: false,
        groupDisplay: {},
        displayHeader: true,
      },
      show: true,
      priority: 9,
    },
  },
  filters: [
    {
      name: "machineType",
      show: false,
      title: i18n.t("machines.filter.equipment_type"),
    },
  ],
};
