<template>
  <div>
    <!-- Product usages -->
    <div v-if="currentProduct && currentProduct.productUsages && currentProduct.productFamily_code == 'phyto'">
      <h4 class="subtitle-form pt-2">Usage du produit</h4>

      <table>
        <!-- Title -->
        <tr>
          <div class="box-infos-input-form title-table-input-form">
            <th class="content-left-input-form">Cultures associées</th>
            <th class="content-center-input-form">Lutte</th>
            <th class="content-right-input-form">Dosage max</th>
          </div>
        </tr>
        <tr v-for="usage in currentProduct.productUsages" v-bind:key="usage.id" :value="usage.id">
          <!-- Crop, against, dose and appropriate bg-color -->
          <div
            :class="{
              'box-infos-input-form': true,
              'text-infos': true,
              'td-usage': usage.endOfUsage === null,
              'bg-danger': usage.endOfUsage !== null && Date.now() > new Date(usage.endOfUsage),
              'bg-warning': Date.now() <= new Date(usage.endOfUsage),
            }"
          >
            <td class="content-left-input-form">
              {{ usage.cropType_name }}
            </td>
            <td class="content-center-input-form">
              {{ usage.against }}
            </td>
            <td class="content-right-input-form">{{ usage.maxValue }} {{ usage.doseUnit_unit }}</td>
          </div>

          <!-- Tooltip info -->
          <td>
            <div :id="usage.id">
              <img :src="icon" alt="icon info action" />
            </div>
            <b-popover
              :target="usage.id.toString(10)"
              @show="getInstructions(usage.id)"
              triggers="hover"
              placement="top"
            >
              <template v-slot:title>
                <span
                  :class="{
                    'usage-authorized': usage.endOfUsage === null,
                    'endOfUsage-danger': usage.endOfUsage !== null && Date.now() > new Date(usage.endOfUsage),
                    'endOfUsage-warning': Date.now() <= new Date(usage.endOfUsage),
                  }"
                >
                  {{ checkEndOfUsage(usage.endOfUsage) }}
                </span>
              </template>
              <table>
                <!-- bbchMin -->
                <tr>
                  <td>
                    <b> Stade bbch min </b>
                  </td>
                  <td class="text-right">
                    {{ usage.bbchMin | empty($t("is_empty")) }}
                  </td>
                </tr>

                <!-- bbchMax -->
                <tr>
                  <td>
                    <b> Stade bbch max </b>
                  </td>
                  <td class="text-right">
                    {{ usage.bbchMax | empty($t("is_empty")) }}
                  </td>
                </tr>

                <!-- end Of Distrib -->
                <tr>
                  <td>
                    <b> Date de fin de distribution </b>
                  </td>
                  <td class="text-right">
                    {{ checkDate(usage.endOfDistrib) }}
                  </td>
                </tr>

                <!-- end Of Usage -->
                <tr>
                  <td>
                    <b> Date de fin d'utilisation </b>
                  </td>
                  <td class="text-right">
                    {{ checkDate(usage.endOfUsage) }}
                  </td>
                </tr>

                <!-- days Before Harvest -->
                <tr>
                  <td>
                    <b> Nombre de jours avant récolte </b>
                  </td>
                  <td class="text-right">
                    {{ usage.daysBeforeHarvest | empty($t("is_empty")) }}
                  </td>
                </tr>

                <!-- max Nb Of Application -->
                <tr>
                  <td>
                    <b> Nombre max d'applications </b>
                  </td>
                  <td class="text-right">
                    {{ usage.maxNbOfApplication | empty($t("is_empty")) }}
                  </td>
                </tr>

                <!-- Fractionable -->
                <tr>
                  <td>
                    <b> Fractionnable </b>
                  </td>
                  <td class="text-right" v-show="usage.fractionable">
                    Oui
                  </td>
                  <td class="text-right" v-show="!usage.fractionable">
                    Non
                  </td>
                </tr>

                <!-- Interval -->
                <tr v-show="usage.interval != null">
                  <td>
                    <b> Intervalle min entre applications </b>
                  </td>
                  <td class="text-right">
                    {{ usage.interval }}
                  </td>
                </tr>

                <!-- ZNT aquatique -->
                <tr v-show="usage.waterSafetyDistance != null">
                  <td>
                    <b> {{ $t("inputStock.details.water_safety_distance") }} </b>
                  </td>
                  <td class="text-right">
                    {{ usage.waterSafetyDistance }}
                  </td>
                </tr>

                <!-- ZNT plantes non cibles -->
                <tr v-show="usage.nonTargetSafetyDistance != null">
                  <td>
                    <b> {{ $t("inputStock.details.non_target_safety_distance") }} </b>
                  </td>
                  <td class="text-right">
                    {{ usage.nonTargetSafetyDistance }}
                  </td>
                </tr>

                <!-- Instructions -->
                <tr v-show="currentInstructions">
                  <td align="justify" colspan="2">
                    {{ currentInstructions }}
                  </td>
                </tr>
              </table>
            </b-popover>
          </td>
        </tr>
      </table>
      <h4 class="subtitle-form pt-2 source-info">{{ currentProduct.infoSource }}</h4>
    </div>

    <br />
  </div>
</template>

<script>
import infoIcon from "@/assets/images/info.svg";
import { mapGetters } from "vuex";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";

export default {
  mixins: [DetailUpdateMixin],

  data() {
    return {
      icon: infoIcon,
      currentInstructions: "",
    };
  },
  methods: {
    async getInstructions(usageId) {
      if (!(usageId in this.productUsagesInfo)) {
        await this.$store.dispatch("getProductUsageInfo", { param: "productUsage", id: usageId });
      }
      this.currentInstructions = this.productUsagesInfo[usageId];
    },
    /**
     * Description: Retourne une String décrivant le status du produit
     * en fonction de la date renseignée.
     * @param {String}       endOfUsage Date de fin d'utilisation.
     * @return {String}
     */
    checkEndOfUsage(endOfUsage) {
      if (endOfUsage === null) return "Usage autorisé";
      else if (Date.now() > new Date(endOfUsage)) return "Usage interdit";
      else return "Usage prochainement interdit";
    },

    /**
     * Description: Retourne une date au format local.
     * @param {Sring}       date une date au format String.
     * @return {Date}            Retourne une date au format local ou "Non définie".
     */
    checkDate(date) {
      if (date === null) return "Non définie";
      else return new Date(date).toLocaleDateString();
    },
  },
  computed: {
    ...mapGetters(["currentProduct", "productUsagesInfo"]),
    ...mapGetters({
      inputsStock: "inputStock/entries",
      currentEntry: "inputStock/currentEntry",
      currentEditedEntry: "inputStock/editedEntry",
    }),
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 0.8em;
  margin-right: 10px;
  margin-left: 10px;
}
.popover {
  max-width: 400px;
}

table th {
  padding-right: 10px;
}

table {
  border-collapse: separate;
  border-spacing: 0px 5px;
}

.td-usage {
  background-color: $background-text-color;
}

.endOfUsage-danger {
  color: $danger-color;
}

.endOfUsage-warning {
  color: $dark-yellow;
}

.bg-danger {
  background-color: $danger-color;
}

.bg-warning {
  background-color: $dark-yellow;
}

.usage-authorized {
  color: $secondary-light-color;
}

.text-right {
  white-space: nowrap;
}
.source-info {
  text-align: center;
  font-style: italic;
}
</style>
