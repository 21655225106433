import * as turf from "@turf/turf";

import { project3857, project4326 } from "@/utils/geomatics";

import { EventBus } from "@/eventBus.js";

export default {
  data() {
    return {
      holeLayer: null,
      activeCropfieldHolesLayer: null,
    };
  },
  mounted() {
    if (this.minimal) return;

    EventBus.$on("plotHoles", this.plotFeatureHoles);
    EventBus.$on("resetFeatureHoles", this.resetFeatureHoles);
    EventBus.$on("enableHoleMode", this.enableHoleMode);
    EventBus.$on("disableHoleMode", this.disableHoleMode);
    EventBus.$on("deleteHole", this.deleteHole);
  },
  methods: {
    plotFeatureHoles(cropfield_id) {
      if (!this.activeCropfieldHolesLayer) return;
      if (!cropfield_id) cropfield_id = this.currentEntry.id;

      this.activeCropfieldHolesLayer.getSource().clear();
      this.activeCropfieldHolesLayer.getSource().dispatchEvent("change");

      let geom = this.geometries3857.filter(g => g.properties.id == cropfield_id);

      if (geom.length == 0) return;

      geom = geom[0];

      let t = this;

      geom.geometry.coordinates.forEach(function(ring, index) {
        if (index == 0) return;

        let poly = new ol.geom.Polygon([ring]);
        poly = new ol.Feature(poly);
        poly.set("index", index);

        let geojson = t.getGeometryFromFeature(poly);

        t.writeGeometryToLayer(geojson, t.activeCropfieldHolesLayer);
      });
    },
    resetFeatureHoles() {
      this.resetSelectedHole();
      this.activeCropfieldHolesLayer.getSource().clear();
      this.activeCropfieldHolesLayer.getSource().dispatchEvent("change");
    },
    enableHoleMode() {
      this.holeMode = true;

      if (!this.currentCropfieldHasGeometry) return;

      this.displayActiveCropfield = false;

      this.showActiveCropfield();

      let drawInteraction = new ol.interaction.Draw({
        source: this.holeLayer.getSource(),
        style: this.getDrawStyle,
        type: "Polygon",
      });

      let t = this;
      drawInteraction.on("drawend", function(e) {
        t.makeHole(e.feature);
      });

      this.map.addInteraction(drawInteraction);

      this.activeCropfieldLayer.getSource().dispatchEvent("change");
      this.activeParcelsLayer.getSource().dispatchEvent("change");
      this.layerSource.dispatchEvent("change");
      this.firstCropsLayer.getSource().dispatchEvent("change");
      this.secondCropsLayer.getSource().dispatchEvent("change");
    },
    async disableHoleMode({ save }) {
      this.holeMode = false;
      await this.removeModifyInteraction({ save });
      this.plotFeatureHoles();
    },
    makeHole(feature) {
      let geometry = this.getGeometryFromFeature(feature);

      let hole = project4326(geometry);

      let cropfield = project4326(this.getGeometryFromLayer(this.activeCropfieldLayer));

      let geojson = turf.difference(cropfield, hole);

      geojson = project3857(geojson);

      this.holeLayer.getSource().clear();
      this.writeGeometryToLayer(geojson, this.activeCropfieldLayer, true);

      //this.removeDrawInteraction();

      //this.holeMode = false;
    },
    async deleteHole() {
      if (!this.selectedHole) return;

      let newGeometry = JSON.parse(JSON.stringify(this.currentEntry.geometry));

      newGeometry.coordinates.splice(this.selectedHole, 1);

      await this.updateEntry({ id: this.currentEntry.id, data: { geometry: newGeometry } });

      this.resetSelectedHole();
      this.plotFeatureHoles(this.currentEntry.id);
    },
  },
};
