import { storeDispatch, storeCommit } from "@/views/common/details/commons";

import {
  yieldDetail,
  yieldEdit,
  yieldNew
} from "./views";

const routes = {
  detail: "yield.details",
  new: "yield.new",
  edit: "yield.edit",
  list: "yield.list"
};

const init = async ({ route }) => {
  let id = route.params.yield_id;
  storeCommit("yield/resetEditedEntry");
  if (id) await storeDispatch("yield/getOneYield", { id });
};
const storeEditAction = "yield/setEditedEntryValue";
const storeReadGetter = "yield/currentEntry";
const storeEditedGetter = "yield/editedEntry";

const onBack = ({ entry, router}) => {
  if (router.history.current.name == "yield.edit") {
    router.push({ name: "yield.details", params: { yield_id: entry.id } });
    return;
  }
  onClose({ entry, router });
};
const onEdit = ({ entry, router }) => router.push({ name: routes.edit, params: { yield_id: entry.id } });

const onClose = ({ entry, router }) => {
  storeCommit("yield/resetLastEntryDisplayed");
  router.push({ name: routes.list });
}
const goToDetail = ({ entry, router }) => router.push({ name: routes.detail, params: { yield_id: entry.id } });
const goToList = ({ router }) => router.push({ name: routes.list });

const commonParams = {
  storeReadGetter: storeReadGetter,
  storeEditAction: storeEditAction,
  storeEditedGetter: storeEditedGetter,
  onInit: init,
  onBack: onBack,
  onEdit: onEdit,
  onClose: onClose,
  goToList: goToList,
  goToDetail: goToDetail,
};

export const views = [
  {
    path: ":yield_id",
    name: "yield.details",
    view: yieldDetail,
    isEditMode: () => false,
    showBackButton: () => false,
    showCloseButton: () => true,
    ...commonParams,
  },
  {
    path: ":yield_id/edit",
    name: "yield.edit",
    view: yieldEdit,
    isEditMode: () => true,
    showBackButton: () => true,
    showCloseButton: () => false,
    ...commonParams,
  },
  {
    path: "new",
    name: "yield.new",
    view: yieldNew,
    isEditMode: () => true,
    showBackButton: () => true,
    showCloseButton: () => false,
    ...commonParams,
  },
];
