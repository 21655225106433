import AnalysisApi from "@/api/analysis.api";

export const ELAPSED_TIME_CHART_DATA = "ELAPSED TIME CHART DATA";
export const IFT_CHART_DATA = "IFT CHART DATA";
export const ANALYSIS_LOADING = "LOAD ANALYSIS";
export const CHART_TYPE = "CHART TYPE";
export const RESET_IFT_CHART_DATA = "RESET IFT CHART DATA";
export const RESET_ELAPSED_TIME_CHART_DATA = "RESET ELAPSED TIME CHART DATA";
export const ROTATED = "ROTATED";
export const INVERTED = "INVERTED";
export const TOTAL_ROW = "SHOW TOTAL ROW";
export const TOTAL_COLUMN = " SHOW TOTAL COLUMN";

export default {
	namespaced: true,
	state: {
		elapsedTimeChartData: [],
    iftChartData: [],
		analysis_loaded: true,
    chartType: "", // Définit le type de graphe
    rotated: false, // A true, inverse les axes du graphes
    inverted: false, // A true, les lignes du tableau sont affichées sur l'axe du graphe. A false, ce sont les colonnes
    totalRow: false, // A true, affiche le total par lignes
    totalColumn: false, // A true, affiche le total par colonnes
	},
	getters: {
		elapsedTimeChartData: state => state.elapsedTimeChartData,
		analysis_loaded: state => state.analysis_loaded,
    iftChartData: state => state.iftChartData,
    chartType: state => state.chartType,
    rotated: state => state.rotated,
    inverted: state => state.inverted,
    totalRow: state => state.totalRow,
    totalColumn: state => state.totalColumn,
	},
	mutations: {
		[ELAPSED_TIME_CHART_DATA]: (state, data) => {
      state.elapsedTimeChartData = data;
    },
		[ANALYSIS_LOADING]: (state, status) => {
      state.analysis_loaded = !status;
    },
		[IFT_CHART_DATA]: (state, data) => {
      state.iftChartData = data;
    },
    [CHART_TYPE]: (state, type) => {
      state.chartType = type;
    },
    [RESET_IFT_CHART_DATA]: (state) => {
      state.iftChartData = [];
    },
    [RESET_ELAPSED_TIME_CHART_DATA]: (state) => {
      state.elapsedTimeChartData = [];
    },
    [ROTATED]: (state, rotated) => {
      state.rotated = rotated;
    },
    [INVERTED]: (state, inverted) => {
      state.inverted = inverted;
    },
    [TOTAL_ROW]: (state, totalRow) => {
      state.totalRow = totalRow;
    },
    [TOTAL_COLUMN]: (state, totalColumn) => {
      state.totalColumn = totalColumn;
    },
	},
	actions: {
		async getElapsedTimeChartData({commit, rootGetters}) {
      commit(ANALYSIS_LOADING, true);
      const currentCampaign = rootGetters.getCampaign.id;
      let params = {campaign: currentCampaign};
      if (rootGetters["farm/currentFarm"]) {
        params["farm"] = rootGetters["farm/currentFarm"].id;
      }
      const response = await AnalysisApi.getElapsedTimeChartData(params);
      response.data.forEach(element => {
        element.elapsedTime = element.elapsedTime/3600;
        element.workingTime = element.workingTime/3600;
        element.activityType = element.orderInCulturalProcess + ". " + element.activityType;
      });
      commit(ELAPSED_TIME_CHART_DATA, response.data);
      commit(ANALYSIS_LOADING, false);
      return response.data;
    },

		async getIFTChartData({commit, rootGetters}) {
      commit(ANALYSIS_LOADING, true);
      const currentCampaign = rootGetters.getCampaign.id;
      let params = {campaign: currentCampaign};
      if (rootGetters["farm/currentFarm"]) {
        params["farm"] = rootGetters["farm/currentFarm"].id;
      }
      const response = await AnalysisApi.getIFTChartData(params);
      commit(IFT_CHART_DATA, response.data);
      commit(ANALYSIS_LOADING, false);
      return response.data;
    },

    setChartType({commit}, type) {
      commit(CHART_TYPE, type);
    },

    setRotated({commit}, rotated) {
      commit(ROTATED, rotated);
    },

    setInverted({commit}, inverted) {
      commit(INVERTED, inverted);
    },

    setTotalRow({commit}, totalRow) {
      commit(TOTAL_ROW, totalRow);
    },

    setTotalColumn({commit}, totalColumn) {
      commit(TOTAL_COLUMN, totalColumn);
    },

    resetIFTChartData({commit}) {
      commit(RESET_IFT_CHART_DATA);
    },

    resetElapsedTimeChartData({commit}) {
      commit(RESET_ELAPSED_TIME_CHART_DATA);
    },
	}
}