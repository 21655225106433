import Gateway from "./base";

export default class LocationApi {

  /**
   * Get All Locations
   */
  static getAllLocations(params) {
    return Gateway.get("/api/locations/", params);
  }

  /**
   * Get one location by id
   * @param id
   */
   static getLocation(id) {
    return Gateway.get(`/api/locations/${id}/`);
  }

  /**
   * Create location
   * @param data
   */
  static createLocation(data) {
    return Gateway.post(`/api/locations/`, data);
  }

  /**
   * Update location
   * @param data
   * @param id
   */
   static updateLocation(id, data) {
    return Gateway.patch(`/api/locations/${id}/`, data);
  }

  /**
   * delete location
   * @param id
   */
   static deleteLocation(id) {
    return Gateway.delete(`/api/locations/${id}/`);
  }

  /**
   * Get All Filters
   */
  static getLocationsFilters(query, campaign, farmId) {
    return Gateway.get("/api/locations/filtre/", { attrib: query, campaign: campaign, farm_id: farmId });
  }

  /**
   * Get available beacons
   */
   static getBeacons() {
    return Gateway.get(`/api/locations/beaconsavailable/`);
  }

  /**
   * Get available beacons by location id
   * @param locationId
   */
   static getBeaconsAvailable(locationId) {
    return Gateway.get(`/api/locations/${locationId}/beaconsavailable/`);
  }

}
