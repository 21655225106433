import { i18n } from "@/i18n.js";
import { dateToSlashString } from "@/utils/commons";

export const config = {
  title: i18n.t("cropfields.title"),
  // le nom marqué dans le comptage des groupe : XX intrants
  countingElements: i18n.t("cropfields.list.countingElements"),
  // "Ajouter une parcelle"
  addButton: i18n.t("cropfields.list.add"),
  // le module du store
  module: "cropfield",
  // scroll automatique à la sélection dans la liste
  scrollToSelection: true,
  // sur quel champs faire la recherche
  searchField: "name",
  searchList: [{
    object: "varieties",
    attribute: "variety_name"
  }],
  // sur ces routes, on garde la sélection des éléments cochés dans la liste
  // sinon, on la vide
  routesKeepSelection: ["cropfield.map.reports", "cropfield.reports", "cropfield.importexport", "cropfield.export"],
  // les principales routes
  routes: {
    index: {
      name: "cropfield",
    },
    list: {
      name: "cropfield.list",
    },
    map: {
      name: "cropfield.map",
    },
    new: {
      // the new route depends on the current route : if we are on the map or not
      name: (currentRoute) => (currentRoute.includes("map") ? "cropfield.map.new" : "cropfield.new"),
    },
    fertilization: {
      name: "cropfield. ",
    },
    details: {
      name: "cropfield.details",
    },
    params: {
      id: "cropfield_id",
    },
  },
  pathOptions: {},
  // sur quelle colonne groupe-t-on par défaut ?
  defaultPivot: 4,
  // la liste des colonnes
  checkbox: true,
  // comment trier les lignes ?
  rowSort: {
    key: "name",
    type: "string",
    order: 1, // 1 si ordre croissant, -1 sinon
  },
  columns: {
    0: {
      name: "name",
      sort: true,
      width: 12,
      title: i18n.t("cropfields.list.name"),
      hideOnDetails: false,
      cellComponent: "CropfieldNameCustom",
      editable: {
        cellComponent: "EditableCellText",
      },
      pivot: {
        enable: true,
        groupDisplay: {},
        displayHeader: false,
      },
      show: true,
      priority: 1,
      orderByKey: "name",
    },
    1: {
      name: "crop",
      width: 10,
      sort: true,
      title: i18n.t("commons.list.crop_name"),
      hideOnDetails: false,
      cellComponent: "CropfieldCropVariety",
      cellDisplay: {
        mode: "fromStore",
        getter: "crop/crops",
        key: "name",
      },
      pivot: {
        enable: true,
        sortHeaderField: [(group) => group.name.toLowerCase()],
        sortHeaderOrder: ["asc"],
        sortDataFields: [(cropfield) => cropfield.name.toLowerCase()],
        sortDataOrders: ["asc"],
        groupDisplay: {},
        displayHeader: true,
      },
      show: true,
      priority: 2,
      editable: {
        cellComponent: "CropDropdown",
      },
    },
    2: {
      name: "surface",
      width: 6,
      sort: false,
      title: i18n.t("cropfields.list.surface"),
      hideOnDetails: false,
      pivot: {
        enable: false,
        groupDisplay: {},
        displayHeader: false,
      },
      editable: {
        cellComponent: "EditableCellNumber",
      },
      show: true,
      priority: 1,
      generalCellDisplayClasses: "pl-4"
    },
    3: {
      name: "farm",
      sort: true,
      width: 10,
      title: i18n.t("cropfields.list.farmId"),
      hideOnDetails: false,
      cellDisplay: {
        mode: "fromStore",
        getter: "farm/farms",
        key: "name",
      },
      pivot: {
        enable: true,
        groupDisplay: {},
        displayHeader: true,
      },
      show: true,
      priority: 3,
      showColumn: function({ $userCan, store }) {
        // return $userCan('inputstock.supplies')
        if (store.getters["cropfield/onFertilizationPlan"])
          return false;
        if (store.getters["farm/currentFarm"])
          return false;
        if (store.getters["farm/farms"].length > 1)
          return true;
        return false;
      }
    },
    4: {
      name: "I_number",
      width: 6,
      sort: true,
      title: i18n.t("cropfields.list.i_number"),
      hideOnDetails: false,
      pivot: {
        enable: true,
        sortHeaderField: [(group) => group.name.toLowerCase()],
        sortHeaderOrder: ["asc"],
        sortDataFields: [(cropfield) => cropfield.name.toLowerCase()],
        sortDataOrders: ["asc"],
        sortOnRaw: true,
        groupDisplay: {
          mode: "function",
          method: (cropfield) => {
            if (!cropfield.farmIsland) return i18n.t("is_empty");
            return i18n.t("cropfields.list.island_number_x") + " " + cropfield.farmIsland;
          }
        },
        displayHeader: true,
      },
      show: true,
      priority: 5,
      cellDisplay: {
        mode: "function",
        method: (value) => {
          if (!value) return i18n.t("is_empty");
          return i18n.t("cropfields.list.island_number_x") + value;
        },
      },
      editable: {
        cellComponent: "EditableCellNumber",
      },
      showColumn: function({ $userCan, store }) {
        return !(store.getters["cropfield/onFertilizationPlan"]);
      }
    },
    5: {
			name: "sowing_date",
      width: 6,
			sort: true,
			title: i18n.t("cropfields.list.sowingDate"),
			isDate: true,
			hideOnDetails: false,
			cellDisplay: {
				mode: "function",
				method: (value) => {
					if (!value) return i18n.t("is_empty");

					return dateToSlashString(new Date(value));
				},
			},
			pivot: {
				enable: true,
				groupDisplay: {},
				displayHeader: true,
				sortHeaderField: [(group) => group.rows.length > 0 && group.rows[0].sowing_date],
				sortHeaderOrder: ["desc"],
				sortDataFields: [(cropfield) => cropfield.name.toLowerCase()],
				sortDataOrders: ["desc"],
			},
      show: true,
      priority: 7,
      showColumn: function({ $userCan, store }) {
        return !(store.getters["cropfield/onFertilizationPlan"]);
      }
		},
    6: {
      name: "expectedHarvestDate",
      width: 6,
      sort: true,
      title: i18n.t("cropfields.list.expectedHarvestDate"),
      hideOnDetails: true,
      pivot: {
        enable: false,
        groupDisplay: {},
        displayHeader: false,
      },
      show: true,
      priority: 9,
      showColumn: function({ $userCan, store }) {
        return !(store.getters["cropfield/onFertilizationPlan"]);
      }
    },
    7: {
			name: "harvestDate",
      width: 6,
			sort: true,
			title: i18n.t("cropfields.list.harvestDate"),
			isDate: true,
			hideOnDetails: false,
			cellDisplay: {
				mode: "function",
				method: (value) => {
					if (!value) return i18n.t("is_empty");

					return dateToSlashString(new Date(value));
				},
			},
			pivot: {
				enable: true,
				groupDisplay: {},
				displayHeader: true,
				sortHeaderField: [(group) => group.rows.length > 0 && group.rows[0].harvestDate],
				sortHeaderOrder: ["desc"],
				sortDataFields: [(cropfield) => cropfield.name.toLowerCase()],
				sortDataOrders: ["desc"],
			},
      show: true,
      priority: 8,
      showColumn: function({ $userCan, store }) {
        return !(store.getters["cropfield/onFertilizationPlan"]);
      }
		},
    8: {
      name: "active",
      width: 5,
      sort: true,
      title: i18n.t("cropfields.list.active"),
      hideOnDetails: true,
      pivot: {
        enable: false,
        groupDisplay: {},
        displayHeader: false,
      },
      cellDisplay: {
        true: i18n.t("cropfields.list.values.active.true"),
        false: i18n.t("cropfields.list.values.active.false"),
      },
      show: true,
      priority: 4,
      showColumn: function({ $userCan, store }) {
        return !(store.getters["cropfield/onFertilizationPlan"]);
      }
    },
    9: {
      name: "receivedInExchange",
      width: 6,
      sort: true,
      title: i18n.t("cropfields.list.receivedInExchange"),
      hideOnDetails: true,
      pivot: {
        enable: true,
        groupDisplay: {},
        displayHeader: true,
      },
      cellDisplay: {
        true: i18n.t("cropfields.list.values.active.true"),
        false: i18n.t("cropfields.list.values.active.false"),
      },
      show: true,
      priority: 10,
      showColumn: function({ $userCan, store }) {
        return !(store.getters["cropfield/onFertilizationPlan"]);
      }
    },
    10: {
      name: "givenInExchange",
      width: 6,
      sort: true,
      title: i18n.t("cropfields.list.givenInExchange"),
      hideOnDetails: true,
      pivot: {
        enable: true,
        groupDisplay: {},
        displayHeader: true,
      },
      cellDisplay: {
        true: i18n.t("cropfields.list.values.active.true"),
        false: i18n.t("cropfields.list.values.active.false"),
      },
      show: true,
      priority: 10,
      showColumn: function({ $userCan, store }) {
        return !(store.getters["cropfield/onFertilizationPlan"]);
      }
    },
  },
  // les filtres
  filters: [
    {
      name: "crop",
      title: i18n.t("commons.filter.crop_name"),
      show: false,
    },
    {
      name: "farm",
      title: i18n.t("commons.filter.farm"),
      show: false,
    },
    {
      name: "active",
      title: i18n.t("cropfields.filter.active.title"),
      show: false,
      values: [
        [true, i18n.t("cropfields.filter.active.values.true")],
        [false, i18n.t("cropfields.filter.active.values.false")]
      ]
    },
  ],
  details: {
    infos: [
      {
        name: "name",
        cols: 12,
        title: i18n.t("cropfields.list.name"),
        editComponent: "EditableCellText",
        showEditionOnly: true,
      },
      {
        name: "campaign_name",
        title: i18n.t("cropfields.list.campaign"),
      },
      {
        name: "locality",
        title: i18n.t("cropfields.list.locality")
      },
      {
        name: "surface",
        cols: 6,
        title: i18n.t("cropfields.list.cultivated_surface"),
        editComponent: "EditCellSurface",
        mergeStrategy: "sum",
      },
      {
        name: "declaredSurface",
        cols: 6,
        title: i18n.t("cropfields.list.declared_surface"),
        editComponent: "EditCellSurface",
        mergeStrategy: "sum",
      },
      // Island choice in cropfield detail : hidden for now
      // {
      //   name: "island",
      //   title: i18n.t("cropfields.list.island"),
      //   cols: 12,
      //   editComponent: "CropfieldIslandForm",
      //   showNewOnly: true,
      // },
      {
        name: "crop",
        title: i18n.t("commons.list.crop_name"),
        cols: 6,
        editComponent: "CropDropdown",
        editMany: true,
        display: {
          mode: "fromStore",
          getter: "crop/crops",
          key: "name",
        },
      },
      {
        name: "active",
        title: i18n.t("cropfields.list.active"),
        cols: 6,
        editComponent: "Checkbox",
        default: true,
        editMany: true,
        display: {
          true: i18n.t("cropfields.list.values.active.true"),
          false: i18n.t("cropfields.list.values.active.false"),
        },
      },
      {
        name: "variety",
        title: i18n.t("cropfields.list.variety"),
        editComponent: "VarietyDropdown",
        cols: 6,
        mergeStrategy: "lineBreak",
        editMany: true,
        condition: function({store}) {
          let entriesChecked = store.getters["cropfield/entriesChecked"];

          if (entriesChecked.length > 1) {
            let entries = store.getters["cropfield/checkedEntries"];
            let allSameCrop = entries.every(e => e.crop !== null && e.crop === entries[0].crop);
            if (!allSameCrop) {
              return false;
            }
          }

          let entry = store.getters["cropfield/currentEntry"];
          return entry.crop != null;
        },
        display: {
          mode: "fromAnotherModel",
        }
      },
      {
        name: "appellation",
        title: "",
        cols: 6,
        condition: function({store}) {
          let entry = store.getters["cropfield/currentEntry"];
          return entry.crop_permanent == true;
        },
        editComponent: "EditableCellText",
      },
      {
        name: "second_crop",
        title: i18n.t("cropfields.details.next_crop"),
        mergeStrategy: "lineBreak",
        editComponent: "NextCrop",
        condition: function({store}) {
          let entry = store.getters["cropfield/currentEntry"];
          return entry.nextCropFields && entry.nextCropFields.length != 0 && entry.crop_permanent != true;
        },
        display: {
          mode: "fromAnotherModel",
        }
      },
      {
        name: "first_crop",
        title: i18n.t("cropfields.details.previous_crop"),
        mergeStrategy: "lineBreak",
        editComponent: "PreviousCrop",
        condition: function({store}) {
          let entry = store.getters["cropfield/currentEntry"];
          return entry.previousCropField != null && entry.crop_permanent != true;
        },
        display: {
          mode: "fromAnotherModel",
        }
      },
      {
        name: "btnSecondCrop",
        title: i18n.t("cropfields.details.next_crop"),
        mergeStrategy: "lineBreak",
        editComponent: "NextCrop",
        condition: function({store}) {
          let entry = store.getters["cropfield/currentEntry"];
          return entry.previousCropField == null && entry.nextCropFields?.length == 0 && entry.crop_permanent != true && entry.crop != null;
        },
        display: {
          mode: "button",
        }
      },
      {
        name: "rowWidth",
        title: i18n.t("cropfields.list.rowWidth"),
        cols: 6,
        editMany: true,
        editComponent: "EditableCellNumber",
        mergeStrategy: "sum",
        condition: function({store}) {
          let entry = store.getters["cropfield/currentEntry"];
          return entry.crop_needRowWidth == true;
        },
      },
      {
        name: "underRowWidth",
        title: i18n.t("cropfields.list.under_row_width"),
        cols: 6,
        editMany: true,
        editComponent: "EditableCellNumber",
        mergeStrategy: "sum",
        condition: function({store}) {
          let entry = store.getters["cropfield/currentEntry"];
          return entry.crop_needRowWidth == true;
        },
      },
      {
        name: "expectedHarvestDate",
        title: i18n.t("cropfields.list.expectedHarvestDate"),
        editComponent: "DatePicker",
        editMany: true,
      },
      {
        name: "harvestDate",
        title: i18n.t("cropfields.list.harvestDate"),
        editComponent: "DatePicker",
        editMany: true,
      },
      {
        name: "receivedInExchange",
        title: i18n.t("cropfields.list.receivedInExchange"),
        editComponent: "Checkbox",
        display: {
          true: i18n.t("cropfields.list.values.active.true"),
          false: i18n.t("cropfields.list.values.active.false"),
        },
      },
      {
        name: "givenInExchange",
        title: i18n.t("cropfields.list.givenInExchange"),
        editComponent: "Checkbox",
        display: {
          true: i18n.t("cropfields.list.values.active.true"),
          false: i18n.t("cropfields.list.values.active.false"),
        },
      },
      {
        name: "farm",
        title: i18n.t("cropfields.list.farmId"),
        cols: 6,
        editComponent: "PacageDropdown",
        editMany: true,
        display: {
          mode: "fromStore",
          getter: "farm/farms",
          key: "name",
        },
      },
      {
        name: "I_number",
        title: i18n.t("cropfields.list.i_number"),
        cols: 3,
        editComponent: "EditableCellText",
        editMany: true,
      },
      {
        name: "P_number",
        title: i18n.t("cropfields.list.p_number"),
        cols: 3,
        editComponent: "EditableCellNumber",
      },
      {
        name: "soilType_designation",
        title: i18n.t("cropfields.list.soilType"),
        cols: 6,
        editComponent: "SoilTypeDropdown",
        editMany: true,
      },
      {
        name: "soilDepth",
        title: i18n.t("cropfields.list.soilDepth"),
        cols: 6,
        editComponent: "SoilDepthDropdown",
        editMany: true,
        display: {
          mode: "fromStore",
          getter: "cropfield/soilDepths",
          key: "designation",
        },
      },
      {
        name: "nonTreatedZone",
        title: i18n.t("cropfields.list.nonTreatedZone"),
        cols: 12,
        editComponent: "EditNonTreatedZone",
        display: {
          mode: "fromAnotherModel",
        }
      },
    ],
  },
};
