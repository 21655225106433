<template>
  <div>
    {{ cellData | datetime("dd/MM/yyyy", "fr") }}
  </div>
</template>

<script>
export default {
  props: ["rowId", "rowData"],
  computed: {
    cellData() {
      return this.rowData.doneDate;
    },
  },
};
</script>

<style lang="scss" scoped></style>
