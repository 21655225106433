export const FAMILY_CREATE_PRODUCT = {
  amend: {
    name: true,
    dose: true,
    composition: true,
    otherElements: true,
    otherFeature: true,
    risks: true,
  },
  engor: {
    name: true,
    dose: true,
    composition: true,
    otherElements: true,
    otherFeature: true,
    percentnpro: true,
    keqN: true,
  },
  engsy: {
    name: true,
    dose: true,
    composition: true,
    otherElements: true,
    otherFeature: true,
  },
  sem: {
    name: true,
    density: true,
    dose: true,
    otherFeature: true,
    seedTreatment: true,
    farmSeedCheckBox: true,
  },
  phyto: {
    usage: true,
    risks: true,
  },
  irrig: {
    name: true,
    dose: true,
    composition: true,
    otherElements: true,
  },
};
