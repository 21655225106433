import { i18n } from "@/i18n.js";

export const config = {
	title: i18n.t("analysis.title"),
	module: "analysis",
	routes: {
		index: {
			name: "analysis",
		},
		options: {
			name: "analysis.options",
		},
		params: {
			id: "analysis_id",
		},
	},

	options: [
		{
			name: "tfi",
			title: i18n.t("analysis.options.tfi"),
			chartType: "bar",
			totalColumn: false,
			totalRow: true,
			rotated: false,
			inverted: false,
			storeAction: "getIFTChartData",
			storeGetter: "iftChartData",
			show: function({ $userCan }) {return $userCan('analysis.tfi')},
		},
		{
			name: "elapsedTimeHourHa",
			title: i18n.t("analysis.options.elapsed_time_hour_ha"),
			chartType: "stackedbar",
			totalColumn: true,
			totalRow: false,
			rotated: true,
			inverted: true,
			storeAction: "getElapsedTimeChartData",
			storeGetter: "elapsedTimeChartData",
			show: function({ $userCan }) {return $userCan('analysis.time')},
		},
		{
			name: "elapsedTimeHour",
			title: i18n.t("analysis.options.elapsed_time_hour"),
			chartType: "stackedbar",
			totalColumn: true,
			totalRow: false,
			rotated: true,
			inverted: true,
			storeAction: "getElapsedTimeChartData",
			storeGetter: "elapsedTimeChartData",
			show: function({ $userCan }) {return $userCan('analysis.time')},
		},

	],
};
