<template>
  <div>
    <div class="fullScreen">
      <b-row no-gutters class="pb-2">
        <b-col cols="12" class="pr-2">
          <b-form inline>
						<!-- Format -->
            <label class="mr-sm-2 ml-2 printOption" for="format">{{ $t("cropfields.map.page_size") }}</label>
            <b-form-select
              id="format"
              class="mb-2 mr-sm-2 mb-sm-0"
              :options="formatOptions"
              v-model="selectedFormat"
              size="sm"
            ></b-form-select>
						
						<!-- Résolution -->
            <label class="mr-sm-2 printOption" for="resolution">{{ $t("cropfields.map.resolution") }}</label>
            <b-form-select
              id="resolution"
              class="mb-2 mr-sm-2 mb-sm-0"
              :options="resolutionOptions"
              v-model="selectedResolution"
              size="sm"
            ></b-form-select>

						<!-- Bouton d'impression -->
            <b-button ref="export-pdf" size="sm" class="btn-success" @click="exportPDF()">
              {{ $t("cropfields.map.eport_to_pdf") }}
            </b-button>
          </b-form>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
export default {
	data() {
		return {
			dims: {
				a0: [1189, 841],
				a1: [841, 594],
				a2: [594, 420],
				a3: [420, 297],
				a4: [297, 210],
				a5: [210, 148],
			},
			formatOptions: [
				{text: "A0", value: "a0"},
				{text: "A1", value: "a1"},
				{text: "A2", value: "a2"},
				{text: "A3", value: "a3"},
				{text: "A4", value: "a4"},
				{text: "A5", value: "a5"},
			],
			resolutionOptions: [
				{text: "72 dpi", value: "72"},
				{text: "150 dpi", value: "150"},
				{text: "300 dpi", value: "300"},
			],
			selectedFormat: "a4",
			selectedResolution: "150",
		}
	},

	props: ['map'],

	methods: {
		exportPDF() {
			this.$refs['export-pdf'].disabled = true;
			document.body.style.cursor = 'progress';

			const dim = this.dims[this.selectedFormat];
			const width = Math.round((dim[0] * this.selectedResolution) / 25.4);
			const height = Math.round((dim[1] * this.selectedResolution) / 25.4);
			const size = this.map.getSize();
			const viewResolution = this.map.getView().getResolution();
			let t = this;

			this.map.once('rendercomplete', function () {
				const mapCanvas = document.createElement('canvas');
				mapCanvas.width = width;
				mapCanvas.height = height;
				const mapContext = mapCanvas.getContext('2d');
				Array.prototype.forEach.call(
					document.querySelectorAll('.ol-layer canvas'),
					function (canvas) {
						if (canvas.width > 0) {
							const opacity = canvas.parentNode.style.opacity;
							mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
							const transform = canvas.style.transform;
							// Get the transform parameters from the style's transform matrix
							const matrix = transform
								.match(/^matrix\(([^\(]*)\)$/)[1]
								.split(',')
								.map(Number);
							// Apply the transform to the export map context
							CanvasRenderingContext2D.prototype.setTransform.apply(
								mapContext,
								matrix
							);
							mapContext.drawImage(canvas, 0, 0);
						}
					}
				);
				mapContext.globalAlpha = 1;
				mapContext.setTransform(1, 0, 0, 1, 0, 0);
				const pdf = new jspdf.jsPDF('landscape', undefined, t.selectedFormat);
				pdf.addImage(
					mapCanvas.toDataURL('image/jpeg'),
					'JPEG',
					0,
					0,
					dim[0],
					dim[1]
				);
				pdf.save('map.pdf');
				// Reset original map size
				t.map.setSize(size);
				t.map.getView().setResolution(viewResolution);
				t.$refs['export-pdf'].disabled = false;
				document.body.style.cursor = 'auto';
			});
			// Set print size
			const printSize = [width, height];
			this.map.setSize(printSize);
			const scaling = Math.min(width / size[0], height / size[1]);
			this.map.getView().setResolution(viewResolution / scaling);
		}
	}
};
</script>
<style lang="scss" scoped>
.fullScreen {
  position: absolute;
  top: 2px;
  padding-top: 4px;
  right: 4%;
  background-color: rgba(0,60,136,.5);
  border-radius: 4px;
  font-size: 0.875rem;
}
.printOption {
  color: white;
}
</style>