<template>
  <div class="d-flex justify-content-end align-items-end ">
    <div class="mr-4 green-variant">
      <button
        v-if="oneSelectedLocation && !isMapView && (!currentLocationType || currentLocationType.canOwnGeometry)"
        class="btn btn-sm align-bottom btn-secondary"
        @click="editGeometry"
      >
        <span v-if="currentLocationHasGeometry">
          {{ $t("cropfields.details.edit_geometry") }}
        </span>
        <span v-else>{{ $t("cropfields.details.define_geometry") }}</span>
      </button>
    </div>
    <div class="d-flex">
      <router-link
        v-if="$route.params.location_id"
        :to="{ name: 'location.details' }"
        class="tab-icon"
        :class="{ active: $route.name != 'location.map' && $route.name != 'location.map.details' }"
      >
        <img src="@/assets/images/list-interface-icon.svg" />
      </router-link>
      <router-link
        v-else
        :to="{ name: 'location.list' }"
        class="tab-icon"
        :class="{ active: $route.name != 'location.map' && $route.name != 'location.map.details' }"
      >
        <img src="@/assets/images/list-interface-icon.svg" />
      </router-link>
      <router-link
        v-if="$route.params.location_id"
        :to="{ name: 'location.map.details', params: { location_id: $route.params.location_id } }"
        class="tab-icon"
        :class="{ active: $route.name == 'location.map.details' }"
      >
        <img src="@/assets/images/cropfield-map-icon.svg" />
      </router-link>
      <router-link
        v-else
        :to="{ name: 'location.map' }"
        class="tab-icon"
        :class="{ active: $route.name == 'location.map' }"
      >
        <img src="@/assets/images/cropfield-map-icon.svg" />
      </router-link>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/eventBus.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

// turf
import * as turf from "@turf/turf";

export default {
  methods: {
    ...mapActions({
      deleteEntry: "location/deleteEntry",
      deleteEntryOfStore: "location/deleteEntryOfStore",
    }),
    async editGeometry() {
      await this.$router
        .push({ name: "location.map.details", params: { location_id: this.currentEntry.id, mode: "editGeometry" } })
        .catch(error => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    },
  },
  computed: {
    ...mapGetters({
      entriesChecked: "location/entriesChecked",
      checkedGeometries: "location/checkedGeometries",
      currentLocationHasGeometry: "location/currentLocationHasGeometry",
      currentLocationType: "currentLocationType",
      locations: "location/entries",
      currentEntry: "location/currentEntry",
    }),
    polygons() {
      return this.checkedGeometries.map(g => g && turf.polygon(g.coordinates));
    },
    currentLocation() {
      if (this.entriesChecked.length == 0) return this.currentEntry;
      return this.locations.find(e => e.id == this.entriesChecked[0]);
    },
    oneSelectedLocation() {
      return this.currentEntry && this.currentEntry.id && this.entriesChecked.length == 0;
    },

    isMapView() {
      return this.$route.name.includes("location.map");
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-icon {
  width: 40px;
  background: $grey;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:first-child {
    border-radius: 7px 0 0 7px;
  }

  &:last-child {
    border-radius: 0 7px 7px 0;
  }

  &.active {
    background: $dark-grey;
  }

  img {
    width: 100%;
  }
}
</style>

<style lang="scss">
.tabs-sticky {
  position: sticky;
  top: 0;
}
</style>
