<template>
<!-- Composant affichant les annotations -->
<!-- Il est utilisé dans le détail des activités et des parcelles -->
  <div class="mb-0">
    <!-- Notes -->
    <h4 class="subtitle-form overload-subtitle mb-2" v-if="isOnActivity && notes.length > 0">Annotations</h4>
    <div v-show="isOnActivity && !currentEntry.notes" class="text-activity-notes enpty-attr text-left">
      Aucune annotation renseignée
    </div>
    <div class="max-width notes-params" v-for="note in this.notes" v-bind:key="note.id" :value="note.id">
      <div :class="['p-2', {'bg-white': note.id != editedNoteId, 'bg-editing': note.id == editedNoteId}]">
        <!-- Image -->
        <ImageViewer v-if="!isOnActivity && note.imageFile" :imageSrc="note.imageFile" class="pl-2"/>
        <!-- Audio -->
        <audio v-if="note.audioFile" controls> <source :src="note.audioFile" type="audio/mp3">
          Votre navigateur ne prend pas en charge l'élément audio.
        </audio>
        <!-- Texte -->
        <!-- On n'affiche pas les boutons de modifications et de suppressions sur la vue des activités -->
        <div :class="['text-infos', {'bg-white': note.id != editedNoteId, 'bg-editing': note.id == editedNoteId}]" >
          <span v-if="isOnActivity"> {{ note.text }} </span>
          <b-row v-else>
            <b-col cols="10" class="break-word"><span> {{ note.text }} </span></b-col>
            <b-col cols="1" class="p-0">
              <button class="btn btn-sm p-1" @click="() => $emit('editNote', note.id)" :title="$t('edit')">
                <img :src="editIcon" alt="icon edit action" />
              </button>
            </b-col>
            <b-col cols="1" class="p-0">
              <button class="btn btn-sm p-1" @click="() => deleteNote(note.id)" :title="$t('delete')">
                <img :src="delIcon" alt="icon delete action" />
              </button>
            </b-col>
          </b-row>
        </div>
      </div>
      <p class="text-activity-notes position-right font-italic mb-0" v-if="note.noteTopic !== null">
        {{note.topic_designation }}
      </p>
      <p class="text-activity-notes position-right font-italic">
        {{note.author ? (note.author + ",") : ""}} {{ formatDate(note.createdAt, "date") }} à {{ formatDate(note.createdAt, "time") }}
      </p>
    </div>
    <hr v-if="isOnActivity"/> 
  </div>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import NoteModule from "@/store/modules/note";
import {formatDate} from "@/utils/commons";
import { mapGetters } from "vuex";
import ImageViewer from "@/components/commons/ImageViewer.vue";
import delIcon from "@/assets/images/delete.svg";
import editIcon from "@/assets/images/pencil-edit-button.svg";
import { askConfirm } from "@/utils/commons";

export default {
  mixins: [DetailUpdateMixin],
  data() {
    return {
      notes: [],
      delIcon: delIcon,
      editIcon: editIcon
    };
  },
  props: {
    editedNoteId: {
      type: Number,
      default: null
    }
  },
  created(){
    this.init();
  },
  beforeCreate() {
    const store = this.$store;
    if (store && store.state) {
      if (!store.state["note"]) {
        store.registerModule("note", NoteModule);
      }
    }
  },
  methods: {
    async init(){
      if (this.isOnActivity && this.currentEntry.notes) {
        this.notes = await this.$store.dispatch("getAllNotes", { activity: this.currentEntry.id, detail: true });
      }
      if (this.isOnCropfield) {
        this.notes = await this.$store.dispatch("getAllNotes", { cropfield_id: this.currentCropfield.id, detail: true });
      }
    },
    playSound (sound) {
      if(sound) {
        var audio = new Audio(sound);
        audio.play();
      }
    },
    async deleteNote(noteId) {
      if (await askConfirm(this.$t("cropfields.details.confirm_delete_note"))) {
        await this.$store.dispatch("deleteNote", { id: noteId });
        await this.init();
      }
    },
    formatDate,
  },
  components: {ImageViewer},
  computed: {
    ...mapGetters({ 
			currentCropfield: "cropfield/currentEntry"
		}),
    isOnActivity() {
      return this.$route.name.includes("activity");
    },
    isOnCropfield() {
      return this.$route.name.includes("cropfield");
    }
  },
};
</script>

<style lang="scss" scoped>
/**
 * Text align right.
 */
.position-right {
  text-align: right;
}

/**
 * Max width of the frame.
 */
.max-width {
  width: 100%;
}

/**
 * Change font-weight to italic
 */
.font-italic {
  font-weight: italic;
}

/**
 * Change notes params.
 */
.notes-params {
  border-radius: 6px;
  padding: 0px 8px 0px 0px;
}

/**
 * Color text for empty list.
 */
.enpty-attr {
  text-align: center;
  color: $danger-color;
}

.bg-editing {
  background: $warning-background;
}

.break-word {
  word-wrap: break-word;
}

img {
  width: 1rem;
  height: 1rem;
}
</style>
