import Gateway from "./base";

export default class InputsApi {


    /*
    * get a file containing a export of activies with inputs
    */
  static exportActivitiesWithInputs(params) {
    const headers = {}

    if ( params.format ) {
      headers['Accept'] = "application/" + params.format;
      delete params.format;
    }
    if ( params.pacage == 'all' ) {
      delete params.pacage;
    }
    if ( params.campaign == 'all' ) {
      delete params.campaign;
    }
    if ( params.farm_id == "all" ) {
      delete params.farm_id;
    }

    return Gateway.get(`/export/inputs/`, params, headers, "arraybuffer");
  }

  /*
    * get a file containing a report on volumes of inputStock uses, with several formats of file
    */
  static reportInputsVolumes(params) {
    const headers = {}

    if ( params.format ) {
      headers['Accept'] = "application/" + params.format;
      delete params.format;
    }
    if ( params.pacage == 'all' ) {
      delete params.pacage;
    }
    if ( params.farm_id == "all" ) {
      delete params.farm_id;
    }
    if ( params.campaign == 'all' ) {
      delete params.campaign;
    }

    return Gateway.get(`/reports/inputs/volumes/`, params, headers, "arraybuffer");
  }

  /**
   * Get all the activities using an input on a campaign
   * @param {Object} params keys : inputsStock, campaign
   * @returns
   */
  static getActivitiesByInputsStock(params) {
    return Gateway.get(`/api/inputs/`, params);
  }
}