<template>
  <b-form @submit="saveFarm" :key="$route.path">
    <b-container>
      <b-row class="mt-5">
        <b-col cols="12" md="4">
          <!-- input field for the pacage -->
          <b-form-group :label="$t('new_farm.pacage') + ' ' + $t('new_farm.mandatory')">
            <b-form-input v-model="farm.pacage" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="8">
          <!-- input field for the farm name -->
          <b-form-group :label="$t('new_farm.name') + ' ' + $t('new_farm.mandatory')">
            <b-form-input v-model="farm.name" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <!-- input field for the farm SIRET -->
          <b-form-group :label="$t('new_farm.identificationNumber')">
            <b-form-input v-model="farm.identificationNumber"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <!-- input field for the farm street -->
          <b-form-group :label="$t('new_farm.street')">
            <b-form-input v-model="farm.street"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <!-- input field for the postal code -->
          <b-form-group :label="$t('new_farm.postalCode') + ' ' + $t('new_farm.mandatory')">
            <b-form-input v-model="farm.postalCode" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="8">
          <!-- input field for the city -->
          <b-form-group :label="$t('new_farm.city') + ' ' + $t('new_farm.mandatory')">
            <b-form-input v-model="farm.city" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="7">
          <!-- input field for the email -->
          <b-form-group :label="$t('new_farm.emailToNotify')">
            <b-form-input v-model="farm.emailToNotify" type="email"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="5">
          <!-- input field for the phone -->
          <b-form-group :label="$t('new_farm.phoneNumberToNotify')">
            <b-form-input v-model="farm.phoneNumberToNotify"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <!-- input field for the mandatory target -->
          <b-form-group :label="$t('new_farm.mandatoryTarget')">
            <b-form-checkbox v-model="farm.mandatoryTarget"> </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="8">
          <!-- input field for the stockstore -->
          <b-form-group :label="$t('new_farm.stockStore')" v-show="stockStores.length > 0">
            <Dropdown
              ref="dropdown"
              label="name"
              class="large"
              :options="stockStores"
              :value="stockStores.find(s => s.id == farm.stockStore)"
              @input="(option) => (farm.stockStore = option && option.id)"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6"></b-col>
        <b-col cols="12" md="6">
          <div class="mt-5 d-flex justify-content-end">
            <!-- cancel button -->
            <button class="btn button-outline text-center mr-2" @click="$router.push({ name: 'home' })">
              {{ $t("new_farm.cancel") }}
            </button>
            <!-- save button -->
            <b-button class="button-default text-center" type="submit">
              {{ $t("new_farm.submit") }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </b-form>
</template>

<script>
import { TILE_UPDATE } from "@/store/modules/page-tile";

import { mapGetters, mapActions } from "vuex";

import Dropdown from "@/components/activity/edit/Dropdown.vue";

export default {
  beforeRouteEnter(to, from, next) {
    // on route enter
    next((vm) => {
      // clear the form data
      vm.resetFormData()

      // if we are on edit mode, clone data from current farm
      if(to.name === "farm.edit-current") {
        vm.initFormData();
      }

      // update page title (green top bar)
      vm.setTitle();

      // fetch stock stores options
      vm.fetchStockStores(false);
    });
  },
  data() {
    return {
      farm: {}
    };
  },
  components: {
    Dropdown,
  },
  computed: {
    ...mapGetters({
      farms: "farm/farms",
      currentFarm: "farm/currentFarm",
      stockStores: "stockStores/stockStores",
    }),
  },
  watch: {
    currentFarm() {
      this.setTitle();

      if (this.$route.name == "farm.edit-current") {
        this.initFormData();
      }
    },
  },
  methods: {
    ...mapActions({
      fetchFarms: "farm/fetchFarms",
      createFarm: "farm/createFarm",
      updateFarm: "farm/updateFarm",
      setCurrentFarm: "farm/setCurrentFarm",
      fetchStockStores: "stockStores/fetchStockStores",
    }),
    resetFormData() {
      this.farm = {
        id: null,
        pacage: null,
        name: null,
        street: null,
        postalCode: null,
        city: null,
        emailToNotify: null,
        phoneNumberToNotify: null,
        mandatoryTarget: false,
        stockStore: null,
        identificationNumber: null,
      }
    },
    initFormData() {
      if (!this.currentFarm) return;

      this.farm = {
        id: this.currentFarm.id,
        pacage: this.currentFarm.pacage,
        name: this.currentFarm.name,
        street: this.currentFarm.street,
        postalCode: this.currentFarm.postalCode,
        city: this.currentFarm.city,
        emailToNotify: this.currentFarm.emailToNotify,
        phoneNumberToNotify: this.currentFarm.phoneNumberToNotify,
        mandatoryTarget: this.currentFarm.mandatoryTarget || false,
        stockStore: this.currentFarm.stockStore,
        identificationNumber: this.currentFarm.identificationNumber,
      };
    },
    /**
     * save the new farm
     */
    async saveFarm(event) {
      event.preventDefault();

      // we use a try / catch to check is api returned an error
      // then we'll redirect to home only if no error was catched

      let foundError = false;
      try {
        // edit mode
        if(this.$route.name == "farm.edit-current") {
          await this.updateFarm({ id: this.farm.id, data: this.farm });
        }
        // create mode
        else {
          await this.createFarm(this.farm);
        }

        await this.fetchFarms();
      } catch {
        foundError = true;
      } finally {
        if (!foundError) {
          // set the new farm as the current farm in global filters
          await this.setCurrentFarm(this.farm.id);
          // redirect on homepage when finish
          this.$router.push({ name: "cropfield.list" });
        }
      }
    },

    /**
     * update the title banner
     */
    setTitle() {
      let title;
      if (this.$route.name == "farm.new") {
        title = this.$t("new_farm.title");
      } else if (this.currentFarm) {
        title = this.$t("edit_current_farm.title") + " " + this.currentFarm.name;
      }
      this.$store.commit(TILE_UPDATE, {
        title: title,
        buttons: [],
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
