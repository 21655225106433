<template>
  <div>
    <b-modal
      ref="reportModal"
      dialog-class="modal-no-max-width"
      v-model="modalReport"
      size="lg"
      hide-header
      hide-footer
    >
      <div class="d-block text-center reportModalContent">
        <a href="javascript:void(0)" @click="$refs['reportModal'].hide()" class="close-button"></a>
        <iframe
          width="100%"
          height="100%"
          id="frame"
          loading="lazy"
          ref="iframe"
          :srcdoc="htmlContent"
          style="border: 0px #ffffff none"
          frameborder="1"
          marginheight="1px"
          marginwidth="0px"
          allowfullscreen
        ></iframe>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modalReport: false,
      htmlContent: null,
    };
  },
  mounted: function () {},
  methods: {
    /**
     * Description: Affiche le modal.
     */
    modalAddReport: async function () {
      this.modalReport = !this.modalReport;
      //this.$refs['reportModal'].innerHTML = this.downloadUrl

      this.$nextTick(async function () {
        this.htmlContent = await this.downloadBlob?.text();
      });
    },
  },
  props: {
    downloadBlob: {
      type: Blob,
      default: () => new Blob(),
    },
  },
};
</script>

<style lang="scss" scoped>
.displayModal {
  display: block;
}

.buttonModalPathActivity {
  display: none;
}

.reportModalContent {
  height: 100vh;
}

#frame {
  height: 100vh;
}

.modal-body {
  padding: 0 !important;
}

.close-button {
  z-index: 10;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 42px;
  height: 41px;
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  padding-top: 4px;
  background: #dc3545;
  opacity: 1;
}

.close-button:hover {
  opacity: 0.75;
}

.close-button:before,
.close-button:after {
  position: absolute;
  left: 19px;
  content: " ";
  height: 32px;
  width: 2px;
  background-color: #ffffff;
}

.close-button:before {
  transform: rotate(45deg);
}

.close-button:after {
  transform: rotate(-45deg);
}
</style>
