import OperatorsAPI from "@/api/operator.api";

export default {
  namespaced: true,
  state: {
    operators: []
  },
  getters: {
    operators: state => state.operators
  },
  mutations: {
    "setOperators": (state, operators) => {
      state.operators = operators
    },
  },
  actions: {
    async fetchOperators({ commit, getters }) {
      // operators already fetched, return
      if(getters.operators.length > 0) return

      const response = await OperatorsAPI.getOperators();
      commit("setOperators", response.data);
      return response.data;
    },
  },
};