import Gateway from "./base";

export default class ProductUsageApi {
  /**
   * Get usages associated to product ID
   *
   * @param id
   */
  static getProductUsageInfo(id) {
    return Gateway.get(`/refapi/productUsages/${id}/`);
  }
}