import External_MiscellaneousApi from "@/api/external_miscellaneous.api";

export const ALL_MISCELLANEOUS = "GET ALL MISCELLANEOUS";
export const ONE_MISCELLANEOUS_BY_KEY = "GET MISCELLANEOUS BY KEY";
export const CREATE_MISCELLANEOUS = "CREATE MISCELLANEOUS";
export const UPDATE_MISCELLANEOUS = "UPDATE MISCELLANEOUS";
export const DELETE_MISCELLANEOUS = "DELETE MISCELLANEOUS";
export const MISCELLANEOUS_LOADING = "LOAD MISCELLANEOUS";
export const SMAG_APPLICATION_ID = " SMAG APPLICATION ID";
export const XARVIO_AUTH_PARAMS = "XARVIO AUTH PARAMS";

export default {
  state: {
    miscellaneous: [],
    currentMiscellaneous: [],
    newMiscellaneous: {},
    miscellaneousUpdated: {},
    miscellaneous_loaded: true,
    smagApplicationId: null,
    xarvioAuthParams: {},
  },
  getters: {
    miscellaneous: state => state.miscellaneous,
    currentMiscellaneous: state => state.currentMiscellaneous,
    smagApplicationId: state => state.smagApplicationId,
    xarvioAuthParams: state => state.xarvioAuthParams,
  },
  mutations: {
    [ALL_MISCELLANEOUS]: (state, miscellaneous) => {
      state.miscellaneous = miscellaneous;
    },

    [ONE_MISCELLANEOUS_BY_KEY]: (state, payload) => {
      state.currentMiscellaneous = payload.miscellaneous;
    },

    [CREATE_MISCELLANEOUS]: (state, payload) => {
      state.newMiscellaneous = payload.miscellaneous;
    },

    [UPDATE_MISCELLANEOUS]: (state, payload) => {
      state.miscellaneousUpdated = payload.miscellaneous;
    },

    [MISCELLANEOUS_LOADING]: (state, status) => {
      state.miscellaneous_loaded = !status;
    },

    [SMAG_APPLICATION_ID]: (state, smagApplicationId) => {
      state.smagApplicationId = smagApplicationId;
    },

    [XARVIO_AUTH_PARAMS]: (state, xarvioAuthParams) => {
      state.xarvioAuthParams = xarvioAuthParams;
    }
  },
  actions: {
    async getAllMiscellaneous({ commit, state }) {
      commit(MISCELLANEOUS_LOADING, true);
      const response = await External_MiscellaneousApi.getAllMiscellaneous();
      commit(ALL_MISCELLANEOUS, response.data);
      commit(MISCELLANEOUS_LOADING, false);
      return response.data;
    },

    async getMiscellaneousByKey({ commit }, payload) {
      commit(MISCELLANEOUS_LOADING, true);
      const response = await External_MiscellaneousApi.getMiscellaneousByKey(payload);
      commit(ONE_MISCELLANEOUS_BY_KEY, { miscellaneous: response.data });
      commit(MISCELLANEOUS_LOADING, false);
      return response.data;
    },

    async createMiscellaneous({ commit }, payload) {
      commit(MISCELLANEOUS_LOADING, true);
      const response = await External_MiscellaneousApi.createMiscellaneous(payload.data);
      commit(CREATE_MISCELLANEOUS, { miscellaneous: response.data });
      commit(MISCELLANEOUS_LOADING, false);
      return response.data;
    },

    async updateMiscellaneous({ commit }, payload) {
      commit(MISCELLANEOUS_LOADING, true);
      const response = await External_MiscellaneousApi.updateMiscellaneous(payload.data, payload.id);
      commit(UPDATE_MISCELLANEOUS, { miscellaneous: response.data });
      commit(MISCELLANEOUS_LOADING, false);
      return response.data;
    },

    async deleteMiscellaneous({ commit }, payload) {
      commit(MISCELLANEOUS_LOADING, true);
      const response = await External_MiscellaneousApi.deleteMiscellaneous(payload.id);
      commit(MISCELLANEOUS_LOADING, false);
      return response.data;
    },

    async getSmagApplicationId({commit}) {
      const response = await External_MiscellaneousApi.getSmagApplicationId();
      commit(SMAG_APPLICATION_ID, {smagApplicationId: response.data});
      return response.data;
    },

    async getXarvioAuthParams({commit}) {
      const response = await External_MiscellaneousApi.getXarvioAuthParams();
      commit(XARVIO_AUTH_PARAMS, response.data)
      return response.data;
    },
  },
};
