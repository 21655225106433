<template>
  <div v-if="currentProduct">
    <!-- Product risks -->
    <div v-if="currentProduct.hasOwnProperty('riskClass')">
      <h4 class="subtitle-form">Risques liés au produit</h4>

      <table>
        <tr>
          <th class="title-table-input-form">Code</th>
          <th class="title-table-input-form">Désignation</th>
        </tr>

        <tr v-for="risk in currentProduct.riskClass" v-bind:key="risk.id" :value="risk.id">
          <td class="text-infos td-risk shape-first-cell">{{ risk.code }}</td>
          <td class="text-infos td-risk shape-third-cell">{{ risk.designation }}</td>
        </tr>
      </table>
    </div>

    <div v-else-if="productInfo.hasOwnProperty('riskClass')">
      <h4 class="subtitle-form">Risques liés au produit</h4>

      <table>
        <tr>
          <th class="title-table-input-form">Code</th>
          <th class="title-table-input-form">Désignation</th>
        </tr>

        <tr v-for="risk in productInfo.riskClass" v-bind:key="risk.id" :value="risk.id">
          <td class="text-infos td-risk shape-first-cell">{{ risk.code }}</td>
          <td class="text-infos td-risk shape-third-cell">{{ risk.designation }}</td>
        </tr>
      </table>
    </div>

    <div v-else>
      <p class="text-infos">
        <span> Aucun risques renseignés </span>
      </p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";

export default {
  mixins: [DetailUpdateMixin],
  data() {
    return {};
  },
  methods: {
    onBack() {
      this.$router.go(-1);
    },
  },
  async mounted() {
    this.loadOn();

    await this.$store.dispatch("getProductInfo", { id: this.$route.params.product_id });
    this.loadOff();
  },
  computed: {
    ...mapGetters(["currentProduct", "productInfo"]),
  },
};
</script>

<style lang="scss" scoped>
/**
 * Shape <td> for the first cell.
 */
.shape-first-cell {
  border-radius: 3px 0px 0px 3px;
  padding-right: 30px;
  padding-left: 8px;
}

/**
 * Shape <td> for the second cell.
 */
.shape-second-cell {
  border-radius: 0px 0px 0px 0px;
  padding-right: 30px;
  font-weight: normal;
}

/**
 * Shape <td> for the third cell.
 */
.shape-third-cell {
  border-radius: 0px 3px 3px 0px;
  font-weight: normal;
}

table {
  border-collapse: separate;
  border-spacing: 0px 5px;
}

.td-risk {
  background-color: $background-text-color;
}
</style>
