<template>
  <!-- Treatment Component : Farm -->
  <!-- Displays a Farm selector -->
  <div class="mt-4">
    <h4 class="subtitle-form mb-2">
      {{ $t("commons.exports_reports.farm_selection_title") }}
    </h4>
    <div class="bg-white text-infos">
      <!-- checkbox "Select all" -->
      <b-form-checkbox v-model="allSelected" :indeterminate="indeterminate" @change="toggleAll">
        {{ $t("commons.exports_reports.all_farms") }}
      </b-form-checkbox>

      <!-- checkboxs for the farms -->
      <b-form-checkbox-group
        v-model="farmChoice"
        :options="farmOptions"
        name="farmChoice"
        class="ml-3"
        stacked
      ></b-form-checkbox-group>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import TreatmentFormPartMixin from "@/components/commons/treatments/TreatmentFormPartMixin";
import { TreatmentFormPart } from "@/models/treatments/Treatment.js";

const FARM_ALL = "all";

export default {
  mixins: [TreatmentFormPartMixin],
  data() {
    return {
      // the selected farm(s)
      farmChoice: [],
      // two variables to store the 'select all' state
      // see the watcher below
      allSelected: false,
      indeterminate: false,
      // Permet d'exclure les exploitations qui n'ont pas d'attribut externalId défini
      routeToExcludeFromFarm: ["cropfield.importfromsmag"]
    };
  },
  props: {
    preselectedFarms: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    validateForm() {
      this.isValid = true;

      return new TreatmentFormPart("farm_id", this.farmChoiceForPayload);
    },
    // update farm selection on component load
    updateFarmChoice() {
      if (!this.currentFarm) this.farmChoice = this.farmOptions.map((f) => f.value);
      else if(this.currentFarm.externalId || !this.routeToExcludeFromFarm.includes(this.$route.name))
        this.farmChoice = [this.currentFarm.id];
      else this.farmChoice = [];
    },
    // when we click on "select all" button
    toggleAll(checked) {
      this.farmChoice = checked ? this.farmOptions.map((f) => f.value) : [];
    },
  },
  mounted() {
    // S'il ya des fermes préselectionnées, elles sont cochées
    if (this.preselectedFarms.length > 0) {
      this.farmChoice.push(...this.preselectedFarms);
    } else {
      // Sinon, on coche la ferme courante ou toutes les fermes s'il n'y en a pas de courante
      this.updateFarmChoice();
    }
  },
  computed: {
    ...mapGetters({
      currentFarm: "farm/currentFarm",
      farms: "farm/farms",
    }),
    // list of farms for the checkboxes
    farmOptions() {
      return this.farms.map((f) => {
        if (f.externalId|| !this.routeToExcludeFromFarm.includes(this.$route.name))
          return { text: f.name, value: f.id };
        return {};
      }).filter(obj => Object.keys(obj).length !== 0);
    },
    // format the selected farms for the api payload
    farmChoiceForPayload() {
      return this.farmChoice.length == this.farms.length
        ? FARM_ALL
        : this.farmChoice.filter((e) => e != undefined).join(",");
    },
  },
  watch: {
    farms() {
      this.updateFarmChoice();
    },
    currentFarm() {
      this.updateFarmChoice();
    },
    // this is used to update the "select all" button state depending on what we do
    farmChoice(newValue, oldValue) {
      // case where no farm is selected -> "select all" is unchecked
      if (newValue.length === 0) {
        this.indeterminate = false;
        this.allSelected = false;
        // case where all farms are selected -> "select all" is checked
      } else if (newValue.length === this.farmOptions.length) {
        this.indeterminate = false;
        this.allSelected = true;
        // otherwise, "select all" is half-checked (a crossing line)
      } else {
        this.indeterminate = true;
        this.allSelected = false;
      }
    },
  },
};
</script>

<style></style>
