import Gateway from "./base";

export default class ActivityApi {
  /**
   * Get All inetrventions
   *
   */
  static getInterventions(params) {
    return Gateway.get("/api/fertilizationPlanInputs/", params);
  }

  /**
   * Get one intervention by id
   *
   * @param id
   */
  static getIntervention(id) {
    return Gateway.get(`/api/fertilizationPlanInputs/${id}/`);
  }

  /**
   * Get fertilizationPlanInputs by cropfied
   *
   * @param cropField
   */
  static getInterventionByCropfield(params) {
    return Gateway.get(`/api/fertilizationPlanInputs/`, params);
  }

  /**
   * Create activity
   *
   * @param data
   */
  static createIntervention(data) {
    return Gateway.post(`/api/fertilizationPlanInputs/`, data);
  }

  /**
   * Update intervention
   *
   * @param data
   * @param id
   */
  static updateIntervention(data, id) {
    return Gateway.put(`/api/fertilizationPlanInputs/${id}/`, data);
  }

  /**
   * delete intervention
   *
   * @param id
   */
  static deleteIntervention(id) {
    return Gateway.delete(`/api/fertilizationPlanInputs/${id}/`);
  }

}
