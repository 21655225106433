<template>
  <div>
    <!-- Treatment Component : ClientId/RegistrationNumber -->
    <!-- Implicit component : no template but add to form the registrationNumber -->
  </div>
</template>

<script>
import TreatmentFormPartMixin from "@/components/commons/treatments/TreatmentFormPartMixin";
import { TreatmentFormPart } from "@/models/treatments/Treatment.js";

export default {
  mixins: [TreatmentFormPartMixin],
  methods: {
    validateForm() {
      this.isValid = true;

      const clientid = this.$cookies.get("registrationNumber");

      return new TreatmentFormPart("clientid", clientid);
    },
  },
};
</script>

<style></style>
