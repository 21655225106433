var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.activityIcon)?_c('div',{staticClass:"activity_icon mr-2",style:({
      'background-color': _vm.activityBgColor,
      'border-radius': (_vm.square ? '0.4rem' : '50%')
    })},[_c('div',{staticClass:"activity_icon_path",style:({
        'mask-image': 'url(' + require('@/assets/icons/activities/' + _vm.activityIcon + '.svg') + ')',
        'background-color': _vm.activityStrokeColor,
      })})]):_c('img',{staticStyle:{"margin-right":"10px","padding":"14px"},attrs:{"src":require('@/assets/images/sesame.svg'),"width":"52","alt":""}})])
}
var staticRenderFns = []

export { render, staticRenderFns }