import { commonGetters, commonMutations, commonActions } from "./commons.js";

import MachineApi from "@/api/machine.api";
import Vue from "vue";
import { i18n } from "@/i18n.js";
import { project4326, project3857 } from "@/utils/geomatics";
import * as turf from "@turf/turf";

export const ALL_MACHINES = "GET ALL MACHINES";
export const ONE_MACHINE = "GET MACHINE BY ID";
export const CREATE_MACHINE = "CREATE MACHINE";
export const UPDATE_MACHINE = "UPDATE MACHINE";
export const DELETE_MACHINE = "DELETE MACHINE";
export const MACHINE_LOADING = "LOAD MACHINE";
export const GET_MACHINE = "GET MACHINE ALREADY LOADED BY ID";

export const MACHINE_SET_FILTER = "SET FILTER MACHINE";
export const GET_FILTERS_MACHINE_BY = "GET ALL FILTER MACHINE";
export const MACHINES_FILTERED = "GET MACHINES FILTERED";
export const MACHINE_REMOVE_FILTER = "REMOVE FILTER MACHINE";
export const MACHINE_CLEAN_FILTER = "CLEAN FILTER MACHINE";
export const CLEAN_CURRENT_MACHINE = "CLEAN CURRENT MACHINE";
export const CLEAN_MACHINE_LOADED = "CLEAN MACHINE LOADED";
export const MAJ_MACHINE_FILTER = "MAJ MACHINE FILTER";
export const MACHINE_MAINTENANCE_FORM = "GET MACHINE MAINTENANCE FORM";

export const GET_AVAILABLE_BEACON = "GET AVAILABLE BEACON";
export const GET_AVAILABLE_TRACKER = "GET AVAILABLE TRACKER";

export const SET_MACHINE_RESEARCHED = "SET MACHINE RESEARCHED";

export const GET_COURSE = "GET MACHINE OR ACTIVITY COURSE";

export default {
  namespaced: true,
  state: {
    // commons
    _entries: {},
    // it seems to be an issue : sometimes the machine object is an empty vue object
    // i don't know for now why, but the quick fix is to ignore this variable with the '?' symbol
    sortFields: [machine => machine.machineType_designation?.toLowerCase(), machine => machine.model?.toLowerCase()],
    sortOrders: ["asc", "asc"],
    currentId: null,
    filterData: {},
    filterSelection: {},
    filters: [],
    currentEntry: {},
    newEntry: {},
    entryUpdated: {},
    entryLoaded: true,
    searchQuery: "",
    page: 1,
    maxPage: 0,
    lastEntryDisplayed: null,
    // others
    allMachines: [],
    machines: [],
    machineFilters: [],
    machineFilterSelected: {},
    allfiltersMachine: {
      machineType: "",
    },
    currentMachine: {},
    newMachine: {},
    machineUpdated: {},
    beaconsavailable: [],
    trackersavailable: [],
    machineResearched: "",
    machine_loaded: true,
    maintenanceForm: [],
    editedEntry: {},
    course: {},
    currentMachineGeometries: {}
  },
  getters: {
    ...commonGetters(),
    allMachines: state => state.allMachines,
    machines: state => state.machines,
    maintenanceForm: state => state.maintenanceForm,
    currentMachine: state => state.currentMachine,
    newMachine: state => state.newMachine,
    getMachineFilters: state => state.machineFilters,
    machineFilterSelected: state => state.machineFilterSelected,
    getAllFiltersMachine: state => state.allfiltersMachine,
    beaconsavailable: state => state.beaconsavailable,
    trackersavailable: state => state.trackersavailable,
    machineResearched: state => state.machineResearched,
    course: state => state.course,
    currentMachineGeometries: (state) => Object.values(state.currentMachineGeometries),
    currentMachineGeometries3857:(state, getters) => getters.currentMachineGeometries.map((g) => g && g.geometry && project3857(g)),
  },
  mutations: {
    ...commonMutations(),
    [GET_MACHINE]: (state, payload) => {
      let machineId = payload.id;

      let product = state.allMachines.filter(function(elem) {
        if (elem.id == machineId) return elem;
      });

      state.currentEntry = product[0];
    },

    [ALL_MACHINES]: (state, machines) => {
      state.machines = machines;
    },

    [GET_AVAILABLE_BEACON]: (state, beacons) => {
      state.beaconsavailable = beacons;
    },

    [SET_MACHINE_RESEARCHED]: (state, payload) => {
      state.machineResearched = payload;
    },

    [GET_AVAILABLE_TRACKER]: (state, trackers) => {
      state.trackersavailable = trackers;
    },

    [MACHINE_MAINTENANCE_FORM]: (state, payload) => {
      state.maintenanceForm = payload.form;
    },

    [ONE_MACHINE]: (state, payload) => {
      state.currentEntry = payload.machine;
      state.allMachines.push(payload.machine);
    },

    [CREATE_MACHINE]: (state, payload) => {
      state.newMachine = payload.machine;
    },

    [UPDATE_MACHINE]: (state, payload) => {
      state.machineUpdated = payload.machine;
    },

    [CLEAN_CURRENT_MACHINE]: state => {
      state.currentEntry = {};
    },

    [CLEAN_MACHINE_LOADED]: (state, payload) => {
      let machineId = payload.id;

      let machine = state.allMachines.filter(function(elem) {
        if (elem.id == machineId) return elem;
      });

      const filter = state.allMachines.indexOf(machine[0]);
      if (filter > -1) state.allMachines.splice(filter, 1);
    },

    [DELETE_MACHINE]: state => {
      state.currentEntry = {};
    },

    [MAJ_MACHINE_FILTER]: (state, payload) => {
      state.allfiltersMachine = payload;
    },

    [MACHINES_FILTERED]: (state, machines) => {
      state.machines = machines;
    },

    [GET_FILTERS_MACHINE_BY]: (state, payload) => {
      const element = payload.elements.map(e => ({ value: e[1], id: e[0], checked: false }));
      if (payload.index >= state.machineFilters.length) {
        state.machineFilters.push(element);
      } else {
        state.machineFilters.splice(payload.index, 0, element);
      }
    },

    [MACHINE_SET_FILTER]: (state, { filterName, elementId }) => {
      let currentId = null;
      if (filterName in state.filterSelection) currentId = state.filterSelection[filterName];
      Vue.set(state.filterSelection, filterName, currentId == elementId ? null : elementId);
    },

    [MACHINE_CLEAN_FILTER]: state => {
      state.machineFilters.map(f => f.map(e => (e.checked = false)));
      state.machineFilterSelected = {};
    },

    [MACHINE_REMOVE_FILTER]: (state, payload) => {
      Vue.delete(state.machineFilterSelected, payload.key);
    },

    [MACHINE_LOADING]: (state, status) => {
      state.machine_loaded = !status;
    },

    [GET_COURSE]: (state, course) => {
      state.course = course;
    },

    setEditedEntryValue: (state, data) => {
      state.editedEntry = { ...state.editedEntry, ...data };
    },

    resetEditedEntry: state => {
      state.editedEntry = {};
    },

    resetCurrentMachineGeometries: (state) => {
      state.currentMachineGeometries = {}
    },

    setCurrentMachineGeometry: (state, { id, geometry }) => {
      Vue.set(state.currentMachineGeometries, id, geometry);
    },
  },

  actions: {
    ...commonActions(),
    async fetchEntries({ commit, dispatch }) {
      // Méthode appelée par la vue liste des machines
        commit(MACHINE_LOADING, true);
        let payload = {detail: true}
        // récupération du filtre appliqué
        const machineType = await dispatch("getFilterValueId", "machineType");
        if (typeof machineType !== "undefined") payload['machineType'] = machineType;

        const response = await MachineApi.getMachines(payload);
        commit("setEntries", { entries: response.data });
        commit(MACHINE_LOADING, false);
    },

    async getMachines({ commit, dispatch }, {machineType} = {}) {
      // Méthode appelée pour alimenter les dropdowns
        commit(MACHINE_LOADING, true);
        let payload = {}
        if (typeof machineType !== "undefined") payload['machineType'] = machineType;

        const response = await MachineApi.getMachines(payload);
        commit("setEntries", { entries: response.data });
        commit(MACHINE_LOADING, false);
    },

    async createEntry({ state, commit, dispatch, rootGetters }, { entry }) {
      commit(MACHINE_LOADING, true);
      let newEntry = await MachineApi.createMachine(entry);
      commit("addEntry", newEntry.data);
      commit("setCurrentId", { id: newEntry.data.id });

      commit(MACHINE_LOADING, false);
    },

    async getBeaconsAvailable({ commit }, payload) {
      commit(MACHINE_LOADING, true);
      const response = await MachineApi.getBeaconsAvailable(payload.machineId);
      commit(GET_AVAILABLE_BEACON, response.data);
      commit(MACHINE_LOADING, false);
      return response.data;
    },

    async getTrackersAvailable({ commit }, payload) {
      commit(MACHINE_LOADING, true);
      const response = await MachineApi.getTrackersAvailable(payload.machineId);
      commit(GET_AVAILABLE_TRACKER, response.data);
      commit(MACHINE_LOADING, false);
      return response.data;
    },

    async getBeacons({ commit }) {
      commit(MACHINE_LOADING, true);
      const response = await MachineApi.getBeacons();
      commit(GET_AVAILABLE_BEACON, response.data);
      commit(MACHINE_LOADING, false);
      return response.data;
    },

    async getTrackers({ commit }) {
      commit(MACHINE_LOADING, true);
      const response = await MachineApi.getTrackers();
      commit(GET_AVAILABLE_TRACKER, response.data);
      commit(MACHINE_LOADING, false);
      return response.data;
    },

    async getAllFiltersMachine({ commit }, payload) {
      commit(MACHINE_LOADING, true);
      const response = await MachineApi.getFiltersMachine(payload.param);
      commit("setFilterData", {
        name: payload.param,
        elements: response.data,
      });
      commit(GET_FILTERS_MACHINE_BY, {
        name: payload.param,
        index: payload.index,
        elements: response.data,
      });
      commit(MACHINE_LOADING, false);
      return response.data;
    },

    async getOneMachine({ commit }, payload) {
      commit(MACHINE_LOADING, true);
      const response = await MachineApi.getMachine(payload.id, payload.detail);
      commit("setCurrentId", { id: payload.id });
      commit("updateEntry", { id: payload.id, data: response.data });
      commit(MACHINE_LOADING, false);
      return response.data;
    },

    async updateEntry({ commit }, payload) {
      commit(MACHINE_LOADING, true);
      const response = await MachineApi.updateMachine(payload.data, payload.id);
      commit(UPDATE_MACHINE, { machine: response.data });

      commit("updateEntry", { machine: response.data });
      commit("setCurrentId", { id: payload.id });

      commit(MACHINE_LOADING, false);
      return response.data;
    },

    async deleteEntry({ commit }, payload) {
      commit(MACHINE_LOADING, true);
      const response = await MachineApi.deleteMachine(payload.id);
      commit(DELETE_MACHINE);
      commit("deleteEntry", { id: payload.id });

      commit(MACHINE_LOADING, false);
      return response;
    },

    async initFilters({ state, dispatch }) {
      state.filters.forEach(async filter => {
        await dispatch("getAllFiltersMachine", {
          param: filter.name,
        });
      });
    },

    async updateFilterSelection({ commit }, { filterName, elementId }) {
      // met à jour les filtres dans le store
      await commit("setFilter", {
        filterName: filterName,
        elementId: elementId,
      });
    },

    async getMaintenanceForm({ commit }, payload) {
      commit(MACHINE_LOADING, true);
      const response = await MachineApi.getMachineMaintenanceForm(payload.id);
      commit(MACHINE_MAINTENANCE_FORM, { form: response.data });
      commit(MACHINE_LOADING, false);
      return response.data;
    },

    async setEditedEntryValue({ commit }, data) {
      await commit("setEditedEntryValue", data);
    },

    async getPath({ commit }, payload) {
      commit(MACHINE_LOADING, true);
      const response = await MachineApi.getPath(payload);
      commit(GET_COURSE, response.data);
      commit(MACHINE_LOADING, false);
      return response.data;
    },

    async getAllCurrentLocations({ commit }) {
      commit(MACHINE_LOADING, true);
      const response = await MachineApi.getAllCurrentLocations();
      let currentLocations = response.data;
      commit("resetCurrentMachineGeometries");
      currentLocations.map(machine => {
        let timeStr = (new Date(machine.dateTime)).toTimeString().substring(0, 8);
        //console.log(timeStr);
        commit("setCurrentMachineGeometry",
        {
          id: machine.id,
          geometry: turf.feature(
            machine.location,
            {
              direction: machine.direction,
              text: machine.model + "<BR>" + timeStr + "<BR>" + i18n.t("activities.path.speed") + " : " + machine.speed + " km/h",
              speed: machine.speed
            }
          )
        })
      });
      commit(MACHINE_LOADING, false);
    }
  },
};
