<template>
  <div>
    <!-- Dose min an max -->
    <Dose />

    <br />

    <!-- Fertilizer composition -->
    <h4 class="subtitle-form">Composition du produit</h4>
    <table>
      <!-- N -->
      <tr>
        <td class="shape-first-cell">
          <h4 class="subtitle-form">N</h4>
        </td>

        <td class="bg-text-color text-infos config-fertilizer-compo">
          {{ getNPK("N", "dose") | empty("0") }} {{ getUnitDesignation(getNPK("N", "unit")) }}
        </td>
      </tr>

      <!-- P -->
      <tr>
        <td class="shape-first-cell">
          <h4 class="subtitle-form">P</h4>
        </td>

        <td class="bg-text-color text-infos config-fertilizer-compo">
          {{ getNPK("P", "dose") | empty("0") }} {{ getUnitDesignation(getNPK("P", "unit")) }}
        </td>
      </tr>

      <!-- K -->
      <tr>
        <td class="shape-first-cell">
          <h4 class="subtitle-form">K</h4>
        </td>

        <td class="bg-text-color text-infos config-fertilizer-compo">
          {{ getNPK("K", "dose") | empty("0") }} {{ getUnitDesignation(getNPK("K", "unit")) }}
        </td>
      </tr>
    </table>

    <div v-if="checkComposition()">
      <table>
        <!-- Other compound -->
        <tr v-for="composition in getCompo()" v-bind:key="composition.id" :value="composition.id">
          <td v-if="checkCompound(composition.compound_symbol)" class="shape-first-cell">
            <h4 class="subtitle-form">{{ composition.compound_symbol }}</h4>
          </td>

          <td
            class="bg-text-color text-infos config-fertilizer-compo"
            v-if="checkCompound(composition.compound_symbol)"
          >
            {{ composition.dose + " " + getUnitDesignation(getNPK(composition.compound_symbol, "unit")) }}
          </td>
        </tr>
      </table>
    </div>

    <br />

    <h4 class="subtitle-form">{{ currentInputStock.otherFeature_label }}</h4>
    <span class="text-infos">
      {{ currentInputStock.otherFeature_designation | empty("Non défini") }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Dose from "@/components/inputstock/details/Dose.vue";

export default {
  name: "fertilizer-composition",
  data() {
    return {
      npk: {},
      nQuantity: 0,
      pQuantity: 0,
      kQuantity: 0,
    };
  },
  components: {
    Dose,
  },
  methods: {
    checkComposition() {
      if(!this.currentProduct) return;

      if (this.currentProduct.hasOwnProperty("composition")) {
        if (this.currentProduct.composition.length > 0) return true;
      } else if (this.currentInputStock.hasOwnProperty("composition")) {
        if (this.currentInputStock.composition.length > 0) return true;
      }
      return false;
    },

    checkCompound(compound) {
      if (compound != "N" && compound != "P" && compound != "K") return true;
      return false;
    },

    getUnitDesignation(idUnit) {
      if (idUnit == null) return "N/A";

      let unitObject = this.inputFormByPtype.compositionUnits.filter(function(elem) {
        if (idUnit == elem.id) return elem;
      });

      if (unitObject.length > 0) return unitObject[0].unit;
      return "N/A";
    },

    getCompo() {
      if (this.readOnly) return this.currentProduct.composition;
      else return this.currentInputStock.composition;
    },

    getNPK(symbol, value) {
      if(!this.currentProduct) return;

      let sym = symbol;
      let composition;

      if (this.currentProduct.hasOwnProperty("composition")) {
        composition = this.currentProduct.composition.filter(function(elem) {
          if (elem.compound_symbol == sym) return elem;
        });

        if (composition.length > 0) return composition[0][value];
      } else if (this.currentInputStock.composition.length > 0) {
        composition = this.currentInputStock.composition.filter(function(elem) {
          if (elem.compound_symbol == sym) return elem;
        });

        if (composition.length > 0) return composition[0][value];
      }

      return null;
    },
  },
  computed: {
    ...mapGetters({
      currentInputStock: "inputStock/currentEntry",
      readOnly: "readOnly",
      inputFormByPtype: "inputFormByPtype",
      currentProduct: "currentProduct",
    }),
  },
};
</script>

<style lang="scss" scoped>
/**
 * Width for the composition inputs .
 */
.width-composition-inputs {
  border-radius: 3px;
  width: 40%;
  height: 25px;
}

table {
  border-collapse: separate;
  border-spacing: 5px 5px;
}

/**
 * Config for the density.
 */
.config-fertilizer-compo {
  text-align: center;
  padding: 0px 5px 0px 5px !important;
  width: 100px;
}

.shape-first-cell {
  width: 120px;
}
/**
 * Config for the npk.
 */
.config-npk {
  padding-left: 5px;
  padding-right: 5px;
}
/**
 * Border npk.
 */
.first-cell-border {
  border-radius: 3px 0px 0px 3px;
}
/**
 * Border npk.
 */
.second-cell-border {
  border-radius: 0 3px 3px 0;
}
</style>
