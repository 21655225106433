<template>
  <div>
    <b-form @submit="save">
      <!-- If there is a geometry -->
      <MCT_ModifyGeometry ref="modifyView" v-if="currentCropfieldHasGeometry" :modifyMode.sync="modifyMode" />
      <!-- If no geometry, define the geometry -->
      <MCT_DefineGeometry ref="defineView" v-else :modifyMode.sync="modifyMode" />

      <!-- Cancel / save buttons -->
      <b-container class="mt-5 p-0" v-if="modifyMode || mapCutMode > 0">
        <b-row align-h="end">
          <b-col cols="6">
            <b-button class="button-default align-bottom" @click="reset">
              {{ $t("cropfields.details.cancel") }}
            </b-button>
          </b-col>
          <b-col cols="6">
            <b-button class="button-default align-bottom" type="submit">
              {{ $t("cropfields.details.save") }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import MapCutToolsMixin from "./MapCutToolsMixin";
import MCT_ModifyGeometry from "./MCT_ModifyGeometry";
import MCT_DefineGeometry from "./MCT_DefineGeometry";

export default {
  data() {
    return {
      modifyMode: false,
    };
  },
  mixins: [MapCutToolsMixin],
  components: { MCT_DefineGeometry, MCT_ModifyGeometry },
  methods: {
    ...mapMutations({
      setMapCutMode: "cropfield/setMapCutMode",
      resetEditedEntry: "cropfield/resetEditedEntry",
      setCurrentId: "cropfield/setCurrentId",
    }),
    ...mapActions({
      createEntry: "cropfield/createEntry",
      updateEntry: "cropfield/updateEntry",
      updateGeometry: "cropfield/updateGeometry",
      fetchIslandGeometries: "cropfield/fetchIslandGeometries",
    }),
    // cancel modifications
    reset() {
      if (this.currentCropfieldHasGeometry) {
        this.$refs.modifyView.reset();
      } else {
        this.$refs.defineView.reset();
      }
    },
    // save !
    async save(event) {
      event.preventDefault();

      if (this.currentCropfieldHasGeometry) {
        this.$refs.modifyView.save();
      } else {
        this.$refs.defineView.save();
      }
    },
  },
  computed: {
    ...mapGetters({
      mapCutMode: "cropfield/mapCutMode",
      currentCropfieldHasGeometry: "cropfield/currentCropfieldHasGeometry",
    }),
  },
};
</script>

<style lang="scss" scoped>
.field {
  position: relative;
  background: #ebebeb;
  border-radius: 10px;
}

.new-cropfield {
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  font-size: 0.8rem;
  color: $dark-grey;
  font-style: italic;
}

.guide-text {
  font-size: 0.9rem;
}

.color-dark {
  color: $text-color;
}
</style>
