var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['h-100', { 'overflow-hidden': _vm.loading }]},[_c('b-overlay',{staticClass:"position-absolute w-100 h-100",attrs:{"show":_vm.loading,"no-wrap":true,"variant":"white","opacity":"0.7","blur":"0","rounded":"sm"}}),(!_vm.loading && _vm.showCloseButton())?_c('closeButton',{staticClass:"detail-close-button close-button-grey",attrs:{"on-click":() => _vm.checkChanges(() => _vm.closeOrGoBack())}}):_vm._e(),_c('div',{staticClass:"position-relative"},[_c('div',{class:['detail-header', {'mb-1': _vm.computedView.subtitle != null, 'mb-4': _vm.computedView.subtitle == null}]},[_c('div',[(_vm.computedView.title)?_c('h3',{staticClass:"title-form"},[_vm._v(_vm._s(_vm.computedView.title))]):_vm._e(),(_vm.computedView.subtitle)?_c('span',{staticClass:"subtitle-form"},[_vm._v(_vm._s(_vm.computedView.subtitle))]):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"ml-1 action-buttons"},[(_vm.isEditMode() != null && !_vm.isEditMode())?_c('editButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.showEditButton({ entry: _vm.currentEntry })),expression:"showEditButton({ entry: currentEntry })"},{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"on-click":() => _vm.onEdit({ entry: _vm.currentEntry, router: _vm.$router }),"title":this.$t(_vm.onHoverEditButton({ entry: _vm.currentEntry }))}}):_vm._e(),(_vm.showBackButton())?_c('backButton',{staticClass:"ml-1",attrs:{"on-click":() => {
              _vm.checkChanges(() => _vm.onBack({ entry: _vm.currentEntry, router: _vm.$router, isEditMode: _vm.isEditMode }));
            }}}):_vm._e(),(_vm.showDuplicateButton())?_c('duplicateButton',{staticClass:"ml-1",attrs:{"on-click":() => {
              _vm.checkChanges(() => _vm.onDuplicate({ entry: _vm.currentEntry, router: _vm.$router, isEditMode: _vm.isEditMode }));
            }}}):_vm._e(),(_vm.showPinButton())?_c('pinButton',{staticClass:"ml-1",attrs:{"on-click":() => {
              _vm.checkChanges(() => _vm.onPin({ entry: _vm.currentEntry, router: _vm.$router, isEditMode: _vm.isEditMode }));
            }}}):_vm._e()],1)]),_c('b-form',{ref:"detailForm",staticClass:"pb-5",on:{"submit":_vm.onSubmit}},[(_vm.computedView.rows)?_c('DetailContainer',_vm._b({attrs:{"storeReadGetter":_vm.storeReadGetter,"storeEditedGetter":_vm.storeEditedGetter,"storeEditAction":_vm.storeEditAction}},'DetailContainer',{ rows: _vm.filteredRows },false)):_vm._e(),_c('div',{staticClass:"mt-4"},[(_vm.canSave)?_c('b-button',{staticClass:"button-default",attrs:{"type":"button"},on:{"click":_vm.onSave}},[_vm._v("Enregistrer")]):_vm._e(),(this.checkOption())?_c('div',{staticClass:"relocate-button d-flex justify-content-center"},[_c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])]):_vm._e(),(_vm.canDelete)?_c('b-button',{staticClass:"button-danger-input mt-4",attrs:{"type":"button"},on:{"click":_vm.onDelete}},[_vm._v("Supprimer")]):_vm._e()],1)],1),_c('NewsModal'),_c('GenerateActivitiesModal'),_c('ExportedActivityWarningModal')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }