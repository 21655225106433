<template>
  <div>
    <div :class="['toolBox', { 'collapsed': !expand }]">
			<!-- En-tête -->
      <b-row>
        <b-col :class="['text-left', {'ml-2': expand }]">
          <img
						class="toolOption"
						:src="toolBox" alt=""
          	:title="$t('cropfields.map.toolbox_help_button')"
						@click="setExpand(true)"
					/>
        </b-col>
				<b-col class="text-right mr-2" v-if="expand">
					<img
						class="toolOption"
						:src="minimizeIcon" alt=""
          	:title="$t('minimize')"
						@click="closeToolBox()"
					/>
				</b-col>
      </b-row>
			<div v-if="expand" class="m-2">
				<!-- Options -->
				<b-row 
					v-for="option in toolOptions" :key="option.name"
					:class="['mx-1 mt-2 toolOption', {'selectedOption': option.value}]"
					@click="selectOption(option)"
				>
					<b-col cols="2">
						<img
							class="toolOption"
							:src="option.src" alt=""
						/>
					</b-col>
					<b-col cols="10">
						{{ option.name }}
					</b-col>
				</b-row>
			</div>
    </div>
		<!-- Indications pour utilisation des outils -->
		<div>
			<b-row v-for="option in toolOptions" :key="option.name">
				<div class="helpBox" v-if="option.value"> {{ option.help }} </div>
			</b-row>
		</div>
  </div>
</template>
<script>
import toolBox from "@/assets/images/drawing_tools.svg";
import minimizeIcon from "@/assets/images/minimize_icon.svg";
import rulerIcon from "@/assets/images/ruler_icon.svg";
import distanceIcon from "@/assets/images/distance_icon.svg";
import surfaceIcon from "@/assets/images/surface_icon.svg";
export default {
  data() {
    return {
      toolBox: toolBox,
			minimizeIcon: minimizeIcon,
			rulerIcon: rulerIcon,
			distanceIcon: distanceIcon,
			surfaceIcon: surfaceIcon,
			expand: false,
			toolOptions: [],
    };
  },

	mounted() {
		// Initialisation de la liste des options
		this.toolOptions = [
			{name: this.$t('cropfields.map.toolOptions.ruler'), src: this.rulerIcon, value: false, action: 'ruler', help: this.$t('cropfields.map.toolOptions.ruler_help')},
			{name: this.$t('cropfields.map.toolOptions.min_distance'), src: this.distanceIcon, value: false, action: 'min_distance', help: this.$t('cropfields.map.toolOptions.min_distance_help')},
			{name: this.$t('cropfields.map.toolOptions.area'), src: this.surfaceIcon, value: false, action: 'area', help: this.$t('cropfields.map.toolOptions.area_help')}
		];
	},

  methods: {
		setExpand(value) {
			this.expand = value;
		},
    closeToolBox(){
      this.setExpand(false)
      this.disableOtherOptions("")
    },
		selectOption(option) {
			option.value = !option.value;
			this.disableOtherOptions(option);
			this.$emit(option.action, option.value);
		},

		// Déselectionne toutes les options sauf celle passée en paramètre
		disableOtherOptions(option) {
			this.toolOptions.forEach( opt => {
				if (opt.name != option.name && opt.value) {
					opt.value = false;
					this.$emit(opt.action, opt.value);
				}
			});
		}
	},
};
</script>
<style lang="scss" scoped>
.toolBox {
  position: absolute;
  top: 8px;
  padding: 2px;
	margin-left: 9px;
  left: 27rem;
  background-color: rgba(0, 60, 136, 0.5);
  border-radius: 4px;
	border: 3px solid #ffffff6f;
  &.collapsed {
    width: 31px;
    height: 31px; 
  }
}
.printOption {
  cursor: pointer;
}
.toolOption {
	color: #ffffffcf;
	font-size: 0.9rem;
	cursor: pointer;
}
.selectedOption {
	background-color: #54B6FF7F;
}

.helpBox {
	width: 200px;
	height: auto;
	position: absolute;
	padding: 2px;
	text-align: center;
	left: 46rem;
	top: 37px;
	background-color: #267BEF7F;
	font-size: 0.9rem;
	border: 3px solid #ffffff6f;
	color: #ffffffcf;
}
</style>