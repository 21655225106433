<template>
    <div>
		<div v-for="family in structuredActivities" :key="family.familyDesignation">
			<div v-if="family.body.length != 0">
				<!-- Nom de la famille d'activité -->
				<div>
					<span class="title-form">{{ family.familyDesignation }}</span>
				</div>
				<div v-for="activity in family.body" :key="activity.id">
					<!-- date de début de type de l'activité -->
					<b-row @click="goToActivity(activity.id)" class="activity-row">
						<b-col cols = "3" ><span class="subtitle-edit-form"> {{ activity.startDate }} </span></b-col>
						<b-col cols = "6"> <span class="subtitle-edit-form"> {{ activity.activityType }} </span> </b-col>
						<b-col cols = "3" class="text-right"> <span class="subtitle-edit-form"> {{ activity.surface ? (activity.surface + " ha") : "" }} </span> </b-col>
					</b-row>
					<!-- intrants de l'activité et leurs doses -->

					<div v-if="activity.needInputs" class="text-infos bg-white">
						<b-row v-for="input in activity.inputs" :key="input.id">
							<b-col cols = "6"> <span class="text-infos">{{ input.inputsStock_name }} </span></b-col>
							<b-col cols = "6" class="text-right"> <span class="text-infos">{{ formatNumber(fixDigits(input.quantity, 5)) }} {{ input.doseUnit_unit }} </span></b-col>
						</b-row>
						<b-row v-if="activity.inputs.length == 0">
							<b-col cols = "12"> <span class=" text-infos">{{ $t("cropfields.details.no_product")}}</span></b-col>
						</b-row>
					</div>
					<div v-else class="text-infos bg-white">
						<b-row v-for="tool in activity.tools" :key="tool.id">
							<span class="text-infos">{{ tool.machineType_designation }} {{ tool.model }}</span>
						</b-row>
					</div>
				</div>
				<hr>
			</div>
		</div>
		<div class="mt-3">
			<!-- Bouton permettant de rediriger vers la création d'activité sur la parcelle en question -->
			<b-button
				class="button-default"
				@click="goToNewActivity()"
			>
				{{ $t("cropfields.details.create_new_activity")}}
			</b-button>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { dateToSlashString } from "@/utils/commons";
import { i18n } from "@/i18n.js";
import { fixDigits, formatNumber} from "@/utils/commons";

export default {
	data() {
		return {
			structuredActivities: [],
		};
	},

	props: ["id"],

	async mounted() {
		await this.$store.dispatch("activity/getActivitiesByCropfieldWithInputs", {idCropField: this.id});
		await this.$store.dispatch("getActivityFamilies");
		this.sortActivityFamilies();
		this.getStructuredActivities();
	},

  computed: {
    ...mapGetters({
      currentId: "cropfield/currentId",
      currentEntry: "cropfield/currentEntry",
      currentEditedEntry: "cropfield/editedEntry",
      entries: "cropfield/entries",
      entriesChecked: "cropfield/entriesChecked",
      campaigns: "campaigns",
	  	activityFamilies: "activityFamilies",
			activitiesByCropfield: "activity/activitiesByCropfield",
    }),

  },

	methods: {
		/**
		 * @description trie le tableau des familles d'activités par ordre croissant de l'attribut "orderInReports"
		 */
		fixDigits,
		formatNumber,
		sortActivityFamilies(){
			this.activityFamilies.sort(function compare(a, b) {
				if(a.orderInReports < b.orderInReports)
					return -1;
				if(a.orderInReports > b.orderInReports)
					return 1;
				if(a.orderInReports == b.orderInReports)
					return 0;
		  });
		},

		/**
		 * @description retourne un tableau contenant les activités d'une famille spécifique sur la parcelle
		 * @param {array} oneActivityFamily une famille d'activités
		 */
    	getActivitiesByFamily(oneActivityFamily) {
			let activitiesByFamily = [];
			this.activitiesByCropfield.forEach(element => {
				if(element.activityFamily == oneActivityFamily.id) {
					activitiesByFamily.push({
						"id": element.id,
						"startDate": this.dateToSlash(element.startDate),
						"activityType": element.activityType_designation,
						"needInputs": element.activityType_needInputs,
						"inputs": element.inputs,
						"tools": element.tools,
						"surface": element.surface
					});
				}
			});
			return activitiesByFamily;
		},

		/**
		 * @description regroupe les activités sur une parcelle par famille d'activités
		 */
		getStructuredActivities() {
			this.structuredActivities = [];
			this.activityFamilies.forEach(element => {
				this.structuredActivities.push({
					"familyDesignation": element.designation,
					"body": this.getActivitiesByFamily(element)
				});
			});
		},

		/**
		 * @description prend une date et la retourne sous la forme dd/mm/AAAA
		 */
		dateToSlash(aDate) {
			if(!aDate)
				return i18n.t("harvestStorage.details.no_date_listed");
			else
				return dateToSlashString(new Date(aDate));
		},

		/**
		 * @description Affiche le détail d'une activité
		 */
		goToActivity(id) {
			this.$router.push({ name: "activity.details", params: { activity_id: id } });
		},

		/**
		 * Redirige vers la création d'activité avec la parcelle présélectionnée
		 */
		goToNewActivity() {
			this.$router.push({ name: "cropfield.activity.new.edit", params: { cropfield_id: this.id } });
		}
  	},

}
</script>

<style lang="scss" scoped>
.activity-row {
  cursor: pointer;
//   border-top: 1px solid $grey;
}
</style>