<template>
  <div>
    <b-row>
      <b-col class="col-8">
        <h4 class="mb-3">
          <i>Fournitures du sol</i>
        </h4>
      </b-col>
    </b-row>

    <!--mh pi mr mrF-->
    <b-row>
      <b-col class="pr-4 col-6" >
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.Mh">{{$t('cropfields.fertilization.needsCalculation.Mh')}}</h4>
        <NumericInput
          inputRef="input"
          v-if="otherCalcul"
          :value="Mh"
          @input="i => {Mh = i}"
          @blur="updateMh()"
          :disabled="!edit_mode"
        />
        <p class="text-infos bg-white" v-else>
            <span> {{ Mh !== null ? Mh : "-" }} </span>
         </p>
      </b-col>


      <b-col class="pr-4 col-6" >
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.Pi">{{$t('cropfields.fertilization.needsCalculation.Pi')}}</h4>
        <NumericInput
        inputRef="input"
          v-if="otherCalcul"
          :value="Pi"
          @input="i => {Pi = i}"
          @blur="updatePi()"
          :disabled="!edit_mode"
        />
        <p class="text-infos bg-white" v-else>
            <span> {{ Pi !== null ? Pi : "-" }} </span>
         </p>
      </b-col>


      <b-col class="pr-4 col-6" >
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.Mr">{{$t('cropfields.fertilization.needsCalculation.Mr')}}</h4>
        <NumericInput
          v-if="otherCalcul"
          :value="Mr"
          @input="i => {Mr = i}"
          @blur="updateMr()"
          :disabled="!edit_mode"
        />
        <p class="text-infos bg-white" v-else>
          <span>{{ Mr !== null ? Mr : "-" }}</span>
         </p>
      </b-col>


      <b-col class="pr-4 col-6" >
        <h4 class="subtitle-form" v-b-tooltip.hover.html="fertiToolTips.MrJ">{{$t('cropfields.fertilization.needsCalculation.MrJ')}}</h4>
        <NumericInput
          v-if="otherCalcul"
          inputRef="input"
          :value="MrJ"
          @input="i => {MrJ = i}"
          @blur="updateMrJ()"
          :disabled="!edit_mode"
        />
        <p class="text-infos bg-white" v-else>
            <span> {{ MrJ !== null ? MrJ : "-" }} </span>
         </p>
      </b-col>


    </b-row>
  </div>
</template>

<script>
import NumericInput from "@/components/commons/NumericInput.vue";

export default {
  name: "fertilization-sol",
  data(){
    return{
      otherCalcul: false,
      edit_mode: false,
      Mh: null,
      Pi: null,
      Mr: null,
      MrJ: null,
    }
  },
  created() {
    this.init();
  },
  props: [
    'editMode', 
    'fertilisationNeedsCalculation', 
    'fertilisationNeedsCalculationList', 
    'fertilisationNeedsCalculationOption',
    'fertiToolTips',
  ],
  methods: {
    init: async function() {
      await this.loadData();
    },
    async loadData(){
      if(typeof this.fertilisationNeedsCalculation != 'undefined') {
        await this.editCheck()
        this.Mh = this.fertilisationNeedsCalculation.Mh
        this.Pi = this.fertilisationNeedsCalculation.Pi
        this.Mr = this.fertilisationNeedsCalculation.Mr
        this.MrJ = this.fertilisationNeedsCalculation.MrJ
      }
    },
    async editCheck(){
      this.edit_mode = this.editMode
    },
    async updateMh(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "Mh", value: this.Mh });
    },
    async updatePi(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "Pi", value: this.Pi });
    },
    async updateMr(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "Mr", value: this.Mr });
    },
    async updateMrJ(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "MrJ", value: this.MrJ });
    },
  },
  watch: {
    fertilisationNeedsCalculation: {
      handler: 'loadData',
      immediate: true
    },
    editMode: {
      handler: 'editCheck',
      immediate: true
    }
  },
  components: {
    NumericInput,
  },
};

</script>

<style>
.disabled-input:disabled {
  background-color: white !important;
  border: none;
}
</style>