import Gateway from "./base";

export default class ServicesApi {
  /**
   * Get services en fonction de la query activityType ou machines
   *
   * @param query
   */
  static getServices(query) {
    //générer correctement l'URL
    const queryString = new URLSearchParams(query).toString();
    return Gateway.get(`/api/services/${queryString ? '?' + queryString : ''}`);
  }
}