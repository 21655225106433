import Gateway from "./base";

export default class NoteApi {
  /**
   * Get All notes
   *
   */
  static getNotes(params) {
    return Gateway.get("/api/notes/", params);
  }

  /**
   * Get one note by id
   *
   * @param id
   */
  static getOneNote(id) {
    return Gateway.get(`/api/notes/${id}/`);
  }

  /**
   * Create note
   *
   * @param data
   */
  static createNote(data) {
    return Gateway.post(`/api/notes/`, data, { "Content-Type": "multipart/form-data" });
  }

  /**
   * Update note
   *
   * @param data
   * @param id
   */
  static updateNote(data, id) {
    return Gateway.patch(`/api/notes/${id}/`, data, { "Content-Type": "multipart/form-data" });
  }

  /**
   * delete note
   *
   * @param id
   */
  static deleteNote(id) {
    return Gateway.delete(`/api/notes/${id}/`);
  }

  /**
   * Get all note topics
   * @returns 
   */
  static getNoteTopics() {
    return Gateway.get(`/api/noteTopics/`);
  }




}
