import Gateway from "./base";

export default class WarehouseApi {
  /**
   * Get All warehouses
   */
  static getAllWarehouses() {
    return Gateway.get(`/api/warehouses/`);
  }

  /**
   * Get one warehouses by id
   *
   * @param id
   */
  static getWarehouse(id) {
    return Gateway.get(`/api/warehouses/${id}`);
  }

  /**
   * Create Warehouse
   *
   * @param data
   */
  static createWarehouse(data) {
    return Gateway.post(`/api/warehouses/`, data);
  }

  /**
   * Update Warehouse
   *
   * @param data
   * @param id
   */
  static updateWarehouse(data, id) {
    return Gateway.put(`/api/warehouses/${id}/`, data);
  }

  /**
   * delete Warehouse
   *
   * @param id
   */
  static deleteWarehouse(id) {
    return Gateway.delete(`/api/warehouses/${id}/`);
  }
}
