<template>
  <div class="home-widget">
    <b-container>
      <b-row class="home-widget-header">
        <b-col class="align-items-center">
          <h3 class="home-widget-title my-3">{{ $t("home.weather.title") }}</h3>
        </b-col>
      </b-row>
      <b-row class="weather-row">
        <p class="m-3">
          {{ $t("home.commons.soon_available") }}
        </p>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.weather-row {
  height: 20vh;
}
</style>
