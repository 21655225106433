<template>
  <div>
    <b-row>
      <b-col class="col-12">
        <h4 class="mb-3">
          <i>Couvert végétal</i>
          <i v-if="this.fertilisationNeedsCalculationOption.coverCrop_name"> {{ "(" + coverCropName + " " + $t('fertilization_plan.edit.sown_on') + " " + coverCropSowingDate + ")"}}</i>
          <i v-else> {{ "(" + $t('fertilization_plan.edit.no_cover_crop') + ")" }}</i>
        </h4>
      </b-col>
    </b-row>

    <b-row>
       <!--Cipan-->
       <b-col class="col-4 ml-3 subtitle-form" >
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.cipan">{{$t('cropfields.fertilization.needsCalculation.cipan')}}</h4>
        <b-form-checkbox size="sm" v-model="cipan" @input="updateCipan()" :disabled="!edit_mode"/>
      </b-col>
    </b-row>
   

    <!--IF Cipan-->
    <b-row v-if="cipan">
      <!--coverCropFamily-->
      <b-col class="col-6" >
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.coverCropFamily">{{$t('cropfields.fertilization.needsCalculation.coverCropFamily')}}</h4>
        <template v-if="edit_mode">
        <b-form-select
          :options="this.fertilisationNeedsCalculationList.coverCropFamilies"
          value-field="code"
          text-field="designation"
          v-model="coverCropFamily"
          size="sm"
          @change="updateCoverCropFamily()"
        />
      </template>
      <template v-else >
        <p class="text-infos bg-white">
            <span> {{ this.fertilisationNeedsCalculation.coverCropFamily_designation ? this.fertilisationNeedsCalculation.coverCropFamily_designation : "-" }} </span>
          </p>
        </template>
      </b-col>

      <!--coverCropDensity-->
      <b-col class="col-6" >
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.coverCropDensity">{{$t('cropfields.fertilization.needsCalculation.coverCropDensity')}}</h4>
        <template v-if="edit_mode">
        <b-form-select
          :options="this.fertilisationNeedsCalculationList.coverCropDensities"
          value-field="code"
          text-field="designation"
          v-model="coverCropDensity"
          size="sm"
          @change="updateCoverCropDensity()"
        />
        </template>
        <template v-else>
          <p class="text-infos bg-white">
            <span> {{ this.fertilisationNeedsCalculation.coverCropDensity ? this.fertilisationNeedsCalculation.coverCropDensity : "-" }} </span>
          </p>
        </template>

      </b-col>

      <!-- coverCropDestructionPeriods = MR-->
      <b-col class="col-6">
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.destructionPeriod">{{$t('cropfields.fertilization.needsCalculation.coverCropDestructionPeriod')}}</h4>
        <template v-if="edit_mode">
           <b-form-select
           :options="this.fertilisationNeedsCalculationList.destructionPeriods"
           value-field="code"
           text-field="designation"
           v-model="destructionPeriod"
           @change="updateDestructionPeriod()"
           size="sm"
         />
        </template>
        <template v-else >
        <p class="text-infos bg-white">
            <span> {{ this.fertilisationNeedsCalculation.destructionPeriod_designation ? this.fertilisationNeedsCalculation.destructionPeriod_designation : "-" }} </span>
          </p>
        </template>
      </b-col>

      <!--MRCI-->
      <b-col class="col-6" >
        <h4 class="subtitle-form" v-b-tooltip.hover.html="fertiToolTips.MrCi">{{$t('cropfields.fertilization.needsCalculation.MrCi')}}</h4>
          <p class="text-infos bg-white">
            <span> {{ this.MrCi ? this.MrCi : "-" }} </span>
          </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: "fertilization-couvert",
  data(){
    return{
      edit_mode: false,
      coverCropName: null,
      coverCropSowingDate: null,
      cipan: false,
      coverCropFamily: null,
      coverCropDensity: null,
      destructionPeriod: null,
      MrCi: null,
    }
  },
  created() {
    this.init();
  },
  props: [
    'editMode', 
    'fertilisationNeedsCalculation', 
    'fertilisationNeedsCalculationList', 
    'fertilisationNeedsCalculationOption',
    'fertiToolTips',
  ],
  methods: {
    init: async function() {
      this.cipan = this.fertilisationNeedsCalculation.cipan
      await this.loadData();
    },
    async loadData(){
      if(typeof this.fertilisationNeedsCalculation != 'undefined') {
        await this.editCheck()
        this.coverCropName = this.fertilisationNeedsCalculationOption.coverCrop_name
        this.coverCropSowingDate = this.fertilisationNeedsCalculation.coverCropSowingDate
        this.coverCropFamily = this.fertilisationNeedsCalculation.coverCropFamily
        this.coverCropDensity = this.fertilisationNeedsCalculation.coverCropDensity
        this.destructionPeriod = this.fertilisationNeedsCalculation.destructionPeriod
        this.MrCi = this.fertilisationNeedsCalculation.MrCi
      }
    },
    async editCheck(){
      this.edit_mode = this.editMode
    },
    async updateCipan(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "cipan", value: this.cipan });
    },
    async updateCoverCropFamily(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "coverCropFamily", value: this.coverCropFamily });
    },
    async updateCoverCropDensity(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "coverCropDensity", value: this.coverCropDensity });
    },
    async updateDestructionPeriod(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "destructionPeriod", value: this.destructionPeriod });
    },
    async updateMrCi(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "MrCi", value: this.MrCi });
    },
  },
  watch: {
    fertilisationNeedsCalculation: {
      handler: 'loadData',
      immediate: true
    },
    editMode: {
      handler: 'editCheck',
      immediate: true
    }
  }
};

</script>

<style>
.disabled-input:disabled {
  background-color: white !important;
  border: none;
}
</style>