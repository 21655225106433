<template>
  <div>
    <!-- Treatment Component : Campaign -->
    <!-- Displays a campaign selector -->
    <h4 class="subtitle-form mb-2">
      {{ $t("commons.exports_reports.campaign_selection_title") }}
    </h4>
    <b-form-radio-group
      v-model="campaignChoice"
      :options="campaignOptions"
      name="campaignChoice"
      class="bg-white text-infos"
      stacked
    ></b-form-radio-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import TreatmentFormPartMixin from "@/components/commons/treatments/TreatmentFormPartMixin";
import { TreatmentFormPart } from "@/models/treatments/Treatment.js";

const CAMPAIGN_ACTIVE = "active";
const CAMPAIGN_ALL = "all";

export default {
  mixins: [TreatmentFormPartMixin],
  data() {
    return {
      campaignChoice: CAMPAIGN_ACTIVE,
      campaignOptions: [
        { text: this.$t("commons.exports_reports.active_campaign"), value: CAMPAIGN_ACTIVE },
        { text: this.$t("commons.exports_reports.all_campaigns"), value: CAMPAIGN_ALL },
      ],
    };
  },
  methods: {
    validateForm() {
      this.isValid = true;

      return new TreatmentFormPart(
        "campaign",
        this.campaignChoice == CAMPAIGN_ALL ? CAMPAIGN_ALL : this.currentCampaign.name,
      );
    },
  },
  computed: {
    ...mapGetters({
      currentCampaign: "getCampaign",
    }),
  },
};
</script>

<style></style>
