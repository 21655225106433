import { commonGetters, commonMutations, commonActions } from "./commons.js";

import { i18n } from "@/i18n.js";

import YieldApi from "@/api/yield.api";

export default {
  namespaced: true,
  state: {
    _entries: {},
    currentId: null,
    editedEntry: {},
    entryLoaded: true,
    filterData: {},
    filterSelection: {},
    filters: [],
    yieldForm: {},
    searchQuery: "",
    lastEntryDisplayed: null,
  },
  getters: {
    ...commonGetters(),
    yieldForm: (state) => state.yieldForm,
  },
  mutations: {
    ...commonMutations(),
    setYieldForm(state, data) {
      state.yieldForm = data
    }
  },
  actions: {
    ...commonActions(),
    async fetchEntries({ commit, dispatch, rootGetters }) {

      let currentFarm = rootGetters["farm/currentFarm"];
      if (currentFarm) currentFarm = currentFarm.id;
      const cropField = await dispatch("getFilterValueId", "cropField");
      const variety = await dispatch("getFilterValueId", "variety");
      const yieldId = await dispatch("getFilterValueRaw", "entryId");

      const yields = await YieldApi.getYields({
        campaign: rootGetters.getCampaign.id,
        farmId: currentFarm,
        cropField: cropField,
        variety: variety,
        yieldId: yieldId
      });
      commit("setEntries", { entries: yields.data })
    },
    async getOneYield({ commit }, { id }) {
      const response = await YieldApi.getYield(id);
      commit("setCurrentId", { id });
      commit("updateEntry", { id, data: response.data });
      return response.data;
    },
    async getYieldFromActivity({rootGetters}, {activityId}) {
      const response = await YieldApi.getYields({
        campaign: rootGetters.getCampaign.id,
        activityId: activityId
      });
      return response.data[0];
    },
    async createEntry({ commit }, { entry }) {
      const response = await YieldApi.createYield(entry);
      commit("setCurrentId", { id: response.data.id });
      commit("updateEntry", { id: response.data.id, data: response.data });
      return response.data;
    },

    async updateEntry({ state, commit }, { id, data }) {
      const response = await YieldApi.updateYield(data, id);
      commit("updateEntry", { id: id, data: response.data });
      return response.data;
    },

    async deleteEntry({ commit }, { id }) {
      const response = await YieldApi.deleteYield(id);
      commit("resetCurrentId");
      commit("deleteEntry", { id });
      return response;
    },

    async initFilters({ commit, dispatch, state, rootGetters }) {
      state.filters.forEach(async (filter) => {
      
        const filters = await dispatch("getAllFiltersValues")

        await dispatch("getFiltersYields", {
        query: filter.name,
        campaign: rootGetters.getCampaign.id,
        params: filters
        });
        
      });
    },

    async getFiltersYields({ commit, rootGetters }, { query, campaign, params }) {

      let currentFarm = rootGetters["farm/currentFarm"];
      if (currentFarm) currentFarm = currentFarm.id;

      const response = await YieldApi.getFiltersYields(query, campaign, currentFarm, params);

      commit("setFilterData", {
        name: query,
        elements: response.data,
      });

      return response.data;
    },

    updateFilterSelection({ commit, dispatch }, { filterName, elementId }) {
      commit("setFilter", {
        filterName: filterName,
        elementId: elementId,
      });
      dispatch("initFilters")
    },

    async getYieldForm({ state, getters, commit }) {
      const activityId = state.editedEntry.activity || getters.currentEntry.activity;

      if(!activityId) return;

      const response = await YieldApi.getYieldForm(activityId);

      commit("setYieldForm", response.data);

      return response.data;
    },
  },
};
