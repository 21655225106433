<template>
  <div>
    <b-overlay rounded="sm">
      <!-- close button -->
      <div class="details-buttons">
        <closeButton :on-click="onClose" />
      </div>

      <div>
        <b-container class="p-0 mb-4">
          <b-row no-gutters>
            <b-col cols="auto" class="pr-3">
              <h3 class="title-form">
                {{ $t("inputStock.reports.title") }}
              </h3>
            </b-col>
          </b-row>
        </b-container>

        <b-container class="mt-5 px-5">
          <b-row>
            <b-col cols="12" class="mb-3">
              <router-link
                :to="{ name: 'inputStock.reports.inputstock_usage' }"
                class="btn button-secondary btn-text-small text-center py-3"
              >
                {{ $t("inputStock.reports.inputstock_usage") }}
              </router-link>
            </b-col>

            <b-col cols="12" class="mb-3">
              <router-link
                :to="{ name: 'inputStock.reports.inputstock_state' }"
                class="btn button-secondary btn-text-small text-center py-3"
              >
                {{ $t("inputStock.reports.inputstock_state") }}
              </router-link>
            </b-col>

          </b-row>
        </b-container>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import closeButton from "@/components/commons/CloseButton.vue";

export default {
  methods: {
    onClose() {
      this.$router.push({ name: "inputStock.list" });
    },
  },
  components: {
    closeButton,
  },
};
</script>
<style lang="scss" scoped>
.btn {
  white-space: normal;
}
</style>
