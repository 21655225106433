<template>
  <div>
    <div
      class="activity_icon mr-2"
      :style="{
        'background-color': activityBgColor,
        'border-radius': (square ? '0.4rem' : '50%')
      }"
      v-if="activityIcon"
    >
      <div
        class="activity_icon_path"
        :style="{
          'mask-image': 'url(' + require('@/assets/icons/activities/' + activityIcon + '.svg') + ')',
          'background-color': activityStrokeColor,
        }"
      ></div>
    </div>

    <img
      :src="require('@/assets/images/sesame.svg')"
      width="52"
      style="margin-right: 10px; padding: 14px;"
      alt=""
      v-else
    />
  </div>
</template>

<script>
export default {
  props: {
    activityIcon: {
      type: String,
      default: null,
    },
    activityBgColor: {
      type: String,
      default: "#bedec5",
    },
    activityStrokeColor: {
      type: String,
      default: "#4a614f",
    },
    square: {
      type: Boolean,
      default: false
    }
  },
};
</script>

<style lang="scss" scoped>
.activity_icon_path {
  mask-size: cover;
}

.activity_icon_path,
.activity_icon {
  width: 50px;
  height: 50px;
}
</style>
