import Gateway from "./base";

export default class GraphApi {
  /**
   * Get a graph
   */
  static getGraph({ id, campaign, farm, cropOrder }) {
    let data = { campaign: campaign };
    if (farm) data["farm_id"] = farm;
    if (cropOrder) data["cropOrder"] = cropOrder;
    return Gateway.get(`/api/homepagegraphs/${id}/`, data);
  }
}
