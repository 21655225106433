import Vue from "vue";
import store from "@/store";
import { i18n } from "@/i18n.js";

export const t = (key) => i18n.t(key);

export const storeGet = (getter) => store.getters[getter];
export const storeDispatch = async(action, payload) => await store.dispatch(action, payload);
export const storeCommit = (mutation, payload) => store.commit(mutation, payload);

export const components = {
  // commons
  FieldInfo: () => import("@/components/commons/details/FieldInfo.vue"),
  CropfieldSelect: () => import("@/components/commons/editable/CropfieldSelect.vue"),
  MachinesByActivity: () => import("@/components/commons/details/MachinesByActivity.vue"),
  DateInfo: () => import("@/components/commons/details/DateInfo.vue"),
  CropsList: () => import("@/components/commons/details/CropsList.vue"),

  Inputs: () => import("@/components/commons/details/Inputs.vue"),
  Machines: () => import("@/components/commons/details/Machines.vue"),
  Prestation: () => import("@/components/commons/details/Prestation.vue"),

  ProductSelection: () => import("@/components/inputstock/details/ProductSelection.vue"),
  ProductTypeSelection: () => import("@/components/inputstock/details/ProductTypeSelection.vue"),
  ComponentRadioChoice: () => import("@/components/inputstock/details/ComponentRadioChoice.vue"),
  ProductInStock: () => import("@/components/inputstock/details/ProductInStock.vue"),
  ProductDoseForm: () => import("@/components/inputstock/details/ProductDoseForm.vue"),
  ProductDose: () => import("@/components/inputstock/details/ProductDose.vue"),
  ProductInfo: () => import("@/components/inputstock/details/ProductInfo.vue"),
  ProductUsage: () => import("@/components/inputstock/details/ProductUsage.vue"),
  ProductRisksButton: () => import("@/components/inputstock/details/ProductRisksButton.vue"),
  SeedTreatment: () => import("@/components/inputstock/details/SeedTreatment.vue"),
  FarmSeedCheckBox: () => import("@/components/inputstock/details/FarmSeedCheckBox.vue"),


  InputStockProductRisks: () => import("@/views/inputStock/InputStockProductRisks.vue"),

  ProductCompositionDetail: () => import("@/components/inputstock/details/ProductCompositionDetail.vue"),

  ProductName: () => import("@/components/inputstock/details/ProductName.vue"),
  ProductCompositionDetailForm: () => import("@/components/inputstock/details/ProductCompositionDetailForm.vue"),
  ProductOtherElements: () => import("@/components/inputstock/details/ProductOtherElements.vue"),
  ProductOtherFeature: () => import("@/components/inputstock/details/ProductOtherFeature.vue"),

  ProductOtherFeatureForm: () => import("@/components/inputstock/details/ProductOtherFeatureForm.vue"),
  ProductPercentageNPro: () => import("@/components/inputstock/details/ProductPercentageNPro.vue"),

  ProductDensityForm: () => import("@/components/inputstock/details/ProductDensityForm.vue"),
  ProductDensity: () => import("@/components/inputstock/details/ProductDensity.vue"),

  ProductsMixAdd: () => import("@/components/inputstock/edit/ProductsMixAdd.vue"),
  ProductsMixDoses: () => import("@/components/inputstock/edit/ProductsMixDoses.vue"),

  DetailContainer: () => import("@/views/common/DetailContainer.vue"),

  InputStockChooseFamily: () => import("@/components/inputstock/details/InputStockChooseFamily.vue"),

  InputStockDetails: () => import("@/views/inputStock/InputStockDetails.vue"),
  EditStockStore: () => import("@/components/inputstock/details/EditStockStore.vue"),

  keqNForm: () => import("@/components/inputstock/details/keqNForm.vue"),

  SeedTreatmentForm: () => import("@/components/inputstock/details/SeedTreatmentForm.vue"),


  //sous-composants pour la section activité
  //Détails d'une activité
  DateAndTime: () => import("@/components/activity/details/DateAndTime.vue"),
  Harvest: () => import("@/components/activity/details/Harvest.vue"),
  InfosActivity: () => import("@/components/activity/details/InfosActivity.vue"),
  Notes: () => import("@/components/activity/details/Notes.vue"),
  Path: () => import("@/components/activity/details/Path.vue"),
  GrowthStage: () => import("@/components/activity/details/GrowthStage.vue"),
  ReasonTargets: () => import("@/components/activity/details/ReasonTargets.vue"),
  WorkedSurface: () => import("@/components/activity/details/WorkedSurface.vue"),
  EditOperator: () => import("@/components/activity/details/edit/EditOperator.vue"),
  NewsActivity: () => import("@/components/activity/details/NewsActivity.vue"),

  //Edition et ajout d'une activité
  EditActivityDate: () => import("@/components/activity/edit/EditActivityDate.vue"),
  EditGrowthStage: () => import("@/components/activity/edit/EditGrowthStage.vue"),
  EditInfosActivity: () => import("@/components/activity/edit/EditInfosActivity.vue"),
  EditMachines: () => import("@/components/commons/details/edit/EditMachines.vue"),
  EditNotes: () => import("@/components/activity/edit/EditNotes.vue"),
  EditTransport: () => import("@/components/activity/edit/EditTransport.vue"),
  EditHarvest: () => import("@/components/activity/edit/EditHarvest.vue"),
  ExportWarning: () => import("@/components/activity/edit/ExportWarning.vue"),
  EditActivityYield: () => import("@/components/activity/edit/EditActivityYield.vue"),
  EditActivityMultiDate: () => import("@/components/activity/edit/EditActivityMultiDate.vue"),
  EditPrestation: () => import("@/components/commons/details/edit/EditPrestation.vue"),

  //Sous-composants pour la section de machines
  Maintenances: () => import("@/components/machines/details/Maintenances.vue"),
  FuelFill: () => import("@/components/machines/details/FuelFill.vue"),
  MachineInfo: () => import("@/components/machines/details/MachineInfo.vue"),
  MachineDetails: () => import("@/views/machines/MachineDetails.vue"),
  MachineEdit: () => import("@/components/machines/edit/MachineEdit.vue"),

  //Sous-composants pour la section des récoltes
  WarehouseSelect: () => import("@/components/harvestStorage/edit/WarehouseSelect.vue"),
  ProductSelect: () => import("@/components/harvestStorage/edit/ProductSelect.vue"),
  EditHarvestDate: () => import("@/components/harvestStorage/edit/EditHarvestDate.vue"),
  EditQuantity: () => import("@/components/harvestStorage/edit/EditQuantity.vue"),
  EditUnit: () => import("@/components/harvestStorage/edit/EditUnit.vue"),

  //Sous-composants pour la section des rendements
  EditYield: () => import("@/components/yield/edit/EditYield.vue"),
  EditUnitYield: () => import("@/components/yield/edit/EditUnitYield.vue"),
  EditSurface: () => import("@/components/yield/edit/EditSurface.vue"),

  //Tours
  ActivityType: () => import("@/components/tour/details/ActivityType.vue"),
  CropfieldList: () => import("@/components/tour/details/CropfieldList.vue"),
  TourDates: () => import("@/components/tour/details/TourDates.vue"),
  ActivityFamilyPicker: () =>  import("@/components/tour/edit/ActivityFamilyPicker.vue"),
  EditActivityGrowthStage: () => import("@/components/tour/edit/EditActivityGrowthStage.vue"),

  EditDate: () => import("@/components/commons/details/edit/EditDate.vue"),
  EditCheckbox: () =>  import("@/components/commons/details/edit/EditCheckbox.vue"),
  EditTextarea: () =>  import("@/components/commons/details/edit/EditTextarea.vue"),
  EditText: () =>  import("@/components/commons/details/edit/EditText.vue"),
  EditActivityType: () =>  import("@/components/commons/details/edit/EditActivityType.vue"),
  EditCrop: () =>  import("@/components/commons/details/edit/EditCrop.vue"),

  EditCropfields: () =>  import("@/components/commons/details/edit/EditCropfields.vue"),
  EditInputs: () => import("@/components/commons/details/edit/EditInputs.vue"),
  EditTargets: () => import("@/components/commons/details/edit/EditTargets.vue"),
  EditReasons: () => import("@/components/commons/details/edit/EditReasons.vue"),
};

export const comp = (args) => {
  if (typeof args === "object") return _component(args);
  else return _component({ component: args });
};

const _component = ({ component, props, title, cols, condition, editMany}) => {
  if (!condition) condition = () => true;
  return (
    {
      title: title,
      cols: cols,
      condition: condition,
      editMany: editMany,
      component: {
        is: components[component],
        props: {
          ...props,
        },
      },
    } ||
    {}
  );
};

export const choice = ({ condition, onChange, choices, onInit, editMany}) => {
  if (!condition) condition = () => 0;
  return comp({
    component: "ComponentRadioChoice",
    props: {
      selected: condition(),
      onChange: onChange,
      onInit: onInit,
      editMany: editMany,
      choices: choices.map((c) => {
        return { title: c.title, component: c.choice.component, props: c.props, display: c.display ||
           {}, editMany: c.editMany, };
      }),
    },
  });
};

export const container = (...components) => {
  return comp({
    component: "DetailContainer",
    props: {
      rows: components,
    },
  });
};

export const generateDetailViews = (views, listComponent, filterComponent, detailComponent) =>
  views.map((v) => {
    return {
      path: v.path,
      name: v.name,
      components: {
        default: listComponent,
        filtering: filterComponent,
        sidebar: detailComponent,
      },
      props: {
        sidebar: {
          view: v.view,
          onInit: v.onInit,
          onBack: v.onBack,
          onPin: v.onPin,
          onDuplicate: v.onDuplicate,
          onEdit: v.onEdit,
          onClose: v.onClose,
          goToList: v.goToList,
          goToDetail: v.goToDetail,
          isNewMode: v.isNewMode,
          isEditMode: v.isEditMode,
          showCloseButton: v.showCloseButton,
          showBackButton: v.showBackButton,
          showEditButton: v.showEditButton,
          showPinButton: v.showPinButton,
          showDuplicateButton: v.showDuplicateButton,
          storeReadGetter: v.storeReadGetter,
          storeEditedGetter: v.storeEditedGetter,
          storeEditAction: v.storeEditAction,
          onHoverEditButton: v.onHoverEditButton,
        },
      },
    };
  });
