<template>
  <div class="box p-2">
    <h4 class="font-weight-bold mb-4">{{ $t("activities.path.title") }}</h4>

    <div class="mt-3">
      <h4 class="subtitle-form mb-1">{{ $t("activities.path.machines") }}</h4>
      <div v-if="fromMachine" class="font-weight-bold">{{ currentMachine.model }}</div>
      <div v-else>
        <div class="machine font-weight-bold" v-for="(machine, index) in currentActivity.machines" :key="index">
          {{ machine.model }}
        </div>
      </div>
    </div>
    <b-form>
      <div class="mt-3">
        <h4 class="subtitle-form mb-2">{{ $t("activities.path.start") }}</h4>
        <input
          type="date"
          v-model="shortDate"
          class="mb-2"
          @change="updateDate"
          placeholder="yyyy-mm-dd"
        />

        <b-input type="time" size="sm" :value="time.substring(0, 5)"  @change="updateTime" />
      </div>

      <div class="mt-1">
        <h4 class="subtitle-form mb-2">{{ $t("activities.path.duration") }}</h4>
        <NumericInput
            inputRef="input"
            :value="duration"
            @input="i => {duration = i}"
            @blur="updateDuration"
            :numberOfDigits="2"
            :required="true"
            :max="1440"
            :warningState="true"
          />
      </div>

      <div class="mt-2">
        <b-button @click="$emit('change')" size="sm">{{ $t("activities.path.show") }}</b-button>
      </div>
    </b-form>

    <div class="mt-4 text-left small">
      <b-form-group class="pl-1">
        <b-form-checkbox-group
          :checked="layers"
          @input="updateLayers"
          :options="layersOptions"
          stacked
        ></b-form-checkbox-group>
      </b-form-group>
    </div>

    <div class="mt-1">
      <!-- <h4 class="subtitle-form mb-2">{{ $t("activities.path.speed") }}</h4> -->
      <b-row class="px-1">
        <b-col
          cols="12"
          class="d-flex justify-content-space-between align-items-center small"
          v-for="legend in speedLegend"
          :key="legend.id"
        >
          <div class="legend-color mr-2" :style="{ backgroundColor: legend.color }"></div>
          {{ legend.description }}
        </b-col>
      </b-row>
    </div>

    <div v-if="currentCourse.hasOwnProperty('workingState')">
      <hr>
      <b-row class="small pl-1">
        <b-col cols="12" class="d-flex justify-content-between">
          <div>
            <img :src="levelIcon"/>
            <span class="pl-2 pr-1">{{ $t("activities.path.rate") }} : </span>
          </div>
          {{ currentCourse["workingState"].rate }} ha/h
        </b-col>
      </b-row>
      <b-row class="small pl-1 mt-1">
        <b-col cols="12" class="d-flex justify-content-between">
          <div>
            <img :src="workingTractorIcon"/>
            <span class="pl-2 pr-1">{{ $t("activities.path.surfaceDone") }} : </span>
          </div>
          {{ currentCourse["workingState"].surfaceDone }} ha
        </b-col>
      </b-row>
      <b-row class="small pl-1 mt-1">
        <b-col cols="12" class="d-flex justify-content-between">
        <div>
          <img :src="hourglassIcon"/>
          <span class="pl-2 pr-1">{{ $t("activities.path.leftTime") }} : </span>
        </div>
          {{ secondsToHMS(currentCourse["workingState"].leftTime*3600) }}
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {checkDatePattern, checkTimePattern, secondsToHMS} from "@/utils/commons";
import level from "@/assets/images/level.svg";
import hourglass from "@/assets/images/hourglass.svg";
import workingTractor from "@/assets/images/working_tractor.svg";
import NumericInput from "@/components/commons/NumericInput.vue";

export default {
  data() {
    return {
      shortDate: null,
      levelIcon: level,
      hourglassIcon: hourglass,
      workingTractorIcon: workingTractor,
    };
  },
  created() {
    this.shortDate = this.date.substring(0, 10);
  },
  props: {
    fromMachine: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      default: "",
    },
    time: {
      type: String,
      default: "",
    },
    duration: {
      type: Number,
      default: 60,
    },
    layers: {
      type: Array,
      default: () => [],
    },
    layersOptions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      currentActivity: "activity/currentEntry",
      currentMachine: "machine/currentEntry",
      currentCourse: "machine/course"
    }),

    speedLegend() {
      let scale = [];
      for (let layer in this.currentCourse) {
        if(this.currentCourse[layer].name === "Vitesse" && this.currentCourse[layer].hasOwnProperty("scale")) {
          scale = this.currentCourse[layer].scale;
        }
      }
      return scale;
    }
  },
  methods: {
    secondsToHMS,
    updateLayers(layers) {
      this.$emit("update:layers", layers);
    },
    updateDate() {
      if(checkDatePattern(this.shortDate))
        this.$emit("update:date", this.shortDate);
    },
    updateTime(time) {
      this.$emit("update:time", time);
    },
    updateDuration(duration) {
      // Limite la requête sur le parcours à 24h
      if(duration > 1440) {
        duration = 1440;
      }
      this.$emit("update:duration", parseInt(duration));
    },
  },
  watch: {
    date() {
      this.shortDate = this.date.substring(0, 10);
    }
  },
  components: {
    NumericInput
  }
};
</script>

<style lang="scss" scoped>
h4 {
  font-weight: bold;
  font-size: 1rem;
}

.box {
  width: 200px;
  font-size: 0.95rem;

  background: white;
  border-radius: 10px;
}

.legend-color {
  width: 20px;
  height: 8px;
  border: 1px solid grey;
}

img {
  width: 1.3em;
}
</style>
