import Gateway from "./base";

export default class MiscellaneousApi {
  /**
   * get columnsPreferences
   */
  static get(key) {
    return Gateway.get(`/api/miscellaneous/${key}/`);
  }

  static update(key, data) {
    return Gateway.put(`/api/miscellaneous/${key}/`, data);
  }

  static create(data) {
    return Gateway.post(`/api/miscellaneous/`, data);
  }
}
