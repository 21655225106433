import { DateTime } from "luxon";

import { i18n } from "@/i18n.js";

export const config = {
  title: i18n.t("activities.title"),
  countingElements: i18n.t("activities.list.countingElements"),
  addButton: i18n.t("activities.list.add"),
  loadEntriesOnScroll: true,
  scrollToSelection: true,
  module: "activity",
  checkbox: true,
  // S'il y'en a plusieurs,
  // les champs sur lesquels s'effectue la recherche doivent être séparés d'une virgule et d'un espace (, )
  searchField: "cropField_name, cropField__crop_name",
  searchList: [{
    object: "inputs",
    attribute: "inputsStock_name"
  }],
  //si on doit aller faire la recherche du côté api
  searchApi: true,
  routes: {
    index: {
      name: "activity",
    },
    list: {
      name: "activity.list",
    },
    new: {
      name: "activity.new",
    },
    details: {
      name: "activity.details",
    },
    params: {
      id: "activity_id",
    },
  },
  defaultPivot: 6,
  columns: {
    0: {
      name: "activityType_designation",
      sort: true,
      title: i18n.t("activities.list.activity_type"),
      cellComponent: "ActivityInfos",
      isDate: false,
      width: 15,
      hideOnDetails: false,
      pivot: {
        enable: true,
        groupDisplay: {},
        displayHeader: true,
      },
      show: true,
      priority: 1,
    },
    1: {
      name: "service_name",
      sort: true,
      title: i18n.t("activities.list.service"),
      isDate: false,
      width: 10,
      hideOnDetails: false,
      pivot: {
        enable: true,
        groupDisplay: {},
        displayHeader: true,
      },
      showColumn: function({ $userCan, store }) {
        return $userCan('activities.service')
      },
      show: true,
      priority: 3,
    },
    2: {
      name: "farm_name",
      sort: true,
      title: i18n.t("activities.list.farm"),
      isDate: false,
      width: 10,
      breakpoint: "lg",
      pivot: {
        enable: true,
        sortHeaderField: [(group) => group.name.toLowerCase()],
        sortHeaderOrder: ["asc"],
        sortDataFields: [(activity) => activity.startDate],
        sortDataOrders: ["desc"],
        groupDisplay: {},
        displayHeader: true,
      },
      showColumn: function({ $userCan, store }) {
        return store.getters["farm/farms"].length > 0 && store.getters["farm/currentFarmId"] == null;
      },
      show: true,
      priority: 3,
    },
    3: {
      name: "cropField_name",
      sort: true,
      title: i18n.t("activities.list.cropfield"),
      isDate: false,
      width: 13,
      hideOnDetails: false,
      pivot: {
        enable: true,
        sortHeaderField: [(group) => group.name.toLowerCase()],
        sortHeaderOrder: ["asc"],
        sortDataFields: [(activity) => activity.startDate],
        sortDataOrders: ["desc"],
        groupDisplay: {},
        displayHeader: true,
      },
      show: true,
      priority: 2,
    },
    4: {
      name: "cropField__crop_name",
      sort: true,
      title: i18n.t("commons.list.crop_name"),
      isDate: false,
      width: 10,
      hideOnDetails: true,
      pivot: {
        enable: true,
        sortHeaderField: [(group) => group.name.toLowerCase()],
        sortHeaderOrder: ["asc"],
        sortDataFields: [(activity) => activity.startDate],
        sortDataOrders: ["desc"],
        groupDisplay: {},
        displayHeader: true,
      },
      show: true,
      priority: 4,
    },
    5: {
      name: "surface",
      sort: false,
      title: i18n.t("cropfields.list.surface"),
      isDate: false,
      width: 8,
      hideOnDetails: false,
      pivot: {
        enable: false,
        groupDisplay: {},
        displayHeader: false,
      },
      show: true,
      priority: 3,
    },
    6: {
      name: "startDate",
      sort: true,
      title: i18n.t("commons.list.date"),
      isDate: true,
      cellComponent: "ActivityDate",
      width: 8,
      hideOnDetails: false,
      pivot: {
        enable: true,
        sortHeaderField: [(group) => group.rows.length > 0 && group.rows[0].startDate],
        sortHeaderOrder: ["desc"],
        sortDataFields: [(activity) => activity.startDate],
        sortDataOrders: ["desc"],
        groupDisplay: {},
        displayHeader: true,
      },
      cellDisplay: {
        mode: "function",
        method: (value) => {
          if (!value) return i18n.t("is_empty");
          let dt = DateTime.fromISO(value).setZone("Europe/Paris").setLocale("fr");
          dt = dt.toRelativeCalendar();
          return dt;
        },
      },
      show: true,
      priority: 1,
    },
    7: {
      name: "status",
      sort: false,
      title: i18n.t("commons.list.status"),
      isDate: false,
      width: 8,
      hideOnDetails: true,
      pivot: {
        enable: true,
        groupDisplay: {},
        displayHeader: true,
      },
      show: true,
      priority: 6,
    },
    8: {
      name: "billingDate",
      sort: true,
      title: i18n.t("activities.list.billingDate"),
      isDate: true,
      cellComponent: "Date",
      width: 8,
      hideOnDetails: false,
      pivot: {
        enable: true,
        sortHeaderField: [(group) => group.rows.length > 0 && group.rows[0].billingDate],
        sortHeaderOrder: ["desc"],
        sortDataFields: [(activity) => activity.billingDate],
        sortDataOrders: ["desc"],
        groupDisplay: {},
        displayHeader: true,
      },
      showColumn: function({ $userCan, store }) {
        return $userCan('activities.service')
      },
      cellDisplay: {
        mode: "function",
        method: (value) => {
          if (!value) return i18n.t("is_empty");
          let dt = DateTime.fromISO(value).setZone("Europe/Paris").setLocale("fr");
          dt = dt.toRelativeCalendar();
          return dt;
        },
      },
      show: false,
      priority: 9,
    },
  },
  filters: [
    {
      name: "activityType",
      title: i18n.t("activities.filter.activity_type"),
      show: false,
    },
    {
      name: "cropField__crop",
      title: i18n.t("commons.filter.crop_name"),
      show: false,
    },
    {
      name: "cropField",
      title: i18n.t("activities.filter.cropfield"),
      show: false,
    },
    {
      name: "notes",
      title: i18n.t("commons.filter.notes"),
      show: false,
      values: [
        [true, i18n.t("cropfields.filter.active.values.true")],
      ]
    },
    {
      name: "billed",
      title: i18n.t("activities.filter.billing.billed"),
      show: false,
      values: [
        [true, i18n.t("activities.filter.billing.yes")],
        [false, i18n.t("activities.filter.billing.no")],
      ],
      condition: function({ $userCan, store }) {
        return $userCan('activities.service')
      },
    },
    {
      name: "month",
      title: i18n.t("commons.filter.month"),
      show: false
    },
  ],
  filterConfig: {
    filterOnEntryDetail: true,
    entryIdParam: "activity_id",
    entryLabel: i18n.t("activities.filter.entry_number_label"),
  },
};
