<template>
  <div class="notifMenu">
    <div class="twoblock">
      <div class="blueBlock"></div>
      <div class="blueBlock"></div>
    </div>
    <div class="mainBodyNotif">
      <div id="textNotifMenu" class="textNotifMenu">
        <span @click="toggleNewsSource">{{ $t('home.news.actuality') }}</span> /
        <span @click="toggleNewsSource">{{ $t('home.news.news') }}</span>
      </div>
      <div class="cadreNotif">
        <div class="arrowNotif" :style="{ left: showUpgrade ? '60%' : '30%' }"></div>
        <div class="scroll">
          <div
            id="message"
            v-for="(news, index) in (showUpgrade ? this.upgradeNews : this.allNews)"
            :key="index"
            class="news"
            @mouseenter="readNews(news)"
          >
            <div class="news-header">
              <div class="news-icon" @click="handleNewsClick(news)">
                <img :src="handleIcon(news)" alt="News Icon" style="width: 100%;height: 100%;"/>
              </div>
              <div class="news-title" @click="handleNewsClick(news)">{{ news.title }}</div>
              <div class="news-delete" v-if="!showUpgrade" @click="closeNews(news.id)">
                <img :src="delIcon" alt="Delete Icon" style="width: 75%;height: 75%;"/>
              </div>
            </div>
            <div class="news-description"  @click="handleNewsClick(news)">{{ showUpgrade ? news.summary : news.body }}</div>
            <div class="news-date">{{ formatDate(news.createdAt) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import alertModal from "@/assets/images/alert_modal.svg";
import infoModal from "@/assets/images/warning_modal.svg";
import warningModal from "@/assets/images/warning_icon.svg";
import MajModal from "@/assets/images/document-text.svg";
import delIcon from "@/assets/images/delete.svg";
import { mapGetters, mapActions } from "vuex";
import { EventBus } from '@/eventBus.js';

export default {
  data() {
    return {
      showUpgrade: false,
      alertModal,
      infoModal,
      warningModal,
      MajModal,
      delIcon,
    };
  },
  computed: {
    ...mapGetters({
      allNews: "allNews",
      upgradeNews: "upgradeNews",
    }),
  },
  methods: {
    ...mapActions(["getAllNews", "setClosedNews", "setVueNews"]),

    toggleNewsSource() {
      this.showUpgrade = !this.showUpgrade;
    },

    async closeNews(id) {
      await this.setClosedNews(id);
    },

    async readNews(news) {
      if (news.readed !== true) {
        await this.setVueNews(news.id);
      }
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },

    handleIcon(news) {
      if (news.category === "NEWS") return this.MajModal;
      if (news.criticity === "A") return this.alertModal;
      if (news.criticity === "W") return this.warningModal;
      if (news.criticity === "I") return this.infoModal;
    },

    handleNewsClick(news) {
      this.showUpgrade 
        ? this.openSplashScreen(news) 
        : this.navigateFromNews(news.redirectURl, news.openInNewTab);
    },

    navigateFromNews(redirectURl, openInNewTab) {
      if (openInNewTab) {
        window.open(redirectURl, '_blank');
      } else {
        if (redirectURl.startsWith('/')) {
          this.$router.push({ path: redirectURl });
        } else {
          window.location.href = redirectURl;
        }
      }
    },

    openSplashScreen(news) {
      EventBus.$emit("showSplashScreen", {
        news: [news],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.notifMenu {
  position: absolute;
  z-index: 999;
  right: 0.5%;
  top: 100%;
  height: 90vh;
  width: 40vh;
}

.twoblock {
  justify-content: space-evenly;
  display: flex;
  height: 1%;
}

.blueBlock {
  width: 2vh;
  height: 100%;
  background-color: $primary-color;
}

.mainBodyNotif {
  background-color: $primary-color;
  border-radius: 20px;
  height: 100%;
  width: 100%;
}

.textNotifMenu {
  text-align: center;
  align-content: center;
  height: 8%;
  font-size: 1.2rem;
  color: white;
}

.cadreNotif {
  background-color: $primary-color-light;
  margin-right: 5%;
  margin-left: 5%;
  height: 91%;
  border-radius: 15px;
  overflow-y: hidden;
}

.scroll {
  height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: $primary-color $primary-color-light;
  margin-top: 5px;
}

.arrowNotif {
  height: 2vh;
  width: 2vh;
  top: 7vh;
  background-color: $primary-color-light;
  position: absolute;
  rotate: 45deg;
}

.news {
  padding: 5%;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.news-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.news-icon {
  width: 30px;
  height: 30px;
}

.news-title {
  flex-grow: 1;
  text-align: left;
  font-size: 1rem;
}

.news-delete {
  width: 30px;
  height: 30px;
  align-items: end;
}

.news-description {
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word;
  max-width: 100%;
  overflow-x: hidden;
  font-size: 0.75rem;
}

.news-date {
  font-size: 10px;
  text-align: right;
  font-style: italic;
}
</style>
