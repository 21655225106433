import store from "@/store";
import { isAvailable } from "@/utils/commons";

export default {
  async install(Vue) {
    /**
     * $userCan
     * Check is a user has a given authorization.
     * This is set as a Vue variable so it's easy access it from everywhere.  
     * @param {string} path the authorization path, for exemple "cropfields.create"
     * @returns true if the user has the authorization, false otherwise
     */
    Vue.prototype.$userCan = function(path) {
      // use the isAvailable function from utils
      return isAvailable(store.getters["user/authorizations"], path)
    }
  }
}
