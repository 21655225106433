import Gateway from "./base";

export default class FuelFillApi {
  /**
   * Get All fuelFillS
   *
   */
  static getFuelFillS() {
    return Gateway.get("/api/fuelFill/");
  }

  /**
   * Get one fuelfill by id
   *
   * @param id
   */
  static getFuelFill(id) {
    return Gateway.get(`/api/fuelFill/${id}/`);
  }

  /**
   * Get one fuelfill by machine id
   *
   * @param param
   */
  static getFuelFillsByMachine(param) {
    return Gateway.get(`/api/fuelFill/`, param);
  }

  /**
   * Create fuelfill
   *
   * @param data
   */
  static createFuelFill(data) {
    return Gateway.post(`/api/fuelFill/`, data);
  }

  /**
   * Update fuelfill
   *
   * @param data
   * @param id

   */
  static updateFuelFill(data, id) {
    return Gateway.patch(`/api/fuelFill/${id}/`, data);
  }

  /**
   * delete fuelfill
   *
   * @param id
   */
  static deleteFuelFill(id) {
    return Gateway.delete(`/api/fuelFill/${id}/`);
  }
}
