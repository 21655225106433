import Gateway from "./base";

export default class StockStoresAPI {
  /**
   * Get All Stock Stores
   */
  static getStockStores(params) {
    return Gateway.get("/api/stockStores/", params);
  }

  /**
   * Get one stock Store by id
   */
  static getStockStore(id) {
    return Gateway.get(`/api/stockStores/${id}/`);
  }
}
