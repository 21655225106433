<template>
  <div>
    <!-- density semence form -->
    <div v-if="!this.readOnly">
      <table>
        <tr>
          <td class="shape-first-cell">
            <h4 class="subtitle-form">Densité:</h4>
          </td>

          <td>
            <NumericInput
              inputRef="input"
              :value="densitySelected"
              @input="i => {densitySelected = i}"
              class="width-density-inputs"
              :numberOfDigits="3"
            />
          </td>

          <td>
            <select style="width:77px;" class="width-select-unit-density" v-model="unitSelected">
              <option v-for="unity in inputFormByPtype.seedDensityUnits" v-bind:key="unity.id" :value="unity.id">{{
                unity.unit
              }}</option>
            </select>
          </td>
        </tr>
      </table>

      <br />

      <CropAndDose ref="cropAndDose" />

      <br />

      <table>
        <tr>
          <td class="shape-first-cell">
            <h4 class="subtitle-form">{{ inputFormByPtype.otherFeature_label }}:</h4>
          </td>

          <td>
            <select class="width-select-unit-density" v-model="specifitySelected">
              <option value="">------</option>
              <option v-for="spec in inputFormByPtype.otherFeatures" v-bind:key="spec.id" :value="spec.id">{{
                spec.designation
              }}</option>
            </select>
          </td>
        </tr>
      </table>
    </div>

    <div v-else>
      <DensitySem />
    </div>
  </div>
</template>

<script>
import CropAndDose from "@/components/inputstock/edit/CropAndDose.vue";
import DensitySem from "@/components/inputstock/details/DensitySemence.vue";
import { mapGetters } from "vuex";
import NumericInput from "@/components/commons/NumericInput.vue";

export default {
  name: "semence-form",
  created() {
    // this.specificity = this.inputFormByPtype.otherFeatures;
    // this.globalUnit = this.inputFormByPtype.seedDensityUnits;

    if (this.$route.name === "inputStock.edit") {
      this.densitySelected = this.currentInputStock.density;
      this.specifitySelected = this.currentInputStock.otherFeature;
      this.unitSelected = this.currentInputStock.seedDensityUnit;
    }
    // else {
    // }
  },
  data() {
    return {
      specifitySelected: "",
      densitySelected: "",
      unitSelected: "",
      specificity: [],
      globalUnit: [],
    };
  },
  components: {
    CropAndDose,
    DensitySem,
    NumericInput
  },
  methods: {
    getSemenceInfo() {
      let semenceInfo = !this.readOnly
        ? this.$refs.cropAndDose.getCropAndDose()
        : {
            unit: this.currentInputStock.doseUnit,
            min: this.currentInputStock.minDoseValue,
            max: this.currentInputStock.maxDoseValue,
          };

      semenceInfo["seedDensity"] = this.densitySelected == "" ? 0 : this.densitySelected;
      semenceInfo["seedDensityUnit"] = this.unitSelected;
      semenceInfo["otherFeature"] = this.specifitySelected;
      // plug unit with cropanddose

      return semenceInfo;
    },
  },
  computed: {
    ...mapGetters({
      currentInputStock: "inputStock/currentEntry",
      readOnly: "readOnly",
    }),
    ...mapGetters(["currentProductType", "inputFormByPtype"]),
  },
};
</script>

<style lang="scss" scoped>
/**
 * Width for the density inputs .
 */
.width-density-inputs {
  border-radius: 4px;
  width: 97px;
  padding-left: 3px;
}

.config-density-semence {
  padding-left: 5px;
}

.shape-first-cell {
  width: 100px;
}

.width-select-unit-density {
  border-radius: 4px;
  font-size: 14px;
}
</style>
