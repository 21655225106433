<template>
  <div class="d-flex justify-content-end">
    <img class="pin" v-if="rowData.isTemplate" :src="pinIcon">
  </div>
</template>

<script>
import pinIcon from "@/assets/images/pin.svg";

export default {
  props: ["rowId", "rowData"],
  data() {
    return {
      pinIcon
    }
  }
};
</script>

<style lang="scss" scoped>
.pin {
  width: 100%;
}
</style>
