<template>
  <div>
    <b-modal id="modal-sm" size="sm" :static="true" title="" v-model="modalDelInput" hide-footer hide-header>
      <div class="modal-body">
        <!-- Close button to close the modal -->
        <div class="position-close-button">
          <closeButton :on-click="onClose"></closeButton>
        </div>

        <p class="text-center">
          Êtes-vous sûr de vouloir supprimer cet intrant ?<br />
          Il sera alors <strong>impossible</strong> de revenir en arrière.
        </p>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn button-validate-params" @click="deleteFunction">
          Valider <i class="fa fa-trash"></i>
        </button>
        <button type="button" class="btn btn-danger" @click="onClose">Annuler</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import closeButton from "@/components/commons/CloseButton.vue";

export default {
  name: "delete-input",
  props: {
    deleteFunction: {
      type: Function,
      require: true,
    },
  },
  data() {
    return {
      modalDelInput: false,
    };
  },
  methods: {
    /**
     * Description: Affiche le modal.
     */
    modalDeleteInput: async function() {
      this.modalDelInput = !this.modalDelInput;
    },

    onClose() {
      this.modalDelInput = !this.modalDelInput;
    },
  },
  components: {
    closeButton,
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
/**
 * Close Button align right.
 */
.position-close-button {
  text-align: right;
}

/**
 * Params of modal contents.
 */
.modal-content-position {
  text-align: center;
  justify-content: center;
}

/**
 * Width input 100%.
 */
.width-input {
  width: 100%;
}

/**
 * Margin subtitle.
 */
.margin-subtitle {
  margin: 30px 0px 1px 0px;
}

/**
 * Custom margin subtitle.
 */
.custom-margin-subtitle {
  margin: 30px 0px 20px 0px;
}

img {
  width: 5em;
}

/**
 * Params validate button.
 */
.button-validate-params {
  background-color: $background-text-color;
  color: $color-light;
}

.button-validate-params:hover {
  background: $secondary-light-color;
}
</style>
