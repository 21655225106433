import { render, staticRenderFns } from "./EditNonTreatedZone.vue?vue&type=template&id=d1e60190&scoped=true"
import script from "./EditNonTreatedZone.vue?vue&type=script&lang=js"
export * from "./EditNonTreatedZone.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1e60190",
  null
  
)

export default component.exports