<template>
  <div class="filter-chips d-flex flex-wrap" v-if="filterSelectionLabels">
    <Chips
      v-for="selection in filterSelectionLabels"
      :label="selection.label"
      :key="selection.filterName"
      @close="removeFilter(selection)"
      class="mt-2"
    />
  </div>
</template>

<script>
/**
 * This component is shown on list pages and above cropfields map
 * It is used to display the filter pills
 */

import { EventBus } from "@/eventBus.js";

import Chips from "@/components/commons/FilterChips.vue";

export default {
  components: {
    Chips,
  },
  methods: {
    loading(value) {
      return this.$store.commit(this.module + "/setEntryLoaded", value);
    },
    fetch() {
      return this.$store.dispatch(this.module + "/fetchEntries", {withGeometries: true});
    },
    updateFilterSelection(selection) {
      return this.$store.dispatch(this.module + "/updateFilterSelection", selection);
    },
    async removeFilter(selection) {
      this.loading(true);

      if (selection.filterName == "entryId") {
        EventBus.$emit("clearSelection", { goToList: true })
      }

      await this.updateFilterSelection(selection);

      await this.fetch();

      // plot the fields again if we have a map
      EventBus.$emit("plotFields");
      EventBus.$emit("plotLocationFields");

      this.loading(false);
    },
  },
  computed: {
    filterSelectionLabels() {
      return this.$store.getters[this.module + "/filterSelectionLabels"];
    },
  },
  props: {
    module: {
      type: String,
      default: () => {},
    },
  }
}
</script>