import * as turf from "@turf/turf";

import { project3857, project4326, computeSurface, writeGeometriesToLayer } from "@/utils/geomatics";
import { mapStyles } from "@/views/cropfields/map.config";
import _ from "lodash";

import { EventBus } from "@/eventBus.js";

import Map from "./Map";

import { i18n } from "@/i18n.js";

export default {
  data() {
    return {
      mapInitialized: false,

      // ui
      showActivityPathFilter: false,

      // map and layers
      map: null,
      activeFeature: null,
      activeCropfieldLayer: null,
      islandsLayer: null,
      varietiesLayer: null,
      activeVarietyLayer: null,
      activeVariety: null,
      notesLayer: null,
      secondCropsLayer: null,
      machinesLayer: null,
      waterCourseLayer: null,
      cutLineLayer: null,
      helpersLayer: null,
      parcelsLayer: null,
      cutSource: null,
      layerSource: null,
      newPolyLayer: [],
      lengthOverlay: null,
      activeParcelsLayer: null,
      firstCropsLayer: null,

      // controls
      fullScreenControl: null,

      // used for debug
      newLines: [],
      transLines: [],
      polygonInter: [],
      lineSliced: [],

      // used for layer filter button
      mapLayers: [],
      layerFilters: [
        { name: this.$t("cropfields.map.layers.satellite"), value: true },
        // { name: this.$t("cropfields.map.layers.cropfields"), value: false },
        { name: this.$t("cropfields.map.layers.islands"), value: false },
        { name: this.$t("cropfields.map.layers.cadastre"), value: true },
        { name: this.$t("cropfields.map.layers.osm"), value: false },
        { name: this.$t("cropfields.map.layers.varieties"), value: false },
        { name: this.$t("cropfields.map.layers.notes"), value: false },
        { name: this.$t("cropfields.map.layers.second_crop"), value: true },
        { name: this.$t("cropfields.map.layers.machines"), value: false },
        { name: this.$t("cropfields.map.layers.water_course"), value: false}
      ],
      selectionMultiple: false,

      // modes
      drawMode: false,
      holeMode: false,
      fullScreenMode: false,
      measuringMode: false,
      minDistanceMode: false,

      // data
      cropfields: [],
      displayActiveCropfield: true,
      displayIslandCropfields: false,
      debounceTimer: null,
      ctrlKey: false,

      // second Crop
      isOnSecondCrop: null,
    };
  },
  mounted() {
    EventBus.$on("zoomOnCropfield", this.zoomOnCropfield);

    this.selectionMultiple = this.selectionMultipleByDefault

    if (this.minimal) return;

    if (this.disableInteraction) return;

    EventBus.$on("plotFields", this.plotFields);

    EventBus.$on("selectOnMap", this.onSelect);

    EventBus.$on("resetMapCut", this.resetMapCut);

    EventBus.$on("checkOnMap", this.onCheck);

    EventBus.$on("refreshMap", this.refreshMap);

    EventBus.$on("disableOutlineMode", this.disableOutlineMode);

    EventBus.$on("fetchAndInit", this.fetchAndInit);

    EventBus.$on("activeALayer", this.activeALayer);
    EventBus.$on("hideActiveVariety", this.hideActiveVariety);
    EventBus.$on("reinitializeVarietyLayer", this.reinitializeVarietyLayer);
    EventBus.$on("reinitializeParcelsLayer", this.reinitializeParcelsLayer);


    let t = this;
    addEventListener("keydown", function (e) {
      if (e.keyCode == 17 || e.keyCode == 91) t.ctrlKey = true;
      // Activation du dessin à main levée lorsqu'on appuie sur la touche shift
      if (e.keyCode == 16 && t.enableFreeHand) t.addFreehandInteraction();
    });
    addEventListener("keyup", function (e) {
      if (e.keyCode == 17 || e.keyCode == 91) t.ctrlKey = false;
      // Désactivation du dessin à main levée lorsqu'on relache la touche shift
      if (e.keyCode == 16 && t.enableFreeHand) setTimeout(t.removeFreehandInteraction, 200);
    });

    if(this.activeVarietiesLayer){
      let varietyLayerIndex = 4;
      this.activeALayer(varietyLayerIndex);
    }
  },
  beforeDestroy(){
    EventBus.$off("zoomOnCropfield", this.zoomOnCropfield);
    EventBus.$off("plotFields", this.plotFields);
    EventBus.$off("selectOnMap", this.onSelect);
    EventBus.$off("resetMapCut", this.resetMapCut);
    EventBus.$off("checkOnMap", this.onCheck);
    EventBus.$off("refreshMap", this.refreshMap);
    EventBus.$off("fetchAndInit", this.fetchAndInit);
    EventBus.$off("activeALayer", this.activeALayer);
    EventBus.$off("hideActiveVariety", this.hideActiveVariety);
    EventBus.$off("reinitializeVarietyLayer", this.reinitializeVarietyLayer);
    EventBus.$off("reinitializeParcelsLayer", this.reinitializeParcelsLayer);
    this.$store.dispatch("cropfield/setActiveVarietiesLayer", false);
  },
  methods: {
    async fetchAndInit(fetchCropFields=true) {
      if (fetchCropFields) {
        // On affiche toutes les parcelles, y compris celles non confirmées, si on est en mode confirmation de contour
        let all = this.outlineMode;
        await this.fetchEntries({ page: 0, withGeometries: true, all: all });
      }

      if(this.clearSelectionOnLoad) {
        this.rowsChecked = []
      }

      this.initMap();
    },
    async fetchAndResetMap() {
      if (this.$route.params.cropfield_id) this.onSelect(this.$route.params.cropfield_id);
      else this.rowsChecked = [];

      console.log("reset");
      // On affiche toutes les parcelles, y compris celles non confirmées, si on est en mode confirmation de contour
      let all = this.outlineMode;
      await this.fetchEntries({ page: 0, withGeometries: true, all: all });
      this.plotFields();

      if (!this.currentEntry) this.resetSelection();

      this.zoomOnData();
      this.refreshLayers();
    },
    resetSelection() {
      this.resetCurrentId();

      if (this.$route.name != "cropfield.map") this.$router.push({ name: "cropfield.map" });
    },
    zoomOnData() {
      if (this.pathOptions && Object.keys(this.pathOptions).length > 0) {
        // Si on affiche parcours, on laisse la méthode qui s'en ocupe effectuer le zoom
        return
      }
      if (this.entriesChecked.length > 0 && this.checkedGeometries3857.length > 0) {
        this.zoomOnCollection(this.checkedGeometries3857);
      } else if (this.currentCropfield && this.currentCropfield.id && this.currentGeometry3857) {
        this.zoomOnCollection([this.currentGeometry3857]);
      } else if(this.geometries3857.length > 0) {
        this.zoomOnCollection(Object.values(this.geometries3857));
      } else if(this.currentFarmFullAddress) {
        // no entry on map but we have a farm address ? Zoom on it
        this.zoomOnFarm()
      }
    },
    zoomOnCropfield(id, duration = 500, maxZoom = 15) {
      const geometry = this.geometries3857.find(g => g.properties.id == id)

      if(!geometry) return

      this.zoomOnCollection([geometry], duration, maxZoom);
    },
    // initialize the map
    initMap() {
      if (this.mapInitialized) return;

      this.mapInitialized = true;

      let t = this;
      Gp.Services.getConfig({
        apiKey: "essentiels,parcellaire",
        // deprecated --> replaced by api-key
        // serverUrl: `${this.publicPath}autoconf.js`,
        // callbackSuffix: "",
        onSuccess: async function () {
          await t.initLayers();

          t.zoomOnData();

          if (t.pathOptions && Object.keys(t.pathOptions).length > 0) {
            // Sur le parcours d'une activité, on affiche la couche des notes par défaut
            if (!t.pathOptions.fromMachine) {
              t.layerFilters[6].value = true
            }
            t.initPath();
          }

          if(!t.disableInteraction) {
            t.map.on("click", t.onClick);

            t.map.on("pointermove", t.onMove);
            // Création de l'interaction pour le dessin à main levée sur la carte
            // Utile pour la sélection groupée des parcelles pour une tournée
            if(t.enableFreeHand) t.createFreehandInteraction();
          }

          t.$emit("mapready");
        },
      });
    },
    async initLayers() {
      // prepare the islands layer
      this.islandsLayer = new ol.layer.Vector({ source: new ol.source.Vector(), style: this.getIslandStyle });
      this.islandsLayer.setZIndex(0);

      // prepare the varieties layer
      this.varietiesLayer = new ol.layer.Vector({source: new ol.source.Vector(), style: this.getVarietyStyle});
      this.varietiesLayer.setZIndex(6);

      // prepare the notes layer
      this.notesLayer = new ol.layer.Vector({source: new ol.source.Vector(), style: this.getNoteStyle});
      this.notesLayer.setZIndex(6);

      // prepare secondCrop layer
      this.secondCropsLayer = new ol.layer.Vector({source: new ol.source.Vector(), style: this.getCropfieldStyle});
      this.secondCropsLayer.setZIndex(5);

      // prepare the machines layer
      this.machinesLayer = new ol.layer.Vector({source: new ol.source.Vector(), style: this.getMachineStyle});
      this.machinesLayer.setZIndex(6);

      // prepare the water course layer
      this.waterCourseLayer = new ol.layer.Vector({source: new ol.source.Vector(), style: this.getWaterCourseStyle});
      this.waterCourseLayer.setZIndex(6);

      this.mapLayers = []
      if(this.layers.includes('satellite')) {
        this.mapLayers.push(new ol.layer.GeoportalWMTS({
          layer: "ORTHOIMAGERY.ORTHOPHOTOS", // Vue satellite
          olParams: {
            sourceParams: {
              crossOrigin: "Anonymous"
            }
          }
        }))
      }
      // if(this.layers.includes('agriculture')) {
      //   this.mapLayers.push(new ol.layer.GeoportalWMS({
      //     layer: "LANDUSE.AGRICULTURE2020", // Registre parcellaire 2020 (couleurs)
      //     olParams: {
      //       opacity: 0.2,
      //       sourceParams: {
      //         crossOrigin: "Anonymous"
      //       }
      //     },
      //   }))
      // }
      if(this.layers.includes('islands')) {
        this.mapLayers.push(this.islandsLayer)
      }
      if(this.layers.includes('parcels')) {
        this.mapLayers.push(new ol.layer.GeoportalWMTS({
          layer: "CADASTRALPARCELS.PARCELS", // Parcelles cadastrales
          olParams: {
            sourceParams: {
              crossOrigin: "Anonymous"
            }
          }
        }))
      }
      if(this.layers.includes('osm')) {
        this.mapLayers.push(new ol.layer.Tile({
          source: new ol.source.OSM(),
        }))
      }
      if(this.layers.includes('varieties')) {
        this.mapLayers.push(this.varietiesLayer)
      }
      if(this.layers.includes('notes')) {
        this.mapLayers.push(this.notesLayer)
      }
      if(this.layers.includes('secondCrop')) {
        this.mapLayers.push(this.secondCropsLayer)
      }
      if(this.layers.includes('machines')) {
        this.mapLayers.push(this.machinesLayer)
      }
      if(this.layers.includes('waterCourse')) {
        this.mapLayers.push(this.waterCourseLayer)
      }

      this.map = new ol.Map({
        target: "map",
        controls: ol.control.defaults({ attribution: false }),
        layers: this.mapLayers,
        view: new ol.View({
          zoom: 8,
          maxZoom: 19,
          // center by default on Paris
          center: ol.proj.fromLonLat([2.294848, 48.863576])
        }),
      });

      Map.setInstance(this.map);

      this.lengthOverlay = new ol.Overlay({
        element: this.$refs.lengthElement,
        offset: [0, -15],
        positioning: "bottom-center",
      });
      this.map.addOverlay(this.lengthOverlay);

      this.layerSource = new ol.source.Vector();
      let activeSource = new ol.source.Vector();
      let activeVarietySource = new ol.source.Vector();
      this.cutSource = new ol.source.Vector();

      /* Ajout d'une barre de zoom */
      var zoomControl = new ol.control.ZoomSlider();
      this.map.addControl(zoomControl);

      // Ajout du bouton "plein écran" et des options d'export en PDF
      if(this.fullScreenButton) {
        let exportOptions = new ol.control.Control({
          element: this.$refs.exportOptions
        });
        this.map.addControl(exportOptions);
        this.fullScreenControl = new ol.control.FullScreen({
          tipLabel: i18n.t('cropfields.map.full_screen_help_button'),
        });
        this.map.addControl(this.fullScreenControl);

        let t = this;
        this.fullScreenControl.on('enterfullscreen', function(evt){
          t.showExportOptions = true;
          t.fullScreenMode = true;
        });

        this.fullScreenControl.on('leavefullscreen', function(evt){
          t.showExportOptions = false;
          t.fullScreenMode = false;
        });
      }

      /* Ajout d'une échelle */
      var scalelineControl = new ol.control.ScaleLine({ minWidth: 150 });
      this.map.addControl(scalelineControl);

      /* Permet l'affichage des coordonnées de la position de la souris sur la carte */
      if (!this.minimal) {
        let mousePositionControl = new ol.control.MousePosition({
          coordinateFormat: ol.coordinate.createStringXY(4),
          projection: "EPSG:4326",
          className: "custom-mouse-position"
        });
        this.map.addControl(mousePositionControl);
      }

      /* Ajout de l'overlay pour afficher les parcelles */
      this.parcelsLayer = new ol.layer.Vector({ source: this.layerSource, style: this.getCropfieldStyle });
      this.parcelsLayer.setZIndex(5);
      this.map.addLayer(this.parcelsLayer);

      if(this.menuBar) {
        /* Ajout d'une barre de recherche d'adresse */
        var searchControl = new ol.control.SearchEngine({ collapsed: false });
        this.map.addControl(searchControl);

        /* Ajout de la boite à outil */
        let toolBoxControl = new ol.control.Control({
          element: this.$refs.toolBox
        });
        this.map.addControl(toolBoxControl);
        /* Ajout de la boite des couches cartographiques */
        let layerBoxControl = new ol.control.Control({
          element: this.$refs.layerBox
        });
        this.map.addControl(layerBoxControl);
      }

      this.plotFields(false);
      this.activeCropfieldLayer = new ol.layer.Vector({ source: activeSource, style: this.getActiveCropfieldStyle });
      this.activeCropfieldLayer.setZIndex(5);
      this.map.addLayer(this.activeCropfieldLayer);

      // Ajout de la couche qui va afficher la variété active
      this.activeVarietyLayer = new ol.layer.Vector({ source: activeVarietySource, style: this.getActiveVarietyStyle });
      this.activeVarietyLayer.setZIndex(4);
      this.map.addLayer(this.activeVarietyLayer);

      this.cutLineLayer = new ol.layer.Vector({ source: new ol.source.Vector(), style: this.cutLineStyle });
      this.cutLineLayer.setZIndex(10);
      this.map.addLayer(this.cutLineLayer);

      this.helpersLayer = new ol.layer.Vector({ source: new ol.source.Vector(), style: this.helpersStyle });
      this.helpersLayer.setZIndex(100);
      this.map.addLayer(this.helpersLayer);

      this.newPolyLayer = new ol.layer.Vector({ source: this.cutSource, style: this.cutCropfields });
      this.newPolyLayer.setZIndex(5);
      this.map.addLayer(this.newPolyLayer);

      this.holeLayer = new ol.layer.Vector({ source: new ol.source.Vector(), style: this.holeStyle });
      this.holeLayer.setZIndex(7);
      this.map.addLayer(this.holeLayer);

      this.measureLayer = new ol.layer.Vector({ source: new ol.source.Vector(), style: this.getMeasuredLineStyle });
      this.measureLayer.setZIndex(10);
      this.map.addLayer(this.measureLayer);

      this.freehandLayer = new ol.layer.Vector({ source: new ol.source.Vector({wrapX: false})});
      this.freehandLayer.setZIndex(10);
      this.map.addLayer(this.freehandLayer);

      this.activeCropfieldHolesLayer = new ol.layer.Vector({
        source: new ol.source.Vector(),
        style: this.activeCropfieldHolesLayerStyle,
      });
      this.activeCropfieldHolesLayer.setZIndex(8);
      this.map.addLayer(this.activeCropfieldHolesLayer);

      // Couche de la / des parcelle(s) sélectionnée(s)
      this.activeParcelsLayer = new ol.layer.Vector({ source: new ol.source.Vector(), style: this.getCropfieldStyle });
      this.activeParcelsLayer.setZIndex(6);
      this.map.addLayer(this.activeParcelsLayer);

      // Couche affichant les parcelles de 1ere culture sur lesquelles il y'a une double culture
      this.firstCropsLayer = new ol.layer.Vector({ source: new ol.source.Vector(), style: this.getHideCropfielStyle });
      this.firstCropsLayer.setZIndex(4);
      this.map.addLayer(this.firstCropsLayer);

      if (this.$route.params.mode == "editGeometry" && this.currentEntry.id) this.plotFeatureHoles();

      await this.refreshLayers();

      /* On centre sur l'ensemble des parcelles */
      if (!ol.extent.isEmpty(this.layerSource.getExtent())) {
        this.map.getView().fit(this.layerSource.getExtent());
      }
    },
    async refreshLayers() {
      if (this.isOnTour)
        return
      for (let i = 0; i < this.layerFilters.length; i++) {
        if (i == 6) {
          // On filtre les parcelles sur les 1ère ou 2ème cultures selon l'option qui est cochée
          if(this.layerFilters[i].value && !this.isOnSecondCrop){
            this.isOnSecondCrop = true;
            // A l'initialisation de la carte (lorsque les géométries sont récupérées pour la 1ère fois), effectuer un zoom
            let zoom = this.geometries3857.length == 0;
            this.plotFields(zoom);
            // Lors de l'affichage des 2nde culture, les 1ère cultures sont affichées, mais dans un style qui permet de les camoufler
            // Cela permettra de proposer leur sélection
            this.firstCropsLayer.setStyle(this.getHideCropfielStyle);
          }
          if (!this.layerFilters[i].value && this.isOnSecondCrop){
            this.isOnSecondCrop = false;
            this.plotFields(false);
            // En affichage des 1ères cultures, la couche des 2nde cultures est rendue inaccessible
            this.firstCropsLayer.setStyle(this.getCropfieldStyle);
          }
        }
        if (i == 8 && this.layerFilters[i].value) {
          // constraint : if the zoom is too low, return & show alert
          if (Map.getZoom() <= mapStyles.normal.courseWaterMinimumZoomLevel) {
            this.showAlert();
            continue;
          }

          // get the bouding box
          const bbox = Map.getExtent2154();
          // Récupère les geométries des cours d'eau
          let waterCourseGeometries = await this.fetchWaterCourseGeometries({bbox: bbox});
          // Recharge les geométries des cours d'eau
          if (waterCourseGeometries && this.waterCourseLayer)
            writeGeometriesToLayer(waterCourseGeometries, this.waterCourseLayer, true);
        }
        if (i == 7 && this.layerFilters[i].value) {
          // Récupérer les géométries des machines
          await this.fetchMachineGeometries();
          // Recharge les géométries des machines
          if (this.machineGeometries3857 && this.machinesLayer)
            writeGeometriesToLayer(this.machineGeometries3857, this.machinesLayer, true);
        }
        if (i == 5 && this.layerFilters[i].value) {
          // Récupère les géométries des notes
          // Sur la consultation du parcours d'une activité, on affiche uniquement les notes de l'activité en question
          if (this.pathOptions && Object.keys(this.pathOptions).length > 0 && this.pathOptions.activity) {
            await this.fetchNoteGeometries({activityId: this.pathOptions.activity});
          } else {
            await this.fetchNoteGeometries();
          }
          // Recharge les géométries des notes
          if(this.noteGeometries3857 && this.notesLayer)
            writeGeometriesToLayer(this.noteGeometries3857, this.notesLayer, true)
        }
        if (i == 4 && this.layerFilters[i].value) {
          // Récupère les géométries des variétés
          await this.fetchVarietyGeometries();
          // Recharge les géométries des variétés
          if (this.varietyGeometries3857 && this.varietiesLayer)
            writeGeometriesToLayer(this.varietyGeometries3857, this.varietiesLayer, true);
        }
        if (i === 1 && this.layerFilters[i].value) {
          let refresh = true;
          if (this.$route.params.mode == "editGeometry") {
            // En modification de contour, on récupère uniquement la geométrie de l'ilot auquel est associée la parcelle
            await this.$store.dispatch("getOneIsland", {id: this.currentCropfield.island});
          } else {
            // Sinon, on récupère la géométrie de tous les ilôts
            refresh = await this.fetchIslandGeometries();
          }
          // Recharge la couche des ilots
          if (refresh && this.islandGeometries3857 && this.islandsLayer)
            writeGeometriesToLayer(this.islandGeometries3857, this.islandsLayer, true);
        }
        if (!this.mapLayers[i]) return;
        this.mapLayers[i].setVisible(this.layerFilters[i].value);

        // if (i == 1 && this.layerFilters[0].value) {
        //   this.mapLayers[i].setOpacity(0.6);
        // } else {
        this.mapLayers[i].setOpacity(1);
        // }
      }
      this.refreshMap()
    },
    refreshMap() {
      this.activeCropfieldLayer.getSource().dispatchEvent("change");
      this.activeParcelsLayer.getSource().dispatchEvent("change");
      this.layerSource.dispatchEvent("change");
      this.firstCropsLayer.getSource().dispatchEvent("change");
      this.secondCropsLayer.getSource().dispatchEvent("change");
    },
    zoomOnCollection(collection, duration = 200, maxZoom) {
      if (!this.map) return;
      let checked = turf.featureCollection(collection);
      this.map.getView().fit(turf.bbox(checked), { duration, padding: [50, 50, 50, 50], maxZoom });
    },
    /**
     * Zoom on current farm using its address string
     * Uses the IGN geocoding service
     * @param {int} duration of zoom animation
     * @param {int} maxZoom of map
     */
    zoomOnFarm(duration = 200, maxZoom = 14) {
      const t = this;
      Gp.Services.geocode({
        apiKey: "calcul", // public IGN api key
        ssl : true,
        // address using farm/currentFullAddress getter
        location : this.currentFarmFullAddress,
        onSuccess : function (result) {
          if(result.locations.length == 0) return

          const coords =  result.locations[0].position

          // we have only one point but we still need to use bbox method to fit the map
          const point = project3857(turf.feature(turf.point([coords.y, coords.x])));
          const collection = turf.featureCollection([point])

          t.map.getView().fit(turf.bbox(collection), { duration, padding: [50, 50, 50, 50], maxZoom })
        }
      })
      //;
    },
    // plot all the cropfields
    // useful when we want to refresh the map
    // Passer zoomOnData à false si on ne veut pas rafraichir le zoom sur la map
    async plotFields(zoomOnData=true) {
      if (!this.layerSource) return;

      let geojson = {
        type: "FeatureCollection",
        features: [],
      };

      let geoms = Object.values(this.uniqueCropGeometries3857);
      geojson.features = geoms;

      this.layerSource.clear();

      await this.layerSource.addFeatures(new ol.format.GeoJSON().readFeatures(geojson));
      writeGeometriesToLayer(this.firstCropGeometries3857, this.firstCropsLayer, true);
      writeGeometriesToLayer(this.secondCropGeometries3857, this.secondCropsLayer, true);

      this.layerSource.dispatchEvent("change");
      this.firstCropsLayer.getSource().dispatchEvent("change");
      this.secondCropsLayer.getSource().dispatchEvent("change");


      if (this.activeParcelsLayer) {
        this.activeParcelsLayer.getSource().clear();
        this.activeParcelsLayer.getSource().dispatchEvent("change");
      }

      if (this.activeCropfieldLayer) {
        this.activeCropfieldLayer.getSource().clear();
        this.activeCropfieldLayer.getSource().dispatchEvent("change");
      }
      this.hideActiveVariety();
      if (zoomOnData){
        this.$nextTick(this.zoomOnData)
      }
    },
    plotPolygons(polygons) {
      polygons = polygons.map((p) => project3857(p));

      let polyGeojson = turf.featureCollection(polygons);
      polyGeojson = turf.clone(polyGeojson);
      let t = this;
      let deb = this.debounce(function () {
        t.setMapCutGeometries(polyGeojson);
      }, 400);
      deb();
      this.newPolyLayer.getSource().clear();
      this.newPolyLayer.getSource().addFeatures(new ol.format.GeoJSON().readFeatures(polyGeojson));
    },
    // reset the map after a cut
    resetMapCut() {
      this.setMapCutMode(0);
      this.selectedPoints = [];
      this.newLines = [];
      this.possiblePaths = [];
      this.choosenPath = null;
      this.hasCut = false;
      if (this.cutLineLayer) {
        this.cutLineLayer.getSource().clear();
        this.cutLineLayer.dispatchEvent("change");
      }
      if (this.newPolyLayer) {
        this.newPolyLayer.getSource().clear();
        this.displayActiveCropfield = true;
        this.layerSource.dispatchEvent("change");
        this.activeParcelsLayer.getSource().dispatchEvent("change");
        this.firstCropsLayer.getSource().dispatchEvent("change");
        this.secondCropsLayer.getSource().dispatchEvent("change");
      }
      if (this.activeCropfieldHolesLayer) {
        this.resetFeatureHoles();
      }

      // reset line cut & multiline interaction
      this.cutLineRemovePointerInteraction();
      this.removeEditMultilineInteraction();
      this.removeMultilineDistanceLine();
      this.multilineMode = false;
      this.selectPointInteraction = null;
      this.snapActiveCropfield = null;
      this.pointerInteraction = null;
      this.snapSelectedPoints = null;
      this.snapMultiline = null;
    },
    //------------------------------------------------------------------
    //  Interactions
    //------------------------------------------------------------------
    showDetails(id) {
      let params = {};
      params[this.routes.params.id] = id;

      if (this.$route.name != "cropfield.map.details" || this.$route.params[this.routes.params.id] != id)
        this.$router.push({ name: "cropfield.map.details", params: params });
    },
    onCheck(rowId) {
      rowId = parseInt(rowId);

      if (this.rowsChecked.includes(rowId)) {
        this.rowsChecked = [...this.rowsChecked.filter((e) => e != rowId)];
        if (this.activeCropfieldLayer) this.activeCropfieldLayer.getSource().clear();
        EventBus.$emit("onMapUncheck", rowId)
      } else {
        this.rowsChecked = [...this.rowsChecked, rowId];
        EventBus.$emit("onMapCheck", rowId)
      }

      if (this.layerSource) this.layerSource.dispatchEvent("change");
      if (this.firstCropsLayer) this.firstCropsLayer.getSource().dispatchEvent("change");
      if (this.secondCropsLayer) this.secondCropsLayer.getSource().dispatchEvent("change");

      if (this.routing && this.$route.params[this.routes.params.id] !== rowId) {
        this.showDetails(rowId);
      }
    },
    async onSelect(rowId) {
      rowId = parseInt(rowId);

      this.rowsChecked = [rowId];

      if (this.layerSource) this.layerSource.dispatchEvent("change");
      if (this.activeParcelsLayer) this.activeParcelsLayer.getSource().dispatchEvent("change")
      if (this.firstCropsLayer) this.firstCropsLayer.getSource().dispatchEvent("change");
      if (this.secondCropsLayer) this.secondCropsLayer.getSource().dispatchEvent("change");

      if (this.minimal || !this.routing) return;

      if (this.$route.params[this.routes.params.id] !== rowId) {
        this.showDetails(rowId);
      }
    },
    onMove(evt) {
      let pixel = this.map.getEventPixel(evt.originalEvent);
      let hitFeatures = this.map.getFeaturesAtPixel(pixel);
      this.map.getViewport().style.cursor = hitFeatures.length > 0 ? "pointer" : "";
      /* Affichage du texte des notes survolées */
      this.showNoteTextHover(hitFeatures, evt);

    },
    /**
     * Permet l'affichage du texte des notes lors du survol de la souris
     * @param {*} hitFeatures
     * @returns
     */
    showNoteTextHover(hitFeatures, evt){
      let t = this;
      if (t.minimal)
        return
      if (hitFeatures.length > 0) {
        for (var i = 0; i < hitFeatures.length; i++) {
          if (
            hitFeatures[i].getGeometry().getType() == "Point" &&
            hitFeatures[i].getProperties().text != null
          ) {
            t.pathTooltip.content = hitFeatures[i].getProperties().text;
            t.pathTooltip.imageFile = hitFeatures[i].getProperties().imageFile;

            t.pathTooltip.x = evt.originalEvent.pageX;
            t.pathTooltip.y = evt.originalEvent.pageY;

            return;
          }else{
            t.pathTooltip.content = null;
          }
        }
      } else {
        t.pathTooltip.content = null;
      }
    },
    onClick(evt) {
      // the click behavior and logic
      if (this.holeMode) return;

      if (this.createFromRpgMode) return;
      if (this.fullScreenMode) return;

      let t = this;
      if (this.drawMode) return; // if we draw, we don't want to interact with the map (except to draw), so return

      if (this.measuringMode) return;
      if (this.minDistanceMode) return;

      // if we have already selected two points
      if (this.selectedPoints.length == 2) {
        // line cut mode
        if (this.mapCutMode == 1) {
          if (this.hasCut && !this.multilineMode) {
            this.cutLineRemovePointerInteraction();
            this.showDistanceTooltip = false;
            this.cutLineShowMultiline();
            return;
          }
        }
        // border cut mode, if we haven't already chosen a path
        if (this.mapCutMode == 2 && !this.choosenPath) {
          this.borderCutChoosePathAndGo(evt);
        }
        return;
      }

      // otherwise, we check on what we have clicked
      let selectedFeature, selectedLayer, selectedVariety;
      this.selectedCropfields = [];
      this.selectedFeatures = [];
      this.map.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
        if (layer == t.islandsLayer) return;
        if(t.outlineMode && (layer == t.parcelsLayer || layer == t.firstCropsLayer || layer == t.secondCropsLayer)) return;
        if (layer == t.waterCourseLayer) return;

        if (layer == t.notesLayer) {
          t.viewNoteImage(feature);
        }

        selectedFeature = feature;
        selectedLayer = layer;
        if (layer == t.parcelsLayer || layer == t.firstCropsLayer || layer == t.secondCropsLayer) {
          t.selectedFeatures.push(feature);
          t.selectedCropfields.push(t._entries[feature.getProperties().id]);
        }

        if (layer == t.activeCropfieldLayer && t.displayActiveCropfield) {
          t.activeFeature = feature;
        }

        if(selectedFeature && selectedFeature.getProperties().type && selectedFeature.getProperties().type == "variety") {
          // Clic sur une variété
          selectedVariety = selectedFeature;
        }
      });
      if (this.varietyGeometries3857 && this.varietiesLayer && this.activeVarietyLayer && this.activeVariety && this.activeVarietyLayer.getSource().hasFeature(this.activeVariety)){
        // S'il y'avait une variété active, elle avait été supprimée de la couche des variétés non actives. On la rajoute
        this.varietiesLayer.getSource().addFeature(this.activeVariety);
      }
      // Si on a cliqué sur une variété, on l'ajoute à la couche des variétés actives, sinon on la retire
      if(selectedVariety) {
        t.showActiveVariety(selectedVariety);
      }
      else {
        this.hideActiveVariety();
      }

      if (selectedFeature && selectedLayer == this.activeCropfieldHolesLayer) {
        this.selectHole({ hole: selectedFeature.getProperties().index });
        this.activeCropfieldHolesLayer.getSource().dispatchEvent("change");
        return;
      }

      this.resetFeatureHoles();

      if (!selectedFeature || !selectedFeature.getProperties()) {
        return;
      }

      // S'il y a plusieurs parcelles à cet emplacement, on demande à l'utilisateur laquelle il veut sélectionner
      if (this.selectedCropfields.length > 1 && this.mapCutMode == 0) {
        this.$refs.cropfieldChoice.showModal(this.ctrlKey, evt);
        return;
      }

      this.clickConfirmed({id: selectedFeature.getProperties().id, check: this.ctrlKey}, evt);
    },
    clickConfirmed({id, check}, evt) {
      let selectedFeature = this.selectedFeatures.find(f => f.getProperties().id == id);

      if (!selectedFeature || !selectedFeature.getProperties()) {
        return;
      }

      let ppties = selectedFeature.getProperties();

      // if no in cut mode, we just select/check it
      if (this.mapCutMode == 0) {
        if (this.selectionMultiple || check) {
          // Move the selected feature to another layer
          if (this.activeParcelsLayer.getSource().hasFeature(selectedFeature)) {
            this.activeParcelsLayer.getSource().removeFeature(selectedFeature);
          } else {
            this.activeParcelsLayer.getSource().addFeature(selectedFeature);
          }
          this.onCheck(ppties.id);
          this.resetFeatureHoles();
        } else {
          // if no in cut mode, we just select/check it
          this.activeParcelsLayer.getSource().clear();
          this.activeParcelsLayer.getSource().addFeature(selectedFeature);
          this.onSelect(ppties.id);
          this.plotFeatureHoles(ppties.id);
        }
        return;
      }

      // now in cut mode
      // if not ppties or not our active cropfield, return;
      if (!this.currentCropfield || !this.currentCropfield.id) return;

      // cut mode

      if (this.mapCutMode == 2) {
        let geom = this.activeFeature.getGeometry();
        let coordinates = geom.getCoordinates()[0];

        let vertices = new ol.geom.MultiPoint(coordinates);

        let candidate = vertices.getClosestPoint(evt.coordinate);

        this.selectedPoints.push(candidate);
      }

      EventBus.$emit("nextGuideStep");

      // if we had two points
      if (this.selectedPoints.length == 2) {
        // if (this.mapCutMode == 1) {
        //   this.showCutLine();
        //   this.showDistanceTooltip = true;
        // }
        if (this.mapCutMode == 2) {
          this.showPossiblePaths();
        }
      }

      // refresh the map
      this.activeCropfieldLayer.getSource().dispatchEvent("change");
      this.activeParcelsLayer.getSource().dispatchEvent("change");
      this.layerSource.dispatchEvent("change");
      this.firstCropsLayer.getSource().dispatchEvent("change");
      this.secondCropsLayer.getSource().dispatchEvent("change");
    },

    /**
     * Permet d'agrandir l'image d'une note
     * @param {*} feature représente une note
     */
    viewNoteImage(feature) {
      let imageFile = feature.getProperties().imageFile;
      if(imageFile) EventBus.$emit("view_note_image", imageFile);
    },

    /**
     * clear all activeParcelsLayer features
     */
    reinitializeParcelsLayer() {
      this.activeParcelsLayer.getSource().clear();
      this.layerSource.dispatchEvent("change");
      this.activeParcelsLayer.getSource().dispatchEvent("change");
      this.firstCropsLayer.getSource().dispatchEvent("change");
      this.secondCropsLayer.getSource().dispatchEvent("change");
    },
    getGeometryFromLayer(layer) {
      let features = layer.getSource().getFeatures();
      console.log(features);

      if (features.length == 0 || !features[0]) return;
      return this.getGeometryFromFeature(features[0]);
    },
    getGeometryFromFeature(feature) {
      let writer = new ol.format.GeoJSON();
      let geom = writer.writeFeature(feature);
      return JSON.parse(geom);
    },
    writeGeometryToLayer(geojson, layer, clear = false) {
      if (clear) {
        layer.getSource().clear();
      }
      try {
        let activeFeature = new ol.format.GeoJSON().readFeature(geojson);
        layer.getSource().addFeature(activeFeature);
      } catch (error) {}
      layer.dispatchEvent("change");
    },
    //------------------------------------------------------------------
    //  Cut mode
    //------------------------------------------------------------------
    editGeometry() {
      this.$router
        .push({ name: "cropfield.map.details", params: { cropfield_id: this.currentEntry.id, mode: "editGeometry" } })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    },

    //------------------------------------------------------------------
    //  Modify the geometry
    //------------------------------------------------------------------
    // create holes

    showActiveCropfield(withDots = false) {
      let id = this.currentCropfield.id;

      let geojson = this.geometries3857.find((g) => g.properties.id == id);

      this.writeGeometryToLayer(geojson, this.activeCropfieldLayer, true);

      this.activeCropfieldLayer.getSource().dispatchEvent("change");
    },

    // Affiche la variété active
    showActiveVariety(feature) {
      this.activeVariety = feature;
      // Recopie la géométrie de la variété sur la couche active
      this.activeVarietyLayer.getSource().clear();
      this.activeVarietyLayer.getSource().addFeature(feature);
      this.activeVarietyLayer.getSource().dispatchEvent("change");

      // Retire la géométrie de la variété de la couche non active
      this.varietiesLayer.getSource().removeFeature(feature);
      this.varietiesLayer.getSource().dispatchEvent("change");
    },

    // Nettoie la couche des variétés actives
    hideActiveVariety() {
      if(this.activeVarietyLayer) {
        this.activeVarietyLayer.getSource().clear();
        this.activeVarietyLayer.getSource().dispatchEvent("change");
      }
    },

    // rajoute la dernière variété active à la couche des variétés non actives
    reinitializeVarietyLayer() {
      if (this.varietyGeometries3857 && this.varietiesLayer && this.activeVariety){
        this.varietiesLayer.getSource().addFeature(this.activeVariety);
        this.varietiesLayer.getSource().dispatchEvent("change");
      }
    },

    debounce(callback, delay) {
      let t = this;
      return function () {
        var args = arguments;
        var context = this;
        clearTimeout(t.debounceTimer);
        t.debounceTimer = setTimeout(function () {
          callback.apply(context, args);
        }, delay);
      };
    },

    computeSurfaceFromOLFeature(feature) {
      let writer = new ol.format.GeoJSON();
      let featureGeojson = writer.writeFeature(feature);
      featureGeojson = JSON.parse(featureGeojson);
      featureGeojson = project4326(featureGeojson);
      return computeSurface(featureGeojson);
    },

    disableOutlineMode() {
      this.outlineMode = false;
    },

    /**
     * Provoque l'affichage d'une couche de la carte
     * @param {*} layerIndex
     */
    activeALayer(layerIndex) {
      this.layerFilters[layerIndex].value = true;
    }
    //------------------------------------------------------------------
    //  Machine Path
    //------------------------------------------------------------------
  },
};
