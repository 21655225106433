import Gateway from "./base";

export default class CropApi {
  /**
   * Get All cropfields
   */
  static getCrops(params) {
    return Gateway.get("/api/crops/", params);
  }

   /**
   * Get tour input form
   *
   * @param activityType
   */
   static getInputForm({ crop, activityType, stockStore, cropField }) {
    let params = { activityType, allgrowthstages: true, flatlist: true }
    if (stockStore != null) params.stockStore = stockStore
    if (cropField != null) params.cropField = cropField
    return Gateway.get(`/api/crops/${crop}/inputForm/`, params);
  }
}
