
import MachinesFuelFill from "./MachineFuelFillList.vue";

import CommonViewIndex from "@/views/common/CommonViewIndex.vue";
import CommonViewList from "@/views/common/CommonViewList.vue";
import CommonViewFiltering from "@/views/common/CommonViewFiltering.vue";
import CommonViewDetail from "@/views/common/CommonViewDetail.vue";
import { generateDetailViews } from "@/views/common/details/commons";
import { views } from "@/views/machines/details/index";
import { config } from "./config";
import MachinesReportIndex from "@/views/machines/reports/MachinesReportIndex.vue";
import MachinesReportUsage from "@/views/machines/reports/MachinesReportUsage.vue";

export default [
  {
    component: CommonViewIndex,
    path: "/machines",
    props: config,
    children: [
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
        },
        path: "",
        name: "machines.list",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: MachinesFuelFill,
        },
        path: ":machines_id/fuelfill",
        name: "machines.fuelfill",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: MachinesReportIndex,
        },
        path: "reports",
        name: "machines.reports",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: MachinesReportUsage,
        },
        path: "report-machines-usage",
        name: "machines.reports.machines_usage",
      },
      ...generateDetailViews(views, CommonViewList, CommonViewFiltering, CommonViewDetail),

    ],
  },
];
