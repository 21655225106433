import Gateway from "./base";

export default class HarvestsStorageApi {

  /**
   * Get All harvestsStorages
   */
  static getAllHarvestsStorages({campaign, crop, warehouse, harvestId, farmId}) {
    return Gateway.get("/api/harvestStorage/", {
      detail: true,
      ordering: "-doneDate",
      campaign: campaign,
      ...{
        ...(crop && {crop: crop }),
        ...(warehouse && {location: warehouse }),
        ...(farmId && {farm_id: farmId}),
        ...(harvestId && {id: harvestId }),
      },
    });
  }

  /**
   * Get All harvestsStorage by activity
   *
   * @param idActivity
   */
  static getHarvestsStorage(idActivity) {
    return Gateway.get(`/api/harvestStorage/`, { activity: idActivity });
  }

  /**
   * Get one harvestStorage by id
   *
   * @param id
   */
  static getHarvest(id) {
    return Gateway.get(`/api/harvestStorage/${id}/`);
  }

  /**
   * Create Harvest
   *
   * @param data
   */
  static createHarvest(data) {
    return Gateway.post(`/api/harvestStorage/`, data);
  }

  /**
   * Update Harvest
   *
   * @param data
   * @param id
   */
  static updateHarvest(data, id) {
    return Gateway.put(`/api/harvestStorage/${id}/`, data);
  }

  /**
   * delete Harvest
   *
   * @param id
   */
  static deleteHarvest(id) {
    return Gateway.delete(`/api/harvestStorage/${id}/`);
  }

  /**
   * Get All filters harvestsStorages
   *
   */
   static getFiltersHarvestStorage(query, campaign, farmId, params) {
    return Gateway.get("/api/harvestStorage/filtre/", { attrib: query, campaign: campaign, farm_id: farmId, ...params });
  }
}
