import Vue from "vue";
import { DateTime } from "luxon";

Vue.filter("timeago", (value, locale, timezone) => {
  if (!value) {
    return "";
  }

  return DateTime.fromISO(value)
    .setZone(timezone ? timezone : "Europe/Paris")
    .setLocale(locale)
    .toRelative();
});
