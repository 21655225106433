<template>
    <div>
			<div v-for="activity in activitiesByInputsStock" :key="activity.activity">
				<!-- date de début et parcelle de l'activité -->
				<b-row @click="goToActivity(activity.activity)" class="activity-row">
					<b-col cols = "4" ><span class="subtitle-edit-form"> {{ dateToSlash(activity.startDate) }} </span></b-col>
					<b-col cols = "8"> <span class="subtitle-edit-form"> {{ activity.cropField_name }} </span> </b-col>
				</b-row>

				<!-- Surface de l'activité et quantité d'intrant utilisée -->
				<div v-if="activity.surface !== null" class="text-infos bg-white">
					<b-row>
						<b-col cols = "6"> <span class="text-infos">{{ $t('inputStock.details.surface') }} </span></b-col>
						<b-col cols = "6" class="text-right"> <span class="text-infos">{{ activity.surface }} {{ $t('unitDenomination.hectare') }} </span></b-col>
					</b-row>
					<b-row>
						<b-col cols = "6"> <span class="text-infos">{{ $t('inputStock.details.quantity') }} </span></b-col>
						<b-col cols = "6" class="text-right"> <span class="text-infos">{{ formatNumber(fixDigits(activity.total_quantity, 5)) }} {{ activity.total_quantity_unit }} </span></b-col>
					</b-row>
				</div>
				<div v-else class="text-infos bg-white">
					<b-row>
						<b-col cols = "12"> <span class="text-infos">{{ $t('inputStock.details.surface_not_defined') }} </span></b-col>
					</b-row>
				</div>
			</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { dateToSlashString } from "@/utils/commons";
import { i18n } from "@/i18n.js";
import { fixDigits, formatNumber} from "@/utils/commons";

export default {
	data() {
		return {
		};
	},

	async mounted() {
		await this.$store.dispatch("getActivitiesByInputsStock", {idInputsStock: this.currentId});
	},

  computed: {
    ...mapGetters({
      currentId: "inputStock/currentId",
			activitiesByInputsStock: "activitiesByInputsStock",
    }),

  },

	methods: {
		formatNumber,
		fixDigits,
		/**
		 * @description prend une date et la retourne sous la forme dd/mm/AAAA
		 */
		dateToSlash(aDate) {
			if(!aDate)
				return i18n.t("harvestStorage.details.no_date_listed");
			else
				return dateToSlashString(new Date(aDate));
		},

		/**
		 * @description Affiche le détail d'une activité
		 */
		goToActivity(id) {
			console.log("goToActivity", id)
			this.$router.push({ name: "activity.details", params: { activity_id: id } });
		},

  },

}
</script>

<style lang="scss" scoped>
.activity-row {
  cursor: pointer;
//   border-top: 1px solid $grey;
}
</style>