<template>
  <div class="d-flex justify-content-end align-items-end ">
    <div class="mr-4 green-variant">
      <button
        v-if="(oneSelectedCropfield || onlyOneCheckedCropfield) && !isMapView"
        class="btn btn-sm align-bottom btn-secondary"
        @click="editGeometry"
      >
        <span v-if="currentCropfieldHasGeometry">
          {{ $t("cropfields.details.edit_geometry") }}
        </span>
        <span v-else>{{ $t("cropfields.details.define_geometry") }}</span>
      </button>
      <div id="merge-btn-wrapper" tabindex="0">
        <button
          v-if="severalCropfields && !isMapView"
          class="btn btn-sm align-bottom btn-secondary"
          :disabled="!mergeAllowed"
          @click="mergeCropfields({ routeName: $route.name })"
        >
          {{ $t("cropfields.details.merge") }}
        </button>
        <b-tooltip v-if="!mergeAllowed" target="merge-btn-wrapper">{{
          $t("cropfields.details.merge_not_allowed")
        }}</b-tooltip>
      </div>
    </div>
    <div class="d-flex">
      <div
        v-if="$route.params.cropfield_id"
        @click="setLastCropfieldView('list', 'cropfield.details')"
        class="tab-icon"
        :class="{ active: $route.name != 'cropfield.map' && $route.name != 'cropfield.map.details' }"
      >
        <img src="@/assets/images/list-interface-icon.svg" />
      </div>
      <div
        v-else
        @click="setLastCropfieldView('list', 'cropfield.list')"
        class="tab-icon"
        :class="{ active: $route.name != 'cropfield.map' && $route.name != 'cropfield.map.details' }"
        :title="$t('cropfields.map.show_list')"
      >
        <img src="@/assets/images/list-interface-icon.svg" />
      </div>
      <div
        v-if="$route.params.cropfield_id"
        @click="setLastCropfieldView('map', 'cropfield.map.details', { cropfield_id: LastCropfield_id })"
        class="tab-icon"
        :class="{ active: $route.name == 'cropfield.map.details' }"
      >
        <img src="@/assets/images/cropfield-map-icon.svg" />
      </div>
      <div
        v-else
        @click="setLastCropfieldView('map', 'cropfield.map')"
        class="tab-icon"
        :class="{ active: $route.name == 'cropfield.map' }"
        :title="$t('cropfields.map.view_map')"
      >
        <img src="@/assets/images/cropfield-map-icon.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/eventBus.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

// turf
import * as turf from "@turf/turf";

export default {
  methods: {
    ...mapActions({
      mergeCropfields: "cropfield/mergeCropfields",
      deleteEntry: "cropfield/deleteEntry",
      deleteEntryOfStore: "cropfield/deleteEntryOfStore",
    }),
    async editGeometry() {
      await this.$router
        .push({ name: "cropfield.map.details", params: { cropfield_id: this.LastCropfield_id, mode: "editGeometry" } })
        .catch(error => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
      //EventBus.$emit("enableModifyGeometry");
    },
    setLastCropfieldView(view = "list", targetRoute, params = {}) {
      this.$cookies.set("last_cropfield_view", view);
      EventBus.$emit("setCropfieldView");
      this.$router.push({name: targetRoute, params: params});
    },
    handleCheckboxChange() {},
  },
  computed: {
    ...mapGetters({
      entriesChecked: "cropfield/entriesChecked",
      checkedGeometries: "cropfield/checkedGeometries",
      currentCropfieldHasGeometry: "cropfield/currentCropfieldHasGeometry",
      cropfields: "cropfield/entries",
      currentEntry: "cropfield/currentEntry",
      mergeAllowed: "cropfield/mergeAllowed",
    }),
    polygons() {
      return this.checkedGeometries.map(g => g && turf.polygon(g.coordinates));
    },
    currentCropfield() {
      if (this.entriesChecked.length == 0) return this.currentEntry;
      return this.cropfields.find(e => e.id == this.entriesChecked[0]);
    },
    oneSelectedCropfield() {
      return this.currentEntry && this.currentEntry.id && this.entriesChecked.length == 0;
    },
    onlyOneCheckedCropfield() {
      return this.entriesChecked.length == 1;
    },
    severalCropfields() {
      return this.entriesChecked.length > 1;
    },
    isMapView() {
      return this.$route.name.includes("cropfield.map");
    },
    LastCropfield_id() {
      // Si une parcelle a une seconde culture, c'est l'id de la parcelle suivante qui est retournée
      // Sinon on retourne l'id de la parcelle en question
      if (this.currentEntry?.nextCropFields && this.currentEntry.nextCropFields[0])
        return this.currentEntry.nextCropFields[0].id;
      return this.currentEntry?.id || 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-icon {
  width: 40px;
  background: $grey;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:first-child {
    border-radius: 7px 0 0 7px;
  }

  &:last-child {
    border-radius: 0 7px 7px 0;
  }

  &.active {
    background: $dark-grey;
  }

  img {
    width: 100%;
  }
}
</style>

<style lang="scss">
.tabs-sticky {
  position: sticky;
  top: 0;
}
</style>
