<template>
  <div>
    <!-- Données à visualiser -->
    <h4>{{ $t('analysis.options.data_type')}}</h4>
    <div class="green-variant">
      <b-form-group>
        <b-form-radio
          v-for="opt in options.filter(opt => opt.show({$userCan: this.$userCan}))"
          :key="opt.name"
          :value="opt.name"
          v-model="selectedChart"
        >
          {{opt.title}}
        </b-form-radio>
      </b-form-group>
    </div>

    <!-- Choix de l'affichage graphe/tableau -->
    <h4>{{ $t('analysis.options.display') }}</h4>
    <div class="green-variant">
      <b-form-group>
        <b-form-checkbox
          :checked="displayChart"
          @change="$emit('displaychart')"
        >
          {{ $t('analysis.options.chart') }}
        </b-form-checkbox>
        <b-form-checkbox
          :checked="displayGrid"
          @change="$emit('displaygrid')"
        >
          {{ $t('analysis.options.grid') }}
        </b-form-checkbox>
      </b-form-group>
    </div>
	</div>
</template>
<script>
export default {
  data() {
    return {
      selectedChart: "",
    }
  },

  props: {
    module: {
      type: String,
      default: "",
    },
    routes: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Array,
      default: () => {},
    },
    displayChart: {
      type: Boolean,
      default: true,
    },
    displayGrid: {
      type: Boolean,
      default: true,
    },
  },

  async mounted() {
    setTimeout(() => {
      this.selectedChart = this.$route.params.analysis_id;
    }, 400)
  },

  watch: {
    selectedChart: {
      handler() {
        if(this.$route.params.analysis_id != this.selectedChart)
          this.$router.push({name: this.routes.options.name, params: {analysis_id: this.selectedChart}})
      },
    },
  }
}
</script>
<style lang="scss" scoped>
h4 {
  color: $grey;
  margin-bottom: 10px;
}

.filter-limit {
  max-height: 145px;
  overflow: hidden;
  transition: height 0.2s ease;

  &.open {
    max-height: none;
    overflow: auto;
  }
}

.custom-control {
  font-size: 0.7em;
  line-height: 2;
}
</style>