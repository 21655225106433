import Gateway from "./base";

export default class CampaignApi {
  /**
   * Get All campaigns
   */
  static getCampaigns(params) {
    return Gateway.get("/api/campaigns/", params);
  }

  /**
   * Get one campaign by id
   *
   * @param id
   */
  static getCampaign(id) {
    return Gateway.get(`/api/campaigns/${id}/`);
  }

  /**
   * Create campaign
   *
   * @param data
   */
  static createCampaign(data) {
    return Gateway.post(`/api/campaigns/`, data);
  }

  /**
   * Update campaign
   *
   * @param data
   * @param id
   */
  static updateCampaign(data, id) {
    return Gateway.patch(`/api/campaigns/${id}/`, data);
  }

  /**
   * delete campaign
   *
   * @param id
   */
  static deleteCampaign(id) {
    return Gateway.delete(`/api/campaigns/${id}/`);
  }
}
