<template>
  <!-- Treatment Component : FileFormat -->
  <!-- Displays a file format selector. Below are listed in allFormat all the possible extensions. But you can narrow it with the exportAllowedFormats attribute of the treatment -->
  <div class="mt-4">
    <h4 class="subtitle-form mb-2">
      {{ $t("commons.exports_reports.which_format") }}
    </h4>
    <b-form-select v-model="formatChoice" :options="formatOptions"></b-form-select>
  </div>
</template>

<script>
import TreatmentFormPartMixin from "@/components/commons/treatments/TreatmentFormPartMixin";
import { TreatmentFormPart } from "@/models/treatments/Treatment.js";

export default {
  mixins: [TreatmentFormPartMixin],
  data() {
    return {
      formatChoice: null,
      allFormats: [
        { value: "xlsx", text: "Excel (.xlsx)" },
        { value: "pdf", text: "PDF" },
        { value: "csv", text: "CSV" },
        { value: "json", text: "JSON" },
        { value: "telepac", text: "telepac (.xml)" },
        { value: "scopix", text: "scopix (.json)" },
        { value: "shapefile", text: "shapefile (shp -> zip)"},
        { value: "html", text: "A l'écran" },
      ],
    };
  },
  mounted() {
    if (!this.formatChoice && this.formatOptions.length > 0) this.formatChoice = this.formatOptions[0].value;
  },
  computed: {
    formatOptions() {
      // if no specified allowed format, return all
      if ((this.treatment.exportAllowedFormats || []).length == 0) return this.allFormats;
      // else return matching format options in the correct order
      return (this.treatment.exportAllowedFormats || []).map((f) => this.allFormats.find((e) => e.value == f));
    },
  },
  methods: {
    validateForm() {
      this.isValid = true;
      this.treatment.exportedFileFormat = this.formatChoice;

      let fileFormat = this.formatChoice
      if (fileFormat == "xlsx") fileFormat = "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      return new TreatmentFormPart("format", fileFormat);
    },
  },
};
</script>

<style></style>
