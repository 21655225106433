<template>
  <div>
		<!-- New inventory -->
    <div v-if="!newInventory" align="center" class="mt-3 mb-3">
      <button class="btn align-bottom button-add-supply" @click="addInventory">{{ $t("inputStock.details.new_inventory") }}</button>
    </div>
		<AddInventory :update="this.update" v-if="newInventory && loadPage" @clicked="newInventory = false"/>

		<button v-if="!loadPage" class="btn btn-primary" type="button" disabled>
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      {{ $t("inputStock.details.loading_data") }}
    </button>
		<div v-show="loadPage">
			<!-- Dernier inventaire -->
			<b-row class="mb-1">
				<b-col> <h4 class="title-form"> {{ $t("inputStock.details.last_inventory") }} </h4></b-col>
			</b-row>
			<div v-if="stockState.lastInventoryDate">
				<b-row class="m-0">
					<!-- Intitulé de la date du dernier inventaire -->
					<b-col cols=6 class="shape-first-cell whitout-bg"> <img :src="iconCalendar" alt="calendar" />
						<h4 class="subtitle-form">{{ $t("harvestStorage.list.date") }}</h4>
					</b-col>
					<!-- Intitulé de la quantité au dernier inventaire -->
					<b-col cols=4 class="shape-first-cell whitout-bg">
						<h4 class="subtitle-form">{{ $t("inputStock.details.quantity") }}</h4>
					</b-col>
				</b-row>

				<b-row class="m-0 bg-white">
					<!-- Date du dernier inventaire -->
					<b-col cols=6 class="text-infos bg-white">
						{{ stockState.lastInventoryDate ? formatDate(stockState.lastInventoryDate, "date") : $t("empty_information")}}
					</b-col>
					<!-- Quantité au dernier inventaire -->
					<b-col cols=4 class="text-infos bg-white">
						{{ fixedDigits(stockState.lastInventoryQuantity, 4).toLocaleString('fr-FR') }} {{ stockState.unit_unit}}
					</b-col>
					<b-col cols=2 class="text-infos bg-white update-delete-buttons">
						<button class="btn update-delete-buttons" @click="onDelete()">
							<img :src="deleteIcon" alt="icon delete action" />
						</button>
						<button class="btn update-delete-buttons" @click="updateInventory()">
							<img :src="updateIcon" alt="icon update action" />
						</button>
					</b-col>
				</b-row>
			</div>
			<b-row v-else class="m-0">
				<b-col class="text-infos bg-white"> {{ $t("is_empty") }} </b-col>
			</b-row>

			<!-- Entrées/Sorties -->
			<b-row class="mb-1 mt-3">
				<b-col> <h4 class="title-form"> {{ $t("inputStock.details.inputs_outputs") }} </h4></b-col>
			</b-row>
			<div>
				<b-row class="m-0">
					<!-- Intitulé de la quantité approvisionnée -->
					<b-col cols=6 class="shape-first-cell whitout-bg">
						<h4 class="subtitle-form">{{ $t("inputStock.details.quantity_supplied") }}</h4>
					</b-col>
					<!-- Intitulé de la quantité utilisée -->
					<b-col cols=6 class="shape-first-cell whitout-bg">
						<h4 class="subtitle-form">{{ $t("inputStock.details.quantity_used") }}</h4>
					</b-col>
				</b-row>
				<b-row class="m-0 bg-white">
					<!-- quantité approvisionnée -->
					<b-col cols=6 class="text-infos bg-white">
						<span v-if="stockState.quantitySupplied != null"> {{ fixedDigits(stockState.quantitySupplied, 4).toLocaleString('fr-FR') }} {{ stockState.unit_unit}} </span>
						<span v-else> {{ $t("is_empty") }} </span>
					</b-col>
					<!-- quantité utilisée -->
					<b-col cols=6 class="text-infos bg-white">
						<span v-if="stockState.quantityUsed != null">{{ fixedDigits(stockState.quantityUsed, 4).toLocaleString('fr-FR') }} {{ stockState.unit_unit}} </span>
						<span v-else> {{ $t("is_empty") }} </span>
					</b-col>
				</b-row>
			</div>

			<!-- Stock estimé -->
			<b-row class="mb-1 mt-3">
				<b-col> <h4 class="title-form"> {{ $t("inputStock.details.estimated_stock") }} </h4></b-col>
			</b-row>
			<div v-if="stockState.estimatedStock">
				<b-row class="m-0">
					<!-- Intitulé de la quantité du stock estimé -->
					<b-col cols=6 class="shape-first-cell whitout-bg">
						<h4 class="subtitle-form">{{ $t("inputStock.details.without_tours") }}</h4>
					</b-col>
					<b-col cols=6 class="shape-first-cell whitout-bg">
						<h4 class="subtitle-form">{{ $t("inputStock.details.with_tours") }}</h4>
					</b-col>
				</b-row>
				<b-row class="m-0">
					<!-- quantité de stock estimé -->
					<b-col cols=6 class="text-infos bg-white">
						<span v-if="stockState.estimatedStock != null">{{ formatNumber(fixedDigits(stockState.estimatedStock, 4)) }} {{ stockState.unit_unit}}</span>
					</b-col>
					<b-col cols=6 class="text-infos bg-white">
						<span v-if="stockState.estimatedStock != null">{{ formatNumber(fixedDigits(stockState.plannedStock, 4)) }} {{ stockState.unit_unit}}</span>
					</b-col>
				</b-row>
			</div>
			<b-row v-else class="m-0">
				<b-col class="text-infos bg-white"> {{ $t("is_empty") }} </b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import calendar from "@/assets/images/calendar.svg";
import AddInventory from "@/components/inputstock/edit/AddInventory.vue";
import delIcon from "@/assets/images/delete_icon.svg";
import updIcon from "@/assets/images/update.svg";
import { askConfirm, formatDate, fixDigits, formatNumber} from "@/utils/commons";
export default {
	data() {
		return {
      iconCalendar: calendar,
			newInventory: false,
			deleteIcon: delIcon,
      updateIcon: updIcon,
			update: false,
			loadPage: false,
		};
	},
	components: {
    AddInventory
  },

	mounted() {
		this.init();
	},

	computed: {
		...mapGetters({
      currentInput: "inputStock/currentEntry",
      stockState: "inputStock/stockState",
     }),
	},

	methods: {
		formatDate,
		formatNumber,
		init: async function() {
		this.loadPage = false;
		await this.$store.dispatch("inputStock/getStockState", {id: this.currentInput.id});

		this.loadPage = true;
		},
	


		/**
     * @description affiche le formulaire d'édition du dernier inventaire
     */
    addInventory() {
      this.newInventory = !this.newInventory;
      this.update = false;
    },

		/**
     * @description affiche le formulaire de modification du dernier inventaire
     */
    updateInventory() {
      this.newInventory = !this.newInventory;
      this.update = true;
    },


    /**
     * @description supprime le dernier inventaire
     */
    async onDelete(supplyId) {
      if(await askConfirm(this.$t("confirm_delete")))
      {
        await this.$store.dispatch("deleteInputInventory", {id : this.stockState.lastInventoryId});
        this.init();
      }
    },

	fixedDigits(value, numberOfDigits){
		return fixDigits(value, numberOfDigits)
	}
  },

}
</script>

<style lang="scss" scoped>
img {
  width: 0.8em;
  float: left;
  margin: auto 5px 5px auto;
}

.title-form {
  font-size: 16px;
}

.button-add-supply {
  background-color: $background-text-color;
  width: 250px;
}
</style>