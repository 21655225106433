<template>
  <b-form-input
    size="sm"
    v-model="value"
    type="text"
    @change="$emit('updateRow', rowId, payload)"
    :required="required"
    :class="{ 'bg-transparent border-0': this.field == 'appellation' }"
    :disabled="this.field == 'appellation'"
  ></b-form-input>
</template>

<script>
export default {
  props: ["rowId", "field", "cellData", "required"],
  data() {
    return {
      value: null,
    };
  },
  created() {
    this.value = this.cellData;
  },
  computed: {
    payload() {
      let payload = {};
      payload[this.field] = this.value;
      return payload;
    },
  },
  watch: {
    cellData() {
      this.value = this.cellData;
    },
  },
};
</script>

<style lang="scss" scoped></style>
