<template>
  <div v-if="entry">
    <b-row v-for="(field, index) in fieldsToShow" :key="index" class="mb-2">
      <b-col cols="4" v-if="field.title">
        <h4 class="subtitle-form">{{ field.title }}</h4>
      </b-col>
      <b-col
        v-if="field.condition ? field.condition(entry) : true"
        :cols="field.title ? 8 : 12"
        class="d-flex align-items-start"
      >
        <div class="bg-text-color text-infos config-density-semence">
          {{ field.display(entry) }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import { mapGetters } from "vuex";

export default {
  mixins: [DetailUpdateMixin],
  data() {
    return {
      fields: [
        {
          condition: entry => entry.authorized != null,
          display: entry => {
            if (entry.authorized === true) return "Produit autorisé";
            if (entry.authorized === false) return "Produit retiré";
            return;
          },
        },
        {
          title: "Type de produit",
          display: entry => entry.productType_designation,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["currentProduct"]),
    ...mapGetters({
      inputsStock: "inputStock/entries",
      currentEntry: "inputStock/currentEntry",
      currentEditedEntry: "inputStock/editedEntry",
    }),
    entry() {
      return (this.currentEntry && this.currentEntry.product_id) || this.currentEditedEntry.product_id
        ? this.currentProduct
        : this.currentEntry;
    },
    fieldsToShow() {
      return this.fields.filter(f => (f.condition ? f.condition(this.currentEntry) : true));
    },
  },
};
</script>

<style lang="scss" scoped></style>
