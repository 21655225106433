import MiscellaneousApi from "@/api/miscellaneous.api";

export const COLUMNS_PREFERENCES = "GET COLUMNS PREFERENCES";
export const MISCELLANEOUS_LOADING = "LOAD MISCELLANEOUS";

export default {
  state: {
    columnsPreferences: {},
  },
  getters: {
    columnsPreferences: state => state.columnsPreferences,
  },
  mutations: {
    [COLUMNS_PREFERENCES]: (state, payload) => {
        state.columnsPreferences = payload;
    },

    [MISCELLANEOUS_LOADING]: (state, status) => {
    state.miscellaneous_loaded = !status;
    },

    create_Columns(state, { module, data }) {
      // Si le module n'existe pas encore, on le crée
      if (!state.columnsPreferences[module]) {
          state.columnsPreferences[module] = {};
      }

      // Transformer et ajouter les colonnes dans le module
      state.columnsPreferences[module] = Object.entries(data).reduce((acc, [index, column]) => {
          acc[index] = {
              name: column.name,
              show: column.show,
          };
          return acc;
      }, {});
    },

    updateColumns(state, { module, index, value, name }) {
      if (state.columnsPreferences[module][index]) {
        // Si la colonne existe, on met juste à jour son état "show"
        state.columnsPreferences[module][index].show = value;
      } else {
        // Si elle n'existe pas, on l'ajoute à l'index donné
        state.columnsPreferences[module][index] = { name, show: value };
      }
    },
  },
  actions: {
    async getColumnsPreferences({ commit, dispatch }, payload) {
      commit(MISCELLANEOUS_LOADING, true);

      try {
        //récuperation des ColumnsPreferences
        const response = await MiscellaneousApi.get("columnsPreferences");


        commit(COLUMNS_PREFERENCES, JSON.parse(response.data.value));

      } catch (error) {
        if (error.response && error.response.status === 404) {
          // Si on obtient une erreur 404 (non trouvé), on crée les ColumnsPreferences
          try {
            dispatch('initColumnsPreferences', payload)
          } catch (createError) {
            console.error("Erreur lors de la création des préférences : ", createError);
          }
        } else {
          // Autre type d'erreur
          console.error("Erreur lors de la récupération des préférences : ", error);
        }
      }

      commit(MISCELLANEOUS_LOADING, false);
    },

    async initColumnsPreferences({commit, state}, payload){
      //On crée la columns dans le store columns preferences
      await commit("create_Columns", {
        module: payload.module,
        data: payload.data,
      });

      const response = await MiscellaneousApi.create({
        key: "columnsPreferences",
        value: JSON.stringify(state.columnsPreferences)
      });
      commit(COLUMNS_PREFERENCES, JSON.parse(response.data.value));
    },

    async addColumnsPreferences({ commit, state }, payload) {
      commit(MISCELLANEOUS_LOADING, true);

      //Ajouter la nouvelle column
      await commit("create_Columns", {
        module: payload.module,
        data: payload.data,
      });

      //update ColumnsPreferences
      const response = await MiscellaneousApi.update(
        "columnsPreferences",
        {key: "columnsPreferences", value: JSON.stringify(state.columnsPreferences)}
      );

      commit(COLUMNS_PREFERENCES, JSON.parse(response.data.value));

      commit(MISCELLANEOUS_LOADING, false);
    },

    async updateColumnsPreferences({ commit, state }, payload) {
      commit(MISCELLANEOUS_LOADING, true);

      //traitement de l'update de la liste
      await commit("updateColumns", {module: payload.module, index: payload.index, value: payload.value, name: payload.name})

      const response = await MiscellaneousApi.update(
        "columnsPreferences",
        {key: "columnsPreferences", value: JSON.stringify(state.columnsPreferences)}
      );

      commit(COLUMNS_PREFERENCES, JSON.parse(response.data.value));

      commit(MISCELLANEOUS_LOADING, false);
    },
    
  },
};
