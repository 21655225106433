
import Gateway from "./base";

export default class OperatorsAPI {
  /**
   * Get All Operators
   */
  static getOperators() {
    return Gateway.get("/api/operators/");
  }
}