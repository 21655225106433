import inputSupplyApi from "@/api/inputSupply.api";

export const ALL_INPUTSUPPLIES = "GET ALL INPUTSUPPLIES";
export const ONE_INPUTSUPPLY = "GET INPUTSUPPLY BY ID";
export const GET_INPUTSUPPLY_BY_INPUTSTOCK = "GET INPUTSUPPLY BY INPUTSTOCK ID";
export const CREATE_INPUTSUPPLY = "CREATE INPUTSUPPLY";
export const UPDATE_INPUTSUPPLY = "UPDATE INPUTSUPPLY";
export const DELETE_INPUTSUPPLY = "DELETE INPUTSUPPLY";
export const INPUTSUPPLY_LOADING = "LOAD INPUTSUPPLY";

export default {
  state: {
    inputSupplies: [],
    currentInputSupply: {},
    newInputSupply: {},
    inputSupplyUpdated: {},
    inputSupply_loaded: true,
  },
  getters: {
    inputSupplies: state => state.inputSupplies,
    currentInputSupply: state => state.currentInputSupply,
  },
  mutations: {
    [ALL_INPUTSUPPLIES]: (state, inputSupplies) => {
      state.inputSupplies = inputSupplies;
    },

    [ONE_INPUTSUPPLY]: (state, payload) => {
      state.currentInputSupply = payload.inputSupply;
    },

    [GET_INPUTSUPPLY_BY_INPUTSTOCK]: (state, payload) => {
      state.inputSupplies = payload;
    },

    [CREATE_INPUTSUPPLY]: (state, payload) => {
      state.newInputSupply = payload.inputSupply;
    },

    [UPDATE_INPUTSUPPLY]: (state, payload) => {
      state.inputSupplyUpdated = payload.inputSupply;
    },

    [DELETE_INPUTSUPPLY]: (state, payload) => {
      state.currentInputSupply = {}
    },

    [INPUTSUPPLY_LOADING]: (state, status) => {
      state.inputSupply_loaded = !status;
    },
  },
  actions: {
    async getAllInputSupplies({ commit }) {
      commit(INPUTSUPPLY_LOADING, true);
      const response = await inputSupplyApi.getAllInputSupplies();
      commit(ALL_INPUTSUPPLIES, response.data);
      commit(INPUTSUPPLY_LOADING, false);
      return response.data;
    },

    async getOneInputSupply({ commit }, payload) {
      commit(INPUTSUPPLY_LOADING, true);
      const response = await inputSupplyApi.getInputSupply(payload.id);
      commit(ONE_INPUTSUPPLY, { inputSupply: response.data });
      commit(INPUTSUPPLY_LOADING, false);
      return response.data;
    },

    async getInputSuppliesByInputStock({ commit }, payload) {
      commit(INPUTSUPPLY_LOADING, true);
      const response = await inputSupplyApi.getInputSuppliesByInputStock(payload);
      commit(GET_INPUTSUPPLY_BY_INPUTSTOCK, response.data);
      commit(INPUTSUPPLY_LOADING, false);
      return response.data;
    },

    async createInputSupply({ commit }, payload) {
      commit(INPUTSUPPLY_LOADING, true);
      const response = await inputSupplyApi.createInputSupply(payload.data);
      commit(CREATE_INPUTSUPPLY, { inputSupply: response.data });
      commit(INPUTSUPPLY_LOADING, false);
      return response.data;
    },
    async deleteInputSupply({ commit }, payload) {
      commit(INPUTSUPPLY_LOADING, true);
      const response = await inputSupplyApi.deleteInputSupply(payload.id);
      commit(DELETE_INPUTSUPPLY);
      commit(INPUTSUPPLY_LOADING, false);
      return response;
    },
    async updateInputSupply({ commit }, payload) {
      commit(INPUTSUPPLY_LOADING, true);
      const response = await inputSupplyApi.updateInputSupply(payload.data, payload.id);
      commit(UPDATE_INPUTSUPPLY, { inputSupply: response.data });
      commit(INPUTSUPPLY_LOADING, false);
      return response.data;
    },
  },
};
