import { mapStyles } from "@/views/cropfields/map.config";
import _ from "lodash";
import { getAreaOrLength } from "@/utils/geomatics";

export default {
  methods: {
    getCropfieldStyle(feature) {
      let t = this;
      let st = [
        new ol.style.Style({
          stroke: new ol.style.Stroke({
            color: mapStyles.cut.borderCut.edgeColor,
            width: 2,
          }),
          geometry: function (feature) {
            var coordinates = t.lineSliced;
            return new ol.geom.LineString(coordinates);
          },
        }),
        new ol.style.Style({
          fill: new ol.style.Fill({
            color: this.getCropfieldColor(feature),
          }),
          stroke: new ol.style.Stroke({
            color: this.getCropfieldBorderColor(feature),
            width: 2,
            miterLimit: 100,
          }),
          text: new ol.style.Text({
            text: this.displayFeatureText(feature),
            textBaseline: "middle",
            overflow: true,
            scale: 1.3,
            fill: new ol.style.Fill({
              color: this.getCropfieldTextColor(feature),
            }),
            stroke: new ol.style.Stroke({
              color: this.getCropfieldTextBorderColor(feature),
              width: 2,
              miterLimit: 100,
            }),
          }),
        }),

        new ol.style.Style({
          image: new ol.style.Circle({
            radius: 5,
            fill: new ol.style.Fill({
              color: "purple",
            }),
          }),
          geometry: function (feature) {
            return new ol.geom.MultiPoint(t.polygonInter);
          },
        }),
      ];

      // these were used for debug
      t.newLines.forEach((e, index) => {
        st.push(
          new ol.style.Style({
            stroke: new ol.style.Stroke({
              color: "#" + (((1 << 24) * Math.random()) | 0).toString(16),
              width: 4,
            }),
            geometry: function (feature) {
              var coordinates = JSON.parse(JSON.stringify(e.geometry.coordinates));
              return new ol.geom.LineString(coordinates);
            },
          }),
        );
      });
      // these were used for debug
      t.transLines.forEach((e, index) => {
        st.push(
          new ol.style.Style({
            stroke: new ol.style.Stroke({
              color: "#" + (((1 << 24) * Math.random()) | 0).toString(16),
              width: 4,
            }),
            geometry: function (feature) {
              var coordinates = JSON.parse(JSON.stringify(e.geometry.coordinates));

              return new ol.geom.LineString(coordinates);
            },
          }),
        );
      });
      return st;
    },
    getHideCropfielStyle(feature) {
      return [
        new ol.style.Style({
          fill: new ol.style.Fill({
            color: "#00000000", // Invisible
          }),
          stroke: new ol.style.Stroke({
            color: this.getCropfieldBorderColor(feature),
            width: 2,
            miterLimit: 100,

          }),
        }),
      ]
    },
    cutCropfields(feature) {
      return [
        new ol.style.Style({
          fill: new ol.style.Fill({
            color: mapStyles.cut.backgroundColor,
          }),
          stroke: new ol.style.Stroke({
            color: mapStyles.cut.edgeColor,
            width: 2,
          }),
          text: new ol.style.Text({
            text: this.getCutCropfieldText(feature),
            textBaseline: "top",
            overflow: true,
            scale: 1.5,
            fill: new ol.style.Fill({
              color: "#FFFFFFFF",
            }),
            stroke: new ol.style.Stroke({
              color: "#000000FF",
              width: 2,
              miterLimit: 100,
            }),
          }),
        }),
      ];
    },
    holeStyle(feature) {
      return [];
    },
    activeCropfieldHolesLayerStyle(feature) {
      let properties = feature.getProperties();
      let index = properties.index;

      return [
        new ol.style.Style({
          fill: new ol.style.Fill({
            color:
              this.selectedHole && index == this.selectedHole
                ? mapStyles.normal.holeHighlightBackgroundColor
                : mapStyles.normal.holeBackgroundColor,
          }),
        }),
        new ol.style.Style({
          stroke: new ol.style.Stroke({
            color: mapStyles.normal.holeEdgeColor,
            width: 2,
          }),
        }),
      ];
    },
    getCutCropfieldText(feature) {
      let properties = feature.getProperties();

      return properties.name + "\n" + properties.surface + " ha";
    },
    cutLineStyle(feature) {
      return [
        new ol.style.Style({
          image: new ol.style.Circle({
            radius: 4,
            fill: new ol.style.Fill({
              color: mapStyles.editGeometry.vertexColor,
            }),
            stroke: new ol.style.Stroke({ color: "white", width: 2 }),
          }),
          geometry: function (feature) {
            return new ol.geom.MultiPoint(feature.getGeometry().getCoordinates());
          },
        }),
        new ol.style.Style({
          stroke: new ol.style.Stroke({
            color: mapStyles.cut.lineCut.color,
            width: 2,
            miterLimit: 100,
          }),
        }),
      ];
    },
    helpersStyle(feature) {
      return [
        new ol.style.Style({
          stroke: new ol.style.Stroke({
            color: mapStyles.helpers.color,
            width: 2,
            lineDash: [5, 5],
          }),
        }),
      ];
    },
    getIslandStyle(feature) {
      return [
        new ol.style.Style({
          fill: new ol.style.Fill({
            color: mapStyles.normal.islandBackgroundColor,
          }),
          stroke: new ol.style.Stroke({
            color: mapStyles.normal.islandBorderColor,
            width: 2,
            miterLimit: 100,
          }),
        }),
      ];
    },
    /**
     * Style à appliquer à la couche des variétés sur la carte
     * @param {*} feature
     * @returns
     */
    getVarietyStyle(feature) {
      return [
        new ol.style.Style({
          fill: new ol.style.Fill({
            color: mapStyles.normal.varietyBackgroundColor,
          }),
          stroke: new ol.style.Stroke({
            color: mapStyles.normal.varietyBorderColor,
            width: 2,
            miterLimit: 100,
          }),
        }),
        new ol.style.Style({
          text: new ol.style.Text({
            text: this.displayVarietyName(feature),
            textBaseline: "middle",
            overflow: true,
            scale: 1.5,
            fill: new ol.style.Fill({
              color: "#FFFFFFFF",
            }),
            stroke: new ol.style.Stroke({
              color: "#000000FF",
              width: 2,
              miterLimit: 100,
            }),
          }),
        }),
      ];
    },

    /**
     * Style à appliquer à la couche des variétés actives
     * @param {*} feature
     * @returns
     */
    getActiveVarietyStyle(feature) {
      return [
        new ol.style.Style({
          fill: new ol.style.Fill({
            color: mapStyles.normal.activeVarietyBackgroundColor,
          }),
          stroke: new ol.style.Stroke({
            color: mapStyles.normal.activeVarietyBorderColor,
            width: 2,
            miterLimit: 100,
          }),
        }),
        new ol.style.Style({
          text: new ol.style.Text({
            text: this.displayVarietyName(feature),
            textBaseline: "middle",
            overflow: true,
            scale: 1.5,
            fill: new ol.style.Fill({
              color: "#FFFFFFFF",
            }),
            stroke: new ol.style.Stroke({
              color: "#000000FF",
              width: 2,
              miterLimit: 100,
            }),
          }),
        }),
      ];
    },
    /**
     * @description retourne les noms des variétés pour l'affichage sur la carte
     * @param {*} feature
     */
		displayVarietyName(feature) {
      let properties = feature.getProperties();

      let name = properties.name;

      if (!name) return;

      return name;
    },

    /**
     * Style à appliquer à la couche des notes sur la carte
     * @param {*} feature
     * @returns
     */
    getNoteStyle(feature){
      let image = feature.getProperties()?.image;
      return [
        new ol.style.Style({
          image: new ol.style.Icon({
            anchor: [0, 80],
            anchorXUnits: 'fraction',
            anchorYUnits: 'pixels',
            scale: 0.2,
            src: image && this.noteImgSrc[image] ? this.noteImgSrc[image] : this.redPin
          })

        })
      ];
    },

    /**
     * Style à appliquer à la couche des machines
     * @param {*} feature
     */
    getMachineStyle(feature){
      let properties = feature.getProperties();
      return [
        new ol.style.Style({
          image: new ol.style.Icon({
            scale: 1,
            src: this.tractor
          })
        }),
        new ol.style.Style({
          image: new ol.style.Icon({
            src: this.upArrow,
            scale: properties.speed > 0 ? 1 : 0,
            rotation: -properties.direction
          })
        })
      ]
    },

    /**
     * Style à appliquer à la couche des cours d'eau
     * @param {*} feature
     */
    getWaterCourseStyle(feature) {
      return [
        new ol.style.Style({
          stroke: new ol.style.Stroke({
            color: mapStyles.waterCourse.color,
            width: 3,
          }),
        }),
      ];
    },
    getActiveCropfieldStyle(feature) {
      let t = this;
      let st = [
        new ol.style.Style({
          image: new ol.style.Circle({
            radius: this.holeMode ? 0 : 4,
            fill: new ol.style.Fill({
              color: mapStyles.editGeometry.vertexColor,
            }),
          }),
          geometry: function (feature) {
            var coordinates = [];
            feature
              .getGeometry()
              .getCoordinates()
              .forEach(function (points) {
                coordinates.push(...points);
              });
            return new ol.geom.MultiPoint(coordinates);
          },
        }),
        new ol.style.Style({
          image: new ol.style.Circle({
            radius: 5,
            fill: new ol.style.Fill({
              color: mapStyles.cut.borderCut.vertexColor,
            }),
          }),
          geometry: function (feature) {
            var coordinates = t.selectedPoints;
            return new ol.geom.MultiPoint(coordinates);
          },
        }),
        new ol.style.Style({
          fill: new ol.style.Fill({
            color: mapStyles.editGeometry.backgroundColor,
          }),
          stroke: new ol.style.Stroke({
            color: mapStyles.editGeometry.edgeColor,
            width: 2,
            miterLimit: 100,
          }),
        }),
        new ol.style.Style({
          text: new ol.style.Text({
            text: this.displayFeatureText(feature),
            textBaseline: "middle",
            overflow: true,
            scale: 1.5,
            fill: new ol.style.Fill({
              color: "#FFFFFFFF",
            }),
            stroke: new ol.style.Stroke({
              color: "#000000FF",
              width: 2,
              miterLimit: 100,
            }),
          }),
        }),
      ];
      t.possiblePaths.forEach((e, index) => {
        let colors = mapStyles.cut.borderCut.possiblePaths;
        st.push(
          new ol.style.Style({
            stroke: new ol.style.Stroke({
              color: colors[index],
              width: 4,
            }),
            geometry: function (feature) {
              var coordinates = JSON.parse(JSON.stringify(e.geometry.coordinates));
              return new ol.geom.LineString(coordinates);
            },
          }),
        );
      });
      return st;
    },
    getDrawStyle(feature, resolution) {
      var area = Math.round(ol.sphere.getArea(feature.getGeometry())) / 10000;
      var text = "";
      if (area > 0) {
        text = area.toFixed(2).toString() + " ha";
      }
      return [
        new ol.style.Style({
          fill: new ol.style.Fill({
            color: "rgba(255, 255, 255, 0.5)",
          }),
          stroke: new ol.style.Stroke({
            color: "rgb(0, 153, 255)",
            width: 3,
          }),
          image: new ol.style.Circle({
            radius: 7,
            fill: new ol.style.Fill({
              color: "rgb(0, 153, 255)",
            }),
            stroke: new ol.style.Stroke({
              color: "#FFFFFFFF",
              width: 1,
            }),
          }),
        }),
        new ol.style.Style({
          text: new ol.style.Text({
            text: text,
            overflow: true,
            scale: 1.5,
            fill: new ol.style.Fill({
              color: "#FFFFFFFF",
            }),
            stroke: new ol.style.Stroke({
              color: "#000000FF",
              width: 2,
              miterLimit: 100,
            }),
          }),
        }),
        new ol.style.Style({
          fill: new ol.style.Fill({
            color: "#FFFFFFFF",
          }),
          stroke: new ol.style.Stroke({
            color: "#FFFFFFFF",
            lineDash: [5, 5],
            width: 2,
          }),
          geometry: function (feature) {
            var coordinates = feature.getGeometry().getCoordinates();
            if (coordinates.length >= 3) {
              return new ol.geom.LineString([coordinates[0], coordinates[coordinates.length - 1]]);
            } else {
              return new ol.geom.LineString([]);
            }
          },
        }),
      ];
    },
    displayFeatureText(feature) {
      let properties = feature.getProperties();
      let zoom = this.map.getView().getZoom();

      // Si les variétés sont affichées, ne pas afficher les noms des parcelles
      if (this.layerFilters[4] && this.layerFilters[4].value){
        return;
      }

      if (
        this.minimal &&
        !(
          this.entriesChecked.includes(properties.id) ||
          (this.currentCropfield && this.currentCropfield.id == properties.id)
        )
      )
        return;

      let cropfield = this._entries[properties.id];

      if (!cropfield) return;

      let surface = cropfield.surface;
      if (this.currentEntry && this.currentEntry.id && this.currentEntry.id == properties.id) {
        surface = this.editedEntry.surface || surface;
      }

      //   if (this.mapCutMode == 1 && this.hasCut && this.currentEntry.id == cropfield.id) return "";

      let text = "";
      // Si l'objet customer a un attribut 'doViticulture' vrai, et pas d'attribut 'doArableFarming 'ni 'doArboriculture'
      // ne pas afficher le nom de la culture
      let displayCropName = !this.customer.doViticulture || this.customer.doArableFarming  || this.customer.doArboriculture
      if (displayCropName && cropfield.crop_name) text += cropfield.crop_name;

      if (zoom > mapStyles.normal.cropNameZoomLevel || this.fullScreenMode) {
        text = cropfield.name + (text != "" ? "\n" : "") + text;
        if (surface) text += "\n" + surface + " ha";
      }
      return text;
    },
    getCropfieldColor(feature) {
      const id = feature.getProperties().id;

      if (this.entriesChecked.includes(id) || (this.currentCropfield && this.currentCropfield.id == id)) {
        if (!this.displayActiveCropfield) return "#00000000";
        return mapStyles.active.backgroundColor;
      }

      const properties = feature.getProperties();
      const cropfield = this._entries[properties.id];

      // we had a transparent compound
      return cropfield.crop_color.slice(0, 7) + mapStyles.normal.alpha;
    },
    getCropfieldTextColor(feature) {
      const id = feature.getProperties().id;
      if (
        (this.entriesChecked.includes(id) || (this.currentCropfield && this.currentCropfield.id == id)) &&
        !this.displayActiveCropfield
      )
        return "#00000000";
      return "#FFFFFFFF";
    },
    getCropfieldTextBorderColor(feature) {
      const id = feature.getProperties().id;
      if (
        (this.entriesChecked.includes(id) || (this.currentCropfield && this.currentCropfield.id == id)) &&
        !this.displayActiveCropfield
      )
        return "#00000000";
      return "#000000FF";
    },
    getCropfieldBorderColor(feature) {
      const id = feature.getProperties().id;
      if (this.entriesChecked.includes(id) || (this.currentCropfield && this.currentCropfield.id == id)) {
        if (!this.displayActiveCropfield) return mapStyles.normal.edgeColor;
        return mapStyles.active.edgeColor;
      }

      if (this.minimal) return mapStyles.minimal.edgeColor;

      return mapStyles.normal.edgeColor;
    },

    /**
     * Style appliqué lorsqu'on dessine sur la carte à partir de la boîte à outil
     * @param {*} feature
     * @returns
     */
    getDrawingToolStyle(feature) {
      return [
        new ol.style.Style({
          stroke: new ol.style.Stroke({
            color: mapStyles.toolBox.edgeColor,
            lineDash: [10, 10],
            width: 2,
          }),
          image: new ol.style.Circle({
            radius: 7,
            fill: new ol.style.Fill({
              color: "rgb(0, 153, 255)",
            }),
            stroke: new ol.style.Stroke({
              color: mapStyles.toolBox.edgeColor,
              width: 1,
            }),
          }),
          fill: new ol.style.Fill({
            color: mapStyles.toolBox.backgroundColor,
          }),
        }),
        new ol.style.Style({
          text: new ol.style.Text({
            text: getAreaOrLength(feature.getGeometry()),
            overflow: true,
            scale: 1.3,
            fill: new ol.style.Fill({
              color: mapStyles.toolBox.edgeColor,
            }),
            stroke: new ol.style.Stroke({
              color: mapStyles.toolBox.textStrokeColor,
              width: 2,
              miterLimit: 100,
            }),
          }),
        })
      ];
    },

    /**
     * Style appliqué à la ligne ou au polygone représentant une distance ou une surface mesurée
     * @param {*} feature
     * @returns
     */
    getMeasuredLineStyle(feature) {
      return new ol.style.Style({
        stroke: new ol.style.Stroke({
          color: "#ffffff",
          width: 2
        }),
        text: new ol.style.Text({
          text: getAreaOrLength(feature.getGeometry()),
          overflow: true,
          scale: 1.5,
          fill: new ol.style.Fill({
            color: "#FFFFFFFF",
          }),
          stroke: new ol.style.Stroke({
            color: "#000000FF",
            width: 2,
            miterLimit: 100,
          }),
        }),
        fill: new ol.style.Fill({
          color: mapStyles.toolBox.backgroundColor,
        }),
      });
    },

  },
};
