import Gateway from "./base";

export default class MachineApi {
  /**
   * Get All machines
   *
   */
  static getMachines(params) {
    return Gateway.get("/api/machines/", params);
  }

  /**
   * Get one machine by id
   *
   * @param id
   */
  static getMachine(id, detail=false) {
    return Gateway.get(`/api/machines/${id}/`, {detail: detail});
  }

  /**
   * Get available beacons by machine id
   *
   * @param machineId
   */
  static getBeaconsAvailable(machineId) {
    return Gateway.get(`/api/machines/${machineId}/beaconsavailable/`);
  }

  /**
   * Get available trackers by machine id
   *
   * @param machineId
   */
  static getTrackersAvailable(machineId) {
    return Gateway.get(`/api/machines/${machineId}/trackersavailable/`);
  }

  /**
   * Get available beacons
   *
   */
  static getBeacons() {
    return Gateway.get(`/api/machines/beaconsavailable/`);
  }

  /**
   * Get available trackers
   *
   */
  static getTrackers() {
    return Gateway.get(`/api/machines/trackersavailable/`);
  }

  /**
   * Create machine
   *
   * @param data
   */
  static createMachine(data) {
    return Gateway.post(`/api/machines/`, data);
  }

  /**
   * Get All filters machines
   *
   */
  static getFiltersMachine(query) {
    return Gateway.get("/api/machines/filtre/", { attrib: query });
  }

  /**
   * Update machine
   *
   * @param data
   * @param id
   */
  static updateMachine(data, id) {
    return Gateway.patch(`/api/machines/${id}/`, data);
  }

  /**
   * delete machine
   *
   * @param id
   */
  static deleteMachine(id) {
    return Gateway.delete(`/api/machines/${id}/`);
  }

  /**
   * Get path
   *
   */
  static getPath({activity, machine, startDate, endDate}) {
    if(activity) return Gateway.get(`/api/activities/${activity}/course/`, {start_date: startDate, end_date: endDate})
    return Gateway.get(`/api/machines/${machine}/course/`, {start_date: startDate, end_date: endDate})
  }

  /**
   * Get machine maintenance form
   *
   * @param id
   */
  static getMachineMaintenanceForm(id) {
    return Gateway.get(`/api/machines/${id}/maintenanceForm/`);
  }

  /**
   * Get last machines position on the last 15 minutes
   * @returns
   */
  static getAllCurrentLocations() {
    return Gateway.get(`/api/machines/currentLocation/`);
  }

 /*
  * get a file containing a report on usages of machines, with several formats
  */
 static reportMachinesUsages(params) {
  const headers = {}

  if ( params.format ) {
    if(params.format === 'html') {
      headers['Accept'] = "text/" + params.format;
    }
    else
      headers['Accept'] = "application/" + params.format;
    delete params.format;
  }
  if ( params.pacage == 'all' ) {
    delete params.pacage;
  }
  if ( params.farm_id == "all" ) {
    delete params.farm_id;
  }
  if ( params.campaign == 'all' ) {
    delete params.campaign;
  }

  return Gateway.get(`/reports/machines/usages/`, params, headers, "arraybuffer");
}
}
