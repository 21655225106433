import { commonGetters, commonMutations, commonActions } from "./commons.js";

import MaintenanceApi from "@/api/maintenance.api";
import Vue from "vue";

export const ALL_MAINTENANCES = "GET ALL MAINTENANCES";
export const MAINTENANCES_BY_MACHINE = "GET MAINTENANCES BY MACHINE"
export const CREATE_MAINTENANCE = "CREATE MAINTENANCE";
export const UPDATE_MAINTENANCE = "UPDATE MAINTENANCE";
export const DELETE_MAINTENANCE = "DELETE MAINTENANCE";
export const MAINTENANCE_LOADING = "LOAD MAINTENANCE";

export default {
  namespaced: true,
  state: {
    _entries: {},
    currentEntry: {},
    newEntry: {},
    maintenances: [],
    maintenancesByMachine: [],
    newMaintenance: {},
    maintenanceUpdated: {},
    maintenances_loaded: true,
  },
  getters: {
    ...commonGetters(),
    maintenanceLoaded: state => state.maintenances_loaded,
    maintenances: state => state.maintenances,
    maintenancesByMachine: state => state.maintenancesByMachine,
    newMaintenance: state => state.newMaintenance,
  },
  mutations: {
    ...commonMutations(),
    [ALL_MAINTENANCES]: (state, maintenances) => {
      state.maintenances = maintenances;
    },

    [MAINTENANCES_BY_MACHINE]: (state, maintenances) => {
      state.maintenancesByMachine = maintenances.entries;
    },

    [CREATE_MAINTENANCE]: (state, payload) => {
      state.newMaintenance = payload.maintenance;
    },

    [UPDATE_MAINTENANCE]: (state, payload) => {
      state.maintenanceUpdated = payload.maintenance;
    },

    [DELETE_MAINTENANCE]: state => {
      state.currentEntry = {};
    },

    [MAINTENANCE_LOADING]: (state, status) => {
      state.maintenances_loaded = !status;
    },
  },
  actions: {
    ...commonActions(),
    async getAllMaintenances({ commit }) {
      commit(MAINTENANCE_LOADING, true);
      const response = await MaintenanceApi.getMaintenances();
      commit(ALL_MAINTENANCES, { entries: response.data });
      commit(MAINTENANCE_LOADING, false);
    },

    async getAllMaintenancesByMachine({ commit }, params) {
      commit(MAINTENANCE_LOADING, true);
      const response = await MaintenanceApi.getMaintenanceByMachine(params);
      commit(MAINTENANCES_BY_MACHINE, { entries: response.data });
      commit(MAINTENANCE_LOADING, false);
    },

    async createMaintenance({ commit }, payload) {
      commit(MAINTENANCE_LOADING, true);
      const response = await MaintenanceApi.createMaintenance(payload.data);
      commit(CREATE_MAINTENANCE, { maintenance: response.data });
      commit(MAINTENANCE_LOADING, false);
      return response.data;
    },

    async updateMaintenance({ commit }, payload) {
      commit(MAINTENANCE_LOADING, true);
      const response = await MaintenanceApi.updateMaintenance(payload.data, payload.id);
      commit(UPDATE_MAINTENANCE, { maintenance: response.data });
      commit(MAINTENANCE_LOADING, false);
      return response.data;
    },

    async deleteMaintenance({ commit }, payload) {
      commit(MAINTENANCE_LOADING, true);
      const response = await MaintenanceApi.deleteMaintenance(payload.id);
      commit(DELETE_MAINTENANCE);
      commit(MAINTENANCE_LOADING, false);
      return response;
    },

  },
};
