<template>
    <div>
      <b-row>
        <!-- Date  -->
        <b-col class="col-6 pl-2 mb-1 flex">
          <h4 class="subtitle-form-private my-2">{{$t('cropfields.fertilization.fertilizationExecution.date')}}</h4>
          <p class="text-form">{{ formatDate(input.date, "date", "/") }}</p>
        </b-col>
      </b-row>
      <b-row>
        <!-- Produit  -->
        <b-col class="col-7 pl-2">
          <h4 class="subtitle-form-private mb-2">{{$t('cropfields.fertilization.fertilizationExecution.engrais')}}</h4>
          <p class="text-infos bg-white">{{ input.inputsStock.name }}</p>
        </b-col>
        <!-- Quantité  -->
        <b-col class="col-5 pl-0">
          <h4 class="subtitle-form-private mb-2">{{$t('cropfields.fertilization.fertilizationExecution.dose')}}</h4>
          <p class="text-infos bg-white">{{ input.dose }} {{ input.doseUnit_unit }}</p>
        </b-col>
      </b-row>
      <b-row>
        <!-- Surface  -->
        <b-col class="col-7 pl-2">
          <h4 class="subtitle-form-private mb-2">{{$t('cropfields.fertilization.fertilizationExecution.surface')}}</h4>
          <p class="text-infos bg-white">{{ input.activitySurface }} ha</p>
        </b-col>
        <!-- Azote apporté  -->
        <b-col class="col-5 pl-0">
          <h4 class="subtitle-form-private mb-2">{{$t('cropfields.fertilization.fertilizationExecution.providedN')}}</h4>
          <p class="text-infos bg-white">{{ input.n_bilan || 0 }} kg/ha</p>
        </b-col>
      </b-row>
      <b-row></b-row>
    </div>
  </template>

  <script>
  import { mapGetters } from "vuex";
  import {formatDate} from "@/utils/commons";

  export default {
    name: "Fertilization-Exexution",
    data() {
      return {
      };
    },
    components: {
    },
    created() {
    },
    methods: {
      // imported methods should be registered to be able to use in template section
      formatDate,
    },
    props: ['input'],
    computed: {
      ...mapGetters({
        cropField: "cropfield/currentEntry",
      }),
    },
  };
  </script>

  <style lang="scss" scoped>
  /**
   * text form.
   */
  .text-form {
    font-size: 12px;
    margin: 5px 0 5px;
    padding: 0;
    font-family: "Roboto", sans-serif;
  }
  /**
   * resize surface component.
   */
  .resize-surface {
    padding-left: 25%;
  }
  /**
   * Display items on one line.
   */
  .flex {
    display: flex;
    gap: 8px;
  }

  .subtitle-form-private {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 2px !important;
    padding: 0;
    color: $subtitle-color;
    font-family: "Roboto", sans-serif;
  }
  </style>