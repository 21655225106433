<template>
  <div class="d-flex flex-column justify-content-center">
    <div v-if="name" class="d-block font-12" style="font-weight: normal">
      {{ name }}
    </div>
    <div v-else>
      <div class="d-block font-12" style="font-weight: normal">
        {{ firstInputs(2) }}
      </div>
      <span class="d-inline-block font-12" v-if="farms && farms.length > 2">
        <b-button :id="'popover-farms-' + rowId" type="button" class="badge badge-pill badge-secondary">
          <span>+{{ farms.length - 2 }}</span>
        </b-button>
      </span>

      <b-popover :target="'popover-farms-' + rowId" triggers="hover" placement="auto" boundary="window">
        <div v-for="farm in farms" :key="farm.id">
          {{ farm.name }}
        </div>
      </b-popover>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: ["rowId", "rowData"],
  computed: {
    farms() {
      return this.rowData?.farms || [];
    },
    name() {
      return this.rowData?.name;
    }
  },
  methods: {
    firstInputs(n) {
      if (!this.farms) return "";
      return this.farms
        .slice(0, n)
        .map((i) => i.name)
        .join(", ");
    },
  },
};
</script>

<style lang="scss" scoped></style>
