import inputInventoryApi from "@/api/inputInventory.api";

export const ALL_INPUTINVENTORIES = "GET ALL INPUT INVENTORIES";
export const ONE_INPUTINVENTORY = "GET INPUT INVENTORY BY ID";
export const GET_INPUTINVENTORY_BY_INPUTSTOCK = "GET INPUT INVENTORY BY INPUTSTOCK ID";
export const CREATE_INPUTINVENTORY = "CREATE INPUT INVENTORY";
export const UPDATE_INPUTINVENTORY = "UPDATE INPUT INVENTORY";
export const DELETE_INPUTINVENTORY = "DELETE INPUT INVENTORY";
export const INPUTINVENTORY_LOADING = "LOAD INPUT INVENTORY";

export default {
  state: {
    inputInventories: [],
    currentInputInventory: {},
    newInputInventory: {},
    inputInventoryUpdated: {},
    inputInventory_loaded: true,
  },
  getters: {
    inputInventories: state => state.inputInventories,
    currentInputInventory: state => state.currentInputInventory,
  },
  mutations: {
    [ALL_INPUTINVENTORIES]: (state, inputInventories) => {
      state.inputInventories = inputInventories;
    },

    [ONE_INPUTINVENTORY]: (state, payload) => {
      state.currentInputInventory = payload.inputInventory;
    },

    [GET_INPUTINVENTORY_BY_INPUTSTOCK]: (state, payload) => {
      state.inputInventories = payload;
    },

    [CREATE_INPUTINVENTORY]: (state, payload) => {
      state.newInputInventory = payload.inputInventory;
    },

    [UPDATE_INPUTINVENTORY]: (state, payload) => {
      state.inputInventoryUpdated = payload.inputInventory;
    },

    [DELETE_INPUTINVENTORY]: (state) => {
      state.currentInputInventory = {}
    },

    [INPUTINVENTORY_LOADING]: (state, status) => {
      state.inputInventory_loaded = !status;
    },
  },
  actions: {
    async getAllInputInventories({ commit }) {
      commit(INPUTINVENTORY_LOADING, true);
      const response = await inputInventoryApi.getAllInputInventories();
      commit(ALL_INPUTINVENTORIES, response.data);
      commit(INPUTINVENTORY_LOADING, false);
      return response.data;
    },

    async getOneInputInventory({ commit }, payload) {
      commit(INPUTINVENTORY_LOADING, true);
      const response = await inputInventoryApi.getInputInventory(payload.id);
      commit(ONE_INPUTINVENTORY, { inputInventory: response.data });
      commit(INPUTINVENTORY_LOADING, false);
      return response.data;
    },

    async getInputInventoriesByInputStock({ commit }, payload) {
      commit(INPUTINVENTORY_LOADING, true);
      const response = await inputInventoryApi.getInputInventoriesByInputStock(payload);
      commit(GET_INPUTINVENTORY_BY_INPUTSTOCK, response.data);
      commit(INPUTINVENTORY_LOADING, false);
      return response.data;
    },

    async createInputInventory({ commit }, payload) {
      commit(INPUTINVENTORY_LOADING, true);
      const response = await inputInventoryApi.createInputInventory(payload.data);
      commit(CREATE_INPUTINVENTORY, { inputInventory: response.data });
      commit(INPUTINVENTORY_LOADING, false);
      return response.data;
    },
    async deleteInputInventory({ commit }, payload) {
      commit(INPUTINVENTORY_LOADING, true);
      const response = await inputInventoryApi.deleteInputInventory(payload.id);
      commit(DELETE_INPUTINVENTORY);
      commit(INPUTINVENTORY_LOADING, false);
      return response;
    },
    async updateInputInventory({ commit }, payload) {
      commit(INPUTINVENTORY_LOADING, true);
      const response = await inputInventoryApi.updateInputInventory(payload.data, payload.id);
      commit(UPDATE_INPUTINVENTORY, { inputInventory: response.data });
      commit(INPUTINVENTORY_LOADING, false);
      return response.data;
    },
  },
};
