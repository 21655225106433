import Gateway, { Request } from "./base";

export default class CropfieldApi {
  /**
   * Get All cropfields
   */
  static getCropfields(params) {
    return Gateway.get("/api/cropfields/", params);
  }

  /**
   * Get All cropfields
   */
  static getAllCropfields() {
    return Gateway.get("/api/cropfields/");
  }

  /**
   * Get filters
   */
  static getFilters(query, params) {
    return Gateway.get("/api/cropfields/filtre/", { attrib: query, ...params });
  }

  /**
   * Get one cropfield by id
   *
   * @param id
   *
   */
  static getCropfield(id) {
    return Gateway.get(`/api/cropfields/${id}/`);
  }

  /**
   * Get culturalProcess by id
   *
   * @param id
   *
   */
  static getCulturalProcess(id) {
    console.log("process", id)
    return Gateway.get(`/api/cropfields/${id}/culturalProcess/`);
  }

  /**
   * Update cultural process
   *
   * @param data
   * @param id
   */
  static updateCulturalProcess(data, id) {
    return Gateway.patch(`/api/cropfields/${id}/culturalProcess/`, data);
  }

  /**
   * Create cropfield
   *
   * @param data
   *
   */
  static createCropfield(data) {
    return Gateway.post(`/api/cropfields/`, data);
  }

  /**
   * Update cropfield
   *
   * @param data
   * @param id
   *
   */
  static updateCropfield(id, data) {
    return Gateway.patch(`/api/cropfields/${id}/`, data);
  }

  /**
   * Merge one cropfield with one or more others
   *
   * @param data
   * @param id
   *
   */
  static mergeCropfield(id, data) {
    return Gateway.patch(`/api/cropfields/${id}/merge/`, data);
  }

  /**
   * delete cropfield
   *
   * @param id
   *
   */
  static deleteCropfield(id) {
    return Gateway.delete(`/api/cropfields/${id}/`);
  }

  /**
   * Get all islands
   *
   *
   */
  static getIslands() {
    return Gateway.get(`/api/islands/`);
  }

  /**
   * Get island
   *
   * @param id
   *
   */
  static getIsland(id) {
    return Gateway.get(`/api/islands/${id}/`);
  }

  /**
   * Get all varieties
   *
   *
   */
  static getVarieties(params) {
    return Gateway.get(`/api/cropfieldVarieties/`, params);
  }

  /**
   * Get fertilzation values for fertilzers brings before plan
   * @param {*} params : {assessmentOpeningDate: '2022-02-15'}
   * @returns {*} {"n_total": 10.2, "n_valuable": 0}
   */
  static getBeforePlanNitrogenSummary(id, params) {
    return Gateway.get(`/api/cropfields/${id}/beforePlanNitrogenSummary/`, params);
  }

  static duplicateFertilizationPlan(id, params) {
    return Gateway.get(`/api/cropfields/${id}/duplicateFertilizationPlan/`, params);
  }

  /**
   * Duplicate the cropfield in order to assign a new crop
   * @param {int} id
   * @returns
   */
  static defineNewCrop(id) {
    return Gateway.get(`/api/cropfields/${id}/defineNewCrop/`);
  }

  /**
   * Get vector RPG data
   */
  static getRPG(source, bbox, projection = "EPSG:4326", count = 10) {
    return Request.get("https://data.geopf.fr/wfs/ows", {
      SERVICE: "WFS",
      VERSION: "2.0.0",
      REQUEST: "GetFeature",
      outputFormat: "application/json",
      typeNames: source,
      bbox: bbox.join(","),
      CRS: projection,
    });
  }

  /**
   * Get vector cadastral parcels
   */
  static getCadastralParcels(source, bbox, projection = "EPSG:4326", count = 10) {
    return Request.get("https://data.geopf.fr/wfs/ows", {
      SERVICE: "WFS",
      VERSION: "2.0.0",
      REQUEST: "GetFeature",
      outputFormat: "application/json",
      typeNames: source,
      bbox: bbox.join(","),
      CRS: projection,
    })
  }

  /**
   * Get vector water course
   */
  static getWaterCourse(bbox, projection = "EPSG:2154") {
    return Request.get("https://data.geopf.fr/wfs/ows", {
      SERVICE: "WFS",
      VERSION: "2.0.0",
      REQUEST: "GetFeature",
      outputFormat: "application/json",
      typeNames: "HYDROGRAPHY.BCAE.LATEST:bcae_cours_eau",
      bbox: bbox.join(","),
      CRS: projection,
    });
  }

  /**
   * List of capabilities from IGN
   * used for example to get all RPG sources
   */
  static getCapabilities() {
    return Request.get(
      "https://data.geopf.fr/wfs/ows?SERVICE=WFS&VERSION=2.0.0&REQUEST=GetCapabilities",
      {},
      { "Content-Type": "text/html,application/xhtml+xml,application/xml" },
      "xml",
    );
  }

  /**
   * Get products by id
   *
   * @param id
   *
   */
  static getProductsByCropfield(id) {
    return Gateway.get(`/api/cropfields/${id}/varieties/`);
  }
  /**
   * Get the url of smag login page
   */
  static getSmagLoginUrl(application_id) {
    if (application_id)
      return Request.post(
        "https://api.smag.tech/authentication-operational/v1/api/authentication/build_url",
        {
          AdditionalValues: {
            application_id: application_id,
            displaySignUp: "0",
            displayLogin: "1",
            displayInfo: "0",
          },
        },
        {
          "Ocp-apim-subscription-key": "5f1731884bb14e7faaa5863dd52a64f2",
        },
      );
    return "";
  }
  /**
   * Create a cropfield variety
   * @param {*} data
   * @returns
   */
  static createCropfieldVariety(data) {
    return Gateway.post(`/api/cropfieldVarieties/`, data);
  }
  /**
   * delete a cropfield variety
   *
   * @param id
   *
   */
  static deleteCropfieldVariety(id) {
    return Gateway.delete(`/api/cropfieldVarieties/${id}/`);
  }

  /**
   * Get all soil types by the cropfield or farm
   * 
   * @param territory
   * @param postalCode
   * @returns
   */
  static getSoilTypes(territory, postalCode) {
    return Gateway.get(`/refapi/soilTypes/`, { territory, postalCode });
  }

  /**
   * Get all soil depths
   * @returns
   */
  static getSoilDepths() {
    return Gateway.get(`/refapi/soilDepths/`);
  }

  /*
   * get a file containing a export of cropFields
   */
  static exportCropFields(params) {
    const headers = {};

    console.log("Appel API exportCropFields");

    if (params.format !== undefined) {
      console.log("format defini");
      headers["Accept"] = "application/" + params.format;
      delete params.format;
    }
    if (params.pacage == "all") {
      delete params.pacage;
    }
    if (params.farm_id == "all") {
      delete params.farm_id;
    }
    if (params.campaign == "all") {
      delete params.campaign;
    }
    if (params.cropfields !== undefined) {
      params.id = params.cropfields;
      delete params.cropfields;
    }
    return Gateway.get(`/export/cropfields/`, params, headers, "arraybuffer");
  }

  /*
   * get a file containing a report input apport summary, with several formats
   */
  static reportFertilizerInputsSummary(params) {
    const headers = {};

    if (params.format) {
      if (params.format === "html") {
        headers["Accept"] = "text/" + params.format;
      } else headers["Accept"] = "application/" + params.format;
      delete params.format;
    }
    if (params.pacage == "all") {
      delete params.pacage;
    }
    if (params.farm_id == "all") {
      delete params.farm_id;
    }

    return Gateway.get(`/reports/fertilization/summary/`, params, headers, "arraybuffer");
  }

  /**
   * Get a file containing the plain report
   * @param {*} params
   * @returns
   */
  static reportPlain(params) {
    const headers = {};

    if (params.format) {
      if (params.format === "html") {
        headers["Accept"] = "text/" + params.format;
      } else headers["Accept"] = "application/" + params.format;
      delete params.format;
    }
    if (params.pacage == "all") {
      delete params.pacage;
    }
    if (params.farm_id == "all") {
      delete params.farm_id;
    }
    if (params.activityFamily == "all") {
      delete params.activityFamily;
    }

    return Gateway.get(`/reports/cropfieldpdf/`, params, headers, "arraybuffer");
  }
    /*
   * get a file containing a report with crop rotations
   */
    static reportCropRotation(params) {
      const headers = {};

      if (params.format) {
        if (params.format === "html") {
          headers["Accept"] = "text/" + params.format;
        } else headers["Accept"] = "application/" + params.format;
        delete params.format;
      }
      if (params.pacage == "all") {
        delete params.pacage;
      }
      if (params.farm_id == "all") {
        delete params.farm_id;
      }

      return Gateway.get(`/reports/cropfields/crop_rotation/`, params, headers, "arraybuffer");
    }

  /*
   * get a file containing a report with IFT
   */
  static reportIFT(params) {
    const headers = {};

    if (params.format) {
      if (params.format === "html") {
        headers["Accept"] = "text/" + params.format;
      } else headers["Accept"] = "application/" + params.format;
      delete params.format;
    }
    if (params.pacage == "all") {
      delete params.pacage;
    }
    if (params.farm_id == "all") {
      delete params.farm_id;
    }

    return Gateway.get(`/reports/cropfields/ift/`, params, headers, "arraybuffer");
  }
}
