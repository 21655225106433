<template>
  <!-- MCT = Map Cut Tool -->
  <!-- this is the view when we define a geometry on the map for a crofield -->
  <div>
    <!-- Button "Define geometry" -->
    <b-button
      class="button-default text-left mb-3 justify-content-start"
      @click="startDrawMode"
      :class="{ active: modifyMode }"
    >
      {{ $t("cropfields.details.define_geometry") }}
    </b-button>

    <!-- Button "Select from RPG" -->
    <b-button
      class="button-default text-left mb-3 justify-content-start"
      @click="startRPGMode"
      :class="{ active: modifyMode }"
    >
      {{ $t("cropfields.details.select_geometry_rpg") }}
    </b-button>

    <!-- Button "Select from cadastral cropfields" -->
    <b-button
      class="button-default text-left mb-3 justify-content-start"
      @click="startCadastralMode"
      :class="{ active: modifyMode }"
    >
      {{ $t("cropfields.details.select_geometry_cadastral") }}
    </b-button>

    <!-- Button "Select from local cropfields" -->
    <b-button
      class="button-default text-left mb-3 justify-content-start"
      @click="startLocalMode"
      :class="{ active: modifyMode }"
    >
      {{ $t("cropfields.details.select_geometry_on_campaign") }}
    </b-button>

    <!-- Guide text -->
    <p v-if="mode" class="bg-white text-infos">
      <span v-if="mode == DRAW_MODE">{{ $t("cropfields.details.define_geometry_guide") }}</span>
      <span v-if="mode == RPG_MODE">{{ $t("cropfields.details.select_rpg_guide") }}</span>
      <span v-if="[LOCAL_MODE, CADASTRAL_MODE].includes(mode)">{{ $t("cropfields.details.select_local_cropfield") }}</span>
    </p>

    <!-- Multiple sélection guide text -->
    <p v-if="[RPG_MODE, LOCAL_MODE, CADASTRAL_MODE].includes(mode) && !mergeError" class="bg-white text-infos">
      <span>{{ $t("cropfields.details.select_multiple_cropfield") }}</span>
    </p>

    <!-- Merge error text -->
    <p v-if="mergeError" class="bg-white text-infos text-danger">
      <span> {{ $t("cropfields.details.merge_not_allowed") }}</span>
    </p>

    <!-- List of RPG dates -->
    <b-row v-if="mode == RPG_MODE" class="mb-3">
      <b-col>
        <h4 class="subtitle-form mb-2 text-nowrap">{{ $t("cropfields.details.rpg_year") }}</h4>
        <b-form-select v-model="currentRpgOption" :options="rpgCapabilities" @input="showRPG('rpg')"></b-form-select>
      </b-col>
    </b-row>

    <!-- Name and surface form, shown when the goemetry has been drawn -->
    <MCT_DefineGeometry_Form v-if="showForm" />
  </div>
</template>

<script>
import { EventBus } from "@/eventBus.js";

import MapCutToolsMixin from "./MapCutToolsMixin";

import MCT_DefineGeometry_Form from "./define-geometry/MCT_DefineGeometry_Form.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
      RPG_MODE: "rpg_mode",
      DRAW_MODE: "draw_mode",
      LOCAL_MODE: "local_mode",
      CADASTRAL_MODE: "cadastral_mode",
      showForm: false,
      mode: null,
      currentRpgOption: null,
      cropfieldIdToDelete: [],
      mergeError: false
    };
  },
  mixins: [MapCutToolsMixin],
  components: { MCT_DefineGeometry_Form },
  props: {
    modifyMode: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    EventBus.$on("geometryDrawEnded", this.submitGeometry);

    EventBus.$on("selectRPGFeature", this.submitGeometry);

    this.fetchRPGCapabilities();
  },
  methods: {
    ...mapMutations({
      resetEditedEntry: "cropfield/resetEditedEntry",
    }),
    ...mapActions({
      setEditedEntryValue: "cropfield/setEditedEntryValue",
      saveEditedEntry: "cropfield/saveEditedEntry",
      fetchRPGCapabilities: "cropfield/fetchRPGCapabilities",
      deleteEntry: "cropfield/deleteEntry",
    }),
    /**
     * Save
     */
    async save() {
      // disable modify mode
      this.$emit("update:modifyMode", false);
      // disable modify interaction on map
      EventBus.$emit("disableModifyGeometry", { save: false });

      const farmId = this.editedEntry.farm

      // save geometry, name & surface
      for(let i=0; i < this.cropfieldIdToDelete.length; i++) {
        if (this.cropfieldIdToDelete[i]) await this.deleteEntry({id: this.cropfieldIdToDelete[i]});
      }
      await this.saveEditedEntry();

      // plot the geometry
      EventBus.$emit("plotFields", false);

      EventBus.$emit("disableRPG");

      if(this.currentFarm?.id && this.currentFarm?.id !== farmId) {
        // if the farm is different than the current one
        // redirect to map homepage
        this.$router.push({
          name: "cropfield.map",
        });
      }
      else {
        // else, redirect to edit geometry page
        this.$router.push({
          name: "cropfield.map.details",
          params: { cropfield_id: this.currentEntry.id, mode: "editGeometry" },
        });
      }
    },
    /**
     * Reset
     */
    reset() {
      // disable modify mode
      this.$emit("update:modifyMode", false);
      // disable modify interaction on map
      EventBus.$emit("disableModifyGeometry", { save: false });

      // hide the form
      this.showForm = false;

      EventBus.$emit("disableRPG");

      // reset form data in store
      this.resetEditedEntry();
    },
    /**
     * Pick geometry from vector layer
     */
    async startRPGMode() {
      this.mode = this.RPG_MODE;
      if (!this.currentRpgOption) this.currentRpgOption = this.lastRPGOption.value;

      this.showRPG('rpg');
    },
    showRPG(source = 'rpg') {
      EventBus.$emit("showRPG",  {rpgName: this.currentRpgOption, source: source});
    },
    /**
     * Pick geometry from cropfields of current campaign
     */
    async startLocalMode() {
      this.mode = this.LOCAL_MODE;
      this.showRPG('local');
    },

    /**
     * Pick geometry from cadastral cropfields
     */
    async startCadastralMode() {
      this.mode = this.CADASTRAL_MODE;
      if (!this.currentRpgOption) this.currentRpgOption = this.lastRPGOption.value;

      this.showRPG('cadastral');
    },

    /**
     * Activates freehand drawing
     */
    startDrawMode() {
      this.mode = this.DRAW_MODE;
      this.modifyGeometry();
    },

    submitGeometry({ geometry, surface, id=[], mergeError }) {
      this.setEditedEntryValue({
        surface: surface,
        geometry: geometry,
      });
      this.cropfieldIdToDelete = id;
      this.mergeError = mergeError;
      if(!geometry && !surface) {
        this.showForm = false;
        this.$emit("update:modifyMode", false);
        return;
      }
      this.$emit("update:modifyMode", true);
      this.showForm = true;

      // if we are on rpg mode, set the "receive in exchange" value to false in form
      if (this.mode == this.RPG_MODE && this.$userCan('cropfields.givenInExchangeByDefaultForRPG'))
        this.$nextTick(() => {
          EventBus.$emit("setReceivedInExchangeCheckboxValue", true);
        });
    },
  },
  computed: {
    ...mapGetters({
      currentFarm: "farm/currentFarm",
      currentEntry: "cropfield/currentEntry",
      editedEntry: "cropfield/editedEntry",
      rpgCapabilities: "cropfield/rpgCapabilities",
    }),
    lastRPGOption() {
      return this.rpgCapabilities[this.rpgCapabilities.length - 1];
    },
  },
};
</script>

<style lang="scss" scoped></style>
