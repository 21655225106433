<template>
  <div>
    <div class="input-group">
      <table>
        <tr v-b-tooltip.hover="translatedCategories">
          <td>
            <div class="input-group-prepend search img-research">
              <img :src="require('@/assets/images/filter-search.svg')" alt="filter search" />
            </div>
          </td>

          <td>
            <div class="search-container" style="position: relative;">
              <label>
                <input
                  type="text"
                  class="form-control placeholder-policy"
                  v-model="searchQuery"
                  :placeholder="$t('global_filters.search')"
                  :disabled="!entryLoaded"
                  style="padding-right: 25px;"
                />
              </label>
              <span
                v-if="searchQuery"
                @click="resetSearch"
                class="reset-search"
              >
                &times;
              </span>
            </div>
          </td>

        </tr>
      </table>
    </div>
    <hr />
    <div class="d-flex justify-content-between align-items-start">
      <h3 class="mb-2">
        <span v-show="entryLoaded">Filtrer</span>
        <span v-show="!entryLoaded">Veuillez patienter ...</span>
      </h3>
      <button class="btn btn-clear p-0" @click="resetFilterSelection">Effacer</button>
    </div>

    <div class="mb-4" v-for="filter in filters" :key="filter.name">
      <div v-if="filterShown(filter.name)">
        <h4>{{ filter.title }}</h4>
        <div :ref="filter.name" class="filter-limit" :class="{ open: showFilters[filter.name] }">
          <div class="custom-control custom-checkbox green-variant" v-for="(data, i) in filterData[filter.name]" :key="data.id">
            <input
              type="checkbox"
              :checked="filterSelection[filter.name] === data.id"
              class="custom-control-input"
              :disabled="!entryLoaded"
              :id="'filter_' + filter.name + '_' + data.id"
              @change="filterChecked(filter.name, data.id)"
            />
            <label class="custom-control-label" :for="'filter_' + filter.name + '_' + data.id">{{
              data.value | empty("Non renseigné")
            }}</label>
          </div>
        </div>
        <button
          v-show="showFilters && filterData[filter.name] && filterData[filter.name].length > 6"
          class="btn btn-clear"
          @click="showFilters[filter.name] = !showFilters[filter.name]"
        >
          <div v-if="!showFilters[filter.name]">
            <img :src="require('@/assets/images/plus-sign-to-add.svg')" alt="icon more action" />Voir plus
          </div>
          <div v-if="showFilters[filter.name]">
            <img :src="require('@/assets/images/negative-sign.svg')" alt="icon more action" />Voir moins
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { SET_INPUT_RESEARCHED, INPUTSTOCK_LOADING } from "@/store/modules/inputstock";
import { EventBus } from "@/eventBus.js";

export default {
  data() {
    return {
      showFilters: {},
      typingTimeout: null,
    };
  },
  async mounted() {
    // initialize filters
    setTimeout(() => {
      this.showFilters = Object.assign({}, ...this.filters.map((f) => ({ [f.name]: false })));
      this.$store.commit(this.module + "/setFilterConfig", this.filters);
      this.initFilters();
    }, 500);
  },
  methods: {
    fetch() {
      return this.$store.dispatch(this.module + "/fetchEntries", {withGeometries: true, fertilizationplan: this.onFertilizationPlan});
    },
    loading(value) {
      return this.$store.commit(this.module + "/setEntryLoaded", value);
    },
    updateFilterSelection(selection) {
      return this.$store.dispatch(this.module + "/updateFilterSelection", selection);
    },
    async resetFilterSelection() {
      await this.loading(true);

      this.searchQuery = "";

      await this.$store.dispatch(this.module + "/resetFilterSelection");

      await this.loading(false);
    },
    initFilters() {
      this.$store.dispatch(this.module + "/initFilters");
    },
    /**
     * Description: Request inputStocks API with a filter and check or uncheck a filter.
     * @param {Number}       filterName filters index.
     * @param {Object}       filterElement filterData object.
     */
    async filterChecked(filterName, elementId) {
      await this.loading(true);

      await this.updateFilterSelection({ filterName, elementId });

      await this.fetch();

      EventBus.$emit("plotFields");
      EventBus.$emit("plotLocationFields");

      await this.loading(false);
    },
    resetSearch(){
      clearTimeout(this.typingTimeout);
      this.searchQuery = "";
      this.$store.dispatch(this.module + "/fetchEntries", {searchQuery: "", page: 0 });
    },
    delayedFetch(research) {
      clearTimeout(this.typingTimeout);

      if (research.length > 2) {
        this.typingTimeout = setTimeout(() => {
          this.$store.dispatch(this.module + "/fetchEntries", { searchQuery: research, page: 0 });
        }, 300);
      } else if (research.length === 0) {
        this.$store.dispatch(this.module + "/fetchEntries", { searchQuery: "", page: 0 });
      }
    },
    filterShown(name) {
      const filter = this.filters.find(f => f.name === name);
      // Vérifier si une condition est définie et l'exécuter si c'est une fonction
      if (!filter.condition || (typeof filter.condition === "function" && filter.condition({
        $userCan: this.$userCan,
        store: this.$store
      }))) {
        return true;
      }

      return false;
    },
  },
  props: {
    module: {
      type: String,
      default: "",
    },
    filters: {
      type: Array,
      default: () => [],
    },
    filterConfig: {
      type: Object,
      default: () => {},
    },
    searchField: {
      type: String,
      default: "",
    },
    searchApi: {
      type: Boolean,
      default: false,
    },
    searchList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      currentFarm: "farm/currentFarm",
      onFertilizationPlan: "cropfield/onFertilizationPlan",
    }),
    searchQuery: {
      get() {
        return this.$store.getters[this.module + "/searchQuery"];
      },
      set(research) {
        if (this.searchApi) {
          this.delayedFetch(research);
        }
        this.$store.commit(this.module + "/setSearchQuery", research);
      },
    },
    filterSelection() {
      return this.$store.getters[this.module + "/filterSelection"];
    },
    entryLoaded() {
      return this.$store.getters[this.module + "/entryLoaded"];
    },
    filterData() {
      return this.$store.getters[this.module + "/filterData"];
    },
    entries() {
      return this.$store.getters[this.module + "/entries"];
    },
    categoryToShow() {
      // Extraire les champs du searchField
      const fields = this.searchField.split(',').map(item => item.trim());
      const listObjects = this.searchList.map(item => item.object);

      return [...fields, ...listObjects];
    },
    translationModule() {
      if (this.module === "activity") return "activities";
      return null;
    },
    translationKey() {
      return (suffix) => `${this.translationModule}.filter.category.${suffix}`;
    },
    translatedCategories() {
      if (this.searchApi) {
        const baseText = this.$t("global_filters.tooltip");
        const translatedCategories = this.categoryToShow
          .map(category => this.$t(this.translationKey(category)))
          .join(', ');

        return baseText + translatedCategories;
      }
      return '';
    }
  },
  watch: {
    async currentFarm(value) {
      // special case, if the global filters current farm changes
      // and we selected all the farms (value = null)
      // then we uncheck the farmId filter
      if(this.module == "cropfield" && !value) {
        this.filterChecked("farm", this.filterSelection["farmId"])
      }
    },
  }
};
</script>

<style lang="scss" scoped>
label {
  margin-bottom: 0;
}
.input-group img {
  background-color: $background-color-light;
}

.filter-limit {
  max-height: 145px;
  overflow: hidden;
  transition: height 0.2s ease;

  &.open {
    max-height: none;
    overflow: auto;
  }
}

.btn-clear {
  font-size: 0.7em;
  color: $grey;

  img {
    width: 0.8em;
    margin-right: 5px;
    vertical-align: initial;
  }
}
h4 {
  color: $grey;
  margin-bottom: 10px;
}

.custom-control {
  font-size: 0.7em;
  line-height: 2;
}

.reset-search {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 16px;
  color: gray;
}

.product-select {
  width: 100%;
  height: 100%;
  padding: 0 0 4px;
  font-size: 0.9rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  border: 1px solid #ccc;
  background-color: #fff;
}
</style>
