import { i18n } from "@/i18n.js";
import { t, comp, choice, container, storeGet, storeDispatch, storeCommit } from "@/views/common/details/commons";

export const harvestStorageDetail = ({ entry }) => {
  console.log("entry ", entry);
  return {
    title: entry.cropField_name,
    subtitle: entry.crop_name,
    // actions: {
    //   delete: ({ entry }) => storeDispatch("harvestStorage/deleteEntry", { id: entry.id }),
    // },
    rows: [
      comp({
        component: "FieldInfo",
        title: i18n.t("harvestStorage.list.warehouse"),
        props: { value: entry.warehouse_name, empty: i18n.t("harvestStorage.details.no_warehouse_listed")},
      }),
      comp({
        component: "FieldInfo",
        title: i18n.t("harvestStorage.list.product"),
        props: { value: entry.variety_name, empty: i18n.t("harvestStorage.details.no_product_listed") },
        cols: 6
      }),
      comp({
        component: "FieldInfo",
        title: i18n.t("harvestStorage.list.quantity"),
        props: { value: entry.quantity, unit: entry.unit },
        cols: 6
      }),
      comp({
        component: "DateInfo",
        props: { value: entry.doneDate, empty: i18n.t("harvestStorage.details.no_date_listed") },
      }),
      comp({
        component: "MachinesByActivity",
        title: i18n.t("harvestStorage.details.equipment"),
        props: {activity_id: entry.activity},
      }),
      
    ],
  };
};

export const harvestStorageEdit = ({ entry, title }) => {
  return {
    title: i18n.t(title) || entry.cropField_name,
    subtitle: entry.crop_name,
    actions: {
      save: () => storeDispatch("harvestStorage/updateEditedEntry"),
      delete: ({ entry }) => storeDispatch("harvestStorage/deleteEntry", { id: entry.id }),
    },
    rows: [
      comp("CropfieldSelect"),
      comp("WarehouseSelect"),
      comp("ProductSelect"),
      comp({
        component: "EditQuantity",
        cols: 6,
      }),
      comp({
        component: "EditUnit",
        cols: 6,
      }),
      comp({
        component: "EditHarvestDate",
        title: i18n.t("harvestStorage.edit.date_and_hour"),
      })
    ],
  };
};

export const harvestStorageNew = ({ entry, editedEntry }) => {
  storeCommit("harvestStorage/resetEditedEntry");
  storeCommit("harvestStorage/resetCurrentId");
  const title = "harvestStorage.details.create_new_harvest";
  return harvestStorageEdit({ entry, editedEntry, title });
};
