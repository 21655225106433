import { i18n } from "@/i18n.js";

import { FAMILY_CREATE_PRODUCT } from "./config";
import { t, comp, choice, container, storeGet, storeDispatch, storeCommit } from "@/views/common/details/commons";

const storeEditTempAction = "inputStock/setEditedEntryValue";

const canShowComponent = (element, familyCode) => {
  let familyProduct = FAMILY_CREATE_PRODUCT[familyCode];
  return familyProduct && familyProduct[element];
};

const productInfoRows = (entry) => {
  storeCommit("resetProductInfo")
  const canShow = (element) => canShowComponent(element, entry.productFamily_code);

  return [
    // stock store
    comp({
      component: "FieldInfo",
      title: i18n.t("inputStock.details.stock_store"),
      props: { value: entry.stockStore_name },
      condition: () => entry.stockStore
    }),
    comp("InputStockDetails"),
    // comp("ProductInfo"),
    // comp({ component: "ProductDensity", condition: () => canShow("density") }),
    // comp({ component: "ProductDose", condition: () => canShow("dose") }),
    // comp({
    //   component: "ProductCompositionDetail",
    //   title: t("inputStock.details.product_composition"),
    //   condition: () => canShow("composition"),
    // }),
    // comp({ component: "ProductOtherFeature", condition: () => canShow("otherFeature") }),
    // comp({ component: "ProductUsage", condition: () => canShow("usage") }),
    // comp({ component: "ProductRisksButton", condition: () => canShow("risks") }),
    // comp({ component: "ProductPercentageNPro", condition: () => canShow("percentnpro") }),
  ];
};

export const inputStockDetail = ({ entry }) => {

  storeCommit("inputStock/setLastEntryDisplayed", entry.id)
  return {
    title: entry.name,
    subtitle: entry.productFamily_designation,
    // actions: {
    //   delete: ({ entry }) => storeDispatch("inputStock/deleteEntry", { id: entry.id }),
    // },
    rows: productInfoRows(entry),
  };
};

export const inputStockEdit = async({ entry, title, newMode }) => {
  let editedEntry = () => storeGet("inputStock/editedEntry");

  if(entry.id) storeCommit("inputStock/resetEditedEntry"); 
  if (entry.id) storeCommit("inputStock/setLastEntryDisplayed", entry.id)

  const familyCode = entry.productFamily_code || editedEntry().productFamily_code;

  const canShow = (element) => canShowComponent(element, familyCode);

  // need to fetch stock stores here, so we can hide the component if no result in list
  await storeDispatch("stockStores/fetchStockStores")

  return {
    title: title || entry.name,
    subtitle: entry.productFamily_designation,
    actions: {
      save: async () => await storeDispatch("inputStock/updateEditedEntry"),
      delete: ({ entry }) => storeDispatch("inputStock/deleteEntry", { id: entry.id }),
    },
    rows: [
      // stock store
      comp({
        component: "EditStockStore",
        title: i18n.t("inputStock.details.stock_store"),
        condition: () => storeGet("stockStores/stockStores").length > 0
      }),
      comp({ component: "ProductTypeSelection", cols: 8 }),
      comp({ component: "ProductInStock", title: t("inputStock.details.available"), cols: 4 }),
      choice({
        condition: () => {
          // return newMode ? 0 : entry.refProduct ? 0 : entry.mixedInput_composition.length ? 2 : 1;
          return newMode ? 0 : !entry.localProduct ? 0 : entry.mixedInput_composition.length ? 2 : 1;
        },
        onInit: () => {
          if(entry && entry.localProduct != null)
            storeDispatch(storeEditTempAction, {localProduct: entry.localProduct});
        },
        onChange: (choice) => {
          if (choice == 0)
            storeDispatch(storeEditTempAction, { refProduct: entry.refProduct, product_id: entry.product_id, mixedInput_composition: null, localProduct: false });
          if (choice == 1)
            storeDispatch(storeEditTempAction, { refProduct: entry.refProduct, product_id: entry.product_id, mixedInput_composition: null, localProduct: true });
          if (choice == 2)
            storeDispatch(storeEditTempAction, {refProduct: null, product_id: null, mixedInput_composition: entry.mixedInput_composition, localProduct: true });
        },
        choices: [
          { title: t("inputStock.details.choose_product"), choice: comp("ProductSelection"), display: true },
          {
            title: t("inputStock.details.define_product"),
            choice: container(
              comp({
                component: "ProductSelection",
                title: familyCode == "sem" ? t("inputStock.details.reference_variety") : t("inputStock.details.reference_product"),
                props: { required: familyCode == "sem" },
                condition: () => familyCode != "engsy"
              }),
              comp({
                component: "ProductName",
                title: t("inputStock.details.product_name"),
                condition: () => canShow("name"),
              }),
              comp({ component: "ProductDensityForm", condition: () => canShow("density") }),
              comp({ component: "ProductDoseForm", condition: () => canShow("dose") }),
              comp({
                component: "ProductCompositionDetailForm",
                title: t("inputStock.details.product_composition"),
                condition: () => canShow("composition"),
              }),
              comp({
                component: "ProductOtherElements",
                title: t("inputStock.details.other_elements"),
                condition: () => canShow("otherElements"),
              }),
              comp({
                component: "keqNForm",
                condition: () => canShow("keqN")
              }),
              comp({ component: "ProductOtherFeatureForm", condition: () => canShow("otherFeature") }),
              comp({ component: "FarmSeedCheckBox", condition: () => canShow("farmSeedCheckBox")}),
              comp({ 
                component: "SeedTreatmentForm", 
                title: t("inputStock.details.seed_treatment"),
                condition: () => canShow("seedTreatment") 
              }),
            ),
            display: familyCode != "phyto"
          },
          {
            title: t("inputStock.details.define_products_mix"),
            choice: container(
              comp({
                component: "ProductsMixAdd",
              }),
              comp({
                component: "ProductName",
                title: t("inputStock.details.products_mix_name"),
                condition: () => canShow("name"),
              }),
              comp({
                component: "ProductsMixDoses",
              }),
              comp({ component: "FarmSeedCheckBox", condition: () => canShow("farmSeedCheckBox")}),
              comp({ 
                component: "SeedTreatmentForm", 
                title: t("inputStock.details.seed_treatment"),
                condition: () => canShow("seedTreatment") 
              }),
            ),
            display: familyCode == "sem",
          },
        ],
      }),
    ],
  };
};

export const inputStockNew = ({ entry, editedEntry }) => {
  storeCommit("inputStock/resetEditedEntry");
  storeCommit("inputStock/resetCurrentId");

  return {
    title: t("inputStock.details.create_new_inputstock"),
    rows: [comp("InputStockChooseFamily")],
  };
};

export const inputStockNewEdit = async ({ entry, editedEntry, route }) => {
  const family = route.params.family;

  storeCommit("inputStock/resetCurrentId");
  storeDispatch(storeEditTempAction, { productFamily_code: family });

  const title = i18n.t("inputStock.add.title." + family);

  return inputStockEdit({ entry, editedEntry, title, newMode: true });
};

export const inputStockProductRisks = () => {
  return {
    title: t("inputStock.details.product_risks"),
    rows: [comp("InputStockProductRisks")],
  };
};

export const inputStockProductInfos = async ({ entry, route }) => {
  const product_id = route.params.product_id;

  await storeDispatch("getProductInfo", { id: product_id });

  const product = storeGet("currentProduct");

  const storeEditTempAction = "inputStock/setEditedEntryValue";
  storeDispatch(storeEditTempAction, {
    product_id: product_id,
    productType: product.productType,
    productFamily_code: product.productFamily_code,
  });

  let editedEntry = storeGet("inputStock/editedEntry");

  return {
    title: t("inputStock.details.product_infos"),
    rows: productInfoRows(editedEntry),
  };
};

