import { commonGetters, commonMutations, commonActions } from "./commons.js";

import { config } from "@/views/activity/config";

import ActivityApi from "@/api/activity.api";
import NoteApi from "@/api/note.api";
import YieldApi from "@/api/yield.api";

import { fingerprint, addDayPlus, changedStartDate, mergeNews} from "@/utils/commons";

import { EventBus } from "@/eventBus.js";
import { dateToEnglishDashString } from "@/utils/commons";
import { mergeCurrentEntry } from "../../views/activity/mergeCurrentEntry.js";
import { removeDuplicates } from "@/utils/commons";

import Vue from "vue";

export const ALL_ACTIVITIES = "GET ALL ACTIVITIES";
export const ONE_ACTIVITY = "GET ACTIVITY BY ID";
export const ACTIVITIES_WITH_INPUT_NEEDED = "GET ALL ACTIVITIES WITH INPUT NEEDED";
export const INPUT_FORM_ACTIVITY = "GET INPUT FORM ACTIVITY";
export const CREATE_ACTIVITY = "CREATE ACTIVITY";
export const GET_FILTERS_ACTIVITY_BY = "GET ALL FILTER ACTIVITY";
export const UPDATE_ACTIVITY = "UPDATE ACTIVITY";
export const DELETE_ACTIVITY = "DELETE ACTIVITY";
export const ACTIVITY_LOADING = "LOAD ACTIVITY";
export const ACTIVITY_SET_FILTER = "SET FILTER ACTIVITY";
export const ACTIVITY_REMOVE_FILTER = "REMOVE FILTER ACTIVITY";
export const ACTIVITY_CLEAN_FILTER = "CLEAN FILTER ACTIVITY";
export const ACTIVITY_RESET_FILTER = "RESET FILTER LIST ACTIVITY";
export const SET_STATE_INPUT_TO_PATCH = "SET STATE INPUT TO PATCH";
export const CLEAN_ACTIVITY = "CLEAN ACTIVITY";
export const INIT_NEW_ACTIVITY = "INIT NEW ACTIVITY";
export const GET_ACTIVITIES_BY_CROPFIELD = "GET ACTIVITIES BY CROPFIELD";
export const SET_SUBCONTRACTORS = "SET SUBCONTRACTORS";
export const SET_CURRENT_ACTIVITY = "SET CURRENT ACTIVITY";
export const UPDATE_ENTRIES = "UPDATE ENTRIES";

export default {
  namespaced: true,
  state: {
    // commons
    _entries: {},
    currentId: null,
    filterData: {},
    filterSelection: {},
    filters: [],
    currentEntry: {},
    editedEntry: {},
    newEntry: {},
    entryUpdated: {},
    entryLoaded: true,
    searchQuery: "",
    page: 1,
    maxPage: 0,
    entriesChecked: [],
    lastEntryDisplayed: null,
    // specific
    count: 0,
    limit: 50,
    inputForm: {},
    inputsMustToBeClean: false,
    activity_loaded: true,
    filterEntryLabel: config.filterConfig.entryLabel,
    // api request fingerprints
    fingerprints: {},
    // initial fetch status
    isInitialFetchDone: false,
    // initial fetch methods that are called when the fetch is done
    initialFetchCallbacks: [],
    activitiesByCropfield: [],
    cropfieldHistoryLoadTime: null,
    lastCropFieldId: [],
    subcontractors: [],
    refreshActivities: false,
    selectedCropfieldId: null,  // Initialisé lorsqu'on crée une activité en venant du détail d'une parcelle
    showCheckbox: false
  },
  getters: {
    ...commonGetters(),
    activityCount: (state) => state.count,
    limit: (state) => state.limit,
    inputForm: (state) => state.inputForm,
    inputsMustToBeClean: (state) => state.inputsMustToBeClean,
    entryLoaded: (state) => state.activity_loaded,
    activitiesByCropfield: (state) => state.activitiesByCropfield,
    cropfieldHistoryLoadTime: (state) => state.cropfieldHistoryLoadTime,
    lastCropFieldId: (state) => state.lastCropFieldId,
    subcontractors: (state) => state.subcontractors,
    selectedCropfieldId: (state) => state.selectedCropfieldId,
    currentEntry: (state) => state.currentEntry,
    crops: (state) => {
      if(state.editedEntry.cropField__crop){
        return ([{id: state.editedEntry.cropField__crop }])
      } else {
        return state.currentEntry.cropField__crop.map(id => ({ id: id }));
      }
    },
    checkedEntries: (state, getters) => getters.entries.filter((e) => state.entriesChecked.includes(e.id)),
  },
  mutations: {
    ...commonMutations(),
    // utilise les constante defini plus haut comme les autres mutations
    [SET_STATE_INPUT_TO_PATCH]: (state, data) => {
      state.inputsMustToBeClean = data;
    },

    [CLEAN_ACTIVITY]: (state) => {
      state.newActivity = {};
      state.currentEntry = {};
    },

    [INIT_NEW_ACTIVITY]: (state) => {
      let data = {
        id: 1,
        startDate: new Date(Date.now()).toJSON(),
        activityType: "",
        activityType_code: "",
        activityType_designation: "",
        activityType_needInputs: false,
      };
      state.newActivity = data;
      state.currentEntry = data;
    },

    [ALL_ACTIVITIES]: (state, activities) => {
      state.activities = [...state.activities, ...activities.results];
      state.count = activities.count;
      state.maxPage = Math.round(activities.count / state.limit);
    },

    [GET_ACTIVITIES_BY_CROPFIELD]: (state, activities) => {
      state.activitiesByCropfield = activities;
    },

    [SET_CURRENT_ACTIVITY]: (state, activities) => {
      state.currentEntry = activities;
    },

    [UPDATE_ENTRIES]: (state, activity) => {
      // Find the index of the entry with the same id as activity.id
      const index = state._entries.findIndex(entry => entry.id === activity.id);
      if (index !== -1) {
          // Update the entry directly
          state._entries[index] = activity;
      } else {
          console.warn(`Entry with id ${activity.id} not found`);
      }
    },

    setSelectedCropfieldId: (state, id) => {
      state.selectedCropfieldId = id;
    },

    resetSelectedCropfieldId: (state) => {
      state.selectedCropfieldId = null;
    },

    setMaxPage: (state, maxPage) => {
      state.maxPage = maxPage;
    },

    setPage: (state, page) => {
      state.page = page;
    },

    setEditedEntryValue: (state, data) => {
      state.editedEntry = { ...state.editedEntry, ...data };
    },

    incrementPage: (state) => {
      state.page += 1;
    },

    resetEditedEntry: (state) => {
      state.editedEntry = {};
    },

    resetCurrentEntry: (state) => {
      state.currentEntry = {};
    },

    _setLastCropfieldId: (state, id) => {
      state.lastCropFieldId = id;
    },

    _resetLastCropfieldId: (state) => {
      state.lastCropFieldId = null;
    },

    setCropfieldHistoryLoadTime: (state, time) => {
      state.cropfieldHistoryLoadTime = time;
    },

    setRefreshActivities: (state, value) => {
      state.refreshActivities = value;
    },

    [ACTIVITY_SET_FILTER]: (state, { filterName, elementId }) => {
      let currentId = null;
      if (filterName in state.filterSelection) currentId = state.filterSelection[filterName];
      Vue.set(state.filterSelection, filterName, currentId == elementId ? null : elementId);
    },

    [ACTIVITY_CLEAN_FILTER]: (state) => {
      state.activityFilters.map((f) => f.map((e) => (e.checked = false)));
      state.activityFilterSelected = {};
    },

    [ACTIVITY_RESET_FILTER]: (state) => {
      state.activityFilters = [];
      state.activityFilterSelected = {};
    },

    [ACTIVITY_REMOVE_FILTER]: (state, payload) => {
      Vue.delete(state.activityFilterSelected, payload.key);
    },

    [ONE_ACTIVITY]: (state, payload) => {
      state.currentEntry = payload.activity;
    },

    // [ACTIVITIES_WITH_INPUT_NEEDED]: (state, payload) => {
    // },

    [INPUT_FORM_ACTIVITY]: (state, payload) => {
      state.inputForm = payload.form;
    },

    // [DELETE_ACTIVITY]: (state, payload) => {
    // },

    [ACTIVITY_LOADING]: (state, status) => {
      state.activity_loaded = !status;
    },

    [SET_SUBCONTRACTORS]: (state, data) => {
      state.subcontractors = data;
    }

  },
  actions: {
    ...commonActions(),
    async fetchEntries({ state, dispatch, commit, getters, rootGetters }, { page, initial, searchQuery } = {}) {
      // case when we call the fetchEntries too early
      // the initial fetch is not done yet or we don't call the method with initial:true
      if(!getters.isInitialFetchDone && !initial) return

      let reset = false;

      if (page != null) {
        if (page == 0) {
          reset = true;
          await commit("setPage", 1);
        } else if (page + 1 <= state.maxPage) await commit("incrementPage");
        else return;
      }
      const activityType = await dispatch("getFilterValueId", "activityType");
      const cropField = await dispatch("getFilterValueId", "cropField");
      const crop = await dispatch("getFilterValueId", "cropField__crop");
      const notes = await dispatch("getFilterValueId", "notes");
      const billed = await dispatch("getFilterValueId", "billed");
      const month = await dispatch("getFilterValueId", "month");

      let currentFarm = rootGetters["farm/currentFarm"];
      if (currentFarm) currentFarm = currentFarm.id;

      const payload = {
        limit: state.limit,
        page: state.page,
        campaign: rootGetters.getCampaign.id,
        cropField: cropField,
        crop: crop,
        activityType: activityType,
        farmId: currentFarm,
        notes: notes,
        billed: billed,
        month: month,
        search: searchQuery
      }

      // Fingerprints part, allows to avoid duplicate calls
      // have we make same request previously ?
      const fp = fingerprint(payload)
      // if fingerprint is already registered, return
      if(getters.fingerprints["fetchEntries"] == fp && !state.refreshActivities) {
        return;
      }
      // else, register fingerprint
      commit("setFingerprint", { key: "fetchEntries", fingerprint: fp })
      commit("setRefreshActivities", false)

      // call the API
      const response = await ActivityApi.getActivities(payload);

      // compute the max page number, based on total data count and limit (number of items / page)
      commit("setMaxPage", Math.ceil(response.data.count / state.limit));

      // if we load new data but we have detail panel open with an entry
      // do we need to close the panel if the entry is not in the new entries list ?
      if(state.page == 1 && !response.data.results.find(e => e.id == getters.currentId) && getters.isInitialFetchDone) {
        EventBus.$emit("goToList")
        commit("resetCurrentId");
      }

      if (page && !reset) {
        commit("setEntries", { entries: response.data.results, add: true });
      } else {
        commit("setEntries", { entries: response.data.results });
      }
    },

    async getAllFiltersActivity({ commit, rootGetters, state }, { param, campaign, values, params }) {
      commit(ACTIVITY_LOADING, true);
      if (values) {
        // Si des valeurs sont définies en dur dans la configuration, les utiliser
        commit("setFilterData", { name: param, elements: values });
      } else {
        let currentFarm = rootGetters["farm/currentFarm"];
        if (currentFarm) currentFarm = currentFarm.id;

        const copyParams = {...params}
        if (copyParams.hasOwnProperty(param)) {
          delete copyParams[param];
        }

        const response = await ActivityApi.getFiltersActivity(param, campaign, currentFarm, copyParams);
        commit("setFilterData", { name: param, elements: response.data });
      }
      
      commit(ACTIVITY_LOADING, false);
    },

    async updateFilterSelection({ commit, rootGetters, state, dispatch }, { filterName, elementId }) {
      // met à jour les filtres dans le store
      await commit("setFilter", {
        filterName: filterName,
        elementId: elementId,
      });

      const filters = await dispatch("getAllFiltersValues")

      state.filters.forEach(async (filter) => {
        await dispatch("getAllFiltersActivity", {
          param: filter.name,
          campaign: rootGetters.getCampaign.id,
          values: filter.values,
          params: filters
        });
      });

      // quand on met à jour un filtre, on repasse la pagination à 1
      commit("setPage", 1);
    },

    async initFilters({ dispatch, state, rootGetters }) {

      const filters = await dispatch("getAllFiltersValues")
      // do better
      state.filters.forEach(async (filter) => {
        await dispatch("getAllFiltersActivity", {
          param: filter.name,
          campaign: rootGetters.getCampaign.id,
          values: filter.values,
          params: filters
        });
      });
    },

    async getOneActivity({ commit, state, dispatch }, payload) {
      commit(ACTIVITY_LOADING, true);
      const response = await ActivityApi.getActivity(payload.id);

      commit("setCurrentId", { id: payload.id });
      commit("updateEntry", { id: payload.id, data: response.data });

      const mergedCurrentEntries = await dispatch("mergeCurrentEntries", {entry: response.data})

      commit(SET_CURRENT_ACTIVITY, mergedCurrentEntries);
      commit(ACTIVITY_LOADING, false);
      return mergedCurrentEntries;
    },

    async getInputFormByCropfield({ commit, getters }, payload) {
      commit(ACTIVITY_LOADING, true);

      // // have we make same request previously ?
      // const fp = fingerprint(payload)
      // // if fingerprint is already registered, return
      // if(getters.fingerprints["inputFormByCropfield"] == fp) return;
      // // else, register fingerprint
      // commit("setFingerprint", { key: "inputFormByCropfield", fingerprint: fp })

      const response = await ActivityApi.getInputFormByCropfield(
        payload.idCropfield,
        payload.idActivityType,
        payload.startDate,
      );
      commit(INPUT_FORM_ACTIVITY, { form: response.data });
      commit(ACTIVITY_LOADING, false);
      return response.data;
    },

    async createEntry({ state, commit, dispatch, rootGetters }, { entry }) {
      commit(ACTIVITY_LOADING, true);
      let newEntry = await ActivityApi.createActivity(entry);
      // old approach
      //commit(CREATE_ACTIVITY, { activity: response.data });

      // Suppression du cache pour recalculer le rapport d'activités sur une parcelle
      if (entry.cropField == state.lastCropFieldId) {
        commit("_resetLastCropfieldId")
      }
      // Suppression du cache pour recalculer le rapport d'activités sur un intrant
      commit("resetLastInputsStockId", null, { root: true })

      // Gestion de la création des notes
      let notesToPatch = entry.notesToPatch !== undefined ? entry.notesToPatch : [];
      if(notesToPatch.length > 0) {
        notesToPatch.forEach(async note => {
          note.activity = newEntry.data.id;
          if (note.text) {
            await NoteApi.createNote(note);
          }
        });
      }

      // Gestion de la création des rendements
      if (entry.theYield && entry.activityType_code == "recol") {
        let theYield = {
          theYield: entry.theYield,
          unit: entry.theYield_unit,
          cropField: entry.cropField,
          variety: entry.theYield_variety,
          activity: newEntry.data.id,
          harvestDate: dateToEnglishDashString(entry.startDate),
          surface: entry.surface
        };
        await YieldApi.createYield(theYield);
      }

      // new approach
      commit("addEntry", newEntry.data);
      commit("setCurrentId", { id: newEntry.data.id });
      const mergedCurrentEntries = await dispatch("mergeCurrentEntries", {entry: newEntry.data})
      commit(SET_CURRENT_ACTIVITY, mergedCurrentEntries);

      commit(ACTIVITY_LOADING, false);
    },

    async setEditedEntryValue({ commit }, data) {
      await commit("setEditedEntryValue", data);
    },

    async updateEntry({ commit, rootGetters, state, dispatch }, payload) {
      commit(ACTIVITY_LOADING, true);

      // Suppression du cache pour recalculer le rapport d'activités sur une parcelle
      if (payload.data.cropField == state.lastCropFieldId) {
        commit("_resetLastCropfieldId")
      }

      // Suppression du cache pour recalculer le rapport d'activités sur un intrant
      commit("resetLastInputsStockId", null, { root: true })

      let notesToPatch = payload.data.notesToPatch !== undefined ? payload.data.notesToPatch : [];
      let previousNotes = payload.data.previousNotes !== undefined ? payload.data.previousNotes : [];

      // Gestion de la suppression des notes
      if(previousNotes.length > 0) {
        let noteExist = undefined;
        previousNotes.forEach(async prevNote => {
          noteExist = notesToPatch.find(note => note.id === prevNote.id);
          if (noteExist === undefined) {
            // Si la note n'est plus présente, on la supprime du backend
            await NoteApi.deleteNote(prevNote.id);
          } else {
            // Sinon, si elle n'a pas été modifiée, on la retire du tableau des notes à mettre à jour
            if (prevNote.text === noteExist.text && prevNote.noteTopic === noteExist.noteTopic) {
              notesToPatch = notesToPatch.filter(elem => elem.id === undefined || elem.id !== prevNote.id)
            }
          }
        });
      }
      // Gestion de la création et de la mise à jour des notes
      if(notesToPatch.length > 0) {
        notesToPatch.forEach(async note => {
          note.activity = payload.id;
          if (note.id === undefined) {
            if (note.text)
              await NoteApi.createNote(note);
          } else {
            await NoteApi.updateNote(note, note.id);
          }
        });
      }
      // Gestion de la création ou de la mise à jour du rendement
      if (state.entriesChecked.length < 1 && payload.data.activityType_code == "recol") {
        let theYield = {
          theYield: payload.data.theYield,
          unit: payload.data.theYield_unit,
          cropField: payload.data.cropField,
          variety: payload.data.theYield_variety,
          activity: payload.id,
          harvestDate: dateToEnglishDashString(payload.data.startDate),
          surface: payload.data.surface
        };

        const yield_response = await YieldApi.getYields({
          campaign: rootGetters.getCampaign.id,
          activityId: payload.id
        });
        let last_yield = yield_response.data[0];

        // S'il y'avait un rendement lié à cette activité, il est modifié ou supprimé
        if (last_yield) {
          if (payload.data.theYield) {
            await YieldApi.updateYield(theYield, last_yield.id);
          } else {
            await YieldApi.deleteYield(last_yield.id);
          }
        } else if (payload.data.theYield) {
          // sinon, il est créé
          await YieldApi.createYield(theYield);
        }
      }

      let newData = {...payload.data}
      let AllResponse = []
      let finalResponse
      let multiNews = []

      //mettre en place place le faites que si plusieur on le fais a plusieur

      if (state.entriesChecked.length > 1) {
        // On va chercher les entries qui nous sont checked
        let entriesArray = Array.isArray(state._entries) ? state._entries : Object.values(state._entries);
        let entries = entriesArray.filter(e => state.entriesChecked.includes(e.id));

        // Calcul de la totalSurface (en nombre)
        const totalSurface = entries.reduce((sum, activity) => sum + (parseFloat(activity.surface) || 0), 0);

        for (let i = 0; i < state.entriesChecked.length; i++) {
          // On donne au payload le bon cropfield pour la bonne entrée
          const id = state.entriesChecked[i];
          const activity = entries.find(entry => entry.id === id);

          // On setup les dates pour le calcul
          if (payload.data.startDate && (!payload.data.dayPlus || payload.data.dayPlus == 0)) {
              const newDates = changedStartDate({
                  startDateStr: activity.startDate,
                  endDateStr: activity.endDate,
                  newStartDateStr: payload.data.startDate
              });
              newData.startDate = newDates.newStartDate;
              newData.endDate = newDates.newEndDate;
          }
          if (payload.data.dayPlus && payload.data.dayPlus != 0) {
              const newDates = addDayPlus({
                  startDateStr: activity.startDate,
                  endDateStr: activity.endDate,
                  dayPlus: payload.data.dayPlus
              });
              newData.startDate = newDates.newStartDate;
              newData.endDate = newDates.newEndDate;
          }

          // On calcul les inputs
          if (payload.data.inputs) {
              const updatedInputs = payload.data.inputs.map(input => {

                if (input.filledQuantity && input.dose === undefined) {
                  // Quantité totale saisie, pas de dose renseignée = doses proportionnelles aux doses existantes
                  // On calcule la quantité totale de produit avant modification
                  let totalQuantity = 0;
                  entries.forEach(activity => {
                    activity.inputs.forEach(i => {
                      if (i.inputsStock === input.inputsStock) totalQuantity += (i.filledQuantity || i.dose * parseFloat(activity.surface || 0))
                    })
                  });

                  // Recalcul des doses proportionnelement aux doses existantes
                  // input.filledQuantity = quantité de ce produit sur l'ensemble des activités sélectionnées
                  let previous_quantity = 0;

                  activity.inputs.forEach(i => {
                    if (i.inputsStock === input.inputsStock) {
                      previous_quantity = i.filledQuantity || i.dose * parseFloat(activity.surface || 0)
                    }
                  })

                  const newFilledQuantity = (input.filledQuantity / totalQuantity) * previous_quantity;

                  return {
                      ...input,
                      dose: 0,
                      filledQuantity: newFilledQuantity,
                  };

                }
                else if (input.filledQuantity) {
                  // Doses uniformes avec une quantité totale saisie, on calcule la quantité pour chaque activité
                  const proportion = (parseFloat(activity.surface) || 0) / totalSurface;
                  const newFilledQuantity = parseFloat((input.filledQuantity * proportion));
                  return {
                      ...input,
                      dose: input.dose ? input.dose : 0,
                      filledQuantity: newFilledQuantity
                  };
                } else if (input.dose) {
                  // Quantité totale non renseignée, on prend la dose saisie
                  return input
                }
                else {
                  // Pas de dose saisie, on renvoie la dose d'origine
                  const previous_input = activity.inputs.find(i => i.inputsStock == input.inputsStock );
                  return previous_input
                }

              });
              newData.inputs = updatedInputs;
          }


          const response = await ActivityApi.updateActivity(newData, id);
          commit("updateEntry", { id: id, data: response.data });
          const c = Object.values(state._entries).find(entry => entry.id === id);
          multiNews.push(...response.data.news)
          AllResponse.push(c)
        }
        finalResponse = mergeCurrentEntry(AllResponse)
        finalResponse.news = mergeNews([], multiNews);
      } else {
        const response = await ActivityApi.updateActivity(payload.data, payload.id);
        const mergedCurrentEntries = await dispatch("mergeCurrentEntries", {entry: response.data})
        finalResponse = mergedCurrentEntries
        commit("updateEntry", { id: payload.id, data: response.data});
      }


      commit(SET_CURRENT_ACTIVITY, finalResponse)
      commit(ACTIVITY_LOADING, false);
      return finalResponse;
    },

    async updateCheckedEntries({ state, dispatch, commit }) {
      if (!state.currentId && state.entriesChecked.length == 0) {
        await dispatch("createEntry", { entry: state.editedEntry });
        dispatch("fetchEntries");
        commit("resetEditedEntry");
        return;
      }

      let entriesToUpdate = removeDuplicates([state.currentId, ...state.entriesChecked], { ignoreType: true });

      await entriesToUpdate.forEach(async (id) => {
        await dispatch("updateEntry", { id: id, data: state.editedEntry });
      });

      commit("resetEditedEntry");
    },

    async newsAboutActivity({ commit }, payload) {
      commit(ACTIVITY_LOADING, true);
      const response = await ActivityApi.newsAboutActivity(payload.id);
      commit(ACTIVITY_LOADING, false);
      return response.data;
    },

    async patchNewsAboutActivity({ commit }, payload) {
      commit(ACTIVITY_LOADING, true);
      const response = await ActivityApi.patchNewsAboutActivity(payload.id);
      commit(ACTIVITY_LOADING, false);
      return response.data;
    },

    async deleteEntry({ commit }, { id }) {
      //commit(ACTIVITY_LOADING, true);
      const response = await ActivityApi.deleteActivity(id);
      commit("resetCurrentId");
      commit("deleteEntry", { id });
      //commit(ACTIVITY_LOADING, false);
      return response;
    },

    async getActivitiesByCropfieldWithInputs({ commit, state }, payload) {
      commit(ACTIVITY_LOADING, true);
      if(state.lastCropFieldId == payload.idCropField)
        return;

      let page = 1;
      let finalResult = [];
      let response = await ActivityApi.getActivitiesByCropfieldWithInputs(payload.idCropField, page);
      finalResult.push(...response.data.results);
      while(response.data.next != null) {
        page ++;
        response = await ActivityApi.getActivitiesByCropfieldWithInputs(payload.idCropField, page);
        finalResult.push(...response.data.results);
      }
      commit("_setLastCropfieldId", payload.idCropField);
      commit(GET_ACTIVITIES_BY_CROPFIELD, finalResult);
      commit(ACTIVITY_LOADING, false);
      return finalResult;
    },

    async setCropfieldHistoryLoadTime({commit}, time) {
      commit("setCropfieldHistoryLoadTime", time);
    },

    async setRefreshActivities({commit}, value) {
      commit("setRefreshActivities", value);
    },

    async getSubcontractors({commit}) {
      commit(ACTIVITY_LOADING, true);
      let response = await ActivityApi.getSubcontractors();
      commit(SET_SUBCONTRACTORS, response.data);
      commit(ACTIVITY_LOADING, false);
      return response.data;
    },

    async setEntriesChecked({ state, commit, dispatch, getters }, { entries }) {
      await commit("setEntriesChecked", { entries: entries });
    },

    removeCheckedEntry: (state, { id }) => {
      state.entriesChecked = [...state.entriesChecked.filter((e) => e.id != id)];
    },

    async mergeCurrentEntries({ state, commit }, {entry}) {
      //Si pas entriechecked ou une seule
      if (state.entriesChecked.length <= 1) {
          return await mergeCurrentEntry([entry])
      } else {
          let entriesArray = Object.values(state._entries);
          return await mergeCurrentEntry(entriesArray.filter(e => state.entriesChecked.includes(e.id)));
      }

    },

    async mergeNews({state},originalNews, newNews) {
      const newsMap = new Map();
      originalNews.forEach(item => newsMap.set(item.id, item));
      newNews.forEach(item => newsMap.set(item.id, item));

      return Array.from(newsMap.values());
    }

  },
};
