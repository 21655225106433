import Gateway from "./base";

export default class TerritoriesApi {
  /**
   * Get All territories
   */
  static getTerritories() {
    return Gateway.get("/refapi/territories/");
  }

  /**
   * Get one territory by id
   * @param id
   */
  static getOneTerritory(id) {
    return Gateway.get(`/refapi/territories/${id}/`);
  }
}
