import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["currentProduct"]),
    useRefProduct() {
      return !this.currentEntry.id || (!this.localProduct && this.productId && this.refProduct);
    },
    localProduct() {
      return this.currentEntry.localProduct || this.currentEditedEntry.localProduct;
    },
    productId() {
      return this.currentEntry.product_id || this.currentEditedEntry.product_id;
    },
    refProduct() {
      return this.currentEntry.refProduct || this.currentEditedEntry.refProduct;
    },
    entry() {
      if (!this.useRefProduct) return this.currentEntry;
      return this.currentProduct;
    },
  },
};
