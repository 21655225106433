import { render, staticRenderFns } from "./LastMaintenance.vue?vue&type=template&id=6f240ce4&scoped=true"
import script from "./LastMaintenance.vue?vue&type=script&lang=js"
export * from "./LastMaintenance.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f240ce4",
  null
  
)

export default component.exports