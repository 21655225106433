export default {
	data() {
		return {
			measureLayer: null,
			drawInteraction: null,
			selectedObjects: [],
			snapCropfieldsTool: null,
			snapActiveCropfieldTool: null,
			snapIslandsTool: null,
			snapWaterCourseTool: null,
			snapNotesTool: null,
			snapFirstCropfieldTool: null,
			snapSecondCropfieldTool: null
		}
	},

	methods: {
		/**
		 * Active le dessin des lignes sur la carte avec leurs longueurs
		 * @param {String} drawType LineString ou Polygon
		 */
		addMeasureInteraction(drawType) {
			this.measuringMode = true;
			// Dessine des lignes sur la carte
			// La distance sera obtenue à partir des styles définis
			this.drawInteraction = new ol.interaction.Draw({
				source: this.measureLayer.getSource(),
				type: drawType,
				style: this.getDrawingToolStyle
			});
			this.map.addInteraction(this.drawInteraction);

			this.snapCropfieldsTool = new ol.interaction.Snap({source: this.layerSource});
			this.snapFirstCropfieldTool = new ol.interaction.Snap({source: this.firstCropsLayer.getSource()});
			this.snapSecondCropfieldTool = new ol.interaction.Snap({source: this.secondCropsLayer.getSource()});
			this.snapActiveCropfieldTool = new ol.interaction.Snap({source: this.activeCropfieldLayer.getSource()});
			this.snapIslandsTool = new ol.interaction.Snap({source: this.islandsLayer.getSource()});
			this.snapWaterCourseTool = new ol.interaction.Snap({source: this.waterCourseLayer.getSource()});
			this.snapNotesTool = new ol.interaction.Snap({source: this.notesLayer.getSource()});
			this.map.addInteraction(this.snapCropfieldsTool);
			this.map.addInteraction(this.snapActiveCropfieldTool);
			this.map.addInteraction(this.snapIslandsTool);
			this.map.addInteraction(this.snapWaterCourseTool);
			this.map.addInteraction(this.snapNotesTool);
			this.map.addInteraction(this.snapFirstCropfieldTool);
			this.map.addInteraction(this.snapSecondCropfieldTool);

		},

		/**
		 * Désactive le dessin sur la carte
		 */
		removeMeasureInteraction() {
			this.map.removeInteraction(this.drawInteraction);
			this.map.removeInteraction(this.snapCropfieldsTool);
			this.map.removeInteraction(this.snapActiveCropfieldTool);
			this.map.removeInteraction(this.snapIslandsTool);
			this.map.removeInteraction(this.snapWaterCourseTool);
			this.map.removeInteraction(this.snapNotesTool);
			this.map.removeInteraction(this.snapFirstCropfieldTool);
			this.map.removeInteraction(this.snapSecondCropfieldTool);
			this.measuringMode = false;
			this.measureLayer.getSource().clear();
		},

		/**
		 * Active ou desactive la mesure de distance via le dessin sur la carte
		 * @param {Boolean} state 
		 */
		manageMeasureInteraction(state) {
			if (state)
				this.addMeasureInteraction('LineString');
			else {
				this.removeMeasureInteraction();
			}
		},

		/**
		 * Active ou desactive la mesure d'aire via le dessin sur la carte
		 * @param {Boolean} state 
		 */
		manageMeasureAreaInteraction(state) {
			if (state)
				this.addMeasureInteraction('Polygon');
			else {
				this.removeMeasureInteraction();
			}
		},

		/**
		 * Permet de sélectionner des éléments sur la carte et de déterminer la distance minimale entre eux
		 * @param {*} evt 
		 */
		minDistanceInteraction(evt) {
			let foundFeature = null;
			// Recherche de l'élement sur lequel on a cliqué
			this.map.forEachFeatureAtPixel(evt.pixel, function (feature) {
        foundFeature = feature;
        return true;
      });
			if (foundFeature != null) {
				const selIndex = this.selectedObjects.indexOf(foundFeature);
				// Si l'élément était déjà sélectionné, on le desélectionne
				if (selIndex >= 0) {
					this.selectedObjects.splice(selIndex, 1);
					this.measureLayer.getSource().removeFeature(foundFeature);
					this.measureLayer.getSource().dispatchEvent("change");
				} else {
					// S'il ya déja au moins 2 éléments sélectionnés, on les désectionne tous
					if (this.selectedObjects.length > 1) {
						this.selectedObjects.forEach(f => {
							this.measureLayer.getSource().removeFeature(f);
						});
						this.selectedObjects = [];
					}
					// Sélection de l'élément sur lequel on a cliqué
					this.selectedObjects.push(foundFeature);
					this.measureLayer.getSource().addFeature(foundFeature);
					this.measureLayer.getSource().dispatchEvent("change");
					// S'il y a 2 éléments sélectionnés, on calcule et affiche la ligne la plus courte entre les 2
					if (this.selectedObjects.length == 2) {
						const geom1 = this.selectedObjects[0].getGeometry();
						const geom2 = this.selectedObjects[1].getGeometry();
						
						const line = this.getShortestLineString(geom1, geom2);
						const feature = new ol.Feature({geometry: line})

						this.measureLayer.getSource().addFeature(feature);
						this.measureLayer.getSource().dispatchEvent("change");
					}
				}
			}
		},

		/**
		 * Retourne les coordonnées du contour externe d'une geomtrie
		 * @param {*} geometry 
		 * @returns 
		 */
		getStrokeCoordinates(geometry) {
			let strokeCoordinates = [];
			if (geometry.getType() == "Polygon") {
				strokeCoordinates = geometry.getLinearRing(0).getCoordinates();
			} else if (geometry.getType() == "Point") {
				strokeCoordinates = [geometry.getCoordinates()];
			} else { // LineString
				strokeCoordinates = geometry.getCoordinates();
			}
			
			return strokeCoordinates;
		},

		/**
		 * Retourne la line la plus courte entre 2 géométries
		 * @param {*} geom1 
		 * @param {*} geom2 
		 * @returns LineString
		 */
		getShortestLineString(geom1, geom2) {
			let result = [];
			let minDistance = Infinity;

			let geom1Coordinates = this.getStrokeCoordinates(geom1);
			let geom2Coordinates = this.getStrokeCoordinates(geom2);
			let closest = null;
			let distanceNew = null;

			geom1Coordinates.forEach(coordinates => {
				closest = geom2.getClosestPoint(coordinates);
				distanceNew = Math.pow(closest[0] - coordinates[0], 2) + Math.pow(closest[1] - coordinates[1], 2);
				if (distanceNew < minDistance) {
					minDistance = distanceNew;
					result = [coordinates, closest];
				}
			});

			geom2Coordinates.forEach(coordinates => {
				closest = geom1.getClosestPoint(coordinates);
				distanceNew = Math.pow(closest[0] - coordinates[0], 2) + Math.pow(closest[1] - coordinates[1], 2);
				if (distanceNew < minDistance) {
					minDistance = distanceNew;
					result = [closest, coordinates];
				}
			});

			return new ol.geom.LineString(result);
		},

		/**
		 * Active ou désactive la mesure de distance minimale entre 2 éléments
		 * @param {Boolean} state 
		 */
		manageMinDistanceInteraction(state) {
			if(state) {
				this.minDistanceMode = true;
				this.map.on('click', this.minDistanceInteraction);
			} else {
				this.minDistanceMode = false;
				this.map.un('click', this.minDistanceInteraction);
				this.measureLayer.getSource().clear();
				this.selectedObjects = [];
			}
		}
	}
}