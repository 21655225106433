import StockStoresAPI from "@/api/stockStores.api";

export const ONE_STOCKSTORE = "GET STOCK STORE BY ID";

export default {
  namespaced: true,
  state: {
    stockStores: [],
    currentStockStore: {}
  },
  getters: {
    stockStores: state => state.stockStores,
    currentStockStore: state => state.currentStockStore
  },
  mutations: {
    "setStockStores": (state, stockStores) => {
      state.stockStores = stockStores
    },
    [ONE_STOCKSTORE]: (state, payload) => {
      state.currentStockStore = payload.stockStore;
    },
  },
  actions: {
    async fetchStockStores({ commit, rootGetters }, filterOnFarm=true) {
      const payload = {}

      // current farm
      if (rootGetters["farm/currentFarm"] && filterOnFarm) {
        payload["farm_id"] = rootGetters["farm/currentFarm"].id;
      }

      const response = await StockStoresAPI.getStockStores(payload);
      commit("setStockStores", response.data);
      return response.data;
    },
    async getStockStore({ commit }, payload) {
      const response = await StockStoresAPI.getStockStore(payload.id);
      commit(ONE_STOCKSTORE, { stockStore: response.data });
      return response.data;
    },
  },
};
