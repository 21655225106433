import { DateTime } from "luxon";

import { i18n } from "@/i18n.js";

export const config = {
  title: i18n.t("activities.title"),
  // le nom marqué dans le comptage des groupe : XX intrants
  countingElements: i18n.t("tours.list.countingElements"),
  // "Ajouter un intrant"
  addButton: i18n.t("tours.list.add"),
  // le module du store
  module: "tour",
  scrollToSelection: true,
  // sur quel champs faire la recherche
  searchField: "name",
  // les principales routes
  routes: {
    index: {
      name: "tour",
    },
    list: {
      name: "tour.list",
    },
    new: {
      name: "tour.new",
    },
    details: {
      name: "tour.details",
    },
    params: {
      id: "tour_id",
    },
  },
  // sur quelle colonne groupe-t-on par défaut ?
  defaultPivot: 4,
  // la liste des colonnes
  columns: {
    0: {
      name: "activityType_designation",
      width: 15,
      sort: true,
      title: i18n.t("commons.list.activity_type"),
      hideOnDetails: false,
      cellComponent: "TourInfo",
      // les infos sur le groupe
      pivot: {
        enable: false,
      },
      show: true,
      priority: 1,
      orderByKey: "activityType_designation",
    },
    1: {
      name: "cropFields",
      sort: true,
      width: 15,
      title: i18n.t("commons.list.cropfields"),
      isAuthorized: false,
      isAvailable: false,
      hideOnDetails: true,
      cellComponent: "CropfieldList",
      // les infos sur le groupe
      pivot: {
        enable: false,
      },
      show: true,
      priority: 5,
    },
    2: {
      name: "farms",
      width: 10,
      sort: true,
      title: i18n.t("commons.list.farms_or_name"),
      cellComponent: "FarmList",
      // les infos sur le groupe
      pivot: {
        enable: false,
      },
      show: true,
      priority: 2,
    },
    3: {
      name: "crop_name",
      width: 10,
      sort: true,
      title: i18n.t("commons.list.crop_name"),
      isAuthorized: false,
      isAvailable: false,
      hideOnDetails: false,
      cellComponent: "CropList",
      // les infos sur le groupe
      pivot: {
        enable: false,
      },
      show: true,
      priority: 3,
    },
    4: {
      name: "startDate",
      width: 9,
      sort: true,
      title: i18n.t("tours.list.start_date"),
      isAuthorized: false,
      isAvailable: false,
      hideOnDetails: false,
      cellComponent: "Date",
      pivot: {
        enable: true,
        sortHeaderField: [(group) => {
          const order = [i18n.t("tours.list.group-upcoming"), i18n.t("tours.list.group-thisweek"), i18n.t("tours.list.group-past")]
          return order.findIndex(e => e == group.name)
        }],
        sortHeaderOrder: ["asc"],
        sortDataFields: [(activity) => activity.startDate],
        sortDataOrders: ["desc"],
        groupDisplay: {},
        displayHeader: true,
      },
      show: true,
      priority: 4,
      cellDisplay: {
        mode: "function",
        method: (value) => {
          if (!value) return i18n.t("is_empty");
          // we make 3 groups : this week, upcoming & past

          // get date of the entry
          const dt = DateTime.fromISO(value).setZone("Europe/Paris").setLocale("fr")
          // get first day of week
          const weekStart = DateTime.now().setZone("Europe/Paris").setLocale("fr").startOf("week");
          // get last day of week
          const weekEnd = DateTime.now().setZone("Europe/Paris").setLocale("fr").endOf("week");

          // compare theses dates and make groups
          if(dt >= weekStart && dt <= weekEnd) return i18n.t("tours.list.group-thisweek")
          else if(dt > weekEnd) return i18n.t("tours.list.group-upcoming")
          else if(dt < weekStart) return i18n.t("tours.list.group-past")
        },
      },
    },
    5: {
      name: "status_designation",
      width: 8,
      sort: true,
      title: i18n.t("tours.list.status"),
      isAuthorized: false,
      isAvailable: false,
      hideOnDetails: true,
      // les infos sur le groupe
      pivot: {
        enable: false,
      },
      show: true,
      priority: 4,
    },
    6: {
      name: "isTemplate",
      title: "",
      width: 2,
      cellComponent: "Pin",
      pivot: {
        enable: false,
      },
      show: true,
      priority: 4,
    },
  },
  // les filtres
  filters: [
    {
      name: "isTemplate",
      show: true,
    },
    {
      name: "activityType",
      title: i18n.t("commons.filter.activity_type"),
      show: true,
    },
    {
      name: "crop",
      title: i18n.t("commons.filter.crop_name"),
      show: true,
    },
    {
      name: "cropField",
      title: i18n.t("commons.filter.cropfield"),
      show: true,
      editMany: true
    },
    {
      name: "status",
      title: i18n.t("commons.filter.status"),
      show: true,
    },
    // {
    //   name: "machine",
    //   title: i18n.t("commons.filter.machine"),
    //   show: true,
    // },
  ],
};
