<template>
  <!-- MCT = Map Cut Tool -->
  <!-- this is the view when we modify a geometry on the map for a crofield -->
  <div>
    <!-- Line & border buttons -->
    <b-button
      class="button-default text-left mb-3 justify-content-start"
      @click="setMapCutMode(1)"
      :class="{ active: mapCutMode == 1 }"
    >
      <img src="@/assets/images/line_cut.svg" class="cut-icon mr-2" />{{ $t("cropfields.details.line_cut") }}
    </b-button>
    <b-button
      class="button-default text-left mb-3 justify-content-start"
      @click="setMapCutMode(3)"
      :class="{ active: mapCutMode == 3 }"
    >
      <img src="@/assets/images/broken_line_cut.svg" class="cut-icon mr-2" />{{ $t("cropfields.details.broken_line") }}
    </b-button>
    <b-button
      class="button-default text-left justify-content-start"
      @click="setMapCutMode(2)"
      :class="{ active: mapCutMode == 2 }"
    >
      <img src="@/assets/images/border_cut.svg" class="cut-icon mr-2" />
      {{ $t("cropfields.details.border_cut") }}
    </b-button>

    <hr />

    <!-- Guide -->
    <div v-if="guideStep < guide.length" class="px-2 guide-text">
      <p class="m-0">
        {{ guide[guideStep] }}
      </p>
      <hr />
    </div>

    <!-- During line/border cut, the cropfields names and surfaces -->
    <div v-for="(field, index) in fields.features" :key="index" class="field px-3 py-3 mt-2">
      <!-- <span class="new-cropfield" v-if="field.properties.isNew">Nouvelle parcelle</span> -->
      <b-form-group class="m-0 mb-3 p-0">
        <h4 class="subtitle-form color-dark p-0 mt-0 mb-2">{{ $t("cropfields.list.name") }}</h4>
        <b-form-input size="sm" type="text" v-model="field.properties.name" @input="updateFields"></b-form-input>
      </b-form-group>
      <b-form-group class="m-0 p-0" append="ha">
        <h4 class="subtitle-form color-dark p-0 mt-0 mb-2">{{ $t("cropfields.list.surface") }}</h4>
        <NumericInput
          id="quantity"
          inputRef="input"
          :value="field.properties.surface"
          @input="i => {field.properties.surface = i}"
          @blur="updateFields"
          />
      </b-form-group>
    </div>

    <!-- Modify & delete buttons -->
    <div v-if="mapCutMode == 0">
      <b-button class="button-default" @click="modifyGeometry" :class="{ active: modifyMode }">
        {{ $t("cropfields.details.modify_geometry") }}
      </b-button>

      <!-- Hole mode : Cancel / save buttons -->
      <b-container class="mt-3 p-0" v-if="holeMode">
        <b-row align-h="end">
          <b-col cols="12">
            <p>{{ $t("cropfields.details.make_hole_help") }}</p>
          </b-col>
          <b-col cols="6">
            <b-button class="button-default align-bottom" @click="resetHole">
              {{ $t("cropfields.details.cancel") }}
            </b-button>
          </b-col>
          <b-col cols="6">
            <b-button class="button-default align-bottom" @click="saveHole">
              {{ $t("cropfields.details.save") }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>

      <b-button v-if="selectedHole" class="button-default mt-3 justify-content-start" @click="deleteHole">
        {{ $t("cropfields.details.delete_hole") }}
      </b-button>

      <b-button class="button-default mt-3 mb-3 justify-content-start" @click="enableHoleMode" :disabled="holeMode">
        {{ $t("cropfields.details.make_hole") }}
      </b-button>

      <b-button class="button-danger-input justify-content-start" @click="deleteGeometry">
        {{ $t("cropfields.details.delete_geometry") }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/eventBus.js";

import MapCutToolsMixin from "./MapCutToolsMixin";

import { convertGeojsonToGeom, project4326 } from "@/utils/geomatics";

import { mapGetters, mapMutations, mapActions } from "vuex";
import NumericInput from "@/components/commons/NumericInput.vue";

export default {
  data() {
    return {
      holeMode: false,
      fields: {
        features: [],
      },
      // guide
      guide: [],
      guideStep: 0,
      borderCutGuide: this.$t("cropfields.map.guides.border_cut"),
      cutLineGuide: this.$t("cropfields.map.guides.line_cut"),
      brokenLineCutGuide: this.$t("cropfields.map.guides.broken_line_cut"),
    };
  },
  mixins: [MapCutToolsMixin],
  mounted() {
    // if we get the signal (from the map) to go to next guide step
    let t = this;
    EventBus.$on("nextGuideStep", function () {
      t.guideStep += 1;
    });
    // or to reset the counter
    EventBus.$on("resetGuideStep", function () {
      t.guideStep = 0;
    });
  },
  props: {
    modifyMode: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapMutations({
      setMapCutMode: "cropfield/setMapCutMode",
      addGeometry: "cropfield/addGeometry",
      resetEditedEntry: "cropfield/resetEditedEntry",
      setCurrentId: "cropfield/setCurrentId",
    }),
    ...mapActions({
      createEntry: "cropfield/createEntry",
      updateEntry: "cropfield/updateEntry",
      updateGeometry: "cropfield/updateGeometry",
      fetchIslandGeometries: "cropfield/fetchIslandGeometries",
    }),
    // when cutting with line/border, update fields if inputs change
    updateFields() {
      this.$store.commit("cropfield/setMapCutGeometries", this.fields);
    },
    async deleteGeometry() {
      let confirmation = confirm(this.$t("cropfields.details.geometry_warning_delete"));
      if (!confirmation) return;

      await this.updateEntry({ id: this.currentCropfield.id, data: { geometry: null } });
      EventBus.$emit("plotFields", false);
      EventBus.$emit("resetFeatureHoles");
    },
    async enableHoleMode() {
      this.holeMode = true;

      EventBus.$emit("enableHoleMode");
    },
    saveHole() {
      this.holeMode = false;
      EventBus.$emit("disableHoleMode", { save: true });
    },
    resetHole() {
      this.holeMode = false;
      EventBus.$emit("disableHoleMode", { save: false });
    },
    async deleteHole() {
      if (await askConfirm(this.$t("cropfields.details.confirm_delete_hole"))) {
        EventBus.$emit("deleteHole");
      }
    },
    async save() {
      this.resetEditedEntry();
      // first case, we are modifying the geometry
      // then we trigger the save on map side
      if (this.modifyMode) {
        this.$emit("update:modifyMode", false);

        EventBus.$emit("disableModifyGeometry", { save: true });
        return;
      }
      let originalId;
      // if we are in line/border cut, we do the save here
      if (this.mapCutMode > 0) {
        // else
        for (let i = 0; i < this.fields.features.length; i++) {
          let newField = this.fields.features[i];
          newField = project4326(newField);
          let data = {
            geometry: newField.geometry,
            surface: newField.properties.surface,
            name: newField.properties.name,
          };
          if (newField.properties.id) {
            originalId = newField.properties.id;
            await this.updateEntry({ id: newField.properties.id, data: data });
          } else {
            data["derivedFromCropField"] = originalId;
            data.geometry = convertGeojsonToGeom(newField);
            await this.createEntry({ entry: data });
          }
        }
        EventBus.$emit("plotFields", false);
        EventBus.$emit("plotHoles");
        this.setMapCutMode(0);
        this.setCurrentId({ id: originalId });
      }
      this.reset();
    },
    async reset() {
      this.fields = {
        features: [],
      };

      EventBus.$emit("resetMapCut");
      EventBus.$emit("disableModifyGeometry", { save: false });

      this.resetEditedEntry();

      this.$emit("update:modifyMode", false);
    },
  },
  computed: {
    ...mapGetters({
      cropfields: "cropfield/entries",
      _entries: "cropfield/_entries",
      mapCutMode: "cropfield/mapCutMode",
      currentEntry: "cropfield/currentEntry",
      entriesChecked: "cropfield/entriesChecked",
      checkedGeometries: "cropfield/checkedGeometries",
      mapCutGeometries: "cropfield/mapCutGeometries",
      currentCropfieldHasGeometry: "cropfield/currentCropfieldHasGeometry",
      getCampaign: "getCampaign",
      selectedHole: "cropfield/selectedHole",
    }),
    currentCropfield() {
      if (this.entriesChecked.length == 0) return this.currentEntry;
      return this.cropfields.find((e) => e.id == this.entriesChecked[0]);
    },
    onlyOneCheckedCropfield() {
      return this.entriesChecked.length == 1;
    },
  },
  watch: {
    mapCutGeometries(newValue, oldValue) {
      this.fields = JSON.parse(JSON.stringify(this.mapCutGeometries));
    },
    mapCutMode(newValue, oldValue) {
      if (newValue == 0 && oldValue != 0) {
        this.guide = [];
        this.guideStep = 0;
      }

      if (newValue != 0) {
        if (newValue == 3) this.guide = this.brokenLineCutGuide;
        if (newValue == 2) this.guide = this.borderCutGuide;
        if (newValue == 1) this.guide = this.cutLineGuide;
      }
    },
  },
  components: {
    NumericInput
  }
};
</script>

<style lang="scss" scoped>
.cut-icon {
  width: 18px;
}
</style>
