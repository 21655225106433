import { dateToSlashString } from "@/utils/commons";

import { i18n } from "@/i18n.js";

export const config = {
  title: i18n.t("harvestStorage.title"),
  countingElements: i18n.t("harvestStorage.list.countingElements"),
  addButton: i18n.t("harvestStorage.list.add"),
  module: "harvestStorage",
  loadEntriesOnScroll: true,
  scrollToSelection: false,
  searchField: "cropField_name",
  routes: {
    index: {
      name: "harvestStorage",
    },
    list: {
      name: "harvestStorage.list",
    },
    new: {
      name: "harvestStorage.new",
    },
    details: {
      name: "harvestStorage.details",
    },
    params: {
      id: "harvestStorage_id",
    },
  },
  defaultPivot: 1,
  columns: {
    0: {
      name: "doneDate",
      sort: true,
      title: i18n.t("harvestStorage.list.date"),
      isDate: true,
      hideOnDetails: false,
      cellDisplay: {
        mode: "function",
        method: (value) => {
          if (!value) return i18n.t("is_empty");

          return dateToSlashString(new Date(value));
        },
      },
      pivot: {
        enable: true,
        groupDisplay: {},
        displayHeader: true,
        sortHeaderField: [(group) => group.rows.length > 0 && group.rows[0].doneDate],
        sortHeaderOrder: ["desc"],
        sortDataFields: [(harvestStorage) => harvestStorage.doneDate],
        sortDataOrders: ["desc"],
      },
      show: true,
      priority: 9,
    },
    1: {
      name: "cropField_name",
      sort: true,
      title: i18n.t("harvestStorage.list.cropField"),
      hideOnDetails: false,
      pivot: {
        enable: true,
        groupDisplay: {},
        displayHeader: true,
        sortHeaderField: [(group) => group.name.toLowerCase()],
        sortHeaderOrder: ["asc"],
        sortDataFields: [(harvestStorage) => harvestStorage.doneDate],
        sortDataOrders: ["desc"],
      },
      show: true,
      priority: 9,
    },
    2: {
      name: "crop",
      sort: true,
      title: i18n.t("harvestStorage.list.crop"),
      hideOnDetails: false,
      cellDisplay: {
        mode: "fromStore",
        getter: "crop/crops",
        key: "name",
      },
      pivot: {
        enable: true,
        sortHeaderField: [(group) => group.name.toLowerCase()],
        sortHeaderOrder: ["asc"],
        sortDataFields: [(harvestStorage) => harvestStorage.doneDate],
        sortDataOrders: ["desc"],
        groupDisplay: {},
        displayHeader: true,
      },
      show: true,
      priority: 9,
    },
    3: {
      name: "variety_name",
      sort: true,
      title: i18n.t("harvestStorage.list.product"),
      hideOnDetails: false,
      pivot: {
        enable: true,
        groupDisplay: {},
        displayHeader: true,
        sortHeaderField: [(group) => group.name.toLowerCase()],
        sortHeaderOrder: ["asc"],
        sortDataFields: [(harvestStorage) => harvestStorage.doneDate],
        sortDataOrders: ["desc"],
      },
      show: true,
      priority: 9,
    },
    4: {
      name: "warehouse_name",
      sort: true,
      title: i18n.t("harvestStorage.list.warehouse"),
      hideOnDetails: false,
      pivot: {
        enable: true,
        groupDisplay: {},
        displayHeader: true,
        sortHeaderField: [(group) => group.name.toLowerCase()],
        sortHeaderOrder: ["asc"],
        sortDataFields: [(harvestStorage) => harvestStorage.doneDate],
        sortDataOrders: ["desc"],
      },
      show: true,
      priority: 9,
    },
    5: {
      name: "quantity",
      sort: false,
      title: i18n.t("harvestStorage.list.quantity"),
      hideOnDetails: false,
      pivot: {
        enable: false,
        groupDisplay: {},
        displayHeader: true,
      },
      show: true,
      priority: 9,
    },
    6: {
      name: "unit",
      sort: false,
      title: i18n.t("harvestStorage.list.unit"),
      hideOnDetails: false,
      pivot: {
        enable: false,
        groupDisplay: {},
        displayHeader: true,
      },
      show: true,
      priority: 9,
    },
  },
  filters: [
    {
      name: "crop",
      show: false,
      title: i18n.t("harvestStorage.filter.crop"),
    },
    {
      name: "warehouse",
      show: false,
      title: i18n.t("harvestStorage.filter.warehouse"),
    },
  ],
};
