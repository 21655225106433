<template>
  <button class="btn align-bottom" @click="onClick">
    <img :src="icon" alt="icon back action" />
    {{ text }}
  </button>
</template>

<script>
import backIcon from "@/assets/images/left-arrow.svg";

export default {
  name: "BackButton",
  data() {
    return {
      icon: backIcon,
    };
  },
  props: {
    text: {
      type: String,
      require: true,
    },
    onClick: {
      type: Function,
      default: () => this.$router.go(-1),
      require: true,
    },
  },
};
</script>
<style lang="scss" scoped>
button {
  background-color: $background-text-color;
  font-size: 0.8em;
  font-weight: bold;

  img {
    width: 1.2em;
  }
}

button:hover {
  background: $secondary-light-color;
}
</style>
