import { render, staticRenderFns } from "./FertilizationPlan.vue?vue&type=template&id=700037c3&scoped=true"
import script from "./FertilizationPlan.vue?vue&type=script&lang=js"
export * from "./FertilizationPlan.vue?vue&type=script&lang=js"
import style0 from "./FertilizationPlan.vue?vue&type=style&index=0&id=700037c3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "700037c3",
  null
  
)

export default component.exports