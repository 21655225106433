<template>
  <div class="d-flex flex-column justify-content-center">
    <div class="d-block font-12" style="font-weight: normal">
      {{ firstCrops(3) }}
    </div>
    <span class="d-inline-block font-12" v-if="crops && crops.length > 3">
      <b-button :id="'popover-crops-' + rowId" type="button" class="badge badge-pill badge-secondary">
        <span>+{{ crops.length - 2 }}</span>
      </b-button>
    </span>

    <b-popover :target="'popover-crops-' + rowId" triggers="hover" placement="auto" boundary="window">
      <div v-for="crop in crops" :key="crop">
        {{ crop }}
      </div>
    </b-popover>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: ["rowId", "rowData"],
  computed: {
    crops() {
      // compact -> remove undefined values
      // uniq -> unique values
      return _.uniq(_.compact(this.rowData?.cropFields?.map(c => c.crop_name) || []));
    }
  },
  methods: {
    firstCrops(n) {
      if (!this.crops) return "";
      return this.crops
        .slice(0, n)
        .join(", ");
    },
  },
};
</script>

<style lang="scss" scoped></style>
