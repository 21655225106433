//import InputStockIndex from "./InputStockIndex.vue";
//import InputStockList from "./InputStockList.vue";
import InputStockDetails from "./InputStockDetails.vue";
import InputStockForm from "./InputStockForm.vue";
import InputStockRisks from "./InputStockProductRisks.vue";
import InputStockInfo from "./InputStockInfoProductPage.vue";

import CommonViewIndex from "@/views/common/CommonViewIndex.vue";
import CommonViewList from "@/views/common/CommonViewList.vue";
import CommonViewFiltering from "@/views/common/CommonViewFiltering.vue";
import CommonViewDetail from "@/views/common/CommonViewDetail.vue";

import InputStockImportExportIndex from "@/views/inputStock/import-export/InputStockImportExportIndex.vue";
import InputStockImportFromFile from "@/views/inputStock/import-export/InputStockImportFromFile.vue";
import InputStockReportIndex from "@/views/inputStock/reports/InputStockReportIndex.vue";
import InputStockReportUsage from "@/views/inputStock/reports/InputStockReportUsage.vue";
import InputStockReportState from "@/views/inputStock/reports/InputStockReportState.vue";

import { config } from "./config";
import { views } from "@/views/inputStock/details/index";
import { generateDetailViews } from "@/views/common/details/commons";

export default [
  {
    component: CommonViewIndex,
    path: "/inputStock",
    props: config,
    children: [
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
        },
        path: "",
        name: "inputStock.list",
      },
      // import / export page
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: InputStockImportExportIndex,
        },
        path: "import-export",
        name: "inputStock.importexport",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: InputStockImportFromFile,
        },
        path: "import",
        name: "inputStock.importfromfile",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: InputStockReportIndex,
        },
        path: "reports",
        name: "inputStock.reports",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: InputStockReportUsage,
        },
        path: "report-inputstock-usage",
        name: "inputStock.reports.inputstock_usage",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: InputStockReportState,
        },
        path: "report-inputstock-state",
        name: "inputStock.reports.inputstock_state",
      },
      // other detail routes
      ...generateDetailViews(views, CommonViewList, CommonViewFiltering, CommonViewDetail),
    ],
  },
];
