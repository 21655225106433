import Gateway from "./base";

export default class FarmApi {
  /**
   * Get All Farms
   */
  static getFarms() {
    return Gateway.get("/api/farms/", {version: "2"});
  }

  /**
   * Create farm
   *
   * @param data
   */
  static createFarm(data) {
    return Gateway.post(`/api/farms/`, data);
  }

  /**
   * Update farm
   *
   * @param data
   */
   static updateFarm(id, data) {
    return Gateway.patch(`/api/farms/${id}/`, data);
  }
}
