<template>
  <div>
    <!-- Product composition -->
    <div>
      <h4 class="subtitle-form">Composition du produit</h4>

      <table>
        <tr v-for="composition in currentProduct.composition" v-bind:key="composition.id" :value="composition.id">
          <div class="box-infos-input-form text-infos">
            <td>{{ composition.compound }}</td>
            <td>{{ composition.dose }} {{ composition.unit }}</td>
          </div>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
// import ActivityModule from "@/store/modules/activity";
import { mapGetters } from "vuex";

export default {
  name: "product-composition",
  data() {
    return {};
  },
  components: {},
  methods: {},
  computed: {
    ...mapGetters({
      currentProduct: "currentProduct",
    }),
  },
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: separate;
  border-spacing: 0 5px;
}

.td-composition {
  background-color: $background-text-color;
}

.box-infos-input-form td {
  padding: 5px 7px;
}
</style>
