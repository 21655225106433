<template>
  <b-container :class="['px-0', 'mh-100', minimal ? 'pt-0' : 'pt-1', 'h-100', 'd-flex', 'flex-column']" fluid>
    <b-row v-if="!minimal && menuBar" ref="mapTools" class="h-auto px-2 py-2 justify-content-between" align-v="end" no-gutters>
      <b-col cols="4">
        <div class="mr-4">
          <div  class="checkbox-container">
            <b-form-checkbox
              class="checkbox"
              v-model="selectionMultiple"
              switch />
              {{ $t("multiple") }}
          </div>
        </div>
        <FilterTopBar module="cropfield" />
      </b-col>
      <b-col cols="6" class="d-flex align-items-end justify-content-end">
        <div class="mr-4 green-variant">
          <button
            v-if="oneSelectedCropfield || onlyOneCheckedCropfield"
            class="btn btn-sm btn-secondary"
            @click="editGeometry"
          >
            <span v-if="currentCropfieldHasGeometry">
              {{ $t("cropfields.details.edit_geometry") }}
            </span>
            <span v-else>{{ $t("cropfields.details.define_geometry") }}</span>
          </button>

          <div id="merge-btn-wrapper " tabindex="0">
            <button
              v-if="severalCropfields"
              class="btn btn-sm align-bottom btn-secondary"
              :disabled="!mergeAllowed"
              @click="mergeCropfields({ routeName: $route.name })"
            >
              {{ $t("cropfields.details.merge") }}
            </button>
            <b-tooltip v-if="!mergeAllowed" target="merge-btn-wrapper">{{
              $t("cropfields.details.merge_not_allowed")
            }}</b-tooltip>
          </div>
        </div>
        <b-dropdown
          id="dropdown-dropup"
          size="sm"
          dropup
          :text="$t('cropfields.map.layers.title')"
          class="mr-2 green-variant"
        >
          <b-dropdown-form v-for="filter in layerFilters" :key="filter.name">
            <b-form-checkbox v-model="filter.value">{{ filter.name }}</b-form-checkbox>
          </b-dropdown-form>
        </b-dropdown>
        <CropfieldsTabs v-if="module == 'cropfield'" />
      </b-col>
    </b-row>
    <b-row
      :style="{ height: 'calc(100% - ' + toolsHeight + 'px)' }"
      :class="['mh-100', 'overflow-hidden', 'p-2', 'flex-grow-1']"
      no-gutters
    >
      <b-col cols="12" class="mh-100">
        <div id="map" :class="['w-100 h-100 mh-100']"></div>
        <div ref="exportOptions" v-show="showExportOptions">
          <CropfieldsMapExportPDF :map="this.map"/>
        </div>
        <div ref="toolBox" v-if="this.menuBar">
          <CropfieldsMapToolBox
            @ruler="manageMeasureInteraction"
            @min_distance="manageMinDistanceInteraction"
            @area="manageMeasureAreaInteraction"
          />
        </div>
        <div ref="layerBox" v-if="this.menuBar">
          <CropfieldsMapLayerBox
            :layerFilters.sync="layerFilters"
          />
        </div>
        <div class="lengthElement d-none" ref="lengthElement"></div>
        <!-- Affichage du panneau avec la légende et les infos sur le parcours -->
        <div v-if="showActivityPathFilter && !this.$route.name.includes('cropfield')" class="position-absolute pathFilter">
          <ActivityPathFilter
            :fromMachine="pathOptions.fromMachine"
            :date.sync="path.date"
            :time.sync="path.time"
            :duration.sync="path.duration"
            :layers.sync="pathLayers"
            :layersOptions.sync="pathLayersOptions"
            @change="fetchPath"
          />
        </div>
        <!-- Modal s'affichant lors du clic sur des parcelles superposées -->
        <div>
          <CropfieldsMapChooseCropfield
            ref="cropfieldChoice"
            :cropFields="selectedCropfields"
            @select="clickConfirmed"
          />
        </div>
      </b-col>
    </b-row>
    <div
      v-if="showDistanceTooltip"
      :style="{ top: 5 + cursorY + 'px', left: 20 + cursorX + 'px' }"
      class="position-fixed distance-tooltip"
    >
      {{ cutLineDistance }}m
    </div>

    <div
      v-if="pathTooltip.content"
      :style="{ top: 5 + pathTooltip.y + 'px', left: 20 + pathTooltip.x + 'px'}"
      style="z-index: 1000"
      class="position-fixed distance-tooltip"
    >
      <div v-if="pathTooltip.imageFile" class="my-2">
        <ImageViewer :imageSrc="pathTooltip.imageFile"/>
      </div>
      <div
        v-html="pathTooltip.content"
      ></div>
    </div>
  </b-container>
</template>

<script>
import FilterTopBar from "@/components/FilterTopBar.vue";

import CropfieldsTabs from "@/components/cropfields/CropfieldsTabs.vue";
import CropfieldsMapExportPDF from "@/components/cropfields/map/CropfieldsMapExportPDF.vue";
import CropfieldsMapToolBox from "@/components/cropfields/map/CropfieldsMapToolBox.vue";
import CropfieldsMapLayerBox from "@/components/cropfields/map/CropfieldsMapLayerBox.vue";
import CropfieldsMapChooseCropfield from "@/components/cropfields/map/CropfieldsMapChooseCropfield.vue";

import CropfieldModule from "@/store/modules/cropfield";

import CropfieldMapBaseMixin from "@/views/cropfields/map/CropfieldMapBaseMixin";
import CropfieldMapModifyGeometryMixin from "@/views/cropfields/map/CropfieldMapModifyGeometryMixin";
import CropfieldMapHoleModeMixin from "@/views/cropfields/map/CropfieldMapHoleModeMixin";
import CropfieldMapPathMixin from "@/views/cropfields/map/CropfieldMapPathMixin";
import CropfieldMapStyleMixin from "@/views/cropfields/map/CropfieldMapStyleMixin";
import CropfieldMapRPGMixin from "@/views/cropfields/map/CropfieldMapRPGMixin";
import CropfieldMapToolBoxMixin from "@/views/cropfields/map/CropfieldMapToolBoxMixin";
import CropfieldMapDrawMixin from "@/views/cropfields/map/CropfieldMapDrawMixin";

import ImageViewer from "@/components/commons/ImageViewer.vue";

// Images des punaises pour les notes
import redPin from "@/assets/images/red_pin.png";
import tractor from "@/assets/images/tractor.png";
import upArrow from "@/assets/images/up_arrow.png";
import i_1E1AE3 from "@/assets/images/pins/1E1AE3.png";
import i_3CE114 from "@/assets/images/pins/3CE114.png";
import i_24D5E7 from "@/assets/images/pins/24D5E7.png";
import i_A9E60A from "@/assets/images/pins/A9E60A.png";
import i_A51CE4 from "@/assets/images/pins/A51CE4.png";
import i_E32C1B from "@/assets/images/pins/E32C1B.png";
import i_EC1AB5 from "@/assets/images/pins/EC1AB5.png";
import i_ECE71C from "@/assets/images/pins/ECE71C.png";

import { mapGetters, mapMutations, mapActions } from "vuex";
import { intervalMinutes, addDuration } from "@/utils/commons";

export default {
  mixins: [
    CropfieldMapBaseMixin,
    CropfieldMapModifyGeometryMixin,
    CropfieldMapHoleModeMixin,
    CropfieldMapPathMixin,
    CropfieldMapStyleMixin,
    CropfieldMapRPGMixin,
    CropfieldMapToolBoxMixin,
    CropfieldMapDrawMixin
  ],
  data() {
    return {
      module: "cropfield",
      publicPath: process.env.BASE_URL,
      disableInteraction: false,
      outlineMode: false,
      redPin: redPin,
      tractor: tractor,
      upArrow: upArrow,
      noteImgSrc: {
        "1E1AE3.png": i_1E1AE3,
        "3CE114.png": i_3CE114,
        "24D5E7.png": i_24D5E7,
        "A9E60A.png": i_A9E60A,
        "A51CE4.png": i_A51CE4,
        "E32C1B.png": i_E32C1B,
        "EC1AB5.png": i_EC1AB5,
        "ECE71C.png": i_ECE71C,
      },
      showExportOptions: false,
      selectedCropfields: [],
      selectedFeatures: [],
    };
  },
  props: {
    routes: {
      type: Object,
      default: () => {},
    },
    minimal: {
      type: Boolean,
      default: false,
    },
    menuBar: {
      type: Boolean,
      default: true,
    },
    searchBar: {
      type: Boolean,
      default: true,
    },
    routing: {
      type: Boolean,
      default: true,
    },
    selectionMultipleByDefault: {
      type: Boolean,
      default: false
    },
    deleteTrashIcon: {
      type: Boolean,
      default: false
    },
    clearSelectionOnLoad: {
      type: Boolean,
      default: false
    },
    layers: {
      type: Array,
      default: () => ['satellite', 'islands', 'parcels', 'osm', 'varieties', 'notes', 'secondCrop', 'machines', 'waterCourse']
      // default: () => ['satellite', 'agriculture', 'islands', 'parcels', 'osm', 'varieties', 'notes', 'secondCrop', 'machines', 'waterCourse']
    },
    fullScreenButton: {
      type: Boolean,
      default: true
    },
    isOnCropfieldView: {
      type: Boolean,
      default: true
    },
    enableFreeHand: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CropfieldsTabs,
    FilterTopBar,
    CropfieldsMapExportPDF,
    CropfieldsMapToolBox,
    CropfieldsMapLayerBox,
    CropfieldsMapChooseCropfield,
    ImageViewer
  },
  async mounted() {
    // charge les données des parcelles
    if (!this.$store.state["cropfield"]) {
      this.$store.registerModule("cropfield", CropfieldModule);
    }

    // Si on est sur la page des propositions de contour de parcelle, on empêche la mise en évidence de la parcelle sélectionnée
    // et on affiche le parcours
    if (this.$route.params.mode == "outlineMode") {
      this.outlineMode = true;
      this.displayActiveCropfield = false;
      if(this.activityPathOptions) {
        this.$emit('update:pathOptions', this.activityPathOptions);
      }
    }

    // Resélection de la parcelle en cours de visualisation, si existante
    if(this.lastEntryDisplayed && this.isOnCropfieldView && !this.outlineMode) {
      this.onSelect(this.lastEntryDisplayed);
    }
    this.fetchCrops()
    // Initialisation de la map
    let fetchCropField = true
    if (this.isOnTour) fetchCropField = false
    if (this.globalFiltersLoaded) this.fetchAndInit(fetchCropField=fetchCropField);
  },
  methods: {
    ...mapMutations({
      selectHole: "cropfield/selectHole",
      resetSelectedHole: "cropfield/resetSelectedHole",
      resetCurrentId: "cropfield/resetCurrentId",
    }),
    ...mapActions({
      fetchEntries: "cropfield/fetchEntries",
      updateGeometry: "cropfield/updateGeometry",
      updateEntry: "cropfield/updateEntry",
      mergeCropfields: "cropfield/mergeCropfields",
      fetchIslandGeometries: "cropfield/fetchIslandGeometries",
      setEditedEntryValue: "cropfield/setEditedEntryValue",
      fetchVarietyGeometries: "cropfield/fetchVarietyGeometries",
      fetchNoteGeometries: "fetchNoteGeometries",
      fetchMachineGeometries: "machine/getAllCurrentLocations",
      fetchWaterCourseGeometries: "cropfield/getWaterCourse",
      fetchCrops: "crop/fetchCrops",
    }),
  },
  computed: {
    ...mapGetters({
      currentFarm: "farm/currentFarm",
      currentFarmFullAddress: "farm/currentFarmFullAddress",
      currentCampaign: "getCampaign",
      hasCurrentCampaign: "hasCurrentCampaign",
      globalFiltersLoaded: "user/globalFiltersLoaded",
      _entries: "cropfield/_entries",
      geometries: "cropfield/geometries",
      geometries3857: "cropfield/geometries3857",
      checkedGeometries3857: "cropfield/checkedGeometries3857",
      checkedGeometries: "cropfield/checkedGeometries",
      checkedEntries: "cropfield/checkedEntries",
      islandGeometries3857: "cropfield/islandGeometries3857",
      varietyGeometries3857: "cropfield/varietyGeometries3857",
      noteGeometries3857: "noteGeometries3857",
      machineGeometries3857: "machine/currentMachineGeometries3857",
      currentCropfieldHasGeometry: "cropfield/currentCropfieldHasGeometry",
      entries: "cropfield/entries",
      entriesChecked: "cropfield/entriesChecked",
      mapCutMode: "cropfield/mapCutMode",
      currentEntry: "cropfield/currentEntry",
      editedEntry: "cropfield/editedEntry",
      mergeAllowed: "cropfield/mergeAllowed",
      selectedHole: "cropfield/selectedHole",
      currentActivity: "activity/currentEntry",
      currentStockStore: "stockStores/currentStockStore",
      activeVarietiesLayer: "cropfield/activeVarietiesLayer",
      geometriesToSelect: "cropfield/geometriesToSelect",
      lastEntryDisplayed: "cropfield/lastEntryDisplayed",
      uniqueCropGeometries3857: "cropfield/uniqueCropGeometries3857",
      firstCropGeometries3857: "cropfield/firstCropGeometries3857",
      secondCropGeometries3857: "cropfield/secondCropGeometries3857",
      customer: "user/customer",
    }),
    oneSelectedCropfield() {
      return this.currentEntry && this.currentEntry.id && this.entriesChecked.length == 0;
    },
    onlyOneCheckedCropfield() {
      return this.entriesChecked.length == 1;
    },
    severalCropfields() {
      return this.entriesChecked.length > 1;
    },
    currentCropfield() {
      if (this.entriesChecked.length == 0) return this.currentEntry;
      return this.entries.find((e) => e.id == this.entriesChecked[0]);
    },
    currentCropfieldGeometry() {
      return this.currentCropfield.geometry;
    },
    rowsChecked: {
      get() {
        return this.$store.getters[this.module + "/entriesChecked"] || [];
      },
      set(entries) {
        this.$store.dispatch(this.module + "/setEntriesChecked", { entries });
      },
    },
    activityPathOptions() {
      // si l'heure de fin est définie => durée du parcours (minutes) = int((heure de fin - heure de début + 10s)/60) + 1
      // sinon : durée du parcours (minutes) = minimum(int((maintenant + 5 minutes - heure de début)/60), 600)
      let currentDate = new Date();
      currentDate.setMinutes(currentDate.getMinutes() + 5);
      currentDate = currentDate.toString();
      return {
        activity: this.currentActivity.id,
        startDate: this.currentActivity.startDate,
        shiftMinutes: 0.17,
        duration: this.currentActivity.endDate
          ? intervalMinutes({ startDate: this.currentActivity.startDate, endDate: this.currentActivity.endDate, secondsAdded: 10 }) + 1
          : Math.min(intervalMinutes({ startDate: this.currentActivity.startDate, endDate: currentDate }), 600),
        fromMachine: false,
      };
    },
    /**
     * @description return true if we are on pages which manage tours
     */
    isOnTour() {
      return this.$route.name.includes("tour");
    },
  },
  watch: {
    entries(newValue, oldValue) {
    },
    globalFiltersLoaded(newValue) {
      let fetchCropField = true
      if (this.isOnTour) fetchCropField = false
      if (newValue) this.fetchAndInit(fetchCropField=fetchCropField);
    },
    currentFarm() {
      if (!this.hasCurrentCampaign) return;
      this.fetchAndResetMap();
    },
    currentCampaign() {
      this.fetchAndResetMap();
    },
    layerFilters: {
      handler() {
        this.refreshLayers();
      },
      deep: true,
    },
    $route() {
      let t = this;
      setTimeout(function () {
        if (!t.map) return;
        t.map.updateSize();
      }, 200);

      if (this.$route.params.mode == "editGeometry") this.plotFeatureHoles();
      else this.resetFeatureHoles();
    },
    isOnSecondCrop: {
      handler() {
        // Si on affiche les dernières (2nde et unique) cultures et que la parcelle sélectionnée est une 1ère ayant une deuxième
        // Ou si on affiche les 1ère cultures uniquement et que la parcelle sélectionnée est une 2ème culture
        // On fait disparaitre le panneau de détail
        if ((this.isOnSecondCrop && this.currentEntry?.nextCropFields && this.currentEntry?.nextCropFields.length > 0)
             || (!this.isOnSecondCrop && this.currentEntry?.previousCropField)
        ) {
          this.$router.push({ name: "cropfield.map"}).catch((error) => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
// css avec sass
.distance-tooltip,
.lengthElement {
  font-size: 0.8rem;
  border-radius: 10px;
  padding: 5px;
  background: black;
  color: white;
}

.pathFilter {
  right: 20px;
  top: 20px;
}

.checkbox-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 13px;
  }

  .checkbox{
    margin-left: 10px;
  }

</style>

<style>
.GPwidget label {
  margin-bottom: 0 !important;
}
</style>
