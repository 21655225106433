<template>
  <!-- Treatment Component : Option -->
  <!-- Displays a option selector. Below are listed all the possible options. But you can narrow it with the exportAllowedOptions attribute of the treatment -->
  <div class="mt-4">
    <h4 class="subtitle-form mb-2">{{ $t(this.titleOption) }}</h4>
    <b-container class="bg-white text-infos">
      <!-- display the choices : The options -->
      <b-row>
        <b-col>
          <b-form-radio-group
            v-model="optionChoice"
            :options="options"
            name="optionChoice"
            class="bg-white text-infos"
            stacked
          ></b-form-radio-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import TreatmentFormPartMixin from "@/components/commons/treatments/TreatmentFormPartMixin";
import { TreatmentFormPart } from "@/models/treatments/Treatment.js";

export default {
  mixins: [TreatmentFormPartMixin],
  data() {
    return {
      optionChoice: null,
      allOptions: [],
    };
  },
  mounted() {
    if (!this.optionChoice && this.options.length > 0) this.optionChoice = this.options[0].value;
  },
  computed: {
    options() {
      // if no specified allowed option, return all
      if (this.groupOptions.length == 0) return this.allOptions;
      // else return matching format options in the correct order
      return (this.groupOptions);
    },
  },
  methods: {
    validateForm() {
      this.isValid = true;
      this.treatment.exportedOption = this.optionChoice;
      return new TreatmentFormPart(this.returnedOption, this.optionChoice);
    },
  },
  props: {
    groupOptions: {
      type: Array,
      default: () => [],
    },
    returnedOption: {
      type: String,
      default: () => '',
    },
    titleOption: {
      type: String,
      default: () => '',
    },
  },
};
</script>

<style></style>
