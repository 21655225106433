import { mapStyles } from "@/views/cropfields/map.config";

export default {
	methods: {

    /**
     * @description définit les styles pour l'affichage des lieux sur la carte
     * @param {*} feature 
     */
		getLocationStyle(feature) {
			let t = this;
			let st = [
				new ol.style.Style({
          fill: new ol.style.Fill({
            color: this.getLocationColor(feature),
          }),
          stroke: new ol.style.Stroke({
            color: this.getLocationBorderColor(feature),
            width: 2,
            miterLimit: 100,
          }),
          text: new ol.style.Text({
            text: this.displayLocatioName(feature),
            textBaseline: "top",
            overflow: true,
            scale: 1.5,
            fill: new ol.style.Fill({
              color: this.getLocationTextColor(feature),
            }),
            stroke: new ol.style.Stroke({
              color: this.getLocationTextBorderColor(feature),
              width: 2,
              miterLimit: 100,
            }),
          }),
        }),
			];
			return st;
		},

    /**
     * @description définit les styles pour l'affichage du lieu sélectionné sur la carte
     * @param {*} feature 
     */
		getActiveLocationStyle(feature) {
			let t = this;
			let st = [
        new ol.style.Style({
          image: new ol.style.Circle({
            radius: 4,
            fill: new ol.style.Fill({
              color: mapStyles.editGeometry.vertexColor,
            }),
          }),
          geometry: function (feature) {
            var coordinates = [];
            feature
              .getGeometry()
              .getCoordinates()
              .forEach(function (points) {
                coordinates.push(...points);
              });
            return new ol.geom.MultiPoint(coordinates);
          },
        }),     
				new ol.style.Style({
          fill: new ol.style.Fill({
            color: mapStyles.editGeometry.backgroundColor,
          }),
          stroke: new ol.style.Stroke({
            color: mapStyles.editGeometry.edgeColor,
            width: 2,
            miterLimit: 100,
          }),
        }),
        new ol.style.Style({
          text: new ol.style.Text({
            text: this.displayLocatioName(feature),
            textBaseline: "top",
            overflow: true,
            scale: 1.5,
            fill: new ol.style.Fill({
              color: "#FFFFFFFF",
            }),
            stroke: new ol.style.Stroke({
              color: "#000000FF",
              width: 2,
              miterLimit: 100,
            }),
          }),
        }),
			];
			return st;
		},

    /**
     * @description retourne les noms des lieux pour l'affichage sur la carte
     * @param {*} feature 
     */
		displayLocatioName(feature) {
      let properties = feature.getProperties();

      let location = this._locEntries[properties.id];

      if (!location) return;

      let text = location.name;

      return text;
    },

    /**
     * @description définit les styles pour dessiner ou modifier des lieux sur la carte
     * @param {*} feature 
     */
    getDrawStyle(feature, resolution) {
      var area = Math.round(ol.sphere.getArea(feature.getGeometry())) / 10000;
      var text = "";
      if (area > 0) {
        text = area.toFixed(2).toString() + " ha";
      }
      return [
        new ol.style.Style({
          fill: new ol.style.Fill({
            color: "rgba(255, 255, 255, 0.5)",
          }),
          stroke: new ol.style.Stroke({
            color: "rgb(0, 153, 255)",
            width: 3,
          }),
          image: new ol.style.Circle({
            radius: 7,
            fill: new ol.style.Fill({
              color: "rgb(0, 153, 255)",
            }),
            stroke: new ol.style.Stroke({
              color: "#FFFFFFFF",
              width: 1,
            }),
          }),
        }),
        new ol.style.Style({
          text: new ol.style.Text({
            text: text,
            overflow: true,
            scale: 1.5,
            fill: new ol.style.Fill({
              color: "#FFFFFFFF",
            }),
            stroke: new ol.style.Stroke({
              color: "#000000FF",
              width: 2,
              miterLimit: 100,
            }),
          }),
        }),
        new ol.style.Style({
          fill: new ol.style.Fill({
            color: "#FFFFFFFF",
          }),
          stroke: new ol.style.Stroke({
            color: "#FFFFFFFF",
            lineDash: [5, 5],
            width: 2,
          }),
          geometry: function (feature) {
            var coordinates = feature.getGeometry().getCoordinates();
            if (coordinates.length >= 3) {
              return new ol.geom.LineString([coordinates[0], coordinates[coordinates.length - 1]]);
            } else {
              return new ol.geom.LineString([]);
            }
          },
        }),
      ];
    },

    /**
     * @description définit les styles pour l'affichage des parcelles sur la carte
     * @param {*} feature 
     */
    getCropfieldLocationStyle(feature) {
      let t = this;
      let st = [
        new ol.style.Style({
          fill: new ol.style.Fill({
            color: '#5D6D7EBF' ,
          }),
          stroke: new ol.style.Stroke({
            color: this.getCropfieldBorderColor(feature),
            width: 2,
            miterLimit: 100,
          }),
          text: new ol.style.Text({
            text: this.displayFeatureText(feature),
            textBaseline: "top",
            overflow: true,
            scale: 1.5,
            fill: new ol.style.Fill({
              color: this.getCropfieldTextColor(feature),
            }),
            stroke: new ol.style.Stroke({
              color: this.getCropfieldTextBorderColor(feature),
              width: 2,
              miterLimit: 100,
            }),
          }),
        }),
      ]; 
      return st;
    },

    /**
     * @description définit la couleur de bordure des noms des lieux sur la carte
     * @param {*} feature 
     */
    getLocationTextBorderColor(feature) {
      const id = feature.getProperties().id;
      if ((this.currentLocation && this.currentLocation.id == id) && !this.displayActiveLocation)
        return "#00000000";
      return "#000000FF";
    },

    /**
     * @description définit la couleur interne des noms des lieux sur la carte
     * @param {*} feature 
     */
    getLocationTextColor(feature) {
      const id = feature.getProperties().id;
      if ((this.currentLocation && this.currentLocation.id == id) && !this.displayActiveLocation)
        return "#00000000";
      return "#FFFFFFFF";
    },

    /**
     * @description définit la couleur de bordure des lieux sur la carte
     * @param {*} feature 
     */
    getLocationBorderColor(feature) {
      const id = feature.getProperties().id;
      if (this.currentLocation && this.currentLocation.id == id){
        if (!this.displayActiveLocation) return mapStyles.normal.edgeColor;
        return mapStyles.active.edgeColor;
      }

      return mapStyles.normal.edgeColor;
    },

    /**
     * @description définit la couleur interne des lieux sur la carte
     * @param {*} feature 
     */
    getLocationColor(feature) {
      const id = feature.getProperties().id;

      if (this.currentLocation && this.currentLocation.id == id){
        if (!this.displayActiveLocation) return "#00000000";
        return mapStyles.active.backgroundColor;
      }

      const properties = feature.getProperties();
      const location = this._locEntries[properties.id];

      // we add a transparent compound
      if(location.base_color)
        return location.base_color.slice(0, 7) + mapStyles.normal.alpha;
      return "#99FF99BF"
    },

    /**
     * @description définit la couleur interne et la bordure des trous sur un lieu sélectionné
     * @param {*} feature 
     */
    activeLocationHolesLayerStyle(feature) {
      let properties = feature.getProperties();
      let index = properties.index;

      return [
        new ol.style.Style({
          fill: new ol.style.Fill({
            color:
              this.locSelectedHole && index == this.locSelectedHole
                ? mapStyles.normal.holeHighlightBackgroundColor
                : mapStyles.normal.holeBackgroundColor,
          }),
        }),
        new ol.style.Style({
          stroke: new ol.style.Stroke({
            color: mapStyles.normal.EdgeColor,
            width: 2,
          }),
        }),
      ];
    },
	}
}