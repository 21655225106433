import Gateway from "./base";

export default class TourApi {
  /**
   * Get All Tours
   */
  static getTours(data) {
    return Gateway.get("/api/tours/", data);
  }

  /**
   * Get one tour by id
   *
   * @param id
   */
   static getTour(id) {
    return Gateway.get(`/api/tours/${id}/`);
  }

  /**
   * Create tour
   *
   * @param data
   */
   static createTour(data) {
    return Gateway.post(`/api/tours/`, data);
  }

  /**
   * Update tour
   *
   * @param data
   * @param id
   */
  static updateTour(data, id) {
    return Gateway.patch(`/api/tours/${id}/`, data);
  }

  /**
   * delete tour
   *
   * @param id
   */
  static deleteTour(id) {
    return Gateway.delete(`/api/tours/${id}/`);
  }

  /**
   * Get All filters tours
   *
   */
   static getFiltersTours(query, params) {
    return Gateway.get("/api/tours/filtre/", { attrib: query, ...params });
  }

  /**
   * Get tour input form
   *
   * @param activityType
   */
   static getInputForm({ crop, activityType, stockStore }) {
    if (!crop) {
      return Gateway.get(`/api/crops/inputForm/`, { activityType, stockStore, allgrowthstages: true });
    } else {
      return Gateway.get(`/api/crops/${crop}/inputForm/`, { activityType, stockStore, allgrowthstages: true });
    }
  }

  /**
   * Create activities from tour
   * @param {int} id
   * @returns
   */
  static generateActivities(id) {
    return Gateway.get(`/api/tours/${id}/generateActivities/`);
  }
}
