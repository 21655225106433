import Gateway from "./base";

export default class ProductsApi {
  /**
   * Get one product by id
   *
   * @param id
   */
  static getProduct(id) {
    return Gateway.get(`/refapi/products/${id}/`);
  }

  /**
   * Get products by crop type
   *
   * @param param
   */
  static getProductsByCropType(param) {
    return Gateway.get(`/refapi/products/`, param);
  }
}
