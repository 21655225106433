<template>
  <div>
    <b-container fluid class="home-container">
      <b-row class="px-5 mx-5">
        <b-col class="d-flex justify-content-between">
          <h2>
            Bonjour <span>{{ user.first_name }}</span
            >,
          </h2>
          <h2>{{ customer.name }}</h2>
        </b-col>
      </b-row>
      <b-row class="px-5 mx-5 pb-3">
        <b-col cols="8">
          <b-row class="mt-2">
            <!-- <b-col cols="12" md="6">
              <WeatherWidget />
            </b-col> -->
            <b-col cols="6" class="d-none d-md-block">
              <NewsWidget />
            </b-col>
            <b-col cols="6"><NotificationsWidget /></b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="12" class="d-none d-md-block"><GraphsWidget /></b-col>
          </b-row>
        </b-col>
        <b-col cols="4">
          <b-row class="mt-2">
            <b-col cols="12" class="d-none d-md-block"><ActivitiesWidget /></b-col>
          </b-row>
          <b-row class="mt-3" v-if="$userCan('home.show_cropfield_to_confirm')">
            <b-col cols="12" class="d-none d-md-block"><ActivitiesOnUnlistedCropfields /></b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import WeatherWidget from "@/components/home/WeatherWidget";
import ActivitiesWidget from "@/components/home/ActivitiesWidget";
import NewsWidget from "@/components/home/NewsWidget";
import GraphsWidget from "@/components/home/GraphsWidget";
import NotificationsWidget from "@/components/home/NotificationsWidget";
import ActivitiesOnUnlistedCropfields from "@/components/home/ActivitiesOnUnlistedCropfields";
import SplashScreen from "@/components/commons/SplashScreen.vue";
import { EventBus } from '@/eventBus.js';

import HomeModule from "@/store/modules/home";
import NewsModule from "@/store/modules/news";

import { TILE_UPDATE } from "@/store/modules/page-tile";

import { mapGetters } from "vuex";
import NewsApi from "@/api/news.api";

export default {
  computed: {
    ...mapGetters({
      user: "user/user",
      customer: "user/customer",
      upgradeNews: "upgradeNews",
    }),
    username() {
      return this.$cookies.get("username");
    }, 
  },
  created() {
    // register home module in store
    const store = this.$store;
    if (store && store.state) {
      if (!store.state["home"]) {
        store.registerModule("home", HomeModule);
      }
      if (!store.state["news"]) {
        store.registerModule("news", NewsModule);
      }
    }
  },
  mounted() {
    this.setTitle();
    this.splashScreen();
  },
  methods: {
    setTitle() {
      let t = this;
      this.$store.commit(TILE_UPDATE, {
        title: null,
        buttons: [],
      });
    },

  async splashScreen() { 
    const news = this.upgradeNews.filter(n => n.closed === false)
    // Vérifie si le tableau news contient des éléments avant de lancer la modal
    if (news && news.length > 0) {
      EventBus.$emit("showSplashScreen", {
        news: news
      });
    }  
  }
  },
  components: {
    NewsWidget,
    ActivitiesWidget,
    GraphsWidget,
    NotificationsWidget,
    ActivitiesOnUnlistedCropfields,
    SplashScreen
  },
};
</script>

<style lang="scss" scoped>
.home-container {
  margin-top: 4vh;
}

h2 {
  font-size: 1.5em;

  span {
    color: $dark-grey;
  }
}
</style>
