<template>
  <div>
    <!-- Input dosage, unit -->

    <!-- Dose min and max -->
    <div class="" v-if="!this.readOnly">
      <table>
        <!-- Dose min -->
        <tr>
          <td class="shape-first-cell">
            <h4 class="subtitle-form">Dose mini:</h4>
          </td>

          <td style="">
            <NumericInput
              inputRef="input"
              :value="minDoseSelected"
              @input="i => {minDoseSelected = i}"
              class="width-dose-inputs"
            />
          </td>

          <td>
            <select class="width-select-unit-dose" v-model="unitSelected">
              <option v-for="unity in inputFormByPtype.doseUnits" v-bind:key="unity.id" :value="unity.id">{{
                unity.unit
              }}</option>
            </select>
          </td>
        </tr>

        <!-- Dose max -->
        <tr>
          <td class="shape-first-cell">
            <h4 class="subtitle-form">Dose maxi:</h4>
          </td>

          <td>
            <NumericInput
              inputRef="input"
              :value="maxDoseSelected"
              @input="i => {maxDoseSelected = i}"
              class="width-dose-inputs"
            />
          </td>

          <td>
            <select class="width-select-unit-dose" v-model="unitSelected">
              <option v-for="unity in inputFormByPtype.doseUnits" v-bind:key="unity.id" :value="unity.id">{{
                unity.unit
              }}</option>
            </select>
          </td>
        </tr>
      </table>
    </div>

    <Dose v-else />
  </div>
</template>

<script>
import delIcon from "@/assets/images/delete.svg";
import Dose from "@/components/inputstock/details/Dose.vue";
// import { GET_UNITS } from "@/store/modules/productType";
import { mapGetters } from "vuex";
import NumericInput from "@/components/commons/NumericInput.vue";
export default {
  name: "crop-and-dose",
  async created() {
    // this.globalUnit = this.inputFormByPtype.doseUnits;

    if (this.$route.name === "inputStock.edit") {
      if (this.currentInputStock.hasOwnProperty("Product_local")) {
        this.minDoseSelected = this.currentLocalProduct.minDoseValue;
        this.maxDoseSelected = this.currentLocalProduct.maxDoseValue;
        this.unitSelected = this.currentLocalProduct.doseUnit;
      } else {
        this.minDoseSelected = this.currentInputStock.minDoseValue;
        this.maxDoseSelected = this.currentInputStock.maxDoseValue;
        this.unitSelected = this.currentInputStock.doseUnit;
      }
    } else {
      this.minDoseSelected = "";
      this.maxDoseSelected = "";
      this.unitSelected = "";

      this.unitSelected = this.inputFormByPtype.doseUnits[0].id;
    }
  },
  data() {
    return {
      icon: delIcon,
      unitSelected: "",
      minDoseSelected: "",
      maxDoseSelected: "",
      globalUnit: [],
    };
  },
  components: {
    Dose,
    NumericInput,
  },
  methods: {
    /**
     * Description: Renvoie un objet contenant le(s) type(s) de culture et le dosage.
     * @returns {Object}  Renvoie un objet contenant le(s) type(s) de culture et le dosage.
     */
    getCropAndDose() {
      return {
        unit: this.unitSelected,
        min: this.minDoseSelected,
        max: this.maxDoseSelected,
      };
    },

    unitsAlreadyLoaded(id) {
      let units = this.allUnits.filter(function(elem) {
        if (elem.id == id) return elem;
      });

      if (units.length > 0) return true;
      return false;
    },
  },
  computed: {
    ...mapGetters({
      currentInputStock: "inputStock/currentEntry",
      readOnly: "readOnly",
      currentProduct: "currentProduct",
    }),
    ...mapGetters(["inputFormByPtype"]),
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
/**
 * Span params.
 */
.span-params {
  // margin-left: 2px;
  margin-right: 80px;
  color: $subtitle-color;
  font-size: 12px;
  font-weight: normal;
}
/**
 * Margin for table div.
 */
.margin-table-div {
  margin-top: 4px;
}
/**
 * Width for the inputStock inputs .
 */
.width-product-inputs {
  border-radius: 4px;
  width: 30%;
  margin-right: 5px;
  height: 25px;
}

/**
 * Width for the density inputs .
 */
.width-dose-inputs {
  border-radius: 4px;
  padding-left: 3px;
  width: 97px;
  margin-right: 0px;
}

.shape-first-cell {
  width: 100px;
}

.width-select-unit-dose {
  border-radius: 4px;
  font-size: 14px;
}

button {
  font-size: 0.8em;
  font-weight: bold;
  img {
    width: 1.5em;
  }
}
</style>
