<template>
  <div class="d-flex">
    <div class="d-flex flex-column justify-content-center">
      <div class="font-14 position-relative" v-if="rowData.width">
        {{ rowData.width }} {{ getUnitText(rowData.widthUnit) }}
      </div>
    </div>
  </div>
</template>

<script>
import { i18n } from '../../../i18n';

export default {
  props: ["rowId", "rowData"],
  data() {
    return{
      units: [
        { value: "m", text: i18n.t("unitDenomination.unit_m") },
        { value: "rg", text: i18n.t("unitDenomination.unit_rg") },
      ],
    }
  },
  methods: {
    getUnitText(value) {
      let text = ""
      this.units.map(unit => {
        if(unit.value == value)
          text = unit.text;
      });
      return text;
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
