import NonTreatedZoneApi from "@/api/nonTreatedZone.api";
import Vue from "vue";

export const ALL_NON_TREATED_ZONE_TYPES = "GET ALL NON TREATED ZONE TYPES";
export const GET_NON_TREATED_ZONE_WIDTHS = "GET NON TREATED ZONE WIDTHS";
export const GET_NON_TREATED_ZONES = "GET NON TREATED ZONES";
export const NON_TREATED_ZONES_LOADING = "LOAD NON TREATED ZONES";

export default {
  state: {
    nonTreatedZones: [],
    nonTreatedZoneTypes: [],
    nonTreatedZoneWidths: [],
    nonTreatedZone_loaded: true,
  },
  getters: {
    nonTreatedZones: state => state.nonTreatedZones,
    nonTreatedZoneTypes: state => state.nonTreatedZoneTypes,
    nonTreatedZoneWidths: state => state.nonTreatedZoneWidths,
    nonTreatedZone_loaded: state => state.nonTreatedZone_loaded,
  },
  mutations: {
    [ALL_NON_TREATED_ZONE_TYPES]: (state, nonTreatedZoneTypes) => {
      state.nonTreatedZoneTypes = nonTreatedZoneTypes;
    },

    [GET_NON_TREATED_ZONE_WIDTHS]: (state, nonTreatedZoneWidths) => {
    	state.nonTreatedZoneWidths = nonTreatedZoneWidths;
    },
    
    [GET_NON_TREATED_ZONES]: (state, nonTreatedZones) => {
    	state.nonTreatedZones = nonTreatedZones;
    },

    [NON_TREATED_ZONES_LOADING]: (state, status) => {
      state.nonTreatedZone_loaded = !status;
    },

  },
  actions: {
    async getNonTreatedZones({ commit }, {cropField}) {
      commit(NON_TREATED_ZONES_LOADING, true);
      const response = await NonTreatedZoneApi.getNonTreatedZones(cropField);
      commit(GET_NON_TREATED_ZONES, response.data);
      commit(NON_TREATED_ZONES_LOADING, false);
      return response.data;
    },
    async getNonTreatedZoneTypes({ commit }, filters) {
      commit(NON_TREATED_ZONES_LOADING, true);
      const response = await NonTreatedZoneApi.getNonTreatedZoneTypes(filters);
      commit(ALL_NON_TREATED_ZONE_TYPES, response.data);
      commit(NON_TREATED_ZONES_LOADING, false);
      return response.data;
    },
    async getNonTreatedZoneWidths({ commit }, { typeCode }) {
      commit(NON_TREATED_ZONES_LOADING, true);
      let filters = {nonTreatedzoneType__code: typeCode}
      const response = await NonTreatedZoneApi.getNonTreatedZoneWidths(filters);
      commit(GET_NON_TREATED_ZONE_WIDTHS, response.data);
      commit(NON_TREATED_ZONES_LOADING, false);
      return response.data;
    },
    async createNonTreatedZone({ commit }, { cropField, data } ) {
      commit(NON_TREATED_ZONES_LOADING, true);
      const response = await NonTreatedZoneApi.createNonTreatedZone(cropField, data);
      commit(NON_TREATED_ZONES_LOADING, false);
      return response;
    },
    
  },
};
