export class Button {
  action = () => {};

  constructor({ text, action, icon, isSmall }) {
    this.text = text || "";
    this.icon = icon;
    if (action) this.action = action;
    this.isSmall = isSmall || false;
  }
}
