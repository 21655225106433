import { DateTime } from "luxon";

import { i18n } from "@/i18n.js";

export const config = {
  title: i18n.t("cropfields.title"),
  // le nom marqué dans le comptage des groupe : XX intrants
  countingElements: i18n.t("cropfields.list.countingElements"),
  // le module du store
  module: "fertilization",
  scrollToSelection: true,
  // sur quel champs faire la recherche
  searchField: "name",
  // les principales routes
  routes: {
    index: {
      name: "fertilization",
    },
    list: {
      name: "fertilization.list",
    },
    new: {
      name: "fertilization.new",
    },
    details: {
      name: "fertilization.details",
    },
    params: {
      id: "fertilization_id",
    },
  },
  // sur quelle colonne groupe-t-on par défaut ?
  defaultPivot: 2,
  // la liste des colonnes
  checkbox: true,

  rowSort: {
    key: "name",
    type: "string",
    order: 1, // 1 si ordre croissant, -1 sinon
  },

  columns: {
    0: {
      name: "name",
      sort: true,
      title: i18n.t("cropfields.list.name"),
      pivot: {
        enable: true,
        groupDisplay: {},
        displayHeader: false,
      },
      show: true,
      orderByKey: "name",
      priority: 1,
    },
    1: {
      name: "farm_name",
      sort: true,
      title: i18n.t("commons.list.farm"),
      breakpoint: "xl",
      pivot: {
        enable: true,
        groupDisplay: {},
        displayHeader: true,
      },
      show: true,
      priority: 3,
      showColumn: function({ $userCan, store }) {
        if (store.getters["farm/currentFarm"])
          return false;
        if (store.getters["farm/farms"].length > 1)
          return true;
        return false;
      }
    },
    2: {
      name: "crop_name",
      title: i18n.t("commons.list.crop_name"),
      sort: true,
      pivot: {
        enable: true,
        groupDisplay: {},
        displayHeader: true,
      },
      show: true,
      priority: 2,
    },
    3: {
      name: "surface",
      title: i18n.t("cropfields.list.surface"),
      sort: false,
      pivot: {
        enable: false,
        groupDisplay: {},
        displayHeader: false,
      },
      show: true,
      priority: 8,
    },
    4: {
      name: "soilType_designation",
      title: i18n.t("cropfields.list.soilType"),
      pivot: {
        enable: false,
        groupDisplay: {},
        displayHeader: false,
      },
      show: true,
      priority: 6,
    },
    5: {
      name: "soilDepth_designation",
      title: i18n.t("cropfields.list.soilDepth"),
      breakpoint: "md",
      pivot: {
        enable: false,
        groupDisplay: {},
        displayHeader: false,
      },
      show: true,
      priority: 5,
    },
    6: {
      name: "previousCrop_name",
      title: i18n.t("commons.list.previous_crop"),
      sort: true,
      pivot: {
        enable: true,
        groupDisplay: {},
        displayHeader: true,
      },
      show: true,
      priority: 4,
    },
    7: {
      name: "status",
      sort: false,
      title: i18n.t("commons.list.status"),
      pivot: {
        enable: false,
        groupDisplay: {},
        displayHeader: false,
      },
      show: true,
      priority: 7,
      cellComponent: "FertilizationPlanStatus",
    },
  },
  // les filtres
  filters: [
    {
      name: "status",
      title: i18n.t("commons.filter.status"),
      show: false,
    },
    {
      name: "crop",
      title: i18n.t("commons.filter.crop_name"),
      show: false,
    },
    {
      name: "farm",
      title: i18n.t("commons.filter.farm"),
      show: false,
    },
    {
      name: "previousCrop",
      title: i18n.t("commons.filter.previous_crop"),
      show: false,
    },
  ],
};
