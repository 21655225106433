import Gateway from "./base";

export default class NotificationApi {
  /**
   * Get all notifications
   *
   */
  static getNotifications(params = {}) {
    return Gateway.get("/api/alerts/", params);
  }

  /**
   * Update notification
   *
   * @param data
   * @param id
   */
  static updateNotification(id, data) {
    return Gateway.patch(`/api/alerts/${id}/`, data);
  }
}
