import Gateway from "./base";

export default class SoilAnalyzesApi {
  /**
   * Get All soil analyzes
   */
  static getSoilAnalyzes() {
    return Gateway.get("/api/soilAnalyzes/");
  }

  /**
   * Get soil analysis by cropfield
   * 
   * @param params
   */
  static getSoilAnalysisByCropfield(params) {
    return Gateway.get("/api/soilAnalyzes/", params);
  }

  /**
   * Update cultural process
   *
   * @param data
   * @param id
   */
  static updateSoilAnalyzes(data, id) {
    return Gateway.patch(`/api/soilAnalyzes/${id}/`, data);
  }

  /**
   * delete cultural process
   *
   * @param id
   */
  static deleteSoilAnalyzes(id) {
    return Gateway.delete(`/api/soilAnalyzes/${id}/`);
  }

  /**
   * Create soil analyzes
   *
   * @param data
   */
  static createSoilAnalyzes(data) {
    return Gateway.post(`/api/soilAnalyzes/`, data);
  }
}
