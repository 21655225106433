import SeedTreatmentApi from "@/api/seedTreatment.api.js";

export const SET_SEED_TREATMENT = "SET SEED TREATMENT";

export default {
    state: {
        seedTreatments: [],
    },
    getters: {
        seedTreatments: state => state.seedTreatments,
    },
    mutations: {
        updateSeedTreatments: (state, payload) => {
            state.seedTreatments = payload;
        }
    },
    actions: {
        async getSeedTreatments({commit}, payload) {
            
            const response = await SeedTreatmentApi.getSeedTreatments(payload);
            commit('updateSeedTreatments', response.data);
            return response.data;
        }
    },
};