import { i18n } from "@/i18n.js";

export const config = {
  title: i18n.t("inputStock.title"),
  // le nom marqué dans le comptage des groupe : XX intrants
  countingElements: i18n.t("inputStock.list.countingElements"),
  // "Ajouter un intrant"
  addButton: i18n.t("inputStock.add.title.generic"),
  // le module du store
  module: "inputStock",
  scrollToSelection: true,
  // sur quel champs faire la recherche
  searchField: "name",
  // les principales routes
  routes: {
    index: {
      name: "inputStock",
    },
    list: {
      name: "inputStock.list",
    },
    new: {
      name: "inputStock.new",
    },
    details: {
      name: "inputStock.details",
    },
    params: {
      id: "inputStock_id",
    },
  },
  // sur quelle colonne groupe-t-on par défaut ?
  defaultPivot: 1,
  // la liste des colonnes
  columns: {
    0: {
      name: "name",
      width: 10,
      sort: true,
      title: i18n.t("inputStock.list.name"),
      isAuthorized: false,
      isAvailable: false,
      hideOnDetails: false,
      // les infos sur le groupe
      pivot: {
        enable: true,
        groupDisplay: {},
        displayHeader: false,
        // cas particulier sur cette colonne
        // car on veut simplement lister toutes les lignes sans groupe
      },
      show: true,
      priority: 1,
      orderByKey: "name",
    },
    1: {
      name: "productType_designation",
      width: 8,
      sort: true,
      title: i18n.t("inputStock.list.product_type"),
      isAuthorized: false,
      isAvailable: false,
      hideOnDetails: false,
      pivot: {
        enable: true,
        sortHeaderField: [group => group.name.toLowerCase()],
        sortHeaderOrder: ["asc"],
        sortDataFields: [inputstock => inputstock.name.toLowerCase()],
        sortDataOrders: ["asc"],
        groupDisplay: {},
        displayHeader: true,
      },
      show: true,
      priority: 1,
      orderByKey: "productType",
    },
    2: {
      name: "authorized",
      width: 7,
      sort: false,
      title: i18n.t("inputStock.list.permission"),
      // permet de donner un dictionnaire pour contrôler l'affichage
      // true -> "Autorisé"
      // false -> "Retiré"
      cellDisplay: {
        true: i18n.t("inputStock.list.values.permission.authorized"),
        false: i18n.t("inputStock.list.values.permission.removed"),
      },
      // permet d'ajouter une classe CSS pour une valeur particulière
      cellDisplayClasses: {
        false: "cell-warning",
      },
      isAuthorized: true,
      isAvailable: false,
      hideOnDetails: false,
      pivot: {
        enable: false,
        // similaire au cellDisplay
        groupDisplay: {
          true: i18n.t("inputStock.list.values.permission.authorized"),
          false: i18n.t("inputStock.list.values.permission.removed"),
          // valeur par défaut
          _DEFAULT: i18n.t("inputStock.list.values.permission.empty"),
        },
        displayHeader: true,
      },
      show: true,
      priority: 3,
    },
    3: {
      name: "stockStore_name",
      width: 8,
      sort: false,
      title: i18n.t("inputStock.details.stock_store"),
      isAuthorized: false,
      isAvailable: false,
      hideOnDetails: false,
      pivot: {
        enable: false,
        sortHeaderField: [group => group.name.toLowerCase()],
        sortHeaderOrder: ["asc"],
        sortDataFields: [inputstock => inputstock.name.toLowerCase()],
        sortDataOrders: ["asc"],
        groupDisplay: {},
        displayHeader: true,
      },
      show: true,
      priority: 2,
      orderByKey: "productType",
      showColumn: function({ $userCan, store }) {
        return $userCan('inputstock.stockStore')
      }
    },
    4: {
      name: "available",
      width: 7,
      sort: true,
      title: i18n.t("inputStock.list.availability"),
      isAuthorized: false,
      isAvailable: true,
      hideOnDetails: false,
      // on indique quel composant on veut pour gérer l'affichage
      // plus poussé que le cellDisplay
      // ne pas oublier d'importer le composant dans <CommonViewList/>
      cellComponent: "Toggle",
      pivot: {
        enable: true,
        groupDisplay: {
          true: i18n.t("inputStock.list.values.availability.available"),
          false: i18n.t("inputStock.list.values.availability.unavailable"),
        },
        displayHeader: true,
      },
      show: true,
      priority: 4,
      orderByKey: "available",
    },
    5: {
      name: "lastSupplyDate",
      width: 10,
      sort: false,
      title: i18n.t("inputStock.list.last_supply"),
      isAuthorized: false,
      isAvailable: false,
      hideOnDetails: false,
      cellComponent: "SupplyCell",
      pivot: {
        enable: false,
        sortHeaderField: [group => group.name.toLowerCase()],
        sortHeaderOrder: ["asc"],
        sortDataFields: [inputstock => inputstock.name.toLowerCase()],
        sortDataOrders: ["asc"],
        groupDisplay: {},
        displayHeader: true,
      },
      show: true,
      priority: 3,
      orderByKey: "productType",
      showColumn: function({ $userCan, store }) {
        return $userCan('inputstock.supplies')
      }
    },
  },
  // les filtres
  filters: [
    {
      name: "productFamily",
      title: i18n.t("inputStock.filter.product_family"),
      show: false,
    },
    {
      name: "productType",
      title: i18n.t("inputStock.filter.product_type"),
      show: false,
    },
    {
      name: "available",
      title: i18n.t("inputStock.filter.availability"),
      show: false,
    },
  ],
};
