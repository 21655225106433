import Vue from "vue";
import { i18n } from "@/i18n.js";
import { DateTime } from "luxon";
import { isNumber, round, toInteger } from "lodash";

export function appendName(name, i = 1) {
  // add +1 at the end of the new name
  let lastLetter = name.slice(-1);
  lastLetter = parseInt(lastLetter);
  let counter = i;
  if (!isNaN(lastLetter)) {
    counter = lastLetter + 1;
    name = name.slice(0, -1);
  } else {
    name = name + "_";
  }
  return name + counter;
}

export function formatServerUrl(url) {
  if (process.env.NODE_ENV !== "production") {
    return `${process.env.API_URI}${url}`;
  }
  return url;
}

export function addDuration({ startDate, minutes }) {
  startDate = new Date(startDate);
  return new Date(startDate.getTime() + minutes * 60000);
}

export function intervalMinutes({ startDate, endDate, secondsAdded=0 }) {
  startDate = new Date(startDate);
  endDate = new Date(endDate);
  var diff = (endDate.getTime() - startDate.getTime()) / 1000 + secondsAdded;
  diff /= 60;
  return Math.abs(Math.round(diff));
}

export function dateToDashString(date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return day + "-" + month + "-" + year;
}

export function dateToSlashString(date) {
  const result = date.toISOString();
  const year = result.slice(0,4);
  const month = result.slice(5,7);
  const day = result.slice(8,10);
  return day + "/" + month + "/" + year;
}

export function dateToEnglishDashString(date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return year + "-" + ("0" + month).slice(-2) + "-" + ("0" + day).slice(-2);
}

export function dateToISOString(date) {
  return new DateTime.fromJSDate(date).toISODate();
}

export function textToLocaleShortDateString(text) {
  return new Date(text).toLocaleDateString();
}

export function textToLocaleShortDateTimeString(text) {
  return new Date(text).toLocaleDateString() + " " + Date(text).toLocaleTimeString().slice(0, 5);
}

export function isString(object) {
  return typeof object === "string" || object instanceof String;
}

export async function askConfirm(message) {
  try {
    await Vue.confirm(message, null, null, {
      confirmButtonText: i18n.t("yes"),
      cancelButtonText: i18n.t("non"),
    });
    return true;
  } catch {
    return false;
  }
}

// from https://codepen.io/rebosante/pen/eENYBv
export function scrollTo(element, to, duration) {
  var start = element.scrollTop,
    change = to - start,
    currentTime = 0,
    increment = 20;

  var animateScroll = function () {
    currentTime += increment;
    var val = Math.easeInOutQuad(currentTime, start, change, duration);
    element.scrollTop = val;
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
}

//t = current time
//b = start value
//c = change in value
//d = duration
Math.easeInOutQuad = function (t, b, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

// allow zero padding before number
// for example, for value = 2 and digitLength = 4, we obtain 0002
export function zeroPad(value, digitLength) {
  return String(value).padStart(digitLength, "0");
}

// check if a cookie exists and has a correct value
export function cookieIsDefined(key) {
  // if key not exist, return
  if (!Vue.$cookies.isKey(key)) return false;

  // check value is defined
  let value = Vue.$cookies.get(key);
  return isDefined(value);
}

export function isDefined(value) {
  return !["null", "undefined", undefined, null].includes(value);
}

export function removeDuplicates(array, { ignoreType }) {
  // if we want to ignore the type (number/string), convert all values as string
  if (ignoreType) array = array.map(String);
  return array.filter((a, b) => array.indexOf(a) === b);
}

var hash = require('object-hash');
export function fingerprint(payload) {
  return hash(payload)
}

/*
 * Evaluate chain path in object
 *
 * From https://github.com/vuejs/vue/issues/11088#issuecomment-870054256
 *
 * Where to use: Use in vue templates to determine deeply nested undefined/null values
 * How to use: Instead of writing parent?.child?.child2 you can write
 *            isAvailable(parent, 'child.child2')
 * @author    Smit Patel
 * @params    {Object} parent
 *            {String} child
 * @return    {Boolean}     True if all the nested properties exist
 */
export function isAvailable(parent, child) {
  try {
    const childArray = String(child).split('.');
    let evaluted = parent;
    childArray.forEach((x) => {
      evaluted = evaluted[x];
    });
    return !!evaluted;
  } catch {
    return false;
  }
}

// Vérifie que la date est dans le format yyyy-mm-dd
// Utile pour Safari sur MAC
/**
 * Vérifie que la date est dans le format yyyy-mm-dd
 * Utile pour Safari sur MAC
 * @param {string} date
 * @returns
 */
export function checkDatePattern(date) {
  var date_regex = /^2[0-9]{3}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
  if (!(date_regex.test(date)) && date != "" && date != null) {
    return false;
  }else{
    return true;
  }
}

/**
 * Vérifie que le temps est dans le format hh:mm
 * Utile pour Safari sur MAC
 * @param {string} time
 * @returns
 */
export function checkTimePattern(time) {
  var time_regex = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/
  if (!(time_regex.test(time)) && time != "" && time != null) {
    return false;
  }else{
    return true;
  }
}

export function formatDate(date, opt = "", separator="", format=""){
      if (opt == "time" && date != null) {
          let dateTime = new Date(date);
          let hours = (parseFloat(dateTime.getHours())<=9) ? "0"+dateTime.getHours() : dateTime.getHours();
          let mins = (parseFloat(dateTime.getMinutes())<=9) ? "0"+dateTime.getMinutes() : dateTime.getMinutes();
          return mins == "0" ? hours+":"+mins+"0" : hours+":"+mins;
      }
      else if(opt == "date"){
          if(separator == "-" ){
              var d = new Date(date),
              month = "" + (d.getMonth() + 1),
              day = "" + d.getDate(),
              year = d.getFullYear();
              if (month.length < 2) month = "0" + month;
              if (day.length < 2) day = "0" + day;
              return [year, month, day].join("-");
          }
          else if(separator == "/" ){
              if(format == "mm-dd-yyyy"){
                  var dateToFormat = new Date(date);
                  var dd = String(dateToFormat.getDate()).padStart(2, '0');
                  var mm = String(dateToFormat.getMonth() + 1).padStart(2, '0');
                  var yyyy = dateToFormat.getFullYear();
                  dateToFormat = mm + '/' + dd + '/' + yyyy;
                  return(dateToFormat);
              }
              var d = new Date(date),
              month = "" + (d.getMonth() + 1),
              day = "" + d.getDate(),
              year = d.getFullYear();
              year = year.toString().substring(year.toString().length - 2);
              if (month.length < 2) month = "0" + month;
              if (day.length < 2) day = "0" + day;
              return day + '/' + month + '/' + year;
          }
          return new Date(date).toLocaleDateString();
  }
}

/**
 * Prend un nombre de secondes et le met sous le format xxhxxmxxs
 * @param {int} seconds
 */
export function secondsToHMS(seconds) {
  let hour = 0;
  let minute = 0;
  let second = seconds;
  if (second >= 60) {
    minute = parseInt(seconds/60);
    second = second - minute*60;
  }
  if (minute >= 60) {
    hour = parseInt(minute/60);
    minute = minute - hour*60;
  }
  let returnString = ""
  if (hour > 0) {
    returnString += hour.toString() + "h"
  }
  if (minute > 0) {
    returnString += minute.toString() + "m"
  }
  if (second >= 0) {
    returnString += second.toString() + "s"
  }
  return returnString
}

export function addDayPlus({ startDateStr, endDateStr, dayPlus }) {
    
  // Convertir les chaînes en objets Date
  let startDate = new Date(startDateStr);
  let endDate = new Date(endDateStr);

  if (isNaN(startDate) || isNaN(endDate)) {
    console.error("Invalid Date(s) provided");
    return;
  }

  // Ajouter le nombre de jours
  startDate.setDate(startDate.getDate() + dayPlus);
  endDate.setDate(endDate.getDate() + dayPlus);

  // Retourner les nouvelles dates en format ISO
  return {
    newStartDate: startDate.toISOString(),
    newEndDate: endDateStr? endDate.toISOString() : null
  };
}

export function changedStartDate({startDateStr, endDateStr, newStartDateStr}) {
  // Convertir les chaînes en objets Date
  let startDate = new Date(startDateStr);
  let endDate = new Date(endDateStr);

  if (isNaN(startDate) || isNaN(endDate)) {
    console.error("Invalid Date(s) provided");
    return;
  }

  // Extraire les heures, minutes, secondes et millisecondes de startDate
  const hours = startDate.getUTCHours();
  const minutes = startDate.getUTCMinutes();
  const seconds = startDate.getUTCSeconds();
  const milliseconds = startDate.getUTCMilliseconds();

  // Créer newStartDate à partir de newStartDateStr et appliquer les heures originales
  let newStartDate = new Date(newStartDateStr + "T00:00:00.000Z");
  newStartDate.setUTCHours(hours, minutes, seconds, milliseconds);

  // Calculer la différence en millisecondes
  let difference = endDate - startDate;

  // Définir le nouveau endDate
  let newEndDate = new Date(newStartDate.getTime() + difference);

  // Retourner les nouvelles dates en format ISO
  return {
    newStartDate: newStartDate.toISOString(),
    newEndDate: endDateStr? newEndDate.toISOString() : null
  };
}

export function mergeNews(originalNews, newNews) {
  const newsMap = new Map();
  originalNews.forEach(item => newsMap.set(item.id, item));
  newNews.forEach(item => newsMap.set(item.id, item));

  return Array.from(newsMap.values());
}

/**
 * Prend un nombre et l'arroundi au nombre de chiffres sinificatifs 
 */
export function fixDigits(value, numberOfDigits) {
  if(!isNumber(value) || value == 0) return value
  let coef = round(Math.pow(10, numberOfDigits - Math.floor(Math.log10(value)) - 1), 10)
  const res = round(value * coef, 0) / coef
  return res
}

/**
  * Cette fonction convertit un nombre en chaine formattée dans la langue courante
  */
export function formatNumber(value, numberOfDigits) {
  const locale = navigator.language || 'en-US';
  if (value === '' || isNaN(value)) return '';
  const number = parseFloat(value);
  return new Intl.NumberFormat(locale, { maximumFractionDigits: numberOfDigits }).format(number);
}

/**
  * Cette fonction permet de récupérer le nombre de pixel d'un rem
  */
export function getRemInPixels() {
  return parseFloat(getComputedStyle(document.documentElement).fontSize);
}
