import Gateway from "./base";

export default class LocationTypesApi {
  /**
   * Get All Location Types
   */
  static getAllLocationTypes() {
    return Gateway.get("/refapi/locationTypes/");
  }

  /**
   * Get Location Types which can Own geometry
   */
  static getLocationTypesWithGeometry() {
    return Gateway.get("/refapi/locationTypes/", {canOwnGeometry: true})
  }

    /**
   * Get Location Types which can Own beacon
   */
  static getLocationTypesWithBeacon() {
    return Gateway.get("/refapi/locationTypes/", {canOwnBeacon: true})
  }

  /**
   * Get one Location Type by id
   * @param id
   */
  static getOneLocationType(id) {
    return Gateway.get(`/refapi/locationTypes/${id}/`);
  }

  /**
   * Get location type by code
   * @param code
   */
  static getLocationTypeByCode(code) {
    return Gateway.get("/refapi/locationTypes/", {code: code});
  }

}