import LocationTypeApi from "@/api/locationType.api";
import { commonMutations } from "./commons.js";

export const ALL_LOCATIONTYPES = "GET ALL LOCATIONTYPES";
export const LOCATIONTYPES_WITH_GEOMETRY = "GET LOCATION tYPES WHICH CAN OWN GEOMETRY";
export const LOCATIONtYPES_WITH_BEACON = "GET LOCATION TYPES WHICH CAN OWN BEACON";
export const ONE_LOCATIONTYPE = "GET ONE LOCATIONTYPE";
export const LOCATIONTYPE_LOADING = "LOAD LOCATIONTYPE";
export const LOCATIONTYPES_LOADING = "LOAD LOCATIONTYPES";

export default {
  state: {
    locationTypes: [],
    locationTypesWithGeometry: [],
    locationTypesWithBeacon: [],
    currentLocationType: {},
    locationType_loaded: true,
    locationTypes_loaded: true,
  },
  getters: {
    currentLocationType: state => state.currentLocationType,
    locationTypes: state => state.locationTypes,
    locationTypesWithGeometry: state => state.locationTypesWithGeometry,
    locationTypesWithBeacon: state => state.locationTypesWithBeacon,
    locationTypeLoaded: state => state.locationType_loaded,
    locationTypesLoaded: state => state.locationTypes_loaded
  },
  mutations: {
    ...commonMutations,
    [ALL_LOCATIONTYPES]: (state, locationTypes) => {
      state.locationTypes = locationTypes;
    },

    [LOCATIONTYPES_WITH_GEOMETRY]: (state, locationTypes) => {
      state.locationTypesWithGeometry = locationTypes;
    },

    [LOCATIONtYPES_WITH_BEACON]: (state, locationTypes) => {
      state.locationTypesWithBeacon = locationTypes;
    },

    [ONE_LOCATIONTYPE]: (state, locationType) => {
      state.currentLocationType = locationType;
    },

    [LOCATIONTYPE_LOADING]: (state, status) => {
      state.locationType_loaded = !status;
    },

    [LOCATIONTYPES_LOADING]: (state, status) => {
      state.locationTypes_loaded = !status;
    },
  },
  actions: {
    async getAllLocationTypes({ commit }) {
      commit(LOCATIONTYPES_LOADING, true);
      const response = await LocationTypeApi.getAllLocationTypes();
      commit(ALL_LOCATIONTYPES, response.data);
      commit(LOCATIONTYPES_LOADING, false);
      return response.data;
    },
    async getLocationTypesWithGeometry({commit}) {
      commit(LOCATIONTYPES_LOADING, true);
      const response = await LocationTypeApi.getLocationTypesWithGeometry();
      commit(LOCATIONTYPES_WITH_GEOMETRY, response.data);
      commit(LOCATIONTYPES_LOADING, false);
      return response.data;
    },
    async getLocationTypesWithBeacon({commit}) {
      commit(LOCATIONTYPES_LOADING, true);
      const response = await LocationTypeApi.getLocationTypesWithBeacon();
      commit(LOCATIONtYPES_WITH_BEACON, response.data);
      commit(LOCATIONTYPES_LOADING, false);
      return response.data;
    },
    async getOneLocationType({ commit }, payload) {
      commit(LOCATIONTYPE_LOADING, true);
      const response = await LocationTypeApi.getOneLocationType(payload.id);
      commit(ONE_LOCATIONTYPE, response.data);
      commit(LOCATIONTYPE_LOADING, false);
      return response.data;
    },
    async resetLocationType({commit}) {
      commit(LOCATIONTYPE_LOADING, true);
      commit(ONE_LOCATIONTYPE, null);
      commit(LOCATIONTYPE_LOADING, false);
    }
  },
};
