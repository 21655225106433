import CommonViewIndex from "@/views/common/CommonViewIndex.vue";
import CommonViewList from "@/views/common/CommonViewList.vue";
import CommonViewFiltering from "@/views/common/CommonViewFiltering.vue";
import CommonViewDetail from "@/views/common/CommonViewDetail.vue";
import { generateDetailViews } from "@/views/common/details/commons";
import { views } from "@/views/activity/details/index";
import ActivityExportIndex from "@/views/activity/import-export/ActivityExportIndex.vue";
import ActivityExportIsoXml from "@/views/activity/import-export/ActivityExportIsoXml.vue";
import ActivityExportActivitiesMachines from "@/views/activity/import-export/ActivityExportActivitiesMachines.vue";
import ActivityExportActivitiesInputs from "@/views/activity/import-export/ActivityExportActivitiesInputs.vue";
import ActivityReportsIndex from "@/views/activity/reports/ActivityReportsIndex.vue";
import ActivityReportInterventionsBilling from "@/views/activity/reports/ActivityReportInterventionsBilling.vue";
import ActivityReportInterventionsPhyto from "@/views/activity/reports/ActivityReportInterventionsPhyto.vue";

import { config } from "./config";

export default [
  {
    component: CommonViewIndex,
    path: "/activity",
    props: config,
    children: [
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
        },
        path: "",
        name: "activity.list",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: ActivityExportIndex,
        },
        path: "export",
        name: "activity.export",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: ActivityExportIsoXml,
        },
        path: "export-iso-xml",
        name: "activity.export.iso-xml",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: ActivityExportActivitiesMachines,
        },
        path: "export-activities-machines",
        name: "activity.export.activities-machines",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: ActivityExportActivitiesInputs,
        },
        path: "export-activities-inputs",
        name: "activity.export.activities-inputs",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: ActivityReportsIndex,
        },
        path: "reports",
        name: "activity.reports",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: ActivityReportInterventionsBilling,
        },
        path: "report-interventions-billing",
        name: "activity.reports.interventions-billing",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: ActivityReportInterventionsPhyto,
        },
        path: "report-interventions-phyto",
        name: "activity.reports.interventions-phyto",
      },      
      ...generateDetailViews(views, CommonViewList, CommonViewFiltering, CommonViewDetail),
    ],
  },
];
