import { EventBus } from "@/eventBus.js";
import { geomXContainsGeomY } from "../../../utils/geomatics";

export default {
	data() {
		return {
			freehandLayer: null,
			selectedObjects: [],
			freehandInteraction: null
		}
	},

	methods: {
		/**
		 * Active le dessin à main levée sur la carte lorsqu'on maintient la touche "shift" du clavier
		 */
		createFreehandInteraction() {
			this.freehandInteraction = new ol.interaction.Draw({
				source: this.freehandLayer.getSource(),
				type: "Polygon",
				condition: ol.events.condition.shiftKeyOnly
			});
			let t = this;
			// On ajoute à la tournée toutes les parcelles étant dans le polygone dessiné
			this.freehandInteraction.on("drawend", function(e) {
				let drawnPolygon = e.feature.getGeometry();
				t.layerSource.getFeatures().forEach(f => {
					const geometry = f.getGeometry();
					if (geomXContainsGeomY(drawnPolygon, geometry)) {
						EventBus.$emit("onMapCheck", f.getProperties().id, true);
					}
				})
				t.firstCropsLayer.getSource().getFeatures().forEach(f => {
					const geometry = f.getGeometry();
					if (geomXContainsGeomY(drawnPolygon, geometry)) {
						EventBus.$emit("onMapCheck", f.getProperties().id, true);
					}
				})
				t.secondCropsLayer.getSource().getFeatures().forEach(f => {
					const geometry = f.getGeometry();
					if (geomXContainsGeomY(drawnPolygon, geometry)) {
						EventBus.$emit("onMapCheck", f.getProperties().id, true);
					}
				})
				setTimeout(t.cleanFreehandLayer, 100);
      });
		},
		addFreehandInteraction() {
			this.map.addInteraction(this.freehandInteraction);
		},

		removeFreehandInteraction() {
			this.map.removeInteraction(this.freehandInteraction);
		},

		/**
		 * Efface le polygone dessiné à main levée sur la carte
		 */
		cleanFreehandLayer() {
			this.freehandLayer.getSource().clear();
			this.freehandLayer.getSource().dispatchEvent("change");
			this.removeFreehandInteraction();
		},
	}
}