<template>
  <b-container fluid class="full-height-container p-0">
    <b-row no-gutters>
      <b-col cols="auto" class="filter scrollable p-3">
          <router-view name="filtering" :module="module" :filters="filters" :filterConfig="filterConfig" :key="module" :searchApi="searchApi" :searchField="searchField" :searchList="searchList"/>
      </b-col>
      <b-col class="content h-100">
        <router-view
          :key="module"
          :page-title="title"
          :countingElements="countingElements"
          :scrollToSelection="scrollToSelection"
          :loadEntriesOnScroll="loadEntriesOnScroll"
          :routes="routes"
          :addButton="addButton"
          :searchField="searchField"
          :searchList="searchList"
          :defaultPivot="defaultPivot"
          :columns="columns"
          :module="module"
          :checkbox="checkbox"
          :rowSort="rowSort"
          :routesKeepSelection="routesKeepSelection"
          :pathOptions.sync="pathOptions"
          :searchApi="searchApi"
        />
      </b-col>
      <transition name="bounce" enter-active-class="bounceInRight" leave-active-class="bounceOutRight">
        <b-col cols="auto" v-show="show" class="details scrollable border-left p-3 config-form right-sidebar">
          <router-view name="sidebar" :key="$route.fullPath" :details="details" :module="module" />
        </b-col>
      </transition>
    </b-row>
  </b-container>
</template>

<script>
import _ from "lodash";

import { TILE_UPDATE } from "@/store/modules/page-tile";

import { Button } from "@/models/button.js";

import ActivityModule from "@/store/modules/activity";
import InputStockModule from "@/store/modules/inputstock";
import CropfieldModule from "@/store/modules/cropfield";
// import CulturalProcessModule from "@/store/modules/culturalprocess";
import DoseUnitsModule from "@/store/modules/doseUnits";
// import FertilizationPlanModule from "@/store/modules/fertilizationplan";
import PeriodsModule from "@/store/modules/periods";
import TerritoriesModule from "@/store/modules/territories";
import SoilAnalyzesModule from "@/store/modules/soilanalyzes";
import ProductsModule from "@/store/modules/products";
import ProductsAliasesModule from "@/store/modules/productsAliases";
import ProductTypeModule from "@/store/modules/productType";
import CropTypesModule from "@/store/modules/cropTypes";
import ProductFamilyModule from "@/store/modules/productFamily";
import ProductUsageModule from "@/store/modules/productUsage";
import MachineModule from "@/store/modules/machine";
import FuelFillModule from "@/store/modules/fuelFill";
import WarehouseModule from "@/store/modules/warehouse";
import CropModule from "@/store/modules/crop";
import MachineTypesModule from "@/store/modules/machineTypes";
import ActivityTypesModule from "@/store/modules/activityTypes";
import FertilizationPlanInputsModule from "@/store/modules/fertilizationPlanInputs";
import MaintenanceModule from "@/store/modules/maintenance";
import InputsModule from "@/store/modules/inputs";
import harvestsStorageModule from "@/store/modules/harvestsStorage";
import LocationModule from "@/store/modules/location";
import LocationTypeModule from "@/store/modules/locationType";
import inputSupplyModule from "@/store/modules/inputSupply";
import External_MiscellaneousModule from "@/store/modules/external_miscellaneous";
import MiscellaneousModule from "@/store/modules/miscellaneous";
import ActivityFamiliesModule from "@/store/modules/activityFamilies";
import InputInventoryModule from "@/store/modules/inputInventory";
import TourModule from "@/store/modules/tour";
import YieldModule from "@/store/modules/yield";
import NoteModule from "@/store/modules/note";
import SeedTreatmentModule from "@/store/modules/seedTreatment";
import NonTreatedZoneModule from "@/store/modules/nonTreatedZone";
import VarietyModule from "@/store/modules/variety";
import FertilizationModule from "@/store/modules/fertilization";
import AgroEdiModule from "@/store/modules/agroedi";
import NewsModule from "@/store/modules/news";
import ServicesModule from "@/store/modules/services";


export default {
  beforeRouteUpdate(to, from, next) {
    this.show = ![this.listRoute, this.mapRoute].includes(to.name);
    this.$forceUpdate();
    next();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.show = ![vm.listRoute, vm.mapRoute].includes(to.name);
      vm.$forceUpdate();
    });
  },
  created() {
    // set page info
    this.show = this.$route.name !== this.listRoute;

    this.updateTitle();

    const store = this.$store;
    if (store && store.state) {
      if (!store.state["activity"]) {
        store.registerModule("activity", ActivityModule);
      }

      if (!store.state["tour"]) {
        store.registerModule("tour", TourModule);
      }

      if (!store.state["crop"]) {
        store.registerModule("crop", CropModule);
      }

      if (!store.state["cropfield"]) {
        store.registerModule("cropfield", CropfieldModule);
      }

      // if (!store.state["culturalprocess"]) {
      //   store.registerModule("culturalprocess", CulturalProcessModule);
      // }

      if (!store.state["doseUnits"]) {
        store.registerModule("doseUnits", DoseUnitsModule);
      }

      // if (!store.state["fertilizationplan"]) {
      //   store.registerModule("fertilizationplan", FertilizationPlanModule);
      // }

      if (!store.state["periods"]) {
        store.registerModule("periods", PeriodsModule);
      }

      if (!store.state["territories"]) {
        store.registerModule("territories", TerritoriesModule);
      }

      if (!store.state["soilanalyzes"]) {
        store.registerModule("soilanalyzes", SoilAnalyzesModule);
      }

      if (!store.state["inputStock"]) {
        store.registerModule("inputStock", InputStockModule);
      }

      if (!store.state["productFamily"]) {
        store.registerModule("productFamily", ProductFamilyModule);
      }

      if (!store.state["products"]) {
        store.registerModule("products", ProductsModule);
      }

      if (!store.state["productsAliases"]) {
        store.registerModule("productsAliases", ProductsAliasesModule);
      }

      if (!store.state["cropTypes"]) {
        store.registerModule("cropTypes", CropTypesModule);
      }

      if (!store.state["machineTypes"]) {
        store.registerModule("machineTypes", MachineTypesModule);
      }

      if (!store.state["activityTypes"]) {
        store.registerModule("activityTypes", ActivityTypesModule);
      }

      if (!store.state["productType"]) {
        store.registerModule("productType", ProductTypeModule);
      }

      if (!store.state["productUsage"]) {
        store.registerModule("productUsage", ProductUsageModule);
      }

      if (!store.state["machine"]) {
        store.registerModule("machine", MachineModule);
      }

      if (!store.state["fuelFill"]) {
        store.registerModule("fuelFill", FuelFillModule);
      }

      if (!store.state["warehouse"]) {
        store.registerModule("warehouse", WarehouseModule);
      }

      if (!store.state["fertilizationPlanInputs"]) {
        store.registerModule("fertilizationPlanInputs", FertilizationPlanInputsModule);
      }

      if (!store.state["maintenance"]) {
        store.registerModule("maintenance", MaintenanceModule);
      }

      if (!store.state["inputs"]) {
        store.registerModule("inputs", InputsModule);
      }

      if(!store.state["harvestStorage"]) {
        store.registerModule("harvestStorage", harvestsStorageModule);
      }

      if(!store.state["location"]) {
        store.registerModule("location", LocationModule);
      }

      if(!store.state["locationType"]) {
        store.registerModule("locationType", LocationTypeModule);
      }

      if(!store.state["inputSupply"]) {
        store.registerModule("inputSupply", inputSupplyModule);
      }

      if(!store.state["external_miscellaneous"]) {
        store.registerModule("external_miscellaneous", External_MiscellaneousModule);
      }

      if(!store.state["miscellaneous"]) {
        store.registerModule("miscellaneous", MiscellaneousModule);
      }

      if(!store.state["activityFamilies"]) {
        store.registerModule("activityFamilies", ActivityFamiliesModule);
      }

      if(!store.state["inputInventory"]) {
        store.registerModule("inputInventory", InputInventoryModule);
      }

      if(!store.state["yield"]) {
        store.registerModule("yield", YieldModule);
      }

      if(!store.state["note"]) {
        store.registerModule("note", NoteModule);
      }

      if(!store.state["seedTreatment"]) {
        store.registerModule("seedTreatment", SeedTreatmentModule);
      }

      if(!store.state["nonTreatedZone"]) {
        store.registerModule("nonTreatedZone", NonTreatedZoneModule);
      }

      if(!store.state["variety"]) {
        store.registerModule("variety", VarietyModule);
      }

      if(!store.state["fertilization"]) {
        store.registerModule("fertilization", FertilizationModule);
      }

      if(!store.state["agroedi"]) {
        store.registerModule("agroedi", AgroEdiModule);
      }

      if(!store.state["news"]) {
        store.registerModule("news", NewsModule);
      }

      if(!store.state["services"]) {
        store.registerModule("services", ServicesModule);
      }
    }
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    addRoute() {
      const route = this.routes.new.name;

      // this routes.new.name can be a function, because sometimes
      // we want a dynamic name depending on the current route
      // so here we need to check if we have a function or not
      // the _ refers to the lodash library
      if (_.isFunction(route)) return route(this.$route.name);
      return this.routes.new.name;
    },
    listRoute() {
      return this.routes.list.name;
    },
    mapRoute() {
      return this.routes.map && this.routes.map.name;
    },
    detailsRoute() {
      return this.routes.details.name;
    },
    newRoute() {
      return this.routes.new.name;
    },
  },
  methods: {
    updateTitle(nextRoute) {
      // on the cropfields page, we show more buttons
      // -> 'reports', 'import/export' and 'add new cropfield'
      let buttons = [];
      if (this.module == "cropfield") {
        let isOnMap = this.$route.name.includes("map");
        buttons.push(
          ...[
            new Button({
              text: this.$t("cropfields.import_export.button"),
              action: () =>
                this.$router.push({ name: isOnMap ? "cropfield.map.importexport" : "cropfield.importexport" }),
            }),
            new Button({
              text: this.$t("cropfields.reports.button"),
              action: () => this.$router.push({ name: isOnMap ? "cropfield.map.reports" : "cropfield.reports" }),
            }),
          ],
        );
      }
      if (this.module == "activity") {
        buttons.push(
          ...[
            new Button({
              text: this.$t("activities.export.button"),
              action: () => this.$router.push({ name: "activity.export" }),
            }),
            new Button({
              text: this.$t("activities.reports.button"),
              action: () => this.$router.push({ name: "activity.reports" }),
            }),
          ],
        );
      }
      // buttons shown on the inputstock page
      if (this.module == "inputStock") {
        buttons.push(
          ...[
            new Button({
              text: this.$t("inputStock.import_export.button"),
              action: () => this.$router.push({ name: "inputStock.importexport" }),
            }),
            new Button({
              text: this.$t("inputStock.reports.button"),
              action: () =>
                this.$router.push({ name: "inputStock.reports" }),
            }),
          ],
        );
      }
      if (this.module == "machine") {
        buttons.push(
          ...[
            new Button({
              text: this.$t("machines.reports.button"),
              action: () =>
                this.$router.push({ name: "machines.reports" }),
            }),
          ],
        );
      }
      buttons.push(
        new Button({
          text: this.addButton,
          action: () => this.$router.push({ name: this.addRoute }),
          icon: "plusIcon",
        }),
      );
      this.$store.commit(TILE_UPDATE, {
        title: this.title,
        buttons: buttons,
      });
    },
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    countingElements: {
      type: String,
      default: "item(s)",
    },
    loadEntriesOnScroll: {
      type: Boolean,
      default: false,
    },
    scrollToSelection: {
      type: Boolean,
      default: false,
    },
    addButton: {
      type: String,
      default: "",
    },
    searchField: {
      type: String,
      default: "",
    },
    defaultPivot: {
      type: Number,
      default: null,
    },
    routes: {
      type: Object,
      default: () => {},
    },
    module: {
      type: String,
      default: "",
    },
    rowSort: {
      type: Object,
      default: () => {},
    },
    columns: {
      type: Object,
      default: () => {},
    },
    filters: {
      type: Array,
      default: () => {},
    },
    filterConfig: {
      type: Object,
      default: () => {},
    },
    details: {
      type: Object,
      default: () => {},
    },
    checkbox: {
      type: Boolean,
      default: false,
    },
    routesKeepSelection: {
      type: Array,
      default: () => [],
    },
    pathOptions: {
      type: Object,
      default: () => {},
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    searchField: {
      type: String,
      default: "",
    },
    searchApi: {
      type: Boolean,
      default: false,
    },
    searchList: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    "$route.name": function (newValue, oldValue) {
      this.updateTitle();
    },
  },
};
</script>

<style lang="scss" scoped>
/**
 * Config form.
 */
.config-form {
  background-color: $background-form-color;
}

.details {
  // useful to display it above some modals
  z-index: 100;
  max-width: 27%;
}
</style>
