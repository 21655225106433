import Gateway from "./base";

export default class DoseUnitsApi {
  /**
   * Get All dose units
   */
  static getDoseUnits() {
    return Gateway.get("/refapi/doseUnits/");
  }

  /**
   * Get nitrogen dose units
   * 
   * @param params
   */
  static getNitrogenDoseUnits(params) {
    return Gateway.get("/refapi/doseUnits/", params);
  }
}