import * as turf from "@turf/turf";
import _ from "lodash";

import { project4326, project3857to2154 } from "@/utils/geomatics";

import { EventBus } from "@/eventBus.js";

/**
 * A helper class to get info about the current map instance
 */
export default class Map {
  static instance;

  static setInstance(instance) {
    Map.instance = instance;

    Map.getView().on("change:resolution", _.debounce(this.onZoom, 100))
  }

  static getExtent() {
    return Map.instance.getView().calculateExtent();
  }

  static getView() {
    return Map.instance.getView();
  }

  static getExtent4326() {
    const points = Map.getExtent();
    let multiPoint = turf.multiPoint([
      [points[0], points[1]],
      [points[2], points[3]],
    ]);

    multiPoint = project4326(multiPoint);
    multiPoint = turf.truncate(multiPoint, { precision: 4 });

    let bbox = turf.bbox(multiPoint);

    return [bbox[1], bbox[0], bbox[3], bbox[2]];
  }

  static getExtent2154() {
    const points = Map.getExtent();
    let multiPoint = turf.multiPoint([
      [points[0], points[1]],
      [points[2], points[3]],
    ]);

    multiPoint = project3857to2154(multiPoint);
    multiPoint = turf.truncate(multiPoint, { precision: 8 });

    let bbox = turf.bbox(multiPoint);

    return [bbox[0], bbox[1], bbox[2], bbox[3]];
  }

  static getZoom() {
    return Map.instance.getView().getZoom();
  }

  static onZoom() {
    EventBus.$emit("mapZoom")
  }
}
