<template>
  <!-- Button to switch between tours and activities-->
  <div class="position-relative cursor-pointer" @click="checked = !checked">
    <b-form-checkbox
      class="overlay duotone-switch"
      v-model="checked"
      switch>
      {{ $t("tours.button") }}
    </b-form-checkbox>
  </div>
</template>

<script>
import { config as tourConfig } from "@/views/tour/config.js"
import { config as activityConfig } from "@/views/activity/config.js"

export default {
  data() {
    return {
      checked: false
    }
  },
  mounted() {
    this.checked = this.$route.name.includes("tour")
  },
  watch: {
    $route: {
      handler() {
        this.checked = this.$route.name.includes("tour");
      }
    },
    checked: {
      handler() {
        if (this.checked == this.$route.name.includes("tour")) return;
        const route = this.checked ? tourConfig.routes.list.name : activityConfig.routes.list.name;
        if(this.$route.name == route) return;
        this.$router.push({ name: route })
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.overlay::before {
  content: "";
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>

<style lang="scss">
.duotone-switch .custom-control-label::before {
  background: $secondary-color;
  border: 0;
  box-shadow: 0px 0px 0px 2px $color-light;
}

.duotone-switch .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $color-light;
}

.duotone-switch.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: $secondary-color;
}

.duotone-switch.custom-switch .custom-control-label::after {
  background-color: $color-light;
}
</style>