import IslandApi from "@/api/island.api";

export const ONE_ISLAND = "GET CULTURAL PROCESS BY ID";
export const ISLAND_LOADING = "LOAD CULTURAL PROCESS";

import * as turf from "@turf/turf";

export default {
  state: {
    currentIsland: {},
    island_loaded: false
  },
  getters: {
    currentIsland: state => state.currentIsland,
  },
  mutations: {
    [ONE_ISLAND]: (state, payload) => {
      state.currentIsland = payload.island;
    },

    // [DELETE_FUELFILL]: (state, payload) => {
    // },

    [ISLAND_LOADING]: (state, status) => {
      state.island_loaded = !status;
    },
  },
  actions: {
    async getOneIsland({ commit }, payload) {
      commit(ISLAND_LOADING, true);
      const response = await IslandApi.getIsland(payload.id);
      commit(ONE_ISLAND, { island: response.data });

      // add island to the cropfield store <id, geometry> dictionnary
      commit("cropfield/setOneIsland", response.data, { root: true });
      commit(ISLAND_LOADING, false);
      return response.data;
    },



  },
};
