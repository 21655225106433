<template>
  <div class="width-table-list h-100 position-relative pt-1 d-flex flex-column" ref="container">
    <b-container class="px-4 pt-2 sticky-top list-header" fluid>
      <b-row no-gutters>
        <b-col>
            <MultiSelectionSwitch v-if="(module == 'activity' || 'cropfield' || 'fertilization') && checkbox" :module="module"/>
            <FilterTopBar :module="module"/>
        </b-col>
        <b-col>
          <CropfieldsTabs v-if="module == 'cropfield'" />
          <LocationsTabs v-if="module == 'location'" />
        </b-col>
      </b-row>
    </b-container>
    <div class="border-bottom pb-1 d-flex mt-3 mb-1 px-4 align-items-center">
      <!-- Conteneur pour la table et le bouton -->
      <div class="d-flex align-items-center w-100">
        <table class="table-list table-list-header flex-grow-1">
          <tr class="table-header-title">
            <td v-if="checkbox && showCheckbox" class="pl-4" :style="{ width: getColWidth(-1) + 'rem' }">
              <b-form-checkbox size="lg" class="visibility-hidden"></b-form-checkbox>
            </td>
            <td
              v-for="(header, key) in columnsToShow"
              :key="key"
              @click="pivotSort(key)"
              :style="{ width: getColWidth(parseInt(key)) + 'rem' }"
              v-show="header.show"
            >
              <span>{{ header.title }}</span>
              <img
                v-if="header.sort"
                :src="require('@/assets/images/sorting.svg')"
                :class="{ 'd-none': key != pivot }"
                alt="sorting pivot"
              />
            </td>
          </tr>
        </table>

        <!-- Btn aligné à droite -->
        <button type="button" class="btn btn-sm btn-light ml-auto" @click="toggleColumnSelector">
          <img :src="require('@/assets/images/columns_selector.svg')" alt="select columns" style="width: 20px; height: auto;">
        </button>

        <!--Table-->
        <div class="position-absolute bg-light border p-3 menuShowFilter" v-if="showMenuColumns"  ref="menuFilter">
          <div class="mb-3">{{$t("column_to_show")}}</div>
          <div v-for="(column, key) in columns"
              :key="key"
              class="custom-control custom-checkbox green-variant"
              v-show="column.title"
          >
            <input
              type="checkbox"
              class="custom-control-input"
              :checked="column.show !== false"
              @change="updateColumns(key,  $event.target.checked, column.name)"
              :id="'column_' + column.title + '_' + key"
            />
            <label class="custom-control-label" :for="'column_' + column.title + '_' + key">{{ column.title }}</label>
          </div>
        </div>
      </div>
    </div>
    <div ref="scrollContainer" class="overflow-y-scroll pb-5 pl-4">
      <!-- <div class="content scrollable-list p-md-3 mt-4" style="flex-grow: 2"> -->
      <div v-for="(group, index) in rowsGroupBy" :key="group.name" style="padding-right: 2.75rem;">
        <!-- group header -->
        <div
          v-if="headerToDisplay"
          class="pivot pb-3 pl-1 pr-3 active collapsible d-flex justify-content-start align-items-center"
          @click="collapse('el' + index)"
        >
        <div v-if="checkbox && showCheckbox" @click.stop="">
          <b-form-checkbox
            @change="checkAll(group.name)"
            :checked="group.rows.every(row => rowsChecked.includes(row.id))"
            size="lg">
            &nbsp;
          </b-form-checkbox>
        </div>
          <div class="mt-2">
            <strong v-if="module == 'harvestStorage' && pivot !== '0' ">
              {{ group.name | empty("Non renseigné") }} ({{ sumQuantity(group.rows) | empty("0") }})
            </strong>
            <strong v-else-if="module == 'cropfield'">
              {{ group.name | empty("Non renseigné") }} ({{ group.rows.length | empty("0") }} {{ countingElements }}) {{ sumSurface(group.rows) }}
            </strong>
            <strong v-else-if="module == 'activity' && pivot === '0'">
              {{ group.name | empty("Non renseigné") }} ({{ group.rows.length | empty("0") }} {{ countingElements }}) {{ sumSurface(group.rows) }}
            </strong>
            <strong v-else-if="module == 'yield'">
              {{ group.name | empty("Non renseigné") }} ({{ group.rows.length | empty("0") }} {{ countingElements }}) {{ averageYield(group.rows) }}
            </strong>
            <strong v-else>
              {{ group.name | empty("Non renseigné") }} ({{ group.rows.length | empty("0") }} {{ countingElements }})
            </strong>
          </div>
        </div>

        <div class="width-body-list" :id="'el' + index" style="display: block;">
          <div v-for="row in group.rows" :key="row.id" class="line-element pb-3 d-flex pl-1 pr-1 flex-wrap heght-line">
            <table class="table-list">
              <!-- ligne -->
              <tr
                :class="{
                  'table-header': true,
                  'cursor-selected-line': true,
                  'selected-line': rowSelected == row.id,
                }"
                :id="row.id"
                :ref="row.id"
                @click="onSelect(row.id)"
              >
                <td v-if="checkbox && showCheckbox" class="pl-4 pt-2" :style="{ width: getColWidth(-1) + 'rem' }" @click.stop="">
                  <b-form-checkbox size="lg" @change="onCheck(row.id)" :checked="rowsChecked.includes(row.id)"
                    >&nbsp;</b-form-checkbox
                  >
                </td>
                <td
                  v-for="(header, key) in columnsToShow"
                  :key="key"
                  v-show="header.show"
                  :style="{ width: getColWidth(parseInt(key)) + 'rem'}"
                >
                  <!-- if there is a component (like a button) for this column, we display it -->
                  <div
                    v-if="rowsChecked.includes(row.id) && getCellEditableComponent(key)"
                    class="d-flex"
                    @click.stop=""
                  >
                    <component
                      :is="getCellEditableComponent(key)"
                      :rowId="row.id"
                      :cellData="row[header.name]"
                      :field="header.name"
                      @updateRow="updateRow"
                    ></component>
                  </div>
                  <div v-else-if="getCellComponent(key)" class="d-flex">
                    <component :is="getCellComponent(key)" :rowId="row.id" :rowData="row"></component>
                  </div>

                  <!-- otherwise, we display the value -->
                  <div v-else class="d-flex">
                    <div
                      :class="[
                        { 'cell-warning': getWarnings(row, header.name).length > 0 },
                        getCellDisplayClasses(key, row[header.name]),
                        getGeneralCellDisplayClasses(key)
                      ]"
                      v-b-tooltip.hover.top.html.window="
                        getWarnings(row, header.name).length > 0 && getWarningsAsString(row, header.name)
                      "
                    >
                      {{ getCellDisplay(key, row[header.name]) }}
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <!-- </div> -->
      <div
        v-if="loadEntriesOnScroll"
        v-infinite-scroll="loadMore"
        data-infinite-scroll-disabled="load_busy"
        data-infinite-scroll-distance="10"
        class="infinite-scroll-message"
      >
        <span v-if="arePagesLeft && !searchQueryDefined">
          veuillez patienter ...
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import infiniteScroll from "vue-infinite-scroll";

import { scrollTo } from "@/utils/commons";

import loader from "@/components/Loader.vue";

import Toggle from "@/components/inputstock/list/Toggle.vue";
import ActivityInfos from "@/components/activity/list/ActivityInfos.vue";
import WidthAndUnit from "@/components/machines/list/WidthAndUnit.vue";
import CropfieldNameCustom from "@/components/cropfields/list/CropfieldNameCustom.vue";
import CropfieldCropVariety from "@/components/cropfields/list/CropfieldCropVariety.vue";
import FertilizationPlanStatus from "@/components/cropfields/FertilizationPlanStatus.vue"
import ActivityDate from "@/components/activity/list/ActivityDate.vue";
import HarvestDate from "@/components/harvestStorage/list/HarvestDate.vue"
import LastMaintenance from "@/components/machines/list/LastMaintenance.vue";

import FilterTopBar from "@/components/FilterTopBar.vue";

import CropfieldList from "@/components/commons/list/CropfieldList.vue";
import FarmList from "@/components/commons/list/FarmList.vue";
import CropList from "@/components/commons/list/CropList.vue";
import Date from "@/components/commons/list/Date.vue";
import TourInfo from "@/components/tour/list/TourInfo.vue";
import Pin from "@/components/tour/list/Pin.vue";

import EditableCellText from "@/components/commons/editable/EditableCellText.vue";
import EditableCellNumber from "@/components/commons/editable/EditableCellNumber.vue";
import CropDropdown from "@/components/commons/CropDropdown.vue";
import DatePicker from "@/components/commons/DatePicker.vue";
import CropfieldsTabs from "@/components/cropfields/CropfieldsTabs.vue";
import LocationsTabs from "@/components/locations/LocationsTabs.vue";
import EditCellSurface from "../../components/cropfields/edit/EditCellSurface.vue";
import MultiSelectionSwitch from "@/components/commons/MultiSelectionSwitch.vue"

import SupplyCell from "@/components/inputstock/list/SupplyCell.vue";

import TheYieldCell from "@/components/yield/list/TheYieldCell.vue";

import { mapGetters, mapActions, mapMutations } from "vuex";

import { DateTime } from "luxon";

import { cookieIsDefined, getRemInPixels } from "@/utils/commons";

import { EventBus } from "@/eventBus.js";
import { i18n } from '../../i18n';

export default {
  components: {
    loader,
    Toggle,
    ActivityInfos,
    WidthAndUnit,
    CropfieldNameCustom,
    CropfieldCropVariety,
    FertilizationPlanStatus,
    ActivityDate,
    HarvestDate,
    LastMaintenance,
    EditableCellText,
    EditableCellNumber,
    EditCellSurface,
    CropDropdown,
    DatePicker,
    CropfieldsTabs,
    LocationsTabs,
    FilterTopBar,
    SupplyCell,
    CropfieldList,
    FarmList,
    CropList,
    Date,
    TourInfo,
    Pin,
    TheYieldCell,
    MultiSelectionSwitch,
  },
  data() {
    return {
      pageLoaded: false,
      containerWidth: 0,
      defaultColumnWidth: 8,
      headerToDisplay: true,
      rowSelected: null,
      pivot: null,
      lastScrollRowId: null,
      modulesList: ['activity', 'cropfield', 'inputStock', 'harvestStorage', 'machine', 'location', 'tour', 'yield', 'fertilization'],
      dispatchedRows: [],
      showCheckbox: false,
      showMenuColumns: false
    };
  },
  props: {
    columns: {
      type: Object,
      default: () => {},
    },
    countingElements: {
      type: String,
      default: "item(s)",
    },
    loadEntriesOnScroll: {
      type: Boolean,
      default: false,
    },
    scrollToSelection: {
      type: Boolean,
      default: false,
    },
    rowSort: {
      type: Object,
      default: () => {},
    },
    defaultPivot: {
      type: Number,
      default: null,
    },
    searchField: {
      type: String,
      default: null,
    },
    searchList: {
      type: Array,
      default: () => [],
    },
    routes: {
      type: Object,
      default: () => {},
    },
    module: {
      type: String,
      default: () => {},
    },
    checkbox: {
      type: Boolean,
      default: false,
    },
    routesKeepSelection: {
      type: Array,
      default: () => [],
    },
    searchApi: {
      type: Boolean,
      default: false,
    },
  },
  beforeMount() {
    this.pageLoaded = false;
  },
  async created() {
    // if the filter are already loaded, call initial fetch
    if(this.$store.getters["user/globalFiltersLoaded"]) {
      this.initialFetch()
    } else {
      // otherwise wait for filters to be loaded
      EventBus.$on("globalFiltersLoaded", this.initialFetch)
    }

    await this.getColumnsPreferences()
    this.setShowCheckbox()
  },
  async mounted() {
    await this.loading(true);

    this.fetchCrops();
    if (this.module == "yield") this.fetchYieldUnits({ forYield: true });

    if (this.rowsChecked.length == 0) {
      let objectId = this.$route.params[this.routes.params.id];
      // Si le détail d'une entrée était affiché avant de quitter le thème, on le réaffiche
      if (objectId || this.lastEntryDisplayed) {
        this.onSelect(objectId || this.lastEntryDisplayed);
      } else {
        this.resetCurrentId();
      }
    }

    EventBus.$on("clearSelection", this.clearSelection);

    EventBus.$on("selectOnList", this.onSelect);

    EventBus.$on("checkboxChange", this.checkboxChange);

    document.addEventListener('click', this.handleClickOutside);

    EventBus.$on("scrollToSelection", () => {
      this.$nextTick(() => {
        this.scrollToRow(this.rowSelected);
      });
    });

    this.refreshContainerWidth();
    window.addEventListener("resize", this.refreshContainerWidth);

    this.pivot = this.defaultPivot;
    // On conserve le dernier ordre de tri utilisé par l'utilisateur
    if (cookieIsDefined(this.module + ".lastorder")) {
      this.pivot = this.$cookies.get(this.module + ".lastorder");
    }
    this.pageLoaded = true;

    // this.addInitialFetchCallback(this.fetch)

    await this.loading(false);
  },
  beforeDestroy() {
    EventBus.$off("selectOnList", this.onSelect);
    EventBus.$off("checkboxChange", this.checkboxChange);
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    ...mapActions({
      fetchCrops: "crop/fetchCrops",
      fetchYieldUnits: "getNitrogenDoseUnits"
    }),
    columnShown(id) {
      // 1/ show we hide the column based on showColumn method ?
      if(!this.columns[id].showColumn || this.columns[id].showColumn({
        $userCan: this.$userCan,
        store: this.$store
      }))
        return true

      return false
    },
    toggleColumnSelector() {
      // Affiche ou ferme le menu De columns
      this.showMenuColumns = !this.showMenuColumns
    },
    async onCheck(rowId) {
      this.rowSelected = null;

      if (this.rowsChecked.includes(rowId)) {
        this.rowsChecked = [...this.rowsChecked.filter(e => e != rowId)];
      } else {
        this.rowsChecked = [...this.rowsChecked, rowId];
      }

      if (this.rowsChecked.length == 0) await this.$router.push({ name: this.routes.list.name });
    },
    checkAll(groupName) {
      let group = this.rowsGroupBy.find(g => g.name === groupName);
      let elements = group.rows.map(e => e.id);

      // On récupère les cases déjà cochées via rowsChecked
      let intersection = elements.filter(value => this.rowsChecked.includes(value));

      if (intersection.length === elements.length) {
        // Si toutes les cases sont cochées, on les décoche toutes via dispatch
        let updatedRows = this.rowsChecked.filter(e => !elements.includes(e));
        this.rowsChecked = updatedRows; // Cela déclenche le setter, donc dispatch à Vuex
      } else {
        // Sinon, on ajoute toutes les cases via dispatch
        let updatedRows = new Set([...this.rowsChecked, ...elements]);
        this.rowsChecked = [...updatedRows]; // Cela déclenche le setter, donc dispatch à Vuex
      }
    },

    updateRow(rowId, data) {
      this.$store.dispatch(this.module + "/updateEntry", { id: rowId, data: data });
    },
    resetCurrentId() {
      this.$store.commit(this.module + "/resetCurrentId");
    },
    getColWidth(index) {
      // -1 is for the checkbox column
      if (index == -1) return 2;

      if (!(index in this.columnsToShow)) return 0;

      const customWidth = this.columnsToShow[index].width;
      if (customWidth) return customWidth;

      return this.defaultColumnWidth
    },
    fetch({ page } = {}) {
      return this.$store.dispatch(this.module + "/fetchEntries", { page });
    },
    addInitialFetchCallback(callback) {
      return this.$store.dispatch(this.module + "/addInitialFetchCallback", callback);
    },
    // Réinitialise les filtres de tous les modules
    resetAllFilterSelection() {
      this.modulesList.forEach(module => {
        this.$store.dispatch(module + "/resetFilterSelection", {fetch: false});
      })
    },
    initialFetch() {
      this.resetAllFilterSelection();
      return this.$store.dispatch(this.module + "/initialFetch");
    },
    loading(value) {
      return this.$store.commit(this.module + "/setEntryLoaded", value);
    },
    initFilters(value) {
      return this.$store.dispatch(this.module + "/initFilters", value);
    },
    getCellComponent(columnIndex) {
      return this.columns[columnIndex].cellComponent;
    },
    getCellEditableComponent(columnIndex) {
      return this.columns[columnIndex].editable && this.columns[columnIndex].editable.cellComponent;
    },
    getCellDisplay(columnIndex, cellValue) {
      let cellDisplay = this.columns[columnIndex].cellDisplay;

      if (cellDisplay && cellDisplay.mode && cellDisplay.mode == "function") {
        return cellDisplay.method(cellValue);
      }

      if (cellDisplay && cellDisplay.mode && cellDisplay.mode == "fromStore") {
        let display = this.$store.getters[cellDisplay.getter].find(e => e.id == cellValue);
        if (display && cellDisplay.key) return display[cellDisplay.key];
        return display;
      }
      // if the column config has a display dictionary, we show the matching value
      if (cellValue in (cellDisplay || {})) return cellDisplay[cellValue];
      // else we return the raw value
      return cellValue;
    },
    getCellDisplayClasses(columnIndex, cellValue) {
      // if the column config has a display dictionary, we show the matching value
      if (cellValue in (this.columns[columnIndex].cellDisplayClasses || {})) {
        return [this.columns[columnIndex].cellDisplayClasses[cellValue]];
      }
      // else we return the raw value
      return [];
    },
    getGeneralCellDisplayClasses(columnIndex) {
      // if the column config has a display dictionary, we apply it
      return this.columns[columnIndex].generalCellDisplayClasses || "";
    },
    collapse(id) {
      const $el = document.querySelector("#" + id);
      $el.classList.remove("fadeInDown");
      $el.classList.remove("fadeOutUp");

      $el.style.display === "none" ? $el.classList.toggle("fadeOutUp") : $el.classList.toggle("fadeInDown");
    },


    /**
     * Description: Scroll to line row with animation
     * @param {number} rowId
     */
    scrollToRow(rowId) {
      if (!this.scrollToSelection || this.lastScrollRowId == rowId) return;

      let elements = this.$refs[rowId];
      if (!elements || !elements[0]) return;

      let topPos = elements[0].getBoundingClientRect().top;

      scrollTo(this.$refs.scrollContainer, Math.max(topPos - 400, 0), 600);

      // avoid duplicate scroll on same id
      this.lastScrollRowId = rowId
    },
    async onSelect(id, uncheckAll = true) {
      if (this.entryLoaded || !this.rowSelected) {
        await this.loading(true);

        if (uncheckAll) this.rowsChecked = [];

        this.rowSelected = id;

        if (this.$route.params[this.routes.params.id] !== id) {
          let params = {};
          params[this.routes.params.id] = id;
          params['fertilizationPlan'] = (this.$route.params.fertilizationPlan == true);
          await this.$router.push({ name: this.routes.details.name, params: params });
          EventBus.$on("closeSupply");
        }

        this.refreshContainerWidth();

        await this.loading(false);
      }
    },

    /**
     * Description: Pivot the columns
     * @param {Array}       data rows data (inputstock, activites etc.).
     * @param {String}      groupKey the object key to group by
     * @param {Object}      groupDisplay if we don't want to display the raw keys, we can give a { keyA: 'displayA', keyB: 'displayB'} object for the display
     * @param {Boolean}     displayHeader if we should display the groups header
     */
    pivotBy(
      data,
      groupKey,
      groupDisplay,
      displayHeader,
      // for more explanation, see documentation on the wiki
      sortHeaderField, // the field for the header
      sortHeaderOrder, // the sort order for the header ('asc' or 'desc')
      sortDataFields, // the data fields to use to sort the rows inside groups
      sortDataOrders, // the data sort orders
    ) {
      this.headerToDisplay = displayHeader;

      let dict = {};
      let result = [];

      if (!data) return dict;

      // first, we make groups according to the pivot key
      let groups = _.groupBy(data, row => {
          // if there is a groupDisplay configuration, use it for the group name
          if(Object.keys(groupDisplay).length > 0) {
            if (groupDisplay.mode && groupDisplay.mode == "function") {
              return groupDisplay.method(row)
            }
            return groupDisplay[row[groupKey]];
          }
          // otherwise, use normal celldisplay value
          return this.getCellDisplay(this.pivot, row[groupKey])
      });

      // then, for each group, take the belonging rows and sort them
      _.keys(groups).forEach(name => {
        result.push({
          //rawName: rawName,
          name: name,
          rows: _.orderBy(groups[name], sortDataFields, sortDataOrders),
        });
      });

      // and then sort the groups
      return _.orderBy(result, sortHeaderField, sortHeaderOrder);
    },

    /**
     * Description: Filter inputStock by the columns value:
     * (Name, product type, authorization, available).
     * @param {number}       index Column index.
     */
    async pivotSort(index) {
      if (index === this.pivot || this.columns[index].pivot.enable === false) return;
      this.pivot = index;

      // On stocke l'ordre de tri dans un cookie
      this.$cookies.set(this.module + ".lastorder", index);
    },

    loadMore() {
      if (!this.arePagesLeft || this.searchQueryDefined || !this.pageLoaded) {
        return;
      }

      this.load_busy = true;

      this.fetch({ page: this.page }).then(data => {
        this.load_busy = false;
      });
    },
    refreshContainerWidth() {
      if (!this.$refs.container) return;
      this.containerWidth = this.$refs.container.getBoundingClientRect().width;
    },
    getWarnings(row, col) {
      let warnings = [];

      // cropfields
      if (this.module == "cropfield") {
        // check if surface computed is different from user input surface

        if (col === "surface") {
          // -> seuil mettre 5%
          if (
            row.calculatedSurface &&
            Math.abs(row.surface - row.calculatedSurface) > 0.05 && // critère absolu, > que 0.05
            Math.abs((row.surface - row.calculatedSurface) / row.surface) > 0.02 // critère %, > que 2%
          ) {
            let message = "Surface calculée: " + row.calculatedSurface + " \n";
            message += this.$t("cropfields.list.warning_surface_different");
            warnings.push(message);
          }
          if (!row.calculatedSurface) {
            warnings.push(this.$t("cropfields.list.warning_missing_geometry"));
          }
        }
      }

      // tours
      if(this.module == "tour") {
        if(col == "status_designation") {
          // warning: when a tour has status "Non started" but the start date is past
          if(row.status == "NS") {
            const dt = DateTime.fromISO(row.startDate).setZone("Europe/Paris").setLocale("fr");
            Date.fromISO
            const now = DateTime.now().setZone("Europe/Paris").setLocale("fr");
            if(dt.plus({hour: 24}) < now)
              warnings.push(this.$t("tours.warnings.delayed-tour"));
          }
        }
      }

      // activity
      if(this.module == "activity") {
        // check if the activity is on unmapped cropfield
        if(col == "cropField_name") {
          if(row.cropField_toBeConfirmed)
            warnings.push(this.$t("cropfields.list.hover_unlisted_cropfield"));
        }
      }

      // you can have other warnings here

      return warnings;
    },
    getWarningsAsString(row, col) {
      return this.getWarnings(row, col).join("<br>");
    },

    async clearSelection({ goToList } = { goToList: false }) {
      this.rowsChecked = [];
      this.rowSelected = null;

      if (goToList === true && this.$router.name != this.routes.list.name)
        this.$router.push({ name: this.routes.list.name });
    },

    /**
     * @description calcule la somme des quantités des récoltes sur un groupement
     * @param {array} rows un tableau de récoltes
     */
    sumQuantity(rows) {
      let sum_kilos = 0;
      let sum_quintaux = 0;
      let sum_tonnes = 0;
      let sum_ballots = 0;
      let result = ""
      rows.map(row => {
        if(row.unit == "kg")
          sum_kilos += row.quantity;
        else if(row.unit == "q")
          sum_quintaux += row.quantity;
        else if(row.unit == "T")
          sum_tonnes += row.quantity;
        else if(row.unit == 'b')
          sum_ballots += row.quantity;
      });
      if(sum_kilos)
        result += sum_kilos.toString() + " kg ";
      if(sum_quintaux) {
        if(result)
          result += "+ ";
        result += sum_quintaux.toString() + " q ";
      }
      if(sum_tonnes) {
        if(result)
          result += "+ ";
        result += sum_tonnes.toString() + " T ";
      }
      if(sum_ballots) {
        if(result)
          result += "+ ";
        result += sum_ballots.toString() + " ballots";
      }
      return result;
    },

    /**
     * @description calcule la somme des surfaces des parcelles ou des activités sur un groupement
     * @param {array} rows un tableau de parcelles
     */
    sumSurface(rows) {
      let result = 0;
      rows.map(row => {
        if (row.surface) {
          result += parseFloat(row.surface);
        }
      });
      result = result.toFixed(2).toString() + " ha";
      if(this.module == "activity")
        result += " " + i18n.t("activities.list.worked");
      return result;
    },

    /**
     * Retourne le rendement pour un groupement de lignes
     */
    averageYield(rows) {
      let result = 0;
      let surface = 0;
      let initialCoef = this.coefToReferenceForYieldUnit(rows[0]?.unit);
      let initialUnit = rows[0]?.unit_unit;
      rows.map(row => {
        result += row.theYield * parseFloat(row.surface || row.cropfield_surface) * this.coefToReferenceForYieldUnit(row.unit);
        surface += parseFloat(row.surface || row.cropfield_surface);
      });
      if (result != 0 || surface != 0){
        result = result / (surface * initialCoef);
        result = result.toFixed(2).toString() + " " + initialUnit;
      }
      return result;
    },

    /**
     * Pour une unité de rendement, retourne le coefficient pour atteindre l'unité de référence
     */
    coefToReferenceForYieldUnit(unit_id) {
      return this.yieldUnits.find(unit => unit.id === unit_id)?.coefToReference;
    },

    checkboxChange(){
      this.showCheckbox = !this.showCheckbox
      if(this.showCheckbox == false) this.rowsChecked = []
    },

    updateShowCheckbox(newValue) {
      this.showCheckbox = newValue;
      if(this.showCheckbox == false){
        this.rowsChecked = []
      }
    },

    setShowCheckbox(){
      this.showCheckbox = this.$store.getters[this.module + "/showCheckbox"];
    },

    /**
     * On récupere les columnsPreferences
     */
    async getColumnsPreferences(){
      //Si columnsPreferences est vide on Call l'api GET
      if(Object.keys(this.columnsPreferences).length === 0){
        await this.$store.dispatch('getColumnsPreferences', { module: this.module, data: this.columns })
      }
      await this.initColumnsToShow()
    },

    /**
     * initialise les colones et les préferences
     */
    initColumnsToShow() {
      if(!this.columnsPreferences[this.module]) this.addColumns()

      const preferences = this.columnsPreferences[this.module];

      if (!preferences) {
        console.error(`Module ${this.module} introuvable dans columnPreferences.`);
        return this.columns; // Retourne columnsShow inchangé si le module est introuvable
      }

      // Parcourir les colonnes de columnsShow
      Object.keys(this.columns).forEach((key) => {
        if (preferences[key]) {
          // Mettre à jour la valeur de `show` si une correspondance est trouvée
          this.columns[key].show = preferences[key].show;
        } else {
          console.warn(`Colonne ${key} introuvable dans les préférences.`);
        }
      });
    },

    /**
     * Ajoute au préferences la colone du module
     */
    addColumns() {
      this.$store.dispatch("addColumnsPreferences", { module: this.module, data: this.columns });
    },

    /**
     * Met a jour les préferences des colones
     */
    updateColumns(index, value, name){
      this.$store.dispatch("updateColumnsPreferences", { index: index, value: value, module: this.module, name });
    },

    /**
     * Gere la fermeture du menu de préferencielles des colones
     */
    handleClickOutside(event) {
      const menuFilter = this.$refs.menuFilter;
      const button = event.target.closest('button'); // Récupère le bouton

      // Si le clic provient du menu ou du bouton, on ne ferme pas le menu
      if (menuFilter && !menuFilter.contains(event.target) && !button) {
        this.showMenuColumns = false;
      }
    },
  },
  directives: { infiniteScroll },
  computed: {
    ...mapGetters({
      columnsPreferences: "columnsPreferences",
      currentFarm: "farm/currentFarm",
      currentCampaign: "getCampaign",
      hasCurrentCampaign: "hasCurrentCampaign",
      filterSelection: "cropfield/filterSelection",
      yieldUnits: "doseUnitsFiltered",
    }),
    /**
     * Cette function nous retourne les colones a afficher en fonction de plusieur paramétres
     * Permet de les triers
     * De leur définir leur bonne taille en function du witdh qu'il leur a été donné
     */
    columnsToShow() {
      let toShow = {};
      let cumulatedWidth = 0; // Accumulation des largeurs
      const cols = Object.keys(this.columns);

      //On trie les colones par leur priorité
      const sortedCols = cols.sort((a, b) => {
        const priorityA = this.columns[a].priority || Infinity;
        const priorityB = this.columns[b].priority || Infinity;
        return priorityA - priorityB;
      });

      sortedCols.forEach(index => {
        let column = this.columns[index];
        const columnWidth = (column.width ? column.width : this.defaultColumnWidth) * getRemInPixels();
        // initialize la bonne valeur de show en fonction des preferences
        column.show = this.columnsPreferences?.[this.module]?.[index]?.show ?? column.show;

        // Vérifiez si la colonne est affichable
        if (this.columnShown(index)) {

          // Vérifiez si l'ajout de cette colonne dépasse la largeur du conteneur
          if ((cumulatedWidth + columnWidth) < this.containerWidth) {
            toShow[index] = column; // Ajoutez la colonne à la liste des colonnes à afficher
            if(column.show){
              cumulatedWidth += columnWidth; // Ajoutez la largeur de la colonne au total
            }
          }
        }
      });
      return toShow;
    },
    routeId() {
      return this.$route.params[this.routes.params.id];
    },
    page() {
      return this.$store.getters[this.module + "/page"];
    },
    maxPage() {
      return this.$store.getters[this.module + "/maxPage"];
    },
    arePagesLeft() {
      return this.page < this.maxPage;
    },
    searchQueryDefined() {
      return this.searchQuery != "" && this.searchQuery != null && this.searchQuery != undefined;
    },
    rows() {
      return this.$store.getters[this.module + "/entries"] || [];
    },
    rowsGroupBy() {
      let column = this.columns[this.pivot];
      if (!column) return {};

      return this.pivotBy(
        this.rowsSearched,
        column.name,
        column.pivot.groupDisplay,
        column.pivot.displayHeader,
        column.pivot.sortHeaderField,
        column.pivot.sortHeaderOrder,
        column.pivot.sortDataFields,
        column.pivot.sortDataOrders,
      );
    },
    rowsSearched() {
      if (this.searchApi) return this.rows
      if (!this.searchQuery) return this.rows;
      return this.rows.filter(row => {
        let found = 0;

        //Filtrage sur les champs définis dans searchField
        let searchArray = this.searchField.split(', ');
        searchArray.map(el => {
          if (row[el] != null && row[el].toString().toLowerCase().includes(this.searchQuery.toLowerCase())) {
            found += 1;
          }
        });

        //Filtrage sur les listes d'objets définies dans searchList
        this.searchList.map(searchItem => {
          let objectField = searchItem.object;
          let attributeField = searchItem.attribute;

          if (row[objectField] && Array.isArray(row[objectField])) {

            row[objectField].map(obj => {
              if (obj[attributeField] && obj[attributeField].toLowerCase().includes(this.searchQuery.toLowerCase())) {
                found += 1;
              }
            });
          }
        });

        // Retourne la ligne si au moins une correspondance est trouvée
        return found !== 0;
      });
    },
    rowsChecked: {
      get() {
        return this.$store.getters[this.module + "/entriesChecked"] || [];
      },
      set(entries) {
        this.$store.dispatch(this.module + "/setEntriesChecked", { entries });
      },
    },
    searchQuery() {
      return this.$store.getters[this.module + "/searchQuery"];
    },
    newEntry() {
      return this.$store.getters[this.module + "/newEntry"];
    },
    currentEntry() {
      return this.$store.getters[this.module + "/currentEntry"];
    },
    entryLoaded() {
      return this.$store.getters[this.module + "/entryLoaded"];
    },
    getAllFiltersInput() {
      return this.$store.getters[this.module + "/getAllFiltersInput"];
    },
    lastEntryDisplayed() {
      return this.$store.getters[this.module + "/lastEntryDisplayed"];
    }
  },
  watch: {
    async currentFarm(newValue, oldValue) {
      if (!this.hasCurrentCampaign) return;

      if (newValue?.id != oldValue?.id) {
        await this.clearSelection();

        await this.fetch({ page: 0 });

        this.initFilters();
      }
    },
    async currentCampaign(newValue, oldValue) {
      if (newValue?.id != oldValue?.id) {
        await this.clearSelection();

        await this.fetch({ page: 0 });

        this.initFilters();
      }
    },
    async rowsChecked(newValue, oldValue) {
      if (oldValue.length == newValue.length) return;

      if (this.rowsChecked.length > 0) {
        let lastChecked = this.rowsChecked[this.rowsChecked.length - 1];
        let params = {};
        params[this.routes.params.id] = lastChecked;
        params['fertilizationPlan'] = (this.$route.params.fertilizationPlan == true);

        if (this.$route.params[this.routes.params.id] && this.$route.params[this.routes.params.id] == lastChecked)
          return;

        // Pour rester sur le panneau latéral droit affiché
        let routeName = this.routes.details.name;
        if(routeName == "cropfield.details"){
          if (![this.routes.list.name, this.routes.new.name, this.routes.new.name(this.$route.name)].includes(this.$route.name)) {
            // si on n'est ni en mode liste, ni en mode création, on conserve la route (panneau) sur laquelle on se trouve
            routeName = this.$route.name;
          }
        }
        await this.$router
          .push({ name: routeName, params: params })
          .catch((error) => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
      }
    },
    rows(newValue, oldValue) {
      if ((oldValue.length <= 1 || Math.abs(newValue.length - oldValue.length) == 1) && this.rowSelected)
        this.$nextTick(() => {
          this.scrollToRow(this.rowSelected);
        });
    },
    $route() {
      setTimeout(this.refreshContainerWidth, 400);
    },
    routeId(newValue) {
      if (this.routesKeepSelection.includes(this.$route.name)) return;

      if (newValue) {
        this.onSelect(newValue, false);
      } else {
        this.resetCurrentId();
        this.clearSelection();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/**
 * Align swicth button available
 */
.align-available {
  padding-left: 65px;
}

.custom-checkbox.b-custom-control-lg,
.input-group-lg .custom-checkbox {
  font-size: inherit;
}

.visibility-hidden {
  visibility: hidden;
}

.list-header {
  background: white;
  z-index: 20 !important;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.infinite-scroll-message {
  padding: 15px 10px 25px 10px;
  margin-bottom: 40px;
}

.custom-control {
  font-size: 0.7em;
  line-height: 2;
}

.menuShowFilter {
  right: 1.2rem;
  top: 7rem;
  // width: 10rem;
  z-index: 1000;
}
</style>
