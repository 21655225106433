import Gateway from "./base";

export default class ActivityApi {
  /**
   * Get All activities
   */
  static getActivities({ limit, page, campaign, cropField, crop, activityId, activityType, farmId, notes, billed, detail=true, month, search }) {
    return Gateway.get("/api/activities/", {
      detail: detail,
      ordering: "-startDate",
      campaign: campaign,
      ...{
        ...(cropField && { cropField: cropField }),
        ...((cropField === null) && { cropField__isnull: true}),
        ...(crop && { cropField__crop: crop }),
        ...(activityType && { activityType: activityType }),
        ...(farmId && { farm_id: farmId }),
        ...(activityId && { id: activityId }),
        ...(notes !== null && { notes: notes }),
        ...(billed !== null && { billed: billed }),
        ...(month && { month: month}),
        ...(search && { search: search})
      },
      page,
      limit,
    });
  }

  /**
   * get activities on unlisted cropfields
   */
  static getActivitiesOnUnlistedCropfields({campaign, farmId}){
    return Gateway.get(`/api/activities/unknowncropfield/`, {
      ordering: "-startDate",
      campaign: campaign,
      ...{
        ...(farmId && {farm_id: farmId}),
      },
    });
  }

  /**
   * Get All filters activity
   *
   */
  static getFiltersActivity(query, campaign, farmId, params) {
    return Gateway.get("/api/activities/filtre/", { attrib: query, campaign: campaign, farm_id: farmId, ...params });
  }

  /**
   * Get one activity by id
   *
   * @param id
   */
  static getActivity(id) {
    return Gateway.get(`/api/activities/${id}/`);
  }

  /**
   * Get activities with inputs needed
   *
   */
  static getActivityWithInputsNeeded(params) {
    return Gateway.get("/api/activities/withInputsNeeded/", params);
  }

  /**
   * Get activity input form
   *
   * @param id
   */

  static getActivityInputForm(id) {
    return Gateway.get(`/api/activities/${id}/inputForm/`);

  }

  /**
   * Get activity input form by cropfield
   *
   * @param idCropfield
   * @param idActivityType
   */
  static getInputFormByCropfield(idCropfield, idActivityType, startdate) {
    return Gateway.get(`/api/cropfields/${idCropfield}/inputForm/`,
      {
        activitytypeid: idActivityType,
        startdate: startdate,
        flatlist: true,
      }
    );
  }

  /**
   * Create activity
   *
   * @param data
   */
  static createActivity(data) {
    return Gateway.post(`/api/activities/`, data);
  }

  /**
   * Update activity
   *
   * @param data
   * @param id
   */
  static updateActivity(data, id) {
    let dataToSend = {...data, updatedBy: "UWA"};
    return Gateway.patch(`/api/activities/${id}/`, dataToSend);
  }

  /**
   * Get informations about inputs updated of a activity
   *
   * @param id
   */
  static newsAboutActivity(id) {
    return Gateway.get(`/api/news/`, { activity: id });
  }

  /**
   * Patch at true the readed news attribut.
   *
   * @param id
   */
  static patchNewsAboutActivity(id) {
    return Gateway.patch(`/api/news/${id}/`, { readed: true });
  }

  /**
   * delete activity
   *
   * @param id
   */
  static deleteActivity(id) {
    return Gateway.patch(`/api/activities/${id}/`, { toRemove: true, updatedBy: "UWA" });
  }

  /**
   * Get all the activities by cropfield with their inputs
   * @param {number} idCropField
   * @returns
   */
  static getActivitiesByCropfieldWithInputs(idCropField, page) {
    return Gateway.get(`/api/activities/`, {report: true, cropField: idCropField, page: page});
  }

  /**
   * Get all subcontractors already defined on last 2 years activities
   */
  static getSubcontractors() {
    return Gateway.get(`/api/activities/subcontractors/`);
  }

  /*
  * get a file containing a export of activies with machines
  */
  static exportActivitiesWithMachines(params) {
    const headers = {}

    if ( params.format ) {
      headers['Accept'] = "application/" + params.format;
      delete params.format;
    }
    if ( params.pacage == 'all' ) {
      delete params.pacage;
    }
    if ( params.farm_id == "all" ) {
      delete params.farm_id;
    }
    if ( params.campaign == 'all' ) {
      delete params.campaign;
    }

    return Gateway.get(`/export/activities/`, params, headers, "arraybuffer");
  }

  /*
  * get a file containing a report of activities with billing
  */
  static reportsActivitiesWithMachines(params) {
    const headers = {}

    if ( params.format ) {
      headers['Accept'] = "application/" + params.format;
      delete params.format;
    }
    if ( params.pacage == 'all' ) {
      delete params.pacage;
    }
    if (params.farm_id == "all") {
      delete params.farm_id;
    }
    if ( params.campaign == 'all' ) {
      delete params.campaign;
    }

    return Gateway.get(`/reports/activities/`, params, headers, "arraybuffer");
  }

    /*
  * get a file containing a report of phyto activities
  */
    static reportsPhytoActivities(params) {
      const headers = {}

      if ( params.format ) {
        headers['Accept'] = "application/" + params.format;
        delete params.format;
      }
      if ( params.pacage == 'all' ) {
        delete params.pacage;
      }
      if (params.farm_id == "all") {
        delete params.farm_id;
      }
      if ( params.campaign == 'all' ) {
        delete params.campaign;
      }

      return Gateway.get(`/reports/activities/phyto/`, params, headers, "arraybuffer");
    }
}

