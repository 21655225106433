<template>
    <div>
      <b-row>
        <b-col>
          <h4 class="mb-3">
            <i>Engrais organiques apportés avant bilan</i>
          </h4>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="col-6" >
          <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.Xpro">{{$t('cropfields.fertilization.needsCalculation.Xpro')}}</h4>
          <p class="text-infos bg-white">
            <span> {{ this.Xpro ? this.Xpro : " - " }} </span>
          </p>
        </b-col>
        <b-col class="col-6" >
          <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.Xa">{{$t('cropfields.fertilization.needsCalculation.Xa')}}</h4>
          <p class="text-infos bg-white">
            <span> {{ this.Xa ? this.Xa : " - " }} </span>
          </p>
        </b-col>
      </b-row>
    </div>
  </template>

<script>

export default {
  name: "fertilization-organiques",
  data(){
    return{
      edit_mode: false,
      Xa: null,
      Xpro: null,
    }
  },
  created() {
    this.init();
  },
  props: [
    'editMode', 
    'fertilisationNeedsCalculation', 
    'fertilisationNeedsCalculationList', 
    'fertilisationNeedsCalculationOption',
    'fertiToolTips',
  ],
  methods: {
    init: async function() {
      await this.loadData();
    },
    async loadData(){
      if(typeof this.fertilisationNeedsCalculation != 'undefined') {
        this.edit_mode = this.editMode
        this.Xa = this.fertilisationNeedsCalculation.Xa
        this.Xpro = this.fertilisationNeedsCalculation.Xpro
      }
    },
  },
  watch: {
    fertilisationNeedsCalculation: {
      handler: 'loadData',
      immediate: true
    }
  }
};

</script>

<style>
.disabled-input:disabled {
  background-color: white !important;
  border: none;
}
</style>