<template>
  <div>
    <Treatment
      taskName="import_inputstock_from_file"
      :taskTimeout="40000"
      :async="true"
      :title="$t('inputStock.import_export.import_inputs')"
      :startButton="$t('commons.imports.import')"
      :finishButton="$t('commons.imports.finish')"
      :failMessage="$t('commons.imports.import_fail')"
      :onBack="onBack"
      :onClose="onClose"
      :onTreatmentDone="onTreatmentDone"
      :customValidation="customValidation"
    >
      <TC_Campaign_NoTemplate />
      <TC_ClientId_NoTemplate />
      <TC_UploadFile :fileFeatures="fileFeatures" />
      <TC_StockStore v-if="stockStores.length > 0" />
    </Treatment>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Treatment from "@/components/commons/treatments/TreatmentExecution";
import TC_Campaign_NoTemplate from "@/components/commons/treatments/TC_Campaign_NoTemplate";
import TC_ClientId_NoTemplate from "@/components/commons/treatments/TC_ClientId_NoTemplate";
import TC_UploadFile from "@/components/commons/treatments/TC_UploadFile";
import TC_StockStore from "@/components/commons/treatments/TC_StockStore";


export default {
  data() {
    return {
      // for component TC_UploadFile
      // a list of features to display in the select input, below the file picker
      fileFeatures: {
        label: "Famille de produit",
        features: [
          { value: "phyto", text: "Produits phytosanitaires" },
          { value: "engsy", text: "Engrais de synthèse" },
          { value: "sem", text: "Semences" },
        ],
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions({
      fetchInputStock: "inputStock/fetchEntries",
      initFilters: "inputStock/initFilters",
      fetchStockStores: "stockStores/fetchStockStores",
    }),
    async init() {
      await this.fetchStockStores(false)
    },
    onBack() {
      this.$router.push({ name: "inputStock.importexport" });
    },
    onClose() {
      this.$router.push({ name: "inputStock.list" });
    },
    customValidation(treatment) {
      return true;
    },
    async onTreatmentDone() {
      await this.fetchInputStock();
      this.initFilters();
    },
  },
  computed: {
    ...mapGetters({
      stockStores: "stockStores/stockStores",
    })
  },
  components: { Treatment, TC_Campaign_NoTemplate, TC_ClientId_NoTemplate, TC_UploadFile, TC_StockStore },
};
</script>
