<template>
  <div v-if="this.$route.name === 'inputStock.edit' || this.$route.name === 'inputStock.details'">
    <!-- semence density -->
    <div>
      <table>
        <tr>
          <td class="shape-first-cell">
            <h4 class="subtitle-form">Densité:</h4>
          </td>

          <td class="bg-text-color text-infos config-density-semence">
            {{ currentInputStock.seedDensity  | empty("0") }} {{ currentInputStock.seedDensityUnit_unit | empty(0) }}
          </td>
        </tr>
      </table>

      <br />

      <!-- Dose min and max -->
      <Dose />

      <br />

      <!-- Spécificité -->
      <table v-if="currentInputStock.otherFeature != null">
        <tr>
          <td class="shape-first-cell">
            <h4 class="subtitle-form">{{ inputFormByPtype.otherFeature_label }}:</h4>
          </td>

          <td class="bg-text-color text-infos config-density-semence">
            {{ currentInputStock.otherFeature_designation | empty("Non défini") }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Dose from "@/components/inputstock/details/Dose.vue";

export default {
  name: "density-semence",
  data() {
    return {
      density: 120,
      unityDensity: ["g/1000 grains", "unknow"],
      specificity: ["Planifiable", "Brasserie", "Biscuiterie"],
    };
  },
  components: {
    Dose,
  },
  methods: {},
  computed: {
    ...mapGetters({
      currentInputStock: "inputStock/currentEntry",
      readOnly: "readOnly",
      inputFormByPtype: "inputStock/inputFormByPtype",
      currentProduct: "currentProduct",
    }),
  },
};
</script>

<style lang="scss" scoped>
/**
 * Config for the density.
 */
.config-density-semence {
  text-align: center;
  padding: 0px 5px 0px 5px !important;
  width: 100px;
}

table {
  border-collapse: separate;
  border-spacing: 5px 5px;
}

.shape-first-cell {
  width: 120px;
}
</style>
