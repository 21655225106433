<template>
  <div>
    <!-- Add supplies -->
    <div v-if="!addOrUpdate" align="center" class="mt-3 mb-3">
      <button class="btn align-bottom button-add-supply" @click="addSupply">{{ $t("inputStock.details.add_supply") }}</button>
    </div>
    <SupplyEdit :item="this.itemToUpdate" @clicked="addOrUpdate = false" v-if="addOrUpdate" />
    <button v-if="!loadPage" class="btn btn-primary" type="button" disabled>
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      {{ $t("inputStock.details.loading_data") }}
    </button>
    <table v-show="loadPage" class="table mt-1">
      <thead class="thead-light">
        <tr>
          <th scope="col" >{{ $t('inputStock.details.date') }}</th>
          <th scope="col">{{ $t('inputStock.details.unit_price') }}</th>
          <th scope="col" >{{ $t('inputStock.details.quantity') }}</th>
        </tr>
      </thead>
      <tbody v-for="item in suppliesToPatch" v-bind:key="item.id">
        <tr>
          <td>
            {{ formatDate(item.supplyDate, "date") }}
          </td>
          <td>
            {{ item.price }}
            <span v-if="item.price != null"> € </span> <br>
        	</td>
          <td>
            {{item.quantity.toLocaleString('fr-FR')}}
            <span v-if="item.quantity!=null"> {{ item.doseUnit_unit}} </span> <br>
            <button class="btn align-right update-delete-buttons" @click="onDelete(item.id)">
              <img :src="deleteIcon" alt="icon delete action" />
            </button>
            <button class="btn align-right update-delete-buttons" @click="updateSupply(item)">
              <img :src="updateIcon" alt="icon update action" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import delIcon from "@/assets/images/delete_icon.svg";
import updIcon from "@/assets/images/update.svg";
import SupplyEdit from "@/components/inputstock/edit/SupplyEdit.vue";
import { askConfirm, formatDate } from "@/utils/commons";
import { EventBus } from "@/eventBus.js";

export default {
  data() {
    return {
      deleteIcon: delIcon,
      updateIcon: updIcon,
      loadPage: false,
      addOrUpdate: false,
      itemToUpdate: {}
    };
  },
  components: {
    SupplyEdit
  },
    created() {
      if(this.$route.params.mode == "addSupply") {
        this.addSupply();
      }
      EventBus.$on("addSupply", this.addSupply);
    	this.init();
  },
  methods: {
    init: async function() {
      // Load all supplies of the current inputStock
      this.loadPage = false;
      await this.$store.dispatch("getInputSuppliesByInputStock", {inputsStock : this.currentInput.id});

      this.loadPage = true;
      if(this.$route.params.mode == "updateSupply") {
        if(this.suppliesToPatch.length)
          this.updateSupply(this.suppliesToPatch[0]);
      }
    },
    formatDate,
    /**
     * @description affiche le formulaire d'ajout d'un approvisionnement
     */
    addSupply() {
      this.addOrUpdate = !this.addOrUpdate;
      this.itemToUpdate = {};
    },
    /**
     * @description affiche le formulaire de modification d'un approvisionnement et transmet les
     * données de l'approvisionnement sélectionné
     */
    updateSupply(item) {
      this.addOrUpdate = !this.addOrUpdate;
      this.itemToUpdate = item;
    },

    /**
     * @description supprime un approvisionnement
     */
    async onDelete(supplyId) {
      if(await askConfirm(this.$t("confirm_delete")))
      {
        await this.$store.dispatch("deleteInputSupply", {id : supplyId});
        await this.$store.dispatch("inputStock/fetchEntries");
        this.init();
      }
    },
  },
  computed: {
    ...mapGetters({
      currentInput: "inputStock/currentEntry",
      inputSupplies: "inputSupplies",
     }),
    suppliesToPatch() {
      var suppliesClone = JSON.parse(JSON.stringify(this.inputSupplies));
      suppliesClone.sort(function(a, b) {
        var c = new Date(a.supplyDate);
        var d = new Date(b.supplyDate);
        return d-c;
      });
      return suppliesClone;
    },
  },
};
</script>

<style lang="scss" scoped>
/**
 * Params button carburant .
 */
.button-add-supply {
  background-color: $background-text-color;
  width: 250px;
}

.button-add-supply:hover {
  background: $secondary-light-color;
}

table {
  border-collapse: separate;
  border-spacing: 0 5px;
}

table > thead > tr,
th {
  font-size: 0.9em;
}

table > tbody > tr,
td {
  font-size: 0.87em;
  padding-bottom: 0rem!important;
}

.resize-date {
  width: 26.9%;
}

.resize-quantity {
  width: 26%;
}

.resize-hours {
  font-size: 0.92em;
}
</style>
