import { EventBus } from "@/eventBus.js";

export default {
  props: {
    // action to edit in the store
    storeEditAction: {
      type: String,
      default: "",
    },
    // getter to read the current entry in the store
    storeReadGetter: {
      type: String,
      default: "",
    },
    // getter to read the current edited entry in the store
    storeEditedGetter: {
      type: String,
      default: "",
    },
  },
  methods: {
    // start loading spinner
    loadOn() {
      EventBus.$emit("detailLoadingOn");
    },
    // stop loading spinner
    loadOff() {
      EventBus.$emit("detailLoadingOff");
    },
  },
  computed: {
    currentEntry() {
      return this.$store.getters[this.storeReadGetter];
    },
    currentEditedEntry() {
      return this.$store.getters[this.storeEditedGetter];
    },
    /**
     * Checks if there are any current entry or current edited entry
     */
    hasEntry() {
      return (Object.keys(this.currentEditedEntry) > 0 || this.currentEntry) 
    }
  },
};
