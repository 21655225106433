import VarietyApi from "@/api/variety.api";

export const GET_ALL_VARIETIES = "GET ALL VARIETIES";
export const VARIETY_LOADING = "LOAD VARIETY";

export default {
	state: {
		allVarieties: [],
	},
	getters: {
		allVarieties: state => state.allVarieties,
	},
	mutations: {

		[GET_ALL_VARIETIES]: (state, payload) => {
			if (payload.varieties.length) {
				state.allVarieties = payload.varieties;
			} else {
				state.allVarieties = []
			}
		},
		[VARIETY_LOADING]: (state, status) => {
			state.product_loaded = !status;
		},

	},
	actions: {

		async getAllVarieties({ commit }, payload) {
			commit(VARIETY_LOADING, true);
			const response = await VarietyApi.getAllVarieties(payload);
			commit(GET_ALL_VARIETIES, { varieties: response.data });
			commit(VARIETY_LOADING, false);
			return response.data;
		},
	},
};
