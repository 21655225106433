import Gateway from "./base";

export default class ProductFamilieskApi {
  /**
   * Get all product families
   *
   */
  static getProductFamilies() {
    return Gateway.get("/refapi/productFamilies/");
  }
}
