<template>
  <div class="chip">
    {{ label }}
    <span class="close-btn" @click="$emit('close')">&times;</span>
  </div>
</template>

<script>
export default {
  name: "FilterChips",
  props: {
    label: {
      type: String,
      default: "",
    },
    closeAction: {
      type: Function,
      require: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.chip {
  display: inline-block;
  margin: 0 5px 0 5px;
  padding: 0 10px;
  height: 30px;
  font-size: 12px;
  line-height: 30px;
  font-weight: bold;
  border-radius: 25px;
  background-color: $secondary-light-color;
  color: $secondary-dark-color;
}

.close-btn {
  padding-left: 10px;
  color: $secondary-dark-color;
  float: right;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}
</style>
