<template>
  <div>
    <b-row no-gutters class="mb-0">
      <b-col class="pr-3 col-8">
        <h4 class="subtitle-form mb-2">{{$t('cropfields.fertilization.intervention.interventionDate')}}</h4>
      </b-col>
      <b-col class="pr-0 col-4">
        <h4 class="subtitle-form mb-2">{{$t('cropfields.fertilization.intervention.input')}}</h4>
      </b-col>
    </b-row>

    <b-row no-gutters class="mb-0">
      <!-- Date de l'intervention -->
      <b-col class="pr-3 col-8">
        <input
          ref="date_intervention"
          type="date"
          v-model="interventionDate"
          placeholder="yyyy-mm-dd"
          required
        />
      </b-col>
      <!-- Apport de l'intervention -->
      <b-col class="pr-0 col-4 flex-container">
        <NumericInput
            ref="interventionInput"
            inputRef="input"
            :value="parseFloat(interventionInput)"
            @input="i => {interventionInput = i}"
            placeholder="Cible"
            class="resize-width"
            :required="true"
          />
        <button class="btn align-bottom" @click="onDelete()">
        <img :src="icon" alt="icon delete action" />
      </button>
      </b-col>
    </b-row>
    <b-row no-gutters class="mb-0">
      <!-- Produit utilisé -->
      <b-col class="pr-3 col-8 resize-input">
        <b-form-select
          :options="entriesByFamilyCode"
          value-field="value"
          text-field="text"
          v-model="interventionInputsStock"
          size="sm"
          @change="changeProduct();"
          required
        >
          <template #first>
            <b-form-select-option :value="null" disabled>-- Produit --</b-form-select-option>
          </template>
        </b-form-select>
      </b-col>
      <!-- La quantité  -->
      <b-col class="pr-0 col-4 resize-input flex-container">
        <NumericInput
            ref="focused"
            inputRef="input"
            name="input-stock"
            :value="parseFloat(interventionQuantity)"
            @input="i => {interventionQuantity = i}"
            placeholder="Dose"
            class="resize-width"
            :required="true"
          />
        <h4 class="text-form pl-1 mb-2">{{interventionDoseUnit ? interventionDoseUnit : ""}}</h4>
      </b-col>
    </b-row>
    <b-row no-gutters class="mb-3 mt-1">
      <!-- Azote brut -->
      <b-col class="pr-0 col-4">
        <div class="resize-padding-bottom">
          <h4 class="subtitle-form mb-2">
            {{$t('cropfields.fertilization.intervention.rawNitrogen')}}:
            <span class="text-form mb-2 resize-font">{{brutN ? brutN : 0}}{{unit_unit ? unit_unit : ""}}</span>
          </h4>
        </div>
      </b-col>
      <!-- Coefficient d'efficacité -->
      <b-col class="pr-0 col-4">
        <div class="resize-padding-bottom">
          <h4 class="subtitle-form mb-2">
            {{$t('cropfields.fertilization.intervention.efficiencyCoeff')}}:
            <span class="text-form mb-2 resize-font">
              {{percentMineralizableNitrogen ? percentMineralizableNitrogen : ""}}
              <span v-show="percentMineralizableNitrogen != '' ">%</span>
            </span>
          </h4>
        </div>
      </b-col>
      <!-- Azote efficace -->
      <b-col class="pr-0 col-4">
        <div class="resize-padding-bottom">
          <h4 class="subtitle-form mb-2">
            {{$t('cropfields.fertilization.intervention.effectiveN')}}:
            <span class="text-form mb-2 resize-font">
              {{effectiveNitrogen ? effectiveNitrogen : 0}}
              <span v-show="effectiveNitrogen != '' ">{{unit_unit ? unit_unit : ""}}</span>
            </span>
          </h4>
        </div>
      </b-col>
    </b-row>
    <hr />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/eventBus.js";
import delIcon from "@/assets/images/delete.svg";
import {checkDatePattern, checkTimePattern} from "@/utils/commons";
import NumericInput from "@/components/commons/NumericInput.vue";

export default {
  name: "edit-intervention",
  data() {
    return {
      icon: delIcon,
      brutN: null,
      effectiveNitrogen: null,
      percentMineralizableNitrogen: null,
      unit_unit: null,
      doseUnit_coefToReference: null,
      effectiveNitrogen_coefToReference: null,
    };
  },
  components: {NumericInput},
  created() {
    // Ecoute l'évènement de mise à jour de l'apport pour mettre également à jour la dose
    EventBus.$on("interventioninputchanged", this.updateInterventionQuantity);
    this.init();
  },
  methods: {
    init: async function() {
      /* Charger les caractéristiques du produit utilisé dans l'intervention */
      this.intervention = this.interventions[this.intervention_index];
      if(this.intervention.inputsStock) {
        this.interventionInputsStock = this.intervention.inputsStock.id;
        this.percentMineralizableNitrogen = this.intervention.inputsStock.percentMineralizableNitrogen;

        this.doseUnit_coefToReference = this.intervention.inputsStock.usages[0].doseUnit_coefToReference;
        let index = this.intervention.inputsStock.composition.findIndex(element => element.compound_symbol === 'N')
        if(index != -1) {
          this.effectiveNitrogen_coefToReference = this.intervention.inputsStock.composition[index].unit_coefToReference;
        }
        this.intervention.inputsStock.composition.map( element => {
          if(element.compound_symbol === 'N')
            {
              this.brutN = element.dose;
              this.unit_unit = element.unit_unit;
              this.effectiveNitrogen = this.brutN != "" && this.percentMineralizableNitrogen != "" ? this.brutN * (this.percentMineralizableNitrogen/100): '';
            }})
        this.interventionDoseUnit = (this.intervention.inputsStock.usages[0].doseUnit_unit);
      }
    },

    /**
     * Cette fonction sert à mettre à jour la dose lorsque l'apport à été modifié sans passer par la saisie au clavier
     */
    updateInterventionQuantity() {
      if(this.interventionInput && !this.interventionQuantity)
          this.setInterventionQuantity();
    },

    setInterventionQuantity() {
      /* Calcul de la quantité à partir de l'apport cible et enregistrement */
      if(this.effectiveNitrogen != null && this.effectiveNitrogen != 0) {
        let dose = this.interventionInput / (this.effectiveNitrogen * this.effectiveNitrogen_coefToReference *  this.doseUnit_coefToReference);
        dose = parseFloat(dose).toFixed(2);
        this.$store.commit("updateIntervention", { field: "dose", index:this.intervention_index,  intervention: dose });
      }
    },
    /**
     * Charger les caractéristiques du produit utilisé dans l'intervention
     */
    async changeProduct() {
      await this.$store.dispatch("inputStock/getInputStockFeatures",{
        id : this.interventionInputsStock
      });
      this.interventionDoseUnit = this.features.usages[0].doseUnit_unit;
      this.doseUnit_coefToReference = this.features.usages[0].doseUnit_coefToReference
      this.percentMineralizableNitrogen = this.features.percentMineralizableNitrogen;
      let index = this.features.composition.findIndex(element => element.compound_symbol === 'N')
      if(index != -1) {
        this.brutN = this.features.composition[index].dose;
        this.unit_unit = this.features.composition[index].unit_unit;
        this.effectiveNitrogen_coefToReference = this.features.composition[index].unit_coefToReference;
        this.effectiveNitrogen = this.brutN != "" && this.percentMineralizableNitrogen != "" ? this.brutN * (this.percentMineralizableNitrogen/100): '';
        if(this.effectiveNitrogen != null && this.effectiveNitrogen != 0 && this.interventionInput != null) {
          let dose = this.interventionInput / (this.effectiveNitrogen * this.effectiveNitrogen_coefToReference * this.doseUnit_coefToReference);
          dose = parseFloat(dose);
          this.interventionQuantity = dose.toFixed(2);
        }
      }
      else {
        this.brutN = ""; this.unit_unit = ""; this.effectiveNitrogen = '';  this.interventionQuantity = '';
      }
    },
    onDelete() {
      this.$emit('removeClicked', this.intervention_index);
    },
  },
  props: ['intervention_index', 'entriesByFamilyCode'],
  computed: {
    ...mapGetters({
      features : "inputStock/features",
      interventions : "interventions",
     }),
    /* Récuperer la date de l'intervention qui va être affiché dans le composnant depuis le store */
    interventionDate: {
      get() {
        return Object.keys(this.$store.state.fertilizationPlanInputs.interventions[this.intervention_index]).length != 0 ? JSON.parse(JSON.stringify(this.$store.state.fertilizationPlanInputs.interventions[this.intervention_index])).date : '';
      },
      set(value) {
        if(checkDatePattern(value))
          this.$store.commit("updateIntervention", { field: "date", index:this.intervention_index,  intervention: value });
      },
    },
    /* Récuperer l'apport de l'intervention qui va être affiché dans le composnant depuis le store */
    interventionInput: {
      get() {
        return this.$store.state.fertilizationPlanInputs.interventions[this.intervention_index].input ?? "";
      },
      set(value) {
        /* Enregistrement de l'apport dans le store */
        this.$store.commit("updateIntervention", { field: "input", index:this.intervention_index,  intervention: value });
        /* Calcul de la quantité à partir de l'apport cible et enregistrement */
        this.setInterventionQuantity();
      },
    },
    /* Récuperer le produit utilisé dans l'intervention qui va être affiché dans le composnant depuis le store */
    interventionInputsStock: {
      get() {
        return this.$store.state.fertilizationPlanInputs.interventions[this.intervention_index].inputsStock_id ?? "";
      },
      set(value) {
        this.$store.commit("updateIntervention", { field: "inputsStock_id", index:this.intervention_index,  intervention: value });
      },
    },
    /* Récuperer la quantité utilisé dans l'intervention qui va être affiché dans le composnant depuis le store */
    interventionQuantity: {
      get() {
        return this.$store.state.fertilizationPlanInputs.interventions[this.intervention_index].dose ?? "";
      },
      set(value) {
        /* Stockage de la quantité saisie dans le store */
        this.$store.commit("updateIntervention", { field: "dose", index:this.intervention_index,  intervention: value });
        /* Calcul de l'apport à partir de la quantité */
        if(this.effectiveNitrogen != null && this.effectiveNitrogen != 0) {
          let input = (this.interventionQuantity * this.doseUnit_coefToReference) * (this.effectiveNitrogen * this.effectiveNitrogen_coefToReference)
          input = parseFloat(input).toFixed(2);
          this.$store.commit("updateIntervention", { field: "input", index:this.intervention_index,  intervention: input });
        }
      },
    },
    /* Récuperer l'unité utilisé dans l'intervention qui va être affiché dans le composnant depuis le store */
    interventionDoseUnit: {
      get() {
        return this.$store.state.fertilizationPlanInputs.interventions[this.intervention_index].doseUnit_unit ?? "";
      },
      set(value) {
        this.$store.commit("updateIntervention", { field: "doseUnit_unit", index:this.intervention_index,  intervention: value });
      },
    },
  },
};
</script>

 <style lang="scss" scoped>
/**
 * text form.
 */
.text-form {
  font-size: 12px;
  font-weight: bold;
  margin: 5px 0 5px;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

.resize-padding-bottom {
  height: 30px;
}

.resize-input {
  padding-top: 0.7rem;
}

.resize-font {
  font-size: 11px;
  color: $primary-color;
}

button {
  font-size: 0.8em;
  font-weight: bold;

  img {
    width: 0.88em;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.flex-container {
  display: flex;
}

.resize-width {
  width:70%
}
</style>