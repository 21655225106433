<template>
  <!-- Treatment Component : Date -->
  <!-- Displays a date selector-->
  <!-- A title can be specified through titleOption prop and the name of the attribute with returnedAttribute -->
  <div class="mt-4">
    <h4 class="subtitle-form mb-2">{{ $t(this.titleOption) }}</h4>
    <!-- display the date selector -->
    <b-row>
      <b-col>
        <input type="date"
          v-model="dateChoice"
          locale="fr"
          class="mb-2"
          placeholder="yyyy-mm-dd"
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TreatmentFormPartMixin from "@/components/commons/treatments/TreatmentFormPartMixin";
import { TreatmentFormPart } from "@/models/treatments/Treatment.js";
import { dateToEnglishDashString } from "@/utils/commons";
import {checkDatePattern, checkTimePattern} from "@/utils/commons";

export default {
  mixins: [TreatmentFormPartMixin],
  data() {
    return {
      dateChoice: dateToEnglishDashString(new Date()),
    };
  },
  mounted() {
  },
  created() {
    // this.init()
  },

  methods: {
    validateForm() {
      if(!checkDatePattern(this.dateChoice))
        this.isValid = false;
      else
        this.isValid = true;
      return new TreatmentFormPart(this.returnedAttribute, this.dateChoice);
    },
  },
  props: {
    titleOption: {
      type: String,
      default: () => 'Date',
    },
    returnedAttribute: {
      type: String,
      default: () => 'date',
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
