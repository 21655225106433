import Gateway from "./base";

export default class PeriodsApi {
  /**
   * Get All periods
   */
  static getPeriods() {
    return Gateway.get("/refapi/periods/");
  }

  /**
   * Get sorted periods
   * 
   * @param params
   */
  static getSortedPeriods(params) {
    return Gateway.get("/refapi/periods/", params);
  }
}
