<template>
  <div>
    <!-- Affiche d'un champ quelconque -->
    <div v-if="value == undefined || !value" cols="12" class="text-activity-notes empty-attr text-left">
      {{ empty || $t("no_value_given") }}
    </div>
    <b-row v-else class="m-0">
      <b-col  cols="12" class="bg-white text-infos">{{ value }} {{ unit}}</b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    unit: {
      type: String,
      default: "",
    },
    empty: {
      type: String,
      default: null,
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
