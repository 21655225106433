<template>
  <div>
		<div v-if="loaded">
			<div v-for="type in nonTreatedZoneTypes" :key="type">
				<b-row class="whitout-bg">
					<!-- Label du type de ZNT -->
					<b-col cols="7" class="font-weight-bold">
						{{ getTypeLabel(type)}}
					</b-col>
					<!-- Réductible ? -->
					<b-col cols="5" class="italic text-right pr-4" v-if="reducible[type]">
						{{ $t("cropfields.list.reducible")}}
					</b-col>
				</b-row>
				<div v-if="surfaces[type] && surfaces[type].length > 0" class="text-infos bg-white mb-2 pb-0 pl-0">
					<b-row v-for="znt in surfaces[type]" :key="znt.width" class="mx-0">
						<!-- Label de la largeur de la ZNT -->
						<b-col cols="5" class="pr-0">
							{{ $t("cropfields.list.znt_to")}} {{ znt.width }} {{ $t("unitDenomination.unit_m") }}
						</b-col>
						<!-- Surface de la ZNT -->
						<b-col cols="7" class="pb-1 text-right">
							{{ znt.surface }} {{ $t("unitDenomination.hectare") }}
						</b-col>
					</b-row>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
			surfaces: {},
			loaded: false,
			nonTreatedZoneTypes: [],
			reducible: {},
    };
  },
  async created() {
		this.loaded = false;
		await this.init();
		this.loaded = true;
  },
  computed: {
    ...mapGetters({
			nonTreatedZones: "nonTreatedZones",
			nonTreatedZoneTypesRef: "nonTreatedZoneTypes",
			currentEntry: "cropfield/currentEntry"
			}),
  },
  methods: {
    ...mapActions({
			getNonTreatedZones: "getNonTreatedZones",
			getNonTreatedZoneTypes: "getNonTreatedZoneTypes"
		}),

		async init() {
			// On requête pour avoir la liste de tous les types de ZNT (utile pour avoir les désignation)
			if(this.nonTreatedZoneTypesRef.length <= 0) await this.getNonTreatedZoneTypes();
			if (this.currentEntry && this.currentEntry.id){
				// On récupère les ZNT définies sur la parcelle
				this.nonTreatedZoneTypes = [];
				this.currentEntry.nonTreatedZoneTypes.forEach(el => {
					this.nonTreatedZoneTypes.push(el.nonTreatedZoneType);
					this.reducible[el.nonTreatedZoneType] = el.canBeReduced;
				});

				await this.getNonTreatedZones({cropField: this.currentEntry.id});
				// On remplit la structure nécessaire à l'affichage
				let types = [];
				this.surfaces = {};
				this.nonTreatedZones.forEach(znt => {
					if (!types.includes(znt.type_code)) {
						types.push(znt.type_code);
						this.surfaces[znt.type_code] = [];
					}
					this.surfaces[znt.type_code].push({width: znt.width, surface: znt.surface, designation: znt.type_designation});
				});
			}
		},

		/**
		 * Retourne la désignation d'un type de znt à partir de son code
		 */
		getTypeLabel(code) {
			return this.nonTreatedZoneTypesRef.find(type => type.code == code)?.designation;
		},
  },
	watch: {
		currentEntry: {
			deep: true,
			handler: async function() {
				await this.init();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
	.italic {
		font-style: italic;
	}
</style>
