<template>
  <div>
    <b-row class="mb-2" v-if="entry && entry.PercentMineralizableNitrogen">
      <b-col cols="4">
        <h4 class="subtitle-form">{{ $t("inputStock.details.npro") }}</h4>
      </b-col>
      <b-col cols="8" class="d-flex align-items-start">
        <div class="bg-text-color text-infos config-density-semence">
          {{ (entry && entry.PercentMineralizableNitrogen) || "N/A" }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import RefProductMixin from "@/mixins/RefProductMixin";

import { mapGetters } from "vuex";

export default {
  mixins: [DetailUpdateMixin, RefProductMixin],
  computed: {
    ...mapGetters({
      inputsStock: "inputStock/entries",
      currentEntry: "inputStock/currentEntry",
      currentEditedEntry: "inputStock/editedEntry",
    }),
  }
};
</script>

<style lang="scss" scoped></style>
