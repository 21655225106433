import Gateway from "./base";

export default class TreatmentApi {
  /**
   * Create treatment
   *
   * @param data
   *
   */
  static createTreatment(taskName, data) {
    if ( data.get('filefeature') ) {
      if (taskName === "import_inputstock_from_file")
      {
        data.append('productFamily_code', data.get('filefeature'));
        data.delete('filefeature');
      }
    }
    return Gateway.post(`/treatmentsapi/treatment/?task=` + taskName, data, { "Content-Type": "multipart/form-data" });
  }

  /**
   * Get a treatment
   *
   * @param task_id
   *
   */
  static getTreatment({ task_id }) {
    return Gateway.get(`/treatmentsapi/treatment/${task_id}/`);
  }
}
