<template>
  <div class="d-flex">
    <ActivityImage
      :activityIcon="activityIcon"
      :activityBgColor="activityBgColor"
      :activityStrokeColor="activityStrokeColor"
      :square="true"
    />
    <div class="d-flex flex-column justify-content-center">
      <div class="font-14 position-relative">
        {{ activityType }}

        <div class="d-block font-12" style="font-weight: normal">
          {{ firstInputs(2) }}
        </div>
        <span class="d-inline-block font-12" v-if="inputs && inputs.length > 2">
          <b-button :id="'popover-inputs-' + rowId" type="button" class="badge badge-pill badge-secondary">
            <span>+{{ inputs.length - 2 }}</span>
          </b-button>
        </span>

        <b-popover :target="'popover-inputs-' + rowId" triggers="hover" placement="auto" boundary="window">
          <div v-for="inputStock in inputs" :key="inputStock.id">
            {{ inputStock.inputsStock_name }}
          </div>
        </b-popover>
      </div>
    </div>
  </div>
</template>

<script>
import ActivityImage from "@/components/activity/list/ActivityImage";

export default {
  props: ["rowId", "rowData"],
  computed: {
    activityIcon() {
      return this.rowData.activityType_iconPath;
    },
    activityBgColor() {
      return this.rowData.activityType_iconBGColor;
    },
    activityStrokeColor() {
      return this.rowData.activityType_iconStrokeColor;
    },
    activityType() {
      return this.rowData.activityType_designation;
    },
    inputs() {
      return this.rowData.inputs;
    },
  },
  components: {
    ActivityImage,
  },
  methods: {
    firstInputs(n) {
      if (!this.inputs) return "";
      return this.inputs
        .slice(0, n)
        .map(i => i.inputsStock_name)
        .join(", ");
    },
  },
};
</script>

<style lang="scss" scoped>
.tracker-icon img {
  width: 18px;
  margin-right: 3px;
  margin-top: -20px
}
</style>
