<template>
  <div v-if="loaded">
    <!-- Liste des notes prises -->
    <div>
      <Notes ref="notes" :editedNoteId="noteToPatch.id" @editNote="setNoteToPatch"/>
    </div>
    <!-- Section d'édition d'une note -->
    <div class="text-infos bg-white mt-0 card">
      <!-- Affiche l'image téléchargée avec une option de suppression -->
      <div v-if="imageUrl" class="image-container">
        <img :src="imageUrl" alt="Prévisualisation" class="preview-image" />
        <span class="remove-icon" @click="removeImage">✖</span>
      </div>
			<b-row>
				<!-- Sélecteur de la catégorie de note -->
				<b-col cols="8" class="mt-1">
					<Dropdown
						ref="dropdown"
						label="designation"
						:options="noteTopics"
						v-model="selectedTopic"
						:placeholder="$t('components.EditNotes.noteTopicPlaceholder')"
            :clearable="false"
					/>
				</b-col>
        <!-- Bouton pour ajouter une image -->
				<b-col cols="1" class="pl-0">
          <button class="btn pl-1" @click="triggerFileSelect" :title="$t('add_image')">
            <!-- Icône pour déclencher l'upload -->
            <img :src="uploadIcon" alt="upload" class="icon" />
          </button>
          <input
            type="file"
            ref="fileInput"
            @change="onFileChange"
            accept=".png,.jpg,.jpeg"
            style="display: none;"
          />
				</b-col>
				<!-- Bouton de réinitialisation -->
				<b-col cols="1" class="pl-0" v-if="canSaveOrReset">
					<button class="btn" @click="reset" :title="$t('cancel')">
						<img :src="cancelIcon" class="icon" alt="icon cancel action" />
					</button>
				</b-col>
        <!-- Bouton d'enregistrement -->
				<b-col cols="2" class="pl-2" v-if="canSaveOrReset">
					<button class="btn" @click="save" :title="$t('save')">
						<img :src="saveDisk" class="icon" alt="icon save action" />
					</button>
				</b-col>
			</b-row>
			<b-row class="mt-2" ref="editingArea">
				<!-- Zone de saisie du texte de la note -->
				<b-col cols="12">
					<b-textarea
            ref="textarea"
						rows="3"
						v-model="noteToPatch.text"
						class="text-infos whitout-bg py-1 px-2"
						:placeholder="$t('components.EditNotes.notePlaceholder')"
					/>
				</b-col>
			</b-row>
		</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Notes from "@/components/activity/details/Notes.vue";
import Dropdown from '@/components/activity/edit/Dropdown.vue';

import cancelIcon from "@/assets/images/cancel_cross_icon.svg";
import saveDisk from "@/assets/images/save_disk.svg";
import uploadIcon from "@/assets/images/upload_image.svg";

import Compressor from 'compressorjs';

export default {
  data() {
    return {
      noteToPatch: {},
      cancelIcon: cancelIcon,
      saveDisk: saveDisk,
      uploadIcon: uploadIcon,
      selectedTopic: null,
      loaded: false,
      imageUrl: null,
    };
  },
  props: ["id"],
  components: {
    Notes,
    Dropdown
  },
  async mounted() {
    this.loaded = false;
    await this.$store.dispatch("getNoteTopics");
    // On sélectionne "Observation de terrain" comme catégorie par défaut
    this.selectedTopic = this.noteTopics.find(nt => nt.designation == "Observation de terrain");
    this.loaded = true;
  },

  computed: {
    ...mapGetters({
      noteTopics: "noteTopics",
      notes: "notes",
      currentEntry: "cropfield/currentEntry"
    }),
    /**
     * On ne permet d'enregistrer ou d'annuler une note, quand il y a déjà une saisie de texte ou une image téléchargée
     */
    canSaveOrReset() {
      return !!this.noteToPatch.text || !!this.imageUrl;
    }
  },
  methods: {

    triggerFileSelect() {
      this.$refs.fileInput.click();
    },
    onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        // Compresser l'image avant de l'utiliser
        new Compressor(file, {
          quality: 0.8, // la qualité de l'image (0 à 1)
          maxWidth: 2000, // Largeur maximale de l'image
          maxHeight: 2000, // Hauteur maximale de l'image
          success: (compressedResult) => {
            this.noteToPatch["imageFile"] = compressedResult;
            this.imageUrl = URL.createObjectURL(compressedResult);
          },
          error(err) {
            console.error('Erreur de compression:', err.message);
          },
        });
      }
    },
    removeImage() {
      this.noteToPatch["imageFile"] = null;
      this.imageUrl = null;
      this.$refs.fileInput.value = ''; // Réinitialise le champ de fichier
    },
    /**
     * Fonction appélée lorsqu'on clique sur le bouton de modification d'une note
     * Elle présaisie les données de la note dans la zone d'édition
     */
    setNoteToPatch(noteId) {
      let editedNote = this.notes.find(n => n.id == noteId);
      if (editedNote) {
        // On récupère les attributs de la note à modifier
        editedNote = {...editedNote};
        // On extrait le topic et l'image pour les afficher sans toutefois les repatcher s'ils ne sont pas modifiés
        let editedNoteTopic = editedNote.noteTopic;
        let editedNoteTopicDesignation = editedNote.topic_designation;
        delete editedNote.noteTopic;

        if (editedNote.imageFile) {
          this.imageUrl = editedNote.imageFile;
          delete editedNote.imageFile;
        }

        this.noteToPatch = {
          ...this.noteToPatch,
          ...editedNote
        }

        if (editedNoteTopic) {
          this.selectedTopic = {
            id: editedNoteTopic,
            designation: editedNoteTopicDesignation
          }
        }
        // Scroll jusqu'à la zone d'édition
        this.scrollToEditingArea();
      }
    },

    /**
     * Enregistrement d'une note
     */
    async save() {
      const formData = new FormData();
      formData.append('text', this.noteToPatch.text);
      formData.append('noteTopic', this.noteToPatch.noteTopic.id);
      if (this.noteToPatch.imageFile?.name) {
        formData.append('imageFile', this.noteToPatch.imageFile, this.noteToPatch.imageFile.name);
      }
      if (this.noteToPatch.imageFile === null) {
        formData.append('imageFile', '');
      }
      // S'il y'a un id de note il s'agit d'une modification
      if (this.noteToPatch.id) {
        await this.$store.dispatch("updateNote", {id: this.noteToPatch.id, data: formData });
      } else {
        // Sinon, il s'agit d'une création
        formData.append('cropField', this.currentEntry.id);
        await this.$store.dispatch("createNote", {data: formData });
      }
      this.reset();
      await this.$refs["notes"].init();
    },

    /**
     * Réiniatialise la zone de saisie
     */
    reset() {
      this.removeImage();
      this.noteToPatch = {
        noteTopic: this.selectedTopic
      }
    },

    /**
     * Description: Scroll to editing area
     */
    scrollToEditingArea() {
      const editingArea = this.$refs["editingArea"];
      if(editingArea) {
        editingArea.scrollIntoView({behavior: 'smooth'});
        this.$refs["textarea"].focus();
      }
    },
  },

  watch: {
    selectedTopic: {
      handler() {
        this.noteToPatch.noteTopic = this.selectedTopic;
      }
    }
  }

};
</script>

<style lang="scss" scoped>
.history {
  background: $light-grey;
  padding: 8px;
}

.history-group {
  thead {
    display: none;
  }

  td {
    padding: 0.4rem !important;
  }
}
.title {
  border-bottom: 1px solid $grey;
  padding: 4px;

  span {
    font-weight: bold;
  }
}

.card {
  border: solid;
  border-color: $dark-grey !important;
}

.icon {
  width: 1rem;
  height: 1rem;
}

.image-container {
  position: relative;
  display: inline-block;
}

.preview-image {
  max-width: 200px;
  max-height: 200px;
  display: block;
}

.remove-icon {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #dc3545;
  color: white;
  border-radius: 50%;
  padding: 6px;
  cursor: pointer;
  display: none;
}

.image-container:hover .remove-icon {
  display: block;
}
</style>
