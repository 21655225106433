<template>
  <div>
    <!-- Dose min and max -->
    <table>
      <!-- Dose min -->
      <tr>
        <td class="shape-first-cell">
          <h4 class="subtitle-form">Dose mini:</h4>
        </td>

        <td class="bg-text-color text-infos config-dose-semence">
          {{ currentInputStock.minDoseValue | empty("0") }} {{ currentInputStock.doseUnit_unit | empty("") }}
        </td>
      </tr>

      <!-- Dose max -->
      <tr>
        <td class="shape-first-cell">
          <h4 class="subtitle-form">Dose maxi:</h4>
        </td>

        <td class="shape-second-cell bg-text-color text-infos config-dose-semence">
          {{ currentInputStock.maxDoseValue | empty("0") }} {{ currentInputStock.doseUnit_unit | empty("") }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "dose",
  data() {
    return {};
  },
  components: {},
  methods: {},
  computed: {
    ...mapGetters({
      currentInputStock: "inputStock/currentEntry",
    }),
  },
};
</script>

<style lang="scss" scoped>
/**
 * Config for the dose.
 */
.config-dose-semence {
  text-align: center;
  padding: 0px 5px 0px 5px !important;
  width: 100px;
}

.shape-first-cell {
  width: 120px;
}

table {
  border-collapse: separate;
  border-spacing: 5px 5px;
}
</style>
