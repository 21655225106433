<template>
  <div>
    <!-- Treatment Component : Farm -->
    <!-- Implicit component : no template but add to form the Farm id -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import TreatmentFormPartMixin from "@/components/commons/treatments/TreatmentFormPartMixin";
import { TreatmentFormPart } from "@/models/treatments/Treatment.js";

const FARM_ALL = "all";

export default {
  mixins: [TreatmentFormPartMixin],
  methods: {
    validateForm() {
      this.isValid = true;

      let farmId;
      if (this.currentFarm) farmId = this.currentFarm.id;
      else if (this.farms.length == 1) farmId = this.farms[0].id;
      else farmId = FARM_ALL;

      return new TreatmentFormPart("farm_id", farmId);
    },
  },
  computed: {
    ...mapGetters({
      currentFarm: "farm/currentFarm",
      farms: "farm/farms",
    }),
  },
};
</script>

<style></style>
