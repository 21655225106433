<template>
  <div>
    <!-- Boîte alertant l'utilisateur que l'activité est déjà exportée dans smag et que les modifications n'y seront pas diffusées -->
    <b-modal ref="exportedActivityWarning" id="modal-1" header-bg-variant="success" centered hide-header>
      <b-row align-v="center" class="m-3">
        <b-col cols="12" class="d-flex justify-content-center">
          <span v-html="$t('activities.details.is_already_exported_smag')"></span>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100 d-flex justify-content-end align-items-center">
          <b-button class="btn btn-success mr-2" @click="gotoEdit">
            {{ $t("edit") }}
          </b-button>
          <b-button class="btn btn-primary" @click="hideModal">
            {{ $t("discard") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { EventBus } from "@/eventBus.js";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      generatedActivitiesNumber: null,
    };
  },
  created() {
    EventBus.$on("showExportedActivityWarningModal", this.showModal);
  },
  beforeDestroy() {
    EventBus.$off("showExportedActivityWarningModal", this.showModal);
  },
  computed: {
    ...mapGetters({
      currentActivity: "activity/currentEntry",
    }),
  },
  methods: {
    /**
     * @description Affiche la boîte
     */
    showModal() {
      this.$refs.exportedActivityWarning.show();
    },
    /**
     * @description Masque la boîte
     */
    hideModal() {
      this.$refs.exportedActivityWarning.hide();
    },

    /**
     * @description Affiche l'activité en mode édition
     */
    async gotoEdit() {
      this.hideModal();
			this.$router.push({ name: "activity.edit", params: { activity_id: this.currentActivity.id } });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>