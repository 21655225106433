import * as turf from "@turf/turf";
import _ from "lodash";

import {
  writeGeometriesToLayer,
  getGeometryFromFeature,
  project3857,
  project4326,
  multiPolygonToPolygon,
} from "@/utils/geomatics";

import { EventBus } from "@/eventBus.js";

import Map from "@/views/cropfields/map/Map";
import CropfieldApi from "@/api/cropfield.api";
import { mapStyles } from "@/views/cropfields/map.config";

export default {
  data() {
    return {
      // if we are on the rpg mode
      createFromRpgMode: null,
      // layer of the rpg, for open layers
      rpgLayer: null,
      // the feature we hover
      rpgHoverFeature: null,
      // the feature we click on
      rpgSelectFeature: [],
      // current zoom event listener
      zoomListener: null
    };
  },
  mounted() {
    if (this.minimal) return;

    EventBus.$on("showRPG", this.showRPG);
    EventBus.$on("disableRPG", this.disableRPG);
    EventBus.$on("mapZoom", this.onZoom);
  },
  methods: {
    /**
     * Initialize the RPG display
     */
    init() {
      this.createFromRpgMode = true;

      // prepare the layer
      this.rpgLayer = new ol.layer.Vector({ source: new ol.source.Vector(), style: this.getRPGLayerStyle });
      this.rpgLayer.setZIndex(6);
      this.map.addLayer(this.rpgLayer);

      // and the listeners
      this.map.on("pointermove", this.onRPGPointerMove);
      this.map.on("click", this.onRPGClick);
    },
    /**
     * Show alert
     */
    showAlert: _.throttle(function() {
      EventBus.$emit("toast", {
        message: this.$i18n.t("cropfields.details.zoom_more"),
        variant: "danger",
      });
    }, 6000),
    /**
     * Show RPG data on layer
     * source can be : rpg, local, cadastral
     */
    async showRPG({rpgName, source='rpg'}) {
      // constraint : if the zoom is too low, return & show alert
      if (Map.getZoom() <= mapStyles.normal.rpgMinimumZoomLevel) {
        this.showAlert()

        // however, we attach a listener on map zoom
        // that will trigger this function
        this.zoomListener = function() { this.showRPG({rpgName, source}); }
        return;
      } else {
        // remove zoom listener, its becomes useless
        this.zoomListener = null
      }

      if (!this.rpgLayer) this.init();

      // get the bouding box
      const bbox = Map.getExtent4326();

      // fetch the data
      let result = {}
      if (source == "rpg") result = await CropfieldApi.getRPG(rpgName, bbox);
      if (source == "local") {
        result['data'] = {};
        result['data']['features'] = this.geometriesToSelect;
      }
      if (source == "cadastral") {
        result = await CropfieldApi.getCadastralParcels("CADASTRALPARCELS.PARCELLAIRE_EXPRESS:parcelle", bbox);
        for (let i = 0; i < result.data.features.length; i++) {
          result.data.features[i].properties["code_cultu"] = result.data.features[i].properties["nom_com"] + " " + result.data.features[i].properties["numero"];
          result.data.features[i].properties["surf_parc"] = _.round(result.data.features[i].properties["contenance"]/10000, 2);
        }
      }

      // mark the features with 'is_rpg' key
      for (let i = 0; i < result.data.features.length; i++) result.data.features[i].properties["is_rpg"] = true;

      // display on map
      let collection = turf.featureCollection(result.data.features);
      collection = collection.features.map((f) => project3857(f));

      writeGeometriesToLayer(collection, this.rpgLayer, true);
    },
    /**
     * Disable RPG
     */
    disableRPG() {
      if (this.rpgLayer) {
        this.rpgLayer.getSource().clear();
        this.rpgLayer.getSource().dispatchEvent("change");
      }
      this.rpgLayer = null;

      this.rpgHoverFeature = null;
      this.rpgSelectFeature = [];

      if(this.map){
        this.map.un("pointermove", this.onRPGPointerMove);
        this.map.un("click", this.onRPGClick);
      }

      this.createFromRpgMode = false;

      // remove current zoom listener
      this.zoomListener = null
    },
    /**
     * On Zoom
     */
    onZoom() {
      this.zoomListener?.()
    },
    /**
     * Hover display, when the pointer move
     */
    onRPGPointerMove(e) {
      if (this.rpgHoverFeature && !this.rpgSelectFeature.includes(this.rpgHoverFeature)) {
        this.rpgHoverFeature.setStyle(this.getRPGLayerStyle);
        this.rpgHoverFeature = null;
      }

      // we make sure here we don't hover a normal non-rpg cropfield
      let nonRPGFound = false;
      let foundFeature = null;
      this.map.forEachFeatureAtPixel(e.pixel, function (f) {
        if (f.getProperties().is_rpg !== true) nonRPGFound = true;
        foundFeature = f;
        return true;
      });

      if (!nonRPGFound && foundFeature) {
        this.rpgHoverFeature = foundFeature;
        if (!this.rpgSelectFeature.includes(this.rpgHoverFeature)) {
          foundFeature.setStyle(this.getRPGLayerHoverStyle);
        }
      }
    },
    /**
     * Click display
     */
    onRPGClick(e) {
      // we make sure here we don't click on a normal non-rpg cropfield
      let nonRPGFound = false;
      let foundFeature = null;
      this.map.forEachFeatureAtPixel(e.pixel, function (f) {
        if (f.getProperties().is_rpg !== true) nonRPGFound = true;
        foundFeature = f;
        return true;
      });

      if (!nonRPGFound && foundFeature) {
        if (this.selectionMultiple) {
          if (this.rpgSelectFeature.includes(foundFeature)) {
            // Désélectionne la géométrie cliquée si elle etait sélectionnée
            foundFeature.setStyle(this.getRPGLayerStyle);
            this.rpgSelectFeature = this.rpgSelectFeature.filter(f => f != foundFeature);
          } else {
            // Sinon, sélectionne
            this.rpgSelectFeature.push(foundFeature);
          }
        } else {
          // Déselectionne tout
          this.rpgSelectFeature.forEach(f => f.setStyle(this.getRPGLayerStyle))
          this.rpgSelectFeature = [];
          // Sélectionne la géometrie cliquée
          this.rpgSelectFeature.push(foundFeature);
        }

        // On fait une union de toutes les géométries sélectionnées
        // Si elles ne sont pas contigües, on indique que la fusion n'est pas possible
        let overlap;
        if (this.rpgSelectFeature.length > 0) {
          try{
            let polygons = this.rpgSelectFeature.map(f => {
              const feature = project4326(getGeometryFromFeature(f));
              const polygon = feature.geometry.type != "Polygon" ? multiPolygonToPolygon(feature) : feature;
              return {
                geometry: turf.buffer(turf.polygon(polygon.geometry.coordinates), 0.003),
                surface: polygon.properties.surf_parc,
                id: polygon.properties.id
              };
            }); // We enlarge the polygons in case there are very close
  
            overlap = {
              geometry: turf.union(...polygons.map(p => p.geometry)),
              surface: _.round(polygons.reduce((acc, item) => acc + item.surface, 0), 2),
              id: polygons.map(p => p.id)
            };
          } catch(error) {
            console.log("merge not possible");
            console.log(error);
          }
        }

        if (overlap && turf.getType(overlap.geometry) == "Polygon") {
          overlap.geometry = turf.buffer(overlap.geometry, -0.003).geometry;
          EventBus.$emit("selectRPGFeature", { surface: overlap.surface, geometry: overlap.geometry, id: overlap.id, mergeError: false });
        } else {
          let mergeError = false;
          if (overlap) mergeError = true;
          EventBus.$emit("selectRPGFeature", { surface: null, geometry: null, id: [], mergeError: mergeError });
        }
        this.rpgSelectFeature.forEach(f => f.setStyle(this.getRPGLayerSelectStyle))
      }
    },
    /**
     * Styles
     */
    getRPGLayerText(feature) {
      return new ol.style.Style({
        text: new ol.style.Text({
          text: feature.getProperties().code_cultu + "\n" + feature.getProperties().surf_parc + " ha",
          textBaseline: "top",
          overflow: true,
          scale: 1.5,
          fill: new ol.style.Fill({
            color: "#FFFFFFFF",
          }),
          stroke: new ol.style.Stroke({
            color: "#000000FF",
            width: 2,
            miterLimit: 100,
          }),
        }),
      });
    },

    getRPGLayerStyle(feature) {
      return [
        new ol.style.Style({
          fill: new ol.style.Fill({
            color: mapStyles.normal.rpgBackgroundColor,
          }),
          stroke: new ol.style.Stroke({
            color: mapStyles.normal.rpgEdgeColor,
            width: mapStyles.normal.rpgEdgeSize,
          }),
        }),
      ];
    },

    getRPGLayerHoverStyle(feature) {
      return [
        new ol.style.Style({
          fill: new ol.style.Fill({
            color: mapStyles.hover.rpgBackgroundColor,
          }),
          stroke: new ol.style.Stroke({
            color: mapStyles.hover.rpgEdgeColor,
            width: mapStyles.hover.rpgEdgeSize,
          }),
        }),
        this.getRPGLayerText(feature),
      ];
    },

    getRPGLayerSelectStyle(feature) {
      return [
        new ol.style.Style({
          fill: new ol.style.Fill({
            color: mapStyles.active.backgroundColor,
          }),
          stroke: new ol.style.Stroke({
            color: mapStyles.active.edgeColor,
            width: mapStyles.normal.rpgEdgeSize,
          }),
        }),
        this.getRPGLayerText(feature),
      ];
    },
  },
};
