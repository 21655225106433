<template>
  <div>
    <!-- Treatment Component : StockStore -->
    <!-- Displays a StockStore selector -->
    <h4 class="subtitle-form mb-2">
      {{ $t("commons.exports_reports.stockStore_selection_title") }}
    </h4>
    <b-form-select v-model="stockStoreChoice" :options="stockStoreOptions"></b-form-select>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import TreatmentFormPartMixin from "@/components/commons/treatments/TreatmentFormPartMixin";
import { TreatmentFormPart } from "@/models/treatments/Treatment.js";

export default {
  mixins: [TreatmentFormPartMixin],
  data() {
    return {
			stockStoreChoice: null,
    };
  },
	mounted() {
		this.initChoice();
	},
  methods: {
    validateForm() {
      this.isValid = true;

      return new TreatmentFormPart(
        "stockStore",
        this.stockStoreChoice,
      );
    },
		async initChoice() {
      // Si on est sur "Toutes" les exploitations, on présélectionne le stock par défaut s'il y'en a
			if(!this.currentFarm) {
				this.stockStores.forEach(el => {
					if (el.default) {
						this.stockStoreChoice = el.id;
					}
				});
			} else if (this.currentFarm?.stockStore) {
        // Si on est sur une exploitation, on présélectionne le stock associé à celle-ci, s'il y'en a
				let st = await this.$store.dispatch("stockStores/getStockStore", { id: this.currentFarm.stockStore });
				if (st.id) {
					this.stockStoreChoice = st.id;
				}
			}
		}
  },
  computed: {
    ...mapGetters({
      stockStores: "stockStores/stockStores",
      currentFarm: "farm/currentFarm",
    }),
    stockStoreOptions() {
      return this.stockStores.map((el) => {
        return { value: el.id, text: el.name };
      });
    },
  },
  watch: {
    currentFarm: {
			handler() {
				this.initChoice();
			}
    },
		stockStores: {
			handler() {
				this.initChoice();
			}
		}
  },
};
</script>

<style></style>
