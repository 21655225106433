<template>
  <div class="home-widget">
    <b-container>
      <b-row class="home-widget-header">
        <b-col class="align-items-center">
          <h3 class="home-widget-title my-3">{{ $t("home.activities.title") }}</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="max-height">
          <b-row
            v-for="(activity, index) in activities"
            :key="index"
            class="activity-row align-items-center"
            @click="goToActivity(activity.id)"
          >
            <b-col cols="3"
              ><ActivityImage
                :activityIcon="activity.activityType_iconPath"
                :activityBgColor="activity.activityType_iconBGColor"
                :activityStrokeColor="activity.activityType_iconStrokeColor"
            /></b-col>
            <b-col cols="9">
              <div class="d-flex justify-content-between align-items-start">
                <h4>{{ activity.activityType_designation }}</h4>
                <span class="date">{{ activity.startDate | datetime("dd/MM/yyyy", "fr") }}</span>
              </div>
              <div class="mt-2">
                <span class="cropfield">{{ activity.cropField_name }}</span>
                {{ activity.cropField__crop_name }}
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ActivityApi from "@/api/activity.api";
import ActivityImage from "@/components/activity/list/ActivityImage";
import { mapGetters } from "vuex";

import { EventBus } from "@/eventBus.js";

export default {
  data() {
    return {
      activities: [],
    };
  },
  components: {
    ActivityImage,
  },
  mounted() {
    // on page initialization, when global filters are loaded
    EventBus.$on("globalFiltersLoaded", async ({ hasFarmSelected }) => {
      // if there is farm selected, data will be fetched on initialization by the watchers, so we return
      if (hasFarmSelected) return;

      this.fetchActivities();
    });

    // if components loads (tab changed) and filters are already loaded
    if (this.globalFiltersLoaded) this.fetchActivities();
  },
  methods: {
    async fetchActivities() {
      const response = await ActivityApi.getActivityWithInputsNeeded({
        creator: "all",
        ordering: "-startDate",
        campaign: this.currentCampaign.id,
        farm_id: this.currentFarm && this.currentFarm.id,
      });
      this.activities = response.data;
    },
    goToActivity(id) {
      this.$router.push({ name: "activity.details", params: { activity_id: id } });
    },
  },
  computed: {
    ...mapGetters({
      currentCampaign: "getCampaign",
      currentFarm: "farm/currentFarm",
      globalFiltersLoaded: "user/globalFiltersLoaded",
    }),
  },
  watch: {
    async currentCampaign() {
      if (!this.globalFiltersLoaded) return;

      this.fetchActivities();
    },
    async currentFarm() {
      this.fetchActivities();
    },
  },
};
</script>

<style lang="scss" scoped>
h4 {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0;
}

.date {
  color: $grey;
}

.cropfield {
  font-size: 0.8em;
  background: $light-grey;
  color: $dark-grey;
  border-radius: 8px;
  padding: 2px 4px;
}

.max-height {
  max-height: 30vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.activity-row {
  cursor: pointer;
  font-size: 0.7rem;
  min-height: 70px;
  border-bottom: 1px solid $grey;
}

.activity-row:hover {
  background: $light-grey;
}
</style>
