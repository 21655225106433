<template>
  <div>
    <b-row class="mb-2" v-if="currentEntry.seedDensity">
      <b-col cols="4">
        <h4 class="subtitle-form">{{ $t("inputStock.details.seedDensity") }}</h4>
      </b-col>
      <b-col cols="8" class="d-flex align-items-center">
        <div class="bg-text-color text-infos config-density-semence">
          {{ currentEntry.seedDensity }}
          {{ currentEntry.seedDensityUnit_unit }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import { mapGetters } from "vuex";
export default {
  mixins: [DetailUpdateMixin],
  computed: {
    ...mapGetters({
      inputsStock: "inputStock/entries",
      currentEntry: "inputStock/currentEntry",
    }),
  }
};
</script>

<style lang="scss" scoped></style>
