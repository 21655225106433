<template>
  <div :class="{'tab-content-notes': activeTab == 'notes'}">
    <!-- Back button to close form -->
    <div class="details-buttons" v-if="!(detailsEditMode || mapEditMode || outlineMode)">
      <div v-if="detailsSelected">
        <editButton :on-click="onEdit" />
      </div>
      <closeButton class="ml-3" :on-click="onBack" />
    </div>
    <div class="details-buttons">
      <closeButton class="ml-3" :on-click="onBack" />
    </div>

    <div :class="{'tab-content-notes': activeTab == 'notes'}">
      <b-container class="p-0">
        <b-row no-gutters>
          <b-col cols="9" class="pr-3">
            <h3 v-if="newMode" class="title-form">
              {{ $t("cropfields.details.new_cropfield") }}
            </h3>
            <h3 v-if="onlyOneCropfield" class="title-form">
              {{ currentCropfield.name }}
              <span v-if="currentCropfield.cropOrder && currentCropfield.cropOrder > 1"> ({{ $t("cropfields.details.second_crop") }})</span>
            </h3>
            <h3 class="title-form" v-if="severalCropfields">{{ $t("cropfields.details.multiple_selection") }}</h3>
            <span v-if="severalCropfields" class="subtitle-form"
              >{{ entriesChecked.length }} {{ $t("cropfields.details.n_cropfields_selected") }}</span
            >
          </b-col>
          <!-- <b-col>
            <editButton :on-click="onEdit" v-if="!editMode" />
            <a href="javascript:void(0)" v-if="editMode" @click="editMode = false" class="ml-2">
              {{ $t("commons.details.cancel").toLowerCase() }}
            </a>
          </b-col> -->
        </b-row>

        <!-- <b-row class="mt-4" v-if="editMode || mapEditMode">
          <b-col cols="6" v-if="entriesChecked.length == 2">

          </b-col>
          <b-col cols="6" v-if="onlyOneCheckedCropfield && currentCropfieldHasGeometry">
            <button class="btn align-bottom button-save-input" @click="cut">
              {{ $t("cropfields.details.cut") }}
            </button>
          </b-col>
        </b-row> -->

        <b-row v-if="mapEditMode" :class="{'tab-content-notes': activeTab == 'notes'}">
          <b-col>
            <CropfieldMapCutTools v-if="mapEditMode" class="mt-4" />
          </b-col>
        </b-row>
        <b-row v-if="outlineMode" :class="{'tab-content-notes': activeTab == 'notes'}">
          <b-col>
            <CropfieldsOutline v-if="outlineMode" class="mt-4" />
          </b-col>
        </b-row>
      </b-container>

      <div v-if="!(mapEditMode || outlineMode)" :class="{'tab-content-notes': activeTab == 'notes'}">
        <b-tabs
          class="details-tabs mt-4"
          :class="{'tab-content-notes': activeTab == 'notes'}"
          v-model="selectedItem"
        >
          <b-tab
            :no-body="true"
            :title="$t('cropfields.details.characteristics')"
            @click="setActiveTab('details')"
            :active="activeTab == 'details'"
            >
            <CropfieldInfos class="mt-4" :editMode="detailsEditMode" :newMode="newMode" :fields="details.infos" />
          </b-tab>
          <b-tab
            v-if="onlyOneCropfield && !detailsEditMode"
            :no-body="true"
            :title="$t('cropfields.details.activity_sum_up')"
            @click="() => setActiveTab('activities')"
            :active="activeTab == 'activities'"
          >
            <CropfieldActivities v-if="activeTab == 'activities' && currentEntryLoaded" :id="currentCropfield.id" class="mt-4"/>
          </b-tab>
          <b-tab
            :no-body="true"
            v-if="onlyOneCropfield && !detailsEditMode"
            :title="$t('cropfields.details.history')"
            @click="() => setActiveTab('history')"
            :active="activeTab == 'history'"
          >
            <CropfieldHistory v-if="activeTab == 'history' && currentEntryLoaded" :id="currentCropfield.id" class="mt-4" />
          </b-tab>
          <b-tab
            :no-body="true"
            v-if="onlyOneCropfield && !detailsEditMode"
            :title="$t('cropfields.details.notes')"
            @click="() => setActiveTab('notes')"
            :active="activeTab == 'notes'"
            :class="{'tab-content-notes': activeTab == 'notes'}"
          >
            <div :class="{'tab-content-notes': activeTab == 'notes'}">
              <CropfieldNotes v-if="activeTab == 'notes' && currentEntryLoaded" :id="currentCropfield.id" class="cropfield-notes" />
            </div>
          </b-tab>
        </b-tabs>

        <b-container class="p-0" v-if="detailsEditMode">
          <b-row align-h="end" align-v="end">
            <b-col cols="12">
              <button class="btn align-bottom button-save-input mb-3" @click="updateRow">
                {{ $t("cropfields.details.save") }}
              </button>
            </b-col>
            <b-col cols="12">
              <button class="btn align-bottom button-danger-input" @click="deleteCropfield">
                {{ $t("cropfields.details.delete") }}
              </button>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>
<script>
import editButton from "@/components/commons/EditButton.vue";
import closeButton from "@/components/commons/CloseButton.vue";

import backButton from "@/components/commons/BackButton.vue";
import CropfieldInfos from "@/components/cropfields/details/CropfieldInfos.vue";
import CropfieldActivities from "@/components/cropfields/details/CropfieldActivities.vue";
import CropfieldHistory from "@/components/cropfields/details/CropfieldHistory.vue";
import CropfieldNotes from "@/components/cropfields/details/CropfieldNotes.vue";

import CropfieldMapCutTools from "@/components/cropfields/details/map-cut-tools/CropfieldMapCutTools.vue";

import CropfieldsOutline from "@/components/cropfields/details/toBeDefined/CropfieldsOutline.vue";

import { mapGetters, mapActions, mapMutations } from "vuex";

import { EventBus } from "@/eventBus.js";

import { askConfirm } from "@/utils/commons";

// turf
import * as turf from "@turf/turf";

export default {
  data() {
    return {
      editingGeometry: false,
      mapEditMode: false,
      newMode: false,
      outlineMode: false,
      selectedItem: null,
      detailsEditMode: false,
      detailsSelected: true,
      currentEntryLoaded: false,
    };
  },
  created: function () {
    this.init(this.$route.params.cropfield_id);
  },
  // async beforeRouteUpdate(to, from, next) {
  //   this.init(to.params.cropfield_id);
  //   next();
  // },
  beforeRouteEnter(to, from, next) {
    next();
  },
  beforeRouteLeave(to, from, next) {
    next();
  },
  methods: {
    ...mapActions({
      updateCropfield: "cropfield/updateEntry",
      deleteEntry: "cropfield/deleteEntry",
      deleteEntryOfStore: "cropfield/deleteEntryOfStore",
      createEntry: "cropfield/createEntry",
      fetch: "cropfield/fetchEntries",
    }),
    ...mapMutations({
      setMapCutMode: "cropfield/setMapCutMode",
      resetCurrentId: "cropfield/resetCurrentId",
    }),
    async enableModifyGeometry() {
      this.editingGeometry = true;
    },
    disableModifyGeometry(save) {
      this.editingGeometry = false;
      EventBus.$emit("disableModifyGeometry", { save: save });
    },
    async init(cropfield_id) {
      EventBus.$on("setEditMode", this.onEdit);
      this.currentEntryLoaded = false;
      await this.$store.dispatch("cropfield/setDetailLoadTime", new Date());
      // mode "edit geometry on map" ?
      // .. if we have 'editGeometry' in route
      if (this.$route.params.mode == "editGeometry") {
        this.mapEditMode = true;
      }
      // .. if we are creating a new cropfield on map
      if (this.$route.name == "cropfield.map.new") {
        this.mapEditMode = true;
      }
      if(this.$route.params.mode == "outlineMode") {
        this.outlineMode = true;
      }
      if(this.$route.params.mode == "editMode") {
        this.detailsEditMode = true;
      }

      // mode "cropfield creation" ?
      // .. if we don't have a cropfield_id
      if (!cropfield_id) {
        this.$store.commit("cropfield/resetEntriesChecked");
        this.$store.dispatch("cropfield/resetCurrentId");
        await this.$store.dispatch("cropfield/setDetailLoadTime", null);
        this.detailsEditMode = true;
        this.newMode = true;
        return;
      }
      this.$store.commit("cropfield/setLastEntryDisplayed", cropfield_id);

      if (this.entriesChecked.length > 1) {
        for (const entry of this.entriesChecked) {
          await this.$store.dispatch("cropfield/getOneCropfield", { id: entry });
        }
      } else {
        await this.$store.dispatch("cropfield/getOneCropfield", { id: cropfield_id });
      }
      this.currentEntryLoaded = true;
    },
    onEdit() {
      // set variable to "edit" for form type
      if(this.detailsSelected) this.detailsEditMode = true;
    },
    async onBack() {
      EventBus.$emit("disableRPG");

      if (this.mapEditMode) {
        this.mapEditMode = false;
        EventBus.$emit("resetMapCut");
        if(!this.newMode)
          this.$router
            .push({ name: "cropfield.map.details", params: { cropfield_id: this.currentEntry.id } })
            .catch((error) => {
              if (error.name != "NavigationDuplicated") {
                throw error;
              }
          });
        else
          this.$router.push({name: "cropfield.map"});

        this.disableModifyGeometry(false);
        this.detailsEditMode = false;
        this.$store.commit("cropfield/resetEditedEntry");
        return;
      }

      if (this.newMode || this.detailsEditMode) {
        if (await askConfirm(this.$t("save_modifications"))) await this.updateRow();
        this.detailsEditMode = false;
        this.$store.commit("cropfield/resetEditedEntry");
        this.$store.commit("cropfield/resetEntriesChecked");
        if (!this.newMode) return;

        this.newMode = false;
      }

      if(this.outlineMode) {
        if(this.$route.params.fromActivityView)
          this.$router.push({name: "activity.details", params: {activity_id: this.$route.params.activity_id}});
        else
          this.$router.push({name: "home"});
      }else {
        // Permet de désigner l'onglet actif dans le panneau de détail d'une parcelle
        this.setActiveTab('details');

        // Redirection vers le mode liste ou le mode carte
        if (this.$route.name == "cropfield.map.details") {
          this.$router.push({ name: "cropfield.map" });
          EventBus.$emit("hideActiveVariety");
          EventBus.$emit("reinitializeVarietyLayer");
          EventBus.$emit("reinitializeParcelsLayer");
        } else {
          this.$router.push({ name: "cropfield.list" });
        }
      }
      this.$store.commit("cropfield/resetLastEntryDisplayed");
      this.$store.commit("cropfield/resetEntriesChecked");
      this.$store.dispatch("cropfield/resetCurrentId");
    },
    async updateRow() {
      await this.$store.dispatch("cropfield/updateCheckedEntries");

      if (this.newMode) {
        EventBus.$emit("selectOnList", this.currentId);
        return;
      }

      if (this.detailsEditMode) {
        this.detailsEditMode = false;
        this.$store.commit("cropfield/resetEntriesChecked");
        return;
      }
    },
    async deleteCropfield() {
      // Le message est au pluriel si plusieurs parcelles sont sélectionnées
      let warningMessage = this.$t("cropfields.details." + (this.onlyOneCropfield ? "warning_delete" : "warning_delete_multiple"));
      let confirmation = confirm(warningMessage);
      if (!confirmation) return;

      let rowsId = [];
      if (this.onlyOneCropfield){
        rowsId.push(this.currentCropfield.id);
      } else {
        rowsId = [...this.entriesChecked];
      }

      for (let i=0; i<rowsId.length; i++) {
        await this.deleteEntry({id: rowsId[i]});
      }
      this.resetCurrentId();

      if (this.isMapView) {
        await this.$router.push({ name: "cropfield.map" });
        EventBus.$emit("plotFields", false);
      } else {
        await this.$router.push({ name: "cropfield.list" });
      }

      EventBus.$emit("clearSelection");
    },
    cut() {
      if (this.isMapView) {
        this.mapEditMode = true;
        return;
      }
      this.duplicateCropfield();
    },
    async duplicateCropfield() {
      const rowId = this.entriesChecked[0];
      let data = { ...this.cropfields.find((f) => f.id == rowId) };

      data.campaign = this.getCampaign.id;

      // add +1 at the end of the new name
      let lastLetter = data.name.slice(-1);
      lastLetter = parseInt(lastLetter);
      let counter = 1;
      if (!isNaN(lastLetter)) {
        counter = lastLetter + 1;
        data.name = data.name.slice(0, -1);
      } else {
        data.name = data.name + "_";
      }
      data.name = data.name + counter;
      data.surface = 0;
      data.geometry = null;

      await this.createEntry({ entry: data });
      await this.fetch();
    },

    // Permet de mémoriser l'onglet actif dans le panneau de détail d'une parcelle
    async setActiveTab(tab) {
      await this.$store.dispatch("cropfield/setActiveTab", tab);
    }
  },
  components: {
    editButton,
    backButton,
    closeButton,
    CropfieldInfos,
    CropfieldActivities,
    CropfieldHistory,
    CropfieldMapCutTools,
    CropfieldsOutline,
    CropfieldNotes
  },
  computed: {
    ...mapGetters({
      currentId: "cropfield/currentId",
      currentEntry: "cropfield/currentEntry",
      currentCropfieldHasGeometry: "cropfield/currentCropfieldHasGeometry",
      entriesChecked: "cropfield/entriesChecked",
      checkedGeometries: "cropfield/checkedGeometries",
      cropfields: "cropfield/entries",
      getCampaign: "getCampaign",
      user: "user/user",
      activeTab: "cropfield/activeTab",
      detailLoadTime: "cropfield/detailLoadTime",
    }),
    onlyOneCropfield() {
      return this.entriesChecked.length <= 1 && this.currentCropfield;
    },
    onlyOneCheckedCropfield() {
      return this.entriesChecked.length == 1;
    },
    severalCropfields() {
      return this.entriesChecked.length > 1;
    },
    currentCropfield() {
      if (this.entriesChecked.length == 0) return this.currentEntry;
      return this.cropfields.find((e) => e.id == this.entriesChecked[0]);
    },
    isMapView() {
      return this.$route.name == "cropfield.map.details";
    },
  },
  props: {
    details: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    $route() {
      if (this.$route.params.mode == "editGeometry") {
        this.mapEditMode = true;
      } else if (this.mapEditMode) {
        this.mapEditMode = false;
      }
    },
    //permet de de gérer l'affichage du bouton edit
    selectedItem() {
      if(this.selectedItem == 0) {
        this.detailsSelected = true;
      }
      else if (this.selectedItem == 3) {
        this.detailsSelected = false;
      } else {
        this.detailsSelected = false;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
/**
 * Edit Button position.
 */
.position-edit-button {
  text-align: center;
  position: relative;
  top: 35px;
  left: 20px;
}

a {
  color: $subtitle-color;
  text-decoration: underline;
}
a:hover {
  color: $primary-color;
}

/**
 * Params button save input.
 */
.button-save-input {
  background-color: $background-text-color;
  width: 100%;
}

.button-save-input:hover {
  background: $secondary-light-color;
}
</style>

<style>
.details-tabs .nav-tabs .nav-link {
  border: 0;
  padding: 0.5rem 0;
  background: transparent;
  font-size: 0.9em;
  color: black;
}

.details-tabs .nav-item {
  margin-right: 1.5rem;
}

.details-tabs .tab-pane.active {
  background: transparent;
}

.details-tabs .nav-tabs .nav-link.active {
  background: transparent;
  border-bottom: 4px solid black;
}

.tab-content-notes .tab-content,
.tab-content-notes {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.cropfield-notes {
  margin-top: auto; /* Pousse le composant vers le bas */
  padding: 20px 0 10px 0;
}
</style>
