import TerritoriesApi from "../../api/territories.api";

export const ALL_TERRITORIES = "GET ALL TERRITORIES";
export const ONE_TERRITORY = "GET ONE TERRITORY";
export const RESET_TERRITORY = "RESET TERRITORY";
export const TERRITORY_LOADING = "LOAD TERRITORY";
export const TERRITORIES_LOADING = "LOAD TERRITORIES";

export default {
  state: {
    territories: [],
    currentTerritory: {},
    territory_loaded: true,
    territories_loaded: true,
  },
  getters: {
    currentTerritory: state => state.currentTerritory,
    territories: state => state.territories,
    territory_loaded: state => state.currentTerritory,
    territoriesLoaded: state => state.territories_loaded
  },
  mutations: {
    [ALL_TERRITORIES]: (state, territories) => {
      state.territories = territories;
    },

    [ONE_TERRITORY]: (state, territory) => {
      state.currentTerritory = territory;
    },

    [RESET_TERRITORY]: state => {
      state.currentTerritory = {};
    },

    [TERRITORY_LOADING]: (state, status) => {
      state.territory_loaded = !status;
    },

    [TERRITORIES_LOADING]: (state, status) => {
      state.territories_loaded = !status;
    },
  },
  actions: {
    async getTerritories({ commit }) {
      commit(TERRITORIES_LOADING, true);
      const response = await TerritoriesApi.getTerritories();
      commit(ALL_TERRITORIES, response.data);
      commit(TERRITORIES_LOADING, false);
      return response.data;
    },
    async getOneTerritory({ commit }, payload) {
      commit(TERRITORY_LOADING, true);
      const response = await TerritoriesApi.getOneTerritory(payload.id);
      commit(ONE_TERRITORY, response.data);
      commit(TERRITORY_LOADING, false);
      return response.data;
    }
  },
};
