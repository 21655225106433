<template>
  <div class="m-1">
    <b-container v-if="$userCan('menu.analysis')" fluid class="dx-viewport full-height-container p-0">
      <b-row no-gutters>
        <b-col cols="2" class="filter scrollable p-3">
          <router-view name="options" :module="module" :options="options" :routes="routes" :key="module"
                                      :displayChart="displayChart" :displayGrid="displayGrid"
                                      @displaychart="() => displayChart = !displayChart"
                                      @displaygrid="() => displayGrid = !displayGrid"
          />
        </b-col>
        <b-col v-if="!loaded" cols="10" class="text-center mt-5">
          <b-spinner></b-spinner>
        </b-col>
        <b-col v-else cols="10" class="content h-100 pl-1">
          <div>
            <!-- Graphe -->
            <b-card ref="card1" class="mb-1" :class="displayGrid ? 'card' : 'widecard'" border-variant="success" v-show="displayChart">
              <DxChart ref="chart" :rotated="rotated">
                <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip"/>
                <DxAdaptiveLayout :width="450" />
                <DxSize :height="cardHeight_1" />
                <DxCommonSeriesSettings :type="chartType" />
                <DxExport :enabled="true"/>
              </DxChart>
            </b-card>
            <!-- Tableau alimentant le graphe -->
            <b-card ref="card2" border-variant="success" :class="displayChart ? 'card' : 'widecard'" v-show="displayGrid">
              <DxPivotGrid
                id="pivotgrid"
                ref="grid"
                :data-source="dataSource"
                :allow-sorting-by-summary="true"
                :allow-sorting="true"
                :allow-filtering="true"
                :show-borders="true"
                :show-column-grand-totals="totalColumn"
                :show-row-grand-totals="totalRow"
                :show-row-totals="false"
                :show-column-totals="false"
                :height="cardHeight_2"
              >
                <DxHeaderFilter
                  :width="300"
                  :height="400"
                />
                <DxFieldChooser :enabled="false"/>
                <DxFieldPanel
                  :visible="true"
                  :showColumnFields="false"
                  :showDataFields="false"
                  :showFilterFields="true"
                  :allowFieldDragging="false"
                />
                <DxExport
                  :enabled="true"
                />
              </DxPivotGrid>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div v-else class="text-center mt-5">
      <h1> {{ $t('analysis.not_authorized') }} </h1>
    </div>
  </div>
</template>
<script>
import { DxChart, DxAdaptiveLayout, DxCommonSeriesSettings, DxSize, DxTooltip } from "devextreme-vue/chart";

import { DxPivotGrid, DxFieldChooser, DxFieldPanel, DxHeaderFilter, DxExport } from "devextreme-vue/pivot-grid";
import "devextreme/dist/css/dx.light.css";

import { TILE_UPDATE } from "@/store/modules/page-tile";

import AnalysisModule from "@/store/modules/analysis";
import { mapGetters, mapActions } from "vuex";

import dataSourceMixin from "./dataSourceMixin";

export default {
  components: {
    DxChart,
    DxAdaptiveLayout,
    DxCommonSeriesSettings,
    DxSize,
    DxTooltip,
    DxPivotGrid,
    DxFieldChooser,
    DxFieldPanel,
    DxHeaderFilter,
    DxExport
  },
  mixins: [dataSourceMixin],
  data() {
    return {
      loaded: false,
      dataSource: null,
      iftChartDataNew: [],
      displayChart: true,
      displayGrid: true,
      cardHeight_1: 0,
      cardHeight_2: 0,
      customizeTooltip(args) {
        return {
          html: `${args.seriesName} <div>${args.originalValue}</div>`,
        };
      },
    };
  },

  props: {
    title: {
      type: String,
      default: "",
    },
    module: {
      type: String,
      default: "",
    },
    routes: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Array,
      default: () => [],
    },
  },

  beforeCreate() {
    const store = this.$store;
    if (store && store.state) {
      if (!store.state["analysis"]) {
        store.registerModule("analysis", AnalysisModule);
      }
    }
  },
  mounted() {
    this.setTitle();
    if(this.$userCan("menu.analysis")) this.init();
  },
  computed: {
    ...mapGetters({
      iftChartData: "analysis/iftChartData",
      elapsedTimeChartData: "analysis/elapsedTimeChartData",
      chartType: "analysis/chartType",
      rotated: "analysis/rotated",
      inverted: "analysis/inverted",
      totalRow: "analysis/totalRow",
      totalColumn: "analysis/totalColumn",
      currentFarmId: "farm/currentFarmId",
      currentCampaign: "getCampaign",
    }),
    sourcesList() {
      return [this.dataSourceIFT, this.dataSourceElapsedTime];
    },
    mapSources() {
      let map = {
        tfi: this.sourcesList[0],
        elapsedTimeHourHa: this.sourcesList[1],
        elapsedTimeHour: this.sourcesList[1],
      };
      return map;
    },
  },
  methods: {
    async init() {
      this.loaded = false;
      // Suppression des données qui alimentent les graphes
      await this.$store.dispatch(this.module + "/resetIFTChartData");
      await this.$store.dispatch(this.module + "/resetElapsedTimeChartData");
      this.loaded = true;

      // Recherche la 1ère option autorisée
      let authorizedOption = null;
      let found = false;
      this.options.map(opt => {
        if (opt.show({$userCan: this.$userCan}) && !found){
          authorizedOption = opt;
          found = true;
        }
      });
      // Si le graphe à afficher n'est pas précisé, on affiche le 1er élément autorisé de la liste
      if(this.$route.name != this.routes.options.name && authorizedOption != null) {
        this.$router.push({ name: this.routes.options.name, params: { analysis_id: authorizedOption.name } });
      }
      else {
        this.loadChart();
      }

    },

    /**
     * Permet d'effacer le titre de la vue
     */
    setTitle() {
      let t = this;
      this.$store.commit(TILE_UPDATE, {
        title: null,
        buttons: [],
      });
    },

    /**
     * Charge la source de données du tableau et l'affecte au graphe
     */
    async loadChart() {
      let option_name = this.$route.params.analysis_id;
      // On détermine le graphe à afficher et on récupère les données qui vont l'alimenter, si elles n'existent pas encore
      if (option_name) {
        for(let i=0; i<this.options.length; i++){
          if(option_name == this.options[i].name){
            if(this.$store.getters[this.module + "/" + this.options[i].storeGetter].length == 0){
              this.loaded = false;
              await this.$store.dispatch(this.module + "/" + this.options[i].storeAction);
              if(option_name == "tfi") {
                this.computeStoreForIFTChart();
              }
              this.loaded = true;
            }
            // On met à jour le type de graphe, son orientation et l'affichage des totaux des lignes et colonnes
            await this.$store.dispatch(this.module + "/setChartType", this.options[i].chartType);
            await this.$store.dispatch(this.module + "/setRotated", this.options[i].rotated);
            await this.$store.dispatch(this.module + "/setInverted", this.options[i].inverted);
            await this.$store.dispatch(this.module + "/setTotalRow", this.options[i].totalRow);
            await this.$store.dispatch(this.module + "/setTotalColumn", this.options[i].totalColumn);
            break;
          }
        }
        // On met à jour la source de données du tableau
        this.dataSource = this.mapSources[option_name];

        // Les temps passés en heure et en heure/ha ont la même source de données
        // On modifie juste le calcul de la donnée à représenter
        if(option_name == "elapsedTimeHour") {
          this.dataSource.field('Temps passés (heure/ha)', {caption: "Temps passés (heure)", summaryType: "sum", dataField: "elapsedTime"});
          this.dataSource.load();
        }
        if(option_name == "elapsedTimeHourHa") {
          this.dataSource.field('Temps passés (heure)', {caption: "Temps passés (heure/ha)", summaryType: "custom", dataField: null});
          this.dataSource.load();
        }

        // On affecte le tableau au graphe
        setTimeout(() => {
          let pivotGrid;
          pivotGrid = this.$refs.grid.instance;
          const chart = this.$refs.chart.instance;
          pivotGrid.bindChart(chart, {
            dataFieldsDisplayMode: "splitPanes",
            alternateDataFields: false,
            inverted: this.inverted,
          });
        }, 200);
        this.updateCardHeight();
      }
    },

    /**
     * Formatte les données d'IFT reçues de l'API pour que le filtre suivant puisse être appliqué
     * IFT Total/ IFT Hors Herbicides / IFT Herbicides
     */
    computeStoreForIFTChart() {
      let tmpObjectIFT = {};
      let tmpObjectIFTHerbFree = {};
      let tmpObjectIFTHerb = {};
      for(let i=0; i< this.iftChartData.length; i++) {
        tmpObjectIFT = {};
        tmpObjectIFTHerbFree = {};
        tmpObjectIFTHerb = {};
        Object.keys(this.iftChartData[i]).map(key => {
          if(key != 'tfi' && key != 'tfi_herbicides_free'){
            tmpObjectIFT[key] = this.iftChartData[i][key];
            tmpObjectIFTHerbFree[key] = this.iftChartData[i][key];
            tmpObjectIFTHerb[key] = this.iftChartData[i][key];
          }
        });
        tmpObjectIFT['tfi'] = this.iftChartData[i]['tfi'];
        tmpObjectIFT['productTypeFilter'] = "IFT Total";
        this.iftChartDataNew.push(tmpObjectIFT);
        tmpObjectIFTHerbFree['tfi'] = this.iftChartData[i]['tfi_herbicides_free'];
        tmpObjectIFTHerbFree['productTypeFilter'] = "IFT Hors Herbicide";
        this.iftChartDataNew.push(tmpObjectIFTHerbFree);
        tmpObjectIFTHerb['tfi'] = this.iftChartData[i]['tfi'] - this.iftChartData[i]['tfi_herbicides_free'];
        tmpObjectIFTHerb['productTypeFilter'] = "IFT Herbicide";
        this.iftChartDataNew.push(tmpObjectIFTHerb);
      }
    },

    /**
     * Permet de mettre à jour les tailles du graphe et tableau en fonction de la taille de l'écran
     */
    updateCardHeight() {
      let height_1 = this.$refs.card1.getBoundingClientRect().height;
      this.cardHeight_1 = height_1 - 0.1*height_1;
      let height_2 = this.$refs.card2.getBoundingClientRect().height;
      this.cardHeight_2 = height_2 - 0.1*height_2;
    }
  },
  watch: {
    $route(){
      this.loadChart();
    },
    currentFarmId(){
      this.init();
    },
    currentCampaign(){
      this.init();
    },
    displayChart() {
      this.loadChart();
    },
    displayGrid() {
      this.loadChart();
    }
  },
};
</script>
<style lang="scss" scoped>
#pivotgrid {
  margin-top: 2px;
}

.currency {
  text-align: center;
}

.card {
  background-color: #eaf9ed;
  height: 42vh;
}

.widecard {
  background-color: #eaf9ed;
  height: 84vh;
}
</style>
