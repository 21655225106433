import * as turf from "@turf/turf";

import { mapMutations } from "vuex";

import {
  project3857,
  project4326,
  borderSlice,
  slicePolygon,
  findPaths,
  lineEnds,
  computeSurface,
  getGeometryFromFeature,
  getClosestPointOutsidePolygon,
} from "@/utils/geomatics";

import { EventBus } from "@/eventBus.js";
import { polygon, unitsFactors } from "@turf/turf";

export default {
  mounted() {
    if (this.minimal) return;

    EventBus.$on("resetMapCut", this.resetMapCut);

    EventBus.$on("enableModifyGeometry", this.addModifyInteraction);
    EventBus.$on("disableModifyGeometry", this.removeModifyInteraction);
  },
  beforeDestroy() {
    if (this.minimal) return;
    EventBus.$off("resetMapCut", this.resetMapCut);

    EventBus.$off("enableModifyGeometry", this.addModifyInteraction);
    EventBus.$off("disableModifyGeometry", this.removeModifyInteraction);
  },
  data() {
    return {
      // cut mode
      selectedPoints: [],
      possiblePaths: [],
      choosenPath: null,
      cutInput: {
        start: null,
        stop: null,
        polygon: null,
        cursorPosition: null,
        distance: 10,
      },
      cutLine: null,
      cutLineScaled: null,
      cutLineAngle: null,
      cutLineDistance: 0,

      cutLineSelectedPointsSource: null,

      brokenLineSource: null,

      // are we in multiline mode ?
      multilineMode: false,
      // we need to create a variable for each interaction & layer,
      // in order to remove them afterwards properly
      // interaction when choosing point at begining of cut line
      selectPointInteraction: null,
      // snap interaction to active cropfield
      snapActiveCropfield: null,
      // pointer interaction used for snapping cutting line
      pointerInteraction: null,
      // snap interaction to snap selected points
      snapSelectedPoints: null,
      // snap to multiline
      snapMultiline: null,

      // brokenline interaction
      brokenLineInteraction: null,
      brokenLineValidStartPoint: null,
      brokenLineFeature: null,

      hasCut: false,
      showDistanceTooltip: false,
      cursorX: 0,
      cursorY: 0,
      multilineMovedPointStart: null,
    };
  },
  methods: {
    ...mapMutations({
      setMapCutGeometries: "cropfield/setMapCutGeometries",
      setMapCutMode: "cropfield/setMapCutMode",
    }),

    // when entering a cut mode, load the polygon
    cutModeLoadPolygon() {
      // we also a set the id in the ppties, useful in cut mode
      this.cutInput.polygon = turf.polygon(this.currentCropfield.geometry.coordinates, {
        id: this.currentCropfield.id,
      });
    },

    showPossiblePaths() {
      this.cutInput.start = turf.point(project4326(turf.point(this.selectedPoints[0])).geometry.coordinates);
      this.cutInput.stop = turf.point(project4326(turf.point(this.selectedPoints[1])).geometry.coordinates);

      this.possiblePaths = findPaths(this.cutInput.start, this.cutInput.stop, this.cutInput.polygon);
      this.possiblePaths = this.possiblePaths.map((p) => project3857(p));

      this.layerSource.dispatchEvent("change");
    },
    borderCutChoosePathAndGo(evt) {
      let t = this;

      EventBus.$emit("nextGuideStep"); // show next guide sentence

      // where the user has clicked
      let pt = project4326(turf.point(evt.coordinate));

      // get the nearest path
      let nearestPathDistance;
      this.possiblePaths.forEach(function (path) {
        let path4326 = turf.lineString(project4326(path).geometry.coordinates);
        let dist = turf.pointToLineDistance(pt, path4326, { units: "miles" });
        if (!nearestPathDistance || dist < nearestPathDistance) {
          t.choosenPath = path4326;
          nearestPathDistance = dist;
        }
      });

      // ask him the distance
      setTimeout(function () {
        if (t.mapCutMode == 2) {
          t.cutInput.distance = parseInt(prompt(t.$t("cropfields.details.border_cut_distance_prompt"), 20));
        }

        t.possiblePaths = [t.choosenPath];
        t.layerSource.dispatchEvent("change");

        setTimeout(function () {
          t.borderCut();
        }, 200);

        this.hasCut = true;
      }, 200);
    },
    borderCut() {
      let res;
      try {
        res = borderSlice({
          polygon: this.cutInput.polygon,
          path: this.choosenPath,
          distance: this.cutInput.distance,
          name: this.currentEntry.name,
        });
      } catch (error) {
        alert(this.$t("cropfields.details.cut_failed"));
        this.resetMapCut();
        return;
      }

      let border = res[0];

      // let path = res[1];
      // path = path.map(p => project3857(p));
      // this.lineSliced = path;

      let trans = res[1];

      trans = trans.map((t) => project3857(t));

      //this.transLines.push(...trans);

      let polygons = res[2];
      this.plotPolygons(polygons);

      //debug;
      // border = project3857(border);
      // this.newLines.push(border);

      this.displayActiveCropfield = false;

      this.layerSource.dispatchEvent("change");
      this.activeParcelsLayer.getSource().dispatchEvent("change");
      // end tempo
    },
    showCutLine() {
      EventBus.$emit("nextGuideStep");

      this.cutLine = project4326(turf.lineString(this.selectedPoints));

      let points = this.cutLine.geometry.coordinates.map((p) => turf.point(p));
      this.cutLineAngle = turf.bearing(...points);
      //if (this.cutLineAngle < 0) this.cutLineAngle += 180;

      this.cutLineLayer.getSource().addFeature(new ol.format.GeoJSON().readFeature(project3857(this.cutLine)));

      // a timeout to be sure we begin to move line before
      // should be removed after code refactorisation
      let t = this;
      setTimeout(function () {
        t.hasCut = true;
      }, 500);
    },

    // découpe en ligne: affiche la ligne de découpe
    plotCutLine(cursorPosition) {
      let cutLine = turf.transformScale(this.cutLine, 20);

      let distance = turf.pointToLineDistance(cursorPosition, cutLine, {
        units: "meters",
      });

      let nearestPoint = turf.nearestPointOnLine(cutLine, cursorPosition, { units: "miles" });

      //let perpendicular = turf.lineString([nearestPoint.getCoords(), cursorPosition.getCoords()]);

      let cursorAngle = this.calcangle(
        ...nearestPoint.geometry.coordinates,
        ...cursorPosition.geometry.coordinates,
        ...this.cutLine.geometry.coordinates[0],
        ...this.cutLine.geometry.coordinates[1],
      );

      let sign = Math.sign(cursorAngle);

      let line = turf.transformTranslate(cutLine, distance, sign * 90 + this.cutLineAngle, {
        units: "meters",
      });

      this.cutLineDistance = Math.round(distance * 100) / 100;
      let t = this;
      let deb = this.debounce(function () {
        let polys = slicePolygon(t.cutInput.polygon, line, t.currentEntry.name);
        if (polys) t.plotPolygons(polys);
      }, 100);
      deb();

      this.cutLineScaled = line;

      this.cutLineLayer.getSource().clear();
      this.cutLineLayer.getSource().addFeature(new ol.format.GeoJSON().readFeature(project3857(line)));
      this.cutLineLayer.dispatchEvent("change");
    },

    // découpe en ligne: ajoute l'interaction pour sélectionner deux points sur la géométrie
    cutLineAddSelectPointInteraction() {
      this.cutLineSelectedPointsSource = new ol.source.Vector();
      this.selectPointInteraction = new ol.interaction.Draw({
        source: this.cutLineSelectedPointsSource,
        type: "Point",
      });

      this.snapActiveCropfield = new ol.interaction.Snap({
        source: this.activeCropfieldLayer.getSource(),
      });

      let t = this;
      this.selectPointInteraction.on("drawend", function (evt) {
        let feature = t.getGeometryFromFeature(evt.feature);
        t.selectedPoints.push(feature.geometry.coordinates);

        t.activeCropfieldLayer.getSource().dispatchEvent("change");
        t.layerSource.dispatchEvent("change");
        t.firstCropsLayer.getSource().dispatchEvent("change");
        t.secondCropsLayer.getSource().dispatchEvent("change");

        if (t.selectedPoints.length == 2) {
          t.cutLineRemoveSelectPointInteraction();
          t.cutLineAddPointerInteraction();
          t.showCutLine();
          t.showDistanceTooltip = true;
        }
      });

      this.map.addInteraction(this.selectPointInteraction);

      this.map.addInteraction(this.snapActiveCropfield);
    },

    // découpe en ligne: retire l'interaction pour sélectionner deux points sur la géométrie
    cutLineRemoveSelectPointInteraction() {
      this.map.removeInteraction(this.selectPointInteraction);
      this.map.removeInteraction(this.snapActiveCropfield);
    },

    // découpe en ligne: ajoute l'aimantation quand on déplace la ligne
    cutLineAddPointerInteraction() {
      let t = this;
      this.pointerInteraction = new ol.interaction.Interaction({
        handleEvent: (evt) => {
          t.cursorX = evt.originalEvent.pageX;
          t.cursorY = evt.originalEvent.pageY;
          let snapCoordinate = project4326(turf.point(evt.coordinate));
          t.plotCutLine(snapCoordinate);
          return true;
        },
      });

      this.map.addInteraction(this.pointerInteraction);

      this.snapActiveCropfield = new ol.interaction.Snap({
        source: this.activeCropfieldLayer.getSource(),
      });

      this.snapSelectedPoints = new ol.interaction.Snap({
        source: this.cutLineSelectedPointsSource,
      });

      this.map.addInteraction(this.snapSelectedPoints);
      this.map.addInteraction(this.snapActiveCropfield);
    },

    // découpe en ligne: retire l'aimantation quand on déplace la ligne
    cutLineRemovePointerInteraction() {
      this.map.removeInteraction(this.pointerInteraction);
      this.map.removeInteraction(this.snapActiveCropfield);
    },

    /**
     * Draw broken line : start the interaction
     */
    startDrawBrokenLine() {
      // the output source
      this.brokenLineSource = new ol.source.Vector({
        updateWhileInteracting: true,
      });

      // create the interaction
      this.brokenLineInteraction = new ol.interaction.Draw({
        source: this.brokenLineSource,
        type: "LineString",
      });

      // snap on the cropfield border & vertices
      this.snapActiveCropfield = new ol.interaction.Snap({
        source: this.activeCropfieldLayer.getSource(),
      });

      let t = this;

      // attach listeners
      this.map.un("click", this.brokenLineOnNewPoint);
      this.brokenLineInteraction.on("drawstart", function (evt) {
        t.brokenLineFeature = evt.feature;
        t.brokenLineValidStartPoint = false;
        t.map.on("click", t.brokenLineOnNewPoint);
      });

      this.map.addInteraction(this.brokenLineInteraction);
      this.map.addInteraction(this.snapActiveCropfield);
    },

    /**
     * Draw broken line : each time we add a point, this function is executed
     */
    brokenLineOnNewPoint() {
      // get the coords of the drawn line
      // let drawCoords = JSON.parse(JSON.stringify(this.brokenLineInteraction.sketchCoords_));
      let drawCoords = this.brokenLineFeature.getGeometry().getCoordinates();
      // remove the last coord, because this is the one of the moving cursor
      drawCoords.pop();

      // get the last point
      let lastCoords = drawCoords[drawCoords.length - 1];
      let point = project4326(turf.point(lastCoords));

      // check if point is in polygon and distance to poly
      const isInPoly = turf.booleanPointInPolygon(point, this.cutInput.polygon, { ignoreBoundary: true });

      // 1) Are we in the poly ?
      if (isInPoly) {
        // check the distance to polygon border
        const polygonAsLine = turf.polygonToLine(this.cutInput.polygon);
        const distanceToPoly = turf.pointToLineDistance(point, polygonAsLine, { units: "meters" });

        // a) Are we in the poly and very close to the border (a few milimeters away) ?
        // This can happen when we snap on the edge.
        if (distanceToPoly < 0.01) {
          // For our cutting purpose, we prefer to have this point outside the polygon
          // so we "translate it" a centimeter outside
          let pointOutside = getClosestPointOutsidePolygon(point, this.cutInput.polygon, 0.01);
          if (pointOutside) {
            pointOutside = project3857(pointOutside);
            this.brokenLineInteraction.removeLastPoint();
            this.brokenLineInteraction.appendCoordinates([pointOutside.geometry.coordinates]);
          }
        }
        // b) If we a not close to broder & it is the first point we make, show alert & remove last point
        else if (!this.brokenLineValidStartPoint) {
          EventBus.$emit("toast", {
            message: this.$t("cropfields.details.first_point_inside_warning"),
            variant: "warning",
          });
          this.brokenLineInteraction.removeLastPoint();
        }
      }
      // 2) If we hare outside / on the border of the poly, and it's the first point we make, flag it
      else if (!this.brokenLineValidStartPoint) {
        this.brokenLineValidStartPoint = true;
      }

      // if we have a valid start point
      if (this.brokenLineValidStartPoint) {
        // get again the coords of the sketch (they may have changed above)
        // drawCoords = JSON.parse(JSON.stringify(this.brokenLineInteraction.sketchCoords_));
        drawCoords = this.brokenLineFeature.getGeometry().getCoordinates();
        // remove the last coord, because this is the one of the moving cursor
        drawCoords.pop();

        // check we have at least two points
        if (drawCoords.length < 2) return;

        // build the lineString and slice the polygon with it
        const brokenLine = project4326(turf.lineString(drawCoords));
        const slices = slicePolygon(this.cutInput.polygon, brokenLine);

        // if at least two slices, remove interaction and start multiline interaction
        if (slices.length >= 2) {
          this.map.removeInteraction(this.brokenLineInteraction);
          this.addEditMultilineInteraction(brokenLine);
          // remove on click event
          this.map.un("click", this.brokenLineOnNewPoint);
        }
      }
    },

    cutLineShowMultiline() {
      EventBus.$emit("nextGuideStep");

      let intersects = turf.lineIntersect(this.cutLineScaled, this.cutInput.polygon);
      let cutLineScaledEnds = lineEnds(this.cutLineScaled);

      let coordinates = [];
      for (let i = 0; i < cutLineScaledEnds.length; i++) {
        let matchingIntersect = turf.nearestPoint(cutLineScaledEnds[i], intersects).geometry.coordinates;
        let segment = turf.lineString([matchingIntersect, cutLineScaledEnds[i]]);
        let end = turf.along(segment, 25, { units: "meters" }).geometry.coordinates;

        let outputSegment = [end, matchingIntersect];
        if (i == 1) outputSegment = outputSegment.reverse();

        coordinates.push(...outputSegment);
      }

      let line = turf.lineString(coordinates);

      this.addEditMultilineInteraction(line);

      // aimantation sur les points de sélections pour la ligne de découpe
      this.snapSelectedPoints = new ol.interaction.Snap({
        source: this.cutLineSelectedPointsSource,
      });
      if (this.snapSelectedPoints) this.map.addInteraction(this.snapSelectedPoints);
    },

    // découpe en ligne: ajoute la ligne brisée
    addEditMultilineInteraction(lineString) {
      this.multilineMode = true;

      this.cutLineLayer.getSource().clear();
      this.cutLineLayer.getSource().addFeature(new ol.format.GeoJSON().readFeature(project3857(lineString)));

      this.cutLineLayer.dispatchEvent("change");

      let modifyInteraction = new ol.interaction.Modify({
        source: this.cutLineLayer.getSource(),
      });

      let t = this;
      modifyInteraction.on("modifystart", this.onMultilineModifyStart);
      const cut = function (e) {
        t.map.un("pointermove", t.onMultilineChangeShowDistance);

        let line = project4326(t.getGeometryFromLayer(t.cutLineLayer));

        let deb = t.debounce(function () {
          let polys = slicePolygon(t.cutInput.polygon, line, t.currentEntry.name);
          if (polys) t.plotPolygons(polys);
        }, 100);
        deb();

        t.removeMultilineDistanceLine();
      };
      modifyInteraction.on("modifyend", cut);

      cut();

      this.map.addInteraction(modifyInteraction);

      // on ajoute les aimantations

      // aimantation sur la parcelle en cours
      this.snapActiveCropfield = new ol.interaction.Snap({
        source: this.activeCropfieldLayer.getSource(),
      });
      this.map.addInteraction(this.snapActiveCropfield);

      // sur la ligne brisée
      this.snapMultiline = new ol.interaction.Snap({
        source: this.cutLineLayer.getSource(),
      });
      this.map.addInteraction(this.snapMultiline);
    },

    // découpe en ligne:  retire l'interaction sur la ligne brisée
    removeEditMultilineInteraction() {
      this.multilineMode = false;
      this.map.removeInteraction(this.snapMultiline);
      this.map.removeInteraction(this.snapSelectedPoints);
      this.map.removeInteraction(this.snapActiveCropfield);
    },
    onMultilineModifyStart(e) {
      let coordinates = e.mapBrowserEvent.coordinate;
      let feature = e.features.getArray()[0];
      this.multilineMovedPointStart = feature.getGeometry().getClosestPoint(coordinates);
      this.map.on("pointermove", this.onMultilineChangeShowDistance);
    },
    onMultilineChangeShowDistance(e) {
      let endPoint = e.coordinate;

      this.cursorX = e.originalEvent.pageX;
      this.cursorY = e.originalEvent.pageY;

      let line = new ol.geom.LineString([this.multilineMovedPointStart, endPoint]);

      this.cutLineDistance = Math.round(line.getLength() * 100) / 100;

      this.helpersLayer.getSource().clear();
      this.helpersLayer.getSource().addFeature(new ol.Feature(line));
      this.helpersLayer.dispatchEvent("change");

      this.showDistanceTooltip = true;
    },
    removeMultilineDistanceLine() {
      this.helpersLayer.getSource().clear();
      this.helpersLayer.dispatchEvent("change");
      this.showDistanceTooltip = false;
    },
    calcangle(x00, y00, x01, y01, x10, y10, x11, y11) {
      var dx0 = x01 - x00;
      var dy0 = y01 - y00;
      var dx1 = x11 - x10;
      var dy1 = y11 - y10;
      let angle = Math.atan2(dx0 * dy1 - dx1 * dy0, dx0 * dx1 + dy0 * dy1);

      return (angle * 180) / 3.14;
    },
    // this function is used to modify or draw a geometry
    addModifyInteraction(forceModify = false) {
      if (!this.map) return;

      let t = this;

      this.drawMode = true;

      this.displayActiveCropfield = false;
      let modifyInteraction;

      // if our field has a geometry
      if (this.currentCropfieldHasGeometry || forceModify) {
        // plot the dots arounds the perimeter
        this.showActiveCropfield();

        this.resetFeatureHoles();

        // add a modify interaction
        modifyInteraction = new ol.interaction.Modify({ source: this.activeCropfieldLayer.getSource() });

        modifyInteraction.on("modifystart", function (e) {
          let feature = e.features.getArray()[0];

          // every time the geometry changes, update the surface
          feature.getGeometry().on("change", function () {
            // add a debounce method for performance reasons
            let deb = t.debounce(function () {
              t.setEditedEntryValue({
                surface: t.computeSurfaceFromOLFeature(feature),
              });
              t.activeCropfieldLayer.getSource().dispatchEvent("change");
              t.layerSource.dispatchEvent("change");
              t.firstCropsLayer.getSource().dispatchEvent("change");
              t.secondCropsLayer.getSource().dispatchEvent("change");
            }, 100);
            deb();
          });
        });

        modifyInteraction.on("modifyend", this.cleanCoords);
      }
      // if our field does not have a geometry, allow the user to draw it
      else {
        modifyInteraction = new ol.interaction.Draw({
          source: this.activeCropfieldLayer.getSource(),
          style: this.getDrawStyle,
          type: "Polygon",
        });

        modifyInteraction.on("drawstart", function (e) {
          t.$refs.lengthElement.classList.remove("d-none");
          t.$refs.lengthElement.innerHTML = "0m";
          t.lengthOverlay.setPosition(e.feature.getGeometry().getFirstCoordinate());

          e.feature.set("name", t.currentCropfield.name);

          // show the segments length in meters
          e.feature.getGeometry().on("change", function (evt) {
            var coordinates = evt.target.getCoordinates();
            if (coordinates[0].length >= 3) {
              t.lengthOverlay.setPosition(coordinates[0][coordinates[0].length - 2]);
              var line = new ol.geom.LineString([
                [coordinates[0][coordinates[0].length - 2]],
                [coordinates[0][coordinates[0].length - 3]],
              ]);
              t.$refs.lengthElement.innerHTML = Math.round(ol.sphere.getLength(line)) + "m";
            }
          });
        });

        modifyInteraction.on("drawend", function (e) {
          t.map.removeInteraction(modifyInteraction);
          t.addModifyInteraction(true);

          t.$refs.lengthElement.innerHTML = "";
          t.$refs.lengthElement.classList.add("d-none");

          // when draw end, we extract the geometry & compute the surface
          const geom = t.getGeometryFromFeature(e.feature);

          if (!geom) return;
          const geom4326 = project4326(geom);
          const surface = computeSurface(geom4326);

          // send the event with geometry & surface for who wants it
          EventBus.$emit("geometryDrawEnded", { geometry: geom4326.geometry, surface: surface });

          // also set it as a property
          e.feature.set("surface", surface);
        });
      }

      this.map.addInteraction(modifyInteraction);

      // on ajoute les aimantations

      // add snap on island & cropfield layer
      let snapCropfields = new ol.interaction.Snap({
        source: this.layerSource,
      });
      this.map.addInteraction(snapCropfields);

      let snapFirstCropfields = new ol.interaction.Snap({
        source: this.firstCropsLayer.getSource(),
      });
      this.map.addInteraction(snapFirstCropfields);

      let snapSecondCropfields = new ol.interaction.Snap({
        source: this.secondCropsLayer.getSource(),
      });
      this.map.addInteraction(snapSecondCropfields);

      // sur la parcelle qu'on est en train de modifier
      let snapModificationSource = new ol.interaction.Snap({
        source: this.activeCropfieldLayer.getSource(),
      });
      this.map.addInteraction(snapModificationSource);

      // sur l'ilot
      let snapIsland = new ol.interaction.Snap({
        source: this.islandsLayer.getSource(),
      });
      this.map.addInteraction(snapIsland);

      this.activeCropfieldLayer.getSource().dispatchEvent("change");
      this.activeParcelsLayer.getSource().dispatchEvent("change");
      this.layerSource.dispatchEvent("change");
      this.firstCropsLayer.getSource().dispatchEvent("change");
      this.secondCropsLayer.getSource().dispatchEvent("change");
    },
    removeDrawInteraction() {
      let t = this;
      this.map.getInteractions().forEach(function (interaction) {
        if (interaction instanceof ol.interaction.Draw) {
          t.map.removeInteraction(interaction);
        }
      });

      this.plotFeatureHoles();
    },

    // permet de nettoyer les coordonnées en retirant les doublons
    cleanCoords(e) {
      // une fonction auxiliaire qui permet de supprimer les doublons de sommets sur une ligne
      function removeVecticesDuplicates(coordinates) {
        var newCoordinatesRing = [];
        var j = 0;

        for (var i = 0; i < coordinates.length - 1; i++) {
          if (coordinates[i].join() != coordinates[i + 1].join()) {
            newCoordinatesRing[j] = coordinates[i];
            j++;
          }
          newCoordinatesRing[j] = coordinates[coordinates.length - 1];
        }

        return newCoordinatesRing;
      }

      let t = this;
      // on itère chaque feature modifiée
      e.features.forEach(function (f) {
        var coordinates = f.getGeometry().getCoordinates();
        var newCoordinates = [];

        // quel type ?
        let type = f.getGeometry().getType();

        if (type == "LineString") {
          newCoordinates = removeVecticesDuplicates(coordinates);
        } else if (type == "Polygon") {
          for (var k = 0; k < coordinates.length; k++) {
            newCoordinates[k] = removeVecticesDuplicates(coordinates[k]);
          }
        }

        // met à jour les coordonnées simplifiées
        f.getGeometry().setCoordinates(newCoordinates);
      });
    },

    // stop the modify/draw interaction
    async removeModifyInteraction({ save }, {displayActive} = {displayActive : true}) {
      this.drawMode = false;
      this.displayActiveCropfield = displayActive;

      // if we want to save it, save it
      if (save) {
        const geom = this.getGeometryFromLayer(this.activeCropfieldLayer);
        if (!geom) return;
        const geom4326 = project4326(geom);
        //geom4326 = turf.cleanCoords(geom4326);
        const surface = computeSurface(geom4326);

        await this.updateEntry({
          id: this.currentCropfield.id,
          data: {
            surface: surface,
            geometry: geom4326.geometry,
          },
        });
        await this.plotFields(false);
      }
      // otherwise, cancel everything
      else {
        if (this.activeCropfieldLayer) {
          this.activeCropfieldLayer.getSource().clear();
          this.activeCropfieldLayer.getSource().dispatchEvent("change");
        }
        if(this.activeVarietyLayer) {
          this.activeVarietyLayer.getSource().clear();
          this.activeVarietyLayer.getSource().dispatchEvent("change");
        }
      }
      let t = this;
      this.map.getInteractions().forEach(function (interaction) {
        if (interaction instanceof ol.interaction.Draw || interaction instanceof ol.interaction.Modify) {
          t.map.removeInteraction(interaction);
        }
      });

      this.activeParcelsLayer.getSource().dispatchEvent("change");
      this.layerSource.dispatchEvent("change");
      this.firstCropsLayer.getSource().dispatchEvent("change");
      this.secondCropsLayer.getSource().dispatchEvent("change");
    },
  },
  watch: {
    mapCutMode(newValue, oldValue) {
      if (newValue == 0 && oldValue != 0) {
        this.resetMapCut();
      }
      // si on active un mode découpe (1 = découpe en ligne, 2 = en bordure, 3 = broken line/ligne brisée)
      if (oldValue == 0 && newValue > 0) {
        if (this.currentCropfieldHasGeometry) {
          // charge le polygon de la parcelle
          this.cutModeLoadPolygon();

          // découpe en ligne
          if (newValue == 1) {
            // démarre l'interaction
            this.cutLineAddSelectPointInteraction();
          }
          if (newValue == 3) {
            // broken line mode
            this.startDrawBrokenLine();
          }
          this.showActiveCropfield();
        }
      }
      this.layerSource.dispatchEvent("change");
      this.activeParcelsLayer.getSource().dispatchEvent("change");
      this.firstCropsLayer.getSource().dispatchEvent("change");
      this.secondCropsLayer.getSource().dispatchEvent("change");
    },
  },
};
