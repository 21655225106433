import GraphApi from '@/api/graph.api';

export default {
  namespaced: true,
  state: {
    cropsGraphData: {},
    seedingProgressGraphData: {},
    secondCropsGraphData: {},
    secondSeedingProgressGraphData: {},
  },
  getters: {
    cropsGraphData: state => state.cropsGraphData,
    seedingProgressGraphData: state => state.seedingProgressGraphData,
    secondCropsGraphData: state => state.secondCropsGraphData,
    secondSeedingProgressGraphData: state => state.secondSeedingProgressGraphData,
  },
  mutations: {
    setFarms: (state, farms) => {
      state.farms = farms;
    },
    setCropsGraphData: (state, data) => {
      state.cropsGraphData = data;
    },
    setSeedingProgressGraphData: (state, data) => {
      state.seedingProgressGraphData = data;
    },
    setSecondCropsGraphData: (state, data) => {
      state.secondCropsGraphData = data;
    },
    setSecondSeedingProgressGraphData: (state, data) => {
      state.secondSeedingProgressGraphData = data;
    },
  },
  actions: {
    async fetchCropsGraphData({ commit, rootGetters }, {cropOrder}) {
      const currentCampaign = rootGetters.getCampaign;
      const currentFarm = rootGetters['farm/currentFarm'];

      let result = await GraphApi.getGraph({
        id: 1,
        campaign: currentCampaign && currentCampaign.id,
        farm: currentFarm && currentFarm.id,
        cropOrder: cropOrder,
      });
      if(cropOrder && cropOrder >= 2) {
        commit('setSecondCropsGraphData', result.data)
      }else{
        commit('setCropsGraphData', result.data);
      }
    },

    async fetchSeedingProgressGraphData({ commit, rootGetters }, {cropOrder}) {
      const currentCampaign = rootGetters.getCampaign;
      const currentFarm = rootGetters['farm/currentFarm'];

      let result = await GraphApi.getGraph({
        id: 2,
        campaign: currentCampaign.id,
        farm: currentFarm && currentFarm.id,
        cropOrder: cropOrder,
      });
      if(cropOrder && cropOrder >= 2) {
        commit('setSecondSeedingProgressGraphData', result.data)
      }else{
        commit('setSeedingProgressGraphData', result.data);
      }
    },
  },
};
