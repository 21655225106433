<template>
  <div>
    <b-row v-if="!loaded">
      <b-col class="text-center mt-5"><b-spinner></b-spinner></b-col>
    </b-row>
    <b-row :key="hash" v-else>
      <b-col v-for="field in fieldsToShow" class="mb-3" :key="field.name" :cols="field.cols ? field.cols : 6">
        <h4 class="subtitle-form mb-2 text-nowrap">{{ field.title }}</h4>

        <div v-if="editMode">
          <component
            :key="'edit-' + cropfields.length"
            :is="field.editComponent"
            :rowId="currentId"
            :cellData="getDefaultValue(field.name)"
            :field="field.name"
            :initialValue="newMode ? field.default : null"
            @updateRow="updateRow"
          ></component>
        </div>
        <div
          v-else
          :class="{
            'bg-transparent': ['appellation'].includes(field.name),
            'bg-white text-infos': !['appellation'].includes(field.name)
          }"
        >
          <div id="field" v-html="displayField(field.name, field.display, field.mergeStrategy)" @click="handleLineClick"></div>
          <!-- Affichage des variétés et du bouton pour la visualisation sur la carte -->
          <div v-if="field.name == 'variety'" class="mt-1 text-center">
            <b-button size="sm" v-if="varieties_have_geometry" @click="goToMapWithVarieties()">
              {{ $t("cropfields.details.see_on_map")}}
            </b-button>
            <div
              :class="['mt-1', (totalVarietySurface < 0.99*currentEntry.surface || totalVarietySurface > 1.01*currentEntry.surface)  ? 'error-message' : '']"
              v-if="currentEntry.varieties && currentEntry.varieties.length > 1 && totalVarietySurface"
            >
              {{ $t('cropfields.details.total') }} : {{ round(totalVarietySurface, 2) }} ha
            </div>
          </div>
          <!-- Affichage du bouton pour définir une second culture -->
          <div v-if="field.name == 'btnSecondCrop'">
            <!-- Définition d'une deuxième culture -->
            <b-row v-if="!editMode && entriesChecked.length < 2">
              <b-col cols="12">
                <b-button
                  size="sm"
                  class="button-default"
                  type="button"
                  v-if="currentEntry.nextCropFields.length == 0 && currentEntry.previousCropField == null && currentEntry.crop != null && currentEntry.crop_permanent != true"
                  @click="openModal"
                >
                  {{$t("cropfields.details.btn_second_crop")}}
                </b-button>
              </b-col>
            </b-row>
          </div>
          <!-- Affichage des zones non traitées -->
          <div v-if="field.name == 'nonTreatedZone'">
            <NonTreatedZone/>
          </div>
        </div>
      </b-col>
    </b-row>

    <DialogModal />
    <HarvestModal ref="harvestModal" :cropName="currentEntry.crop_name" @patchHarvestDate="patchHarvestDate"/>
    <DoubleCropModal @defineNewCrop="defineNewCrop"/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/eventBus.js";
import _ from "lodash";

import EditableCellText from "@/components/commons/editable/EditableCellText.vue";
import EditableCellNumber from "@/components/commons/editable/EditableCellNumber.vue";
import CropDropdown from "@/components/commons/CropDropdown.vue";
import VarietyDropdown from "@/components/commons/VarietyDropdown.vue";
import PacageDropdown from "@/components/commons/PacageDropdown.vue";
import Checkbox from "@/components/commons/Checkbox.vue";
import DatePicker from "@/components/commons/DatePicker.vue";
import CropfieldIslandForm from "@/components/commons/CropfieldIslandForm.vue";
import DoubleCropModal from "./DoubleCropModal.vue";
import HarvestModal from "./HarvestModal.vue";
import SoilTypeDropdown from "@/components/commons/SoilTypeDropdown.vue";
import SoilDepthDropdown from "@/components/commons/SoilDepthDropdown.vue";
import NextCrop from "@/components/commons/NextCrop.vue";
import PreviousCrop from "@/components/commons/PreviousCrop.vue";
import EditNonTreatedZone from "@/components/cropfields/edit/EditNonTreatedZone.vue";
import NonTreatedZone from "@/components/cropfields/details/NonTreatedZone.vue";
import DialogModal from "@/components/commons/DialogModal.vue";
import EditCellSurface from "../edit/EditCellSurface.vue";

export default {
  data(){
    return{
      loaded: false,
    }
  },
  async created() {
    this.loaded = false;
    await this.getSoilDepths();
    this.loaded = true;
  },
  computed: {
    ...mapGetters({
      currentId: "cropfield/currentId",
      currentEntry: "cropfield/currentEntry",
      currentEditedEntry: "cropfield/editedEntry",
      entries: "cropfield/entries",
      entriesChecked: "cropfield/entriesChecked",
      campaigns: "campaigns",
      checkedEntries: "cropfield/checkedEntries",
    }),
    cropfields() {
      if (this.entriesChecked.length == 0) return this.currentEntry ? [this.currentEntry] : [];
      return this.entries.filter(e => this.entriesChecked.includes(e.id));
    },
    hash() {
      return this.cropfields.map(e => e.id).reduce((a, b) => a + b, 0);
    },
    fieldsToShow() {
      let fields;
      if (this.editMode)
        fields = this.fields.filter(
          field =>
            field.editComponent &&
            (this.newMode || this.cropfields.length == 1 || (this.cropfields.length > 1 && field.editMany)),
        );
      else fields = this.fields.filter(field => !field.showEditionOnly);

      if (!this.newMode) fields = fields.filter(f => !f.showNewOnly);

      fields = fields.filter(f => f.condition == undefined || f.condition({store: this.$store}) == true);

      return fields;
    },
    totalVarietySurface() {
      let result = 0;
      this.currentEntry.varieties?.map(cv => {
        if(cv.surface)
          result = result + parseFloat(cv.surface);
      });
      return result;
    },
    varieties_have_geometry() {
      let result = false;
      this.currentEntry.varieties?.map(cv => {
        if (cv.hasZone)
          result = true;
      });
      return result;
    },
    isOnMap() {
      return this.$route.name.includes("map");
    },
  },
  methods: {
    ...mapActions({
      getSoilDepths: "cropfield/getSoilDepths",
    }),
    round: _.round,
    displayField(field, display, mergeStrategy) {
      let result = this.getNotNullValues(field);

      if (display && display.mode && display.mode == "fromStore") {
        result = result.map(value => {
          value = this.$store.getters[display.getter].find(e => e.id == value);
          if (value && display.key) return value[display.key];
          return value;
        });
      }

      if (display && display.mode && display.mode == "fromAnotherModel") {
        if (field == "variety") {
          if (this.entriesChecked.length > 1) {

            let varietyMap = new Map();
            this.checkedEntries.forEach(entry => {
                entry.varieties?.forEach(v => {
                    let varietyName = v.variety_name;
                    let surface = parseFloat(v.surface) || 0;

                    if (varietyMap.has(varietyName)) {
                        let currentSurface = varietyMap.get(varietyName);
                        varietyMap.set(varietyName, currentSurface + surface);
                    } else {
                        varietyMap.set(varietyName, surface);
                    }
                });
            });

            let resultString = "";

            varietyMap.forEach((surface, varietyName) => {
                resultString = "<div class='row mb-1'><div class='col-6' align='left'>" + varietyName + "</div>";
                if (surface) resultString += "<div class='col-6' align='right'>" + surface.toFixed(2) + " ha" + "</div>"
                resultString += "</div>"
                result.push(resultString);
            });

            result = [...new Set(result)]
          }
          else {
            let resultString = ""
            this.currentEntry.varieties?.map(cv => {
              resultString = "<div class='row mb-1'><div class='col-6' align='left'>" +cv.variety_name + "</div>"
              if (cv.surface) resultString += "<div class='col-6' align='right'>" + cv.surface + " ha" + "</div>"
              resultString += "</div>"
              result.push(resultString);
            });
            result = [...new Set(result)];
          }
        }

        if(field == "second_crop") {
          this.currentEntry.nextCropFields.map(cf => {
            result.push("<div class='row mb-1'><div class='col-12' align='left'><a href='javascript:void(0);' id='next-" + cf.id + "'>" + cf.crop_name + "<a/></div></div>")
          });
        }

        if(field == "first_crop") {
          result.push("<div class='row mb-1'><div class='col-12' align='left'><a href='javascript:void(0);' id='next-" + this.currentEntry.previousCropField + "'>" + this.currentEntry.previousCropField_crop_name + "<a/></div></div>")
        }

        if(field == "nonTreatedZone") {
          if (this.currentEntry.nonTreatedZoneTypes?.length <= 0) {
            result.push(this.$t("cropfields.list.no_znt"));
          }
        }
      }

      if (display && !display.mode) result = result.map(e => display[e]);

      if (mergeStrategy == "sum" && result.length > 0) {
        result = result.reduce((a, b) => parseFloat(a) + parseFloat(b));
        result = Math.round(result * 10000) / 10000;
      }
      else if (mergeStrategy == "lineBreak") {
        result = result.join("");
      }
      else result = result.join(", ");

      // add units
      if (field == "surface") result += " ha";
      if (field == "declaredSurface") result += " ha";
      if (field == "rowWidth" && result) result += " mètres";
      if (field == "underRowWidth" && result) result += " mètres";

      if (result === "" && field != "btnSecondCrop" && field != "nonTreatedZone" && field != "appellation") return this.$t("is_empty");
      return result;
    },
    getNotNullValues(field) {
      return this.cropfields.map(e => e[field]).filter((x, i, a) => a.indexOf(x) === i && x != undefined && x != null);
    },
    getValues(field) {
      return this.cropfields.map(e => e[field]).filter((x, i, a) => a.indexOf(x) === i);
    },
    getDefaultValue(field) {
      let values = this.getValues(field);
      if (values.length == 1) return values[0];
      else return null;
    },
    updateRow(id, data) {
      this.$store.dispatch("cropfield/setEditedEntryValue", data);
    },

    /**
     * Affiche la carte avec la couche des variétés activée
     */
    goToMapWithVarieties() {
      let varietyLayerIndex = 4;
      EventBus.$emit("activeALayer", varietyLayerIndex);
      this.$store.dispatch("cropfield/setActiveVarietiesLayer", true);
      if (this.$route.name != "cropfield.map.details")
        this.$router.push({ name: 'cropfield.map.details', params: { cropfield_id: this.$route.params.cropfield_id } });
    },

    /**
     * Ouvre la boîte informant l'utilisateur qu'une nouvelle parcelle sera créée
     */
    openModal() {
      // S'il n'y a pas de date de récolte renseignée sur la parcelle, on demande d'abord à l'utilisateur de la saisir
      if (this.currentEntry.harvestDate || this.currentEntry.expectedHarvestDate) {
        EventBus.$emit("showDoubleCropModal");
      } else {
        this.$refs.harvestModal.showModal();
      }
    },

    /**
     * Patch de la date de récolte sur la parcelle avant création de seconde culture
     */
    async patchHarvestDate(date) {
      this.$refs.harvestModal.hideModal();
      let data = {};
      // Si la date saisie est déjà passée, on la patch en tant que date effective de récolte
      if (new Date(date).getTime() < new Date().getTime()) {
        data = { harvestDate: date };
      } else {
        // Sinon, en tant que date prévisionnelle de récolte
        data = { expectedHarvestDate: date };
      }
      await this.$store.dispatch("cropfield/updateEntry", {id: this.currentEntry.id, data: data});
      // Puis on informe l'utilisateur du process de création d'une seconde culture, si nécessaire (géré dans la modale correspondante)
      this.openModal();
    },

    /**
     * Ferme la boîte informant l'utilisateur qu'une nouvelle parcelle sera créée
     */
    hideModal() {
      EventBus.$emit("hideDoubleCropModal");
    },

    defineNewCrop: async function() {
      // appel l'api pour définir une nouvelle culture et passe en mode modification de la parcelle créée
      this.hideModal();
      let nextCropfield = await this.$store.dispatch("cropfield/defineNewCrop", {id : this.currentEntry['id']});
      this.changeCropFieldSelection(nextCropfield.id)
      setTimeout(() => {
        EventBus.$emit("setEditMode");
      }, 400);
    },
    changeCropFieldSelection(id) {
      // Sélectionne une autre parcelle dans la liste ou sur la map et affiche son détail
      if (this.isOnMap) {
        EventBus.$emit("selectOnMap", id);
      } else {
        EventBus.$emit("selectOnList", id);
      }
    },

    /**
     * Permet de gérer le clic sur la culture précédente ou suivante, le cas échéant
     */
    handleLineClick(e) {
      let clickedElId = e.target.id;
      if (clickedElId.startsWith("next-")) {
        let nextCropfieldId = parseInt(clickedElId.substring(5));
        this.changeCropFieldSelection(nextCropfieldId);
      }
    }
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    newMode: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EditableCellText,
    EditableCellNumber,
    EditCellSurface,
    CropDropdown,
    PacageDropdown,
    Checkbox,
    DialogModal,
    DatePicker,
    CropfieldIslandForm,
    DoubleCropModal,
    HarvestModal,
    VarietyDropdown,
    SoilTypeDropdown,
    SoilDepthDropdown,
    NextCrop,
    PreviousCrop,
    EditNonTreatedZone,
    NonTreatedZone,
  },

  watch: {
    currentId: {
      deep: true,
      handler: async function() {
        if(this.entriesChecked?.length <= 1){
          if(this.currentEntry.crop != null)
            await this.$store.dispatch("getOneCropType", { id: this.currentEntry.crop });
          else
            await this.$store.dispatch("resetCurrentCropType");
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
h6 {
  font-weight: bold;
}
.error-message {
  color: $danger-color;
}
</style>
