<template>
  <div>
    <div v-if="checkFamily()">
      <!-- Elements select list -->
      <h4 class="subtitle-form">{{ checkDesignation() }}</h4>

      <!-- Other elements table -->
      <table>
        <tbody>
          <tr v-for="elem in allElements()" v-bind:key="elem.compound">
            <!-- compound name -->
            <td class="shape-first-cell">
              <span class="subtitle-form"> {{ elem.symbol }} </span>
            </td>

            <!-- dose -->
            <td>
              <!-- <div v-if="input.errQuantity" class="text-input-notes error-quantity-inputs">
                {{ input.errMessage }}
              </div> -->
              <NumericInput
                inputRef="input"
                :value="elem.dose"
                @input="i => {elem.dose = i}"
                class="width-composition-inputs"
              />
            </td>

            <!-- unit -->
            <td>
              <select class="width-select-unit-npk" v-model="elem.unit">
                <option v-for="unity in globalUnit" v-bind:key="unity.id" :value="unity.id">{{ unity.unit }}</option>
              </select>
            </td>

            <!-- Delete button -->
            <td>
              <button class="btn align-bottom" @click="onDelete(elem.compound)">
                <img :src="icon" alt="icon delete action" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <select class="shape-select resize-select" v-model="elemSelected" @change="addElem()">
        <option value="" selected>---------</option>
        <option v-for="elem in elemCompo" v-bind:key="elem.id" :value="elem.id">{{ elem.symbol }}</option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import delIcon from "@/assets/images/delete.svg";
import NumericInput from "@/components/commons/NumericInput.vue";

export default {
  name: "others-elements",
  created() {
    this.fillOtherElements();
    this.unitSelected = this.inputFormByPtype.compositionUnits[0].id;
    // else {
    // }
    this.globalUnit = this.inputFormByPtype.compositionUnits;
  },
  data() {
    return {
      icon: delIcon,
      unitSelected: "",
      elemSelected: "",
      elemToPatch: [],
      elemCompo: [],
      globalUnit: [],
    };
  },
  components: {NumericInput},
  methods: {
    fillOtherElements() {
      this.inputFormByPtype.compounds.forEach(compound => {
        if (compound.symbol != "N" && compound.symbol != "P" && compound.symbol != "K") this.elemCompo.push(compound);
      });
    },

    checkDesignation() {
      if (this.currentInputStock.productFamily_code === "engsy") return "Autre éléments";
      else if (this.$route.name === "inputStock.new" && this.currentFamily == "engsy") return "Autre éléments";
      else if (this.currentInputStock.productFamily_code === "amend") return "Composition du produit";
      else if (this.$route.name === "inputStock.new" && this.currentFamily == "amend") return "Composition du produit";
    },

    checkFamily() {
      if (
        !this.readOnly &&
        (this.currentInputStock.productFamily_code === "engsy" || this.currentInputStock.productFamily_code === "amend")
      )
        return true;
      else if (
        this.$route.name === "inputStock.new" &&
        (this.currentFamily == "engsy" || this.currentFamily == "amend")
      )
        return true;
      return false;
    },

    getOthersElements() {
      for (let i = 0; i < this.elemToPatch.length; i++) {
        delete this.elemToPatch[i].symbol;
      }
      return this.elemToPatch;
    },

    /**
     * Delete elements into elemToPatch.
     */
    onDelete(cmpd) {
      let compound = cmpd;
      let val = this.elemToPatch.filter(function(elem) {
        if (elem.compound == compound) return elem;
      });

      const filter = this.elemToPatch.indexOf(val[0]);
      if (filter > -1) this.elemToPatch.splice(filter, 1);
    },

    /**
     * Add elements into the array 'elemToPatch'.
     */
    addElem() {
      if (this.elemSelected != "") {
        let compound = this.elemSelected;
        let element = this.elemCompo.filter(function(elem) {
          if (elem.id == compound) return elem;
        });

        if (!this.checkIfElemWasAdded(element[0].id))
          this.elemToPatch.push({
            dose: 0,
            compound: element[0].id,
            unit: this.unitSelected,
            symbol: element[0].symbol,
          });
      }
    },

    /**
     * Description: Renvoie true si l'élément a déjà été ajouté sinon false.
     * @param {number}      compound Le compound de l'élément.
     * @return {boolean}
     */
    checkIfElemWasAdded(compound) {
      let response = this.elemToPatch.filter(function(elem) {
        if (elem.compound == compound) return elem;
      });

      if (response.length > 0) return true;
      return false;
    },

    /**
     * Renvoie tout les elements à patcher.
     * Vérifie si il y a eu un changement et s'il faut donc
     * vider le contenu du tableau 'elemToPatch'.
     */
    allElements() {
      // if (this.elemMustBeClean) {
      // this.elemToPatch = [];
      // this.$store.commit(SET_STATE_ELEMS_TO_PATCH, false);
      // }
      return this.elemToPatch;
    },
  },
  computed: {
    ...mapGetters({
      currentInputStock: "inputStock/currentEntry",
    }),
    ...mapGetters(["readOnly", "currentProduct", "inputFormByPtype", "currentFamily"]),
  },
  watch: {
    currentInputStock(newValue, oldValue) {
      if(!this.currentInputStock) return;
      
      if (this.$route.name === "inputStock.edit") {
        if (this.currentInputStock.hasOwnProperty("product_id")) {
          this.currentProduct.composition.forEach(compo => {
            if (compo.compound_symbol != "N" && compo.compound_symbol != "P" && compo.compound_symbol != "K")
              this.elemToPatch.push({
                dose: compo.dose,
                compound: compo.compound,
                unit: compo.unit,
                symbol: compo.compound_symbol,
              });
            this.unitSelected = compo.unit;
          });
        } else if (this.currentInputStock.composition.length > 0) {
          this.currentInputStock.composition.forEach(compo => {
            if (compo.compound_symbol != "N" && compo.compound_symbol != "P" && compo.compound_symbol != "K")
              this.elemToPatch.push({
                dose: compo.dose,
                compound: compo.compound,
                unit: compo.unit,
                symbol: compo.compound_symbol,
              });
            this.unitSelected = compo.unit;
          });
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
button {
  font-size: 0.8em;
  font-weight: bold;

  img {
    width: 1.5em;
  }
}
/**
 * Width for the composition elements .
 */
.width-composition-inputs {
  border-radius: 4px;
  width: 97px;
  padding-left: 3px;
}

.shape-first-cell {
  width: 100px;
}

.width-select-unit-npk {
  border-radius: 4px;
  font-size: 14px;
  width: 77px;
}

table {
  margin-top: 0px;
}

.resize-select {
  width: 196px;
}
</style>
