<template>
  <div v-if="!this.fertilisationNeedsCalculationOption.ceilingDose_show">
    <b-row>
      <b-col class="col-8">
        <h4 class="mb-3">
          <i>Besoins</i>
        </h4>
      </b-col>
    </b-row>

    <b-row>
      <!-- B -->
      <b-col class="pr-4 col-6">
        <h4 v-if="this.fertilisationNeedsCalculationOption.yieldGoal_show" class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.b">{{$t('cropfields.fertilization.needsCalculation.b')}}</h4>
        <h4 v-else class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.fixedDose">{{$t('cropfields.fertilization.needsCalculation.fixedDose')}}</h4>
        <NumericInput
          inputRef="input"
          :value="b"
          @input="i => {b = i}"
          @blur="updateB()"
          :disabled="!edit_mode"
          :class="{ 'disabled-input': !edit_mode }"
        />
      </b-col>

      <!-- b_unit -->
      <b-col class="pr-4 col-6" >
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.bUnit">{{$t('cropfields.fertilization.needsCalculation.doseUnit')}}</h4>
        <template v-if="edit_mode">
          <b-form-select
            size="sm"
            v-model="bUnit"
            :options="this.fertilisationNeedsCalculationList.b_units"
            value-field="id"
            text-field="designation"
            @change="updateBUnit()"
          />
        </template>
        <template v-else>
            <p class="text-infos bg-white">
              <span> {{ this.fertilisationNeedsCalculation.b_unit_designation  ? this.fertilisationNeedsCalculation.b_unit_designation   : "-" }} </span>
          </p>
        </template>
      </b-col>
    </b-row>

    <!--yieldGoal = -->
    <b-row v-if="this.fertilisationNeedsCalculationOption.yieldGoal_show">
      <b-col class="pr-4 col-6" >
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.yieldGoal">{{$t('cropfields.fertilization.needsCalculation.yieldGoal')}}</h4>
        <NumericInput
          inputRef="input"
          :value="yieldGoal"
          @input="i => {yieldGoal = i}"
          @blur="updateYieldGoal()"
          :disabled="!edit_mode"
          :class="{ 'disabled-input': !edit_mode }"
        />
      </b-col>

      <!-- yieldUnit = yield_unit -->
      <b-col class="pr-4 col-6" >
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.yieldUnit">{{$t('cropfields.fertilization.needsCalculation.yieldUnit')}}</h4>
        <template v-if="edit_mode">
          <b-form-select
            size="sm"
            v-model="yieldUnit"
            :options="this.fertilisationNeedsCalculationList.yield_units"
            value-field="id"
            text-field="designation"
            @change="updateYieldUnit()"
          />
        </template>
        <template v-else>
          <p class="text-infos bg-white">
            <span> {{ this.fertilisationNeedsCalculation.yieldUnit_designation ?  this.fertilisationNeedsCalculation.yieldUnit_designation : "-" }} </span>
          </p>
        </template>
      </b-col>
    </b-row>

    <b-row>
      <!--rootingDepth = -->
      <b-col class="pr-4 col-6">
        <h4 class="subtitle-form mb-2"  v-b-tooltip.hover.html="fertiToolTips.rootingDepth">{{$t('cropfields.fertilization.needsCalculation.rootingDepth')}}</h4>
        <NumericInput
          inputRef="input"
          :value="rootingDepth"
          @input="i => {rootingDepth = i}"
          @blur="updateRootingDepth()"
          :disabled="!edit_mode"
          :class="{ 'disabled-input': !edit_mode }"
        />
      </b-col>

      <!-- Rf -->
      <b-col class="pr-4 col-6" >
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.Rf">{{$t('cropfields.fertilization.needsCalculation.Rf')}}</h4>
        <NumericInput
          v-if="otherCalcul"
          inputRef="input"
          :value="Rf"
          @input="i => {Rf = i}"
          @blur="updateRf()"
          :disabled="!edit_mode"
          :class="{ 'disabled-input': !edit_mode }"
        />
        <p class="text-infos bg-white" v-else>
            <span> {{ Rf ? Rf : "-" }} </span>
         </p>
      </b-col>
    </b-row>

    <b-row>
      <!-- Pf -->
      <b-col class="pr-4 col-6" >
        <h4
          class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.Pf"
        >{{$t('cropfields.fertilization.needsCalculation.Pf')}}</h4>
        <NumericInput
          v-if="otherCalcul"
          inputRef="input"
          :value="Pf"
          @input="i => {Pf = i}"
          @blur="updatePf()"
          :disabled="!edit_mode"
          :class="{ 'disabled-input': !edit_mode }"
        />
         <p class="text-infos bg-white" v-else>
            <span> {{ Pf ? Pf : "-" }} </span>
         </p>
      </b-col>
    </b-row>
  </div>

  <div v-else>
    <b-row>
      <b-col class="col-8">
        <h4 class="mb-3">
          <i>Dose Plafond</i>
        </h4>
      </b-col>
    </b-row>

    <b-row>
      <!-- B -->
      <b-col class="pr-4 col-6"  >
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.ceilingDose">Besoins dose plafond</h4>
        <NumericInput
          inputRef="input"
          :value="ceilingDose"
          @input="i => {ceilingDose = i}"
          @blur="updateCeilingDose()"
          :disabled="!edit_mode"
          :class="{ 'disabled-input': !edit_mode }"
        />
      </b-col>
    </b-row>

  </div>

</template>

<script>
import NumericInput from "@/components/commons/NumericInput.vue";

export default {
  name: "fertilization-besoins",
  data(){
    return{
      otherCalcul: false,
      edit_mode: false,
      b: 0,
      bUnit: 0,
      yieldGoal: null,
      yieldUnit: null,
      rootingDepth: 0,
      Rf: null,
      Pf: null,
      ceilingDose: null,
    }
  },
  created() {
    this.init();
  },
  props: [
    'editMode',
    'fertilisationNeedsCalculation',
    'fertilisationNeedsCalculationList',
    'fertilisationNeedsCalculationOption',
    'fertiToolTips',
  ],
  methods: {
    init: async function() {
      await this.loadData();
    },

    goToHelp() {
      console.log("lien cliquer")
    },

    async loadData(){
      if(typeof this.fertilisationNeedsCalculation != 'undefined') {
        await this.editCheck()
        this.b = this.fertilisationNeedsCalculation.b
        this.bUnit = this.fertilisationNeedsCalculation.bUnit
        this.yieldGoal = this.fertilisationNeedsCalculation.yieldGoal
        this.yieldUnit = this.fertilisationNeedsCalculation.yieldUnit
        this.rootingDepth = this.fertilisationNeedsCalculation.rootingDepth
        this.Rf = this.fertilisationNeedsCalculation.Rf
        this.Pf = this.fertilisationNeedsCalculation.Pf
        this.ceilingDose = this.fertilisationNeedsCalculation.ceilingDose
      }
    },
    async editCheck(){
      this.edit_mode = this.editMode
    },
    async updateB(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "b", value: this.b });
    },
    async updateBUnit(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "bUnit", value: this.bUnit });
    },
    async updateYieldGoal(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "yieldGoal", value: this.yieldGoal });
    },
    async updateYieldUnit(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "yieldUnit", value: this.yieldUnit });
    },
    async updateRootingDepth(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "rootingDepth", value: this.rootingDepth });
    },
    async updateRf(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "Rf", value: this.Rf });
    },
    async updatePf(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "Pf", value: this.Pf });
    },
    async updateCeilingDose(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "ceilingDose", value: this.ceilingDose });
    },
  },
  watch: {
    fertilisationNeedsCalculation: {
      handler: 'loadData',
      immediate: true
    },
    editMode: {
      handler: 'editCheck',
      immediate: true
    }
  },
  components: {
    NumericInput,
  },
};

</script>

<style>
.disabled-input:disabled {
  background-color: white !important;
  border: none;
}

.to-much-text{
  font-size: 11px;
  font-weight: bold;
  margin: 7px 0;
  margin-bottom: 4px !important;
  padding: 0;
  color: #aaaaaa;
  font-family: "Roboto", sans-serif;
}

</style>