import Gateway from "./base";

export default class ActivityTypesApi {
  /**
   * Get All Activity Types
   */
  static getActivityTypes() {
    return Gateway.get("/refapi/activityTypes/");
  }

  /**
   * Get one activity by id
   * @param id
   */
  static getOneActivityType(id) {
    return Gateway.get(`/refapi/activityTypes/${id}/`);
  }
  /**
   * Get activity type by machine type if
   */
  static getActivityTypeFromMachineTypes(data) {
    return Gateway.get(`/refapi/activityTypes/activityType/`, data);
  }

}
