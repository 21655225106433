import ProductUsageApi from "../../api/productUsage.api";

export const SET_USAGE_INFO = "SET USAGE INFO";

export default {
  state: {
    productUsagesInfo: [],
  },
  getters: {
    productUsagesInfo: state => state.productUsagesInfo,
  },
  mutations: {
    [SET_USAGE_INFO]: (state, payload) => {
      state.productUsagesInfo[payload['product'].id] = payload['product'].instructions;
    },
  },
  actions: {
    async getProductUsageInfo({ commit }, payload) {
      const response = await ProductUsageApi.getProductUsageInfo(payload.id);
      commit(SET_USAGE_INFO, { product: response.data });
      return response.data;
    },
  },
};
