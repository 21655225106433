<template>
  <v-select
    @input="onSelectChange()"
    :options="farms"
    label="name"
    v-model="value"
    :appendToBody="true"
    :clearable="false"
    :taggable="true"
    :selectable="(option) => !option.hasOwnProperty('group')"
    :required="true"
  >
    <template #option="{ group, pacage, name }">
      <div v-if="group" class="group">
        {{ group }}
      </div>
       {{ name }} - {{ pacage }}
    </template>
    <template #no-options>
      {{ $t("dropdown.no_option") }}
    </template>
  </v-select>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/eventBus.js";

export default {
  props: ["rowId", "field", "cellData", "activeFarmAsDefault", "ignoreExternalUpdaters"],
  data() {
    return {
      value: null,
    };
  },
  created() {
    if (this.$route.name.includes("new") || this.$route.params.mode == "outlineMode") {
      this.$store.dispatch("farm/fetchFarms")
    }
    
    this.value = this.farms.find((e) => e.id == this.cellData);
    if (this.value) this.value = this.value.name;
    // cas d'une valeur entrée à la main
    else this.value = this.cellData;

    // if no value is set, and activeFarmAsDefault is true
    // set the value as the active farm
    if(!this.value && this.activeFarmAsDefault) {
      this.value = {
        id: this.currentFarm?.id,
        pacage: this.currentFarm?.pacage,
        name: this.currentFarm?.name
      }

      this.$emit('updateRow', this.rowId, this.payload)
    }

    // if no value is set and there is only one farm in the exploitation
    // set the value as that farm
    if (!this.value && this.farms.length == 1) {
      this.value = {
        id: this.farms[0].id,
        pacage: this.farms[0].pacage,
        name: this.farms[0].name
      };

      this.$emit('updateRow', this.rowId, this.payload);
    }

    // change the value if another component ask to
    if(!this.ignoreExternalUpdaters)
      EventBus.$on("setPacageDropdownValue", this.setValue);
  },
  computed: {
    ...mapGetters({
      farms: "farm/farms",
      currentFarm: "farm/currentFarm",
    }),
    payload() {
      let payload = {};
      payload[this.field] = this.value?.id || this.value?.name;
      return payload;
    },
  },
  methods: {
    setValue(value) {
      this.value = value;
    },
    onSelectChange() {
      this.$emit("updateRow", this.rowId, this.payload);
      this.$store.dispatch("cropfield/getSoilTypes");
    }
  },
};
</script>

<style lang="scss" scoped>
.vs__selected {
  word-break: keep-all;
  white-space: nowrap;

  max-width: 60px;
}

.vs__selected-options {
  overflow: hidden;
}

.vs__dropdown-menu {
  width: fit-content !important;
}

.vs__dropdown-toggle {
  height: calc(1.5em + 0.5rem + 2px);
  // padding: 0.25rem 0.5rem;
  padding: 0 0 4px;
  font-size: 0.9rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.vs__dropdown-option {
  font-size: 0.9rem;
  padding: 2px 5px !important;
  word-break: keep-all !important;
  white-space: nowrap !important;
  border-bottom: 1px solid #ededed;
}

.vs__dropdown-option--disabled {
  background: #ededed !important;
  color: #2d2d2d;
  font-weight: bold;
  padding: 4px 5px !important;
}

.vs-inline .v-select,
.vs-inline .vs__dropdown-toggle,
.vs-inline .vs__search:focus {
  width: 100% !important;
}
</style>
