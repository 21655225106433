<template>
<div>
  <!-- Boîte proposant à l'utilisateur de créer des activités à partir d'une tournée -->
  <b-modal ref="generateActivities" id="modal-1" header-bg-variant="success" centered hide-header>
    <b-row align-v="center">
      <b-col cols="12" class="m-2 d-flex justify-content-center">
        <span>{{$t("tours.modal.generateActivitiesQuestion")}}</span>
      </b-col>
    </b-row>
    <template v-slot:modal-footer>
      <div class="w-100 d-flex justify-content-end align-items-center">
        <b-button class="btn btn-success mr-2" @click="generateActivities">
          {{ $t("yes") }}
        </b-button>
        <b-button class="btn btn-primary" @click="hideModal">
          {{ $t("no") }}
        </b-button>
      </div>
    </template>
  </b-modal>
  </div>
</template>

<script>
import { EventBus } from "@/eventBus.js";
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      generatedActivitiesNumber: null
    };
  },
  created() {
    EventBus.$on("showGenerateActivitiesModal", this.showModal);
  },
  beforeDestroy() {
    EventBus.$off("showGenerateActivitiesModal", this.showModal);
  },
	computed: {
		...mapGetters({
      currentTour: 'tour/currentEntry',
    }),
	},
  methods: {
    /**
     * @description Affiche la boîte
     */
    showModal() {
			this.$refs.generateActivities.show();
    },
    /**
     * @description Masque la boîte
     */
    hideModal() {
      this.$refs.generateActivities.hide();
    },

		/**
		 * @description fait appel à l'api pour générer les activités à partir de la tournée et affiche le nombre d'activités créées
		 */
		async generateActivities() {
      this.hideModal();
      // Provoque le rafraichissement de la liste des activités
      this.$store.dispatch("activity/setRefreshActivities", true);
      // Génération des activités
      this.generatedActivitiesNumber = await this.$store.dispatch("tour/generateActivities", this.currentTour.id);
      let message = "" + this.generatedActivitiesNumber + " " + this.$i18n.t("tours.modal.creationMessage");
      EventBus.$emit("toast", {
        message: message,
        variant: "success",
      });
		}
  },
};
</script>
<style lang="scss" scoped>
</style>