import Gateway from "./base";

export default class ProductTypeApi {
  /**
   * Get units associated to product Type ID
   *
   * @param id
   */
  static getUnitsProductType(id) {
    return Gateway.get(`/refapi/productType/${id}/units`);
  }

  /**
   * Get productype associated to product Family
   *
   * @param id
   */
  static getProductTypesByFamily(code) {
    return Gateway.get(`/refapi/productTypes/`, { productFamily__code: code });
  }

  /**
   * Get inputForm by productype
   *
   * @param id
   */
  static getinputFormByProductType(id) {
    return Gateway.get(`/refapi/productTypes/${id}/inputForm/`);
  }
}
