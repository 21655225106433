<template>
    <div>

     <!--Titre-->
     <b-row>
      <b-col class="col-8">
        <h4 class="mb-3">
          <i>Ouverture du bilan</i>
        </h4>
      </b-col>
    </b-row>

    <!--Date-->
    <b-row>
      <b-col class="col-8">
        <h4
          class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.assessmentOpeningDate"
        >{{$t('cropfields.fertilization.needsCalculation.assessmentOpeningDate')}}</h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6" class="text-infos shape-first-cell whitout-bg" >
        <div class="date-params">
          <input type="date" id="example-datepicker"
            v-model="assessmentOpeningDate"
           placeholder="yyyy-mm-dd" :disabled="!edit_mode" :class="{ 'disabled-input': !edit_mode }"
            @blur="updateAssessmentOpeningDate()"/>
        </div>
      </b-col>
    </b-row>

    <!--Date Sowing and harvest-->
    <b-row>

      <!--sowingDateShow-->
        <b-col class="col-6" v-if="this.fertilisationNeedsCalculationOption.sowing_date_show">
          <h4 class="subtitle-form mb-2"  v-b-tooltip.hover.html="fertiToolTips.sowingDate">{{$t('cropfields.list.sowingDate')}}</h4>
          <div class="date-params">
            <input type="date" id="example-datepicker"
            v-model="sowingDate"
            placeholder="yyyy-mm-dd" :disabled="!edit_mode" :class="{ 'disabled-input': !edit_mode }"
            @blur="updateSowingDate()"/>
          </div>
        </b-col>


      <!--harvestDateShow-->
        <b-col class="col-6" v-if="this.fertilisationNeedsCalculationOption.harvest_date_show">
          <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.harvestDate">{{$t('cropfields.list.harvestDate')}}</h4>
          <div class="date-params">
            <input type="date" id="exazmple-datepicker"
            v-model="harvestDate"
            placeholder="yyyy-mm-dd" :disabled="!edit_mode" :class="{ 'disabled-input': !edit_mode }"
            @blur="updateHarvestDate()"/>
          </div>
        </b-col>

    </b-row>

    <!--GrowthStage-->
    <b-row v-if="!this.fertilisationNeedsCalculationOption.ceilingDose_show">
      <b-col class="col-8" v-if="this.fertilisationNeedsCalculationOption.growthStates_show">
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.growthState">{{$t('cropfields.fertilization.needsCalculation.growthState')}}</h4>
        <template v-if="edit_mode">
        <b-form-select
          :options="this.fertilisationNeedsCalculationList.growthStates"
          value-field="id"
          text-field="designation"
          v-model="growthState"
          size="sm"
          @change="updateGrowthState()"
        />
        </template>
        <template v-else>
            <p class="text-infos bg-white">
                <span> {{ this.fertilisationNeedsCalculation.growthState_designation ? this.fertilisationNeedsCalculation.growthState_designation : "-" }} </span>
            </p>
        </template>
      </b-col>
    </b-row>

    <!--RSH-->
    <b-row v-if="!this.fertilisationNeedsCalculationOption.ceilingDose_show">

      <!--RIOption-->
      <b-col class="col-8" >
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.riOption">{{$t('cropfields.fertilization.needsCalculation.riOption')}}</h4>
        <template v-if="edit_mode">
        <b-form-select
          value-field="id"
          text-field="designation"
          size="sm"
          :options="this.fertilisationNeedsCalculationList.riOptions"
          v-model="riOption"
          @change="updateRiOption()"
        />
        </template>
        <template v-else>
          <p class="text-infos bg-white">
            <span> {{ this.fertilisationNeedsCalculation.riOption_designation ? this.fertilisationNeedsCalculation.riOption_designation : "-" }} </span>
         </p>
        </template>
      </b-col>

      <!--Ri Option1 & 2 -->
      <b-col class="col-4" >
        <h4 class="to-much-text mb-1" v-b-tooltip.hover.html="fertiToolTips.Ri">{{$t('cropfields.fertilization.needsCalculation.Ri')}}</h4>
        <NumericInput
          inputRef="input"
          :value="Ri"
          @input="i => {Ri = i}"
          @blur="updateRi()"
          :disabled="!edit_mode || (riOption == 2)"
          :class="{ 'disabled-input': !edit_mode }"
        />
      </b-col>
    </b-row>

    <!--soilAnalysis-->
    <b-row v-if="riOption == 2 && !this.fertilisationNeedsCalculationOption.ceilingDose_show">
      <b-col class="col-8" >
        <h4 
          class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.soilAnalysis"
        >{{$t('cropfields.fertilization.needsCalculation.referenceCropfield')}}</h4>
        <template v-if="edit_mode">
        <b-form-select
          :options="this.fertilisationNeedsCalculationList.soilAnalyzes"
          value-field="id"
          text-field="name"
          v-model="soilAnalysis"
          size="sm"
        />
        </template>
        <template v-else>
            <p class="text-infos bg-white">
            <span> {{ this.fertilisationNeedsCalculation.soilAnalysis_designation ? this.fertilisationNeedsCalculation.soilAnalysis_designation : "-" }} </span>
        </p>
        </template>
      </b-col>
    </b-row>

    <!--soilOrganicMatterRate-->
    <b-row v-if="this.fertilisationNeedsCalculationOption.soilOrganicMatterRate_show && !this.fertilisationNeedsCalculationOption.ceilingDose_show">
      <b-col class="col-8" >
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.soilOrganicMatterRate">{{$t('cropfields.fertilization.needsCalculation.soilOrganicMatterRate')}}</h4>
        <NumericInput
          inputRef="input"
          :value="soilOrganicMatterRate"
          @input="i => {soilOrganicMatterRate = i}"
          @blur="updateMv_sh()"
          :disabled="!edit_mode || (riOption == 2)"
          :class="{ 'disabled-input': !edit_mode }"
        />
      </b-col>
    </b-row>

  </div>

</template>

<script>
import { checkDatePattern } from "@/utils/commons";
import NumericInput from "@/components/commons/NumericInput.vue";

export default {
  name: "fertilization-bilan",
  data(){
    return{
      edit_mode: false,
      assessmentOpeningDate: "",
      sowingDate: "",
      harvestDate: "",
      growthState: null,
      Ri: null,
      riOption: null,
      soilAnalysis: null,
      soilOrganicMatterRate: null,
    }
  },
  created() {
    this.init();
  },
  props: [
    'editMode', 
    'fertilisationNeedsCalculation', 
    'fertilisationNeedsCalculationList', 
    'fertilisationNeedsCalculationOption',
    'fertiToolTips',
  ],
  methods: {
    init: async function() {
      await this.loadData();
    },
    async loadData(){
      if(typeof this.fertilisationNeedsCalculation != 'undefined') {
        await this.editCheck()
        this.assessmentOpeningDate = this.fertilisationNeedsCalculation.assessmentOpeningDate
        this.growthState = this.fertilisationNeedsCalculation.growthState
        this.sowingDate = this.fertilisationNeedsCalculation.sowingDate
        this.harvestDate = this.fertilisationNeedsCalculation.harvestDate
        this.Ri = this.fertilisationNeedsCalculation.Ri
        this.riOption = this.fertilisationNeedsCalculation.riOption
        this.soilAnalysis = this.fertilisationNeedsCalculation.soilAnalysis
        this.soilOrganicMatterRate = this.fertilisationNeedsCalculation.soilOrganicMatterRate
      }
    },
    async editCheck(){
      this.edit_mode = this.editMode
    },
    async updateAssessmentOpeningDate(){
      if(checkDatePattern(this.assessmentOpeningDate)){
        await this.$store.dispatch("fertilization/addAtributeChange", { name: "assessmentOpeningDate", value: this.assessmentOpeningDate });
      }
    },
    async updateGrowthState(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "growthState", value: this.growthState });
    },
    async updateSowingDate(){
      if(checkDatePattern(this.sowingDate)){
        await this.$store.dispatch("fertilization/addAtributeChange", { name: "sowingDate", value: this.sowingDate });
      }
    },
    async updateHarvestDate(){
      if(checkDatePattern(this.harvestDate)){
        await this.$store.dispatch("fertilization/addAtributeChange", { name: "harvestDate", value: this.harvestDate });
      }
    },
    async updateRi(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "Ri", value: this.Ri });
    },
    async updateRiOption(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "riOption", value: this.riOption });
    },
    async updateSoilAnalysis(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "soilAnalysis", value: this.soilAnalysis });
    },
    async updateSoilOrganiqueMaterRate(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "soilOrganicMatterRate", value: this.soilOrganicMatterRate });
    },
  },
  watch: {
    fertilisationNeedsCalculation: {
      handler: 'loadData',
      immediate: true
    },
    editMode: {
      handler: 'editCheck',
      immediate: true
    }
  },
  components: {
    NumericInput,
  },
};

</script>

<style>
.disabled-input:disabled {
  background-color: white !important;
  border: none;
}

.date-params {
  margin: 2px 10px 3px 15px;
}

.to-much-text{
  font-size: 11px;
  font-weight: bold;
  margin: 7px 0;
  margin-bottom: 2px !important;
  padding: 0;
  color: #aaaaaa;
  font-family: "Roboto", sans-serif;
}
</style>