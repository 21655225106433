import * as turf from "@turf/turf";

import { mapMutations } from "vuex";

import {
  project4326,
} from "@/utils/geomatics";

import { EventBus } from "@/eventBus.js";

export default {
  mounted() {

    EventBus.$on("enableModifyGeometry", this.addModifyInteraction);
    EventBus.$on("disableModifyGeometry", this.removeModifyInteraction);
  },
  data() {
    return {
    };
  },
  methods: {

    // this function is used to modify or draw a geometry
    addModifyInteraction(forceModify = false) {
      if (!this.map) return;

      let t = this;

      this.drawMode = true;

      this.displayActiveLocation = false;
      let modifyInteraction;

      // if our field has a geometry
      if (this.currentLocationHasGeometry || forceModify) {
        // plot the dots arounds the perimeter
        this.showActiveLocation();

        this.resetFeatureHoles();

        // add a modify interaction
        modifyInteraction = new ol.interaction.Modify({ source: this.activeLocationLayer.getSource() });

        modifyInteraction.on("modifystart", function (e) {
          let feature = e.features.getArray()[0];

          // every time the geometry changes, update the surface
          feature.getGeometry().on("change", function () {
            // add a debounce method for performance reasons
            let deb = t.debounce(function () {
              t.activeLocationLayer.getSource().dispatchEvent("change");
              t.locationLayerSource.dispatchEvent("change");
            }, 100);
            deb();
          });
        });

        modifyInteraction.on("modifyend", this.cleanCoords);
      }
      // if our field does not have a geometry, allow the user to draw it
      else {
        modifyInteraction = new ol.interaction.Draw({
          source: this.activeLocationLayer.getSource(),
          style: this.getDrawStyle,
          type: "Polygon",
        });

        modifyInteraction.on("drawstart", function (e) {
          t.$refs.lengthElement.classList.remove("d-none");
          t.$refs.lengthElement.innerHTML = "0m";
          t.lengthOverlay.setPosition(e.feature.getGeometry().getFirstCoordinate());

          e.feature.set("name", t.currentLocation.name);

          // show the segments length in meters
          e.feature.getGeometry().on("change", function (evt) {
            var coordinates = evt.target.getCoordinates();
            if (coordinates[0].length >= 3) {
              t.lengthOverlay.setPosition(coordinates[0][coordinates[0].length - 2]);
              var line = new ol.geom.LineString([
                [coordinates[0][coordinates[0].length - 2]],
                [coordinates[0][coordinates[0].length - 3]],
              ]);
              t.$refs.lengthElement.innerHTML = Math.round(ol.sphere.getLength(line)) + "m";
            }
          });
        });

        modifyInteraction.on("drawend", function (e) {
          t.map.removeInteraction(modifyInteraction);
          t.addModifyInteraction(true);

          t.$refs.lengthElement.innerHTML = "";
          t.$refs.lengthElement.classList.add("d-none");

          // when draw end, we extract the geometry 
          const geom = t.getGeometryFromFeature(e.feature);

          if (!geom) return;
          const geom4326 = project4326(geom);

          // send the event with geometry for who wants it
          EventBus.$emit("geometryDrawEnded", { geometry: geom4326.geometry });

        });
      }

      this.map.addInteraction(modifyInteraction);

      // on ajoute les aimantations

      // add snap on location & cropfield layer
      let snapLocations = new ol.interaction.Snap({
        source: this.locationLayerSource,
      });
      this.map.addInteraction(snapLocations);

      // sur le lieu qu'on est en train de modifier
      let snapModificationSource = new ol.interaction.Snap({
        source: this.activeLocationLayer.getSource(),
      });
      this.map.addInteraction(snapModificationSource);

      // sur les parcelles
      let snapCropfields = new ol.interaction.Snap({
        source: this.parcelsLayer.getSource(),
      });
      this.map.addInteraction(snapCropfields);

      this.activeLocationLayer.getSource().dispatchEvent("change");
      this.locationLayerSource.dispatchEvent("change");
    },

    removeDrawInteraction() {
      let t = this;
      this.map.getInteractions().forEach(function (interaction) {
        if (interaction instanceof ol.interaction.Draw) {
          t.map.removeInteraction(interaction);
        }
      });
      this.plotFeatureHoles();

    },

    // permet de nettoyer les coordonnées en retirant les doublons
    cleanCoords(e) {
      // une fonction auxiliaire qui permet de supprimer les doublons de sommets sur une ligne
      function removeVecticesDuplicates(coordinates) {
        var newCoordinatesRing = [];
        var j = 0;

        for (var i = 0; i < coordinates.length - 1; i++) {
          if (coordinates[i].join() != coordinates[i + 1].join()) {
            newCoordinatesRing[j] = coordinates[i];
            j++;
          }
          newCoordinatesRing[j] = coordinates[coordinates.length - 1];
        }

        return newCoordinatesRing;
      }

      let t = this;
      // on itère chaque feature modifiée
      e.features.forEach(function (f) {
        var coordinates = f.getGeometry().getCoordinates();
        var newCoordinates = [];

        // quel type ?
        let type = f.getGeometry().getType();

        if (type == "LineString") {
          newCoordinates = removeVecticesDuplicates(coordinates);
        } else if (type == "Polygon") {
          for (var k = 0; k < coordinates.length; k++) {
            newCoordinates[k] = removeVecticesDuplicates(coordinates[k]);
          }
        }

        // met à jour les coordonnées simplifiées
        f.getGeometry().setCoordinates(newCoordinates);
      });
    },

    // stop the modify/draw interaction
    async removeModifyInteraction({ save }) {
      this.drawMode = false;
      this.displayActiveLocation = true;

      // if we want to save it, save it
      if (save) {
        const geom = this.getGeometryFromLayer(this.activeLocationLayer);
        if (!geom) return;
        const geom4326 = project4326(geom);
        //geom4326 = turf.cleanCoords(geom4326);

        await this.updateLocEntry({
          id: this.currentLocation.id,
          data: {
            geometry: geom4326.geometry,
          },
        });
        await this.plotLocationFields();
        if (this.activeLocationLayer) {
          this.activeLocationLayer.getSource().clear();
          this.activeLocationLayer.getSource().dispatchEvent("change");
        }
      }
      // otherwise, cancel everything
      else {
        if (this.activeLocationLayer) {
          this.activeLocationLayer.getSource().clear();
          this.activeLocationLayer.getSource().dispatchEvent("change");
        }
      }
      let t = this;
      this.map.getInteractions().forEach(function (interaction) {
        if (interaction instanceof ol.interaction.Draw || interaction instanceof ol.interaction.Modify) {
          t.map.removeInteraction(interaction);
        }
      });

      this.locationLayerSource.dispatchEvent("change");
    },
  },
  
};
