import CropFieldsDetails from "./CropfieldsDetails.vue";
import CropFieldsMap from "./CropfieldsMap.vue";
import FertilizationPlan from "@/views/fertilization/FertilizationPlan.vue";

import CropfieldExport from "@/views/cropfields/import-export/CropfieldExport.vue";
import CropfieldImport from "@/views/cropfields/import-export/CropfieldImport.vue";
import CropfieldImportExportIndex from "@/views/cropfields/import-export/CropfieldImportExportIndex.vue";
import CropfieldImportFromSmag from "@/views/cropfields/import-export/CropfieldImportFromSmag.vue";
import CropfieldExportToXarvio from "@/views/cropfields/import-export/CropfieldExportToXarvio.vue";
import CropfieldExportAgroEDI from "@/views/cropfields/import-export/CropfieldExportAgroEDI.vue";
import CropfieldReport from "@/views/cropfields/reports/CropfieldReport.vue";

import CommonViewFiltering from "@/views/common/CommonViewFiltering.vue";
import CommonViewIndex from "@/views/common/CommonViewIndex.vue";
import CommonViewList from "@/views/common/CommonViewList.vue";

import FertilizerInputSummaryReport from "@/views/cropfields/reports/FertilizerInputSummaryReport.vue";
import CropRotationReport from "@/views/cropfields/reports/CropRotationReport.vue";
import PlainReport from "@/views/cropfields/reports/PlainReport.vue";
import IFTReport from "@/views/cropfields/reports/IFTReport.vue";

import { activityNew, activityNewEdit, } from "../activity/details/views";
import CommonViewDetail from "@/views/common/CommonViewDetail.vue";
import { storeCommit } from "@/views/common/details/commons";

import { config } from "./config";

const onBack = ({ router }) => router.go(-1);
const onBack2 = ({ router }) => router.go(-2);
const init = async ({ route }) => {
  // cropfield_id est défini lorsqu'on vient du détail d'une activité
  let cropfield_id = route.params.cropfield_id;
  storeCommit("activity/resetEditedEntry");
  storeCommit("activity/resetSelectedCropfieldId");
  if (cropfield_id) storeCommit("activity/setSelectedCropfieldId", cropfield_id);
};

export default [
  {
    component: CommonViewIndex,
    path: "/cropfields",
    props: config,
    children: [
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
        },
        path: "",
        name: "cropfield.list",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: CropFieldsDetails,
        },
        path: "new",
        name: "cropfield.new",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: CommonViewDetail,
        },
        path: ":cropfield_id/create-intervention",
        name: "cropfield.activity.new.edit",
        props: {
          sidebar: {
            view: activityNewEdit,
            onInit: init,
            onBack: onBack,
            goToDetail: onBack,
            goToList: onBack,
            isNewMode: () => true,
            isEditMode: () => null,
            showBackButton: () => true,
            storeReadGetter: "activity/currentEntry",
            storeEditedGetter: "activity/editedEntry",
            storeEditAction: "activity/setEditedEntryValue"
          },
        },
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: CommonViewDetail,
        },
        path: ":cropfield_id/create-intervention/:groupCode",
        name: "cropfield.activity.new",
        props: {
          sidebar: {
            view: activityNew,
            onInit: init,
            onBack: onBack,
            goToDetail: onBack2,
            goToList: onBack,
            isNewMode: () => true,
            isEditMode: () => null,
            showBackButton: () => true,
            storeReadGetter: "activity/currentEntry",
            storeEditedGetter: "activity/editedEntry",
            storeEditAction: "activity/setEditedEntryValue"
          },
        },
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: CropfieldReport,
        },
        path: "reports",
        name: "cropfield.reports",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: PlainReport,
        },
        path: "plain_report",
        name: "cropfield.reports.plain_report",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: FertilizerInputSummaryReport,
        },
        path: "reports-fertilizer-inputs-summary",
        name: "cropfield.reports.fertilizer_inputs_summary",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: CropRotationReport,
        },
        path: "reports-crop-rotation",
        name: "cropfield.reports.crop_rotation",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: IFTReport,
        },
        path: "reports-ift",
        name: "cropfield.reports.ift",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: CropfieldImportExportIndex,
        },
        path: "import-export",
        name: "cropfield.importexport",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: CropfieldImport,
        },
        path: "import",
        name: "cropfield.import",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: CropfieldImportFromSmag,
        },
        path: "import-from-smag",
        name: "cropfield.importfromsmag",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: CropfieldExportToXarvio,
        },
        path: "export-to-xarvio",
        name: "cropfield.exporttoxarvio",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: CropfieldExport,
        },
        path: "export",
        name: "cropfield.export",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: CropfieldExportAgroEDI,
        },
        path: "export-agro-edi",
        name: "cropfield.exporttoagroedi",
      },
      {
        components: {
          default: CropFieldsMap,
          filtering: CommonViewFiltering,
        },
        path: "map",
        name: "cropfield.map",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: CropFieldsDetails,
        },
        path: ":cropfield_id",
        name: "cropfield.details",
      },
      {
        components: {
          default: CropFieldsMap,
          filtering: CommonViewFiltering,
          sidebar: CropFieldsDetails,
        },
        path: "map/new",
        name: "cropfield.map.new",
      },
      {
        components: {
          default: CropFieldsMap,
          filtering: CommonViewFiltering,
          sidebar: CropFieldsDetails,
        },
        path: "map/:cropfield_id/:mode?/:activity_id?",
        name: "cropfield.map.details",
      },
      {
        components: {
          default: CropFieldsMap,
          filtering: CommonViewFiltering,
          sidebar: CropfieldReport,
        },
        path: "reports",
        name: "cropfield.map.reports",
      },
      {
        components: {
          default: CropFieldsMap,
          filtering: CommonViewFiltering,
          sidebar: CropfieldImportExportIndex,
        },
        path: "map/import-export",
        name: "cropfield.map.importexport",
      },
      {
        components: {
          default: CropFieldsMap,
          filtering: CommonViewFiltering,
          sidebar: CropfieldImport,
        },
        path: "map/import",
        name: "cropfield.map.import",
      },
      {
        components: {
          default: CropFieldsMap,
          filtering: CommonViewFiltering,
          sidebar: CropfieldImportFromSmag,
        },
        path: "map/import-from-smag",
        name: "cropfield.map.importfromsmag",
      },
      {
        components: {
          default: CropFieldsMap,
          filtering: CommonViewFiltering,
          sidebar: CropfieldExportToXarvio,
        },
        path: "map/export-to-xarvio",
        name: "cropfield.map.exporttoxarvio",
      },
      {
        components: {
          default: CropFieldsMap,
          filtering: CommonViewFiltering,
          sidebar: PlainReport,
        },
        path: "map/plain_report",
        name: "cropfield.map.reports.plain_report",
      },
      {
        components: {
          default: CropFieldsMap,
          filtering: CommonViewFiltering,
          sidebar: FertilizerInputSummaryReport,
        },
        path: "map/reports-fertilizer-inputs-summary",
        name: "cropfield.map.reports.fertilizer_inputs_summary",
      },
      {
        components: {
          default: CropFieldsMap,
          filtering: CommonViewFiltering,
          sidebar: CropRotationReport,
        },
        path: "map/reports-crop-rotation",
        name: "cropfield.map.reports.crop_rotation",
      },
      {
        components: {
          default: CropFieldsMap,
          filtering: CommonViewFiltering,
          sidebar: IFTReport,
        },
        path: "map/reports-ift",
        name: "cropfield.map.reports.ift",
      },
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
          sidebar: FertilizationPlan,
        },
        path: ":cropfield_id/fertilization",
        name: "cropfield.fertilization",
      },
    ],
  },
];
