import { commonGetters, commonMutations, commonActions } from "./commons.js";
import FertilizationPlanApi2 from "@/api/fertilizationPlan2.api";
import { config } from "@/views/fertilization/config"

export const FERTILISATION_LOADING = "FERTILISATION LOADING"

export const FERTILISATION_NEEDS_CALCULATION = "FERTILISATION NEEDS CALCULATION";
export const FERTILISATION_NEEDS_CALCULATION_FORM = "FERTILISATION NEEDS CALCULATION FORM";
export const FERTILISATION_NEEDS_CALCULATION_SA = "FERTILISATION NEEDS CALCULATION SA";
export const FERTILISATION_NEEDS_CALCULATION_TOOLTIP = "FERTILISATION NEEDS CALCULATION TOOLTIP";

export const FERTILIZATION_EDIT_MODE = "FERTILIZATION EDIT MODE";
export const FERTILIZATION_OTHER_CALCUL = "FERTILIZATION OTHER CALCUL";

export const GET_FERTILIZERS_SINCE_ASSESSMENT_OPENING_DATE = "GET FERTILIZERS SINCE ASSESSMENT OPENING DATE";

export default {
  namespaced: true,
  state: {
    sortFields: ["name"],
    sortOrders: ["asc"],
    _entries: {},
    page: 1,
    limit: 30,
    filterSelection: {},
    sortOrders: ["asc"],
    currentId: null,
    filterData: {},
    filters: [],
    currentEntry: {},
    id: 0,
    newEntry: {},
    entryUpdated: {},
    entryLoaded: true,
    searchQuery: "",
    entriesChecked: [],
    fertilisationNeedsCalculation: {},
    fertilisationNeedsCalculationForm: {},
    fertilisationNeedsCalculationSa: {},
    fertilisationNeedsCalculationToolTip: {},
    attributesChanged: [],
    errorAttributesChanged: [],
    editFertilisationCalcul: {},
    fertilisationLoading: false,
    editMode: false,
    fertilizersSinceAssessmentOpeningDate: [],
    showCheckbox: false
  },
  getters: {
    ...commonGetters(),
    getFertilisationNeedsCalculation: (state) => state.fertilisationNeedsCalculation,
    getFertilisationNeedsCalculationForm: (state) => state.fertilisationNeedsCalculationForm,
    getFertilisationNeedsCalculationSa: (state) => state.fertilisationNeedsCalculationSa,
    getFertilisationNeedsCalculationToolTip: (state) => state.fertilisationNeedsCalculationToolTip,
    editFertilisationCalcul: (state) => state.editFertilisationCalcul,
    editMode: (state) => state.editMode,
    fertilizersSinceAssessmentOpeningDate: (state) => state.fertilizersSinceAssessmentOpeningDate,
    getfertilisationLoading: (state) => state.fertilisationLoading,
    checkedEntries: (state, getters) => getters.entries.filter((e) => state.entriesChecked.includes(e.id)),
  },
  mutations: {
    ...commonMutations(),
    [FERTILISATION_NEEDS_CALCULATION]: (state, fertilisationNeedsCalculation) => {
        state.fertilisationNeedsCalculation = fertilisationNeedsCalculation;
    },
    [FERTILISATION_NEEDS_CALCULATION_FORM]: (state, fertilisationNeedsCalculationForm) => {
        state.fertilisationNeedsCalculationForm = fertilisationNeedsCalculationForm;
    },
    [FERTILISATION_NEEDS_CALCULATION_SA]: (state, fertilisationNeedsCalculationSa) => {
        state.fertilisationNeedsCalculationSa = fertilisationNeedsCalculationSa;
    },
    [FERTILISATION_NEEDS_CALCULATION_TOOLTIP]: (state, fertilisationNeedsCalculationToolTip) => {
        state.fertilisationNeedsCalculationToolTip = fertilisationNeedsCalculationToolTip;
    },
    [FERTILISATION_LOADING]: (state, status) => {
        state.fertilisationLoading = !status;
    },
    [FERTILIZATION_EDIT_MODE]: (state, mode) => {
        state.editMode = mode;
    },
    [GET_FERTILIZERS_SINCE_ASSESSMENT_OPENING_DATE]: (state, fertilizers) => {
        state.fertilizersSinceAssessmentOpeningDate = fertilizers;
      },
    setAttributeChanged: (state, data) => {
        state.attributesChanged.push(data);
        if(state.errorAttributesChanged.length > 0){
            state.attributesChanged.push(state.errorAttributesChanged[0]);
        } 
    },
    setFertilizationAttribute: (state) => {
        if (state.fertilisationNeedsCalculation.hasOwnProperty(state.attributesChanged[0].name)) {
            state.fertilisationNeedsCalculation[state.attributesChanged[0].name] = state.attributesChanged[0].value;
        }
        state.fertilisationNeedsCalculation.variable_changed = state.attributesChanged[0].name
    },
    deleteEntryOfAttributesChanged: (state) => {
        state.attributesChanged.shift()
    },
    gestionErrorAttributesChanged: (state) => {
        state.errorAttributesChanged.push(state.attributesChanged[0])
        state.attributesChanged.shift()

    },
    checkIfDouble: (state) => {
        let double = {};

        state.attributesChanged.forEach(e => {
            double[e.name] = e
        });

        let uniqueValue = Object.values(double);

        state.attributesChanged.length = 0

        uniqueValue.forEach(e => {
            state.attributesChanged.push(e)
        })
    },
    updateFertilisationNeedsCalcul: (state, data) => {
        state.fertilisationNeedsCalculation = data
    },
    setEditFertilisationCalculValue: (state, data) => {
        state.editFertilisationCalcul = { ...state.editFertilisationCalcul, ...data };
    },
    resetEditFertilisationCalcul: (state) => {
        state.editFertilisationCalcul = {};
    },
    setCurrentId: (state, data) => {
        state.id = data
    },
    setSave: (state) => {
        state.fertilisationNeedsCalculation.save_calculation = true
    },
    resetEntriesChecked: (state) => {
        state.entriesChecked = [];
    },
    removeCheckedEntry: (state, { id }) => {
        state.entriesChecked = [...state.entriesChecked.filter((e) => e.id != id)];
    },
  },
  actions: {
    ...commonActions(),
    async fetchEntries({ state, getters, commit, dispatch, rootGetters }) {
        let filters = await dispatch("getAllFiltersValues")

        const payload = {}

        // campaign & farms
        payload["campaign"] = rootGetters.getCampaign.id;
        if (rootGetters["farm/currentFarm"]) {
        payload["farm"] = rootGetters["farm/currentFarm"].id;
        }

        // get all the specific filters
        for(const filter of config.filters) {
            const value = await dispatch("getFilterValueId", filter.name)
            if(value != null)
            payload[filter.name] = value
        }

        const fertiPlan = await FertilizationPlanApi2.getAllFertilizationPlan(payload);
        commit("setEntries", { entries: fertiPlan.data })
    },
    async getFilter({ commit, rootGetters, state }, { attrib, params, values }) {
        if(values) {
            // if there is hard setted values in config, use it
            commit("setFilterData", { name: attrib, elements: values });
            return values;
        } else {
        
            const copyParams = { ...params }
            if (copyParams.hasOwnProperty(attrib)) {
            delete copyParams[attrib];
            }

            const response = await FertilizationPlanApi2.getFilters(attrib, copyParams);
            commit("setFilterData", { name: attrib, elements: response.data });
        }
    },

    async updateFilterSelection({ commit, dispatch, state, rootGetters }, { filterName, elementId }) {
        commit("setFilter", {
            filterName: filterName,
            elementId: elementId,
        });

        const filters = await dispatch("getAllFiltersValues")

        state.filters.forEach(async (filter) => {
            await dispatch("getFilter", {
            attrib: filter.name,
            params: {...filters, campaign: rootGetters.getCampaign.id},
            values: ""
            });
        });
    },
    async initFilters({ state, getters, rootGetters, dispatch, commit }) {
        let currentFarm = rootGetters["farm/currentFarm"];
        if (currentFarm) currentFarm = currentFarm.id;

        const filters = await dispatch("getAllFiltersValues")

        // for each filter in config
        for(const filter of config.filters) {
          // get the filter data
          await dispatch("getFilter", {
            attrib: filter.name,
            params: {
              ... filters,
              campaign: rootGetters.getCampaign.id,
              farm: currentFarm
            },
            values: filter.values
          });
        }
    },
    async addAtributeChange({commit, dispatch}, payload){
        //ajoute le payload(object{name: "", value: any})dans la liste attributesChanged
        await commit("setAttributeChanged", {name: payload.name, value: payload.value})
        //verifie si un appel api est deja en cours, si pas d'appel en cours alors appel la méthode update, si non rien
        if(FERTILISATION_LOADING == true){
            return;
        } else {
            dispatch("updateFertiCalcul")
        }
    },
    async updateFertiCalcul({commit, state, dispatch}){
        commit(FERTILISATION_LOADING, true);
        // Lit la liste attributesChanged, lit le formulaire stocké dans le store editFertilisationCalcul
        // avec la liste unique des noms d'attributs modifiés (pas besoin des valeurs, retrait des doublons)
        if (state.attributesChanged.length > 0) {
            commit("checkIfDouble")
            commit("setFertilizationAttribute")
        }

        // Appel de l'api
        let response;

        try {
            response = await FertilizationPlanApi2.updatefertilizationPlan(state.id, state.fertilisationNeedsCalculation)
        } catch (error) {
            // gère l'es erreur de l'api code retour >= 400
            console.log("error Api", error)
            await commit("gestionErrorAttributesChanged")
            return;
        }
        // si pas d'erreur retire les element de la pile a la liste attributesChanged
        if (state.attributesChanged.length > 0 && response) {
           commit("deleteEntryOfAttributesChanged")
        }

        commit(FERTILISATION_LOADING, false);


        if(state.attributesChanged.length > 0){
            //boucle For de attributesChanged sur le response.data
            state.attributesChanged.forEach(e => {
                // modifie le résulat de l'api avec les valeurs de attributesChanged dans l'ordre d'insertion
               if (response.data.hasOwnProperty(e.name)){
                    response.data[e.name] = e.value
                }
                dispatch("updateFertiCalcul")
            })

        }
        // Stocker dans le store le résulat
        commit("updateFertilisationNeedsCalcul", response.data)
    },
    async save({commit, state}){
        commit(FERTILISATION_LOADING, true);
        commit('setSave')
        let response = {}

        try {
             response = await FertilizationPlanApi2.updatefertilizationPlan(state.id, state.fertilisationNeedsCalculation)
        } catch (error) {
            console.log("erreur save")
            // gère l'es erreur de l'api code retour >= 400
            console.log("error Api", error)
        }
        commit("updateEntry", { id: state.id, data: response.data });
        commit(FERTILISATION_LOADING, false);
    },
    async getfertilizationPlan({ commit }, payload) {
        const id = payload.id
        commit(FERTILISATION_LOADING, true);
        const response = await FertilizationPlanApi2.getfertilizationPlan(payload.id);
        commit("setCurrentId", id);
        commit("updateEntry", { id: id, data: response.data });
        commit(FERTILISATION_NEEDS_CALCULATION, response.data);
        commit(FERTILISATION_LOADING, false);
        return response.data;
    },
    async getfertilizationPlanList({ commit }, payload) {
        commit(FERTILISATION_LOADING, true);
        const response = await FertilizationPlanApi2.getfertilizationPlanList(payload.id);
        commit(FERTILISATION_NEEDS_CALCULATION_FORM, response.data);
        commit(FERTILISATION_LOADING, false);
        return response.data;
    },
    async getfertilizationPlanOptions({ commit }, payload) {
        commit(FERTILISATION_LOADING, true);
        const response = await FertilizationPlanApi2.getfertilizationPlanOptions(payload.id);
        commit(FERTILISATION_NEEDS_CALCULATION_SA, response.data);
        commit(FERTILISATION_LOADING, false);
        return response.data;
    },
    async getfertilizationPlanToolTip({ commit }) {
        commit(FERTILISATION_LOADING, true);
        let helptexts
        const response = await FertilizationPlanApi2.getHelpText()

        helptexts = response.data.reduce((acc, item) => {
            acc[item.parameter] = item.text;
            return acc;
        }, {});
          
        commit(FERTILISATION_NEEDS_CALCULATION_TOOLTIP, helptexts);
        commit(FERTILISATION_LOADING, false);
        return helptexts;
    },
    async duplicateFertilizationPlan({commit, dispatch}, payload){
        commit(FERTILISATION_LOADING, true);
        await FertilizationPlanApi2.duplicateFertilizationPlan(payload.id, payload.target)
        commit(FERTILISATION_LOADING, false);
        for (let i = 0; i < payload.target.targetPlans.length; i++) {
            dispatch("getfertilizationPlan", {id: payload.target.targetPlans[i]})
        }
        commit("resetEntriesChecked")
    },
    async getDuplicableFertilizationPlan({ commit, rootGetters }, payload) {
        payload["campaign"] = rootGetters.getCampaign.id;
        payload["status"] = 3;
        if (rootGetters["farm/currentFarm"]) {
        payload["farm"] = rootGetters["farm/currentFarm"].id;
        }
        commit(FERTILISATION_LOADING, true);
        const response = await FertilizationPlanApi2.getDuplicableFertilizationPlan(payload);
        commit(FERTILISATION_LOADING, false);
        return response.data;
    },
    async getFertilizersSinceAssessmentOpeningDate({commit}, {id}) {
        let response = await FertilizationPlanApi2.getFertilizersSinceAssessmentOpeningDate(id);
        commit(GET_FERTILIZERS_SINCE_ASSESSMENT_OPENING_DATE, response.data);
        return response.data;
    },
    async setEntriesChecked({ state, commit, dispatch, getters }, { entries }) {
        await commit("setEntriesChecked", { entries: entries });
    },
    async updateCheckedEntries({ state, dispatch, commit }) {
        if (!state.currentId && state.entriesChecked.length == 0) {
          await dispatch("createEntry", { entry: state.editedEntry });
          dispatch("fetchEntries");
          commit("resetEditedEntry");

          return;
        }

        let entriesToUpdate = removeDuplicates([state.currentId, ...state.entriesChecked], { ignoreType: true });

        await entriesToUpdate.forEach(async (id) => {
          await dispatch("updateEntry", { id: id, data: state.editedEntry });
        });

        commit("resetEditedEntry");
    },
    async recalculFertilizationPlan({state, dispatch, commit}, payload) {
        commit(FERTILISATION_LOADING, true);
        await payload.ferti_plan.forEach(async (id) => {
            const response = await FertilizationPlanApi2.recalculateFertilizationPlan(id)
            commit("updateEntry", { id: id, data: response.data });
            if(payload.ferti_plan.length === 1 ){
                commit(FERTILISATION_NEEDS_CALCULATION, response.data);
            }
        })
        commit(FERTILISATION_LOADING, false);
    },
  },
};
