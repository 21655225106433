import ActivityFamiliesApi from "@/api/activityFamilies.api";
import { commonMutations } from "./commons.js";

export const ALL_ACTIVITYFAMILIES = "GET ALL ACTIVITYFAMILIES";
export const ONE_ACTIVITYFAMILY = "GET ONE ACTIVITYFAMILY";
export const ALL_ACTIVITYTYPEGROUPS = "GET ALL ACTIVITYTYPEGROUPS";
export const ACTIVITYFAMILY_LOADING = "LOAD ACTIVITYFAMILY";

export default {
  state: {
    activityFamilies: [],
    currentActivityFamily: {},
    activityFamily_loaded: true,
    activityTypeGroups: [],
  },
  getters: {
    currentActivityFamily: state => state.currentActivityFamily,
    activityFamilies: state => state.activityFamilies,
    activityFamilyLoaded: state => state.activityFamily_loaded,
    activityTypeGroups: state => state.activityTypeGroups,
  },
  mutations: {
    ...commonMutations,
    [ALL_ACTIVITYFAMILIES]: (state, activityFamilies) => {
      state.activityFamilies = activityFamilies;
    },

    [ONE_ACTIVITYFAMILY]: (state, activityFamily) => {
      state.currentActivityFamily = activityFamily;
    },

    [ALL_ACTIVITYTYPEGROUPS]: (state, activityTypeGroups) => {
        state.activityTypeGroups = activityTypeGroups;
    },   

    [ACTIVITYFAMILY_LOADING]: (state, status) => {
      state.activityFamily_loaded = !status;
    },
  },
  actions: {
    async getActivityFamilies({ commit, state }) {
      commit(ACTIVITYFAMILY_LOADING, true);
      if (state.activityFamilies.length != 0)
        return state.activityFamilies;
      const response = await ActivityFamiliesApi.getActivityFamilies();
      commit(ALL_ACTIVITYFAMILIES, response.data);
      commit(ACTIVITYFAMILY_LOADING, false);
      return response.data;
    },
    async getOneActivityFamily({ commit }, payload) {
      commit(ACTIVITYFAMILY_LOADING, true);
      const response = await ActivityFamiliesApi.getOneActivityFamily(payload.id);
      commit(ONE_ACTIVITYFAMILY, response.data);
      commit(ACTIVITYFAMILY_LOADING, false);
      return response.data;
    },
    async getAllActivityTypeGroups({ commit }) {
      commit(ACTIVITYFAMILY_LOADING, true);
      const response = await ActivityFamiliesApi.getActivityTypeGroups();
      commit(ALL_ACTIVITYTYPEGROUPS, response.data);
      commit(ACTIVITYFAMILY_LOADING, false);
      return response.data;
    },
  },
};
