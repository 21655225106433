<template>
  <div>
    <Treatment
      taskName="export_agro_edi"
      :async="true"
      :title="$t('cropfields.import_export.export_agro_edi')"
      :startButton="$t('commons.exports.export')"
      :finishButton="$t('commons.exports.finish')"
      :downloadButton="$t('commons.exports_reports.download_export')"
      :failMessage="$t('cropfields.import_export.export_cropfields_fail')"
      :onBack="onBack"
      :onClose="onClose"
    >
      <TC_Farm v-if="!areEntriesChecked" class="mb-3"/>
      <TC_TargetSoftware_Agroedi/>
      <TC_TextField
        :fieldTitle="$t('commons.exports_reports.receiver_name')"
        fieldName="receiver_name"
        :storeCookie="true"
      />
      <TC_TextField
        :fieldTitle="$t('commons.exports_reports.receiver_siret')"
        fieldName="receiver_siret"
        :storeCookie="true"
      />
      <TC_HistoryAgroedi class="mt-3"/>
      <TC_ActivityAgroedi class="mt-3"/>
      <TC_CropfieldGeometry class="mt-3"/>
      <TC_Campaign_NoTemplate />
      <TC_CheckedCropfields_NoTemplate />
      <TC_ClientId_NoTemplate />
    </Treatment>
  </div>
</template>

<script>
import Treatment from "@/components/commons/treatments/TreatmentExecution";
import TC_Campaign_NoTemplate from "@/components/commons/treatments/TC_Campaign_NoTemplate";
import TC_CheckedCropfields_NoTemplate from "@/components/commons/treatments/TC_CheckedCropfields_NoTemplate";
import TC_HistoryAgroedi from "@/components/commons/treatments/TC_HistoryAgroedi";
import TC_ActivityAgroedi from "@/components/commons/treatments/TC_ActivityAgroedi";
import TC_CropfieldGeometry from "@/components/commons/treatments/TC_CropfieldGeometry";
import TC_ClientId_NoTemplate from "@/components/commons/treatments/TC_ClientId_NoTemplate";
import TC_Farm from "@/components/commons/treatments/TC_Farm";
import TC_TextField from "@/components/commons/treatments/TC_TextField";
import TC_TargetSoftware_Agroedi from "@/components/commons/treatments/TC_TargetSoftware_Agroedi";

import CropFieldApi from "@/api/cropfield.api";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      CropFieldApi: CropFieldApi,
    };
  },
  methods: {
    onBack() {
      this.$router.push({ name: "cropfield.importexport" });
    },
    onClose() {
      this.$router.push({ name: "cropfield.importexport" });
    },
  },
  computed: {
    ...mapGetters({
      entriesChecked: "cropfield/entriesChecked",
    }),
    areEntriesChecked() {
      return this.entriesChecked.length > 0;
    },
  },
  components: {
    Treatment,
    TC_Campaign_NoTemplate,
    TC_CheckedCropfields_NoTemplate,
    TC_HistoryAgroedi,
    TC_ActivityAgroedi,
    TC_CropfieldGeometry,
    TC_ClientId_NoTemplate,
    TC_Farm,
    TC_TextField,
    TC_TargetSoftware_Agroedi
  },
};
</script>
