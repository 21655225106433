<template>
  <button class="btn align-bottom" @click="onClick" type="button">
    <img v-if="iconSrc" :src="iconSrc" alt="icon more action" />
    {{ text }}
  </button>
</template>

<script>
import plusIcon from "@/assets/images/plus-sign-to-add.svg";

const _ICONS = {
  plusIcon: plusIcon,
};
export default {
  name: "Button",
  computed: {
    iconSrc() {
      if (this.icon in _ICONS) return _ICONS[this.icon];
    },
  },
  props: {
    text: {
      type: String,
      require: true,
    },
    icon: {
      type: String,
      require: true,
    },
    onClick: {
      type: Function,
      require: true,
    },
  },
};
</script>
<style lang="scss" scoped>
button {
  background-color: $reverse-color;
  font-size: 0.8em;
  font-weight: bold;

  img {
    width: 0.8em;
    margin-right: 10px;
  }
}
</style>
