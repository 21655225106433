import CropApi from "@/api/crop.api";
import { i18n } from "@/i18n.js";

export default {
  namespaced: true,
  state: {
    // commons
    crops: [],
  },
  getters: {
    crops: state => state.crops,
    cropsByExisting: state =>
      state.crops.length == 0
        ? []
        : [
            { group: i18n.t("crops.existing.true"), name: null },
            ...state.crops.filter(c => c.existing),
            { group: i18n.t("crops.existing.false"), name: null },
            ...state.crops.filter(c => !c.existing),
          ],
  },
  mutations: {
    setCrops: (state, crops) => {
      state.crops = crops;
    },
  },
  actions: {
    async fetchCrops({ commit, state }) {
      let payload = {
        all: true,
      };
      if (state.crops.length == 0) {
        const response = await CropApi.getCrops(payload);

        // sort by names
        const data = response.data.sort(function(a, b) {
          return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
        });

        commit("setCrops", data);
      }
    },
  },
};
