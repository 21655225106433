<template>
  <div>
		<b-form-group class="mb-0">
			<!-- Case à cocher pour les types de ZNT -->
			<b-form-checkbox
				v-for="type in nonTreatedZoneTypes"
				v-model="types"
				:key="type.code"
				:value="type.code"
				@input="$emit('updateRow', rowId, payload)"
			>
				<b-row>
					<!-- Label du type de ZNT -->
					<b-col cols="12">
						<span class="text-infos whitout-bg mt-1"> {{ type.designation}} </span>
					</b-col>
					<!-- Réductible ? -->
					<b-col cols="4" v-show="types.includes(type.code)">
						<b-checkbox v-model="reducible[type.code]" @input="updateReducible">
							<span class="text-infos whitout-bg mt-1"> {{ $t("cropfields.list.reducible")}} </span>
						</b-checkbox>
					</b-col>
				</b-row>
				<div v-if="types.includes(type.code)" class="text-infos bg-white mb-2 pb-0 pl-0">
					<b-row v-for="width in widths[type.code]" :key="width.id" class="mx-0">
						<!-- Label de la largeur de la ZNT -->
						<b-col cols="6" class="mt-1 pr-0">
							{{ $t("cropfields.list.znt_to")}} {{ width.width }} {{ $t("unitDenomination.unit_m") }}
						</b-col>
						<!-- Input de saisie de la surface de la ZNT -->
						<b-col cols="5" class="pb-1">
							<NumericInput
								id="quantity"
								inputRef="input"
								:value="getSurface(type.code, width.id)"
								@input="surface => {setSurface(type.code, width.id, surface)}"
								:numberOfDigits="4"
								class="mb-1 text-right"
							/>
						</b-col>
						<!-- Unité de la ZNT -->
						<b-col cols="1" class="mt-1 pl-0">
							{{ $t("unitDenomination.hectare") }}
						</b-col>
					</b-row>
				</div>
			</b-form-checkbox>
		</b-form-group>

	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import NumericInput from "@/components/commons/NumericInput.vue";

export default {
  props: ["rowId", "field", "cellData", "disabled"],
  data() {
    return {
      types: [],
			widths: {},
			surfaces: {},
			nonTreatedZonesToCreate: [],
			reducible: {},
			typeRelationId: {},
			typesToPatch: [],
    };
  },
  async created() {
		// On récupère tous les types de ZNT
    if(this.nonTreatedZoneTypes.length == 0) await this.getNonTreatedZoneTypes();
		let widthsArray = [];
		for (let i=0; i < this.nonTreatedZoneTypes.length; i++) {
			// On récupère toutes les largeurs de ZNT et on les classe par rapport au type
			widthsArray = await this.getNonTreatedZoneWidths({typeCode: this.nonTreatedZoneTypes[i].code});
			this.widths[this.nonTreatedZoneTypes[i].code] = widthsArray;
			// On prépare la structure pour stocker les surfaces
			this.surfaces[this.nonTreatedZoneTypes[i].code] = []
		}

		// Si on est en modification, on remplit la structure avec les données enregistrées dans le store
		if (this.rowId) {
			await this.getNonTreatedZones({cropField: this.rowId});
			this.currentEntry.nonTreatedZoneTypes.forEach(el => {
				this.types.push(el.nonTreatedZoneType);
				this.reducible[el.nonTreatedZoneType] = el.canBeReduced;
				this.typeRelationId[el.nonTreatedZoneType] = el.id;
			});
			this.nonTreatedZones.forEach(znt => {
				if (!this.types.includes(znt.type_code)) {
					this.types.push(znt.type_code);
				}
				this.surfaces[znt.type_code].push({nonTreatedZoneWidth: znt.nonTreatedZoneWidth, surface: znt.surface});
			});
		}
  },
  computed: {
    ...mapGetters({
			nonTreatedZoneTypes: "nonTreatedZoneTypes",
			nonTreatedZones: "nonTreatedZones",
			currentEntry: "cropfield/currentEntry",
			}),
    payload() {
			this.typesToPatch = [];
			this.types.forEach(el => {
				this.typesToPatch.push({id: this.typeRelationId[el], nonTreatedZoneType: el, canBeReduced: this.reducible[el] == true});
			});
      let payload = { nonTreatedZoneTypes: this.typesToPatch, nonTreatedZones: this.nonTreatedZonesToCreate };
      return payload;
    },
  },
  methods: {
    ...mapActions({
			getNonTreatedZoneTypes: "getNonTreatedZoneTypes",
			getNonTreatedZoneWidths: "getNonTreatedZoneWidths",
			getNonTreatedZones: "getNonTreatedZones"
		}),

		/**
		 * Met à jour la structure des surfaces des ZNT
		 */
		setSurface(typeCode, widthId, surface) {
			this.surfaces[typeCode] = this.surfaces[typeCode].filter(el => el.nonTreatedZoneWidth != widthId);
			this.surfaces[typeCode].push({nonTreatedZoneWidth: widthId, surface: surface});

			// Prépare la liste des ZNT à stocker dans le store
			// Elle sera plus tard poussée au backend
			this.nonTreatedZonesToCreate = [];
			this.types.forEach(type => {
				this.nonTreatedZonesToCreate = [...this.nonTreatedZonesToCreate, ...this.surfaces[type]];
			})
			this.nonTreatedZonesToCreate = this.nonTreatedZonesToCreate.filter(znt => znt.surface != "");

			this.$emit('updateRow', this.rowId, this.payload);
		},
		getSurface(typeCode, widthId) {
			return this.surfaces[typeCode].find(el => el.nonTreatedZoneWidth == widthId)?.surface;
		},
		updateReducible() {
			this.typesToPatch = [];
			this.$emit('updateRow', this.rowId, this.payload);
		}
  },
  components: {
	NumericInput
  }
};
</script>

<style lang="scss" scoped>
</style>
