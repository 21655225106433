import WarehouseApi from "@/api/warehouse.api";

export const ALL_WAREHOUSES = "GET ALL WAREHOUSES";
export const ONE_WAREHOUSE = "GET WAREHOUSE BY ID";
export const CREATE_WAREHOUSE = "CREATE WAREHOUSE";
export const UPDATE_WAREHOUSE = "UPDATE WAREHOUSE";
export const DELETE_WAREHOUSE = "DELETE WAREHOUSE";
export const WAREHOUSE_LOADING = "LOAD WAREHOUSE";

export default {
  state: {
    warehouses: [],
    currentWarehouse: {},
    newWarehouse: {},
    warehouseUpdated: {},
    warehouse_loaded: true,
  },
  getters: {
    warehouses: state => state.warehouses,
    currentWarehouse: state => state.currentWarehouse,
  },
  mutations: {
    [ALL_WAREHOUSES]: (state, warehouses) => {
      state.warehouses = warehouses;
    },

    [ONE_WAREHOUSE]: (state, payload) => {
      state.currentWarehouse = payload.warehouse;
    },

    [CREATE_WAREHOUSE]: (state, payload) => {
      state.newWarehouse = payload.warehouse;
    },

    [UPDATE_WAREHOUSE]: (state, payload) => {
      state.warehouseUpdated = payload.warehouse;
    },

    // [DELETE_WAREHOUSE]: (state, payload) => {
    // },

    [WAREHOUSE_LOADING]: (state, status) => {
      state.warehouse_loaded = !status;
    },
  },
  actions: {
    async getAllWarehouses({ commit, state }) {
      if (state.warehouses.length > 0) return;
      commit(WAREHOUSE_LOADING, true);
      const response = await WarehouseApi.getAllWarehouses();
      commit(ALL_WAREHOUSES, response.data);
      commit(WAREHOUSE_LOADING, false);
      return response.data;
    },

    async getWarehouse({ commit }, payload) {
      commit(WAREHOUSE_LOADING, true);
      const response = await WarehouseApi.getWarehouse(payload.id);
      commit(ONE_WAREHOUSE, { warehouse: response.data });
      commit(WAREHOUSE_LOADING, false);
      return response.data;
    },

    async createWarehouse({ commit }, payload) {
      commit(WAREHOUSE_LOADING, true);
      const response = await WarehouseApi.createWarehouse(payload.data);
      commit(CREATE_WAREHOUSE, { warehouse: response.data });
      commit(WAREHOUSE_LOADING, false);
      return response.data;
    },

    async updateWarehouse({ commit }, payload) {
      commit(WAREHOUSE_LOADING, true);
      const response = await WarehouseApi.updateWarehouse(payload.data, payload.id);
      commit(UPDATE_WAREHOUSE, { warehouse: response.data });
      commit(WAREHOUSE_LOADING, false);
      return response.data;
    },

    async deleteWarehouse({ commit }, payload) {
      commit(WAREHOUSE_LOADING, true);
      const response = await WarehouseApi.deleteWarehouse(payload.id);
      commit(WAREHOUSE_LOADING, false);
      return response.data;
    },
  },
};
