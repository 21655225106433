<template>
  <div  class="checkbox-container" @click="handleCheckbox">
    <b-form-checkbox
      v-model="checkbox"
      @change="handleCheckbox"
      switch />
  {{ $t("multiple") }}
  </div>
</template>

<script>
  import { EventBus } from "@/eventBus.js";
    export default {
        data() {
          return {
              checkbox: false
          };
        },
        created(){
          this.checkbox = this.setShowCheckbox();
        },
        methods: {
          handleCheckbox(){
            this.checkbox = !this.checkbox
            EventBus.$emit('checkboxChange')
            this.$store.commit(this.module + "/setShowCheckbox", this.checkbox);
          },
          setShowCheckbox(){
            return this.$store.getters[this.module + "/showCheckbox"];
          }
        },
        props: {
          module: {
            type: String,
            default: () => {},
          },
        }
    };
</script>


<style lang="scss" scoped>
  .checkbox-container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }



</style>
