<template>
    <div>
      <!-- Edit -->
      <div class="details-buttons">
        <div>
          <reloadButton :on-click="onRelaod" v-if="(!neverInitialized || severalFertilizationPlan) && !this.editMode"/>
        </div>
        <div>
          <editButton class="ml-3" :on-click="onEdit" v-if="((!this.editMode && !this.neverInitialized) || (!this.neverInitialized && !entriesCheckedTrue && !editMode)) && !entriesCheckedTrue"/>
        </div>
          <closeButton class="ml-3" :on-click="onClose" v-if="!this.editMode"/>
          <backButton class="ml-3" :on-click="onClose" v-else/>
      </div>

    <div>

        <!--Titre-->
        <b-container class="p-0">
          <b-row no-gutters>
            <b-col cols="9" class="pr-3">
              <h3 class="title-form">
                <span> Plan de Fumure</span>
              </h3>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="9" class="pr-3">
              <h3 class="title-form">
                <span> {{name}}</span>
              </h3>
            </b-col>
          </b-row>
        </b-container>

        <FertilizationPlan v-if="this.fertilization_id && this.showPlan"
                :fertilization_id="this.fertilization_id"
                :fertilizationPlanChecked="entriesChecked"

                class="mt-4"
        />

        <DialogModal />
      </div>
    </div>
  </template>


  <script>
  import editButton from "@/components/commons/EditButton.vue";
  import closeButton from "@/components/commons/CloseButton.vue";
  import reloadButton from "@/components/commons/ReloadButton.vue"
  import DialogModal from "@/components/commons/DialogModal.vue";

  import backButton from "@/components/commons/BackButton.vue";
  import { FERTILIZATION_EDIT_MODE } from "@/store/modules/fertilization";

  import FertilizationPlan from "@/views/fertilization/FertilizationPlan";

  import { mapGetters, mapActions, mapMutations } from "vuex";

  import { EventBus } from "@/eventBus.js";

  import { askConfirm } from "@/utils/commons";
import ReloadButton from "../../components/commons/ReloadButton.vue";


  export default {
    data() {
      return {
        newMode: false,
        selectedItem: null,
        detailsEditMode: false,
        fertilizationSelected: false,
        detailsSelected: true,
        currentEntryLoaded: false,
        fertilization_id: 0,
        neverInitialized: false,
        name: "",
        showPlan: false,
      };
    },
    created: function () {
      this.fertilization_id = parseInt(this.$route.params.fertilization_id)

      this.init(this.$route.params.fertilization_id);
    },
    mounted() {
    EventBus.$on("onBackInit", this.displayBack);
  },
  beforeDestroy(){
    EventBus.$off("onBackInit", this.displayBack);
  },
    methods: {
      ...mapActions({
      }),
      ...mapMutations({
        resetCurrentId: "fertilization/resetCurrentId",
      }),
      async init(fertilization_id) {
        EventBus.$on("setEditMode", this.onEdit);
        this.currentEntryLoaded = false;
        this.$store.commit("fertilization/" + FERTILIZATION_EDIT_MODE, false);
        const f = await this.$store.dispatch("fertilization/getfertilizationPlan", { id: fertilization_id });

        this.name = f.name

        if(this.$route.params.mode == "editMode") {
          this.detailsEditMode = true;
        }

        if (!fertilization_id) {
          this.$store.commit("fertilization/resetEntriesChecked");
          this.$store.dispatch("fertilization/resetCurrentId");
          await this.$store.dispatch("fertilization/setDetailLoadTime", null);
          this.detailsEditMode = true;
          this.newMode = true;
          return;
        }

        if(f.status == 0){
          this.neverInitialized = true
        }

        this.currentEntryLoaded = true;
        this.showPlan = true
      },
      onEdit() {
          this.$store.commit("fertilization/" + FERTILIZATION_EDIT_MODE, true);
          //On transmet l'appuie sur le bouton edit pour déclencher le chagement des données dans le composant fertilizationPlan
          EventBus.$emit("loadFertilizationData");
        if(this.detailsSelected) this.detailsEditMode = true;
      },
      async onClose() {
        if(!this.detailsEditMode){
        this.$router.push({ name: "fertilization.list" });
        this.$store.commit("fertilization/resetEntriesChecked");
        this.$store.dispatch("fertilization/resetCurrentId");
       }

        if (this.newMode || this.detailsEditMode) {
          if (await askConfirm(this.$t("save_modifications"))) await this.updateRow();
          this.detailsEditMode = false;
          this.newMode = false;
          this.$store.commit("fertilization/" + FERTILIZATION_EDIT_MODE, false);
        }

        if(this.detailsEditMode){
          await this.$store.dispatch("fertilization/getfertilizationPlan", { id: this.fertilization_id })
          this.$store.commit("fertilization/" + FERTILIZATION_EDIT_MODE, false);
        }

        await this.$store.dispatch("fertilization/getfertilizationPlan", { id: this.fertilization_id });
      },
      async updateRow() {
        await this.$store.dispatch("fertilization/updateCheckedEntries");
        EventBus.$emit("saveFertilizationPlan");
        EventBus.$emit("saveFertilizationIntervention");
      },
      async editCheck(){
        this.detailsEditMode = this.editMode
      },
      onRelaod(){
        EventBus.$emit("showAlertModal", {
          title: this.$t("warning"),
          message: this.$t('cropfields.fertilization.recalculate.modal_text'),
          okBtn: () => this.onRealodOk(),
          cancelBtn: () => {return},
          okTitle: "ok",
          cancelTitle: "no",
        });
      },
      async onRealodOk(){
        let fertilization_ids

        if(this.severalFertilizationPlan){
          fertilization_ids = this.entriesChecked
        } else {
          fertilization_ids = [this.fertilization_id]
        }

        await this.$store.dispatch("fertilization/recalculFertilizationPlan", {ferti_plan: fertilization_ids})
      },
    },
    components: {
      editButton,
      backButton,
      closeButton,
      FertilizationPlan,
      DialogModal,
      reloadButton,
    },
    computed: {
      ...mapGetters({
        currentId: "fertilization/currentId",
        currentEntry: "fertilization/currentEntry",
        entriesChecked: "fertilization/entriesChecked",
        ferti: "fertilization/getFertilisationNeedsCalculation",
        editMode: "fertilization/editMode"
      }),
      severalFertilizationPlan() {
        return this.entriesChecked.length > 1;
      },
      entriesCheckedTrue() {
      return this.entriesChecked.length >= 1;
    },

    },
    props: {
      details: {
        type: Object,
        default: () => {},
      },
    },
    watch: {
      $route() {
      },
      //permet de de gérer l'affichage du bouton edit
      selectedItem() {
        if(this.selectedItem == 0) {
          this.detailsSelected = true;
          this.fertilizationSelected = false;
        }
        else if (this.selectedItem == 3) {
          this.fertilizationSelected = true;
          this.detailsSelected = false;
        } else {
          this.detailsSelected = false;
          this.fertilizationSelected = false;
        }
      },
      editMode: {
        handler: 'editCheck',
        immediate: true
      },
    },
  };
  </script>

  <style lang="scss" scoped>
  /**
   * Edit Button position.
   */
  .position-edit-button {
    text-align: center;
    position: relative;
    top: 35px;
    left: 20px;
  }

  a {
    color: $subtitle-color;
    text-decoration: underline;
  }
  a:hover {
    color: $primary-color;
  }

  /**
   * Params button save input.
   */
  .button-save-input {
    background-color: $background-text-color;
    width: 100%;
  }

  .button-save-input:hover {
    background: $secondary-light-color;
  }

</style>
