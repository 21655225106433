export const mapStyles = {
  // these are the different possible states for the cropfields
  normal: {
    // default style
    alpha: "BF",
    cropNameZoomLevel: 16,
    islandBackgroundColor: "#0000007F",
    islandBorderColor: "#000000FF",
    varietyBackgroundColor: "#FFFFFF7F",
    varietyBorderColor: "#FFFFFF",
    activeVarietyBackgroundColor: "#FFFF007F",
    activeVarietyBorderColor: "#FFFF00",
    edgeColor: "#2c3f61",
    holeEdgeColor: "#e35214",
    holeBackgroundColor: "#ffffff00",
    holeHighlightBackgroundColor: "#ff00005a",
    // vector rpg
    rpgBackgroundColor: "#0000004D",
    rpgEdgeColor: "#000000FF",
    rpgEdgeSize: 2,
    rpgMinimumZoomLevel: 14,
    courseWaterMinimumZoomLevel: 10,
  },
  active: {
    // when we click on a cropfield
    backgroundColor: "#ffffff8f",
    edgeColor: "#FF0000",
  },
  minimal: {
    // minimal style, on path modal
    backgroundColor: "#ffffffCC",
    edgeColor: "#037ffc",
  },
  edit: {
    vertexColor: "#00000080",
  },
  cut: {
    backgroundColor: "#0000005a",
    edgeColor: "#000000FF",
    // cut mode : "découpe en bordure"
    borderCut: {
      vertexColor: "#FFF700FF",
      edgeColor: "#FFF700FF",
      possiblePaths: ["yellow", "brown"], // need two colors here
    },
    // cut mode : "découpe en ligne"
    lineCut: {
      color: "#00000080",
    },
  },
  // popover : la bulle qui est parfois affichée
  // par exemple sur la distance de découpe
  helpers: {
    color: "#00000080",
  },
  // modification de la géométrie
  editGeometry: {
    backgroundColor: "#fff7e34f",
    edgeColor: "#FF0000",
    vertexColor: "#FF0000",
    hole: {
      backgroundColor: "#0000005a",
    },
  },
  hover: {
    // vector rpg
    rpgBackgroundColor: "#00000080",
    rpgEdgeColor: "#000000FF",
    rpgEdgeSize: 4,
  },
  waterCourse: {
    color: "#05CEFA"
  },
  toolBox: {
    edgeColor: "#FFFFFFFF",
    pointStrokeColor: "#0000007F",
    textStrokeColor: "#000000FF",
    backgroundColor: "#FFFFFF7F"
  }
};
