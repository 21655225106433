import { i18n } from "@/i18n.js";

//import { FAMILY_CREATE_PRODUCT } from "./config";
import { t, comp, choice, container, storeGet, storeDispatch, storeCommit } from "@/views/common/details/commons";

const storeEditAction = "activity/setEditedEntryValue"

export const activityDetail = async ({ entry }) => {

  storeCommit("activity/setLastEntryDisplayed", entry.id);
  const entriesChecked = storeGet("activity/entriesChecked")
  await storeDispatch("getDoseUnits");

  const titleActivity = entriesChecked.length > 1?  i18n.t("multiple") : entry.activityType_designation

  const subtitleActivity = entriesChecked.length + " " + (entry.activityType_designation? entry.activityType_designation : "actvités") + " sélectionnées";

  const finalsub = entriesChecked.length > 1? subtitleActivity : entry.startDate ? new Date(entry.startDate).toLocaleDateString() : ""

  let activity_yield = entry.activityType_code == "recol" ? await storeDispatch("yield/getYieldFromActivity", {activityId: entry.id}) : null;
  return {
    title: titleActivity,
    subtitle: finalsub,
    // Décommenter les 3 lignes ci-dessous pour afficher le bouton supprimer
    // actions: {
    //   delete: ({ entry }) => storeDispatch("activity/deleteEntry", { id: entry.id }),
    //   delete: ({ entry }) => storeDispatch("activity/deleteEntry", { id: entry.id }),//consoel,anet{{^^consoel.consoel}}
    // },
    rows: [
      comp({
        component: "InfosActivity",
        editMany: true,
      }),
      comp({
        component: "GrowthStage",
        condition: () => entry.activityType_needInputs && (entry.activityType_needGrowthStage || entry.growthStage ),
        editMany: true,
         }),
      comp({
        component: "Inputs",
        title: i18n.t("components.Inputs.title"),
        condition: () => entry.activityType_needInputs,
        editMany: true,
      }),
      comp({
        component: "ReasonTargets",
        condition: () => entry.activityType_needTargets,
        editMany: true,
       }),
      comp({
        component: "Notes",
        condition: () => entry.notes,
      }),
      // rendement
      comp({
        component: "FieldInfo",
        title: i18n.t("activities.details.yield"),
        props: { value: activity_yield && activity_yield.theYield, unit: activity_yield && activity_yield.unit_unit },
        cols: 6,
        condition: () => activity_yield
      }),
      comp({
        component: "FieldInfo",
        title: i18n.t("yield.list.product"),
        props: { value: activity_yield && activity_yield.product_name},
        cols: 6,
        condition: () => activity_yield
      }),
      comp({
        component: "Path",
      }),
      comp({
        component: "WorkedSurface",
        name: "surface",
        editMany: true,
      }),
      comp({
        component: "Machines",
        name: "machines",
        title: i18n.t("commons.list.machines"),
        editMany: true,
       }),
       comp({
        component: "Prestation",
        condition: ($userCan) => $userCan('activities.service'),
        editMany: true,
      }),
      // operator
      comp({
        component: "FieldInfo",
        title: i18n.t("activities.details.operator"),
        props: { value: entry.operator_fullName == undefined?  i18n.t("activities.multiple.details.operator") : entry.operator_fullName },
        condition: () => entry.operator,
        editMany: true,
      }),
      comp({
        component: "DateAndTime",
        editMany: true,
      }),
      comp({
         component: "NewsActivity",
         condition: () => entry.alerts,
         }),
      comp({
        component: "Harvest",
        condition: () => entry.activityType_code == 'trans',
       }),
    ],
  };
};


export const activityEdit = async({ entry, title, subtitle }) => {

  const entriesChecked = storeGet("activity/entriesChecked")

  // need to fetch operators here, so we can hide the component if no result in list
  await storeDispatch("operators/fetchOperators")
  await storeDispatch("getDoseUnits");

  let subtitleActivity = entriesChecked.length + " " + (entry.activityType_designation? entry.activityType_designation : "actvités") + " sélectionnées";

  if (entry.id) storeCommit("activity/setLastEntryDisplayed", entry.id);

  let activity_yield = null;

  if (entry.activityType_code == "recol") {
    await storeDispatch("getNitrogenDoseUnits", { forYield: true });
    activity_yield = entry.id ? await storeDispatch("yield/getYieldFromActivity", {activityId: entry.id}) : null;
  }

  let titleActivity = entriesChecked.length > 1 ? i18n.t("multiple") : (i18n.t(title) || (entry.cropField_name + " - " + entry.cropField__crop_name))

  let finalsub = entriesChecked.length > 1 ? subtitleActivity : subtitle

  return {
    title: titleActivity,
    subtitle: finalsub,
    actions: {
      save: async () => {
        let activityType_code = storeGet("activity/editedEntry")?.activityType_code || storeGet("activity/currentEntry")?.activityType_code;
        await storeDispatch("activity/updateEditedEntry");
        // Si une activité de transport a été enregistrée et une récolte a été définie, elle est aussi enregistrée
        if(activityType_code == "trans" && storeGet("harvestStorage/editedEntry").quantity !== undefined) {
          // affectation de l'activité à la récolte
          await storeDispatch("harvestStorage/setEditedEntryValue", { activity: storeGet("activity/currentId") });
          // Enregistrement de la récolte
          await storeDispatch("harvestStorage/updateEditedEntry");
        }
      },
      delete: ({ entry }) => {
        if (Array.isArray(entriesChecked) && entriesChecked.length > 1) {
            entriesChecked.forEach(e => {
                storeDispatch("activity/deleteEntry", { id: e });
            });
        } else {
            storeDispatch("activity/deleteEntry", { id: entry.id });
        }
      }
    },
    rows: [
      comp({
        component: "ExportWarning",
        condition: () => entry.exported,
      }),
      comp({
        component: "EditInfosActivity",
        editMany: true,
      }),
      comp({
        component: entriesChecked.length > 1 ? "EditActivityMultiDate" :"EditActivityDate",
        editMany: true,
        condition: () => (!(entriesChecked.length > 1) || entriesChecked.length > 1 && storeGet("activity/currentEntry")?.cropField_toBeConfirmed === false)
      }),
      // machines
      comp({
        component: "EditMachines",
        title: i18n.t('components.EditMachines.title'),
        editMany: true,
      }),
      // Rendement
      comp({
        component: "EditActivityYield",
        props: {activity_yield: activity_yield},
        condition: () =>  (storeGet("activity/editedEntry")?.activityType_code == "recol" ||
                          (storeGet("activity/editedEntry")?.activityType_code === undefined &&
                          storeGet("activity/currentEntry")?.activityType_code == "recol"))
      }),
      comp({
        component: "EditOperator",
        title: i18n.t("activities.details.operator"),
        editMany: true,
        condition: () => storeGet("operators/operators").length > 0
      }),
      comp({
        component: "EditPrestation",
        condition: ($userCan) => $userCan('activities.service') && (storeGet("activity/editedEntry")?.activityType != undefined || storeGet("activity/currentEntry")?.activityType != undefined),
        editMany: true,
      }),
      comp({
        component: "EditGrowthStage",
        props: { inputFormPath: "activity/inputForm" },
        editMany: true,
        condition: () => (storeGet("activity/editedEntry")?.activityType_needInputs || (storeGet("activity/editedEntry")?.activityType_needInputs === undefined && storeGet("activity/currentEntry")?.activityType_needInputs)) && storeGet("activity/inputForm")?.growthStages?.length > 0
      }),
      comp({
        component: "EditHarvest",
        title: i18n.t("activities.details.harvest"),
        condition: () => storeGet("activity/editedEntry")?.activityType_code == "trans" ||
                          (storeGet("activity/editedEntry")?.activityType_code === undefined &&
                          storeGet("activity/currentEntry")?.activityType_code == "trans")
      }),
      /*comp({
        component: "EditTransport",
        condition: () => storeGet("activity/editedEntry")?.activityType_designation == "Transport-A revoir" || storeGet("activity/currentEntry")?.activityType_designation == "Transport-A revoir"
      }), */
      comp({
        component: "EditInputs",
        title: i18n.t("commons.list.inputs"),
        props: { inputFormPath: "activity/inputForm" , showVolumes: true},
        editMany: true,
        condition: () => Object.keys(storeGet("activity/inputForm")).length > 0 && (storeGet("activity/editedEntry")?.activityType_needInputs ||
                        (storeGet("activity/editedEntry")?.activityType_needInputs === undefined &&
                         storeGet("activity/currentEntry")?.activityType_needInputs))
      }),
      comp({
        component: "EditReasons",
        title: i18n.t("commons.list.reasons"),
        props: { inputFormPath: "activity/inputForm" },
        editMany: true,
        condition: () => Object.keys(storeGet("activity/inputForm")).length > 0 && (storeGet("activity/editedEntry")?.activityType_needTargets ||
                        (storeGet("activity/editedEntry")?.activityType_needTargets === undefined &&
                         storeGet("activity/currentEntry")?.activityType_needTargets))
      }),
      comp({
        component: "EditTargets",
        title: i18n.t("commons.list.targets"),
        props: { inputFormPath: "activity/inputForm" },
        editMany: true,
        condition: () => Object.keys(storeGet("activity/inputForm")).length > 0 && (storeGet("activity/editedEntry")?.activityType_needTargets ||
                        (storeGet("activity/editedEntry")?.activityType_needTargets === undefined &&
                         storeGet("activity/currentEntry")?.activityType_needTargets))
      }),
      comp("EditNotes"),
    ],
  };
};

export const activityNew = ({ entry, editedEntry, route }) => {
  const groupCode = route.params.groupCode;
  storeCommit("activity/resetEditedEntry");
  storeCommit("activity/resetCurrentId");
  storeCommit("activity/resetCurrentEntry")
  storeDispatch(storeEditAction, {
    groupCode: groupCode
  });

  const title = "activities.details.create_new_activity";
  const designation = storeGet("activityTypeGroups").find(group => group.code === groupCode).designation || null
  const subtitle = i18n.t("tours.add.subtitle_prefix") + " " + designation
  return activityEdit({ entry, editedEntry, title, subtitle});
}

export const activityNewEdit = ({ entry, editedEntry }) => {
  storeCommit("activity/resetEditedEntry");
  storeCommit("activity/resetCurrentId");
  storeCommit("activity/resetCurrentEntry")
  const title = "activities.details.create_new_activity";
  return {
    title: i18n.t(title),
    rows: [comp("ActivityFamilyPicker")],
  };
};