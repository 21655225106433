    export function mergeCurrentEntry(entries) {
        let currentEntry = {...entries[0]}

        // Attributs qui ne sont affichés que si ils ont les mêmes valeurs
        const listOfSame = [
            "endDate",
            "startDate",
            "growthStage",
            "growthStage_designation",
            "activityType",
            "activityType_designation",
            "activityType_code",
            "machine_list",
            "subcontractor",
            "reason",
            "target_list",
            "operator",
            "operator_fullName",
            "activityType_needGrowthStage",
            "activityType_needInputs",
            "activityType_needTargets",
            "cropField_toBeConfirmed",
            "service",
            "notToBill",
        ]

        // Attributs dont les valeurs sont à cumuler dans une liste
        const listOfAdd = [
            "cropField__crop",
            "cropField",
            "cropField_name",
            "cropField__crop_name",
        ]

        // Attribut dont les attributs doivent être additionnés
        const listOfCalcul = [
            "surface",
            "roadDuration",
            "elapsedTime",
            "workingTime",
        ]

        function mergeAddValue(properties) {
            properties.forEach(property => {
                let values = entries.map(e => e[property]);
                let uniqueValues = [...new Set(values.filter(v => v !== undefined && v !== null))];
                currentEntry[property] = uniqueValues;
            });
        }

        function mergeCalculValue(properties) {
            properties.forEach(property => {
                let values = entries.map(e => parseFloat(e[property])).filter(v => !isNaN(v));
                if (values.length !== entries.length) {
                    currentEntry[property] = undefined;
                } else {
                    let sum = values.reduce((acc, val) => acc + val, 0);
                    currentEntry[property] = parseFloat(sum.toFixed(2));
                }
            });
        }

        function mergeSameValue(properties) {
            properties.forEach(property => {
                let values = entries.map(e => e[property]);
                if (values.every(v => JSON.stringify(v) === JSON.stringify(values[0]))) {
                    currentEntry[property] = values[0];
                } else {
                    currentEntry[property] = undefined;
                }
            });
        }

        function mergeInputs() {
            let groupedInputs = {};

            // Grouper les inputs par critères (inputsStock, doseUnit)
            entries.forEach(entry => {
                if (!entry.inputs || !Array.isArray(entry.inputs)) return;

                entry.inputs.forEach(input => {
                    const key = `${input.inputsStock}-${input.doseUnit}`;
                    if (!groupedInputs[key]) {
                        groupedInputs[key] = [];
                    }
                    groupedInputs[key].push(input);
                });
            });

            // Traiter chaque groupe
            let mergedInputs = [];

            // On vérifie d'abord que les entrées mentionnes toutes les mêmes inputsStock et doseUnit
            // il faut que le nombre d'input de chaque groupe corresponde au nombre d'entrées
            Object.values(groupedInputs).forEach(group => {
                if (group.length != entries.length) {
                    mergedInputs = undefined;
                }
            })

            // Ensuite on vérifie si les doses sont identifiques pour chaque produit
            if (mergedInputs !== undefined) {
                Object.values(groupedInputs).forEach(group => {
                    let mergedInput = { ...group[0] }; // Copie de la première entrée du groupe

                    // Vérification des doses
                    const doseValues = group.map(input => input.dose);
                    const doseAllEqual = doseValues.every(v => v === doseValues[0]);

                    if (!doseAllEqual) {
                        mergedInput.dose = undefined; // Invalider la dose si les valeurs diffèrent
                    } else {
                        mergedInput.dose = doseValues[0]; // Conserver la dose si elles sont identiques
                    }

                    const filledQuantityValues = group.map(input => input.filledQuantity);
                    const nonNullFilledQuantity = filledQuantityValues.filter(v => v !== null && v !== undefined);

                    if (nonNullFilledQuantity.length > 0 && nonNullFilledQuantity.length !== filledQuantityValues.length) {
                        // Mélange de valeurs valides et null/undefined : invalider filledQuantity
                        mergedInput.filledQuantity = null;
                    } else if (nonNullFilledQuantity.length === 0) {
                        // Si tous les `filledQuantity` sont null ou undefined
                        mergedInput.filledQuantity = null;
                    } else {
                        // Sinon, sommer les `filledQuantity` valides
                        mergedInput.filledQuantity = nonNullFilledQuantity.reduce((acc, val) => acc + val, 0);
                    }

                    mergedInputs.push(mergedInput);
                });
            }

            // Assigner les inputs fusionnés
            currentEntry.inputs = mergedInputs;
        }


        mergeInputs()
        mergeAddValue(listOfAdd);
        mergeCalculValue(listOfCalcul);
        mergeSameValue(listOfSame);

        return currentEntry
    }
