<template>
  <div v-if="loaded">
    <Treatment
      taskName="export_to_xarvio"
      :async="true"
      :taskTimeout="90000"
      :title="$t('cropfields.import_export.export_to_xarvio')"
      :startButton="$t('commons.exports.export')"
      :finishButton="$t('commons.exports.finish')"
      :failMessage="$t('cropfields.import_export.export_cropfields_fail')"
      :onBack="onBack"
      :onClose="onClose"
      :onTreatmentDone="onTreatmentDone"
    >
      <TC_Farm :preselectedFarms="xarvioFarmList"/>
      <TC_ClientId_NoTemplate />
      <TC_CheckBox
        name="crop"
        storeAction="cropfield/getFilter"
        :storeActionParams="{attrib: 'crop'}"
        :preselectedOptions="xarvioCropList"
        :title="$t('commons.exports_reports.crop_selection_title')"
        :allLabel="$t('commons.exports_reports.all_crops')"
      />
    </Treatment>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import Treatment from "@/components/commons/treatments/TreatmentExecution";
import TC_ClientId_NoTemplate from "@/components/commons/treatments/TC_ClientId_NoTemplate";
import TC_Farm from "@/components/commons/treatments/TC_Farm";
import TC_CheckBox from "@/components/commons/treatments/TC_CheckBox";

export default {
  data() {
    return {
      xarvioFarmList: [],
      xarvioCropList: [],
      loaded: false
    }
  },
  async mounted() {
    // On récupère les fermes et les cultures stockées lors du précédent export,
    // afin de les préselectionner dans le formulaire
    this.loaded = false;
    let registeredFarms = await this.$store.dispatch("getMiscellaneousByKey", {key: "xarvio_farm_list"});
    let registeredCrops = await this.$store.dispatch("getMiscellaneousByKey", {key: "xarvio_crop_list"});
    if (registeredFarms.length > 0) {
      let registeredFarmsId = registeredFarms[0].value;
      if (registeredFarmsId != "all")
        this.xarvioFarmList = registeredFarmsId.split(",");
    }
    if (registeredCrops.length > 0) {
      let registeredCropsId = registeredCrops[0].value;
      if (registeredCropsId != "all")
        this.xarvioCropList = registeredCropsId.split(",");
    }
    this.loaded = true;
  },
  methods: {
    ...mapActions({
      fetchCropfields: "cropfield/fetchEntries",
      initFilters: "cropfield/initFilters",
      fetchFarms: "farm/fetchFarms",
    }),
    onBack() {
      this.$router.push({ name: this.isOnMap ? "cropfield.map.importexport" : "cropfield.importexport" });
    },
    onClose() {
      this.$router.push({ name: this.isOnMap ? "cropfield.map" : "cropfield.list" });
    },
    async onTreatmentDone() {
      this.fetchFarms();

      await this.fetchCropfields();
      this.initFilters();
    },
  },
  computed: {
    isOnMap() {
      return this.$route.name.includes("map");
    },
  },
  components: { Treatment, TC_ClientId_NoTemplate, TC_Farm, TC_CheckBox },
};
</script>
