import { storeDispatch, storeCommit } from "@/views/common/details/commons";

import {
  machineDetail,
  machineEdit,
  machineNew,
} from "./views";

const routes = {
  detail: "machines.details",
  edit: "machines.edit",
  list: "machines.list",
};

const init = async ({ route }) => {
  let id = route.params.machines_id;
  storeCommit("machine/resetEditedEntry");
  if (id) await storeDispatch("machine/getOneMachine", { id: id, detail: true });
};

const storeEditAction = "machine/setEditedEntryValue";
const storeReadGetter = "machine/currentEntry";
const storeEditedGetter = "machine/editedEntry";

const onBack = ({ entry, router, isEditMode }) => {
  if (isEditMode()) {
    onClose({ entry, router });
  } else goToList({ router });
};
const onEdit = ({ entry, router }) => router.push({ name: routes.edit, params: { machines_id: entry.id } });
const onClose = ({ entry, router }) => {
  storeCommit("machine/resetLastEntryDisplayed");
  router.push({ name: routes.list });
}
const goToDetail = ({ entry, router }) => router.push({ name: routes.detail, params: { machines_id: entry.id } });
const goToList = ({ router }) => router.push({ name: routes.list });

const commonParams = {
  storeReadGetter: storeReadGetter,
  storeEditAction: storeEditAction,
  storeEditedGetter: storeEditedGetter,
  onInit: init,
  onBack: onBack,
  onEdit: onEdit,
  onClose: onClose,
  goToList: goToList,
  goToDetail: goToDetail,
};

export const views = [
  {
    path: ":machines_id",
    name: "machines.details",
    view: machineDetail,
    isEditMode: () => false,
    showBackButton: () => false,
    showCloseButton: () => true,
    ...commonParams,
  },
  {
    path: ":machines_id/edit",
    name: "machines.edit",
    view: machineEdit,
    isEditMode: () => true,
    showBackButton: () => true,
    showCloseButton: () => false,
    ...commonParams,
  },
  {
    path: "new",
    name: "machines.new",
    view: machineNew,
    isEditMode: () => null,
    showBackButton: () => true,
    ...commonParams,
  },
];
