<template>
  <div>
    <Treatment
      :endpoint="CropFieldApi.exportCropFields"
      :async="false"
      :title="
        areEntriesChecked
          ? $t('cropfields.import_export.export_selected_cropfields')
          : $t('cropfields.import_export.export_all_cropfields')
      "
      :startButton="$t('commons.exports_reports.export_button')"
      :finishButton="$t('commons.exports_reports.finish')"
      :exportAllowedFormats="['xlsx', 'shapefile', 'telepac', 'json']"
      :isDirectDownload="true"
      :onBack="onBack"
      :onClose="onClose"
    >
      <TC_FileFormat />
      <TC_Farm_NoTemplate />
      <TC_Campaign_NoTemplate />
      <TC_CheckedCropfields_NoTemplate />
    </Treatment>
  </div>
</template>

<script>
import Treatment from "@/components/commons/treatments/TreatmentExecution";
import TC_FileFormat from "@/components/commons/treatments/TC_FileFormat";
import TC_Farm_NoTemplate from "@/components/commons/treatments/TC_Farm_NoTemplate";
import TC_Campaign_NoTemplate from "@/components/commons/treatments/TC_Campaign_NoTemplate";
import TC_CheckedCropfields_NoTemplate from "@/components/commons/treatments/TC_CheckedCropfields_NoTemplate";

import CropFieldApi from "@/api/cropfield.api";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      CropFieldApi: CropFieldApi,
    };
  },
  methods: {
    onBack() {
      this.$router.push({ name: "cropfield.importexport" });
    },
    onClose() {
      this.$router.push({ name: "cropfield.importexport" });
    },
  },
  computed: {
    ...mapGetters({
      entriesChecked: "cropfield/entriesChecked",
    }),
    areEntriesChecked() {
      return this.entriesChecked.length > 0;
    },
  },
  components: {
    Treatment,
    TC_FileFormat,
    TC_Farm_NoTemplate,
    TC_Campaign_NoTemplate,
    TC_CheckedCropfields_NoTemplate,
  },
};
</script>
