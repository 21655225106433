<template>
  <div>
    <router-link
      :to="{
        name: 'inputStock.risks',
        params: { product_id: currentEntry.product_id || currentEditedEntry.product_id },
      }"
      class="btn bg-text-color text-infos config-density-semence"
    >
      {{ $t("inputStock.details.see_product_risks") }}
    </router-link>
  </div>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import { mapGetters } from "vuex";

export default {
  mixins: [DetailUpdateMixin],
  computed: {
    ...mapGetters({
      inputsStock: "inputStock/entries",
      currentEntry: "inputStock/currentEntry",
      currentEditedEntry: "inputStock/editedEntry",
    }),
  }
};
</script>
