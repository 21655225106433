<template>
  <div>

    <!-- Nav Charac & maintenances -->
    <div>
      <b-tabs class="details-tabs-stock">
        <b-tab :no-body="true" :title="$t('inputStock.details.features')" :active="!showSupplies">
          <!-- <CropfieldInfos class="mt-4" :editMode="editMode" :newMode="newMode" :fields="details.infos" /> -->
          <InputStockInfos :currentEntry="this.currentEntry" class="mt-4" />
        </b-tab>
        <b-tab v-if="$userCan('inputstock.supplies')" :no-body="true" :title="$t('inputStock.details.supplies')" :active="showSupplies">
          <Supplies class="mt-4" />
        </b-tab>
        <b-tab v-if="$userCan('inputstock.supplies')" :no-body="true" :title="$t('inputStock.details.stock_state')" lazy>
          <StockState class="mt-4" />
        </b-tab>
        <b-tab :no-body="true" :title="$t('inputStock.details.activities_list')" lazy>
          <ProductActivities class="mt-4" />
        </b-tab>

      </b-tabs>
    </div>

    <hr />
    <br />
  </div>
</template>
<script>
import InputStockInfos from "@/components/inputstock/details/InputStockInfos.vue";
import Supplies from "@/components/inputstock/details/Supplies.vue";
import StockState from "@/components/inputstock/details/StockState.vue";
import ProductActivities from "@/components/inputstock/details/ProductActivities.vue";
import { mapGetters } from "vuex";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import { EventBus } from "@/eventBus.js";

export default {
  mixins: [DetailUpdateMixin],

  async mounted () {
    if(this.$route.params.mode == "addSupply" || this.$route.params.mode == "updateSupply") {
      this.showSupply();
    }
    EventBus.$on("addSupply", this.showSupply);
    EventBus.$on("updateSupply", this.showSupply);
  },
  data() {
    return {
      showSupplies: false,
    };
  },
  methods: {

  showSupply({ show } = {show: true}) {
    if(show)
      this.showSupplies = true;
    else
      this.showSupplies = false;
  },

  },
  components: {
    InputStockInfos,
    Supplies,
    StockState,
    ProductActivities

  },
  computed: {
    ...mapGetters({
      inputsStock: "inputStock/entries",
      currentEntry: "inputStock/currentEntry"
    })
  },
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: separate;
  border-spacing: 5px 0px;
}

/**
 * Put border when a page is selected.
 */
.page-active {
  border-bottom: 5px solid $primary-color;
  border-radius: 0px;
  font-weight: bold !important;
}

/**
 * Css link for nav charac & maint.
 */
.nav span {
  display: inline-block;
  font-weight: normal;
  font-size: 16px;
}

/**
 * Css hr under the nav.
 */
hr {
  margin: 0px auto auto auto;
}

/**
 * Link text when hover.
 */
.nav span:hover {
  font-weight: bold;
  color: $primary-color;
  cursor: pointer;
}

</style>
<style>
.details-tabs-stock .nav-tabs .nav-link {
  border: 0;
  padding: 0.5rem 0;
  background: transparent;
  font-size: 0.9em;
  color: black;
}

.details-tabs-stock .nav-item {
  margin-right: 1rem;
}

.details-tabs-stock .tab-pane.active {
  background: transparent;
}

.details-tabs-stock .nav-tabs .nav-link.active {
  background: transparent;
  border-bottom: 4px solid black;
}
</style>

