<template>
  <!-- MCT = Map Cut Tool -->
  <!-- this is the view when we define a geometry on the map for a location -->
  <div class="pt-4">
    <!-- Button "Define geometry" -->
    <b-button
      class="button-default text-left mb-3 justify-content-start"
      @click="modifyGeometry"
      :class="{ active: modifyMode }"
    >
      {{ $t("cropfields.details.define_geometry") }}
    </b-button>

    <!-- Guide text -->
    <p v-if="mode" class="bg-white text-infos">
      <span v-if="mode == DRAW_MODE">{{ $t("cropfields.details.define_geometry_guide") }}</span>
    </p>

    <!-- Name and type form, shown when the geometry has been drawn -->
    <MCT_Loc_DefineGeometry_Form v-if="showForm" />
  </div>
</template>

<script>
import { EventBus } from "@/eventBus.js";

import MapCutToolsMixin from "@/components/cropfields/details/map-cut-tools/MapCutToolsMixin";


import MCT_Loc_DefineGeometry_Form from "./MCT_Loc_DefineGeometry_Form.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
      DRAW_MODE: "draw_mode",
      showForm: false,
      mode: null,
    };
  },
  mixins: [MapCutToolsMixin],
  components: { MCT_Loc_DefineGeometry_Form },
  props: {
    modifyMode: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    EventBus.$on("geometryDrawEnded", this.submitGeometry);

  },
  methods: {
    ...mapMutations({
      resetEditedEntry: "location/resetEditedEntry",
    }),
    ...mapActions({
      setEditedEntryValue: "location/setEditedEntryValue",
      saveEditedEntry: "location/saveEditedEntry",
    }),
    /**
     * Save
     */
    async save() {
      // disable modify mode
      this.$emit("update:modifyMode", false);
      // disable modify interaction on map
      EventBus.$emit("disableModifyGeometry", { save: false });

      // save geometry, name & type
      await this.saveEditedEntry();

      // plot the geometry
      EventBus.$emit("plotLocationFields");

      // redirect to edit geometry page
      this.$router.push({
        name: "location.map.details",
        params: { location_id: this.currentEntry.id, mode: "editGeometry" },
      });
    },
    /**
     * Reset
     */
    reset() {
      // disable modify mode
      this.$emit("update:modifyMode", false);
      // disable modify interaction on map
      EventBus.$emit("disableModifyGeometry", { save: false });

      // hide the form
      this.showForm = false;

      // reset form data in store
      this.resetEditedEntry();
    },

    submitGeometry({ geometry }) {
      this.setEditedEntryValue({
        geometry: geometry,
      });
      this.$emit("update:modifyMode", true);
      this.showForm = true;

    },
  },
  computed: {
    ...mapGetters({
      currentEntry: "location/currentEntry",
      editedEntry: "location/editedEntry",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
