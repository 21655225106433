<template>
  <div>

    <!-- Section d'ouverture du bilan -->
    <Bilan
      :editMode="this.edit_mode"
      :fertilisationNeedsCalculation="this.ferti"
      :fertilisationNeedsCalculationList="this.fertiList"
      :fertilisationNeedsCalculationOption="this.fertiOption"
      :fertiToolTips="this.fertiToolTips"
    />

    <hr v-if="this.fertiOption.colzaCalculator_show || this.fertiOption.ceilingDose_show"/>

    <!--PI COLZA-->
    <Colza v-if="this.fertiOption.colzaCalculator_show && !this.fertiOption.ceilingDose_show"
      :editMode="this.edit_mode"
      :fertilisationNeedsCalculation="this.ferti"
      :fertilisationNeedsCalculationList="this.fertiList"
      :fertilisationNeedsCalculationOption="this.fertiOption"
      :fertiToolTips="this.fertiToolTips"
    />

    <hr v-if="!this.fertiOption.ceilingDose_show"/>

    <!-- EngraisOrganiques -->
    <EngraisOrganiques
      :editMode="this.edit_mode"
      :fertilisationNeedsCalculation="this.ferti"
      :fertilisationNeedsCalculationList="this.fertiList"
      :fertilisationNeedsCalculationOption="this.fertiOption"
      :fertiToolTips="this.fertiToolTips"
    />

    <hr v-if="!this.fertiOption.ceilingDose_show"/>

    <!-- Section d'historique de la parcelle -->
    <HistoriqueDeParcelle v-if="!this.fertiOption.ceilingDose_show"
      :editMode="this.edit_mode"
      :fertilisationNeedsCalculation="this.ferti"
      :fertilisationNeedsCalculationList="this.fertiList"
      :fertilisationNeedsCalculationOption="this.fertiOption"
      :fertiToolTips="this.fertiToolTips"
    />

    <hr v-if="!this.fertiOption.ceilingDose_show"/>

    <!-- Section du couvert végétal -->
    <CouvertVegetale v-if="!this.fertiOption.ceilingDose_show"
      :editMode="this.edit_mode"
      :fertilisationNeedsCalculation="this.ferti"
      :fertilisationNeedsCalculationList="this.fertiList"
      :fertilisationNeedsCalculationOption="this.fertiOption"
      :fertiToolTips="this.fertiToolTips"
    />


    <hr />

    <!--Besoin-->
    <Besoins
      :editMode="this.edit_mode"
      :fertilisationNeedsCalculation="this.ferti"
      :fertilisationNeedsCalculationList="this.fertiList"
      :fertilisationNeedsCalculationOption="this.fertiOption"
      :fertiToolTips="this.fertiToolTips"
    />

    <hr v-if="!this.fertiOption.ceilingDose_show"/>
    <!--Fournitures du Sol-->
    <FournitureDuSol v-if="!this.fertiOption.ceilingDose_show"
      :editMode="this.edit_mode"
      :fertilisationNeedsCalculation="this.ferti"
      :fertilisationNeedsCalculationList="this.fertiList"
      :fertilisationNeedsCalculationOption="this.fertiOption"
      :fertiToolTips="this.fertiToolTips"
    />

    <hr />
    <!--Irrigation-->
    <Irrigation
      :editMode="this.edit_mode"
      :fertilisationNeedsCalculation="this.ferti"
      :fertilisationNeedsCalculationList="this.fertiList"
      :fertilisationNeedsCalculationOption="this.fertiOption"
      :fertiToolTips="this.fertiToolTips"
    />

    <hr />

   <!--Result-->

    <b-row v-if="!this.fertiOption.ceilingDose_show">
      <div class="ml-5">
        <h6>
          {{$t('cropfields.fertilization.needsCalculation.X')}} :
          <strong>{{ this.ferti.X !== null ? this.ferti.X + " kgN/ha" : "Non calculé" }}</strong>
        </h6>
      </div>
    </b-row>

    <b-row>
      <div class="ml-5">
        <h6>
          {{$t('cropfields.fertilization.needsCalculation.Xauthorized')}} :
          <strong>{{ this.ferti.Xauthorized !== null ? this.ferti.Xauthorized + " kgN/ha" : "Non calculé" }}</strong>
        </h6>
      </div>
    </b-row>


    <!--Save-->
    <div align="center" v-if="edit_mode">
      <div class="mt-2 mb-3">
        <b-button
          class="button-default button-default-resize"
          type="submit"
          @click="onSave()"
        >{{$t('cropfields.details.save')}}</b-button>
      </div>
    </div>

  </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import Bilan from "@/views/fertilization/PPF/Bilan"
import Colza from "@/views/fertilization/PPF/Colza"
import EngraisOrganiques from "@/views/fertilization/PPF/EngraisOrganiques";
import HistoriqueDeParcelle from "@/views/fertilization/PPF/HistoriqueDeParcelle";
import Besoins from "@/views/fertilization/PPF/Besoins";
import FournitureDuSol from "@/views/fertilization/PPF/FournitureDuSol";
import CouvertVegetale from "@/views/fertilization/PPF/CouvertVegetale";
import Irrigation from "@/views/fertilization/PPF/Irrigation";
import { EventBus } from "@/eventBus.js";

export default {
  name: "edit-needs-calculation",
  data() {
    return {
    };
  },
  components: {
    Bilan,
    Colza,
    EngraisOrganiques,
    HistoriqueDeParcelle,
    Besoins,
    FournitureDuSol,
    CouvertVegetale,
    Irrigation,
},
mounted() {
    EventBus.$on("saveFertilizationPlan", this.onSave);
  },
  beforeDestroy(){
    EventBus.$off("saveFertilizationPlan", this.onSave);
  },
methods: {
  async onSave() {
    await this.$store.dispatch('fertilization/save');
    EventBus.$emit("switch2")
  },
},
  props: ['ferti', 'fertiList', 'fertiOption', 'fertiToolTips'],
  computed: {
    ...mapGetters({
      edit_mode: "fertilization/editMode",
     }),
  },
};
</script>

<style lang="scss" scoped>
/**
 * Date params.
 */
.date-params {
  margin: 2px 10px 3px 15px;
}

.button-default-resize {
  max-width: 200px;
}
</style>
