<template>
  <div>
    <b-row>

      <!--Variété-->
      <b-col class="col-6" v-if="this.fertilisationNeedsCalculationOption.varieties_show" >
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="this.fertiToolTips.variety">{{$t('cropfields.fertilization.variety')}} :</h4>
        <template v-if="editMode">
          <b-form-select
            :options="this.fertilisationNeedsCalculationForm.varieties"
            value-field="id"
            text-field="name"
            v-model="variety"
            size="sm"
            @change="updateVariety();"
          />
        </template>
        <template v-else>
          <p class="text-infos bg-white">
            <span>{{ this.fertilisationNeedsCalculation.variety_name ? this.fertilisationNeedsCalculation.variety_name : "/" }}</span>
          </p>
        </template>
      </b-col>

      <!--Sensibilité Azoté-->
      <b-col class="col-6" v-if="this.fertilisationNeedsCalculationOption.criteria1_label" >
          <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.criteria1_value"> {{criteria1_label }} </h4>
          <template v-if="editMode">
          <b-form-select
            :options="this.fertilisationNeedsCalculationForm.criteria1_values"
            value-field="id"
            text-field="designation"
            v-model="criteria1_value"
            size="sm"
            @change="updateCriteria1();"
          />
        </template>
        <template v-else>
          <p class="text-infos bg-white">
            <span>{{ this.fertilisationNeedsCalculation.criteria1_value ? this.fertilisationNeedsCalculation.criteria1_value : "/" }}</span>
          </p>
        </template>
      </b-col>

      <!--Protein-->
      <b-col class="col-6" v-if="this.fertilisationNeedsCalculationOption.proteinPrority_show" >
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.proteinPrority" >{{$t('cropfields.fertilization.proteinPrority')}}</h4>
        <b-form-checkbox size="sm" v-model="proteinPrority" @input="updateProtein();" :disabled="!edit_mode"/>
      </b-col>
    </b-row>

    <!--Sol-->
    <b-row>
      <!-- Type de sol -->
      <b-col class="col-6" >
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.soilType">{{$t('cropfields.fertilization.soilType')}} :</h4>
        <template v-if="editMode">
          <b-form-select
          :options="this.fertilisationNeedsCalculationForm.soilTypes"
          value-field="id"
          text-field="designation"
          v-model="soilType"
          size="sm"
          @change="updateSoilType();"
        />
        </template>
        <template v-else>
          <p class="text-infos bg-white">
            <span>{{ this.fertilisationNeedsCalculation.soilType_designation ? this.fertilisationNeedsCalculation.soilType_designation : "/" }}</span>
          </p>
        </template>
      </b-col>

      <!-- Profondeur de sol -->
      <b-col class="col-6" >
        <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.soilDepth">{{$t('cropfields.fertilization.soilDepth')}} :</h4>
        <template v-if="editMode">
          <b-form-select
          :options="this.fertilisationNeedsCalculationForm.soilDepths"
          value-field="id"
          text-field="designation"
          v-model="soilDepth"
          size="sm"
          @change="updateSoilDepth();"
        />
        </template>
        <template v-else>
          <p class="text-infos bg-white">
            <span>{{ this.fertilisationNeedsCalculation.soilDepth_designation ? this.fertilisationNeedsCalculation.soilDepth_designation : "/" }}</span>
          </p>
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: "edit-fertilization-infos",
  data(){
    return{
      variety: "",
      soilType: "",
      soilDepth: "",
      proteinPrority: false,
      criteria1_value: "",
      criteria1_label: "",
    }
  },
  created() {
    this.init();
  },
  props: ['editMode', 'fertilisationNeedsCalculation', 'fertilisationNeedsCalculationForm', 'fertilisationNeedsCalculationOption', 'fertiToolTips'],
  methods: {
    init: async function() {
      this.proteinPrority = this.fertilisationNeedsCalculation.proteinPrority
      await this.loadData();
    },
    async loadData(){
      if(typeof this.fertilisationNeedsCalculation != 'undefined') {
        this.variety = this.fertilisationNeedsCalculation.variety
        this.soilType = this.fertilisationNeedsCalculation.soilType
        this.soilDepth = this.fertilisationNeedsCalculation.soilDepth
         this.criteria1_value = this.fertilisationNeedsCalculation.criteria1_value
        this.criteria1_label = this.fertilisationNeedsCalculationOption.criteria1_label
      }
    },
    async editCheck(){
      this.edit_mode = this.editMode
    },
    async updateSoilType(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "soilType", value: this.soilType });
    },
    async updateSoilDepth(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "soilDepth", value: this.soilDepth });
    },
    async updateVariety(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "variety", value: this.variety });
    },
    async updateProtein(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "proteinPrority", value: this.proteinPrority });
    },
    async updateCriteria1(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "criteria1_value", value: this.criteria1_value });
    },
  },
  watch: {
    fertilisationNeedsCalculation: {
      handler: 'loadData',
      immediate: true
    },
    editMode: {
      handler: 'editCheck',
      immediate: true
    }
  }
};

</script>

<style lang="scss" scoped>
.font-culture {
  font-size: 12px;
  font-weight: normal;
  color: $primary-color;
}

.disabled-input:disabled {
  background-color: white !important;
  border: none;
}

.to-much-text{
  font-size: 11px;
  font-weight: bold;
  margin: 7px 0;
  margin-bottom: 4px !important;
  padding: 0;
  color: #aaaaaa;
  font-family: "Roboto", sans-serif;
}

</style>
