import * as turf from "@turf/turf";

import { project3857, project4326, computeSurface, writeGeometriesToLayer } from "@/utils/geomatics";

import { EventBus } from "@/eventBus.js";

import Map from "@/views/cropfields/map/Map";

export default {
	data() {
		return {
			mapInitialized: false,

			// ui
			showActivityPathFilter: false,

			// map and layers
			map: null,
			activeFeature: null,
			
			layerSource: null,

			lengthOverlay: null,

			locationLayerSource: null,
			locationsLayer: null,
			activeLocationLayer: null,

			// used for debug
			newLines: [],
			transLines: [],
			polygonInter: [],
			lineSliced: [],

			// used for layer filter button
			mapLayers: [],
			layerFilters: [
				{ name: this.$t("cropfields.map.layers.satellite"), value: true },
				{ name: this.$t("cropfields.map.layers.cadastre"), value: true },
				{ name: this.$t("cropfields.map.layers.osm"), value: false },
				{ name: this.$t("cropfields.map.layers.cropfields"), value: true },
			],


			// modes
			drawMode: false,
			holeMode: false,

			// data

			debounceTimer: null,
			ctrlKey: false,

			displayActiveLocation: true,
		};
	},
	mounted() {

		if (this.disableInteraction) return;

		EventBus.$on("plotFields", this.plotFields);
		EventBus.$on("plotLocationFields", this.plotLocationFields);

		EventBus.$on("refreshMap", this.refreshMap);

		let t = this;
		addEventListener("keydown", function (e) {
			if (e.keyCode == 17 || e.keyCode == 91) t.ctrlKey = true;
		});
		addEventListener("keyup", function (e) {
			if (e.keyCode == 17 || e.keyCode == 91) t.ctrlKey = false;
		});

	},
	beforeDestroy() {
		EventBus.$off("plotFields", this.plotFields);
		EventBus.$off("plotLocationFields", this.plotLocationFields);

		EventBus.$off("refreshMap", this.refreshMap);
	},
	methods: {
		/**
		 * @description récupère les données des lieux et les affiche sur la carte
		 */
		async fetchAndInit() {
			await this.fetchLocEntries({ page: 0, withGeometries: true });
			await this.fetchEntries({ page: 0, withGeometries: true });
	  
			this.initMap();
			
		},
		/**
		 * @description récupère les données des lieux et rafraîchit la carte 
		 */
		async fetchAndResetMap() {
			if (this.$route.params.location_id) this.onSelect(this.$route.params.location_id);
		
			await this.fetchLocEntries({ page: 0, withGeometries: true });
			this.plotLocationFields();
		
			if (!this.locCurrentEntry) this.resetSelection();
		
			this.zoomOnData();
		},
		/**
		 * @description désélectionne le lieu qui était sélectionné
		 */
		resetSelection() {
			this.resetCurrentId();

			if (this.$route.name != "location.map") this.$router.push({ name: "location.map" });
		},
		zoomOnData() {
			if(this.locGeometries3857.length)
				this.zoomOnCollection(Object.values(this.locGeometries3857));
			else
				this.zoomOnCollection(Object.values(this.geometries3857));
		},
		/**
		 * @description fait un zoom sur les lieux présents sur la carte
		 * @param {*} collection 
		 */
		zoomOnCollection(collection) {
			if (!this.map) return;

			let checked = turf.featureCollection(collection);

			this.map.getView().fit(turf.bbox(checked), { duration: 0, padding: [50, 50, 50, 50] });
		},

		// initialize the map
		initMap() {
			if (this.mapInitialized) return;

			this.mapInitialized = true;

			let t = this;
			Gp.Services.getConfig({
				apiKey: "essentiels,parcellaire",
				// serverUrl: `${this.publicPath}autoconf.js`,
				// callbackSuffix: "",
				onSuccess: function () {
					t.initLayers();
					t.zoomOnData();

					t.map.on("click", t.onClick);
					t.map.on("pointermove", t.onMove);

					t.$emit("mapready");
				},
			});
		},

		/**
		 * Initialise les couches de la carte
		 */
		initLayers() {
			this.mapLayers = [
				new ol.layer.GeoportalWMTS({
					layer: "ORTHOIMAGERY.ORTHOPHOTOS", // Vue satellite
				}),
				new ol.layer.GeoportalWMTS({
					layer: "CADASTRALPARCELS.PARCELS", // Parcelles cadastrales
				}),
				new ol.layer.Tile({
          source: new ol.source.OSM(),
        }),
			];

			this.map = new ol.Map({
				target: "map",
				controls: ol.control.defaults({ attribution: false }),
				layers: this.mapLayers,
				view: new ol.View({
					zoom: 14,
					maxZoom: 19,
				}),
			});

			this.lengthOverlay = new ol.Overlay({
				element: this.$refs.lengthElement,
				offset: [0, -15],
				positioning: "bottom-center",
			});
			this.map.addOverlay(this.lengthOverlay);

			this.layerSource = new ol.source.Vector();
			this.locationLayerSource = new ol.source.Vector();
			let activeSource = new ol.source.Vector();
			this.cutSource = new ol.source.Vector();

			/* Ajout d'une barre de zoom */
			var zoomControl = new ol.control.ZoomSlider();
			this.map.addControl(zoomControl);

			/* Ajout d'une échelle */
			var scalelineControl = new ol.control.ScaleLine({ minWidth: 150 });
			this.map.addControl(scalelineControl);

			/* Ajout de l'overlay pour afficher les parcelles */
			this.parcelsLayer = new ol.layer.Vector({ source: this.layerSource, style: this.getCropfieldLocationStyle });
			this.parcelsLayer.setZIndex(5);
			this.mapLayers.push(this.parcelsLayer);
			this.map.addLayer(this.parcelsLayer);

			/* Ajout de l'overlay pour afficher les lieux */
			this.locationsLayer = new ol.layer.Vector({ source: this.locationLayerSource, style: this.getLocationStyle });
			this.locationsLayer.setZIndex(7);
			this.map.addLayer(this.locationsLayer);

			/* Ajout d'une barre de recherche d'adresse */
			var searchControl = new ol.control.SearchEngine({ collapsed: false });
			this.map.addControl(searchControl);

			// récupère la géométrie des parcelles
			this.plotFields();

			//récupère la géométrie des lieux
			this.plotLocationFields();

			/* Ajout de l'overlay pour afficher le lieu sélectionné */
			this.activeLocationLayer = new ol.layer.Vector({ source: activeSource, style: this.getActiveLocationStyle });
      this.activeLocationLayer.setZIndex(7);
      this.map.addLayer(this.activeLocationLayer);

			this.holeLayer = new ol.layer.Vector({ source: new ol.source.Vector(), style: this.holeStyle });
      this.holeLayer.setZIndex(9);
      this.map.addLayer(this.holeLayer);

			this.activeLocationHolesLayer = new ol.layer.Vector({ source: new ol.source.Vector(), style: this.activeLocationHolesLayerStyle });
      this.activeLocationHolesLayer.setZIndex(10);
      this.map.addLayer(this.activeLocationHolesLayer);

			if (this.$route.params.mode == "editGeometry" && this.locCurrentEntry.id) this.plotFeatureHoles();

      this.refreshLayers();

			/* On centre sur l'ensemble des lieux */
			if (!ol.extent.isEmpty(this.locationLayerSource.getExtent())) {
				this.map.getView().fit(this.locationLayerSource.getExtent());
			}
		},


		// plot all the cropfields
		// useful when we want to refresh the map
		async plotFields() {
			if (!this.layerSource) return;

			let geojson = {
				type: "FeatureCollection",
				features: [],
			};

			let geoms = Object.values(this.geometries3857);
			geojson.features = geoms;

			let t = this;

			this.layerSource.clear();

			await this.layerSource.addFeatures(new ol.format.GeoJSON().readFeatures(geojson));

			this.layerSource.dispatchEvent("change");

		},

		// plot all the locations
		// useful when we want to refresh the map
		async plotLocationFields() {
			if (!this.locationLayerSource) return;

			let geojson = {
				type: "FeatureCollection",
				features: [],
			};

			let geoms = Object.values(this.locGeometries3857);
			geojson.features = geoms;

			let t = this;

			this.locationLayerSource.clear();

			await this.locationLayerSource.addFeatures(new ol.format.GeoJSON().readFeatures(geojson));

			this.locationLayerSource.dispatchEvent("change");

		},

		/**
		 * @description affiche les couches sélectionnées par l'utilisateur
		 */
		refreshLayers() {
			for (let i = 0; i < this.layerFilters.length; i++) {
				if (!this.mapLayers[i]) return;
				this.mapLayers[i].setVisible(this.layerFilters[i].value);

			}
		},

		/**
		 * @description modifie la forme du pointer lorque l'on est sur une parcelle ou un lieu
		 * @param {*} evt 
		 */
		onMove(evt) {
      let pixel = this.map.getEventPixel(evt.originalEvent);
      let hitFeatures = this.map.getFeaturesAtPixel(pixel);
      this.map.getViewport().style.cursor = hitFeatures.length > 0 ? "pointer" : "";
    },

		/**
		 * @description définit le comportement d'un click sur une géométrie
		 * @param {*} evt 
		 */
		onClick(evt) {
      // the click behavior and logic

			if (this.holeMode) return;

      let t = this;
      if (this.drawMode) return; // if we draw, we don't want to interact with the map (except to draw), so return


      // otherwise, we check on what we have clicked
      let selectedFeature, selectedLayer;
      this.map.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
        if (layer == t.parcelsLayer) return;

        selectedFeature = feature;
        selectedLayer = layer;
      });

			if (selectedFeature && selectedLayer == this.activeLocationHolesLayer) {
        this.locSelectHole({ hole: selectedFeature.getProperties().index });
        this.activeLocationHolesLayer.getSource().dispatchEvent("change");
        return;
      }

      this.resetFeatureHoles();

      if (!selectedFeature || !selectedFeature.getProperties()) {
        return;
      }

      this.activeFeature = selectedFeature;

      let ppties = selectedFeature.getProperties();

			this.onSelect(ppties.id);
			this.plotFeatureHoles(ppties.id);

			return;

    },

		/**
		 * @description lorsqu'un lieu est sélectionné, ses informations s'affichent dans le panneau de détail
		 * @param {*} rowId 
		 */
		async onSelect(rowId) {
      rowId = parseInt(rowId);

      if (this.locationLayerSource) {
				let t = this;
				setTimeout(function(){
					console.log(t.currentLocation);
					t.locationLayerSource.dispatchEvent("change");
				}, 1000);
			}
      if (this.$route.params[this.routes.params.id] !== rowId) {
        this.showDetails(rowId);
      }
    },

		/**
		 * @description affiche les informations sur un lieu
		 * @param {Number} id 
		 */
		showDetails(id) {
      let params = {};
      params[this.routes.params.id] = id;

      if (this.$route.name != "location.map.details" || this.$route.params[this.routes.params.id] != id) {
        this.$router.push({ name: "location.map.details", params: params });
			}
    },

    getGeometryFromLayer(layer) {
      let features = layer.getSource().getFeatures();
      console.log(features);

      if (features.length == 0 || !features[0]) return;
      return this.getGeometryFromFeature(features[0]);
    },
    getGeometryFromFeature(feature) {
      let writer = new ol.format.GeoJSON();
      let geom = writer.writeFeature(feature);
      return JSON.parse(geom);
    },

		writeGeometryToLayer(geojson, layer, clear = false) {
      if (clear) {
        layer.getSource().clear();
      }
      try {
        let activeFeature = new ol.format.GeoJSON().readFeature(geojson);
        layer.getSource().addFeature(activeFeature);
      } catch (error) {}
      layer.dispatchEvent("change");
    },

		// create holes
		showActiveLocation() {
			let id = this.currentLocation.id;

			let geojson = this.locGeometries3857.find((g) => g.properties.id == id);

			this.writeGeometryToLayer(geojson, this.activeLocationLayer, true);

			this.activeLocationLayer.getSource().dispatchEvent("change");
    },

    debounce(callback, delay) {
      let t = this;
      return function () {
        var args = arguments;
        var context = this;
        clearTimeout(t.debounceTimer);
        t.debounceTimer = setTimeout(function () {
          callback.apply(context, args);
        }, delay);
      };
    },

		editGeometry() {
			this.$router
				.push({ name: "location.map.details", params: { location_id: this.locCurrentEntry.id, mode: "editGeometry" } })
				.catch((error) => {
				if (error.name != "NavigationDuplicated") {
					throw error;
				}
			});
		},

		refreshMap() {
			this.activeLocationLayer.getSource().dispatchEvent("change");
			this.locationLayerSource.dispatchEvent("change");
		},
	},
};
