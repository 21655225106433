import ProductsAliasesApi from "@/api/productsAliases.api";

export const PRODUCTS_ALIASES_LOADING = "LOAD PRODUCTS ALIASES";
export const GET_PRODUCTS_ALIASES = "GET PRODUCTS ALIASES BY PRODUCTTYPE ID";
export const RESEARCH_PRODUCTS_ALIASES = "RESEARCH PRODUCTS ALIASES BY NAME";
export const CLEAN_RESEARCH_RESULTS = "CLEAN RESEARCH RESULTS";
export const GET_OTHER_FEATURES="GET OTHER FEATURES";

export default {
  state: {
    researchResults: [],
    productsAliases: [],
    productsAliases_loaded: true,
    otherFeatures: [],
  },
  getters: {
    productsAliases: state => state.productsAliases,
    researchResults: state => state.researchResults,
    otherFeatures: state => state.otherFeatures,
  },
  mutations: {
    [GET_PRODUCTS_ALIASES]: (state, payload) => {
      state.productsAliases = payload.productsAliases;
      state.researchResults = payload.productsAliases;
    },

    [RESEARCH_PRODUCTS_ALIASES]: (state, payload) => {
      state.researchResults = payload.results;
    },

    [CLEAN_RESEARCH_RESULTS]: state => {
      state.researchResults = [];
      state.productsAliases = [];
    },

    [PRODUCTS_ALIASES_LOADING]: (state, status) => {
      state.productsAliases_loaded = !status;
    },
    [GET_OTHER_FEATURES]: (state, payload) => {
      state.otherFeatures = payload;
    },
  },
  actions: {
    async getProductsAliases({ commit }, payload) {
      commit(PRODUCTS_ALIASES_LOADING, true);
      const response = await ProductsAliasesApi.getProductsAliases(payload.id);
      commit(GET_PRODUCTS_ALIASES, { productsAliases: response.data });
      commit(PRODUCTS_ALIASES_LOADING, false);
      return response.data;
    },

    async researchProductsAliases({ commit }, payload) {
      commit(PRODUCTS_ALIASES_LOADING, true);
      const response = await ProductsAliasesApi.getProductsAliases(
        payload.idProductType,
        payload.idProductFamily,
        payload.name,
      );
      commit(RESEARCH_PRODUCTS_ALIASES, { results: response.data });
      commit(PRODUCTS_ALIASES_LOADING, false);
      return response.data;
    },
    async getOtherFeatures({commit}, payload){
      const response = await ProductsAliasesApi.getOtherFeatures(payload);
      commit(GET_OTHER_FEATURES, response.data);
      return response.data;
    },
  },
};
