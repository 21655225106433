import ServicesApi from "@/api/services.api";

export const SERVICES = "GET SERVICES";
export const SERVICES_LOADING = "LOAD SERVICES";

export default {
  state: {
    services: [],
    services_loading: false
  },
  getters: {
    services: state => state.services,
  },
  mutations: {
    [SERVICES]: (state, payload) => {
        state.services = payload;
    },

    [SERVICES_LOADING]: (state, status) => {
    state.services_loading = !status;
    },
  },
  actions: {
    async getServices({ commit, dispatch }, payload) {
      commit(SERVICES_LOADING, true);
        
      const query = {
            activityType: payload.activityType || "",
            machines: payload.machines?.length ? payload.machines.join(",") : ""
      };

      try {
        //récuperation des services
        const response = await ServicesApi.getServices(query);
        commit(SERVICES, response.data);

      } catch (error) {
        
      }
      commit(SERVICES_LOADING, false);
    },
  },
};
