<template>
<!-- Ce composant affiche la culture suivante dans le panneau d'édition d'une parcelle -->
  <div>
    <div class="bg-white text-infos" v-if="currentEntry.nextCropFields && currentEntry.nextCropFields.length != 0">
      <div v-for="cf in currentEntry.nextCropFields" :key="cf.id">
        {{ cf.crop_name }}
      </div>
    </div>
    <div v-else class="bg-white text-infos">
      {{ $t("is_empty") }}
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {

  computed: {
    ...mapGetters({
			currentEntry: "cropfield/currentEntry",
    }),
  },
	
  
};
</script>

<style lang="scss"></style>
