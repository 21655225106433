<template>
  <div>
    <!-- Treatment Component : History for Agro EDI -->
    <!-- Displays a checkbox and a Campaign selector -->
    <b-form-checkbox v-model="exported">
      <span class="dropdown-option"> {{ $t("commons.exports_reports.export_history") }} </span>
    </b-form-checkbox>
		<div v-show="exported" class="ml-4 mt-2">
			<h4 class="subtitle-form mb-2">
				{{ $t("commons.exports_reports.all_campaigns_from") }}
			</h4>
    	<b-form-select
				class="dropdown-option"
				v-model="campaignChoice"
				:options="campaignsOptions"
				text-field="name"
				value-field="name"
			></b-form-select>
		</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import TreatmentFormPartMixin from "@/components/commons/treatments/TreatmentFormPartMixin";
import { TreatmentFormPart } from "@/models/treatments/Treatment.js";

export default {
  mixins: [TreatmentFormPartMixin],
  data() {
    return {
			exported: false,
			campaignChoice: null,
    };
  },
	mounted() {
		this.initChoice();
	},
  methods: {
    validateForm() {
      this.isValid = true;

			let historyCount = 0;
			if (this.exported) {
				historyCount = parseInt(this.currentCampaign.name) - parseInt(this.campaignChoice)
			}
			return new TreatmentFormPart("history", historyCount);
    },
		async initChoice() {
			// On sélectionne par défaut la campagne la plus récente de la liste
			if(this.campaignsOptions.length > 0) {
				this.campaignChoice = this.campaignsOptions[this.campaignsOptions.length - 1].name;
			}
		},
  },
  computed: {
    ...mapGetters({
			campaigns: "campaigns",
			currentCampaign: "getCampaign",
			currentFarm: "farm/currentFarm"
    }),
    campaignsOptions() {
			// On ne propose que les campagnes antérieures à la campagne actuelle
			// La liste est limitée à 9 éléments (restriction agro edi)
      return this.campaigns.filter(el => el.startDate < this.currentCampaign.startDate).toSorted((a, b) => {
				if(a.name < b.name)
					return -1;
				else
					return 1;
			}).slice(-9)
    },
  },
  watch: {
    currentFarm: {
			handler() {
				this.initChoice();
			}
    },
		campaignsOptions: {
			handler() {
				this.initChoice();
			}
		}
  },
};
</script>

<style>
.dropdown-option {
		font-size: 13.5px !important;
	}
</style>
