<template>
  <!-- Name and type form, shown when the geometry has been drawn -->
  <b-row>
    <b-col class="mb-3">
      <h4 class="subtitle-form mb-2 text-nowrap">{{ $t("locations.list.name") }}</h4>
      <EditableCellText
        @updateRow="updateRow"
        :rowId="currentEntry.id"
        :cellData="currentEntry.name"
        field="name"
        :required="true"
      />
    </b-col>
    <b-col>
      <h4 class="subtitle-form mb-2 text-nowrap">{{ $t("locations.list.type") }}</h4>
      <Dropdown
				ref="dropdown"
				class="shape-custom-select"
				:options="locationTypesWithGeometry"
				label="designation"
        :value="currentLocationType"
				@input="selectedType => addType(selectedType)"
				required
    	/>
    </b-col>
  </b-row>
</template>

<script>
import EditableCellText from "@/components/commons/editable/EditableCellText.vue";
import Dropdown from "@/components/harvestStorage/edit/Dropdown.vue";

import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  components: { EditableCellText, Dropdown },
  async mounted() {
		await this.$store.dispatch("getLocationTypesWithGeometry");
    if(this.$route.name == "location.map.new")
      await this.$store.dispatch("resetLocationType");
    this.addType(this.currentLocationType);
	},
  methods: {
    updateRow(id, data) {
      this.$store.dispatch("location/setEditedEntryValue", data);
    },

		/**
     * @description stocke le type sélectionné dans currentEditedEntry
     * @param {object} type le type de lieu sélectionné
     */
    async addType(type) {
      if(type) {
        this.currentEditedEntry.locationType = type.id;
        await this.$store.dispatch("getOneLocationType", {id: type.id});
      }else {
        this.currentEditedEntry.locationType = null;
        await this.$store.dispatch("resetLocationType");
      }
    },
  },
  computed: {
    ...mapGetters({
      currentEntry: "location/currentEntry",
      currentEditedEntry: "location/editedEntry",
      currentLocationType: "currentLocationType",
      locationTypesWithGeometry: "locationTypesWithGeometry",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
