import Gateway from "./base";

export default class CampaignApi {

  /**
   * Get one island by id
   *
   * @param id
   */
  static getIsland(id) {
    return Gateway.get(`/api/islands/${id}/`);
  }

}
