<template>
<!-- Ce composant permet de créer un champ d'entrée numérique acceptant le point et la virgule -->
  <div>
		<b-input
			:ref="inputRef"
			size="sm"
			:name="name"
			class="width-input-quantity"
			v-model="content"
			@input="onInput"
			@blur="onBlur"
			type="text"
			:placeholder="placeholder"
			:required="required"
			:disabled="disabled"
			:class="{maxExceeded: warningState, 'text-right': align_right}"
			v-b-tooltip.hover :title="warningState ? warningText : $t(textHover)"
		>
		</b-input>

  </div>
</template>

<script>
import _ from "lodash";
import { formatNumber } from "@/utils/commons";

export default {
	data() {
		return {
			charList: "0123456789.,",
			content: "",
			numericValue: null,
			warningState: false
		}
	},

	mounted() {
		this.setInitialValue()
	},

	methods: {
		/**
		 * Cette fonction convertit une chaine de caractère formattée représentant un nombre en float
		 */
		isValid() {
			return !(
				this.numericValue != null && (
					this.min != null && this.numericValue < this.min
					|| this.max != null && this.numericValue > this.max
				)
			)
		},
		parseFormattedNumber(input) {
			if (input == "" || input == "-") return null;
			const locale = navigator.language || 'en-US';
			const decimalSeparator = new Intl.NumberFormat(locale).format(1.1)[1]; // Obtenir le séparateur décimal
    		const thousandSeparator = new Intl.NumberFormat(locale).format(1000)[1]; // Obtenir le séparateur de milliers

			// Remplace les séparateurs de milliers par une chaîne vide et les décimales par "."
			var normalizedInput = input
				.replace(new RegExp(`[${thousandSeparator}]`, 'g'), '') // Supprime les séparateurs de milliers
				.replace(decimalSeparator, '.'); // Convertit le séparateur décimal en "."

			// Suppression des caractères non valides
			normalizedInput = normalizedInput.replace(/[^0-9.-]/g, '')

			return parseFloat(normalizedInput);
		},
		/**
		 * Cette fonction met en forme une chaine qui représente un nombre (en conservant les 0 non significatifs)
		 * en limitant le nombre de décimales
		 */
		formatStrNumber(value_str){
			// Si on a saisi un signe - en premier, on le garde tel quel si les valeurs négatives sont autorisées
			if (value_str == "-") {
				if (this.min != null && this.min >= 0) return "";
				return value_str;
			}
			// on met de côté le signe pourle rajouter à la fin, pour permettre de saisir "-0"
			var sign = "";
			if (value_str[0] == "-") {
				sign = "-";
				value_str = value_str.slice(1);
			}

			const locale = navigator.language || 'en-US';
			const decimalSeparator = new Intl.NumberFormat(locale).format(1.1)[1]; // Obtenir le séparateur décimal
    		const thousandSeparator = new Intl.NumberFormat(locale).format(1000)[1]; // Obtenir le séparateur de milliers

			// Remplace les séparateurs de milliers par une chaîne vide et les décimales par "."
			var normalizedInput = value_str
				.replace(new RegExp(`[${thousandSeparator}]`, 'g'), '') // Supprime les séparateurs de milliers
				.replace(decimalSeparator, '.'); // Convertit le séparateur décimal en "."

				// Suppression des caractères non valides
			normalizedInput = normalizedInput.replace(/[^0-9.-]/g, '')
			if (normalizedInput == "") return "";

			const parts = normalizedInput.split(".");
			let string = "";
			let integer_part = "";
			let decimal_part = "";
            if (parts.length >= 1) integer_part = new Intl.NumberFormat(locale).format(parseFloat(parts[0]))
			if (parts.length >= 2) {
				decimal_part = parts[1].substring(0, this.numberOfDigits);
				string = integer_part + decimalSeparator + decimal_part
			}
			else {
				string = integer_part
				if (normalizedInput.indexOf(".") > 0) string += decimalSeparator
			}

			return sign + string;
		},
		/**
		 * Cette fonction est appélée lorsque le champ de saisie est modifié
		 * Elle permet de controler la saisie des caractères dans l'input
		 * Autorise uniquement la saisie des chiffres, de la virgule et du point
		 */
		onInput(input) {
			let value_str = this.formatStrNumber(input);
			this.content = value_str;
			this.numericValue = this.parseFormattedNumber(value_str);
			// Vérification de la valeur par rapport à la page autorisée
			if (this.isValid()) {
				this.warningState = false;
				this.$refs.input.setCustomValidity("");
			}
			else {
				this.warningState = true;
				if (this.controlValidity) this.$refs.input.setCustomValidity(this.$t(this.warningText));
			}
			this.$refs.input.vModelValue = this.content;
			this.$refs.input.localValue = this.content;
			this.$emit("input", this.numericValue);
		},

		/**
		 * Blur event is triggered when this input loses user focus
		 */
		onBlur() {
			this.$emit("blur", this.numericValue);
		},

		setInitialValue() {
			this.content = (this.value !== null && !Number.isNaN(this.value))  ? formatNumber(this.value, this.numberOfDigits) : "";
			this.numericValue = this.parseFormattedNumber(this.content);
		}
	},

	props: {
		inputRef: {
			type: String,
			default: "",
		},
		name: {
			type: String,
			default: "",
		},
		value: {
			type: Number,
			default: null,
		},
		warningText: {
			type: String,
			default: function () {return this.$t("components.NumericInput.incorrect-value")},
		},
		min: {
			type: Number,
			default: null,
		},
		max: {
			type: Number,
			default: null,
		},
		controlValidity: {  // indique si l'enregistrement doit être bloqué lorsque la valeur est hors plage
			type: Boolean,
			default: false
		},
		label: {
			type: String,
			default: "",
		},
		placeholder: {
			type: String,
			default: "",
		},
		required: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		textHover: {
			type: String,
			default: "",
		},
		align_right: {
			type: Boolean,
			default: false,
		},
		numberOfDigits: {
			type: Number,
			default: 4
		}
	},

	watch: {
		value() {
			this.setInitialValue()
		}
	}
}
</script>
<style lang="scss" scoped>
	.form-control:disabled {
		background-color: $disabled-color;
	}
	.maxExceeded {
		border-color: $warning-color;
		background-color: #F09D0B2F;
	}
</style>