<template>
  <div>
    <div class="toolBox">
      <!-- Bouton pour afficher les couches cartographiques -->
      <b-row>
        <b-col>
          <img
            class="toolOption"
            :src="layerBox"
            alt=""
            :title="$t('cropfields.map.layerbox_help_button')"
            @click="showModal"
          />
        </b-col>
      </b-row>
    </div>
    <!-- Modal affichant la liste des couches -->
    <div v-if="modalVisible" class="modal-container">
      <div class="modal-overlay" @click="hideModal"></div>
      <div class="modal-content">
        <!-- Contenu de la modal -->
        <div>
          <div class="title-form">{{ $t('cropfields.map.layerbox_title') }}</div>
          <div class="p-3">
            <b-row
              v-for="filter in layerFilters"
              :key="filter.name"
            >
              <b-col class="text-infos-without-bg py-0">
                <b-form-checkbox
                  v-model="filter.value"
                >
                  {{filter.name}}
                </b-form-checkbox>
              </b-col>
            </b-row>
          </div>
          <!-- Bouton pour fermer la modal -->
          <div class="justify-content-end align-items-center m-0 text-right">
						<b-button class="btn btn-default" size="sm" @click="hideModal">
							{{ $t("ok") }}
						</b-button>
					</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import layerBox from "@/assets/images/layers_icon.svg";
export default {
  data() {
    return {
      layerBox: layerBox,
      modalVisible: false
    };
  },
	props: {
		layerFilters: {
			type: Array,
			default: () => []
		}
	},

  methods: {
		showModal() {
      this.modalVisible = true;
		},
		hideModal() {
      this.modalVisible = false;
		}
  },
};
</script>
<style lang="scss" scoped>
.toolBox {
  position: absolute;
  top: 8px;
  padding: 2px;
  left: 25rem;
  background-color: rgba(0, 60, 136, 0.5);
  border-radius: 4px;
  border: 3px solid #ffffff6f;
	width: 31px;
	height: 31px;
}
.toolOption {
  color: #ffffffcf;
  font-size: 0.9rem;
  cursor: pointer;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050; /* Z-index supérieur à celui de la carte pour s'assurer qu'elle apparaît au-dessus */
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fond semi-transparent pour créer l'effet de superposition */
}

.modal-content {
  background-color: white;
  text-align: left;
  padding: 20px;
  border-radius: 5px;
  max-width: 300px; /* Largeur maximale de la modal */
}
</style>