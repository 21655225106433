import AppComponent from "@/views/App.vue";
import HomeRouter from "@/views/home/router";
import NotFoundComponent from "@/components/Notfound.vue";
import ActivityRouter from "@/views/activity/router";
import TourRouter from "@/views/tour/router";
import ParcelRouter from "@/views/cropfields/router";
import InputStockRouter from "@/views/inputStock/router";
import MachinesRouter from "@/views/machines/router";
import CampaignRouter from "@/views/campaign/router";
import FarmRouter from "@/views/farm/router";
import HarvestStorageRouter from "@/views/harvestStorage/router";
import LocationRouter from "@/views/locations/router";
import YieldRouter from "@/views/yield/router";
import AnalysisRouter from "@/views/analysis/router";
import FertilizationRouter from "@/views/fertilization/router";

export const routes = [
  {
    component: AppComponent,
    path: "/",
    name: "root",
    auth: true,
    children: [
      ...HomeRouter,
      ...TourRouter,
      ...ActivityRouter,
      ...FertilizationRouter,
      ...ParcelRouter,
      ...InputStockRouter,
      ...MachinesRouter,
      ...CampaignRouter,
      ...FarmRouter,
      ...HarvestStorageRouter,
      ...YieldRouter,
      ...LocationRouter,
      ...AnalysisRouter,
    ],
  },
  {
    component: NotFoundComponent,
    name: "forbidden",
    path: "/403",
  },
  {
    component: NotFoundComponent,
    path: "*",
  },
];
