import LocationApi from "@/api/location.api";
import LocationTypeApi from "@/api/locationType.api";
import Vue from "vue";

import * as turf from "@turf/turf";

import { project4326, project3857 } from "@/utils/geomatics";

import { EventBus } from "@/eventBus.js";

import { commonGetters, commonMutations, commonActions } from "./commons.js";

export const ALL_LOCATIONS = "GET ALL LOCATIONS";
export const ALL_WAREHOUSES = "GET ALL WAREHOUSES";
export const ALL_GAS_PUMPS = "GET ALL GASPUMPS";
export const ONE_LOCATION = "GET LOCATION BY ID";
export const LOCATION_FILTERS = "ALL LOCATION FILTERS";
export const CREATE_LOCATION = "CREATE LOCATION";
export const UPDATE_LOCATION = "UPDATE LOCATION";
export const DELETE_LOCATION = "DELETE LOCATION";
export const LOCATION_LOADING = "LOAD LOCATION";

export const GET_AVAILABLE_BEACON = "GET AVAILABLE BEACON";

// Manage filter store
export const LOCATION_SET_FILTER = "SET LOCATION FILTER";
export const LOCATION_REMOVE_FILTER = "REMOVE LOCATION FILTER";
export const LOCATION_CLEAN_FILTER = "CLEAN LOCATION FILTER";
export const LOCATION_RESET_FILTER = "RESET LOCATION FILTER LIST";

export default {
  namespaced: true,
  state: {
    // commons
    _entries: {},
    sortFields: [(location) => location.name.toLowerCase()],
    sortOrders: ["asc"],
    currentId: null,
    filterData: {},
    filterSelection: {},
    filters: [],
    currentEntry: {},
    newEntry: {},
    entryUpdated: {},
    entryLoaded: true,
    searchQuery: "",
    entriesChecked: [],
    lastEntryDisplayed: null,
    // others
    editedEntry: {},
    filterSelectionValue: {},
    locations: [],
    warehouses: [],
    gasPumps: [],
    locationFilters: [],
    locationFilterSelected: {},
    filteredLocation: {},
    beaconsavailable: [],
    location_loaded: true,
    //geometries: {},
    mapEditMode: false,
    mapCutMode: 0,
    mapCutGeometries: [],
    selectedHole: null,
  },
  getters: {
    ...commonGetters(),
    beaconsavailable: state => state.beaconsavailable,
    currentLocationHasGeometry: (state, getters) =>
      getters.currentEntry != null &&
      getters.currentEntry.geometry != null &&
      getters.currentEntry.geometry.coordinates.length > 0,

    geometries: (state, getters) =>
      getters.entries.filter((e) => e.geometry != null).map((e) => turf.feature(e.geometry, { id: e.id })),
    geometries3857: (state, getters) => getters.geometries.map((g) => project3857(g)),
    editedEntry: (state) => state.editedEntry,
    locations: (state) => state.locations,
    warehouses: (state) => state.warehouses,
    gasPumps: (state) => state.gasPumps,
    mapEditMode: (state) => state.mapEditMode,
    mapCutMode: (state) => state.mapCutMode,
    mapCutGeometries: (state) => state.mapCutGeometries,
    
    getLocationFilterSelected: (state) => state.locationFilterSelected,

    checkedEntries: (state, getters) => getters.entries.filter((e) => state.entriesChecked.includes(e.id)),
    checkedGeometries: (state, getters) =>
      getters.geometries.filter((g) => state.entriesChecked.includes(g.properties.id)),
    checkedGeometries3857: (state, getters) =>
      getters.geometries3857.filter((g) => state.entriesChecked.includes(g.properties.id)),
    selectedHole: (state) => state.selectedHole,
  },
  mutations: {
    ...commonMutations(),
    [ALL_LOCATIONS]: (state, locations) => {
      state.locations = locations;
    },

    [ALL_WAREHOUSES]: (state, warehouses) => {
      state.warehouses = warehouses;
    },
    
    [ALL_GAS_PUMPS]: (state, gasPumps) => {
      state.gasPumps = gasPumps
    },

    [LOCATION_LOADING]: (state, status) => {
      state.location_loaded = !status;
    },

    [GET_AVAILABLE_BEACON]: (state, beacons) => {
      state.beaconsavailable = beacons;
    },

    setFilterSelectionValue: (state, { name, value }) => {
      Vue.set(state.filterSelectionValue, name, value);
    },

    setEditedEntryValue: (state, data) => {
      state.editedEntry = { ...state.editedEntry, ...data };
    },

    resetEditedEntry: (state) => {
      state.editedEntry = {};
    },

    resetEntriesChecked: (state) => {
      state.entriesChecked = [];
    },

    removeCheckedEntry: (state, { id }) => {
      state.entriesChecked = [...state.entriesChecked.filter((e) => e.id != id)];
    },

    setMapEditMode: (state, mode) => {
      state.mapEditMode = mode;
    },

    setMapCutMode: (state, mode) => {
      state.mapCutMode = mode;
    },

    setMapCutGeometries: (state, geometries) => {
      state.mapCutGeometries = geometries;
    },

    selectHole: (state, { hole }) => {
      state.selectedHole = hole;
    },

    resetSelectedHole: (state) => {
      state.selectedHole = null;
    },
  },

  actions: {
    ...commonActions(),
    async fetchEntries({ commit, dispatch, state, rootGetters }, { withGeometries, silent } = {}) {
      if (!rootGetters.getCampaign.id) return;
      commit(LOCATION_LOADING, true);

      if (!silent) {
        await dispatch("refreshFarmFilterSelection");
      }
      const locationType = await dispatch("getFilterValueId", "locationType");
      let payload = {};

      if (withGeometries) {
        payload["detail"] = true;
      }
      payload["campaign"] = rootGetters.getCampaign.id;

      if (rootGetters["farm/currentFarm"]) {
        payload["farm_id"] = rootGetters["farm/currentFarm"].id;
      }
      payload["locationType"] = locationType;

      const response = await LocationApi.getAllLocations(payload);

      if (!silent) {
        commit("setEntries", { entries: response.data });

        dispatch("initFilters");
      }

      commit(LOCATION_LOADING, false);

      return response.data;
    },

    async getOneLocation({ dispatch, commit }, { id }) {
      commit(LOCATION_LOADING, true);
      const response = await LocationApi.getLocation(id);
      const location = response.data;

      commit("setCurrentId", { id: id });

      commit("updateEntry", { id: id, data: location });

      commit(LOCATION_LOADING, false);
      return response.data;
    },


    async convertGeomToGeoJSON({}, geometry) {
      // remove srid part
      geometry = geometry.split(";");
      if (geometry.length < 1) return;

      geometry = geometry[1];
      let reader = new ol.format.WKT();

      let out = reader.readFeature(geometry);

      let writer = new ol.format.GeoJSON({
        dataProjection: "EPSG:3857",
        featureProjection: "EPSG:4326",
      });
      let result = writer.writeFeatureObject(out);

      return result;
    },

    async updateEntry({ commit, dispatch }, { id, data }) {
      commit(LOCATION_LOADING, true);

      let result = await LocationApi.updateLocation(id, data);
      commit("updateEntry", { id: id, data: result.data });

      EventBus.$emit("plotLocationFields");
      commit(LOCATION_LOADING, false);
    },

    async updateGeometry({ commit, dispatch }, { id, geometry }) {
      let geometry4326 = project4326(geometry);

      dispatch("updateEntry", { id: id, data: geometry4326 });
    },

    async deleteEntry({ commit }, { id }) {
      commit(LOCATION_LOADING, true);

      commit("removeCheckedEntry", { id: id });

      await LocationApi.deleteLocation(id);
      commit("deleteEntry", { id });

      commit(LOCATION_LOADING, false);
    },

    async deleteEntryOfStore({ commit }, { id }) {
      commit(LOCATION_LOADING, true);

      commit("removeCheckedEntry", { id: id });
      commit("deleteEntry", { id });

      commit(LOCATION_LOADING, false);
    },

    async createEntry({ state, commit, dispatch, rootGetters }, { entry }) {
      commit(LOCATION_LOADING, true);

      let newEntry = await LocationApi.createLocation(entry);
      newEntry = newEntry.data;

      commit("addEntry", newEntry);

      commit("setCurrentId", { id: newEntry.id });

      commit(LOCATION_LOADING, false);
    },

    async updateCheckedEntries({ state, dispatch, commit }) {
      if (!state.currentId && state.entriesChecked.length == 0) {
        await dispatch("createEntry", { entry: state.editedEntry });
        dispatch("fetchEntries");
        commit("resetEditedEntry");

        return;
      }

      await dispatch("updateEntry", { id: state.currentId, data: state.editedEntry });
      dispatch("fetchEntries");
      commit("resetEditedEntry");

    },

    async saveEditedEntry({ state, dispatch, commit }) {
      if (state.currentId) await dispatch("updateEntry", { id: state.currentId, data: state.editedEntry });
      else await dispatch("createEntry", { entry: state.editedEntry });

      dispatch("fetchEntries");
      commit("resetEditedEntry");
    },

    async updateFilterSelection({ commit }, { filterName, elementId }) {
      await commit("setFilter", {
        filterName: filterName,
        elementId: elementId,
      });
    },

    async fetchGeometry({ dispatch }, entryId) {
      let result = await dispatch("getOneLocation", { id: entryId });
      if (!result.geometry) return;
    },

    async setEditedEntryValue({ commit }, data) {
      await commit("setEditedEntryValue", data);
    },

    async getAllFiltersLocation({ commit, rootGetters }, { param, campaign }) {
      commit(LOCATION_LOADING, true);

      let currentFarm = rootGetters["farm/currentFarm"];
      if (currentFarm) currentFarm = currentFarm.id;

      const response = await LocationApi.getLocationsFilters(param, campaign, currentFarm);
      commit("setFilterData", { name: param, elements: response.data });
      commit(LOCATION_LOADING, false);
    },

    async initFilters({ dispatch, state, rootGetters }) {
      // do better
      state.filters.forEach(async (filter) => {
        await dispatch("getAllFiltersLocation", {
          param: filter.name,
          campaign: rootGetters.getCampaign.id,
        });
      });
    },

    async getAllLocations({ commit }) {
      commit(LOCATION_LOADING, true);
      const response = await LocationApi.getAllLocations();
      commit(ALL_LOCATIONS, response.data);
      commit(LOCATION_LOADING, false);
      return response.data;
    },

    async refreshFarmFilterSelection({ commit, state, rootGetters }) {
      const filterName = "farm_id";

      if (rootGetters["farm/currentFarm"] && state.filterData[filterName] == rootGetters["farm/currentFarm"].id) return;
      if (!rootGetters["farm/currentFarm"]) return;

      const farmId = rootGetters["farm/currentFarm"].id;

      if (!state.filterData[filterName]) return;

      const elementId = state.filterData[filterName].findIndex((e) => e.id == farmId);

      if (elementId >= 0)
        await commit("setFilter", { filterName: filterName, elementId: elementId, force: true });
    },

    async getBeacons({ commit }) {
      commit(LOCATION_LOADING, true);
      const response = await LocationApi.getBeacons();
      commit(GET_AVAILABLE_BEACON, response.data);
      commit(LOCATION_LOADING, false);
      return response.data;
    },

    async getBeaconsAvailable({ commit }, payload) {
      commit(LOCATION_LOADING, true);
      const response = await LocationApi.getBeaconsAvailable(payload.id);
      commit(GET_AVAILABLE_BEACON, response.data);
      commit(LOCATION_LOADING, false);
      return response.data;
    },

    async getWarehouses({ commit }) {
      commit(LOCATION_LOADING, true);
      let param = {locationType__code: "entrep,coop"};
      const response = await LocationApi.getAllLocations(param);
      commit(ALL_WAREHOUSES, response.data);
      commit(LOCATION_LOADING, false);
      return response.data;
    },

    async getGasPumps({ commit }) {
      commit(LOCATION_LOADING, true);
      let param = {locationType__code: "fuel"};
      const response = await LocationApi.getAllLocations(param);
      commit(ALL_GAS_PUMPS, response.data);
      commit(LOCATION_LOADING, false);
      return response.data;
    }
  },
}

