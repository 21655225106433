<template>
  <!-- Treatment Component : Activity Choice -->
  <!-- Displays an activity selector. We can choose either all activities since last export, or a date range -->
  <div class="mt-4">
    <h4 class="subtitle-form mb-2">
      {{ $t("commons.exports_reports.activity_selection_title") }}
    </h4>
    <b-container class="bg-white text-infos">
      <!-- display the choices : new activities only, all activites, all activites on period -->
      <b-row>
        <b-col>
          <b-form-radio-group
            v-model="activityChoice"
            :options="activityOptionsObjects"
            name="activityChoice"
            class="bg-white text-infos"
            stacked
          ></b-form-radio-group>
        </b-col>
      </b-row>

      <!-- if we are on "onPeriod" mode, we display dates pickers -->
      <b-row class="mt-1" v-if="onPeriod">
        <b-col cols="2"></b-col>
        <b-col cols="4" class="d-flex align-items-center justify-content-end text-right text-nowrap">{{
          $t("commons.exports_reports.start_date")
        }}</b-col>
        <b-col cols="6">
          <input 
            type="date"
            v-model="startDate"
            placeholder="yyyy-mm-dd"
            class="mb-2"
          />
        </b-col>
      </b-row>
      <b-row class="mt-1" v-if="onPeriod">
        <b-col cols="2"></b-col>
        <b-col cols="4" class="d-flex align-items-center justify-content-end text-right text-nowrap">{{
          $t("commons.exports_reports.end_date")
        }}</b-col>
        <b-col cols="6">
          <input 
            type="date"
            v-model="endDate"
            placeholder="yyyy-mm-dd"
            class="mb-2"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { dateToEnglishDashString } from "@/utils/commons";

import TreatmentFormPartMixin from "@/components/commons/treatments/TreatmentFormPartMixin";
import { TreatmentFormPart } from "@/models/treatments/Treatment.js";

// 'newOnly' means "only the new activities". It will send 'newOnly = true' to the API
const ACTIVITY_NEW = "newOnly";
// 'onPeriod' means "all activities on given period". In that case it will send a startDate and endDate to the API
const ACTIVITY_PERIOD = "onPeriod";
// 'all' means "all activities". It will send 'all = true' to the API
const ACTIVITY_ALL = "all";

export default {
  mixins: [TreatmentFormPartMixin],
  data() {
    return {
      // the selected option, by default null but set up in mounted()
      activityChoice: null,
      // all the possible options. It can be narrowed with the 'activityOptions' props
      allOptions: [
        { text: this.$t("commons.exports_reports.new_activity_since_last_export"), value: ACTIVITY_NEW },
        { text: this.$t("commons.exports_reports.all_activities"), value: ACTIVITY_ALL },
        { text: this.$t("commons.exports_reports.all_activities_on_period"), value: ACTIVITY_PERIOD },
      ],
      startDate: dateToEnglishDashString(new Date()),
      endDate: dateToEnglishDashString(new Date()),
    };
  },
  mounted() {
    // on component load, set the default value as the first in the list
    this.activityChoice = this.activityOptions[0];
  },
  methods: {
    validateForm() {
      this.isValid = true;

      // set parameters (newOnly, all, onPeriod) and their values (true/false)
      const result = [
        new TreatmentFormPart(ACTIVITY_NEW, this.newOnly),
        new TreatmentFormPart(ACTIVITY_ALL, this.all),
        new TreatmentFormPart(ACTIVITY_PERIOD, this.onPeriod),
      ];

      // if we are on period, add start and end date
      if (this.onPeriod) {
        result.push(new TreatmentFormPart("startDate", this.startDate));
        result.push(new TreatmentFormPart("endDate", this.endDate));
      }

      return result;
    },
  },
  computed: {
    activityOptionsObjects() {
      return this.activityOptions.map((option) => this.allOptions.find((o) => o.value == option));
    },
    all() {
      return this.activityChoice == ACTIVITY_ALL;
    },
    newOnly() {
      return this.activityChoice == ACTIVITY_NEW;
    },
    onPeriod() {
      return this.activityChoice == ACTIVITY_PERIOD;
    },
  },
  props: {
    activityOptions: {
      type: Array,
      default: () => [ACTIVITY_NEW, ACTIVITY_ALL, ACTIVITY_PERIOD],
    },
  },
};
</script>

<style></style>
