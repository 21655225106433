import EditFarm from "./EditFarm.vue";

export default [
  {
    component: EditFarm,
    path: "/farm/new",
    name: "farm.new"
  },
  {
    component: EditFarm,
    path: "/farm/edit-current",
    name: "farm.edit-current"
  },
];
