import { i18n } from "@/i18n.js";

import { t, comp, choice, container, storeGet, storeDispatch, storeCommit } from "@/views/common/details/commons";

const storeEditAction = "yield/setEditedEntryValue";

/* Read-only Detail View */
export const yieldDetail = async ({ entry }) => {

  storeCommit("yield/setLastEntryDisplayed", entry.id);

  return {
    title: entry.cropfield_name,
    actions: {
      delete: ({ entry }) => storeDispatch("yield/deleteEntry", { id: entry.id }),
    },
    rows: [
      comp({
        component: "FieldInfo",
        title: i18n.t("yield.list.product"),
        props: { value: entry.variety_name},
        cols: 6
      }),
      comp({
        component: "FieldInfo",
        title: i18n.t("yield.list.yield"),
        props: { value: entry.theYield, unit: entry.unit_unit },
        cols: 6
      }),
      comp({
        component: "FieldInfo",
        title: i18n.t("yield.details.surface"),
        props: {value: entry.surface, unit: "ha"},
        cols: 6
      }),
      comp({
        component: "DateInfo",
        title: i18n.t("yield.list.harvest_date"),
        props: { value: entry.harvestDate, empty: i18n.t("harvestStorage.details.no_date_listed"), onlyDate: true },
        cols: 6
      }),
    ],
  };
};

/* Edit Detail View */
export const yieldEdit = async ({ entry, editedEntry, title, subtitle, newMode }) => {

  await storeDispatch("yield/getYieldForm");
  await storeDispatch("getNitrogenDoseUnits", { forYield: true });

  if (entry.id) storeCommit("yield/setLastEntryDisplayed", entry.id);

  return {
    title: title || entry.cropfield_name,
    actions: {
      save: async () => await storeDispatch("yield/updateEditedEntry"),
      delete: ({ entry }) => storeDispatch("yield/deleteEntry", { id: entry.id }),
    },
    rows: [
      comp("CropfieldSelect"),
      comp("ProductSelect"),
      comp({
        component: "EditYield",
        title: i18n.t("yield.list.yield"),
        cols: 6
      }),
      comp({
        component: "EditUnitYield",
        title: i18n.t("harvestStorage.list.unit"),
        cols: 6
      }),
      comp({
        component: "EditSurface",
        title: i18n.t("yield.list.harvested_surface"),
        cols: 6
      }),
      comp({
        component: "EditHarvestDate",
        props: { onlyDate: true },
        title: i18n.t("yield.list.harvest_date"),
        cols: 6
      }),
      
    ],
  };
};

/* New View
*/
export const yieldNew = ({ entry, editedEntry }) => {
  storeCommit("yield/resetEditedEntry");
  storeCommit("yield/resetCurrentId");
  const title = i18n.t("yield.add.title");
  return yieldEdit({ entry, editedEntry, title });
};


