import * as turf from "@turf/turf";

import { appendName } from "@/utils/commons";

//--------------------------------------------------------------------
// Makes a border slice on a polygon, given a start and stop point
// Params :
// -> polygon : turf representation of a polygon, in 4326 projection
// -> start & stop : turf representation of points, in 4326 projection
//--------------------------------------------------------------------
export function borderSlice({ polygon, path, distance, name }) {
  // the center of the polygon
  let centroid = turf.centroid(polygon);

  // used for debug
  // contain all the intersection points
  let allPts = [];

  //--------------------------------------------------------------------
  // Second, we translate each segment one by one
  // and we look for intersection with previous segment
  //--------------------------------------------------------------------

  // all the intersection points between segments
  let points = [];

  // previous segment
  let previousSegment = null;

  // end segment = first and last, the ones that will intersect the polygon
  // this is the scaled version
  let endSegments = [];
  // this is the unscaled version
  let originalEnds = [];

  let segments = turf.lineSegment(path);

  const minSegmentLength = 2
  const scaleFactor = 20

  segments.features.forEach((segment, index) => {
    let len = turf.length(segment) * 1000;

    if (len < minSegmentLength) return;

    let segmentStart = turf.point(turf.getCoords(segment)[0]);
    let segmentStop = turf.point(turf.getCoords(segment)[1]);

    // translate the segment
    let angle = turf.bearing(segmentStart, segmentStop);

    // we try two orientations
    let translatedA = turf.transformTranslate(segment, distance, 90 + angle, {
      units: "meters",
    });
    let translatedB = turf.transformTranslate(segment, distance, -90 + angle, {
      units: "meters",
    });

    let firstPointA = turf.getCoords(translatedA)[0];
    let firstPointB = turf.getCoords(translatedB)[0];

    // in order to know which orientation is the best
    // we check which segment has the greatest length inside the polygon
    let segmentLengthInPolygon = (segment) => {
      let segmentChunks = turf.lineChunk(segment, 0.01, {
        units: "kilometers",
      });
      segmentChunks.features = segmentChunks.features.filter((s) => turf.booleanWithin(s, polygon));
      return turf.length(segmentChunks);
    };

    let translatedALength = segmentLengthInPolygon(translatedA);
    let translatedBLength = segmentLengthInPolygon(translatedB);

    let translated;
    translated = translatedA;
    // in the case both are 0
    if (translatedALength == 0 && translatedBLength == 0) {
      // we use distance to centroid instead
      if (turf.distance(centroid, firstPointA) > turf.distance(centroid, firstPointB)) translated = translatedB;
    } else {
      // otherwise use the length
      if (translatedBLength > translatedALength) translated = translatedB;
    }

    // after we translated our segment, we scale it x100 (by default)
    let scaled = turf.transformScale(translated, scaleFactor);

    // if there is a previous segment
    if (previousSegment) {
      // make intersection
      let inter = turf.lineIntersect(scaled, previousSegment);

      // case if have an intersection
      if (inter.features.length > 0) {
        let interPoint = inter.features[0];
        points.push(interPoint.geometry.coordinates);
        allPts.push(interPoint.geometry.coordinates);
      } else {
        // if we don't have any intersection
        // segments are (almost) parallels
        // in that case we take the middle point

        scaled = turf.lineString([turf.getCoords(previousSegment)[0], turf.getCoords(scaled)[1]])
      }
    }

    // if we are ad the start or end
    if (!previousSegment) {
      endSegments.push(scaled);
      originalEnds.push(translated);
    } else {
      endSegments[1] = scaled;
      originalEnds[1] = translated;
    }

    previousSegment = scaled;
  });



  //--------------------------------------------------------------------
  // Third, we process both end segments
  // the idea is here to split the segment with the intersection inside the polygon
  // and with the polygon border
  //--------------------------------------------------------------------

  if (points.length > 0)
    endSegments.forEach((segment, index) => {
      let splitters = [];

      // take the intersection from previously
      if (index == 0 && points[0]) splitters.push(points[0]);
      else if (points[points.length - 1]) splitters.push(points[points.length - 1]);

      if (splitters.length == 0) return;
      // split our segment
      splitters = turf.multiPoint(splitters);
      let candidates = turf.lineSplit(segment, splitters);

      candidates = candidates.features;

      // in order to find the best part ('best candidate'),
      // we check the overlapping length with the original translated segment
      let maxLength = 0;
      let bestCandidate;
      candidates.forEach((s, i) => {
        let segmentChunks = turf.lineChunk(originalEnds[index], 0.001, {
          units: "kilometers",
        });
        segmentChunks.features = segmentChunks.features.filter((m) => turf.booleanWithin(m, polygon));

        segmentChunks = turf.combine(segmentChunks);

        let overlap = turf.lineOverlap(s, segmentChunks, {
          tolerance: 0.01,
        });

        let len = turf.length(overlap);
        if (len > maxLength) {
          maxLength = len;
          bestCandidate = s;
        }
      });

      if (!bestCandidate) return;

      bestCandidate = bestCandidate.geometry.coordinates;

      if (index == 0) {
        points = [...bestCandidate, ...points];
      } else {
        points = [...points, ...bestCandidate];
      }
    });

  if (points.length == 0) points.push(...previousSegment.geometry.coordinates);

  points = turf.multiPoint(points);
  points = turf.cleanCoords(points);
  points = turf.getCoords(points);

  let border = turf.lineString(points);

  let polys;

  polys = slicePolygon(polygon, border, name);

  //polys = polys.features;

  return [border, originalEnds, polys];
}

export function lineEnds(line) {
  let coords = line.geometry.coordinates;
  return [coords[0], coords[coords.length - 1]];
}

export function findPaths(start, stop, polygon) {
  // the polygon as a line
  let line = turf.polygonToLine(polygon);

  // get the index of both points
  let startIndex = turf.getCoords(line).findIndex((p) => turf.booleanEqual(turf.point(p), start));
  let stopIndex = turf.getCoords(line).findIndex((p) => turf.booleanEqual(turf.point(p), stop));

  // if the start is after the stop, we swap the variables
  if (startIndex > stopIndex) {
    let tmp = start;
    let tmpIndex = startIndex;

    start = stop;
    startIndex = stopIndex;

    stop = tmp;
    stopIndex = tmpIndex;
  }

  // first, we slice from start to stop
  let slice = turf.getCoords(line).slice(startIndex, stopIndex + 1);

  let path = turf.lineString(slice);

  // but we also do the outer path
  let outerSlice = [];
  for (let i = startIndex; i >= 0; i--) outerSlice.push(turf.getCoords(line)[i]);
  for (let i = turf.getCoords(line).length - 2; i >= stopIndex; i--) outerSlice.push(turf.getCoords(line)[i]);
  outerSlice = turf.lineString(outerSlice);

  // // then, we check the lengths and choose the shorter path
  // if (turf.length(outerSlice) < turf.length(path)) {
  //   path = outerSlice;
  // }

  path = turf.cleanCoords(path);
  outerSlice = turf.cleanCoords(outerSlice);

  return [path, outerSlice];
}

export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export function pointEquals(a, b) {
  return a[0] == b[0] && a[1] == b[1];
}

export function stitchSegments(segments) {
  let iterations = 20;

  for (let k = 0; k < iterations; k++) {
    let buffer = [];
    for (let i = 0; i < segments.length; i += 2) {
      let a = segments[i];

      if (i + 1 == segments.length) {
        buffer.push(a);
        continue;
      }
      let b = segments[i + 1];
      let res;
      if (pointEquals(a[0], b[b.length - 1])) {
        res = [...b, ...a];
      } else if (pointEquals(b[0], a[a.length - 1])) {
        res = [...a, ...b];
      } else if (pointEquals(a[0], b[0])) {
        res = [...a.reverse(), ...b];
      } else if (pointEquals(a[a.length - 1], b[b.length - 1])) {
        res = [...b, ...a.reverse()];
      }
      if (res) {
        res = res;
        buffer.push(res);
      } else {
        a = a;
        b = b;
        buffer.push(a);
        buffer.push(b);
      }

      continue;
    }
    segments = buffer;
    if (segments.length == 1) return segments;

    shuffle(segments);
  }

  return (segments.length == 1 && segments) || undefined;
}

export function project4326(feature) {
  let reader = new ol.format.GeoJSON();
  let out = reader.readFeature(feature.geometry);

  let writer = new ol.format.GeoJSON({
    dataProjection: "EPSG:4326",
    featureProjection: "EPSG:3857",
  });
  let result = writer.writeFeatureObject(out);
  result.properties = feature.properties;
  return result;
}

export function project3857(feature) {
  let reader = new ol.format.GeoJSON();
  let out = reader.readFeature(feature.geometry);

  let writer = new ol.format.GeoJSON({
    dataProjection: "EPSG:3857",
    featureProjection: "EPSG:4326",
  });
  let result = writer.writeFeatureObject(out);
  result.properties = feature.properties;
  return result;
}

export function project2154to3857(feature) {
  let reader = new ol.format.GeoJSON();
  let out = reader.readFeature(feature.geometry);

  let writer = new ol.format.GeoJSON({
    dataProjection: "EPSG:3857",
    featureProjection: "EPSG:2154",
  });
  let result = writer.writeFeatureObject(out);
  result.properties = feature.properties;
  return result;
}

export function project3857to2154(feature) {
  let reader = new ol.format.GeoJSON();
  let out = reader.readFeature(feature.geometry);

  let writer = new ol.format.GeoJSON({
    dataProjection: "EPSG:2154",
    featureProjection: "EPSG:3857",
  });
  let result = writer.writeFeatureObject(out);
  result.properties = feature.properties;
  return result;
}

export function convertGeojsonToGeom(feature) {
  let reader = new ol.format.GeoJSON();
  let out = reader.readFeature(feature.geometry);

  let writer = new ol.format.WKT();
  let result = writer.writeFeature(out);

  return result;
}

// check if a point is on a line
// turf function not working well because of the float precision issue
export function isOnLine(point, line) {
  let nearestPoint = turf.nearestPointOnLine(line, point, { units: "kilometers" });
  return nearestPoint.properties.dist && nearestPoint.properties.dist < 0.001;
}

export function computeSurface(polygon) {
  return Math.round(turf.area(polygon) / 100) / 100;
}

export function computeSurfaceSquareMeters(polygon) {
  return Math.round(turf.area(polygon));
}

export function segmentLengthInPolygon(segment, polygon) {
  let segmentChunks = turf.lineChunk(segment, 0.01, {
    units: "kilometers",
  });
  segmentChunks.features = segmentChunks.features.filter((s) => turf.booleanWithin(s, polygon));
  return turf.length(segmentChunks);
}

function makeWrap(line, reverse = false) {
  let lineCopy = turf.clone(line);
  lineCopy = turf.transformRotate(lineCopy, 180);

  let ends = lineEnds(lineCopy);
  let angle = turf.bearing(...ends.map((p) => turf.point(p)));

  if (reverse) angle = angle - 90;
  else angle = angle + 90;

  lineCopy = turf.transformTranslate(lineCopy, 10, angle);

  let wrap = turf.lineString([...line.geometry.coordinates, ...lineCopy.geometry.coordinates]);
  wrap = turf.lineToPolygon(wrap);
  wrap = turf.cleanCoords(wrap);

  let kinks = turf.unkinkPolygon(wrap).features;

  if (kinks.length > 1) {
    let maxArea = 0;
    let bestCandidate;
    kinks.forEach((k) => {
      let area = turf.area(k);
      if (area > maxArea) {
        maxArea = area;
        bestCandidate = k;
      }
    });
    if (bestCandidate) wrap = bestCandidate;
  }

  return wrap;
}

export function slicePolygon(polygon, line, name = "") {
  // see article for more details
  // https://kuanbutts.com/2020/07/07/subdivide-polygon-with-linestring/
  const polyAsLine = turf.polygonToLine(polygon);

  let id = polygon.properties.id;

  const unionedLines = turf.union(polyAsLine, line);

  const polygonized = turf.polygonize(unionedLines);

  let slices = polygonized["features"].filter((ea) => turf.booleanPointInPolygon(turf.pointOnFeature(ea), polygon));

  for (let i = 0; i < slices.length; i++) {
    slices[i].properties.surface = computeSurface(slices[i]);

    if (i == 0) {
      slices[i].properties.name = name;
      slices[i].properties.id = id;
    } else {
      slices[i].properties.name = appendName(name, i);
      slices[i].properties.id = null;
    }
  }

  return slices;
}

export function getGeometryFromLayer(layer) {
  let features = layer.getSource().getFeatures();
  if (features.length == 0 || !features[0]) return;
  return getGeometryFromFeature(features[0]);
}

export function getGeometryFromFeature(feature) {
  let writer = new ol.format.GeoJSON();
  let geom = writer.writeFeature(feature);
  return JSON.parse(geom);
}

export function writeGeometryToLayer(geojson, layer, clear = false) {
  if (clear) {
    layer.getSource().clear();
  }
  try {
    let activeFeature = new ol.format.GeoJSON().readFeature(geojson);
    layer.getSource().addFeature(activeFeature);
  } catch (error) {
    console.log(error);
  }
  layer.dispatchEvent("change");
}

export function writeGeometriesToLayer(geojson, layer, clear = false) {

  if (clear || geojson && geojson.length == 0) {
    layer.getSource().clear();
  }
  try {
    geojson = turf.featureCollection(geojson);

    let features = new ol.format.GeoJSON().readFeatures(geojson);

    layer.getSource().addFeatures(features);
  } catch (error) {
    console.log(error);
  }
  layer.dispatchEvent("change");
}

/**
 * Case where we have a point inside a polygon, close to the border,
 * and we want to find the closest point outside
 */
export function getClosestPointOutsidePolygon(point, polygon, bufferDistance = 0.01) {
  // create a buffer around the point
  const buffered = turf.buffer(point, bufferDistance, { units: "meters" });
  // transform the buffer polygon to points
  const bufferedPoints = turf.explode(buffered);

  let firstPointOutside;
  // iterate each point and find the first one outside
  turf.featureEach(bufferedPoints, function (currentFeature, featureIndex) {
    if (!firstPointOutside && !turf.booleanPointInPolygon(currentFeature, polygon)) {
      firstPointOutside = currentFeature;
    }
  });

  // if we have a candidate, return
  if (firstPointOutside) return firstPointOutside;
  else
    console.error(
      "getClosestPointOutsidePolygon: failed to find a point outside. Maybe the bufferDistance is too small.",
    );
}

/*
 * Convert a multipolygon to polygon
 */
export function multiPolygonToPolygon(multiPolygon) {
  return turf.polygon(multiPolygon.geometry.coordinates[0], multiPolygon.properties);
}

/**
 * Retourne la longueur d'une LineString formatée en m et km
 * @param {*} line
 * @returns
 */
export function formatLength(line) {
  let output = '';
  if (line.getType() == 'LineString') {
    const length = ol.sphere.getLength(line);
    if (length > 1000) {
      output = Math.round((length / 1000) * 1000) / 1000 + ' ' + 'km';
    } else if (length > 0) {
      output = Math.round(length * 10) / 10 + ' ' + 'm';
    }
  }
  return output;
}

/**
 * Retourne l'aire d'un polygon en ha
 * @param {*} polygon
 * @returns
 */
export function formatArea(polygon) {
  let output = '';
  const area = ol.sphere.getArea(polygon);
  if (area > 0) {
    output = _.round(area/10000, 2) + ' ' + 'ha';
  }
  return output;
}

/**
 * Retourne l'aire d'un polygon ou la longueur d'une lineString
 * @param {*} geom
 * @returns
 */
export function getAreaOrLength(geom) {
  if (geom.getType() == 'Polygon')
    return formatArea(geom);
  return formatLength(geom);
}

/**
 *
 * @param {*} geomX polygon
 * @param {*} geomY polygon
 * @returns true si geomX contient geomY
 */
export function geomXContainsGeomY(geomX, geomY) {
  let coordinatesY = geomY.getCoordinates()[0];
  return coordinatesY.every(coord => {
    return geomX.intersectsCoordinate(coord);
  });
}
