<template>
  <div v-if="currentInfo">
    <!-- Product authorization -->
    <p v-if="currentInfo.authorized === true">
      <span class="text-infos"> Produit autorisé </span>
    </p>
    <p v-else-if="currentInfo.authorized === false">
      <span class="text-infos"> Produit retiré de la vente </span>
    </p>

    <!-- Product type -->
    <h4 class="subtitle-form">Type du produit</h4>
    <p>
      <span class="text-infos"> {{ currentInfo.productType_designation }} </span>
    </p>

    <!-- Manufacturer -->
    <div v-if="currentInfo.productFamily_code === 'phyto'">
      <h4 class="subtitle-form">Fabricant</h4>
      <p class="text-infos">
        <span v-if="currentInfo.hasOwnProperty('manufacturer')"> {{ currentInfo.manufacturer }} </span>
        <span v-else> Fabricant inconnu </span>
      </p>
    </div>
  </div>
</template>

<script>
// import ActivityModule from "@/store/modules/activity";
import { mapGetters } from "vuex";

export default {
  name: "infos-input",
  data() {
    return {};
  },
  components: {},
  methods: {},
  computed: {
    ...mapGetters({
      currentInputStock: "inputStock/currentEntry",
      currentProduct: "currentProduct",
    }),
    currentInfo() {
      if(Object.keys(this.currentInputStock).length > 0) return this.currentInputStock
      else return this.currentProduct
    }
  }
};
</script>

<style lang="scss" scoped></style>
