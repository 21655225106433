<template>
  <div>
    <!-- Product composition -->
    <div v-if="this.readOnly === false">
      <CropAndDose ref="cropAndDose" />

      <br />

      <h4 class="subtitle-form">Composition du produit</h4>
      <table>
        <!-- NPK -->
        <tbody>
          <!-- N -->
          <tr>
            <td class="shape-first-cell">
              <span class="subtitle-form"> N </span>
            </td>
            <td>
              <NumericInput
                inputRef="input"
                :value="nQuantity"
                @input="i => {nQuantity = i}"
                class="width-composition-inputs"
              />
            </td>
            <td>
              <select class="width-select-unit-npk" v-model="unitSelected">
                <option v-for="unity in inputFormByPtype.compositionUnits" v-bind:key="unity.id" :value="unity.id">{{
                  unity.unit
                }}</option>
              </select>
            </td>
          </tr>

          <!-- P -->
          <tr>
            <td>
              <span class="subtitle-form"> P </span>
            </td>
            <td>
              <NumericInput
                inputRef="input"
                :value="pQuantity"
                @input="i => {pQuantity = i}"
                class="width-composition-inputs"
              />
            </td>
            <td>
              <select class="width-select-unit-npk" v-model="unitSelected">
                <option v-for="unity in inputFormByPtype.compositionUnits" v-bind:key="unity.id" :value="unity.id">{{
                  unity.unit
                }}</option>
              </select>
            </td>
          </tr>

          <!-- K -->
          <tr>
            <td>
              <span class="subtitle-form"> K </span>
            </td>
            <td>
              <NumericInput
                inputRef="input"
                :value="kQuantity"
                @input="i => {kQuantity = i}"
                class="width-composition-inputs"
              />
            </td>
            <td>
              <select class="width-select-unit-npk" v-model="unitSelected">
                <option v-for="unity in inputFormByPtype.compositionUnits" v-bind:key="unity.id" :value="unity.id">{{
                  unity.unit
                }}</option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>

      <br />

      <OthersElements ref="othersElements" />

      <br />

      <h4 class="subtitle-form">{{ inputFormByPtype.otherFeature_label }}</h4>
      <select class="shape-select" v-model="otherFeatureSelected">
        <option v-for="feature in inputFormByPtype.otherFeatures" v-bind:key="feature.id" :value="feature.id">{{
          feature.designation
        }}</option>
      </select>
    </div>

    <div v-else-if="this.$route.name === 'inputStock.edit' && this.readOnly">
      <FertilizerComposition />
    </div>
  </div>
</template>

<script>
import CropAndDose from "@/components/inputstock/edit/CropAndDose.vue";
import FertilizerComposition from "@/components/inputstock/details/FertilizerComposition.vue";
import OthersElements from "@/components/inputstock/edit/OthersElements.vue";
import { mapGetters } from "vuex";
import NumericInput from "@/components/commons/NumericInput.vue";

export default {
  name: "fertilizer-form",
  created() {
    // this.globalUnit = this.inputFormByPtype.compositionUnits;

    if (this.$route.name === "inputStock.edit") {
      this.nQuantity = this.getNPK("N", "dose");
      this.pQuantity = this.getNPK("P", "dose");
      this.kQuantity = this.getNPK("K", "dose");
      this.unitSelected = this.getNPK("N", "unit");
      this.otherFeatureSelected = this.currentInputStock.otherFeature;
    } else {
      this.minDoseSelected = "";
      this.maxDoseSelected = "";
      this.unitSelected = "";

      if (this.inputFormByPtype.otherFeatures.length == 1)
        this.otherFeatureSelected = this.inputFormByPtype.otherFeatures[0].id;

      this.unitSelected = this.inputFormByPtype.compositionUnits[0].id;
    }
  },
  data() {
    return {
      otherFeatureSelected: "",
      nQuantity: 0,
      pQuantity: 0,
      kQuantity: 0,
      unitSelected: "",
      globalUnit: [],
    };
  },
  components: {
    CropAndDose,
    FertilizerComposition,
    OthersElements,
    NumericInput,
  },
  methods: {
    getFertilizerInfo() {
      let fertilizerInfo = !this.readOnly
        ? this.$refs.cropAndDose.getCropAndDose()
        : {
            unit: this.currentInputStock.doseUnit,
            min: this.currentInputStock.minDoseValue,
            max: this.currentInputStock.maxDoseValue,
          };
      let othersElems = !this.readOnly ? this.$refs.othersElements.getOthersElements() : [];

      fertilizerInfo["composition"] = [
        {
          compound: 1,
          dose: this.nQuantity,
          unit: this.unitSelected,
        },
        {
          compound: 2,
          dose: this.pQuantity,
          unit: this.unitSelected,
        },
        {
          compound: 4,
          dose: this.kQuantity,
          unit: this.unitSelected,
        },
      ];

      fertilizerInfo["otherFeature"] = this.otherFeatureSelected;

      if (othersElems.length > 0) othersElems.map(comp => fertilizerInfo.composition.push(comp));

      return fertilizerInfo;
    },

    getNPK(symbol, value) {
      if(!this.currentProduct) return null;

      let sym = symbol;
      let composition;

      if (this.currentProduct.hasOwnProperty("composition")) {
        composition = this.currentProduct.composition.filter(function(elem) {
          if (elem.compound_symbol == sym) return elem;
        });

        if (composition.length > 0) return composition[0][value];
      } else if (this.currentInputStock.composition.length > 0) {
        composition = this.currentInputStock.composition.filter(function(elem) {
          if (elem.compound_symbol == sym) return elem;
        });

        if (composition.length > 0) return composition[0][value];
      }

      return null;
    },
  },
  computed: {
    ...mapGetters({
      currentInputStock: "inputStock/currentEntry",
    }),
    ...mapGetters(["readOnly", "currentProduct", "inputFormByPtype"]),
  },
};
</script>

<style lang="scss" scoped>
/**
 * Width for the composition inputs .
 */
.width-composition-inputs {
  border-radius: 4px;
  width: 97px;
  padding-left: 3px;
}
table {
  margin-top: 15px;
}

.shape-first-cell {
  width: 100px;
}

.width-select-unit-npk {
  border-radius: 4px;
  font-size: 14px;
  width: 77px;
}
</style>
