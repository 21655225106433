<template>
  <div>{{ cellData }}</div>
</template>

<script>
import { textToLocaleShortDateString } from "@/utils/commons";
export default {
  props: ["rowId", "rowData"],
  computed: {
    cellData() {
      if (this.rowData.maintenance_lastdate != null)
          return textToLocaleShortDateString(this.rowData.maintenance_lastdate);
        else
          return
    },
  },
};
</script>

<style lang="scss" scoped></style>
