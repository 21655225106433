<template>
  <!-- Boîte demandant à l'utilisateur de saisir la date de récolte estimée de sa parcelle -->
  <b-modal ref="harvestModal" hide-header centered>
    <div class="row">
      <div class="col-3 px-3 pt-4 d-flex justify-content-center">
        <!-- image -->
        <div>
          <img :src="NumberOneIcon" width="100%"/>
        </div>
      </div>
      <div class="col-9 my-4">
        <!-- Text -->
        <div>
          {{$t("cropfields.details.harvest_modal_text")}} {{ cropName }} ?
        </div>
				<!-- Harvest date selector -->
        <div class="mt-2">
					<b-form-input
						ref="harvestDateInput"
						type="date"
						class="w-auto"
						size="sm"
						v-model="harvestDate"
						placeholder="yyyy-mm-dd"
						:required="true"
					/>
        </div>
      </div>
    </div>
    <template v-slot:modal-footer>
      <div class="w-100 d-flex justify-content-end align-items-center">
        <b-button
          class="btn btn-primary mr-2"
          @click="hideModal()"
        >{{ $t('cancel') }}</b-button
        >
        <b-button
					type="submit"
          class="btn btn-success"
          @click="patchHarvestDate"
        >{{ $t('save') }}</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>

import NumberOneIcon from "@/assets/images/number_one.svg";

export default {
  data() {
    return {
      NumberOneIcon: NumberOneIcon,
      harvestDate: null,
    };
  },
  props: {
    cropName: {
        type: String,
        default: ""
    }
  },
  methods: {
    /**
     * Affiche la boite
     */
    showModal() {
      this.$refs.harvestModal.show();
    },
    /**
     * @description Masque la boîte
     */
    hideModal() {
      this.$refs.harvestModal.hide();
    },

    /**
     * Active l'évènement pour déclencher le patch de la date de récolte
     */
    patchHarvestDate() {
			if (!this.harvestDate) {
				this.$refs.harvestDateInput.reportValidity();
				return;
			}
			this.$emit("patchHarvestDate", this.harvestDate);
    },
  }
}
</script>