import { addDuration } from "@/utils/commons";

import MachineApi from "@/api/machine.api";

import ActivityPathFilter from "@/components/activity/modals/ActivityPathFilter.vue";
import { EventBus } from "@/eventBus.js";

export default {
  data() {
    return {
      // machine path
      path: {
        machine: null,
        date: null,
        time: null,
        duration: null,
      },
      pathLayers: [],
      pathLayersOptions: [],
      pathLayersOptionsObjects: [],
      allPathLayersOptions: [], // Utile pour la reinitialisation des couches du parcours
      allPathLayersOptionsObjects: [], // Utile pour la reinitialisation des couches du parcours
      pathTooltip: {
        content: null,
        coordinates: [],
        imageFile: null
      },

      toolsHeight: 0,
    };
  },
  props: {
    pathOptions: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ActivityPathFilter,
  },
  mounted() {
    this.computeToolsHeight();
  },
  methods: {
    initPath() {
      this.path.activity = this.pathOptions.activity;
      this.path.machine = this.pathOptions.machine;
      this.path.shiftMinutes = this.pathOptions.shiftMinutes;
      this.path.date = addDuration({ startDate: this.pathOptions.startDate, minutes: - this.path.shiftMinutes}).toISOString() ;

      let date = new Date(this.path.date);
      this.path.time = date.toLocaleTimeString();
      this.path.duration = this.pathOptions.duration;

      this.fetchPath();

      if(!this.outlineMode) this.showPathDetailsHover();
    },
    async fetchPath() {
      let date = new Date(this.path.date);

      if (isNaN(date)) return;

      let time_parts = this.path.time.split(":");

      date.setHours(time_parts[0], time_parts[1], time_parts[2] | 0);

      this.path.date = date.toISOString();
      let shift = this.path.shiftMinutes;
      let startDate = this.path.date;
      let endDate = addDuration({ startDate: this.path.date, minutes: this.path.duration}).toISOString();

      let response = await this.$store.dispatch("machine/getPath", {
        // here either activity or machine is undefined
        activity: this.path.activity,
        machine: this.path.machine,
        startDate: startDate,
        endDate: endDate,
      });

      // Suppression de toutes les couches précédentes du parcours
      for (let i = 0; i < this.allPathLayersOptions.length; i++) {
        this.map.removeLayer(this.allPathLayersOptionsObjects[i]);
        this.allPathLayersOptionsObjects[i].dispatchEvent("change");
      }

      this.showMachinePath(response);
    },
    showPathDetailsHover() {
      let t = this;
      this.map.on("pointermove", function (e) {
        var pixel = t.map.getEventPixel(e.originalEvent);
        var hitFeatures = t.map.getFeaturesAtPixel(pixel);

        /* Affichage de l'heure du tronçon survolé */
        if (hitFeatures.length > 0) {
          for (var i = 0; i < hitFeatures.length; i++) {
            if (
              ["LineString", "Point"].indexOf(hitFeatures[i].getGeometry().getType()) >= 0 &&
              hitFeatures[i].getProperties().name != "<center></center>" &&
              hitFeatures[i].getProperties().name != undefined
            ) {
              // Affichage de l'heure du troncon
              t.pathTooltip.content = hitFeatures[i].getProperties().name;

              t.pathTooltip.x = e.originalEvent.pageX;
              t.pathTooltip.y = e.originalEvent.pageY;

              return;
            } else if (
              hitFeatures[i].getGeometry().getType() == "Point" &&
              hitFeatures[i].getProperties().text != null
            ) {
              // Affichage du détail des notes survolées
              t.pathTooltip.content = hitFeatures[i].getProperties().text;
  
              t.pathTooltip.x = e.originalEvent.pageX;
              t.pathTooltip.y = e.originalEvent.pageY;
  
              return;
            } else {
              t.pathTooltip.content = null;
            }
          }
        } else {
          t.pathTooltip.content = null;
        }
      });
    },
    refreshPathLayers() {
      for (let i = 0; i < this.pathLayersOptions.length; i++) {
        let showLayer = this.pathLayers.find((l) => l == this.pathLayersOptions[i].value) != undefined;
        this.pathLayersOptionsObjects[i].setVisible(showLayer);
        this.pathLayersOptionsObjects[i].dispatchEvent("change");
      }
    },
    showMachinePath(data) {
      this.showActivityPathFilter = true;

      let dataFound = false;

      this.pathLayersOptionsObjects = [];

      let objFound;
      this.pathLayersOptions = [];

      this.allPathLayersOptions = [];
      this.allPathLayersOptionsObjects = [];

      /* On affiche chacune des couches renvoyées par le fichier de traitement */
      for (let layer in data) {
        var currentLayer = data[layer];
        
        // N'affiche pas le trajet et la fin du parcours dans les options de couches
        if(data[layer].hasOwnProperty("name")) {
          // On récupère toutes les couches du parcours pour gérer la réinitialisation
          this.allPathLayersOptions.push({ text: data[layer].name, value: data[layer].name });
          if (!["Trajet", "Fin du parcours"].includes(data[layer].name)) {
            // On récupère uniquement les couches qui seront proposées dans les options sur la carte
            this.pathLayersOptions.push({ text: data[layer].name, value: data[layer].name });
          }
        }

        let vectorLayer = new ol.layer.Vector({
          source: new ol.source.Vector({
            features: [],
          }),
        });

        if (currentLayer.hasOwnProperty("type")) {
          switch (currentLayer.type) {
            case "Polyline":
              if (currentLayer.hasOwnProperty("polylines")) {
                var z = currentLayer.hasOwnProperty("Zindex") ? currentLayer.Zindex : 0;
                vectorLayer.setZIndex(z);
                for (let polyline in currentLayer.polylines) {
                  dataFound = true;
                  if (currentLayer["polylines"][polyline].hasOwnProperty("points")) {
                    var description = "<center>";
                    description += currentLayer["polylines"][polyline].hasOwnProperty("startDate")
                      ? this.convertDate(currentLayer["polylines"][polyline].startDate) + "<br>-<br>"
                      : "";
                    description += currentLayer["polylines"][polyline].hasOwnProperty("endDate")
                      ? this.convertDate(currentLayer["polylines"][polyline].endDate) + "<br>"
                      : "";
                    description += currentLayer["polylines"][polyline].hasOwnProperty("description")
                      ? currentLayer["polylines"][polyline].description
                      : "";
                    description += "</center>";
                    var color = currentLayer["polylines"][polyline].hasOwnProperty("color")
                      ? currentLayer["polylines"][polyline].color
                      : "#000";

                    if (currentLayer.hasOwnProperty("scale") && currentLayer["polylines"][polyline].hasOwnProperty("scale_id")){
                      // Cas de layer_speed
                      color = currentLayer["scale"].find(scale => scale.id === currentLayer["polylines"][polyline].scale_id)?.color || "#000";
                    }
                    /* On crée le tronçon */
                    var line = new ol.geom.LineString(currentLayer["polylines"][polyline].points).transform(
                      "EPSG:4326",
                      "EPSG:3857",
                    );
                    var feature = new ol.Feature({ geometry: line, name: description });
                    feature.setStyle(new ol.style.Style({ stroke: new ol.style.Stroke({ width: 2, color: color }) }));
                    vectorLayer.getSource().addFeature(feature);
                  }
                }
              }
              break;
            case "Marker":
              if (currentLayer.hasOwnProperty("marker")) {
                var z = currentLayer.hasOwnProperty("Zindex") ? currentLayer.Zindex : 0;
                vectorLayer.setZIndex(z);
                var icon = currentLayer.hasOwnProperty("icon") ? currentLayer.icon : "";
                for (let marker in currentLayer.marker) {
                  dataFound = true;

                  if (currentLayer["marker"][marker].hasOwnProperty("point")) {
                    var description = currentLayer["marker"][marker].hasOwnProperty("description")
                      ? currentLayer["marker"][marker].description + " : "
                      : "";
                    var date = currentLayer["marker"][marker].hasOwnProperty("date")
                      ? this.convertDate(currentLayer["marker"][marker].date)
                      : "";
                    /* On crée le marker */
                    var point = new ol.geom.Point(currentLayer["marker"][marker]["point"]).transform(
                      "EPSG:4326",
                      "EPSG:3857",
                    );
                    var feature = new ol.Feature({ geometry: point, name: description + date });
                    feature.setStyle(new ol.style.Style({ image: new ol.style.Icon({ src: icon }) }));
                    vectorLayer.getSource().addFeature(feature);
                    if (objFound == false) {
                      if (isSameSearch == false) {
                        this.map.getView().fit(feature.getGeometry(), { duration: 500 });
                      }
                      objFound = true;
                    }
                  }
                }
              }
              break;
            default:
              alert("Le type de couche " + currentLayer.type + " n'est pas reconnu");
          }
        } else if(currentLayer.hasOwnProperty("name")){
          alert("La couche " + layer + " ne peut pas être lue, type de données manquant");
        }

        // set path layer above others
        vectorLayer.setZIndex(20);
        this.map.addLayer(vectorLayer);
        // N'affiche pas le trajet et la fin du parcours dans les options de couches
        if(data[layer].hasOwnProperty("name")) {
          // On récupère toutes les couches du parcours pour gérer la réinitialisation
          this.allPathLayersOptionsObjects.push(vectorLayer);
          if (!["Trajet", "Fin du parcours"].includes(data[layer].name)) {
            // On récupère uniquement les couches qui seront proposées dans les options sur la carte
            this.pathLayersOptionsObjects.push(vectorLayer);
          }
        }
      }

      if (!dataFound) {
        alert("Aucune donné trouvée pour ce tracker sur cette période");
      }

      let layersToExclude = ["Changements de Direction", "GPS perdu"];
      this.pathLayers = this.pathLayersOptions.map((l) => l.text).filter((l) => !layersToExclude.includes(l));

      if (dataFound && this.pathLayersOptionsObjects.length > 0)
        this.map.getView().fit(this.pathLayersOptionsObjects[0].getSource().getExtent(), { duration: 500 });

      // Une fois que le parcours est affiché, on provoque l'affichage du contour de la parcelle non repertoriée 
      if (this.outlineMode) EventBus.$emit("showGeneratedOutline");
    },
    convertDate(date) {
      let options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZoneName: "short",
      };
      return new Date(date).toLocaleDateString("fr-FR", options);
    },
    computeToolsHeight() {
      if (!this.$refs.mapTools) return;
      this.toolsHeight = this.$refs.mapTools.getBoundingClientRect().height;
    },
  },
  watch: {
    pathLayers() {
      this.refreshPathLayers();
    },
  },
};
