<!-- Dialog Modal, cette modal est utilisé pour afficher un message 
	 Cette Modal est affiché quand on utilise un EventBus.$emit avec le Topic
	 qui est "showAlertModal", on va aussi pouvoir y passer plusieur argument
	 -title: Un titre qui va s'aaficher que quand il aura une value
	 -message: C'est le message que l'on veut afficher dans la modale
	 -okBtn: La fonction qui sera utilisé quand on clicke sur le BTN OK
	 -cancelBtn: La fonction qui sera utilisé quand on clicke sur le BTN Cancel
	 -okTitle: Le titre du BTN OK
	 -cancelTitle: Le titre du BTN Cancel
-->
<template>
	<b-modal ref="DialogModal" centered :hide-header="!title" :title="title" @ok="okBtn" @cancel="cancelBtn"
		:ok-title="$t(okTitle)" :cancel-title="$t(cancelTitle)" :ok-only="cancelTitle == ''">
		<p class="px-1 news-title">
			{{ message }}
		</p>
	</b-modal>
</template>
  
<script>
import { EventBus } from "@/eventBus.js";

export default {
	data() {
		return {
			title: "",
			message: "",
			okBtn: () => { },
			cancelBtn: () => { },
			okTitle: "",
			cancelTitle: "",
		};
	},
	created() {
		EventBus.$on("showAlertModal", this.showModal);
	},
	beforeDestroy() {
		EventBus.$off("showAlertModal", this.showModal);
	},
	methods: {
		showModal({ title, message, okBtn, cancelBtn, okTitle, cancelTitle }) {
			this.title = title || "",
			this.message = message;
			this.okBtn = okBtn;
			this.cancelBtn = cancelBtn;
			this.okTitle = okTitle;
			this.cancelTitle = cancelTitle;
			this.$refs.DialogModal.show();
		},
	}
}
</script>
<style lang="scss" scoped>
.news-title {
	font-weight: bold;
	margin: 10px;
}
</style>