<template>
  <div>
    <div class="history" v-if="cropfieldCampaigns.length > 0">
      <div class="history-group small" v-for="group in cropfieldCampaigns" :key="group.name">
        <div class="title">
          <span class="mr-3">{{ group.name }}</span> {{ listToString(group.crops) }}
        </div>
        <b-table fixed :items="group.cropfields" :fields="fields" borderless></b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CropfieldApi from "@/api/cropfield.api";

export default {
  data() {
    return {
      fields: ["name", "surface", "crop"],
      cropfieldCampaigns: [],
    };
  },
  props: ["id"],
  mounted() {
    this.fetchCropfields();
  },

  computed: {
    ...mapGetters({
      crossesCropfields: "cropfield/crossesCropfields",
    })

  },
  methods: {
    listToString(list) {
      return list.join(", ");
    },
    async fetchCropfields() {
      if (!this.id) return;
      await this.$store.dispatch("activity/setCropfieldHistoryLoadTime", new Date());
      await this.$store.dispatch("cropfield/getCrossesCropfields", {id: this.id});

      let result = [];
      this.crossesCropfields.forEach(function(field) {
        if (!result.find(c => c.name == field.campaign_name)) {
          result.push({
            name: field.campaign_name,
            crops: [],
            cropfields: [],
          });
        }

        let index = result.findIndex(c => c.name == field.campaign_name);

        if (field.crop_name) result[index].crops = [...new Set([...result[index].crops, field.crop_name])];
        result[index].cropfields.push({
          name: field.name,
          surface: field.surface + " ha",
          crop: field.crop_name,
        });
      });

      result.sort(function(a, b) {
        return b.name - a.name;
      });

      this.cropfieldCampaigns = result;
    },
  },
  watch: {
    id() {
      this.fetchCropfields();
    },
  },
};
</script>

<style lang="scss" scoped>
.history {
  background: $light-grey;
  padding: 8px;
}

.history-group {
  thead {
    display: none;
  }

  td {
    padding: 0.4rem !important;
  }
}
.title {
  border-bottom: 1px solid $grey;
  padding: 4px;

  span {
    font-weight: bold;
  }
}
</style>
