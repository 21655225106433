<template>
  <div>
		<b-row class="mt-1">
			<!-- Date text -->
      <b-col cols="6">
				<img :src="iconCalendar" alt="calendar" />
        <h4 class="subtitle-form overload-subtitle mb-2">{{ $t("inputStock.details.date") }}</h4>
      </b-col>
      <!-- Quantity text -->
      <b-col cols="3" class="pl-2">
        <h4 class="subtitle-form overload-subtitle mb-2">{{ $t("inputStock.details.quantity") }}</h4>
      </b-col>
			<!-- Unit text -->
      <b-col cols="3" class="pl-2">
        <h4 class="subtitle-form overload-subtitle mb-2">{{ $t("inputStock.details.unit") }}</h4>
      </b-col>

    </b-row>
		<b-row class="row-resize mb-3">
      <!-- Date Input-->
      <b-col cols="6" class="text-infos shape-first-cell whitout-bg">
        <div class="inputs-params pl-3">
          <input type="date" ref="date" id="date" v-model="date" placeholder="yyyy-mm-dd" required/>
        </div>
      </b-col>
      <!-- Quantity Input-->
      <b-col cols="3" class="text-infos shape-first-cell whitout-bg">
        <div class="inputs-params">
          <NumericInput
                id="quantity"
                inputRef="input"
                :value="quantity"
                @input="i => {quantity = i}"
                :min="0"
                :numberOfDigits="4"
                :required="true"
            />
        </div>
      </b-col>
      <!-- Unit input -->
      <b-col cols="3" class="pr-3 text-infos shape-second-cell whitout-bg">
        <div class="inputs-params">
          <Dropdown
						ref="dropdown"
						class="shape-custom-select"
						label="unit"
						:value="unit"
						:options="units"
						@input="input => addUnit(input)"
						:required="true"
            :clearable="false"
					/>
        </div>
      </b-col>
    </b-row>

		<b-container class="mb-3 p-0">
        <b-row align-h="end">
          <b-col cols="6">
            <b-button class="button-default align-bottom" @click="onReset">
              {{ $t("cancel") }}
            </b-button>
          </b-col>
          <b-col cols="6">
            <b-button class="button-default align-bottom" @click="onSave" type="submit">
              {{ $t("save") }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import calendar from "@/assets/images/calendar.svg";
import Dropdown from "@/components/inputstock/details/Dropdown.vue";
import {checkDatePattern, checkTimePattern} from "@/utils/commons";
import NumericInput from "@/components/commons/NumericInput.vue";

export default {
	data() {
		return {
			iconCalendar: calendar,
			date: null,
			quantity: null,
			unit: {},
			units: [],
		};
	},

  props: {
    update: false
  },

	components: {
    Dropdown,
    NumericInput
  },

	mounted() {
		this.units = this.currentInput.units;
    if(this.update)
    {
      this.date = this.stockState.lastInventoryDate;
      this.quantity = this.stockState.lastInventoryQuantity;
      this.unit = {'id': this.stockState.unit_id, 'unit': this.stockState.unit_unit};
    }else{
      this.unit = this.units[0];
		  this.date = new Date().toISOString().substring(0, 10);
    }
	},

	computed: {
		...mapGetters({
      currentInput: "inputStock/currentEntry",
      stockState: "inputStock/stockState",
     }),
	},

	methods: {
		addUnit(input){
			this.unit = input;
		},

		/**
     * Description: return date
     */
    getDate() {
      var date = new Date(this.date);
      if (date != "Invalid Date") return date.toISOString().substring(0, 10);
      return null;
    },

		/**
     * @description save input inventory
     */
    async onSave() {
			if(!checkDatePattern(this.date) || !this.date || !this.unit || !this.quantity)
				return;

      let dataToPatch = {
        date: this.getDate(),
        quantity: parseFloat(this.quantity),
				doseUnit: this.unit.id,
        inputsStock: this.currentInput.id,
      }
      if(this.update)
        await this.$store.dispatch("updateInputInventory", {id: this.stockState.lastInventoryId, data: dataToPatch})
      else
			  await this.$store.dispatch("createInputInventory", { data: dataToPatch });

      await this.$store.dispatch("inputStock/fetchEntries")

      await this.$store.dispatch("inputStock/getStockState", {id: this.currentInput.id});
			this.$emit('clicked');
    },

		/**
		 * Retourne en mode consultation
		 */
		onReset() {
			this.$emit("clicked");
		}
	},
  watch: {
    date: {
      handler: async function() {
        if(!checkDatePattern(this.date)) {
          this.$refs.date.setCustomValidity(this.$i18n.t("commons.details.datePatternError"));
        }else{
          this.$refs.date.setCustomValidity("");
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.button-add-carburant {
  background-color: $secondary-light-color;
  width: 175px;
}

img {
  width: 1.5em;
  float: left;
  margin: auto 5px 5px auto;
}
</style>