<template>
  <div></div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "phyto-form",
  data() {
    return {};
  },
  components: {},
  methods: {},
  computed: {
    ...mapGetters({
      currentInputStock: "inputStock/currentEntry",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
