import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';

// Source des données pour alimenter les graphes

export default {
	computed: {
		dataSourceIFT() {
			// Données relatives à l'IFT
			const dataSource = new PivotGridDataSource({
				fields: [
					{
						caption: "Famille de culture",
						width: 120,
						dataField: "crop_family_name",
						area: "row"
					},
					{
						caption: "Culture",
						width: 120,
						dataField: "crop_type_name",
						area: "row",
						sortBySummaryField: "IFT",
					},
					{
						caption: "Parcelle",
						dataField: "name",
						width: 150,
						area: "row",
					},
					{
						dataField: "campaign",
						dataType: "date",
						area: "column",
					},
					{
						caption: "Campagne",
						dataField: "campaign",
						area: "filter",
					},
					{
						groupName: 'date',
						groupInterval: 'month',
						visible: false,
					},
					{
						caption: "IFT herbicide",
						dataType: "number",
						summaryType: "custom",
						area: "data",
						calculateCustomSummary: (options) => {
							switch (options.summaryProcess) {
								case 'start':
									options.totalValue = 0;
									options.sum = 0;
									break;
								case 'calculate':
									options.totalValue += options.value.herbicides_tfi * parseFloat(options.value.surface);
									options.sum += parseFloat(options.value.surface);
									break;
								case 'finalize':
									options.totalValue = options.sum != 0 && options.totalValue != 0 ? parseFloat(options.totalValue / options.sum).toFixed(2) : 0;
									break;
							}
						}
					},
					{
						caption: "IFT hors herbicide",
						dataType: "number",
						summaryType: "custom",
						area: "data",
						calculateCustomSummary: (options) => {
							switch (options.summaryProcess) {
								case 'start':
									options.totalValue = 0;
									options.sum = 0;
									break;
								case 'calculate':
									options.totalValue += options.value.tfi_herbicides_free * parseFloat(options.value.surface);
									options.sum += parseFloat(options.value.surface);
									break;
								case 'finalize':
									options.totalValue = options.sum != 0 && options.totalValue != 0 ? parseFloat(options.totalValue / options.sum).toFixed(2) : 0;
									break;
							}
						}
					},
					{
						caption: "IFT semence",
						dataType: "number",
						summaryType: "custom",
						area: "data",
						calculateCustomSummary: (options) => {
							switch (options.summaryProcess) {
								case 'start':
									options.totalValue = 0;
									options.sum = 0;
									break;
								case 'calculate':
									options.totalValue += options.value.seed_tfi * parseFloat(options.value.surface);
									options.sum += parseFloat(options.value.surface);
									break;
								case 'finalize':
									options.totalValue = options.sum != 0 && options.totalValue != 0 ? parseFloat(options.totalValue / options.sum).toFixed(2) : 0;
									break;
							}
						}
					},


				],
				store: this.iftChartData,
			});

			return dataSource;
		},

		dataSourceElapsedTime() {
			// Données relatives aux temps passés dans les activités
			const dataSource = new PivotGridDataSource({
				fields: [
					{
						caption: "Culture",
						dataField: "crop_name",
						width: 120,
						area: "row",
					},
					{
						caption: "Parcelle",
						width: 150,
						dataField: "cropField_name",
						area: "row",
					},
					{
						caption: "Type d'activité",
						dataField: "activityType",
						area: "column",
						sortBy: "displayText",
					},
					{
						caption: "Temps passés (heure/ha)",
						dataType: "number",
						summaryType: "custom",
						area: "data",
						calculateCustomSummary: (options) => {
							switch (options.summaryProcess) {
								case 'start':
									options.totalValue = 0;
									options.sum = 0;
									break;
								case 'calculate':
									options.totalValue += options.value.elapsedTime;
									options.sum += options.value.cropField_surface;
									break;
								case 'finalize':
									options.totalValue = parseFloat(options.totalValue / options.sum).toFixed(2);
									break;
							}
						},
						format: {
							type: "largeNumber",
							precision: 2
						}
					},
				],
				store: this.elapsedTimeChartData,
			});
			return dataSource
		}
	}
}