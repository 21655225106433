<template>
  <div class="home-widget">
    <b-container>
      <b-row class="home-widget-header">
        <!-- Title -->
        <b-col class="align-items-center" cols="10">
          <h3 class="home-widget-title my-3">{{ $t('home.notifications.title') }}</h3>
        </b-col>
        <!-- Print icon -->
        <b-col class="align-items-center mt-2 pt-1" cols="2">
          <img :src="printIcon" @click="printAlert()" v-b-tooltip.hover :title="$t('home.notifications.print')" class='print-icon'>
        </b-col>
      </b-row>
      <!-- notifications list -->
      <b-row>
        <b-col class="max-height">
          <b-row
            v-for="notification in notifications"
            :key="notification.id"
            @click="showNewsModal(notification)"
            class="notification-row align-items-center"
            :class="{ alert_bg: !notification.readed && notification.criticity=='A', warning_bg: !notification.readed && notification.criticity=='W' }"
          >
            <b-col cols="1">
              <img v-if="notification.criticity === 'A' " :src="alertModal" width="20px" height="20px">
              <img v-if="notification.criticity === 'W' " :src="warningModal" width="20px" height="20px">
            </b-col>
            <b-col cols="11">
              <p>{{ notification.title }}</p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>

    <!-- modal displaying notification details -->
    <NewsModal @markAsClosed="markAsClosed"/>
  </div>
</template>

<script>
import NotificationApi from '@/api/notification.api';
import { mapGetters } from 'vuex';
import { formatServerUrl } from "@/utils/commons.js";

import { EventBus } from '@/eventBus.js';
import alertModal from "@/assets/images/alert_modal.svg";
import warningModal from "@/assets/images/warning_modal.svg";
import printIcon from "@/assets/images/print_icon.svg";
import NewsModal from "@/components/commons/NewsModal.vue";

export default {
  data() {
    return {
      currentId: null,
      notifications: [],
      alertModal: alertModal,
      warningModal: warningModal,
      printIcon: printIcon,
    };
  },
  components: {
    NewsModal
  },
  mounted() {
    // on page initialization, when global filters are loaded
    EventBus.$on('globalFiltersLoaded', async ({ hasFarmSelected }) => {
      // if there is farm selected, data will be fetched on initialization by the watchers, so we return
      if (hasFarmSelected) return;

      this.fetchNotifications();
    });

    // if components loads (tab changed) and filters are already loaded
    if (this.globalFiltersLoaded) this.fetchNotifications();
  },
  computed: {
    currentNotification() {
      return (
        this.notifications.find(n => n.id == this.currentId) || {
          title: '',
          body: '',
        }
      );
    },
    ...mapGetters({
      currentCampaign: 'getCampaign',
      currentFarm: 'farm/currentFarm',
      globalFiltersLoaded: 'user/globalFiltersLoaded',
    }),
  },
  methods: {
    showNewsModal(notification) {
      this.currentId = notification.id;
      this.markAsRead();
      if (this.currentNotification) {
        // this.$refs.notificationDetail.show();
        let alertToShow = [];
        alertToShow.push(notification);
        EventBus.$emit("showNewsModal", {
          news: alertToShow,
          goToDetail: () => this.hideModal(),
          showModifyButton: false,
          showRouterButton: true
        });
      }

    },
    /**
     * Marque la notification sélectionnée comme étant lue
     */
    async markAsRead() {
      let index = this.notifications.findIndex(n => n.id == this.currentId);
      if(this.notifications[index].readed != true){
        this.notifications[index].readed = true;
        await this.$store.dispatch("setVueNotification", this.currentId)
        //await NotificationApi.updateNotification(this.currentId, { readed: true });
      }
    },
    /**
     * Marque la notification sélectionnée comme "closed". Permet de ne plus l'afficher
     */
    async markAsClosed() {
      let index = this.notifications.findIndex(n => n.id == this.currentId);
      this.notifications.splice(index, 1);
      this.hideModal();
      await this.$store.dispatch("setClosedNotification", this.currentId)
      //await NotificationApi.updateNotification(this.currentId, { closed: true });
    },
    /**
     * Ferme la boîte modal
     */
    hideModal() {
      EventBus.$emit("hideModal");
    },
    /**
     * Récupère la liste de notifications qui ne sont pas "closed"
     */
    async fetchNotifications() {
      const response = await this.$store.dispatch("getAllNotification", {
        campaign: this.currentCampaign.id,
        farm_id: this.currentFarm && this.currentFarm.id,
      })
      this.notifications = response.filter(n => n && n.closed == false);
    },

    /**
     * Fonction permettant d'imprimer la listes des alertes
     */
    async printAlert(){
      let queryParams = {format: "pdf", campaign: this.currentCampaign.name};
      queryParams = new URLSearchParams(queryParams).toString();
      window.open(formatServerUrl(`/reports/news/phyto/`) + "?" + queryParams, "_blank");
    }
  },
  watch: {
    async currentCampaign() {
      if (!this.globalFiltersLoaded) return;

      this.fetchNotifications();
    },
    async currentFarm() {
      this.fetchNotifications();
    },
  },
};
</script>

<style lang="scss" scoped>
.max-height {
  max-height: 20vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.notification-row {
  padding: 1em 0;
  font-size: 0.7rem;
  cursor: pointer;
  border-bottom: 1px solid $grey;

  p {
    margin: 0;
  }
}

.notification-row:hover {
  background: $light-grey;
}

.unread {
  background: $warning-background;
}
.warning_bg {
  background: #D5F9FF;
}
.alert_bg {
  background: #FFDED5;
}

.print-icon {
  width: 1.4em;
  cursor: pointer;
}
</style>
