<template>
  <div class="bg-grey p-2">
    <b-form-radio-group v-model="mode" @input="$emit('updateRow', rowId, payload)" stacked>
      <b-form-radio value="select">
        {{ $t("cropfields.details.select_island") }}
        <b-form-select
          v-if="mode == 'select'"
          class="mt-2 mb-4"
          v-model="islands.selected"
          @input="$emit('updateRow', rowId, payload)"
          :options="islandsOptions"
        ></b-form-select>
      </b-form-radio>
      <b-form-radio value="create">
        {{ $t("cropfields.details.create_island") }}
      </b-form-radio>
    </b-form-radio-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["rowId", "field", "cellData"],
  data() {
    return {
      mode: "select",
      value: null,
      islands: {
        selected: -1,
      },
    };
  },
  created() {
    this.value = this.cellData;
  },
  computed: {
    ...mapGetters({
      islandsPrettyList: "cropfield/islandsPrettyList",
    }),
    islandsOptions() {
      return this.islandsPrettyList
        .filter(e => e.island != undefined)
        .map(e => {
          return {
            text: e.display,
            value: e.island,
          };
        })
        .sort((a, b) => {
          return a.value - b.value;
        });
    },
    payload() {
      let payload = {};
      if (this.mode == "select") payload["island"] = this.islands.selected;
      else payload["island"] = null;
      return payload;
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-grey {
  background: $light-grey;
  border-radius: 10px;
}
</style>
