import { commonGetters, commonMutations, commonActions } from "./commons.js";
import HarvestsStorageApi from "@/api/harvestsStorage.api";

import Vue from "vue";

export const ALL_HARVESTS = "GET ALL HARVESTS";
export const ONE_HARVEST = "GET HARVEST BY ID";
export const CREATE_HARVEST = "CREATE HARVEST";
export const UPDATE_HARVEST = "UPDATE HARVEST";
export const CLEAN_HARVEST = "CLEAN HARVEST";
export const DELETE_HARVEST = "DELETE HARVEST";
export const HARVEST_LOADING = "LOAD HARVEST";
export const GET_HARVEST = "GET HARVEST ALREADY LOADED BY ID";

export const GET_FILTERS_HARVEST_BY = "GET ALL FILTER HARVEST";
export const HARVEST_SET_FILTER = "SET FILTER HARVEST";
export const HARVESTS_FILTERED = "GET HARVESTS FILTERED";
export const HARVEST_REMOVE_FILTER = "REMOVE FILTER HARVEST";
export const HARVEST_CLEAN_FILTER = "CLEAN FILTER HARVEST";
export const MAJ_HARVEST_FILTER = "MAJ HARVEST FILTER";

export const CLEAN_CURRENT_HARVEST = "CLEAN CURRENT HARVEST";
export const CLEAN_HARVEST_LOADED = "CLEAN HARVEST LOADED";
export const GET_HARVESTS_ORDERED = "GET HARVESTS ORDERED";
export const SET_HARVEST_RESEARCHED = "SET HARVEST RESEARCHED";

export default {
  namespaced: true,
  state: {
    // commons
    _entries: {},
    currentId: null,
    filterData: {},
    filterSelection: {},
    filters: [],
    currentEntry: {},
    newEntry: {},
    entryUpdated: {},
    entryLoaded: true,
    searchQuery: "",
    page: 1,
    maxPage: 0,

    //others
    harvests: [],
    allHarvests: [],
    currentHarvest: {},
    newHarvest: {},
    harvestUpdated: {},
    harvestsMustBeToClean: false,
    harvest_loaded: true,
    allfiltersHarvest: {
      crop: "",
      warehouse_name: "",
    },
    harvestFilters: [],
    harvestFilterSelected: {},
    editedEntry: {},
    harvestResearched: "",
  },
  getters: {
    ...commonGetters(),
    harvests: (state) => state.harvests,
    allHarvests: (state) => state.allHarvests,
    currentHarvest: (state) => state.currentHarvest,
    harvestsMustBeToClean: (state) => state.harvestsMustBeToClean,
    getAllfiltersHarvest: (state) => state.allfiltersHarvest,
    getHarvestFilters: (state) => state.harvestFilters,
    harvestFilterSelected: (state) => state.harvestFilterSelected,
  },
  mutations: {
    ...commonMutations(),
    [CLEAN_HARVEST](state, data) {
      state.harvestsMustBeToClean = data;
    },

    [ALL_HARVESTS]: (state, harvests) => {
      state.harvests = harvests;
    },

    [ONE_HARVEST]: (state, payload) => {
      state.currentHarvest = payload.harvest;
      state.currentEntry = payload.harvest;
      state.allHarvests.push(payload.harvest);
    },

    [CREATE_HARVEST]: (state, payload) => {
      state.newHarvest = payload.harvest;
    },

    [UPDATE_HARVEST]: (state, payload) => {
      state.harvestUpdated = payload.harvest;
    },

    [DELETE_HARVEST]: (state) => {
      state.currentEntry = {};
    },

    [HARVEST_LOADING]: (state, status) => {
      state.harvest_loaded = !status;
    },

    [MAJ_HARVEST_FILTER]: (state, payload) => {
      state.allfiltersHarvest = payload;
    },

    [HARVESTS_FILTERED]: (state, machines) => {
      state.harvests = harvests;
    },

    [GET_FILTERS_HARVEST_BY]: (state, payload) => {
      const element = payload.elements.map((e) => ({ value: e[1], id: e[0], checked: false }));
      if (payload.index >= state.harvestFilters.length) {
        state.harvestFilters.push(element);
      } else {
        state.harvestFilters.splice(payload.index, 0, element);
      }
    },

    [HARVEST_SET_FILTER]: (state, { filterName, elementId }) => {
      let currentId = null;
      if (filterName in state.filterSelection) currentId = state.filterSelection[filterName];
      Vue.set(state.filterSelection, filterName, currentId == elementId ? null : elementId);
    },

    [HARVEST_CLEAN_FILTER]: (state) => {
      state.harvestFilters.map((f) => f.map((e) => (e.checked = false)));
      state.harvestFilterSelected = {};
    },

    [HARVEST_REMOVE_FILTER]: (state, payload) => {
      Vue.delete(state.harvestFilterSelected, payload.key);
    },

    [GET_HARVEST]: (state, payload) => {
      let harvestId = payload.id;

      let harvest = state.allHarvests.filter(function (elem) {
        if (elem.id == harvestId) return elem;
      });

      state.currentEntry = harvest[0];
    },

    [CLEAN_CURRENT_HARVEST]: (state) => {
      state.currentEntry = {};
    },

    [CLEAN_HARVEST_LOADED]: (state, payload) => {
      let harvestId = payload.id;

      let harvest = state.allHarvests.filter(function (elem) {
        if (elem.id == harvestId) return elem;
      });

      const filter = state.allHarvests.indexOf(harvest[0]);
      if (filter > -1) state.allHarvests.splice(filter, 1);
    },

    [GET_HARVESTS_ORDERED]: (state, harvests) => {
      state.harvests = harvests;
    },

    [SET_HARVEST_RESEARCHED]: (state, payload) => {
      state.harvestResearched = payload;
    },

    setEditedEntryValue: (state, data) => {
      state.editedEntry = { ...state.editedEntry, ...data };
    },

    resetEditedEntry: (state) => {
      state.editedEntry = {};
    },
  },
  actions: {
    ...commonActions(),
    async getAllHarvestsStorages({ commit, state }) {
      if (state.harvests.length > 0) return;
      commit(HARVEST_LOADING, true);
      const crop = await dispatch("getFilterValueId", "crop");
      const warehouse_name = await dispatch("getFilterValueId", "warehouse_name");
      const response = await HarvestsStorageApi.getAllHarvestsStorages(crop, warehouse_name);
      commit(ALL_HARVESTS, response.data);
      commit(HARVEST_LOADING, false);
      return response.data;
    },

    async getHarvestsStorage({ commit }, payload) {
      commit(HARVEST_LOADING, true);
      const response = await HarvestsStorageApi.getHarvestsStorage(payload.idActivity);
      commit(ALL_HARVESTS, response.data);
      commit(HARVEST_LOADING, false);
      return response.data;
    },

    async getHarvest({ commit }, payload) {
      commit(HARVEST_LOADING, true);
      const response = await HarvestsStorageApi.getHarvest(payload.id);
      commit(ONE_HARVEST, { harvest: response.data });
      commit("setCurrentId", { id: payload.id });
      commit("updateEntry", { id: payload.id, data: response.data });
      commit(HARVEST_LOADING, false);
      return response.data;
    },

    async createHarvest({ commit }, payload) {
      commit(HARVEST_LOADING, true);
      const response = await HarvestsStorageApi.createHarvest(payload.data);
      commit(CREATE_HARVEST, { harvest: response.data });
      commit(HARVEST_LOADING, false);
      return response.data;
    },

    async updateHarvest({ commit }, payload) {
      commit(HARVEST_LOADING, true);
      const response = await HarvestsStorageApi.updateHarvest(payload.data, payload.id);
      commit(UPDATE_HARVEST, { harvest: response.data });
      commit(HARVEST_LOADING, false);
      return response.data;
    },

    async deleteHarvest({ commit }, payload) {
      commit(HARVEST_LOADING, true);
      const response = await HarvestsStorageApi.deleteHarvest(payload.id);
      commit(HARVEST_LOADING, false);
      return response.data;
    },

    async updateEntry({ commit }, payload) {
      commit(HARVEST_LOADING, true);
      const response = await HarvestsStorageApi.updateHarvest(payload.data, payload.id);
      commit(UPDATE_HARVEST, { harvest: response.data });

      commit("updateEntry", { harvest: response.data });
      commit("setCurrentId", { id: payload.id });

      commit(HARVEST_LOADING, false);
      return response.data;
    },

    async fetchEntries({ dispatch, commit, rootGetters }) {
      commit(HARVEST_LOADING, true);
      const crop = await dispatch("getFilterValueId", "crop");
      const warehouse = await dispatch("getFilterValueId", "warehouse");

      const harvestId = await dispatch("getFilterValueRaw", "entryId");

      let currentFarm = rootGetters["farm/currentFarm"];
      if (currentFarm) currentFarm = currentFarm.id;

      const response = await HarvestsStorageApi.getAllHarvestsStorages({
        campaign: rootGetters.getCampaign.id,
        crop: crop,
        warehouse: warehouse,
        harvestId: harvestId,
        farmId: currentFarm,
      });
      commit("setEntries", { entries: response.data });

      commit(HARVEST_LOADING, false);
    },

    async getHarvestsFiltered({ commit }, payload) {
      commit(HARVEST_LOADING, true);
      const response = await HarvestsStorageApi.getAllHarvestsStorages(payload.crop, payload.warehouse);
      commit(MAJ_HARVEST_FILTER, payload);
      commit(HARVESTS_FILTERED, response.data);
      commit(HARVEST_LOADING, false);
      return response.data;
    },

    async getAllFiltersHarvest({ commit, rootGetters }, { param, campaign, params, values }) {
      commit(HARVEST_LOADING, true);
      if(values) {
        // if there is hard setted values in config, use it
        commit("setFilterData", { name: attrib, elements: values });
        return values;
      } else {
        let currentFarm = rootGetters["farm/currentFarm"];
        if (currentFarm) currentFarm = currentFarm.id;
      
        const copyParams = { ...params }
        if (copyParams.hasOwnProperty(param)) {
        delete copyParams[param];
        }

        const response = await HarvestsStorageApi.getFiltersHarvestStorage(param, campaign, currentFarm, copyParams);
        commit("setFilterData", { name: param, elements: response.data });
          
      }
      commit(HARVEST_LOADING, false);

    },
    async initFilters({ state, dispatch, rootGetters }) {
      state.filters.forEach(async (filter) => {
        await dispatch("getAllFiltersHarvest", {
          param: filter.name,
          campaign: rootGetters.getCampaign.id,
        });
      });
    },

    async updateFilterSelection({ commit, state, rootGetters, dispatch }, { filterName, elementId }) {
        commit("setFilter", {
            filterName: filterName,
            elementId: elementId,
        });

        const filters = await dispatch("getAllFiltersValues")

        state.filters.forEach(async (filter) => {
            await dispatch("getAllFiltersHarvest", {
            param: filter.name,
            params: filters,
            values: "",
            campaign: rootGetters.getCampaign.id
            });
        });

    },

    async setEditedEntryValue({ commit }, data) {
      await commit("setEditedEntryValue", data);
    },

    async deleteEntry({ commit }, payload) {
      commit(HARVEST_LOADING, true);
      const response = await HarvestsStorageApi.deleteHarvest(payload.id);
      commit(DELETE_HARVEST);
      commit("deleteEntry", { id: payload.id });

      commit(HARVEST_LOADING, false);
      return response;
    },

    async createEntry({ state, commit, dispatch, rootGetters }, { entry }) {
      commit(HARVEST_LOADING, true);
      let newEntry = await HarvestsStorageApi.createHarvest(entry);
      commit("addEntry", newEntry.data);
      commit("setCurrentId", { id: newEntry.data.id });

      commit(HARVEST_LOADING, false);
    },
  },
};
