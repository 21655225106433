import ActivityTypesApi from "@/api/activityTypes.api";
import { commonMutations } from "./commons.js";

export const ALL_ACTIVITYTYPES = "GET ALL ACTIVITYTYPES";
export const ONE_ACTIVITYTYPE = "GET ONE ACTIVITYTYPE";
export const ACTIVITYTYPE_LOADING = "LOAD ACTIVITYTYPE";
export const ACTIVITYTYPES_LOADING = "LOAD ACTIVITYTYPES";

export default {
  state: {
    activityTypes: [],
    currentActivityType: {},
    activityType_loaded: true,
    activityTypes_loaded: true,
  },
  getters: {
    currentActivityType: state => state.currentActivityType,
    activityTypes: state => state.activityTypes,
    activityTypeLoaded: state => state.activityType_loaded,
    activityTypesLoaded: state => state.activityTypes_loaded
  },
  mutations: {
    ...commonMutations,
    [ALL_ACTIVITYTYPES]: (state, activityTypes) => {
      state.activityTypes = activityTypes;
    },

    [ONE_ACTIVITYTYPE]: (state, activityType) => {
      state.currentActivityType = activityType;
    },

    [ACTIVITYTYPE_LOADING]: (state, status) => {
      state.activityType_loaded = !status;
    },

    [ACTIVITYTYPES_LOADING]: (state, status) => {
      state.activityTypes_loaded = !status;
    },
  },
  actions: {
    async getAllActivityTypes({ commit }) {
      commit(ACTIVITYTYPES_LOADING, true);
      const response = await ActivityTypesApi.getActivityTypes();
      commit(ALL_ACTIVITYTYPES, response.data);
      commit(ACTIVITYTYPES_LOADING, false);
      return response.data;
    },
    async getOneActivityType({ commit }, payload) {
      commit(ACTIVITYTYPE_LOADING, true);
      const response = await ActivityTypesApi.getOneActivityType(payload.id);
      commit(ONE_ACTIVITYTYPE, response.data);
      commit(ACTIVITYTYPE_LOADING, false);
      return response.data;
    },
    async checkIfFallow({ commit }, payload) {
      commit(ACTIVITYTYPE_LOADING, true);
      const response = await ActivityTypesApi.getOneActivityType(payload.id);
      commit(ACTIVITYTYPE_LOADING, false);
      return response.data;
    },
    async getActivityTypeFromMachineTypes({commit}, machineTypes) {
      commit(ACTIVITYTYPE_LOADING, true);
      let payload = {machineTypes: machineTypes.join(",")}
      const response = await ActivityTypesApi.getActivityTypeFromMachineTypes(payload);
      commit(ONE_ACTIVITYTYPE, response.data);
      commit(ACTIVITYTYPE_LOADING, false);
      return response.data;
    }
  },
};
