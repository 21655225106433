import Gateway from "./base";

export default class InputStockApi {
  /**
   * Get All inputStocks
   *
   */
  static getInputStocks(params) {
    if (params == undefined) params = { limit: 5000 };
    else params.limit = 5000;
    return Gateway.get("/api/inputStock/", params);
  }

  /**
   * Get All filters inputStock
   *
   */
  static getFiltersInputStock(query, query2) {
    let params = { attrib: query };
    if (query2 !== "") params.productType__productFamily = query2;
    return Gateway.get("/api/inputStock/filtre/", params);
  }

  /**
   * Get inputStocks by productType, available, productType__productFamily
   *
   */
  static getInputStocksFiltered(params) {
    return Gateway.get("/api/inputStock/", params);
  }

  /**
   * Get one inputStock by id
   *
   * @param id
   */
  static getInputStock(id) {
    return Gateway.get(`/api/inputStock/${id}/`);
  }

  /**
   * Create inputStock
   *
   * @param data
   */
  static createInputStock(data) {
    return Gateway.post(`/api/inputStock/`, data);
  }

  /**
   * Update inputStock
   *
   * @param data
   * @param id
   */
  static updateInputStock(data, id) {
    return Gateway.patch(`/api/inputStock/${id}/`, data);
  }

  /**
   * delete inputStock
   *
   * @param id
   */
  static deleteInputStock(id) {
    return Gateway.patch(`/api/inputStock/${id}/`, { toRemove: true });
  }

  /**
   * Get inputStock feature
   *
   * @param id
   */
  static getInputStockFeatures(id) {
    return Gateway.get(`/api/inputStock/${id}/features/`);
  }


  /**
   * Get the maximum and minimum doses of a mixture of products
   */
  static getMixedDoses(products, percents) {
    const params = {products: products, percents: percents};
    return Gateway.get(`/api/inputStock/mixed_doses/`, params);
  }

  /**
   * Get the name of a mixture of products
   */
  static getMixedName(products, percents) {
    const params = {products: products, percents: percents};
    return Gateway.get(`/api/inputStock/mixed_name/`, params);
  }

  /**
   * Get available dose units and quantity units for an inputStock
   * @param {number} id
   */
  static getInputStockUnits(id) {
    return Gateway.get(`/api/inputStock/${id}/units/`)
  }

  /**
   * Get the last inventory and the estimated stock of an input
   * @param {number} id
   */
  static getStockState(id) {
    return Gateway.get(`/api/inputStock/${id}/stockstate/`)
  }

  /*
    * get a file containing a report on inputStock state, with several formats of file
    */
    static reportInputsStockState(params) {
    const headers = {}

    if ( params.format ) {
      headers['Accept'] = "application/" + params.format;
      delete params.format;
    }
    if ( params.pacage == 'all' ) {
      delete params.pacage;
    }
    if ( params.farm_id == "all" ) {
      delete params.farm_id;
    }

    return Gateway.get(`/reports/inputsStock/state/`, params, headers, "arraybuffer");
  }
}
