<template>
  <!-- Formulaire d'édition d'un lieu -->
  <div class="pt-3" v-if="editMode">
    <!-- Nom du lieu -->
    <b-row  class="m-0">
      <b-col>
        <h4 class="subtitle-form">{{ $t("locations.details.name") }}</h4>
        <b-input  @input="addName" :value="currentEntry.name" size="sm" required/>
      </b-col>
    </b-row>
    <!-- Type de lieu -->
    <b-row  class="m-0">
      <b-col>
        <h4 class="subtitle-form">{{ $t("locations.details.type") }}</h4>
        <Dropdown
          ref="dropdown"
          class="shape-custom-select"
          :options="locationTypes"
          label="designation"
          :value="this.currentLocationType"
          @input="selectedType => addType(selectedType)"
          required
        />
      </b-col>
    </b-row>
    <!-- Balise associée -->
    <b-row class="m-0" v-if="this.currentLocationType && this.currentLocationType.canOwnBeacon">
      <b-col>
        <h4 class="subtitle-form">{{ $t("locations.details.beacon") }}</h4>
        <Dropdown
          ref="dropdown2"
          class="shape-custom-select"
          :options="beaconsavailable"
          label="name"
          :value="this.currentBeacon"
          @input="selectedBeacon => addBeacon(selectedBeacon)"
          required
        />
      </b-col>
    </b-row>
  </div>
  <!-- Affichage d'informations sur un lieu -->
  <div class="pt-3" v-else>
    <!-- Type du lieu -->
    <b-row class="m-0">
      <b-col>
        <h4 class="subtitle-form">{{ $t("locations.details.type") }}</h4>
        <FieldInfo
          :value = currentEntry.locationType_designation
        />
      </b-col>
    </b-row>
    <!-- Balise associée -->
    <b-row class="m-0" v-if="this.currentLocationType && this.currentLocationType.canOwnBeacon">
      <b-col>
        <h4 class="subtitle-form">{{ $t("locations.details.beacon") }}</h4>
        <table v-if="currentEntry.beacon">
        <tr>
          <td class="text-infos bg_white">
            <img :src="beaconIcon" alt="beacon" />
          </td>

          <td
            class="text-infos bg_white"
          >{{ currentEntry.beacon_name }}</td>
        </tr>
        </table>
        <FieldInfo v-else
          :value="currentEntry.beacon_name"
          :empty="empty"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import Dropdown from "@/components/harvestStorage/edit/Dropdown.vue";
import FieldInfo from "@/components/commons/details/FieldInfo.vue";
import beacon from "@/assets/images/beacon.svg";
import { i18n } from "@/i18n.js";

export default {
  data() {
    return {
      empty: i18n.t("locations.details.not_filled"),
      currentBeacon: {},
      beaconIcon: beacon,
    }
  },

  /**
   * @description Récupération des données du backend
   */
  async created() {
    if(!this.newMode){
      if(this.currentId && this.currentEntry) {
        // await this.$store.dispatch("location/getBeaconsAvailable", {id: this.currentId});
        // await this.$store.dispatch("getOneLocationType", {id: this.currentEntry.locationType});
        // this.getCurrentBeacon();
        // this.addType(this.currentLocationType);

      }
    }else {
      await this.$store.dispatch("resetLocationType");
      await this.resetEditedEntry;
      await this.$store.dispatch("location/getBeacons");
      await this.$store.dispatch("getAllLocationTypes");
    }

  },
  computed: {
    ...mapGetters({
      currentId: "location/currentId",
      currentEntry: "location/currentEntry",
      currentEditedEntry: "location/editedEntry",
      entries: "location/entries",
      entriesChecked: "location/entriesChecked",
      campaigns: "campaigns",
      locationTypes: "locationTypes",
      currentLocationType: "currentLocationType",
      beaconsavailable: "location/beaconsavailable"
    }),
    ...mapMutations({
      resetEditedEntry: "location/resetEditedEntry",
    }),
    Locations() {
      if (this.entriesChecked.length == 0) return this.currentEntry ? [this.currentEntry] : [];
      return this.entries.filter(e => this.entriesChecked.includes(e.id));
    },

  },
  methods: {
    /**
     * @description stocke le type sélectionné dans currentEditedEntry
     * @param {object} type le type de lieu sélectionné
     */
    async addType(type) {
      if(type) {
        this.currentEditedEntry.locationType = type.id;
        await this.$store.dispatch("getOneLocationType", {id: type.id});
      }else {
        this.currentEditedEntry.locationType = null;
        await this.$store.dispatch("resetLocationType");
      }
    },

    /**
     * @description stocke la balise sélectionnée dans currentEditedEntry
     * @param {object} beacon la balise sélectionnée
     */
    addBeacon(beacon) {
      this.currentBeacon = beacon;
      if(beacon) {
        this.currentEditedEntry.beacon = beacon.id;
      }else {
        this.currentEditedEntry.beacon = null;
      }
    },

    /**
     * @description stocke le nom entré par l'utilisateur dans currentEditedEntry
     * @param {string} name le nom du lieu
     */
    addName(name) {
      this.currentEditedEntry.name = name;
    },

    /**
     * @description détermine la balise associée au lieu qui a été sélectionné
     */
    getCurrentBeacon() {
      this.currentBeacon = {};
      this.beaconsavailable.map(beacon => {
        if(beacon.id == this.currentEntry.beacon) {
          this.currentBeacon = beacon;
        }
      });
      return this.currentBeacon;

    }
  },

  watch: {
    currentId: {
      deep: true,
      handler: async function() {
        // await this.$store.dispatch("location/getBeaconsAvailable", {id: this.currentId});
        await this.$store.dispatch("getOneLocationType", {id: this.currentEntry.locationType});
        // this.getCurrentBeacon();
        // this.addType(this.currentLocationType);
        this.currentEditedEntry.locationType = this.currentLocationType.id;
      }
    },
    beaconsavailable: {
      deep: true,
      handler: function() {
        this.getCurrentBeacon();
      }
    },

    currentLocationType: {
      handler: function() {
        if(!this.currentLocationType || !this.currentLocationType.canOwnBeacon) {
          this.addBeacon(null);
        }
      }
    }
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    newMode: {
      type: Boolean,
      default: false,
    },
  },
  components: {
   Dropdown,
   FieldInfo,

  },
};
</script>

<style lang="scss" scoped>
h6 {
  font-weight: bold;
}

.bg_white {
  background-color: $reverse-color;
}
</style>
