<template>
  <div v-if="productInfo">
    <!-- Back button to close form -->
    <backButton :on-click="onBack" />

    <br />
    <br />

    <table v-if="productInfo">
      <tr>
        <!-- Input name -->
        <td>
          <h3 class="title-form">{{ productInfo.name }}</h3>
          <span class="text-infos"> {{ productInfo.productFamily_designation }} </span>
        </td>
      </tr>
    </table>

    <br />

    <!-- Main informations -->
    <InfosInput />

    <!-- Product usages -->
    <ProductUsage v-if="this.productInfo.productFamily_code == 'phyto'" />

    <!-- Product composition -->
    <ProductComposition v-if="this.productInfo.productFamily_code == 'phyto'" />

    <!-- Fertilizer -->
    <div
      v-if="
        ['engsy', 'engor'].includes(this.productInfo.productFamily_code)
      "
    >
      <FertilizerComposition />
    </div>

    <!-- Amendement -->
    <div
      v-else-if="
        ['amend', 'irrig'].includes(this.productInfo.productFamily_code)
      "
    >
      <Amendement />
    </div>

    <!-- Semance -->
    <div v-else-if="this.productInfo.productFamily_code === 'sem'">
      <DensitySemance />
    </div>

    <br />
    <br />

    <!-- See product risks -->
    <button
      v-show="productInfo.hasOwnProperty('riskClass') && productInfo.riskClass.length > 0"
      class="btn align-bottom button-product-risk"
      @click="onRisks"
    >
      {{ $t("inputStock.details.see_product_risks") }}
    </button>
  </div>
</template>
<script>
import FertilizerComposition from "@/components/inputstock/details/FertilizerComposition.vue";
import DensitySemance from "@/components/inputstock/details/DensitySemence.vue";
import InfosInput from "@/components/inputstock/details/InfosInput.vue";
import ProductUsage from "@/components/inputstock/details/ProductUsage.vue";
import ProductComposition from "@/components/inputstock/details/ProductComposition.vue";
import backButton from "@/components/commons/BackButton.vue";
import Amendement from "@/components/inputstock/details/Amendement.vue";
import { mapGetters } from "vuex";

export default {
  created: async function() {
    await this.$store.dispatch("getProductInfo", { id: this.$route.params.product_id });
  },
  async beforeRouteUpdate(to, from, next) {
    await this.$store.dispatch("getProductInfo", { id: to.params.product_id });
    next();
  },
  data() {
    return {};
  },
  methods: {
    onRisks() {
      this.$router.push({ name: "inputStock.risks" });
    },
    onBack() {
      this.$router.go(-1);
    },
  },
  components: {
    backButton,
    InfosInput,
    ProductUsage,
    ProductComposition,
    FertilizerComposition,
    DensitySemance,
    Amendement,
  },
  computed: {
    ...mapGetters([
      "currentProduct",
      "allProducts",
      "inputFormByPtype",
      "productInfo",
    ]),
    ...mapGetters({
      currentInputStock: "inputStock/currentEntry",
    }),
  },
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: separate;
  border-spacing: 5px 0px;
}
/**
 * Params button product risks.
 */
.button-product-risk {
  background-color: $background-text-color;
  width: 100%;
  margin-right: 0px;
}

.button-product-risk:hover {
  background: $secondary-light-color;
}

.shape-second-cell {
  padding-bottom: 10px;
}
</style>
