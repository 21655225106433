<template>
  <div class="position-relative">
    <h3 class="home-widget-title my-3">{{ mainGraph.title }}</h3>
    <div ref="chart"></div>
    <div ref="tooltip" class="tooltip">
      {{ tooltipData.label }}<br />
      {{ tooltipData.display_value }}
      ({{ Math.round((tooltipData.value / total) * 100) }}%)
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

import { mapGetters, mapActions } from 'vuex';

import { EventBus } from '@/eventBus.js';

export default {
  data() {
    return {
      tooltipData: {},
      mainGraph: {},
      total: null,
    };
  },
  async mounted() {
    // on page initialization, when global filters are loaded
    EventBus.$on('globalFiltersLoaded', async ({ hasFarmSelected }) => {
      this.refreshData();
    });

    // if components loads (tab changed) and filters are already loaded
    if (this.globalFiltersLoaded) {
      this.refreshData();
    }
  },
  props: {
    cropOrder: {
      type: Number,
      default: 1
    }
  },
  methods: {
    ...mapActions({
      fetchCropsGraphData: 'home/fetchCropsGraphData',
    }),
    plotChart() {
      // clear the graph
      d3.select(this.$refs.chart)
        .selectAll('*')
        .remove();

      if (!this.mainGraph.values || this.mainGraph.values.length == 0) return;

      // set the dimensions and margins of the graph
      var width = 300;
      let height = 300;
      let margin = 10;

      // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
      var radius = Math.min(width, height) / 2 - margin;

      let tooltip = d3.select(this.$refs.tooltip).style('opacity', 0);

      // append the svg object to the div called 'my_dataviz'
      var svg = d3
        .select(this.$refs.chart)
        .append('svg')
        .attr('preserveAspectRatio', 'xMinYMin meet')
        .attr('viewBox', `0 0 ${width} ${height}`)
        .append('g')
        .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

      var data = _.clone(this.mainGraph.values);
      data.sort(function(a, b) {
        return a.value - b.value;
      });

      let maxValue = Math.max(...Object.values(data.map(e => parseFloat(e.value))));
      this.total = Object.values(data.map(e => parseFloat(e.value))).reduce((a, b) => a + b);

      let visibleThreshold = 0.2 * maxValue;

      // Compute the position of each group on the pie:
      var pie = d3.pie().value(function(d) {
        return d.value.value;
      });
      var data_ready = pie(d3.entries(data));

      // shape helper to build arcs:
      var arcGenerator = d3
        .arc()
        .innerRadius(0)
        .outerRadius(radius);

      let t = this;
      // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
      let g = svg
        .selectAll('mySlices')
        .data(data_ready)
        .enter()
        .append('g')
        .on('mousemove', function(d, i) {
          tooltip.style('left', d3.event.pageX + 10 + 'px').style('top', d3.event.pageY - 15 + 'px');
        })
        .on('mouseover', function(d, i) {
          t.tooltipData = d.data.value;

          d3.select(this)
            .transition()
            .duration('50')
            .attr('opacity', '.80');
          tooltip
            .transition()
            .duration('50')
            .style('opacity', 1);
        })
        .on('mouseout', function(d, i) {
          d3.select(this)
            .transition()
            .duration('50')
            .attr('opacity', '1');
          tooltip
            .transition()
            .duration('50')
            .style('opacity', 0);
        });

      g.append('path')
        .attr('d', arcGenerator)
        .attr('fill', function(d) {
          return d.data.value.color;
        });

      g.append('text')
        .text(function(d) {
          return d.data.value.value > visibleThreshold ? d.data.value.label : '';
        })
        .attr('transform', function(d) {
          return 'translate(' + arcGenerator.centroid(d) + ')';
        })
        .style('text-anchor', 'middle')
        .style('font-size', '12px')
        .style('fill', 'white')
        .attr('class', 'tooltip-label');
    },
    /**
     * Permet de passer du graphe des 1eres Cultures à celui des 2ndes Cultures
     */
    changeGraph() {
      if(this.cropOrder == 1)
        this.mainGraph = this.graph;
      else
        this.mainGraph = this.secondGraph;
    },
    /**
     * Rafraichit les graphes
     */
    async refreshData() {
      await this.fetchCropsGraphData({cropOrder: 1});
      await this.fetchCropsGraphData({cropOrder: 2});
      this.changeGraph();
      this.plotChart();
    }
  },
  computed: {
    ...mapGetters({
      graph: 'home/cropsGraphData',
      secondGraph: 'home/secondCropsGraphData',
      currentCampaign: 'getCampaign',
      currentFarm: 'farm/currentFarm',
      globalFiltersLoaded: 'user/globalFiltersLoaded',
    }),
  },
  watch: {
    currentCampaign() {
      if (!this.globalFiltersLoaded) return;
      this.refreshData();
    },
    currentFarm() {
      this.refreshData();
    },
    cropOrder: {
      handler() {
        this.changeGraph();
        this.plotChart();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.tooltip {
  position: fixed;
  text-align: center;
  padding: 5px 10px;
  background: #ffffff;
  border: 1px solid rgb(130, 130, 130);
  color: #313639;
  border-radius: 4px;
  pointer-events: none;
  font-size: 1rem;
}

text.tooltip-label {
  stroke: black;
  stroke-width: 4px;
}
</style>
