<template>
  <div>
    <!-- Back button to close form -->
    <div class="details-buttons" v-if="!(editMode || mapEditMode)">
      <editButton :on-click="onEdit" />
      <closeButton class="ml-3" :on-click="onBack" />
    </div>
    <div class="details-buttons" v-else>
      <backButton :on-click="onBack" />
    </div>

    <div>
      <b-container class="p-0">
        <b-row no-gutters>
          <!-- Titre -->
          <b-col cols="9" class="pr-3">
            <h3 v-if="newMode" class="title-form">
              {{ $t("locations.details.new_location") }}
            </h3>
            <h3 v-if="onlyOneLocation" class="title-form">
              {{ currentLocation.name }}
            </h3>
          </b-col>

        </b-row>

        <!-- Edition en mode Carte -->
        <b-row v-if="mapEditMode">
          <b-col>
            <LocationMapCutTools v-if="mapEditMode" class="mt-2" />
          </b-col>
        </b-row>
      </b-container>

      <div v-if="!mapEditMode">
        <!-- Edition en mode liste -->
        <b-row >
          <b-col class="p-0">
            <LocationInfos class="mt-2" :editMode="editMode" :newMode="newMode"/>
          </b-col>

        </b-row>

        <b-container class="mt-4 p-0" v-if="editMode">
          <b-row align-h="end" align-v="end">
            <b-col cols="12">
              <button class="btn align-bottom button-save-input mb-3" @click="updateRow">
                {{ $t("cropfields.details.save") }}
              </button>
            </b-col>
            <b-col cols="12" v-if="onlyOneLocation">
              <button class="btn align-bottom button-danger-input" @click="deleteLocation">
                {{ $t("cropfields.details.delete") }}
              </button>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>
<script>
import editButton from "@/components/commons/EditButton.vue";
import closeButton from "@/components/commons/CloseButton.vue";

import backButton from "@/components/commons/BackButton.vue";
import LocationInfos from "@/components/locations/details/LocationInfos.vue";

import LocationMapCutTools from "@/components/locations/details/map-cut-tools/LocationMapCutTools.vue";


import { mapGetters, mapActions, mapMutations } from "vuex";

import { EventBus } from "@/eventBus.js";

import { askConfirm } from "@/utils/commons";

// turf
import * as turf from "@turf/turf";

export default {
  data() {
    return {
      editMode: false,
      editingGeometry: false,
      mapEditMode: false,
      newMode: false,
    };
  },
  created: function () {
    this.init(this.$route.params.location_id);
  },
  methods: {
    ...mapActions({
      updateLocation: "location/updateEntry",
      deleteEntry: "location/deleteEntry",
      deleteEntryOfStore: "location/deleteEntryOfStore",
      createEntry: "location/createEntry",
      fetch: "location/fetchEntries",
    }),
    ...mapMutations({
      setMapCutMode: "location/setMapCutMode",
      resetCurrentId: "location/resetCurrentId",
    }),
    async enableModifyGeometry() {
      this.editingGeometry = true;
    },
    disableModifyGeometry(save) {
      this.editingGeometry = false;
      EventBus.$emit("disableModifyGeometry", { save: save });
    },
    init(location_id) {
      // mode "edit geometry on map" ?
      // .. if we have 'editGeometry' in route
      if (this.$route.params.mode == "editGeometry") {
        this.mapEditMode = true;
      }
      // .. if we are creating a new location on map
      if (this.$route.name == "location.map.new") {
        this.mapEditMode = true;
      }

      // mode "location creation" ?
      // .. if we don't have a location_id
      if (!location_id) {
        this.$store.commit("location/resetEntriesChecked");
        this.$store.dispatch("location/resetCurrentId");
        this.editMode = true;
        this.newMode = true;
        return;
      }

      this.$store.commit("location/setLastEntryDisplayed", location_id);
      this.$store.dispatch("location/getOneLocation", { id: location_id });
    },
    async onEdit() {
      // set variable to "edit" for form
      await this.$store.dispatch("location/getBeaconsAvailable", {id: this.currentId});
      await this.$store.dispatch("getAllLocationTypes");

      this.editMode = true;
    },
    async onBack() {

      if (this.mapEditMode) {
        this.mapEditMode = false;
        if(!this.newMode)
          this.$router
            .push({ name: "location.map.details", params: { location_id: this.currentEntry.id } })
            .catch((error) => {
              if (error.name != "NavigationDuplicated") {
                throw error;
              }
          });
        else
          this.$router.push({name: "location.map"})

        this.disableModifyGeometry(false);
        this.editMode = false;
        return;
      }

      if (this.newMode || this.editMode) {
        if (await askConfirm(this.$t("save_modifications"))) await this.updateRow();
        this.editMode = false;
        if (!this.newMode) return;

        this.newMode = false;
      }

      if (this.$route.name == "location.map.details") {
        this.$router.push({ name: "location.map" });
      } else {
        this.$router.push({ name: "location.list" });
      }

      this.$store.commit("location/resetLastEntryDisplayed");
      this.$store.commit("location/resetEntriesChecked");
      this.$store.dispatch("location/resetCurrentId");
    },
    async updateRow() {
      await this.$store.dispatch("location/saveEditedEntry");

      if (this.newMode) {
        EventBus.$emit("selectOnList", this.currentId);
        return;
      }

      if (this.editMode) {
        this.editMode = false;
        return;
      }
    },
    async deleteLocation() {
      let confirmation = confirm(this.$t("locations.details.warning_delete"));
      if (!confirmation) return;

      const rowId = this.currentLocation.id;

      await this.deleteEntry({ id: rowId });
      this.resetCurrentId();

      if (this.isMapView) {
        await this.$router.push({ name: "location.map" });
        EventBus.$emit("plotLocationFields");
      } else {
        await this.$router.push({ name: "location.list" });
      }

      EventBus.$emit("clearSelection");
    },

  },
  components: {
    editButton,
    backButton,
    closeButton,
    LocationInfos,
    LocationMapCutTools,
  },
  computed: {
    ...mapGetters({
      currentId: "location/currentId",
      currentEntry: "location/currentEntry",
      currentLocationHasGeometry: "location/currentLocationHasGeometry",
      entriesChecked: "location/entriesChecked",
      checkedGeometries: "location/checkedGeometries",
      locations: "location/entries",
      getCampaign: "getCampaign",
      user: "user/user",
    }),
    onlyOneLocation() {
      return this.entriesChecked.length <= 1 && this.currentLocation;
    },
    currentLocation() {
      if (this.entriesChecked.length == 0) return this.currentEntry;
      return this.locations.find((e) => e.id == this.entriesChecked[0]);
    },
    isMapView() {
      return this.$route.name == "location.map.details";
    },
  },
  props: {
    details: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    $route() {
      if (this.$route.params.mode == "editGeometry") {
        this.mapEditMode = true;
      } else if (this.mapEditMode) {
        this.mapEditMode = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/**
 * Edit Button position.
 */
.position-edit-button {
  text-align: center;
  position: relative;
  top: 35px;
  left: 20px;
}

a {
  color: $subtitle-color;
  text-decoration: underline;
}
a:hover {
  color: $primary-color;
}

/**
 * Params button save input.
 */
.button-save-input {
  background-color: $background-text-color;
  width: 100%;
}

.button-save-input:hover {
  background: $secondary-light-color;
}

.details-tabs .nav-tabs .nav-link {
  border: 0;
  padding: 0.5rem 0;
  background: transparent;
  font-size: 0.9em;
  color: black;
}

.details-tabs .nav-item {
  margin-right: 1.5rem;
}

.details-tabs .tab-pane.active {
  background: transparent;
}

.details-tabs .nav-tabs .nav-link.active {
  background: transparent;
  border-bottom: 4px solid black;
}
</style>
