<template>
	<div>
    <div v-if="!pageLoaded">
      <button class="btn btn-primary" type="button" disabled>
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        {{ $t("inputStock.details.loading_data") }}
      </button>
    </div>
    <div v-show="pageLoaded">
      <!-- Guide -->
      <div class="text-infos-without-bg">
        <p class="m-0">
          {{ $t("cropfields.details.outline_to_defined_alert") }}
        </p>
        <hr />
      </div>

      <!-- Choix du contour -->
      <b-form-group class="px-3 pt-2">
        <b-form-radio v-model="selected" :value="SUGGESTED">
          <span class="text-infos-without-bg"> {{ $t("cropfields.details.suggested_outline") }} </span>
        </b-form-radio>
        <b-form-radio v-model="selected" :value="RPG">
          <span class="text-infos-without-bg"> {{ $t("cropfields.details.RPG_plot") }} </span>
        </b-form-radio>
        <b-form-radio v-model="selected" :value="CADASTRAL">
          <span class="text-infos-without-bg"> {{ $t("cropfields.details.cadastral_plot") }} </span>
        </b-form-radio>
      </b-form-group>

      <!-- Guide text -->
      <p class="bg-white text-infos">
        <span v-if="selected == SUGGESTED ">{{ $t("cropfields.details.select_suggested_outline") }}</span>
        <span v-if="selected == RPG ">{{ $t("cropfields.details.select_rpg_guide") }}</span>
        <span v-if="selected == CADASTRAL ">{{ $t("cropfields.details.select_cadastral_cropfield") }}</span>
      </p>

      <!-- Multiple sélection guide text -->
      <p v-if="[RPG, CADASTRAL].includes(selected) && !mergeError" class="bg-white text-infos">
        <span>{{ $t("cropfields.details.select_multiple_cropfield") }}</span>
      </p>

      <!-- Merge error text -->
      <p v-if="mergeError" class="bg-white text-infos text-danger">
        <span> {{ $t("cropfields.details.merge_not_allowed") }}</span>
      </p>

      <!-- List of RPG dates -->
      <b-row v-if="mode == RPG_MODE && selected == RPG" class="mb-3">
        <b-col>
          <h4 class="subtitle-form mb-2 text-nowrap">{{ $t("cropfields.details.rpg_year") }}</h4>
          <b-form-select v-model="currentRpgOption" :options="rpgCapabilities" @input="showRPG('rpg')"></b-form-select>
        </b-col>
      </b-row>

      <b-form>

        <!-- Name and surface form, shown when the geometry has been drawn -->
        <MCT_DefineGeometry_Form v-if="showForm" />

        <!-- Bouton enregistrer -->
        <b-container class="mt-3 p-0">
          <b-row>
            <b-col cols="12">
              <b-button class="button-default align-bottom" type="button" @click="save" :disabled="!enableRegistration || !showForm">
                {{ $t("cropfields.details.save") }}
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
      <DialogModal/>
    </div>
	</div>
</template>

<script>
import MapCutToolsMixin from "@/components/cropfields/details/map-cut-tools/MapCutToolsMixin";
import MCT_DefineGeometry_Form from "@/components/cropfields/details/map-cut-tools/define-geometry/MCT_DefineGeometry_Form.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { intervalMinutes, addDuration } from "@/utils/commons";
import { EventBus } from "@/eventBus.js";
import DialogModal from '@/components/commons/DialogModal.vue';

export default {
  data() {
		return {
      SUGGESTED: "suggested",
      RPG: "rpg",
      CADASTRAL: "cadastral",
			selected: "suggested",
			activity: {},
			RPG_MODE: "rpg_mode",
      mode: null,
      currentRpgOption: null,
      showForm: false,
      enableRegistration: true,
      pageLoaded: false,
      mergeError: false,
		};
	},
	mixins: [
    MapCutToolsMixin,
  ],
  components: { MCT_DefineGeometry_Form, DialogModal },

	async created() {
    this.pageLoaded = false;
    EventBus.$on("selectRPGFeature", this.submitGeometry);
    EventBus.$on("showGeneratedOutline", this.onChanges);

		this.activity = await this.$store.dispatch("activity/getOneActivity", {id: this.$route.params.activity_id});
    // Si la géométrie est nulle à ce niveau
    // => on vient du tableau de bord et le calcul du contour a été fait après affichage de cette vue
    if (this.currentEntry.geometry == null){
      await this.$store.dispatch("cropfield/getOneCropfield", { id: this.currentActivity.cropField[0] });
    }

    // Si la géométrie est nulle à ce niveau
    // => Le contour de la parcelle n'a pas pu être généré
    // On l'indique à l'utilisateur et on sélectionne l'option "Parcelles du RPG" par défaut
    if (this.currentEntry.geometry == null) {
      this.selected = this.RPG;
      EventBus.$emit("showAlertModal", {
        title: this.$t("warning"),
        message: this.$t("cropfields.details.no_outline_computed_alert"),
        okBtn: () => {},
        cancelBtn: () => {},
        okTitle: "ok",
        cancelTitle: "",
      });
    }

		this.fetchRPGCapabilities();
    this.pageLoaded = true;

	},

  beforeDestroy(){
    EventBus.$off("selectRPGFeature", this.submitGeometry);
    EventBus.$off("showGeneratedOutline", this.onChanges);
  },

	methods: {
    ...mapMutations({
      resetEditedEntry: "cropfield/resetEditedEntry",
    }),
    ...mapActions({
      setEditedEntryValue: "cropfield/setEditedEntryValue",
      saveEditedEntry: "cropfield/saveEditedEntry",
      fetchRPGCapabilities: "cropfield/fetchRPGCapabilities",
      fetchEntries: "cropfield/fetchEntries",
      fetchIslandGeometries: "cropfield/fetchIslandGeometries",
    }),

    /**
     * actions à mener lors du choix de la méthode de définition du contour
     */
		onChanges() {
			this.resetEditedEntry();
      EventBus.$emit("disableRPG");
			if(this.selected == this.SUGGESTED) {
        this.showForm = true;
				this.modifyGeometry();
        this.enableRegistration = true;
			}
			if(this.selected == this.RPG) {
        this.enableRegistration = false;
				EventBus.$emit("disableModifyGeometry", { save: false}, {displayActive: false});
				this.startRPGMode('rpg');
			}
			if(this.selected == this.CADASTRAL) {
        this.enableRegistration = false;
				EventBus.$emit("disableModifyGeometry", { save: false}, {displayActive: false});
				this.startRPGMode('cadastral');
			}

		},
		/**
     * Pick geometry from vector layer
     */
    async startRPGMode(source='rpg') {
      this.mode = this.RPG_MODE;
      if (!this.currentRpgOption) this.currentRpgOption = this.lastRPGOption?.value;

      if (this.currentRpgOption) this.showRPG(source);
    },
    showRPG(source = 'rpg') {
      EventBus.$emit("showRPG", {rpgName: this.currentRpgOption, source: source});
    },

		/**
     * Save
     */
    async save() {
      EventBus.$emit("disableOutlineMode");
      if(this.selected == this.SUGGESTED) {
        EventBus.$emit("disableModifyGeometry", { save: true });
      }
      this.setEditedEntryValue({
        toBeConfirmed: false,
      });
      // disable modify interaction on map
      EventBus.$emit("disableModifyGeometry", { save: false });

      // save geometry, name & surface
      await this.saveEditedEntry({ fetchAll: true });

      // plot the geometry
      EventBus.$emit("plotFields", false);

      EventBus.$emit("disableRPG");


      // redirect to edit geometry page
      this.$router.push({
        name: "cropfield.map.details",
        params: { cropfield_id: this.currentEntry.id, mode: "editMode"},
      });
    },

    /**
     * Modification de la parcelle éditée lors de la sélection d'une parcelle dans le RPG
     */
    submitGeometry({ geometry, surface, mergeError }) {
      this.setEditedEntryValue({
        surface: surface,
        geometry: geometry,
      });
      this.mergeError = mergeError;
      if (!geometry && !surface) {
        this.enableRegistration = false;
        return;
      }
      this.showForm = true;

      // if we are on rpg mode, set the "receive in exchange" value to false in form
      if (this.mode == this.RPG_MODE) {
        this.$nextTick(() => {
          EventBus.$emit("setReceivedInExchangeCheckboxValue", true);
        });
        this.enableRegistration = true;
      }
    },
	},
	computed: {
    ...mapGetters({
      currentActivity: "activity/currentEntry",
			currentEntry: "cropfield/currentEntry",
      editedEntry: "cropfield/editedEntry",
			rpgCapabilities: "cropfield/rpgCapabilities",
			entries: "cropfield/entries",
    }),
		lastRPGOption() {
      return this.rpgCapabilities[this.rpgCapabilities.length - 1];
    },
  },

	watch: {
    selected() {
      this.onChanges();
    }
	}
}
</script>
<style lang="scss" scoped>

</style>