<template>
	<div>
		<ProductInfo/>
		<ProductDensity/>
		<ProductDose/>
		<ProductAMM/>
		<ProductCompositionDetail/>
		<ProductOtherFeature/>
		<SeedTreatment/>	
		<ProductUsage/>
		<ProductRisksButton/>
		<ProductPercentageNPro/>
		<KeqN/>
	</div>
</template>

<script>
import ProductInfo from "./ProductInfo.vue";
import ProductDensity from "./ProductDensity.vue";
import ProductDose from "./ProductDose.vue";
import ProductAMM from "./ProductAMM.vue";
import ProductCompositionDetail from "./ProductCompositionDetail.vue";
import ProductOtherFeature from "./ProductOtherFeature.vue";
import ProductUsage from "./ProductUsage.vue";
import ProductRisksButton from "./ProductRisksButton.vue";
import ProductPercentageNPro from "./ProductPercentageNPro.vue";
import KeqN from "./keqN.vue";
import SeedTreatment from "./SeedTreatment.vue";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import { mapGetters } from "vuex";
export default {
	mixins: [DetailUpdateMixin], 
	components: {
    ProductInfo,
    ProductDensity,
		ProductDose,
		ProductAMM,
		ProductCompositionDetail,
		ProductOtherFeature,
		ProductUsage,
		ProductRisksButton,
		ProductPercentageNPro,
		KeqN,
		SeedTreatment,
  },
	async created() {
    if (this.currentEntry.product_id) {
      await this.$store.dispatch("getProductInfo", {
        id: this.currentEntry.product_id,
      });
    }
  },
	computed: {
		...mapGetters({
      inputsStock: "inputStock/entries",
      currentEntry: "inputStock/currentEntry",
    })
	}
}
</script>
<style lang="scss" scoped></style>