import { i18n } from "@/i18n.js";

export const config = {
  title: i18n.t("locations.title"),
  countingElements: i18n.t("locations.list.countingElements"),
  addButton: i18n.t("locations.list.add"),
  module: "location",
  loadEntriesOnScroll: true,
  scrollToSelection: false,
  searchField: "name",
  routes: {
    index: {
      name: "location",
    },
    list: {
      name: "location.list",
    },
    map: {
      name: "location.map",
    },
    new: {
      // the new route depends on the current route : if we are on the map or not
      name: (currentRoute) => (currentRoute.includes("map") ? "location.map.new" : "location.new"),
    },
    details: {
      name: "location.details",
    },
    params: {
      id: "location_id",
    },
  },
  defaultPivot: 1,
  columns: {
    0: {
      name: "name",
      sort: true,
      title: i18n.t("locations.list.name"),
      hideOnDetails: false,
      pivot: {
        enable: true,
        groupDisplay: {},
        displayHeader: true,
        sortHeaderField: [(group) => group.name.toLowerCase()],
        sortHeaderOrder: ["asc"],
        sortDataFields: [(location) => location.name.toLowerCase()],
        sortDataOrders: ["asc"],
      },
      show: true,
      priority: 9,
    },
    1: {
      name: "locationType_designation",
      sort: true,
      title: i18n.t("locations.list.type"),
      hideOnDetails: false,
      pivot: {
        enable: true,
        groupDisplay: {},
        displayHeader: true,
        sortHeaderField: [(group) => group.name.toLowerCase()],
        sortHeaderOrder: ["asc"],
        sortDataFields: [(location) => location.name.toLowerCase()],
        sortDataOrders: ["asc"],
      },
      show: true,
      priority: 9,
    },
    2: {
      name: "beacon_name",
      sort: true,
      title: i18n.t("locations.list.beacon"),
      hideOnDetails: false,
      pivot: {
        enable: true,
        groupDisplay: {},
        displayHeader: true,
        sortHeaderField: [(group) => group.name.toLowerCase()],
        sortHeaderOrder: ["asc"],
        sortDataFields: [(location) => location.name.toLowerCase()],
        sortDataOrders: ["asc"],
      },
      show: true,
      priority: 9,
    },
  },
  filters: [
    {
      name: "locationType",
      show: false,
      title: i18n.t("locations.list.type"),
    },
  ],
};
