<template>
    <div>
      <b-row>
        <b-col>
          <h4 class="mb-3">
            <i>{{$t('cropfields.fertilization.needsCalculation.Pi_Colza')}}</i>
          </h4>
        </b-col>
      </b-row>
  
      <b-row>
        <b-col class="col-6" >
          <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.mv_eh">{{$t('cropfields.fertilization.needsCalculation.mv_eh')}}</h4>
          <NumericInput
          inputRef="input"
          :value="mv_eh"
          @input="i => {mv_eh = i}"
          @blur="updateMv_eh()"
          :disabled="!edit_mode"
        />
        </b-col>
        <b-col class="col-6"> 
          <h4 class="subtitle-form mb-2" v-b-tooltip.hover.html="fertiToolTips.mv_sh">{{$t('cropfields.fertilization.needsCalculation.mv_sh')}}</h4>
          <NumericInput
          inputRef="input"
          :value="mv_sh"
          @input="i => {mv_sh = i}"
          @blur="updateMv_sh()"
          :disabled="!edit_mode"
        />
        </b-col>
      </b-row>
    </div>
  </template>

<script>
import NumericInput from "@/components/commons/NumericInput.vue";

export default {
  name: "fertilization-colza",
  data(){
    return{
      edit_mode: false,
      mv_eh: null,  
      mv_sh: null,
    } 
  },
  created() {
    this.init();
  },
  props: [
    'editMode', 
    'fertilisationNeedsCalculation', 
    'fertilisationNeedsCalculationList', 
    'fertilisationNeedsCalculationOption',
    'fertiToolTips',
  ],
  methods: {
    init: async function() {
      await this.loadData();
    },
    async loadData(){
      if(typeof this.fertilisationNeedsCalculation != 'undefined') {
        await this.editCheck()
        this.mv_eh = this.fertilisationNeedsCalculation.mv_eh
        this.mv_sh = this.fertilisationNeedsCalculation.mv_sh
      }
    },
    async editCheck(){
      this.edit_mode = this.editMode
    },
    async updateMv_eh(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "mv_eh", value: this.mv_eh });
    },
    async updateMv_sh(){
      await this.$store.dispatch("fertilization/addAtributeChange", { name: "mv_sh", value: this.mv_sh });
    },
  },
  watch: {
    fertilisationNeedsCalculation: {
      handler: 'loadData',
      immediate: true
    },
    editMode: {
      handler: 'editCheck',
      immediate: true
    }
  },
  components: {
    NumericInput,
  },
}; 

</script>

<style>
.disabled-input:disabled {
  background-color: white !important;
  border: none;
}
</style>