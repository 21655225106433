<template>
  <div>
    <!-- Treatment Component : Cropfield geometry for Agro EDI -->
    <!-- Displays a checkbox  -->
    <b-form-checkbox v-model="exported">
      <span class="dropdown-option"> {{ $t("commons.exports_reports.export_cropfields_geometry") }} </span>
    </b-form-checkbox>
  </div>
</template>

<script>

import TreatmentFormPartMixin from "@/components/commons/treatments/TreatmentFormPartMixin";
import { TreatmentFormPart } from "@/models/treatments/Treatment.js";

export default {
  mixins: [TreatmentFormPartMixin],
  data() {
    return {
			exported: false,
    };
  },
  methods: {
    validateForm() {
      this.isValid = true;

			return new TreatmentFormPart("no_cropfield_geometry", !this.exported);
    },
  },
};
</script>

<style>
</style>
