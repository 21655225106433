<template>
  <div v-if="loaded">
    <b-row class="my-2" v-if="keqN != null">
      <b-col cols="8">
        <h4 class="subtitle-form">{{ $t("inputStock.details.percent_mineralizable_nitrogen") }}</h4>
      </b-col>
      <b-col cols="4" class="d-flex align-items-start">
        <div class="bg-text-color text-infos">
          {{ keqN }} %
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import { mapGetters } from "vuex";
export default {
  mixins: [DetailUpdateMixin],
  computed: {
    ...mapGetters({
      inputsStock: "inputStock/entries",
      currentEntry: "inputStock/currentEntry",
      currentProduct: "currentProduct",
    }),
  },
  data() {
    return {
      keqN: null,
      loaded: false
    };
  },
  mounted: function() {
    this.init();
	},
  methods: {
    init(){
      this.loaded = false;
      this.keqN = this.currentEntry.localProduct ? this.currentEntry.percentMineralizableNitrogen : this.currentProduct?.percentMineralizableNitrogen;
      this.loaded = true;
    }
  },
  watch: {
    currentEntry: {
      handler: function() {
        this.init();
      },
      deep: true,
    },
    currentProduct: {
      handler: function() {
        this.init();
      },
      deep: true,
    },
  }
};
</script>

<style lang="scss" scoped></style>
