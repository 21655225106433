import CommonViewIndex from "@/views/common/CommonViewIndex.vue";
import CommonViewList from "@/views/common/CommonViewList.vue";
import CommonViewFiltering from "@/views/common/CommonViewFiltering.vue";
import CommonViewDetail from "@/views/common/CommonViewDetail.vue";
import { generateDetailViews } from "@/views/common/details/commons";

import { views } from "@/views/tour/details/index";

import { config } from "./config";

export default [
  {
    component: CommonViewIndex,
    path: "/activity/tour",
    props: config,
    children: [
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering
        },
        path: "",
        name: "tour.list",
      },
      ...generateDetailViews(views, CommonViewList, CommonViewFiltering, CommonViewDetail),
    ],
  },
];
