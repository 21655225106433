import Gateway from "./base";

export default class NewsApi {
  /**
   * Get all news
   *
   */
  static getNews() {
    return Gateway.get("/api/news/");
  }

  /**
   * Update news
   *
   * @param data
   * @param id
   */
  static updateNews(id, data) {
    return Gateway.patch(`/api/news/${id}/`, data);
  }
}
