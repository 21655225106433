import Gateway from "./base";

export default class inputSupplyApi {
  /**
   * Get All input supplies
   *
   */
  static getAllInputSupplies() {
    return Gateway.get("/api/inputSupply/");
  }

  /**
   * Get one input Supply by id
   *
   * @param id
   */
  static getInputSupply(id) {
    return Gateway.get(`/api/inputSupply/${id}/`);
  }

  /**
   * Get input Supplies by inputStock id
   *
   * @param param
   */
  static getInputSuppliesByInputStock(param) {
    return Gateway.get(`/api/inputSupply/`, param);
  }

  /**
   * Create input supply
   *
   * @param data
   */
  static createInputSupply(data) {
    return Gateway.post(`/api/inputSupply/`, data);
  }

  /**
   * Update input supply
   *
   * @param data
   * @param id

   */
  static updateInputSupply(data, id) {
    return Gateway.patch(`/api/inputSupply/${id}/`, data);
  }

  /**
   * delete input supply
   *
   * @param id
   */
  static deleteInputSupply(id) {
    return Gateway.delete(`/api/inputSupply/${id}/`);
  }
}
