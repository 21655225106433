import CommonViewIndex from "@/views/common/CommonViewIndex.vue";
import CommonViewList from "@/views/common/CommonViewList.vue";
import CommonViewFiltering from "@/views/common/CommonViewFiltering.vue";
import CommonViewDetail from "@/views/common/CommonViewDetail.vue";
import { generateDetailViews } from "@/views/common/details/commons";
import { views } from "@/views/harvestStorage/details/index";
import { config } from "./config";

export default [
  {
    component: CommonViewIndex,
    path: "/yield/harvestStorage",
    props: config,
    children: [
      {
        components: {
          default: CommonViewList,
          filtering: CommonViewFiltering,
        },
        path: "",
        name: "harvestStorage.list",
      },

      ...generateDetailViews(views, CommonViewList, CommonViewFiltering, CommonViewDetail),
    ],
  },
];
