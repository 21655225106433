import NoteApi from "@/api/note.api";
import * as turf from "@turf/turf";
import Vue from "vue";
import { i18n } from "@/i18n.js";

import { project4326, project3857 } from "@/utils/geomatics";

export const ALL_NOTES = "GET ALL NOTES";
export const ONE_NOTE = "GET ONE NOTE";
export const NOTE_LOADING = "LOAD NOTE";
export const NOTES_LOADING = "LOAD NOTES";
export const DELETE_NOTE = "DELETE NOTE";
export const CREATE_NOTE = "CREATE NOTE";
export const UPDATE_NOTE = "UPDATE NOTE";

export const ALL_NOTE_TOPICS = "GET ALL NOTE TOPICS";

export default {
  state: {
    notes: [],
    currentNote: {},
    note_loaded: true,
    notes_loaded: true,
    currentEntry: {},
    newNote: {},
    noteUpdated: {},
    noteGeometries: {},
    noteTopics: [],
  },
  getters: {
    currentNote: state => state.currentNote,
    notes: state => state.notes,
    noteLoaded: state => state.note_loaded,
    notesLoaded: state => state.notes_loaded,
    newNote: state => state.newNote,
    _noteGeometries: (state) => state.noteGeometries,
    noteGeometries: (state) => Object.values(state.noteGeometries),
    noteGeometries3857:(state, getters) => getters.noteGeometries.map((g) => g && g.geometry && project3857(g)),
    noteTopics: state => state.noteTopics,
  },
  mutations: {
    [ALL_NOTES]: (state, notes) => {
      state.notes = notes;
    },

    [ONE_NOTE]: (state, note) => {
      state.currentNote = note;
    },

    [NOTE_LOADING]: (state, status) => {
      state.note_loaded = !status;
    },

    [NOTES_LOADING]: (state, status) => {
      state.notes_loaded = !status;
    },

    [DELETE_NOTE]: state => {
      state.currentEntry = {};
    },
    
    [CREATE_NOTE]: (state, payload) => {
      state.newNote = payload.note;
    },

    [UPDATE_NOTE]: (state, payload) => {
      state.noteUpdated = payload.note;
    },

    [ALL_NOTE_TOPICS]: (state, noteTopics) => {
      state.noteTopics = noteTopics;
    },

    resetNotesGeometry: (state) => {
      state.noteGeometries = {}
    },

    setNoteGeometry: (state, { id, geometry }) => {
      Vue.set(state.noteGeometries, id, geometry);
    },
  },
  actions: {
    async getAllNotes({ commit }, filters) {
      commit(NOTES_LOADING, true);
      const response = await NoteApi.getNotes(filters);
      commit(ALL_NOTES, response.data);
      commit(NOTES_LOADING, false);
      return response.data;
    },
    async getOneNote({ commit }, payload) {
      commit(NOTE_LOADING, true);
      const response = await NoteApi.getOneNote(payload.id);
      commit(ONE_NOTE, response.data);
      commit(NOTE_LOADING, false);
      return response.data;
    },
    async deleteNote({ commit }, { id }) {
      commit(NOTE_LOADING, true);
      const response = await NoteApi.deleteNote(id);
      commit(DELETE_NOTE);
      //commit("deleteEntry", { id: id });
      commit(NOTE_LOADING, false);
      return response;
    },
    async createNote({ commit, rootGetters }, payload) {
      commit(NOTE_LOADING, true);
      let user = rootGetters["user/user"];
      if (user) {
        payload.data.append("author", user.first_name + " " + user.last_name);
      }
      const response = await NoteApi.createNote(payload.data);
      commit(CREATE_NOTE, { note: response.data });
      commit(NOTE_LOADING, false);
      return response;
    },
    async updateNote({ commit }, payload) {
      commit(NOTE_LOADING, true);
      const response = await NoteApi.updateNote(payload.data, payload.id);
      commit(NOTE_LOADING, false);
      return response.data;
    },
    async fetchNoteGeometries({commit, rootGetters}, payload = {}){
      // fetch notes from api
      let data = {detail: true, campaign: rootGetters.getCampaign.id};
      let currentFarm = rootGetters["farm/currentFarm"];
      if (currentFarm) data["farm_id"] = currentFarm.id;
      if (payload.activityId) data["activity"] = payload.activityId;
      let notes = await NoteApi.getNotes(data);
      notes = notes.data;
      // add each notes geometry to the store <id, geometry> dictionnary
      commit("resetNotesGeometry");
      for (const note of notes) {
        if (note.location != null){
          let options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          };
          let date = new Date(note.createdAt).toLocaleDateString("fr-FR", options);
          commit("setNoteGeometry", {
            id: note.id,
            geometry: turf.feature(note.location, {
              text: date + "<br>" + (note.text ? note.text : "") + "<br>" + (note.author ? (i18n.t("cropfields.map.author") + " : " + note.author) : ""),
              image: note.topic_pinFile,
              imageFile: note.imageFile
            })
          });
        }
      }
    },

    async getNoteTopics({ commit }) {
      commit(NOTE_LOADING, true);
      const response = await NoteApi.getNoteTopics();
      commit(ALL_NOTE_TOPICS, response.data );
      commit(NOTE_LOADING, false);
      return response.data;
    },
  },
};
