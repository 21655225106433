<template>
  <div :class="['h-100', { 'overflow-hidden': loading }]">
    <b-overlay
      :show="loading"
      :no-wrap="true"
      variant="white"
      opacity="0.7"
      class="position-absolute w-100 h-100"
      blur="0"
      rounded="sm"
    ></b-overlay>

    <closeButton
      class="detail-close-button close-button-grey"
      v-if="!loading && showCloseButton()"
      :on-click="() => checkChanges(() => closeOrGoBack())"
    />

    <div class="position-relative">
      <div :class="['detail-header', {'mb-1': computedView.subtitle != null, 'mb-4': computedView.subtitle == null}]">
        <!-- <div :class="[{'detail-header-title-1-btn': !showDuplicateButton(), 'detail-header-title-3-btn': showDuplicateButton()}]"> -->
        <div>
          <h3 class="title-form" v-if="computedView.title">{{ computedView.title }}</h3>
          <span class="subtitle-form" v-if="computedView.subtitle">{{ computedView.subtitle }}</span>
        </div>
        <div v-show="!loading" class="ml-1 action-buttons">
          <editButton
            v-show="showEditButton({ entry: currentEntry })"
            v-if="isEditMode() != null && !isEditMode()"
            :on-click="() => onEdit({ entry: currentEntry, router: $router })"
            v-b-tooltip.hover
            :title="this.$t(onHoverEditButton({ entry: currentEntry }))"
          />
          <backButton
            class="ml-1"
            v-if="showBackButton()"
            :on-click="
              () => {
                checkChanges(() => onBack({ entry: currentEntry, router: $router, isEditMode: isEditMode }));
              }
            "
          />
          <duplicateButton
            class="ml-1"
            v-if="showDuplicateButton()"
            :on-click="
              () => {
                checkChanges(() => onDuplicate({ entry: currentEntry, router: $router, isEditMode: isEditMode }));
              }
            "
          />
          <pinButton
            class="ml-1"
            v-if="showPinButton()"
            :on-click="
              () => {
                checkChanges(() => onPin({ entry: currentEntry, router: $router, isEditMode: isEditMode }));
              }
            "
          />
        </div>
      </div>

      <b-form ref="detailForm" @submit="onSubmit" class="pb-5">
        <DetailContainer
          v-if="computedView.rows"
          v-bind="{ rows: filteredRows }"
          :storeReadGetter="storeReadGetter"
          :storeEditedGetter="storeEditedGetter"
          :storeEditAction="storeEditAction"
        />
        <div class="mt-4">
          <b-button class="button-default" type="button" v-if="canSave" @click="onSave">Enregistrer</b-button>
          <div v-if="this.checkOption()" class="relocate-button d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <b-button type="button" class="button-danger-input mt-4" v-if="canDelete" @click="onDelete">Supprimer</b-button>
        </div>
      </b-form>

      <NewsModal />
      <GenerateActivitiesModal/>
      <ExportedActivityWarningModal/>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/eventBus.js";
import { askConfirm } from "@/utils/commons";

import editButton from "@/components/commons/EditButton.vue";
import pinButton from "@/components/commons/PinButton.vue";
import duplicateButton from "@/components/commons/DuplicateButton.vue";
import closeButton from "@/components/commons/CloseButton.vue";
import backButton from "@/components/commons/BackButton.vue";
import DetailContainer from "@/views/common/DetailContainer.vue";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import DataToLoad from "@/models/load/loadedData";

import NewsModal from "@/components/commons/NewsModal.vue";
import GenerateActivitiesModal from '@/components/tour/edit/GenerateActivitiesModal.vue';
import ExportedActivityWarningModal from '@/components/activity/modals/ExportedActivityWarningModal.vue';


export default {
  mixins: [DetailUpdateMixin],
  data() {
    return {
      loading: false,
      computedView: {},
      dataToLoad: null
    };
  },
  provide() {
    const loadedData = {};
    Object.defineProperty(loadedData, "dataToLoad", {
      enumerable: true,
      get: () => this.dataToLoad,
    });
    return { loadedData };
  },
  props: {
    module: {
      type: String,
      default: () => {},
    },
    view: {
      type: Function,
      default: () => {},
    },
    onInit: {
      type: Function,
      default: () => {},
    },
    onBack: {
      type: Function,
      default: () => {},
    },
    onPin: {
      type: Function,
      default: () => {},
    },
    onDuplicate: {
      type: Function,
      default: () => {},
    },
    onEdit: {
      type: Function,
      default: () => {},
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    goToList: {
      type: Function,
      default: () => {},
    },
    goToDetail: {
      type: Function,
      default: () => {},
    },
    isNewMode: {
      type: Function,
      default: () => false,
    },
    isEditMode: {
      type: Function,
      default: () => {},
    },
    showBackButton: {
      type: Function,
      default: () => {},
    },
    showEditButton: {
      type: Function,
      default: () => true,
    },
    showPinButton: {
      type: Function,
      default: () => {},
    },
    showDuplicateButton: {
      type: Function,
      default: () => {},
    },
    showCloseButton: {
      type: Function,
      default: () => {},
    },
    onHoverEditButton: {
      type: Function,
      default: () => "",
    }
  },
  created() {
    this.loadOn();
    this.dataToLoad = new DataToLoad();
  },
  beforeDestroy() {
    // Annuler les listeners aux évènements avant la fermeture du panneau
    EventBus.$off("detailLoadingOn");
    EventBus.$off("detailLoadingOff");
    EventBus.$off("goToList");
  },
  async mounted() {
    EventBus.$on("detailLoadingOn", this.loadOn);
    EventBus.$on("detailLoadingOff", this.loadOff);
    EventBus.$on("goToList", () => this.goToList({ router: this.$router }));

    // add the detail view initialization as initial fetch callback
    // this way we wait entries has been loaded first
    this.$store.dispatch(this.module + "/addInitialFetchCallback", async () => {
      await this.onInit({ route: this.$route });

      // this should not be here
      if(this.$route.name == "activity.edit" && this.currentEntry.cropField && this.currentEntry.activityType){
        await this.$store.dispatch("activity/getAllInputForm");
      }
      await this.initView();

      this.loadOff();
    })

  },
  methods: {
    loadOn() {
      this.loading = true;
      setTimeout(this.loadOff, 10000);
    },
    loadOff() {
      this.loading = false;
    },
    display(element) {
      if (typeof element === "function") {
        return element();
      }
      return element;
    },
    canDoAction(action) {
      if(action === 'save') {
        let forms = this.dataToLoad.formParts;
        if(forms.length) {
          var i;
          for(i=0 ; i<forms.length ; i++) {
            if(!forms[i].validateForm()) return false;
          }
        }
      }
      return this.computedView.actions && action in this.computedView.actions;
    },
    checkOption() {
      return !this.canSave && this.computedView.rows && this.computedView.actions && (this.$route.name.includes("edit") || this.$route.name.includes("new"))
    },
    async initView() {
      this.computedView = await this.view({
        entry: this.currentEntry,
        editedEntry: this.currentEditedEntry,
        route: this.$route,
      });
    },
    async checkChanges(callback) {
      if (this.isEditMode() && (await askConfirm(this.$t("save_modifications")))) {
        // confirm to save changes
        await this.computedView.actions.save();
      }
      callback();
    },

    // Si indiqué dans les paramètres de la route actuelle (goBackOnClose), on retourné sur la route précédente
    // sinon, on appelle la fonction close du module
    closeOrGoBack() {
      if (this.$route.params['goBackOnClose'] === true) {
        this.$router.go(-1);
      } else {
        this.onClose({ entry: this.currentEntry, router: this.$router });
      }
    },

    onSubmit(event) {
      event.preventDefault();
      event.stopPropagation();
    },
    async onSave() {
      EventBus.$emit("onSave");
      let valid = this.$refs.detailForm.reportValidity();
      if (!valid) return;
      const newMode = this.currentEntry.id == null;
      this.loadOn();
      try {
        await this.computedView.actions.save();
      } catch (error) {
        this.loadOff()
        return
      }
      this.loadOff()

      // if we need to display news
      if(this.currentEntry.news?.length){
        // in new mode ? then go to edit first
        if(this.isNewMode()) {
          await this.onEdit({
            entry: this.currentEntry,
            router: this.$router
          });
        }
        // show the news modal
        this.$nextTick(() =>
          EventBus.$emit("showNewsModal", {
            news: this.currentEntry.news,
            // give the go-to-detail route, this is needed for the modal 'ok' button
            goToDetail: () => {
              this.goToDetail({ entry: this.currentEntry, router: this.$router });
              if (this.severalEntries) {
                this.$store.commit(this.module + "/resetEntriesChecked");
              }
            },
            openGenerateActivitiesModal: () => this.openGenerateActivitiesModal(this.currentEntry)
        }))
      }
      // no news ? display detail

      if(!this.currentEntry.news || !this.currentEntry.news.length) {
        await this.$nextTick(() => {
          this.goToDetail({ entry: this.currentEntry, router: this.$router });
          if (this.severalEntries) {
                this.$store.commit(this.module + "/resetEntriesChecked");
              }
        });
        if(this.module == "tour"){
          // Affichage la modal permettant de générer des activities à partir d'une tournée
          this.openGenerateActivitiesModal(this.currentEntry);
        }
      }

      if (newMode) EventBus.$emit("scrollToSelection");
    },

    /**
     * Ouvre la modal proposant de générer des activités à partir d'une tournée
     * si la date de fin de la tournée est antérieure ou égale à la date actuelle
     * si nous sommes passé midi le dernier jour autorisé (date-heure de fin -12H)
     */
    openGenerateActivitiesModal(tour) {
      let endDate = tour.endDate;
      if(endDate && ((new Date(endDate).getTime() - 13*3600*1000) <= Date.now())) {
        this.$nextTick(() => {
          EventBus.$emit("showGenerateActivitiesModal");
        });
      }
    },

    async onDelete() {
      if (await askConfirm(this.$t("confirm_delete"))) {
        this.loadOn();
        await this.computedView.actions.delete({ entry: this.currentEntry });
        this.loadOff();
        this.goToList({ router: this.$router });
      }
    },
  },
  components: {
    editButton,
    pinButton,
    closeButton,
    backButton,
    duplicateButton,
    DetailContainer,
    NewsModal,
    GenerateActivitiesModal,
    ExportedActivityWarningModal
  },
  computed: {
    entriesChecked() {
      return this.$store.getters[this.module + "/entriesChecked"];
    },
    canSave() {
      return this.canDoAction("save");
    },
    canDelete() {
      return this.canDoAction("delete");
    },
    severalEntries() {
        return this.entriesChecked?.length > 1;
    },
    filteredRows() {

      if (this.entriesChecked?.length > 1) {
          if (this.computedView && this.computedView.rows) {
              return this.computedView.rows.filter(row => row.editMany === true);
          }
      }
      return this.computedView ? this.computedView.rows : [];
    },
  },
};
</script>

<style lang="scss">
.detail-header {
  display: flex;
  margin-right: 1.3rem;
  justify-content: space-between;

  &-title-1-btn {
    margin-right: 0.75rem;
    max-width: 80%;
  }
  &-title-3-btn {
    margin-right: 0.75rem;
    max-width: 50%;
  }
}

.detail-close-button {
  position: absolute;
  top: 0;
  right: 0;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.relocate-button {
  background-color: none;
}
</style>
