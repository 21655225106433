<template>
  <v-select
    @input="$emit('updateRow', rowId, payload)"
    :options="soilTypes"
    label="designation"
    v-model="value"
    :appendToBody="true"
    :clearable="true"
    :disabled="disabled"
  >
    <template #no-options>
      {{ $t("dropdown.no_option") }}
    </template>
  </v-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["rowId", "field", "cellData", "disabled"],
  data() {
    return {
      value: null,
    };
  },
  async created() {
    await this.getSoilTypes();
    let soilType = this.soilTypes.find(e => e.designation == this.cellData);
    if (soilType) this.value = soilType;
  },
  computed: {
    ...mapGetters({ soilTypes: "cropfield/soilTypes" }),
    payload() {
      let payload = {};
      if (this.value){
        payload["soilType"] = this.value.id;
      }else{
        payload["soilType"] = null;
      }
      return payload;
    },
  },
  methods: {
    ...mapActions({ getSoilTypes: "cropfield/getSoilTypes" }),
  },
};
</script>

<style lang="scss">
</style>
