import axios from "axios";
import { i18n } from "../i18n.js";

import { EventBus } from "../eventBus.js";

export const AxiosInterceptorSuccess = (response) => {
  if (["post", "patch", "put", "delete"].includes(response.config.method)) {
    if ([200, 201, 202, 204].includes(response.status)) {
      const methods = { post: "created", patch: "updated", put: "updated", delete: "deleted" };
      const verb = methods[response.config.method.toLowerCase()];
      
      const noMessageUrl = ["/api/miscellaneous"]
      if (response.config.url && noMessageUrl.some(path => response.config.url.includes(path))) {
        return;
      }

      const message = i18n.t("toasts.information_status.message") + " " + i18n.t("toasts.information_status." + verb);

      EventBus.$emit("toast", {
        message: message,
        variant: "success",
      });
      // previous color was #1c942b
    }
  }
  return response;
};

export const AxiosInterceptorError = (error) => {
  // Do something with response error
  if (error.response.status === 401) {
    EventBus.$emit("toast", {
      message: i18n.t("toasts.must_be_logged_in"),
      variant: "default",
    });
    window.location.href =
      process.env.NODE_ENV !== "production" ? "http://localhost:8000/admin/" : "/connexion/?next=/";
      
  } else if (error.response.status === 400) {
    const err = error.response.data;
    const text = Object.keys(err)
      .map((e) => e + ": " + err[e])
      .join(" | ");

    EventBus.$emit("toast", {
      message: text,
      variant: "danger",
    });
    
  } else if (error.response.status === 403) {
    EventBus.$emit("toast", {
      message: i18n.t("toasts.forbidden"),
      variant: "default",
    });
  } else if (error.response.status === 404) {
    EventBus.$emit("toast", {
      message: i18n.t("toasts.information_not_found"),
      variant: "warning",
    });
    // previous color was #ff4848
  }
  return Promise.reject(error);
};

export default class Gateway {
  static get(uri, params, headers = {}, responseType = "json") {
    return axios.get(Gateway.formatUri(uri), { params, headers: headers, responseType: responseType });
  }

  static post(uri, data, headers = {}) {
    return axios.post(Gateway.formatUri(uri), data, { headers: headers });
  }

  static put(uri, data, headers = {}) {
    return axios.put(Gateway.formatUri(uri), data, { headers: headers });
  }

  static patch(uri, data, headers = {}) {
    return axios.patch(Gateway.formatUri(uri), data, { headers: headers });
  }

  static delete(uri, headers = {}) {
    return axios.delete(Gateway.formatUri(uri), { headers: headers });
  }

  static formatUri(uri) {
    if (process.env.NODE_ENV !== "production") {
      return `${process.env.API_URI}${uri}`;
    }
    return uri;
  }
}

/**
 * Request class is used for API requests from outside (example : IGN server)
 */
export class Request {
  static get(uri, params, headers = {}, responseType = "json") {
    return axios.get(uri, {
      params,
      headers: headers,
      responseType: responseType,
      withCredentials: false,
    });
  }

  static post(uri, data, headers = {}) {
    return axios.post(uri, data, {headers: headers, withCredentials: false});
  }
}
