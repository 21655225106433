<template>
  <div>
    <div @click="hideOptions()" class="align-div">
      <table>
        <tr>
          <td>
            <!-- Select product type -->
            <h4 class="subtitle-form">Type de produit</h4>

            <v-select
              class="shape-select"
              label="designation"
              :clearable="false"
              :options="productTypesByFamily"
              :value="productTypesByFamily.find(p => p.id == currentInputStock.productType)"
              @input="
                type => {
                  prodTypeSelected = type.id;
                  changeProdType();
                }
              "
            >
            </v-select>
            <!-- 
            <select class="shape-select" v-model="prodTypeSelected" @change="changeProdType()">
              <option v-for="type in productTypesByFamily" v-bind:key="type.id" :value="type.id">
                {{ type.designation }}
              </option>
            </select>
            <div v-if="errorPtype" class="error-params">
              Sélectionner un type de produit
            </div> -->
          </td>

          <!-- Product available -->
          <td style="padding-left:14px;padding-bottom:25px;">
            <h4 class="subtitle-form">En stock</h4>
          </td>
          <td style="padding-bottom:20px;">
            <div class="custom-control custom-switch">
              <input checked type="checkbox" class="custom-control-input" id="prodAvailable" v-model="availableProd" />
              <label class="custom-control-label" for="prodAvailable"></label>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <!-- <br /> -->

    <table>
      <!-- select a product catalog -->
      <tr>
        <td>
          <input
            type="radio"
            id="selectProdCatalog"
            v-model="modelRadio"
            :value="selectProdCatalog"
            @change="isCatalogProduct()"
          />
          <label for="selectProdCatalog"></label>
        </td>
        <td class="select-product-catalaog" @click="isCatalogProduct()">
          <h4 class="subtitle-form">Sélectionner un produit du catalogue</h4>
        </td>
      </tr>
    </table>

    <!-- Search select product catalog -->
    <div class="main-div-custom-select">
      <div v-show="selectProdCatalog === true">
        <!-- <ProductDropdown
          class="shape-custom-select"
          :rows="researchResults"
          :query="productSearchQuery"
          :value="researchResults.find(p => p.product == currentInputStock.product_id)"
          @search="onSearch"
          @input="
            product => product && selectedProduct(product.name, product.id, product.productType_id, product.product)
          "
        /> -->
      </div>

      <button v-show="idProduct" class="btn align-bottom button-product-info" @click="onInfo">
        Information sur le produit
      </button>
    </div>

    <br />

    <table v-show="checkFamily()">
      <!-- select own product -->
      <tr>
        <td>
          <input
            type="radio"
            id="selectOwnProduct"
            v-model="modelRadio"
            :value="selectOwnProduct"
            @click="isCatalogProduct()"
          />
          <label for="selectOwnProduct"></label>
        </td>
        <td class="select-product-catalaog" @click="isCatalogProduct()">
          <h4 class="subtitle-form">Définir mon propre produit</h4>
        </td>
      </tr>
    </table>

    <div @click="hideOptions()" v-show="checkFamily()">
      <!-- Name of product catalog -->
      <div v-show="selectProdCatalog === false">
        <h4 class="subtitle-form">{{ inputFormByPtype.namePrompt }}</h4>
        <input type="text" class="shape-select" v-model="item.text" />
        <div v-if="errorName" class="error-params">
          Entrer un nom de produit
        </div>
      </div>
    </div>

    <br />
  </div>
</template>

<script>
import _ from "lodash";
// import ProductDropdown from "@/components/inputstock/details/ProductDropdown.vue"
import { SET_READ_ONLY } from "@/store/modules/products";
import { SET_PRODUCT_TYPE } from "@/store/modules/productType";
import { mapGetters } from "vuex";
import caretDownSolid from "@/assets/images/caret-down-solid.svg";

export default {
  name: "product",
  async created() {
    this.debouncedGetAnswer = _.debounce(this.onSearch, 500);
    if (this.$route.name === "inputStock.edit") {
      if (this.readOnly) this.selectProdCatalog = true;
      else {
        this.selectOwnProduct = true;
        this.selectProdCatalog = false;
      }

      this.family = this.currentInputStock.productFamily_id;
      this.idProduct = this.currentInputStock.product_id;

      this.prodTypeSelected = this.currentInputStock.productType;
      await this.changeProdType();

      this.item.value = this.currentInputStock.refProduct != null ? this.currentInputStock.refProduct : "";
      this.item.text = this.currentInputStock.name;
      this.researchedProduct = this.currentInputStock.refProduct != null ? this.currentInputStock.name : "";
      this.availableProd = this.currentInputStock.available;
    } else {
      this.family = this.productFamilies_id[this.currentFamily];
      this.firstOpenning = false;
    }
  },
  data() {
    return {
      errorPtype: false,
      errorName: false,
      idProduct: "",
      family: "",
      firstOpenning: true,
      prodSelected: "",
      researchedProduct: "",
      displayOptions: false,
      caretDown: caretDownSolid,
      prodTypeSelected: "",
      availableProd: true,
      selectProdCatalog: true,
      selectOwnProduct: false,
      productName: "",
      modelRadio: true,
      item: {
        value: "",
        text: "",
      },
      productFamilies_id: {
        engsy: 1,
        phyto: 2,
        sem: 3,
        engor: 8,
        amend: 9,
      },
      productSearchQuery: "life",
    };
  },
  components: {
    // ProductDropdown,
  },
  methods: {
    /**
     * Description: Renvoie un objet contenant les informations sur le produit.
     * @returns {Object}  Renvoie un objet contenant les informations sur le produit.
     */
    getProdInfo() {
      this.errorPtype = false;
      this.errorName = false;

      if (this.prodTypeSelected == "") {
        this.errorPtype = true;
        return false;
      }
      if (this.item.text == "") {
        this.errorName = true;
        return false;
      }

      return {
        productType: this.prodTypeSelected,
        productCatalog: this.item.value,
        productName: this.item.text,
        productAvailable: this.availableProd,
      };
    },

    checkFamily() {
      if (this.$route.name === "inputStock.edit") {
        if (this.currentInputStock.productFamily_code != "phyto") return true;
      } else if (this.currentFamily != "phyto") return true;

      return false;
    },

    onInfo() {
      this.$router.push({ name: "inputStock.info", params: { product_id: this.idProduct } });
    },

    /**
     * Description: Renvoie true si c'est un produit du catalogue sinon false.
     * @returns {Boolean}  Return a bool, true if is a catalog product else false.
     */
    typeOfProduct() {
      return this.selectProdCatalog;
    },

    /**
     * Description: Change l'affichage en fonction du mode sélectionner.
     */
    isCatalogProduct() {
      this.family = this.productFamilies_id[this.currentFamily];
      this.selectOwnProduct = !this.selectOwnProduct;
      this.selectProdCatalog = !this.selectProdCatalog;

      this.$store.commit(SET_READ_ONLY, { value: !this.readOnly });
    },

    /**
     * Description: Récupère les produits correspondant au type de produit selectionné.
     */
    async changeProdType() {
      this.$store.commit(SET_PRODUCT_TYPE, { id: this.prodTypeSelected });
      await this.$store.dispatch("getProductsAliases", { id: this.prodTypeSelected });
      await this.$store.dispatch("getinputFormByProductType", { id: this.prodTypeSelected });
      this.item.text = "";
      this.researchedProduct = "";
    },

    displayChoices() {
      this.displayOptions = !this.displayOptions;
    },

    hideOptions() {
      this.displayOptions = false;
    },

    async onSearch(query) {
      this.productSearchQuery = query;

      if (this.$route.name === "inputStock.new") this.family = this.productFamilies_id[this.currentFamily];

      if (this.researchedProduct.length >= 3 && this.researchedProduct != this.prodSelected && !this.firstOpenning)
        await this.$store.dispatch("researchProductsAliases", {
          name: this.researchedProduct,
          idProductType: this.prodTypeSelected,
          idProductFamily: this.family,
        });
      else if (this.researchedProduct == "" && this.item.text == "")
        await this.$store.dispatch("getProductsAliases", { id: this.prodTypeSelected });
      if (this.researchedProduct != this.prodSelected && !this.firstOpenning) this.displayOptions = true;
      this.firstOpenning = false;
    },

    selectedProduct(name, id, productType_id, product) {
      this.idProduct = product;
      if (this.prodTypeSelected == "") this.prodTypeSelected = productType_id;
      this.prodSelected = name;
      this.researchedProduct = name;
      this.displayOptions = false;
      this.item.text = name;
      this.item.value = id;
    },
  },
  computed: {
    ...mapGetters({
      currentInputStock: "inputStock/currentEntry",
      readOnly: "readOnly",
    }),
    ...mapGetters([
      "productTypes",
      "productsAliases",
      "researchResults",
      "currentFamily",
      "productTypesByFamily",
      "inputFormByPtype",
      "productInfo",
    ]),
  },
  watch: {
    researchedProduct: function() {
      this.debouncedGetAnswer();
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 0.5em;
  margin-left: 27px;
}
.error-params {
  color: $danger-color;
}
/**
 * Config css for custom search-select.
 */
.shape-custom-input {
  border: none;
}
/**
 * Config css for custom search-select.
 */
.shape-custom-select {
  width: 225px;
}
/**
 * Config div options select.
 */
.config-options-select {
  border: 1px solid rgb(74, 159, 228);
  background-color: $background-color-light;
  position: absolute;
  z-index: 2;
  padding-left: 2px;
  width: 223px;
  cursor: default;
  overflow-y: scroll;
  max-height: 200px;
}

.select-product-catalaog {
  cursor: pointer;
}

table {
  border-collapse: separate;
  border-spacing: 0px 5px;
}

.config-selection:hover {
  background-color: $background-text-color;
}
/**
 * Config selected product.
 */
.config-selected-product {
  position: absolute;
}

.button-product-info {
  background-color: $background-text-color;
  font-size: 0.8em;
  margin-right: 0px;
  margin-top: 10px;
}

.button-product-info:hover {
  background: $secondary-light-color;
}
</style>
