<template>
  <div class="pl-3">
		<span>{{ theYield }} </span>
		<span v-if="unit" class="pl-1"> {{ unit }} </span>
	</div>
</template>

<script>

export default {
  props: ["rowId", "rowData"],
  computed: {
    theYield() {
      return this.rowData.theYield;
    },
    unit() {
      return this.rowData.unit_unit;
    },
  },
 
};
</script>

<style lang="scss" scoped>

</style>
