import Vue from "vue";

import { EventBus } from "@/eventBus.js";

import CropApi from "../../api/crop.api";

export const commonGetters = () => {
  return {
    entriesListUnsorted: state => Object.values(state._entries),
    entriesList: (state, getters) =>
      _.orderBy(getters.entriesListUnsorted, state.sortFields || [], state.sortOrders || []),
    _entries: state => state._entries,
    entries: (state, getters) => getters.entriesList,
    currentId: state => state.currentId,
    currentEntry: state => (state.currentId ? state._entries[state.currentId] : {}),
    editedEntry: state => state.editedEntry,
    newEntry: state => state.newEntry,
    filters: state => state.filters,
    filterData: state => state.filterData,
    filterSearch: state => state.filterSearch,
    filterSelection: state => state.filterSelection,
    filterSelectionLabels: state => {
      return Object.keys(state.filterSelection)
        .filter(key => {
          return state.filterSelection[key] != null;
        })
        .map(key => {
          let label;
          if (key == "entryId") label = (state.filterEntryLabel || "n°") + state.filterSelection[key];
          else label = state.filterData[key].find(e => e.id === state.filterSelection[key])?.value;

          return {
            filterName: key,
            elementId: state.filterSelection[key],
            label: label,
          };
        });
    },
    searchQuery: state => state.searchQuery,
    entryLoaded: state => state.entryLoaded,
    entriesChecked: state => state.entriesChecked,
    page: state => state.page,
    maxPage: state => state.maxPage,
    fingerprints: state => state.fingerprints,
    isInitialFetchDone: state => state.isInitialFetchDone,
    lastEntryDisplayed: state => state.lastEntryDisplayed,
    inputForm: state => state.inputForm,
    showCheckbox: state => state.showCheckbox,
  };
};

export const commonMutations = () => {
  return {
    setEntries: (state, { entries, add } = { entries: [], add: false }) => {
      let dict = {};
      for (let i = 0; i < entries.length; i++) {
        dict[entries[i].id] = {
          // first include the existing data
          ...(state._entries[entries[i].id] || {}),
          // and erase, if exist, with the new values
          ...entries[i],
        };
      }

      if (add) {
        state._entries = {
          ...state._entries,
          ...dict,
        };
      } else {
        state._entries = dict;
      }
    },

    addEntry: (state, entry) => {
      Vue.set(state._entries, entry.id, entry);
    },

    setSearchQuery: (state, query) => {
      state.searchQuery = query;
    },

    setInitialFetchDone: (state) => {
      state.isInitialFetchDone = true;
    },

    setFilterData: (state, { name, elements }) => {
      Vue.set(
        state.filterData,
        name,
        elements.map(e => ({ id: e[0], value: e[1] })),
      );
    },

    setFilter: (state, { filterName, elementId, force }) => {
      if (force === true) {
        Vue.set(state.filterSelection, filterName, elementId);
        return;
      }

      let currentId = null;
      if (filterName in state.filterSelection) currentId = state.filterSelection[filterName];

      Vue.set(state.filterSelection, filterName, currentId == elementId ? null : elementId);
    },

    setFilterConfig: (state, filters) => {
      state.filters = filters;
    },

    setCurrentId: (state, { id }) => {
      if (typeof id === "string" || id instanceof String) {
        state.currentId = parseInt(id);
      }
      state.currentId = id;
    },

    setNewEntry: (state, { entry }) => {
      state.newInputStock = entry;
    },

    updateEntry: (state, { id, data }) => {
      Vue.set(state._entries, id, {
        ...state._entries[id],
        ...data,
      });
    },

    deleteEntry: (state, { id }) => {
      Vue.delete(state._entries, id);
    },

    setEntriesChecked: (state, { entries }) => {
      state.entriesChecked = entries;
    },

    resetEntriesChecked: (state) => {
      state.entriesChecked = [];
    },

    resetCurrentId: state => {
      state.currentId = null;
    },

    setEntryLoaded: (state, status) => {
      state.entryLoaded = !status;
    },

    setEditedEntryValue: (state, data) => {
      state.editedEntry = { ...state.editedEntry, ...data };
    },

    resetEditedEntry: state => {
      state.editedEntry = {};
    },

    setFingerprint: (state, { key, fingerprint }) => {
      Vue.set(state.fingerprints, key, fingerprint)
    },

    addInitialFetchCallback: (state, { callback }) => {
      state.initialFetchCallbacks.push(callback)
    },

    clearInitialFetchCallbacks: (state) => {
      state.initialFetchCallbacks = []
    },

    setLastEntryDisplayed: (state, id) => {
      state.lastEntryDisplayed = id;
    },

    resetLastEntryDisplayed: (state) => {
      state.lastEntryDisplayed = null;
    },

    clearInputForm(state) {
      state.inputForm = {}
    },

    mergeInputForm(state, data) {
      state.inputForm = {
        ... state.inputForm,
        ... data,
        // inputstock -> make intersection with previous on 'id' field
        inputsStock: !_.isEmpty(state.inputForm) && _.intersectionBy(
          state.inputForm.inputsStock,
          data.inputsStock, "id")
        || data.inputsStock,
        // growthStages -> make intersection with previous on 'id' field
        growthStages: !_.isEmpty(state.inputForm) && _.intersectionBy(
          state.inputForm.growthStages ,
          data.growthStages, "id")
        || data.growthStages,
        // targetFamilies -> make intersection with previous on 'id' field
        targetsFamilies: !_.isEmpty(state.inputForm) && _.intersectionBy(
          state.inputForm.targetsFamilies,
          data.targetsFamilies, "id")
        || data.targetsFamilies
      };

      // targets for each targetFamily --> make intersection with previous on 'id' field
      let incomingTargetFamily = {};
      let newTargets = [];
      let newTargetFamilies = [];
      state.inputForm.targetsFamilies.forEach(el => {
        incomingTargetFamily = data.targetsFamilies.find(fam => fam.id == el.id);
        if (incomingTargetFamily) {
          newTargets = _.intersectionBy(el.targets, incomingTargetFamily.targets, "id");
          incomingTargetFamily = {...el, targets: newTargets};
          newTargetFamilies.push(incomingTargetFamily);
        }
      });

      // doseUnits for each inputStock --> min = max(all of min), max = min(all of max)
      let incomingInputStock = {};
      let incomingDoseUnit = {};
      let newDoseUnits = [];
      let newInputsStock = [];
      let maxDose = 0;
      let minDose = 0;
      state.inputForm.inputsStock.forEach(el => {
        newDoseUnits = [];
        incomingInputStock = data.inputsStock.find(input => input.id == el.id);
        if (incomingInputStock) {
          el.doseUnit.forEach(dose => {
            incomingDoseUnit = incomingInputStock.doseUnit.find(ds => ds.id == dose.id);
            if (incomingDoseUnit) {
              minDose = _.max([dose.min, incomingDoseUnit.min]);
              maxDose = _.min([dose.max, incomingDoseUnit.max]);
              incomingDoseUnit = {...dose, min: minDose, max: maxDose};
              newDoseUnits.push(incomingDoseUnit);
            }
          });
          incomingInputStock = {...el, doseUnit: newDoseUnits};
          newInputsStock.push(incomingInputStock);
        }
      });

      state.inputForm = {
        ... state.inputForm,
        inputsStock: newInputsStock,
        targetsFamilies: newTargetFamilies
      }
    },

    setShowCheckbox(state, data){
      state.showCheckbox = data
    }
  };
};

export const commonActions = () => {
  return {
    getFilterValueId: ({ state }, filterName) => {
      if (!(filterName in state.filterSelection)) return;
      // get the id in filters
      let elementId = state.filterSelection[filterName];
      // if no id we return
      if(elementId == undefined) return
      // get the value in the datas
      let value = state.filterData[filterName].find(e => e.id === elementId);
      return value && value?.id;
    },
    getAllFiltersValues: async({ state, dispatch }) => {
      const data = {}
      state.filters.forEach(async(filter) => {
        const value = await dispatch("getFilterValueId", filter.name)
        if(value) {
          data[filter.name] = value
        }
      })
      return data
    },
    getFilterValueRaw: ({ state }, filterName) => state.filterSelection[filterName],
    resetFilterSelection: async ({ state, dispatch }, {fetch} = {fetch: true}) => {
      for (const filterName in state.filterSelection) {
        const elementId = state.filterSelection[filterName];
        await dispatch("updateFilterSelection", { filterName, elementId });
      }
      if (fetch) await dispatch("fetchEntries");
    },
    setEntriesChecked: ({ commit }, { entries }) => {
      commit("setEntriesChecked", { entries: entries });
    },
    resetCurrentId: ({ commit }) => {
      commit("resetCurrentId");
    },

    setEditedEntryValue({ commit }, data) {
      commit("setEditedEntryValue", data);
    },

    /**
     * Set a filter selection directly by the value
     * Currently, when we use the filters sidebar, it keeps the filter index
     * in store, not the value.
     * It can be useful also to sometimes filter directly by the value
     * This is what this function does
     * @param {string} filterName
     * @param {*} value
     */
    async setFilterByValue({ state, commit, dispatch }, { filterName, value }) {
      // first, check if the filters are initialized
      if(Object.keys(state.filterData).length == 0) {
        await dispatch("initFilters")
      }

      // then, get the element index of the wanted value, and update filter
      const elementId = state.filterData[filterName].findIndex(f => f.value == value)
      commit("setFilter", { filterName, elementId })
    },

    async updateEditedEntry({ state, dispatch, commit }) {
      if (!state.currentId) {
        await dispatch("createEntry", { entry: state.editedEntry });
      } else {
        await dispatch("updateEntry", { id: state.currentId, data: state.editedEntry });
      }

      commit("resetEditedEntry");
    },

    /**
     * Initial fetch process
     * you can implement the beforeInitialFetch & afterInitialFetch
     * in your module actions
     */
    async beforeInitialFetch() {},
    async afterInitialFetch() {},

    // the main method, don't erase it in the module
    async initialFetch({ commit, dispatch, getters }) {
      // reset filter selection when we arrive on the module page
      await dispatch("resetFilterSelection", {fetch: false});
      // reset checked entries
      commit("resetEntriesChecked");

      await dispatch("beforeInitialFetch")

      // as this is the first call to fetchEntries, use initial:true
      await dispatch("fetchEntries", { initial: true })

      EventBus.$emit("scrollToSelection")

      await dispatch("afterInitialFetch")

      await dispatch("resolveInitialFetchCallbacks")

      // as not all the modules have isInitialFetchDone, return is null
      if(getters.isInitialFetchDone == null) return
      commit("setInitialFetchDone")
    },

    // use this to add a callback
    async addInitialFetchCallback({ state, commit, getters }, callback) {
      // 1st case : the initial fetch is already done
      // so we don't wait and invoke the callback directly
      if(!state.initialFetchCallbacks || getters.isInitialFetchDone) {
        await callback()
        return
      }

      // 2st case : the initial fetch is not done yet
      // we must wait, so we add callback to queue
      // it will be resolve later in the initialFetch action
      commit("addInitialFetchCallback", { callback })
    },

    async resolveInitialFetchCallbacks({ state, commit }) {
      if(!state.initialFetchCallbacks) return

      // resolve callbacks one by one
      for(let i = 0; i < state.initialFetchCallbacks.length; i++) {
        await state.initialFetchCallbacks[i]()
      }

      commit("clearInitialFetchCallbacks")
    },

    async getInputForm({ state, getters, commit }, crop) {
      let activityType, stockStore, cropField;

      if (Object.keys(getters.editedEntry).length !== 0) {
        activityType = getters.editedEntry.activityType;
        stockStore = getters.editedEntry.stockStore;
        cropField = [getters.editedEntry.cropField].join(",");
      } else {
        activityType = getters.currentEntry.activityType;
        stockStore = getters.currentEntry.stockStore;
        cropField = (getters.currentEntry.cropField || []).join(",");
      }

      // we need crop & activityType to fetch input form
      if (!crop || crop.length === 0 || !activityType) return;

      // We clear the inputForm at the first crop selected, in case the inputForm has been retrieved when selecting cropfield without crop
      if (getters.crops.length === 0 && !_.isEmpty(getters.inputForm)) {
        commit("clearInputForm");
      }
      const response = await CropApi.getInputForm({ crop, activityType, stockStore, cropField});

      // merge with previous data
      commit("mergeInputForm", response.data);
      return response.data;
    },

    async getAllInputForm({ state, dispatch, commit, getters }) {
      commit("clearInputForm")

      for(let i = 0; i < getters.crops.length; i++) {
        await dispatch("getInputForm", getters.crops[i].id)
      }
    },
  };
};
