<template>
    <div v-if="showIfSemence">
        <b-row class="mb-2" >
            <b-col cols="4">
                <h4 class="subtitle-form">{{ $t("inputStock.details.farm_seed") }}</h4>
            </b-col>
            <b-col cols="8" class="d-flex align-items-center">
                <div class="bg-text-color text-infos " >
                    {{ currentEntry.farmSeed? $t('yes'): $t('no') }}
                </div>
            </b-col>
        </b-row>

        <div v-if="treatmentsExist">
            <b-row class="mb-2">
                <b-col cols="8">
                    <h4 class="subtitle-form">{{ $t("inputStock.details.seed_treatment") }}</h4>
                </b-col>
            </b-row>

            <b-row class="px-0 m-1 br-bottom-0 bg-white text-infos" v-for="treatment in currentEntry.treatments" v-bind:key="treatment.id" >
                <b-col cols="8" class="font-weight-bold">{{ treatment.refProduct_name }}</b-col>
                <b-col cols="4" class="text-right">{{ treatment.dose }} {{ treatment.doseUnit_unit }}</b-col>
            </b-row>
        </div>
    </div>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters({
            currentEntry: "inputStock/currentEntry",
        }),
        treatmentsExist() {
            return this.currentEntry.treatments.length > 0;
        },
        showIfSemence(){
            return this.currentEntry.productFamily_code == "sem";
        },
    }
}

</script>
