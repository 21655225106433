<template>
  <!-- MCT = Map Cut Tool -->
  <!-- this is the view when we modify a geometry on the map for a location -->
  <div>
    <!-- Modify & delete buttons -->
    <div v-if="mapCutMode == 0" class="pt-4">
      <b-button class="button-default" @click="modifyGeometry" :class="{ active: modifyMode }">
        {{ $t("cropfields.details.modify_geometry") }}
      </b-button>

      <!-- Hole mode : Cancel / save buttons -->
      <b-container class="mt-3 p-0" v-if="holeMode">
        <b-row align-h="end">
          <b-col cols="12">
            <p>{{ $t("cropfields.details.make_hole_help") }}</p>
          </b-col>
          <b-col cols="6">
            <b-button class="button-default align-bottom" @click="resetHole">
              {{ $t("cropfields.details.cancel") }}
            </b-button>
          </b-col>
          <b-col cols="6">
            <b-button class="button-default align-bottom" @click="saveHole">
              {{ $t("cropfields.details.save") }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>

      <b-button v-if="selectedHole" class="button-default mt-3 justify-content-start" @click="deleteHole">
        {{ $t("cropfields.details.delete_hole") }}
      </b-button>

      <b-button class="button-default mt-3 mb-3 justify-content-start" @click="enableHoleMode" :disabled="holeMode">
        {{ $t("cropfields.details.make_hole") }}
      </b-button>
      <b-button class="button-danger-input justify-content-start mt-2" @click="deleteGeometry">
        {{ $t("cropfields.details.delete_geometry") }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/eventBus.js";

import MapCutToolsMixin from "@/components/cropfields/details/map-cut-tools/MapCutToolsMixin";

import { convertGeojsonToGeom, project4326 } from "@/utils/geomatics";

import { mapGetters, mapMutations, mapActions } from "vuex";
import { askConfirm } from "@/utils/commons";

export default {
  data() {
    return {
      holeMode: false,
      fields: {
        features: [],
      },
    };
  },
  mixins: [MapCutToolsMixin],
  mounted() {
  },
  props: {
    modifyMode: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapMutations({
      setMapCutMode: "location/setMapCutMode",
      addGeometry: "location/addGeometry",
      resetEditedEntry: "location/resetEditedEntry",
      setCurrentId: "location/setCurrentId",
    }),
    ...mapActions({
      createEntry: "location/createEntry",
      updateEntry: "location/updateEntry",
      updateGeometry: "location/updateGeometry",
    }),

    /**
     * @description supprime la geometrie du lieu sélectionné et rafraîchit la carte
     */
    async deleteGeometry() {
      let confirmation = confirm(this.$t("cropfields.details.geometry_warning_delete"));
      if (!confirmation) return;

      await this.updateEntry({ id: this.currentLocation.id, data: { geometry: null } });
      EventBus.$emit("plotLocationFields");
      EventBus.$emit("resetFeatureHoles");
    },

    /**
     * @description active le mode de création des trous
     */
    async enableHoleMode() {
      this.holeMode = true;

      EventBus.$emit("enableHoleMode");
    },

    /**
     * @description enregistre un trou
     */
    saveHole() {
      this.holeMode = false;
      EventBus.$emit("disableHoleMode", { save: true });
    },

    /**
     * @description annule la création d'un trou
     */
    resetHole() {
      this.holeMode = false;
      EventBus.$emit("disableHoleMode", { save: false });
    },
    
    /**
     * @description supprime un trou
     */
    async deleteHole() {
      if (await askConfirm(this.$t("cropfields.details.confirm_delete_hole"))) {
        EventBus.$emit("deleteHole");
      }
    },
    
    async save() {
      this.resetEditedEntry();
      // first case, we are modifying the geometry
      // then we trigger the save on map side
      if (this.modifyMode) {
        this.$emit("update:modifyMode", false);

        EventBus.$emit("disableModifyGeometry", { save: true });
        return;
      }
      EventBus.$emit("plotLocationFields");

      this.reset();
    },
    async reset() {
      this.fields = {
        features: [],
      };

      EventBus.$emit("disableModifyGeometry", { save: false });

      this.resetEditedEntry();

      this.$emit("update:modifyMode", false);
    },
  },
  computed: {
    ...mapGetters({
      locations: "location/entries",
      _entries: "location/_entries",
      mapCutMode: "location/mapCutMode",
      currentEntry: "location/currentEntry",
      entriesChecked: "location/entriesChecked",
      checkedGeometries: "location/checkedGeometries",
      mapCutGeometries: "location/mapCutGeometries",
      currentLocationHasGeometry: "location/currentLocationHasGeometry",
      getCampaign: "getCampaign",
      selectedHole: "location/selectedHole",
    }),
    currentLocation() {
      if (this.entriesChecked.length == 0) return this.currentEntry;
      return this.locations.find((e) => e.id == this.entriesChecked[0]);
    },
    onlyOneCheckedLocation() {
      return this.entriesChecked.length == 1;
    },
  },
  watch: {
    mapCutGeometries(newValue, oldValue) {
      this.fields = JSON.parse(JSON.stringify(this.mapCutGeometries));
    },
  },
};
</script>

<style lang="scss" scoped>
.cut-icon {
  width: 18px;
}
</style>
