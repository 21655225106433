<template>
  <div>
    <Treatment
      taskName="import_cropfields"
      :taskTimeout="90000"
      :async="true"
      :title="$t('cropfields.import_export.import_cropfields')"
      :startButton="$t('commons.imports.import')"
      :finishButton="$t('commons.imports.finish')"
      :failMessage="$t('cropfields.import_export.import_cropfields_fail')"
      :onBack="onBack"
      :onClose="onClose"
      :onTreatmentDone="onTreatmentDone"
      :customValidation="customValidation"
      :uploadFileAllowedFormats="['xml', 'kml', 'edi', 'zip']"
    >
      <TC_Campaign_NoTemplate />
      <TC_ClientId_NoTemplate />
      <TC_Farm_NoTemplate />
      <TC_Option :groupOptions="this.groupOptions" :returnedOption="this.returnedOption" :titleOption="this.titleOption" />
      <TC_UploadFile />
    </Treatment>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import Treatment from "@/components/commons/treatments/TreatmentExecution";
import TC_Campaign_NoTemplate from "@/components/commons/treatments/TC_Campaign_NoTemplate";
import TC_ClientId_NoTemplate from "@/components/commons/treatments/TC_ClientId_NoTemplate";
import TC_Farm_NoTemplate from "@/components/commons/treatments/TC_Farm_NoTemplate";
import TC_Option from "@/components/commons/treatments/TC_Option";
import TC_UploadFile from "@/components/commons/treatments/TC_UploadFile";

import { isDefined } from "@/utils/commons";

export default {
  data() {
    return {
      groupOptions:[
        { value: "telepac", text: "Telepac (format XML)" },
        { value: "edi", text: "Export EDI (Smag, Mes Parcelles)" },
        { value: "geofolia", text: "Geofolia (export SHP, format zip)" },
        { value: "wiuz", text: "Wiuz (export SHP, format zip)" },
        { value: "mesparcelles", text: "Mes Parcelles (export SHP, format zip)" },
        { value: "scopix", text: "Scopix (format JSON)" }
      ],
      returnedOption: 'source',
      titleOption: "cropfields.import_export.source"
    };
  },
  methods: {
    ...mapActions({
      fetchCropfields: "cropfield/fetchEntries",
      initFilters: "cropfield/initFilters",
      fetchFarms: "farm/fetchFarms",
    }),
    onBack() {
      this.$router.push({ name: this.isOnMap ? "cropfield.map.importexport" : "cropfield.importexport" });
    },
    onClose() {
      this.$router.push({ name: this.isOnMap ? "cropfield.map" : "cropfield.list" });
    },
    customValidation(treatment) {
      let farmId = treatment.formData.get("farm_id");
      let fileFormat = treatment.formData.get("file").name.split(".").pop();
      let source = treatment.formData.get("source")

      // On contrôle si le format ou la source nécessite que la ferme cible soit sélectionnée
      if (
        (!isDefined(farmId) || farmId == "all")
        && !["xml", "json"].includes(fileFormat)
        && (
          (fileFormat == "zip" && !["geofolia"].includes(source))
          || (fileFormat == "edi")
          || (fileFormat == "kml")
      )) {
        treatment.status = null;
        this.$alert(this.$i18n.t("cropfields.import_export.alert_select_farm"));
        return false;
      }

      return true;
    },
    async onTreatmentDone() {
      this.fetchFarms();

      await this.fetchCropfields();
      this.initFilters();
    },
  },
  computed: {
    isOnMap() {
      return this.$route.name.includes("map");
    },
  },
  components: { Treatment, TC_Campaign_NoTemplate, TC_ClientId_NoTemplate, TC_Farm_NoTemplate, TC_UploadFile, TC_Option },
};
</script>
