import Gateway from "./base";

export default class MaintenanceApi {
  /**
   * Get All maintenances
   *
   */
  static getMaintenances() {

    return Gateway.get("/api/maintenances/");
  }

  /**
   * Get one maintenance by id
   *
   * @param id
   */
  static getMaintenance(id) {
    return Gateway.get(`/api/maintenances/${id}/`);
  }

  /**
   * Get maintenance by machine
   *
   * @param machine
   */
  static getMaintenanceByMachine(params) {
    return Gateway.get(`/api/maintenances/`,params);
  }

  /**
   * Create maintenance
   *
   * @param data
   */
  static createMaintenance(data) {
    return Gateway.post(`/api/maintenances/`, data);
  }

  /**
   * Update maintenance
   *
   * @param data
   * @param id
   */
  static updateMaintenance(data, id) {
    return Gateway.patch(`/api/maintenances/${id}/`, data);
  }

  /**
   * delete maintenance
   *
   * @param id
   */
  static deleteMaintenance(id) {
    return Gateway.delete(`/api/maintenances/${id}/`);
  }
}
