import FuelFillApi from "@/api/fuelFill.api";

export const ALL_FUELFILLS = "GET ALL FUELFILLS";
export const ONE_FUELFILL = "GET FUELFILL BY ID";
export const GET_FUELFILL_BY_MACHINE = "GET FUELFILL BY MACHINE ID";
export const CREATE_FUELFILL = "CREATE FUELFILL";
export const UPDATE_FUELFILL = "UPDATE FUELFILL";
export const DELETE_FUELFILL = "DELETE FUELFILL";
export const FUELFILL_LOADING = "LOAD FUELFILL";

export default {
  namespaced: true,
  state: {
    fuelFills: [],
    currentFuelfill: {},
    newFuelFill: {},
    fuelFillUpdated: {},
    fuelFill_loaded: true,
  },
  getters: {
    fuelFills: state => state.fuelFills,
    currentFuelfill: state => state.currentFuelfill,
  },
  mutations: {
    [ALL_FUELFILLS]: (state, fuelFills) => {
      state.fuelFills = fuelFills;
    },

    [ONE_FUELFILL]: (state, payload) => {
      state.currentFuelfill = payload.fuelFill;
    },

    [GET_FUELFILL_BY_MACHINE]: (state, payload) => {
      state.fuelFills = payload;
    },

    [CREATE_FUELFILL]: (state, payload) => {
      state.newFuelFill = payload.fuelFill;
    },

    [UPDATE_FUELFILL]: (state, payload) => {
      state.fuelFillUpdated = payload.fuelFill;
    },

    [DELETE_FUELFILL]: (state, payload) => {
      state.currentFuelfill = {}
    },

    [FUELFILL_LOADING]: (state, status) => {
      state.fuelFill_loaded = !status;
    },
  },
  actions: {
    async getAllFuelFillS({ commit }) {
      commit(FUELFILL_LOADING, true);
      const response = await FuelFillApi.getFuelFillS();
      commit(ALL_FUELFILLS, response.data);
      commit(FUELFILL_LOADING, false);
      return response.data;
    },

    async getOneFuelFill({ commit }, payload) {
      commit(FUELFILL_LOADING, true);
      const response = await FuelFillApi.getFuelFill(payload.id);
      commit(ONE_FUELFILL, { fuelFill: response.data });
      commit(FUELFILL_LOADING, false);
      return response.data;
    },

    async getFuelFillsByMachine({ commit }, payload) {
      commit(FUELFILL_LOADING, true);
      const response = await FuelFillApi.getFuelFillsByMachine(payload);
      commit(GET_FUELFILL_BY_MACHINE, response.data);
      commit(FUELFILL_LOADING, false);
      return response.data;
    },

    async createFuelFill({ commit }, payload) {
      commit(FUELFILL_LOADING, true);
      const response = await FuelFillApi.createFuelFill(payload.data);
      commit(CREATE_FUELFILL, { fuelFill: response.data });
      commit(FUELFILL_LOADING, false);
      return response.data;
    },
    async deleteFuelFill({ commit }, payload) {
      commit(FUELFILL_LOADING, true);
      const response = await FuelFillApi.deleteFuelFill(payload.id);
      commit(DELETE_FUELFILL);
      commit(FUELFILL_LOADING, false);
      return response;
    },
    async updateFuelFill({ commit }, payload) {
      commit(FUELFILL_LOADING, true);
      const response = await FuelFillApi.updateFuelFill(payload.data, payload.id);
      commit(UPDATE_FUELFILL, { fuelFill: response.data });
      commit(FUELFILL_LOADING, false);
      return response.data;
    },
  },
};
