import CropfieldApi from "@/api/cropfield.api";
import nonTreatedZoneApi from "@/api/nonTreatedZone.api";
import Vue from "vue";
import { PROGRESSION } from "@/enums/progession.enum";
import { i18n } from "@/i18n.js";
// import Polygon from "ol/geom/Polygon";
import * as turf from "@turf/turf";

import { project4326, project3857, project2154to3857 } from "@/utils/geomatics";
import { removeDuplicates } from "@/utils/commons";

import { EventBus } from "@/eventBus.js";

import { commonGetters, commonMutations, commonActions } from "./commons.js";

import { zeroPad } from "@/utils/commons";

import { config } from "@/views/cropfields/config";
import { isEmpty } from "lodash";

export const ALL_CROPFIELDS = "GET ALL CROPFIELDS";
export const ONE_CROPFIELD = "GET CROPFIELD BY ID";
export const CROPFIELD_FILTERS = "ALL CROPFIELD FILTER";
export const CREATE_CROPFIELD = "CREATE CROPFIELD";
export const UPDATE_CROPFIELD = "UPDATE CROPFIELD";
export const DELETE_CROPFIELD = "DELETE CROPFIELD";
export const CROPFIELD_LOADING = "LOAD CROPFIELD";
export const CULTURALPROCESS_BY_CROPFIELD = "CULTURALPROCESS BY CROPFIELD";
export const PRODUCTS_BY_CROPFIELD = "PRODUCTS BY CROPFIELD";
export const FILTERED_CROPFIELD = "FILTERED CROPFIELD";
export const FERTILISATION_NEEDS_CALCULATION = "FERTILISATION NEEDS CALCULATION";
export const FERTILISATION_NEEDS_CALCULATION_FORM = "FERTILISATION NEEDS CALCULATION FORM";

// Manage filter store
export const CROPFIELD_SET_FILTER = "SET CROPFIELD FILTER";
export const CROPFIELD_REMOVE_FILTER = "REMOVE CROPFIELD FILTER";
export const CROPFIELD_CLEAN_FILTER = "CLEAN CROPFIELD FILTER";
export const CROPFIELD_RESET_FILTER = "RESET CROPFIELD FILTER LIST";

// Manage fertilization plan view
export const ON_FERTILIZATION_PLAN = "ON FERTILIZATION PLAN";

const ISLAND_FIELD = "island";

export const SET_CROPFIELD_VARIETIES = "SET CROPFIELD VARIETIES";
export const VARIETIES_HAVE_GEOMETRY = "VARIETIES HAVE GEOMETRY";
export const ACTIVE_VARIETIES_LAYER = "ACTIVE VARIETIES LAYER";

export const GET_CROSSES_CROPFIELDS = "GET CROSSES CROPFIELDS";
export const GET_CROSSES_CROPFIELDS_ON_NEAR_CAMPAINGS = "GET CROSSES CROPFIELDS ON NEAR CAMPAIGNS";

export const GET_FERTILIZERS_SINCE_ASSESSMENT_OPENING_DATE = "GET FERTILIZERS SINCE ASSESSMENT OPENING DATE";

// Manage soil
export const GET_SOIL_TYPES = "GET SOIL TYPES";
export const GET_SOIL_DEPTHS = "GET SOIL DEPTHS";

// Temps minimum pour pouvoir rafraichir la liste des :
// - soilTypes, soilDepths
// - 1h
const delayBeforeFetchingNewData = 3600000;

export default {
  namespaced: true,
  state: {
    // commons
    _entries: {},
    sortFields: [(cropfield) => cropfield.name.toLowerCase()],
    sortOrders: ["asc"],
    currentId: null,
    filterData: {},
    filterSelection: {},
    filters: [],
    currentEntry: {},
    newEntry: {},
    entryUpdated: {},
    entryLoaded: true,
    searchQuery: "",
    entriesChecked: [],
    lastEntryDisplayed: null,
    // others
    editedEntry: {},
    cropfields: [],
    cropfieldFilters: [],
    cropFieldFilterSelected: {},
    filteredCropField: {},
    cropfield_loaded: true,
    culturalProcess: {},
    fertilisationNeedsCalculation: {},
    fertilisationNeedsCalculationForm: {},
    productsByCrop: [],
    //geometries: {},
    islands: [],
    varietyGeometries: {},
    mapEditMode: false,
    mapCutMode: 0,
    mapCutGeometries: [],
    selectedHole: null,
    // rpg
    rpgCapabilities: [],
    // if we need to filter the cropfields to a subset
    // null -> no filter
    // a list -> filter with the listed elements
    subsetFilter: null,
    onFertilizationPlan: false,

    cropfieldVarieties: [],
    varieties_have_geometry: false,
    activeVarietiesLayer: false,

    activeTab: "details",
    detailLoadTime: null,
    crossesCropfields: [],
    crossesCropfieldsOnNearCampaigns: [],
    lastCropfieldIdForCross: null,
    lastCropfieldIdForCrossOnNearCampaigns: null,
    cropfieldActivitiesLoadTime: null,

    fertilizersSinceAssessmentOpeningDate: {},

    soilTypes: [],
    soilDepths: [],
    lastFetchingTime: {},
    showCheckbox: false,

    lastFetchIslandsTimestamp: 0
  },
  getters: {
    ...commonGetters(),
    islandGeometries: (state) =>
      state.islands.map((island) => turf.feature(island.geometry)),
    islandGeometries3857: (state, getters) => getters.islandGeometries.map((g) => g && project3857(g)),
    _varietyGeometries: (state) => state.varietyGeometries,
    varietyGeometries: (state) => Object.values(state.varietyGeometries),
    varietyGeometries3857:(state, getters) => getters.varietyGeometries.map((g) => g && g.geometry && project3857(g)),
    currentCropfieldHasGeometry: (state, getters) =>
      getters.currentEntry != null &&
      getters.currentEntry.geometry != null &&
      getters.currentEntry.geometry.coordinates.length > 0,

    // override the getter
    entriesWithCustomFields: (state, getters) => {
      return getters.entriesList.map((e) => {
        return {
          ...e,
          // add zero-padding to cropfield i_number to have better sorting
          farmIsland: e.farmId + "-" + zeroPad(e.I_number, 3),
        };
      });
    },
    entriesWithoutSubset: (state, getters) => {
      return getters.entriesWithCustomFields
    },
    entries: (state, getters) => {
      return getters.entriesWithoutSubset.filter(
        (f) => (state.subsetFilter === null || state.subsetFilter.includes(f.id)))
    },
    geometries: (state, getters) =>
      getters.entries.filter((e) => e.geometry != null).map((e) => turf.feature(e.geometry, { id: e.id })),
    geometries3857: (state, getters) => getters.geometries.map((g) => project3857(g)),
    currentGeometry3857: (state, getters) => getters.geometries3857.find((g) => g.properties.id == getters.currentEntry.id),
    geometriesToSelect: (state, getters) => getters.entries.filter(
      (e) => e.geometry != null).map((e) => turf.feature(e.geometry, { id: e.id, surf_parc: e.surface, code_cultu: e.name })
    ),
    // Géométries des parcelles de seconde culture
    secondCropGeometries: (state, getters) =>
      getters.entries.filter((e) => e.geometry != null && e.cropOrder > 1).map((e) => turf.feature(e.geometry, { id: e.id })),
    secondCropGeometries3857: (state, getters) => getters.secondCropGeometries.map((g) => project3857(g)),
    // Géométrie des parcelles de pure première culture
    firstCropGeometries: (state, getters) =>
      getters.entries.filter((e) => e.geometry != null && (!e.cropOrder || e.cropOrder == 1) && e.nextCropFields?.length > 0).map((e) => turf.feature(e.geometry, { id: e.id })),
    firstCropGeometries3857: (state, getters) => getters.firstCropGeometries.map((g) => project3857(g)),
    // Géométrie des parcelles qui n'ont qu'une seule culture
    uniqueCropGeometries: (state, getters) =>
      getters.entries.filter((e) => e.geometry != null && (!e.cropOrder || e.cropOrder == 1) && e.nextCropFields?.length == 0).map((e) => turf.feature(e.geometry, { id: e.id })),
    uniqueCropGeometries3857: (state, getters) => getters.uniqueCropGeometries.map((g) => project3857(g)),
    editedEntry: (state) => state.editedEntry,
    crops: (state) => state.filterData.crop_name,
    cropfields: (state) => state.cropfields,
    mapEditMode: (state) => state.mapEditMode,
    mapCutMode: (state) => state.mapCutMode,
    mapCutGeometries: (state) => state.mapCutGeometries,
    islandsPrettyList: (state, getters) => {
      const howManyCropfields = 3;

      // approach with a dict in order to have unicity
      let islands = {};

      getters.entries.forEach((e) => {
        islands[e.island] = { id: e.island, number: e.island_number };
      });
      islands = Object.values(islands);

      return (islands = islands.map((island) => {
        let cropfields = getters.entries.filter((e) => e.island == island.id).map((e) => e.name);
        let display =
          (island.number ? i18n.t("cropfields.list.island_number_x") + island.number : i18n.t("is_empty")) +
          " (" +
          cropfields.slice(0, howManyCropfields).join(", ") +
          (cropfields.length > howManyCropfields ? "...)" : ")");

        return {
          island: island.id,
          display: display,
        };
      }));
    },
    getCropFiltersSelectedList: (state) => state.cropFieldFilterSelected,
    getCropFilterList: (state) => state.cropfieldFilters,
    getFertilisationNeedsCalculation: (state) => state.fertilisationNeedsCalculation,
    getFertilisationNeedsCalculationForm: (state) => state.fertilisationNeedsCalculationForm,
    checkedEntries: (state, getters) => getters.entries.filter((e) => state.entriesChecked.includes(e.id)),
    checkedGeometries: (state, getters) =>
      getters.geometries.filter((g) => state.entriesChecked.includes(g.properties.id)),
    checkedGeometries3857: (state, getters) =>
      getters.geometries3857.filter((g) => state.entriesChecked.includes(g.properties.id)),
    mergeAllowed(state, getters) {
      if (getters.checkedGeometries.length < 2 || getters.checkedGeometries.includes(undefined)) return false;

      let overlap;
      try {
        let polygons = getters.checkedGeometries.map(
          (g) => g && turf.buffer(turf.polygon(g.geometry.coordinates), 0.00001),
        ); // We enlarge the polygons in case there are very close
        overlap = turf.union(...polygons);
      } catch (error) {
        console.log("merge not possible");
        console.log(error);
      }

      return overlap && turf.getType(overlap) == "Polygon";
    },
    selectedHole: (state) => state.selectedHole,
    culturalProcess: (state) => state.culturalProcess,
    fertilisationNeedsCalculation: (state) => state.fertilisationNeedsCalculation,
    fertilisationNeedsCalculationForm: (state) => state.fertilisationNeedsCalculationForm,
    filteredCropField: (state) => state.filteredCropField,
    rpgCapabilities: (state) => state.rpgCapabilities,
    productsByCrop: (state) => state.productsByCrop,
    cropfieldsLoaded: (state) => state.cropfield_loaded,
    onFertilizationPlan: (state) => state.onFertilizationPlan,

    cropfieldVarieties: (state) => state.cropfieldVarieties,
    varieties_have_geometry: (state) => state.varieties_have_geometry,
    activeVarietiesLayer: (state) => state.activeVarietiesLayer,

    activeTab: (state) => state.activeTab,
    detailLoadTime: (state) => state.detailLoadTime,
    crossesCropfields: (state) => state.crossesCropfields,
    crossesCropfieldsOnNearCampaigns: (state) => state.crossesCropfieldsOnNearCampaigns,
    lastCropfieldIdForCross: (state) => state.lastCropfieldIdForCross,
    cropfieldActivitiesLoadTime: (state) => state.cropfieldActivitiesLoadTime,

    fertilizersSinceAssessmentOpeningDate: (state) => state.fertilizersSinceAssessmentOpeningDate,

    soilTypes: (state) => state.soilTypes,
    soilDepths: (state) => state.soilDepths,
  },
  mutations: {
    ...commonMutations(),
    [ALL_CROPFIELDS]: (state, cropfields) => {
      state.cropfields = cropfields;
    },

    [CULTURALPROCESS_BY_CROPFIELD]: (state, culturalProcess) => {
      state.culturalProcess = culturalProcess;
    },

    [FERTILISATION_NEEDS_CALCULATION]: (state, fertilisationNeedsCalculation) => {
      state.fertilisationNeedsCalculation = fertilisationNeedsCalculation;
    },

    [FERTILISATION_NEEDS_CALCULATION_FORM]: (state, fertilisationNeedsCalculationForm) => {
      state.fertilisationNeedsCalculationForm = fertilisationNeedsCalculationForm;
    },

    [PRODUCTS_BY_CROPFIELD]: (state, productsByCrop) => {
      state.productsByCrop = productsByCrop;
    },

    [CROPFIELD_LOADING]: (state, status) => {
      state.cropfield_loaded = !status;
    },

    [ON_FERTILIZATION_PLAN]: (state, status) => {
      state.onFertilizationPlan = status;
    },

    [FILTERED_CROPFIELD]: (state, cropfields) => {
      state.filteredCropField = cropfields
    },

    [SET_CROPFIELD_VARIETIES]: (state, varieties) => {
      state.cropfieldVarieties = varieties;
    },

    [VARIETIES_HAVE_GEOMETRY]: (state, status) => {
      state.varieties_have_geometry = status;
    },

    [ACTIVE_VARIETIES_LAYER]: (state, status) => {
      state.activeVarietiesLayer = status;
    },

    [GET_CROSSES_CROPFIELDS]: (state, cropfields) => {
      state.crossesCropfields = cropfields;
    },

    [GET_CROSSES_CROPFIELDS_ON_NEAR_CAMPAINGS]: (state, cropfields) => {
      state.crossesCropfieldsOnNearCampaigns = cropfields.sort(
        function(a, b) {
          return (a.campaign_name > b.campaign_name || a.campaign_name == b.campaign_name && a.name > b.name) ? 1 : -1
        }
      )
    },

    [GET_FERTILIZERS_SINCE_ASSESSMENT_OPENING_DATE]: (state, fertilizers) => {
      state.fertilizersSinceAssessmentOpeningDate = fertilizers;
    },

    [GET_SOIL_TYPES]: (state, soilTypes) => {
      state.soilTypes = soilTypes;
    },

    [GET_SOIL_DEPTHS]: (state, soilDepths) => {
      state.soilDepths = soilDepths;
    },

    setActiveTab: (state, tab) => {
      state.activeTab = tab;
    },

    setSubsetFilter: (state, subset) => {
      state.subsetFilter = subset
    },

    setEditedEntryValue: (state, data) => {
      state.editedEntry = { ...state.editedEntry, ...data };
    },

    resetEditedEntry: (state) => {
      state.editedEntry = {};
    },

    resetEntriesChecked: (state) => {
      state.entriesChecked = [];
    },

    removeCheckedEntry: (state, { id }) => {
      state.entriesChecked = [...state.entriesChecked.filter((e) => e.id != id)];
    },

    setMapEditMode: (state, mode) => {
      state.mapEditMode = mode;
    },

    setMapCutMode: (state, mode) => {
      state.mapCutMode = mode;
    },

    setMapCutGeometries: (state, geometries) => {
      state.mapCutGeometries = geometries;
    },

    setOneIsland: (state, newIsland) => {
      state.islands = state.islands.filter(island => island.id != newIsland.id)
      state.islands.push(newIsland);
    },

    setIslands: (state, islands) => {
      state.islands = islands;
    },

    resetVarietyGeometry: (state) => {
      state.varietyGeometries = {}
    },

    setVarietyGeometry: (state, { id, geometry }) => {
      Vue.set(state.varietyGeometries, id, geometry);
    },

    selectHole: (state, { hole }) => {
      state.selectedHole = hole;
    },

    resetSelectedHole: (state) => {
      state.selectedHole = null;
    },

    setRPGCapabilities: (state, capabilities) => {
      state.rpgCapabilities = capabilities;
    },

    _setLastCropfieldIdForCross: (state, id) => {
      state.lastCropfieldIdForCross = id;
    },

    _setLastCropfieldIdForCrossOnNearCampaigns: (state, id) => {
      state.lastCropfieldIdForCrossOnNearCampaigns = id;
    },

    setDetailLoadTime: (state, millis) => {
      state.detailLoadTime = millis;
    },

    setCropfieldActivitiesLoadTime: (state, time) => {
      state.cropfieldActivitiesLoadTime = time;
    },

    setLastFetchingTime: (state, {key, time}) => {
      Vue.set(state.lastFetchingTime, key, time);
    },

    _setLastFetchIslandsTimestamp: (state, time) => {
      state.lastFetchIslandsTimestamp = time
    }
  },
  actions: {
    ...commonActions(),
    async fetchEntries({ commit, dispatch, state, rootGetters }, { withGeometries, stockStore, silent, fertilizationplan, last_crop, cropOrder, all } = {}) {
      if (!rootGetters.getCampaign.id) return;
      commit(CROPFIELD_LOADING, true);

      // await dispatch("refreshFarmFilterSelection");

      // construct query payload
      const payload = {}

      //On s'assure que les filtre soit initialiser 
      if(isEmpty(state.filterData)){
        await dispatch("initFilters")
      } 
      //get all the filters
      for(const filter of config.filters) {
        const value = await dispatch("getFilterValueId", filter.name)
        if(value != null)
        payload[filter.name] = value
      }

      // used for the map
      if (withGeometries) {
        payload["detail"] = true;
      }

      // used for fertilization plan view
      if(fertilizationplan || state.onFertilizationPlan){
        payload['fertilizationplan'] = true;
        commit(ON_FERTILIZATION_PLAN, true);
      }
      else{
        commit(ON_FERTILIZATION_PLAN, false);
      }

      // campaign & farms
      payload["campaign"] = rootGetters.getCampaign.id;
      if (rootGetters["farm/currentFarm"]) {
        payload["farm"] = rootGetters["farm/currentFarm"].id;
      }

      //filter on stock store
      if (stockStore) {
        payload["stockStore"] = stockStore
      }

      if (last_crop) {
        payload["last_crop"] = last_crop
      }

      if (cropOrder) {
        payload["cropOrder"] = cropOrder
      }

      // Pour inclure les parcelles à confirmer dans la liste
      if (all) {
        payload["all"] = all
      }

      const response = await CropfieldApi.getCropfields(payload);

      commit("setEntries", { entries: response.data });

      commit(CROPFIELD_LOADING, false);

      return response.data;
    },

    setOnFertilizationPlan({commit}, status) {
      commit(ON_FERTILIZATION_PLAN, status);
    },

    async getOneCropfield({ dispatch, commit}, { id }) {
      commit(CROPFIELD_LOADING, true);
      let response = await CropfieldApi.getCropfield(id);
      if (response.status >= 400 && response.status < 500)
        response.data["error"] = i18n.t('cropfields.details.cropfield_doesnt_exist');
      const cropfield = response.data;

      commit("setCurrentId", { id: id });

      commit("updateEntry", { id: id, data: cropfield });

      commit(CROPFIELD_LOADING, false);
      return response.data;
    },

    async getCropfield({commit}, {id}) {
      commit(CROPFIELD_LOADING, true);
      const response = await CropfieldApi.getCropfield(id);
      commit(CROPFIELD_LOADING, false);
      return response.data;
    },

    async getCrossesCropfields({commit, state}, {id}) {
      commit(CROPFIELD_LOADING, true);
      if (state.lastCropfieldIdForCross == id && state.detailLoadTime < state.cropfieldActivitiesLoadTime)
        return state.crossesCropfields;

      const response = await CropfieldApi.getCropfields({ crosses_cropfield: id });
      commit(GET_CROSSES_CROPFIELDS, response.data);
      commit("setCropfieldActivitiesLoadTime", new Date());
      commit("_setLastCropfieldIdForCross", id);
      commit(CROPFIELD_LOADING, false);
      return response.data;
    },

    async getCrossesCropfieldsOnNearCampaigns({commit, state, rootGetters}, {id}) {
      commit(CROPFIELD_LOADING, true);
      if (state.lastCropfieldIdForCrossOnNearCampaigns == id && state.detailLoadTime < state.cropfieldActivitiesLoadTime)
        return state.crossesCropfieldsOnNearCampaigns;

      const response = await CropfieldApi.getCropfields({all_crosses_cropfield: id, campaign_id_around: rootGetters.getCampaign.id})
      commit(GET_CROSSES_CROPFIELDS_ON_NEAR_CAMPAINGS, response.data);
      commit("setCropfieldActivitiesLoadTime", new Date());
      commit("_setLastCropfieldIdForCrossOnNearCampaigns", id);
      commit(CROPFIELD_LOADING, false);
      return response.data;
    },

    async getCulturalProcess({ commit }, payload) {
      commit(CROPFIELD_LOADING, true);
      const response = await CropfieldApi.getCulturalProcess(payload.id);
      commit(CULTURALPROCESS_BY_CROPFIELD, response.data);
      commit(CROPFIELD_LOADING, false);
      return response.data;
    },

    async updateCulturalProcess({ commit }, payload) {
      commit(CROPFIELD_LOADING, true);
      const response = await CropfieldApi.updateCulturalProcess(payload.data, payload.id);
      commit(CULTURALPROCESS_BY_CROPFIELD, response.data);
      commit(CROPFIELD_LOADING, false);
      return response.data;
    },

    async getFertilisationNeedsCalculation({ commit }, payload) {
      commit(CROPFIELD_LOADING, true);
      const response = await CropfieldApi.getFertilisationNeedsCalculation(payload.id);
      commit(FERTILISATION_NEEDS_CALCULATION, response.data);
      commit(CROPFIELD_LOADING, false);
      return response.data;
    },

    async getFertilisationNeedsCalculationForm({ commit }, payload) {
      commit(CROPFIELD_LOADING, true);
      const response = await CropfieldApi.getFertilisationNeedsCalculationForm(payload.id);
      commit(FERTILISATION_NEEDS_CALCULATION_FORM, response.data);
      commit(CROPFIELD_LOADING, false);
      return response.data;
    },

    async convertGeomToGeoJSON({}, geometry) {
      // remove srid part
      geometry = geometry.split(";");
      if (geometry.length < 1) return;

      geometry = geometry[1];
      let reader = new ol.format.WKT();

      let out = reader.readFeature(geometry);

      let writer = new ol.format.GeoJSON({
        dataProjection: "EPSG:3857",
        featureProjection: "EPSG:4326",
      });
      let result = writer.writeFeatureObject(out);

      return result;
    },

    async updateEntry({ commit, dispatch }, { id, data }) {
      commit(CROPFIELD_LOADING, true);

      // Récupération des infos nécessaires à la création des zones non traitées
      let znt = data.nonTreatedZones;

      // Par mesure de sécurité
      // S'il y'a une géométrie dans les données envoyées, on met directement la parcelle comme n'étant pas à confirmer
      if (data.geometry) {
        data["toBeConfirmed"] = false;
      }

      let result = await CropfieldApi.updateCropfield(id, data);

      commit("updateEntry", { id: id, data: result.data });

      // Création des surfaces des zones non traitées si renseignées
      if (znt && znt.length > 0) {
        await nonTreatedZoneApi.createNonTreatedZone(id, znt);
      }

      EventBus.$emit("plotFields", false);
      commit(CROPFIELD_LOADING, false);
    },

    async mergeCropfields({ commit, state, getters, dispatch }, { routeName }) {
      let confirmation = confirm(i18n.t("cropfields.details.warning_merge"));

      if (!confirmation) return;

      var name = prompt(i18n.t("cropfields.details.merge_choose_name"));

      const rows = getters.entries.filter((f) => state.entriesChecked.includes(f.id));

      const firstRowId = state.entriesChecked[0];

      var data = { cropFields: [] };
      if (name) {
        data["name"] = name;
      }

      rows.forEach((r) => {
        if (r.id != firstRowId) data.cropFields.push({ id: r.id });
      });

      await dispatch("mergeEntry", {
        id: firstRowId,
        data: data,
      });

      rows.forEach((r) => {
        if (r.id != firstRowId) {
          dispatch("deleteEntryOfStore", { id: r.id });
        }
      });

      if (routeName.includes("cropfield.map")) {
        EventBus.$emit("selectOnMap", firstRowId);
      } else {
        EventBus.$emit("selectOnList", firstRowId);
      }

      EventBus.$emit("plotFields", false);
    },

    async mergeEntry({ commit, dispatch }, { id, data }) {
      commit(CROPFIELD_LOADING, true);

      const response = await CropfieldApi.mergeCropfield(id, data);

      const cropfield = response.data;

      await commit("updateEntry", { id: id, data: cropfield });
      await commit("setCurrentId", { entry: cropfield.id });

      commit(CROPFIELD_LOADING, false);
    },

    async updateGeometry({ commit, dispatch }, { id, geometry }) {
      let geometry4326 = project4326(geometry);

      dispatch("updateEntry", { id: id, data: geometry4326 });
    },

    async deleteEntry({ commit }, { id }) {
      commit(CROPFIELD_LOADING, true);

      commit("removeCheckedEntry", { id: id });

      await CropfieldApi.deleteCropfield(id);
      commit("deleteEntry", { id });

      commit(CROPFIELD_LOADING, false);
    },

    async deleteEntryOfStore({ commit }, { id }) {
      commit(CROPFIELD_LOADING, true);

      commit("removeCheckedEntry", { id: id });
      commit("deleteEntry", { id });

      commit(CROPFIELD_LOADING, false);
    },

    async createEntry({ state, commit, dispatch, rootGetters }, { entry }) {
      commit(CROPFIELD_LOADING, true);

      // Récupération des infos nécessaires à la création des zones non traitées
      let znt = entry.nonTreatedZones;

      entry["campaign"] = rootGetters.getCampaign.id;

      let newEntry = await CropfieldApi.createCropfield(entry);
      newEntry = newEntry.data;

      commit("addEntry", newEntry);

      commit("setCurrentId", { id: newEntry.id });

      // Création des surfaces des zones non traitées si renseignées
      if (znt && znt.length > 0) {
        await nonTreatedZoneApi.createNonTreatedZone(newEntry.id, znt);
      }

      commit(CROPFIELD_LOADING, false);
    },

    async updateCheckedEntries({ state, dispatch, commit }) {
      if (!state.currentId && state.entriesChecked.length == 0) {
        await dispatch("createEntry", { entry: state.editedEntry });
        dispatch("fetchEntries");
        commit("resetEditedEntry");

        return;
      }

      let entriesToUpdate = removeDuplicates([state.currentId, ...state.entriesChecked], { ignoreType: true });

      await entriesToUpdate.forEach(async (id) => {
        await dispatch("updateEntry", { id: id, data: state.editedEntry });
      });

      commit("resetEditedEntry");
    },

    async saveEditedEntry({ state, dispatch, commit }, {fetchAll} = { fetchAll: false }) {
      if (state.currentId) await dispatch("updateEntry", { id: state.currentId, data: state.editedEntry });
      else await dispatch("createEntry", { entry: state.editedEntry });

      await dispatch("fetchEntries", { all: fetchAll });
      commit("resetEditedEntry");
    },

    async updateFilterSelection({ commit, dispatch, state, rootGetters}, { filterName, elementId }) {
      await commit("setFilter", {
        filterName: filterName,
        elementId: elementId,
      });

      const filters = await dispatch("getAllFiltersValues")

      state.filters.forEach(async (filter) => {
        await dispatch("getFilter", {
          attrib: filter.name,
          params: {...filters, campaign: rootGetters.getCampaign.id},
          values: filter.name == "active" ? filter.values : ""
        });
      });
    },

    async refreshFarmFilterSelection({ commit, state, rootGetters }) {
      const filterName = "farm";

      if (rootGetters["farm/currentFarm"] && state.filterData[filterName] == rootGetters["farm/currentFarm"].id) return;
      if (!rootGetters["farm/currentFarm"]) return;

      const farmId = rootGetters["farm/currentFarm"].id;

      if (!state.filterData[filterName]) return;

      const elementId = state.filterData[filterName].findIndex((e) => e.id == farmId);

      if (elementId >= 0)
        await commit("setFilter", { filterName: filterName, elementId: elementId, force: true });
    },

    // override
    async setEntriesChecked({ state, commit, dispatch, getters }, { entries }) {
      await commit("setEntriesChecked", { entries: entries });
    },

    async fetchGeometry({ dispatch }, entryId) {
      let result = await dispatch("getOneCropfield", { id: entryId });
      if (!result.geometry) return;
    },

    async fetchIslandGeometries({ state, getters, commit }) {
      // On récupère les ilots si le dernier appel date de plus de 60 secondes
      if (Date.now() - state.lastFetchIslandsTimestamp <= 60000) {
        return false;
      }
      // fetch islands from api
      let islands = await CropfieldApi.getIslands();
      islands = islands.data;

      // add each island to the store <id, geometry> dictionnary
      commit("setIslands", islands);
      commit("_setLastFetchIslandsTimestamp", Date.now());
      return true;
    },

    async fetchVarietyGeometries({commit, rootGetters, getters}){
      // fetch varieties from api
      let campaign = rootGetters.getCampaign.id;
      let currentFarm = rootGetters["farm/currentFarm"];
      let cropfields_id = [];
      getters.entries.map(cf => {
        cropfields_id.push(cf.id);
      });
      cropfields_id = cropfields_id.join(",");
      let payload = {detail: true, campaign: campaign, cropField: cropfields_id};
      if (currentFarm)
        payload['farm_id'] = currentFarm.id;
      let varieties = await CropfieldApi.getVarieties(payload);
      varieties = varieties.data;
      // add each variety to the store <id, geometry> dictionnary
      commit("resetVarietyGeometry");
      for (let i = 0; i < varieties.length; i++) {
        if (varieties[i].zone != null)
          commit("setVarietyGeometry", { id: varieties[i].id, geometry: turf.feature(varieties[i].zone, {name: varieties[i].variety_name, id: varieties[i].id, type: "variety"}) });
      }
    },

    async fetchVarietiesByCropfield({commit, rootGetters}, {cropfields_id}){
      // fetch varieties from api
      let campaign = rootGetters.getCampaign.id;
      let currentFarm = rootGetters["farm/currentFarm"];
      if (currentFarm) currentFarm = currentFarm.id;

      let cropfields_id_str = cropfields_id.join(",")
      let varieties = await CropfieldApi.getVarieties({detail: true, cropField: cropfields_id_str, campaign: campaign, farm_id: currentFarm});
      commit(SET_CROPFIELD_VARIETIES, varieties.data);
      return varieties.data;
    },

    async getBeforePlanNitrogenSummary({}, {id, params}){
      let beforePlanNitrogenSummary = await CropfieldApi.getBeforePlanNitrogenSummary(id, params);
      return beforePlanNitrogenSummary.data;
    },

    async getFertilizersSinceAssessmentOpeningDate({commit}, {id}) {
      let response = await CropfieldApi.getFertilizersSinceAssessmentOpeningDate(id);
      commit(GET_FERTILIZERS_SINCE_ASSESSMENT_OPENING_DATE, response.data);
      return response.data;
    },

    async setActiveVarietiesLayer({commit}, active) {
      commit(ACTIVE_VARIETIES_LAYER, active);
    },

    async setEditedEntryValue({ commit }, data) {
      await commit("setEditedEntryValue", data);
    },

    async getFilter({ commit, rootGetters }, { attrib, params, values }) {
      if(values) {
       
        // if there is hard setted values in config, use it
        commit("setFilterData", { name: attrib, elements: values });
        return values;
      } else {
    
        //enlevez le params qui a le meme nom que l'attribut
        const copyParams = {...params}
        if (copyParams.hasOwnProperty(attrib)) {
          delete copyParams[attrib];
        }
        
        const response = await CropfieldApi.getFilters(attrib, copyParams);
        commit("setFilterData", { name: attrib, elements: response.data });
        return response.data
      }
    },

    async initFilters({ state, getters, rootGetters, dispatch, commit }) {
      let currentFarm = rootGetters["farm/currentFarm"];
      if (currentFarm) currentFarm = currentFarm.id;

      const filters = await dispatch("getAllFiltersValues")

      // for each filter in config
      for(const filter of config.filters) {
        // get the filter data
        await dispatch("getFilter", {
          attrib: filter.name,
          params: {
            ... filters,
            campaign: rootGetters.getCampaign.id,
            farm: currentFarm
          },
          values: filter.values
        });
      }

      // update farm filter according to global filter
      // await dispatch("refreshFarmFilterSelection", {force: true});
    },

    /**
     * fetch RPG source list
     */
    async fetchRPGCapabilities({ commit }) {
      const response = await CropfieldApi.getCapabilities();

      // parse the XML and get all the <Name> tags
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(response.data, "text/xml");
      let capabilities = Array.from(xmlDoc.querySelectorAll("Name"));

      // build list of RPG sources
      const rpgList = [];
      capabilities.forEach((capability) => {
        // parse on this pattern
        const pattern = /RPG.(.*):parcelles_graphiques/g;

        capability = capability.textContent;

        const groups = pattern.exec(capability);

        if (!groups) return;

        const year = groups[1];

        // each option will have a year (ex: 2019) and a value (ex: RPG.2019:parcelles_graphiques)
        if(year != "LATEST")
          rpgList.push({ value: capability, text: year });
      });

      commit("setRPGCapabilities", rpgList);
    },

    async getAllCropfields({ commit }) {
      commit(CROPFIELD_LOADING, true);
      const response = await CropfieldApi.getAllCropfields();
      commit(ALL_CROPFIELDS, response.data);
      commit(CROPFIELD_LOADING, false);
      return response.data;
    },

    async getCropfieldModelsForFertilizationPlan({commit, rootGetters}, {crop}) {
      commit(CROPFIELD_LOADING, true);
      let campaign = rootGetters.getCampaign.id;
      let params = {has_cultural_process: true, has_fertilization_plan: true, fertilizationplan: true, campaign: campaign};
      if (crop)
        params['crop'] = crop
      const response = await CropfieldApi.getCropfields(params);
      commit(FILTERED_CROPFIELD, response.data);
      commit(CROPFIELD_LOADING, false);
      return response.data;
    },

    /**
     * Duplique le plan de fertilisation d'une parcelle pour une autre
     * @param {int} id : id de la parcelle modèle
     * @param {int} cropfield_id : id de la parcelle dont cultural process est à générer
     */
    async duplicateFertilizationPlan({commit}, {id, cropfield_id}) {
      commit(CROPFIELD_LOADING, true);
      let params = {cropfield_id: cropfield_id};
      const response = await CropfieldApi.duplicateFertilizationPlan(id, params)
      commit(CROPFIELD_LOADING, false);
      return response.data;
    },

    async getProductsByCropfield({ commit }, payload) {
      commit(CROPFIELD_LOADING, true);
      const response = await CropfieldApi.getProductsByCropfield(payload.id);
      commit(PRODUCTS_BY_CROPFIELD, response.data);
      commit(CROPFIELD_LOADING, false);
      return response.data;
    },

    async getSmagLoginUrl({ commit }, payload) {
      const response = await CropfieldApi.getSmagLoginUrl(payload.application_id);
      return response.data;
    },

    async setActiveTab({commit}, tab) {
      commit("setActiveTab", tab);
    },

    async setDetailLoadTime({commit}, time) {
      commit("setDetailLoadTime", time);
    },

    async setCropfieldActivitiesLoadTime({commit}, time) {
      commit("setCropfieldActivitiesLoadTime", time);
    },

    async defineNewCrop({commit}, payload) {
      commit(CROPFIELD_LOADING, true);
      const response = await CropfieldApi.defineNewCrop(payload.id);
      commit("addEntry", response.data);
      commit(CROPFIELD_LOADING, false);
      return response.data;
    },
    async getSoilTypes({commit, state, rootGetters}) {
      commit(CROPFIELD_LOADING, true);
      let territory = [];
      let postalCode = null;
      let entries = Object.values(state._entries);
      
      // on definie le territory seulement si on a plusieur entré ou un currentId
      if (state.entriesChecked?.length || state.currentId) {
        //Quand on a plusieur entrés on va les lire et les push dans le tableux territory 
        if (state.entriesChecked?.length) {
          state.entriesChecked.forEach(id => {
            let entry = entries.find(e => e.id == id);
            if (entry) {
              territory.push(entry.territory);
            }
          });
          //on check filtre les doublon
          territory = [...new Set(territory)];
        } else {
          //pour le cas ou il n'y en a que un seul
          let entry = entries.find(e => e.id == state.currentId);
          if (entry) {
            territory.push(entry.territory);
          }
        }
        // Convertit le tableau `territory` en chaîne si nécessaire
        territory = territory.length ? territory.join(",") : null;
      }

      //si on a pas de territory on recupere le postalCode de la farm
      if (!territory.length) {
        let farms = rootGetters["farm/farms"];
        let id = state.editedEntry?.farm || entries.find(e => e.id == state.currentId)?.farm
        let farm = farms.find(e => e.id == id);
        if (farm) {
          postalCode = farm.postalCode;
        }
      }
      
      const response = await CropfieldApi.getSoilTypes(territory, postalCode);
      commit(GET_SOIL_TYPES, response.data);
      commit(CROPFIELD_LOADING, false);
      return response.data;
    },
    async getSoilDepths({commit, state}) {
      commit(CROPFIELD_LOADING, true);
      // La liste des soilDepths dans le store n'est pas vide et que la dernière requête pour l'avoir date de moins d'une heure
      // On ne repasse pas la requête
      const currentTime = Date.now();
      if (state.soilDepths.length != 0 && state.lastFetchingTime["soilDepths"] && (currentTime - state.lastFetchingTime["soilDepths"] < delayBeforeFetchingNewData))
        return
      const response = await CropfieldApi.getSoilDepths();
      commit(GET_SOIL_DEPTHS, response.data);
      commit("setLastFetchingTime", {key: "soilDepths", time: currentTime});
      commit(CROPFIELD_LOADING, false);
      return response.data;
    },
    async getWaterCourse({commit}, {bbox}) {
      commit(CROPFIELD_LOADING, true);
      const response = await CropfieldApi.getWaterCourse(bbox);
      // display on map
      let collection = turf.featureCollection(response.data.features);
      collection = collection.features.map((f) => project2154to3857(f));
      commit(CROPFIELD_LOADING, false);
      return collection;
    }
  },
};
