<template>
  <div v-if="currentEntry.productFamily_code != 'phyto'">
    <b-row v-for="(dose, index) in doses" :key="index" class="mb-2">
      <b-col cols="4" v-if="getValue(dose.key)">
        <h4 class="subtitle-form">{{ dose.display }}</h4>
      </b-col>
      <b-col cols="8" class="d-flex align-items-start" v-if="getValue(dose.key)">
        <div class="bg-text-color text-infos config-density-semence">
          {{ getValue(dose.key) }}
          {{ getUnit || "N/A" }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import RefProductMixin from "@/mixins/RefProductMixin";

import { mapGetters } from "vuex";

export default {
  mixins: [DetailUpdateMixin, RefProductMixin],
  data() {
    return {
      doses: [
        {
          display: "Dose min",
          key: "minDoseValue",
        },
        {
          display: "Dose max",
          key: "maxDoseValue",
        },
      ],
    };
  },
  methods: {
    getValue(key) {
      // use current entry data
      if (!this.useRefProduct) return this.currentEntry[key];

      if (!this.currentProduct || !this.currentProduct.productUsages || this.currentProduct.productUsages.length == 0) return;

      // else, use reference product
      if (key == "minDoseValue") return this.currentProduct.productUsages[0].minValue;
      if (key == "maxDoseValue") return this.currentProduct.productUsages[0].maxValue;

      return;
    },
  },
  computed: {
     ...mapGetters({
      inputsStock: "inputStock/entries",
      currentEntry: "inputStock/currentEntry",
      currentEditedEntry: "inputStock/editedEntry",
    }),
    getUnit() {
      // use current entry data
      if (!this.useRefProduct) return this.currentEntry.doseUnit_unit;

      if (!this.currentProduct || !this.currentProduct.productUsages || this.currentProduct.productUsages.length == 0) return;

      return this.currentProduct.productUsages[0].doseUnit_unit;
    },
  },
};
</script>

<style lang="scss" scoped></style>
