<template>
  <form @submit.prevent="onSave" >
    <b-row class="mt-1">
			<!-- Date text -->
      <b-col cols="4">
				<img :src="iconCalendar" alt="calendar" />
        <h4 class="subtitle-form overload-subtitle mb-2">{{ $t("inputStock.details.date") }}</h4>
      </b-col>
      <!-- Quantity text -->
      <b-col cols="4">
        <h4 class="subtitle-form overload-subtitle mb-2">{{ $t("inputStock.details.quantity") }}</h4>
      </b-col>
			<!-- Unit text -->
      <b-col cols="4" class="pl-2">
        <h4 class="subtitle-form overload-subtitle mb-2">{{ $t("inputStock.details.unit") }}</h4>
      </b-col>

    </b-row>
		<b-row class="row-resize mb-3">
      <!-- Date Input-->
      <b-col cols="4" class="text-infos shape-first-cell whitout-bg">
        <div class="inputs-params">
          <input type="date" ref="date" id="date" v-model="date" placeholder="yyyy-mm-dd" required/>
        </div>
      </b-col>
      <!-- Quantity Input-->
      <b-col cols="4" class="text-infos shape-first-cell whitout-bg">
        <div class="inputs-params">
          <NumericInput
                id="quantity"
                inputRef="input"
                :value="parseFloat(quantity)"
                @input="i => {quantity = i}"
                @blur="computeUnitPrice"
                :numberOfDigits="4"
                :controlValidity="true"
          />
        </div>
      </b-col>
      <!-- Unit input -->
      <b-col cols="4" class="pr-3 text-infos shape-second-cell whitout-bg">
        <div class="inputs-params">
          <Dropdown
						ref="dropdown"
						class="shape-custom-select"
						label="unit"
						:value="unit"
						:options="units"
						@input="input => unit=input"
						:required="true"
            :clearable="false"
					/>
        </div>
      </b-col>
    </b-row>
		<b-row>
			<!-- Total Price text -->
      <b-col cols="6">
        <h4 class="subtitle-form overload-subtitle mb-2">{{ $t("inputStock.details.total_price") }}</h4>
      </b-col>
      <!-- Unit Price text -->
      <b-col cols="6" class="pl-2">
        <h4 class="subtitle-form overload-subtitle mb-2">{{ $t("inputStock.details.price") }} / {{ unit.unit }} </h4>
      </b-col>
    </b-row>
		<b-row class="row-resize mb-3">
      <!-- Total price input -->
      <b-col cols="5" class="pr-3 text-infos shape-second-cell whitout-bg">
        <div class="inputs-params">
          <NumericInput
                id="total_price"
                inputRef="input"
                :value="parseFloat(totalPrice)"
                @input="i => {totalPrice = i}"
                @blur="computeUnitPrice"
                :numberOfDigits="2"
          />
        </div>
      </b-col>
			<b-col cols="1" class="pl-0"> <span>€</span> </b-col>
      <!-- Unit price input -->
      <b-col cols="5" class="pr-3 text-infos shape-second-cell whitout-bg">
        <div class="inputs-params">
          <NumericInput
                id="unit_price"
                inputRef="input"
                :value="parseFloat(unitPrice)"
                @input="i => {unitPrice = i}"
                @blur="computeTotalPrice"
                :numberOfDigits="8"
          />
        </div>
      </b-col>
			<b-col cols="1" class="pl-0"> <span>€</span> </b-col>
    </b-row>

		<b-container class="mb-3 p-0">
        <b-row align-h="end">
          <b-col cols="6">
            <b-button class="button-default align-bottom" @click="onReset">
              {{ $t("cancel") }}
            </b-button>
          </b-col>
          <b-col cols="6">
            <b-button class="button-default align-bottom" type="submit">
              {{ $t("save") }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </form>
</template>

<script>
import calendar from "@/assets/images/calendar.svg";
import Dropdown from "@/components/inputstock/details/Dropdown.vue";
import { mapGetters } from "vuex";
import {checkDatePattern, checkTimePattern} from "@/utils/commons";
import NumericInput from "@/components/commons/NumericInput.vue";

export default {
	data() {
    return {
        iconCalendar: calendar,
				totalPrice: null,
        unitPrice: null,
				date: null,
				quantity: null,
				unit: {},
				units: [],
				add: true,
    };
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

	components: {
    Dropdown,
    NumericInput
  },
	async mounted() {
		this.units = this.currentInput.units;
		this.unit = this.units[0];
		this.date = this.getCampaign.startDate;
		if(Object.keys(this.item).length != 0)
      {
        this.date = this.item.supplyDate;
				this.unitPrice = parseFloat(this.item.price);
        this.quantity = this.item.quantity ? this.item.quantity : null;
        this.totalPrice = this.quantity ? (this.quantity * this.unitPrice) : null
				this.unit = {'id': this.item.doseUnit, 'unit': this.item.doseUnit_unit};
        this.add = false;
      }
	},

	computed: {
		...mapGetters({
			currentInput: "inputStock/currentEntry",
			inputSupplies: "inputSupplies",
			getCampaign: "getCampaign",
		}),
	},

	methods: {
    // Retourne la date au format iso sans l'heure
    getDate() {
      var date = new Date(this.date);
      if (date != "Invalid Date") return date.toISOString().substring(0, 10);
      return null;
    },
    /**
     * @description calcule le prix unitaire si la quantité est renseignée
     */
    computeUnitPrice() {
      if(this.quantity && this.totalPrice) {
        this.unitPrice = (parseFloat(this.totalPrice) / parseFloat (this.quantity)).toFixed(8);
      }
    },

    /**
     * @description calcule le prix total si la quantité est renseignée
     */
    computeTotalPrice() {
      if(this.quantity) {
        this.totalPrice = ((this.unitPrice ? parseFloat(this.unitPrice) : 0) * (parseFloat(this.quantity))).toFixed(2);
      }
    },

		/**
     * @description save input supply
     */
    async onSave() {
			if(!checkDatePattern(this.date) || !this.date || !this.unit)
				return;
      if(!this.quantity && !this.unitPrice){
        return;}

      let dataToPatch = {
        supplyDate: this.getDate(),
        price: this.unitPrice ? this.unitPrice : null,
        quantity: this.quantity ? this.quantity : null ,
				doseUnit: this.unit.id,
        inputsStock: this.currentInput.id,
      }
      if(this.add == true)
      {
        await this.$store.dispatch("createInputSupply", {
          data: dataToPatch,
        });
      }
      else {
        await this.$store.dispatch("updateInputSupply", {
					id: this.item.id,
          data: dataToPatch,
        });
      }

      await this.$store.dispatch("inputStock/fetchEntries")

      await this.$store.dispatch("getInputSuppliesByInputStock", {inputsStock : this.currentInput.id});
			this.$emit('clicked');
    },

    /**
     * Retourne en mode consultation
     */
    onReset() {
			this.$emit("clicked");
		}
	},
  watch: {
    date: {
      handler: async function() {
        if(!checkDatePattern(this.date)) {
          this.$refs.date.setCustomValidity(this.$i18n.t("commons.details.datePatternError"));
        }else{
          this.$refs.date.setCustomValidity("");
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
/**
 * Date params.
 */
.inputs-date {
  margin: 2px 10px 3px 0px;
  // width: 47%;
}

img {
  width: 1.5em;
  float: left;
  margin: auto 5px 5px auto;
}

/**
 * Resize rows.
 */
.row-resize {
  padding-left: 16px;
}

.button-add-carburant {
  background-color: $secondary-light-color;
  width: 175px;
}
</style>
