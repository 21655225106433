<template>
  <div>
    <Treatment
      :endpoint="ActivityApi.reportsPhytoActivities"
      :async="false"
      :title="$t('activities.reports.interventions-phyto.title')"
      :startButton="$t('commons.exports_reports.generate_button')"
      :finishButton="$t('commons.exports_reports.finish')"
      :exportAllowedFormats="['pdf']"
      :isDirectDownload="true"
      :onBack="onBack"
      :onClose="onClose"
    >
      <TC_Campaign />
      <TC_Farm />
      <!-- 
        for component TC_ActivityChoice; List of options to display.
        - 'newOnly' means "only the new activities". It will send 'newOnly = true' to the API
        - 'onPeriod' means "all activities on given period". In that case it will send a startDate and endDate to the API
        - 'all' means "all activities". It will send 'all = true' to the API
      -->
      <TC_ActivityChoice :activityOptions="['all', 'onPeriod']" />
      <TC_FileFormat />
    </Treatment>
  </div>
</template>

<script>
import Treatment from "@/components/commons/treatments/TreatmentExecution";
import TC_Campaign from "@/components/commons/treatments/TC_Campaign";
import TC_Farm from "@/components/commons/treatments/TC_Farm";
import TC_ActivityChoice from "@/components/commons/treatments/TC_ActivityChoice";
import TC_FileFormat from "@/components/commons/treatments/TC_FileFormat";

import ActivityApi from "@/api/activity.api";

export default {
  data() {
    return {
      ActivityApi: ActivityApi,
    };
  },
  methods: {
    onBack() {
      this.$router.push({ name: "activity.reports" });
    },
    onClose() {
      this.$router.push({ name: "activity.reports" });
    },
  },
  components: { Treatment, TC_Campaign, TC_Farm, TC_ActivityChoice, TC_FileFormat },
};
</script>
