<template>
  <div>
    <!-- Treatment Component : Activity for Agro EDI -->

		<!-- Exporter les activités ? -->
    <b-form-checkbox v-model="exported">
      <span class="dropdown-option"> {{ $t("commons.exports_reports.export_activities") }} </span>
    </b-form-checkbox>
		<div v-show="exported" class="ml-4 mt-2">
			<!-- Exporter toutes les activités ou à partir du dernier export -->
			<b-form-select
				class="dropdown-option"
				v-model="activityChoice"
				:options="activityOptions"
			></b-form-select>
			<div v-if="activityChoice == 'fromLastExport'" class="ml-2 mt-2">
				<!-- Date de début de l'export (par défaut : date du dernier export) -->
				<b-row>
					<b-col cols="4">
						<h4 class="subtitle-form">
							{{ $t("commons.exports_reports.export_activities_options.start_date") }}
						</h4>
					</b-col>
					<b-col cols="8" class="pl-0">
						<b-form-input type="date" v-model="startDate" required></b-form-input>
					</b-col>
				</b-row>
				<!-- Exporter uniquement les nouvelles activités ou pas -->
				<b-row class="mt-3">
					<b-col class="bg-white ml-2 mr-3 py-1">
						<b-form-radio-group
							v-model="subActivityChoice"
							:options="subActivityOptions"
							class="dropdown-option"
							stacked
						></b-form-radio-group>
					</b-col>
				</b-row>
			</div>
		</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import TreatmentFormPartMixin from "@/components/commons/treatments/TreatmentFormPartMixin";
import { TreatmentFormPart } from "@/models/treatments/Treatment.js";

export default {
  mixins: [TreatmentFormPartMixin],
  data() {
    return {
			exported: false,
			activityChoice: null,
			activityOptions: null,
			subActivityOptions: null,
			subActivityChoice: null,
			startDate: null,
    };
  },
	mounted() {
		this.initChoice();
	},
  methods: {
    validateForm() {
			// Si export depuis le dernier export, la saisie de la date est obligatoire
			if (this.exported && this.activityChoice == "fromLastExport" && !this.startDate) {
				this.isValid = false;
				this.$alert(this.$t("commons.exports_reports.alert_empty_date"));
        return;
			}
			else {
				this.isValid = true;
				let result = [];
				let noActivity = !this.exported;
				result.push(new TreatmentFormPart("no_activity", noActivity));
				if (this.exported && this.activityChoice == "fromLastExport") {
					let newOnly = this.subActivityChoice == "newOnly";
					result.push(new TreatmentFormPart("start_activity_date", this.startDate));
					result.push(new TreatmentFormPart("new_activity_only", newOnly));
				}
				return result;
			}
    },
		async initChoice() {
			// Récupération de la date du dernier export
			let registeredLastExportDate = await this.$store.dispatch("getMiscellaneousByKey", {key: "agro_edi_last_export_date"});
			if (registeredLastExportDate.length > 0) {
				this.startDate = registeredLastExportDate[0].value;
			}
			// Initialisation des listes d'options
			this.activityOptions = [
				{
					value: "fromLastExport",
					text: this.$t("commons.exports_reports.export_activities_options.activities_from_last_export")
				},
				{
					value: "all",
					text: this.$t("commons.exports_reports.export_activities_options.all_activities")
				}
			];
			this.activityChoice = this.startDate == null ? "all" : "fromLastExport";

			this.subActivityOptions = [
				{
					value: "newOnly",
					text: this.$t("commons.exports_reports.export_activities_options.new_activities_only")
				},
				{
					value: "newAndUpdated",
					text: this.$t("commons.exports_reports.export_activities_options.new_and_modified_activities")
				}
			];
			this.subActivityChoice = "newOnly";
		},
  },
  computed: {
    ...mapGetters({
    }),
  },
};
</script>

<style>
	.dropdown-option {
		font-size: 13.5px !important;
	}
</style>
