<template>
  <input
    type="date"
    class="w-auto"
    size="sm"
    v-model="value"
    @blur="$emit('updateRow', rowId, payload)"
    placeholder="yyyy-mm-dd"
  />
</template>

<script>
import {checkDatePattern, checkTimePattern} from "@/utils/commons";

export default {
  props: ["rowId", "field", "cellData"],
  data() {
    return {
      value: null,
    };
  },
  created() {
    this.value = this.cellData;
  },
  computed: {
    payload() {
      let payload = {};
      if (!this.value || !checkDatePattern(this.value)) this.value = null; 
      payload[this.field] = this.value;
      return payload;
    },
  },
  watch: {
    cellData() {
      this.value = this.cellData;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
