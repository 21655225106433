<template>
  <b-container>
    <b-row class="mt-5">
      <b-col cols="12" md="6" lg="4">
        <b-form>
          <div class="mb-2" v-html="$t('new_campaign.new_campaign_year')"></div>

          <!-- sélecteur de date -->
          <b-form-group label-for="input-name">
            <b-form-select
              id="input-name"
              class="w-auto"
              v-model="targetCampaignName"
              :options="yearsList"
            ></b-form-select>
          </b-form-group>

          <!-- sélecteur de farm -->
          <b-form-group :label="$t('global_filters.farm')" label-for="input-farm">
            <b-form-input
              :value="currentFarm ? currentFarm.name : $t('global_filters.all_farms')"
              disabled
            ></b-form-input>
          </b-form-group>

          <!-- sélecteur de date de début -->
          <b-form-group :label="$t('new_campaign.start_date')" label-for="input-startDate">
            <b-form-datepicker
              id="input-startDate"
              class="w-auto"
              v-model="startDate"
              locale="fr"
              placeholder=""
            ></b-form-datepicker>
          </b-form-group>

          <!-- sélecteur de date de fin -->
          <b-form-group :label="$t('new_campaign.end_date')" label-for="input-endDate">
            <b-form-datepicker
              id="input-endDate"
              class="w-auto"
              v-model="endDate"
              locale="fr"
              placeholder=""
            ></b-form-datepicker>
          </b-form-group>

          <!-- sélecteur de l'année de référence -->
          <b-form-group
            class="w-auto"
            :label="$t('new_campaign.new_campaign_reference_year')"
            label-for="input-referenceCampaign"
          >
            <b-form-select
              id="input-referenceCampaign"
              class="w-auto"
              v-model="referenceCampaignId"
              :options="pastCampaigns"
            ></b-form-select>
          </b-form-group>
        </b-form>

        <div class="mt-5 d-flex justify-content-end">
          <button class="btn button-outline text-center mr-2" @click="$router.push({ name: 'home' })">
            {{ $t("new_campaign.cancel") }}
          </button>
          <b-button class="button-default text-center" @click="saveNewCampaign" :disabled="creationOnGoing">
            <span v-if="creationOnGoing">
              <b-spinner small></b-spinner>
              {{ $t("new_campaign.generating") }}...
            </span>
            <span v-else>
              {{ $t("new_campaign.continue") }}
            </span>
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { TILE_UPDATE } from "@/store/modules/page-tile";

import { EventBus } from "@/eventBus.js";

import { dateToISOString, askConfirm } from "@/utils/commons";

import CampaignModule from "@/store/modules/campaign";

import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      creationOnGoing: false,
      allCampaigns: [],
    };
  },
  created() {
    if (!this.$store.state["campaign"]) {
      this.$store.registerModule("campaign", CampaignModule);
    }
    this.setTitle();
  },
  computed: {
    ...mapGetters({
      farms: "farm/farms",
      currentFarm: "farm/currentFarm",
    }),
    /* formulaire : synchronise le nom de la campagne avec le store */
    targetCampaignName: {
      get() {
        return this.$store.state.campaign.newCampaign.targetCampaignName;
      },
      set(value) {
        this.$store.commit("updateNewCampaign", { field: "targetCampaignName", value: value.toString() });
      },
    },
    /* formulaire : synchronise la date de début de la campagne avec le store */
    startDate: {
      get() {
        return this.$store.state.campaign.newCampaign.startDate;
      },
      set(value) {
        this.$store.commit("updateNewCampaign", { field: "startDate", value: value });
      },
    },
    /* formulaire : synchronise la date de fin de la campagne avec le store */
    endDate: {
      get() {
        return this.$store.state.campaign.newCampaign.endDate;
      },
      set(value) {
        this.$store.commit("updateNewCampaign", { field: "endDate", value: value });
      },
    },
    /* formulaire : synchronise l'année de référence de la campagne avec le store */
    referenceCampaignId: {
      get() {
        return this.$store.state.campaign.newCampaign.referenceCampaignId;
      },
      set(value) {
        this.$store.commit("updateNewCampaign", { field: "referenceCampaignId", value: value });
      },
    },
    /* getter : liste des années */
    yearsList() {
      /* on prend la liste des campagnes non débutées */
      let campaigns = this.notStartedCampaigns.map((c) => c.name) || [];

      /* sur ces campagnes on va ajouter "l'année prochaine" */
      let nextYear;
      if (campaigns.length > 0) {
        /* s'il y a des campagnes non débutées, on prend la dernière et on lui ajoute + 1 */
        nextYear = parseInt(this.lastNotStartedCampaign.name) + 1;
      } else {
        /* sinon */

        /* s'il y a des campagnes débutées, on prend la dernière et on lui ajoute + 1 */
        if (this.lastAlreadyStartedCampaign) {
          nextYear = parseInt(this.lastAlreadyStartedCampaign.name) + 1;

          /* sinon, on prend l'année actuelle et on ajoute 1 */
        } else nextYear = new Date().getFullYear() + 1;
      }
      return [...campaigns, , nextYear].filter((c) => c != undefined);
    },
    /* getter : liste des campagnes sous la forme d'une liste d'objets { id, name } */
    pastCampaigns() {
      return this.$store.state.campaign.campaigns.map((c) => {
        return { value: c.id, text: c.name };
      });
    },
    /* getter : liste des campagnes déjà commencées, suivant le critère que leur date de début est avant aujourd'hui */
    alreadyStartedCampaigns() {
      return this.$store.state.campaign.campaigns.filter((c) => Date.parse(c.startDate) < Date.now());
    },
    /* getter : dernière campagne déjà commencée */
    lastAlreadyStartedCampaign() {
      return this.alreadyStartedCampaigns[this.alreadyStartedCampaigns.length - 1];
    },
    notStartedCampaigns() {
      /* getter : liste des campagnes non commencée, suivant le critère que leur date de début est aujourd'hui ou après aujourd'hui */
      return this.$store.state.campaign.campaigns.filter((c) => Date.parse(c.startDate) >= Date.now());
    },
    /* getter : dernière campagne non commencée */
    lastNotStartedCampaign() {
      return this.notStartedCampaigns[this.notStartedCampaigns.length - 1];
    },
  },
  mounted() {
    // on initialise les valeurs
    this.updateCurrentCampaignForm();

    EventBus.$emit("hideGlobalFilters");
  },
  methods: {
    ...mapActions({ getAllCampaigns: "getAllCampaigns" }),
    /**
     * sauvegarde la campagne (d'après les données du formulaire)
     * note : les données sont déjà synchronisées avec le store
     */
    async saveNewCampaign() {
      // get all campaigns, without filter on farm
      this.allCampaigns = await this.getAllCampaigns({ commitAfterFetch: false, filterOnFarm: false });

      /* s'il existe déjà une campagne avec ce nom, on ne peut pas sauvegarder */
      const campaignExists = this.allCampaigns.find((c) => c.name == this.targetCampaignName);
      const campaignExistsForCurrentFarm = this.$store.state.campaign.campaigns.find(
        (c) => c.name == this.targetCampaignName,
      );

      this.creationOnGoing = true;

      try {
        // case where campaign exists for the current farm : we ask confirmation
        if (campaignExistsForCurrentFarm) {
          if (await askConfirm(this.$t("new_campaign.erase_warning"))) {
            // we patch existing campaign
            await this.$store.dispatch("saveCampaign", { campaign_id: campaignExists.id });
          } else {
            throw "Updated canceled";
          }

          // case where campaign exists for another farm
        } else if (campaignExists) {
          // we patch existing campaign
          await this.$store.dispatch("saveCampaign", { campaign_id: campaignExists.id });

          // case where campaign does not exist yet
        } else {
          // we create (post) new campaign
          /* sinon on sauvegarde la campagne */
          await this.$store.dispatch("saveCampaign");
        }

        this.$router.push({ name: "cropfield.list" });
      } catch (error) {
        console.log(error);
      } finally {
        this.creationOnGoing = false;
      }
    },
    updateCurrentCampaignForm(forceUpdateFromReference = false) {
      /* le nom :
      on prend soit (dans l'ordre) :
      - la dernière campagne non démarrée ;
      - le dernier élément de la liste de dates (yearsList)
      */
      this.targetCampaignName =
        //this.yearsList.includes(this.targetCampaignName) ||
        this.targetCampaignName ||
        (this.lastNotStartedCampaign && this.lastNotStartedCampaign.name) ||
        this.yearsList[this.yearsList.length - 1];

      /* l'année de référence :
      on prend soit (dans l'ordre) :
      - la référence déjà dans le store ;
      - la dernière campagne démarrée
      */
      this.referenceCampaignId =
        this.referenceCampaignId || (this.lastAlreadyStartedCampaign && this.lastAlreadyStartedCampaign.id) || "";

      /* s'il y a une campagne qui porte ce nom-là, met à jour les sélecteur de dates */
      const currentCampaign = this.$store.state.campaign.campaigns.find((c) => c.name == this.targetCampaignName);

      if (currentCampaign) {
        this.$store.commit("updateNewCampaign", { field: "startDate", value: currentCampaign.startDate });
        this.$store.commit("updateNewCampaign", { field: "endDate", value: currentCampaign.endDate });
      }
      if ((!currentCampaign && this.referenceCampaignId) || forceUpdateFromReference) {
        this.updateDatePickers(this.targetCampaignName, this.referenceCampaignId);
      }
    },
    /**
     * met à jour les sélecteurs de date
     */
    updateDatePickers() {
      // récupère la campagne de référence d'après l'id
      const campaign = this.$store.state.campaign.campaigns.find((c) => c.id == this.referenceCampaignId);

      if (!campaign) return;

      // année de référence
      const referenceYear = parseInt(campaign.name);
      // année de la nouvelle campagne
      const targetYear = parseInt(this.targetCampaignName);
      // différence entre les deux
      const difference = targetYear - referenceYear;

      // définit date de début et de fin de la nouvelle campagne
      let startDate = new Date(campaign.startDate);
      startDate.setFullYear(startDate.getFullYear() + difference);

      let endDate = new Date(campaign.endDate);
      endDate.setFullYear(endDate.getFullYear() + difference);

      this.$store.commit("updateNewCampaign", { field: "startDate", value: dateToISOString(startDate) });
      this.$store.commit("updateNewCampaign", { field: "endDate", value: dateToISOString(endDate) });
    },
    /**
     * met à jour le bandeau de titre
     */
    setTitle() {
      let t = this;
      this.$store.commit(TILE_UPDATE, {
        title: this.$t("new_campaign.title"),
        buttons: [],
      });
    },
  },
  watch: {
    yearsList() {
      this.updateCurrentCampaignForm();
    },
    targetCampaignName() {
      this.updateCurrentCampaignForm();
    },
    referenceCampaignId() {
      this.updateCurrentCampaignForm(true);
    },
  },
};
</script>

<style lang="scss" scoped></style>
