<template>
  <div>
    <Treatment
      taskName="import_cropfields_from_smag"
      :async="true"
      :taskTimeout="90000"
      :title="$t('cropfields.import_export.import_from_smag')"
      :startButton="$t('commons.imports.import')"
      :finishButton="$t('commons.imports.finish')"
      :failMessage="$t('cropfields.import_export.import_cropfields_fail')"
      :onBack="onBack"
      :onClose="onClose"
      :onTreatmentDone="onTreatmentDone"
    >
      <TC_Campaign />
      <TC_Farm />
      <TC_ClientId_NoTemplate />
    </Treatment>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import Treatment from "@/components/commons/treatments/TreatmentExecution";
import TC_ClientId_NoTemplate from "@/components/commons/treatments/TC_ClientId_NoTemplate";
import TC_Campaign from "@/components/commons/treatments/TC_Campaign.vue"
import TC_Farm from "@/components/commons/treatments/TC_Farm";

export default {
  methods: {
    ...mapActions({
      fetchCropfields: "cropfield/fetchEntries",
      initFilters: "cropfield/initFilters",
      fetchFarms: "farm/fetchFarms",
    }),
    onBack() {
      this.$router.push({ name: this.isOnMap ? "cropfield.map.importexport" : "cropfield.importexport" });
    },
    onClose() {
      this.$router.push({ name: this.isOnMap ? "cropfield.map" : "cropfield.list" });
    },
    async onTreatmentDone() {
      this.fetchFarms();

      await this.fetchCropfields();
      this.initFilters();
    },
  },
  computed: {
    isOnMap() {
      return this.$route.name.includes("map");
    },
  },
  components: { Treatment, TC_ClientId_NoTemplate, TC_Campaign, TC_Farm },
};
</script>
