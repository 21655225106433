import AuthApi from "@/api/auth.api";

export default {
  namespaced: true,
  state: {
    customer: {},
    globalFiltersLoaded: false,
    user: {
      authorizations: {},
    },
  },
  getters: {
    user: (state) => state.user,
    customer: (state) => state.customer,
    globalFiltersLoaded: (state) => state.globalFiltersLoaded,
    authorizations: (state) => {
      return {
        menu: state.user.authorizations.menu,
        ...state.user.authorizations.pages
      }
    },
  },
  mutations: {
    setGlobalFiltersLoaded: (state) => {
      state.globalFiltersLoaded = true;
    },
    setUser: (state, user) => {
      state.user = user;
    },
    setCustomer: (state, customer) => {
      state.customer = customer;
    },
  },
  actions: {
    /**
     * fetch the name of the customer
     * used to display on homepage
     *
     */
    async fetchCustomer({ commit }) {
      const response = await AuthApi.getCustomer();
      commit("setCustomer", response.data);
    },
    async fetchUser({ commit, getters }) {
      const response = await AuthApi.getUser();
      commit("setUser", response.data);
    },
  },
};
