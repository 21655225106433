<template>
  <!-- Name and surface form, shown when the goemetry has been drawn -->
  <b-row>
    <b-col cols="6" class="mb-3">
      <h4 class="subtitle-form mb-2 text-nowrap">{{ $t("cropfields.list.name") }}</h4>
      <EditableCellText
        @updateRow="updateRow"
        :rowId="currentEntry.id"
        :cellData="currentEntry.toBeConfirmed ? null : currentEntry.name"
        field="name"
        :required="true"
      />
    </b-col>
    <b-col cols="6" class="mb-3">
      <h4 class="subtitle-form mb-2 text-nowrap">{{ $t("cropfields.list.surface") }}</h4>
      <EditableCellNumber
        @updateRow="updateRow"
        :rowId="currentEntry.id"
        :cellData="editedEntry.surface || currentEntry.surface"
        field="surface"
        :required="true"
      />
    </b-col>
    <b-col cols="6">
      <h4 class="subtitle-form mb-2 text-nowrap">{{ $t("cropfields.list.receivedInExchange") }}</h4>
      <Checkbox
        @updateRow="updateRow"
        :rowId="currentEntry.id"
        :cellData="editedEntry.receivedInExchange"
        field="receivedInExchange"
      />
    </b-col>
    <b-col cols="6">
      <h4 class="subtitle-form mb-2 text-nowrap">{{ $t("cropfields.list.farmId") }}</h4>
      <PacageDropdown
        @updateRow="updateRow"
        :rowId="currentEntry.id"
        :cellData="editedEntry.farm || currentEntry.farm"
        :activeFarmAsDefault="true"
        :ignoreExternalUpdaters="true"
        field="farm"
      />
    </b-col>
  </b-row>
</template>

<script>
import EditableCellText from "@/components/commons/editable/EditableCellText.vue";
import EditableCellNumber from "@/components/commons/editable/EditableCellNumber.vue";
import PacageDropdown from "@/components/commons/PacageDropdown.vue";
import Checkbox from "@/components/commons/Checkbox.vue";

import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  components: { EditableCellText, EditableCellNumber, PacageDropdown, Checkbox },
  mounted() {
    if (this.currentEntry?.toBeConfirmed) {
      this.updateRow(this.currentEntry.id, {name: null});
    }
  },
  methods: {
    updateRow(id, data) {
      this.$store.dispatch("cropfield/setEditedEntryValue", data);
    },
  },
  computed: {
    ...mapGetters({
      currentEntry: "cropfield/currentEntry",
      editedEntry: "cropfield/editedEntry",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
