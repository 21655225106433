<template>
  <!-- Treatment Component : target sofware for Agro EDI -->
  <div class="mt-4">
    <h4 class="subtitle-form mb-2">{{ $t("commons.exports_reports.target_software") }}</h4>
      <!-- display the choices -->
		<b-row>
			<b-col>
				<v-select
					label="name"
					:options="receivers"
					@input="onInput"
					required
				>
					<template #no-options>
						{{ $t("dropdown.no_option") }}
					</template>
				</v-select>
			</b-col>
		</b-row>
  </div>
</template>

<script>
import TreatmentFormPartMixin from "@/components/commons/treatments/TreatmentFormPartMixin";
import { TreatmentFormPart } from "@/models/treatments/Treatment.js";
import { mapGetters } from "vuex";

export default {
  mixins: [TreatmentFormPartMixin],
  data() {
    return {
			receiver: null
    };
  },
  async mounted() {
		// Récupération de la liste des logiciels cibles pour l'export EDI
		await this.$store.dispatch("getAgroEdiReceivers");
  },
	computed: {
		...mapGetters({
      receivers: "agroEdiReceivers"
    }),
	},
  methods: {
    validateForm() {
			// Le champ est obligatoire
			if (!this.receiver || Object.keys(this.receiver).length === 0) {
				this.isValid = false;
				return
			}
			this.isValid = true;
			let result = [
				new TreatmentFormPart("ansi", this.receiver.ansi),
				new TreatmentFormPart("geom_srid", this.receiver.srid),
				new TreatmentFormPart("pac_island_mandatory", this.receiver.pac_island_mandatory),
			]
      return result;
    },

		onInput(choice) {
			this.receiver = choice;
		}
  },
};
</script>

<style></style>
