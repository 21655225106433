<template>
  <!-- Boîte indiquant à l'utilisateur qu'une parcelle sera créée pour la double culture -->
  <b-modal ref="doubleCropConfirm" hide-header centered>
    <div class="row">
      <div class="col-3 px-3 pt-4 d-flex justify-content-center">
        <!-- image -->
        <div>
          <img :src="doubleCropIcon" width="100%"/>
        </div>
        
      </div>
      <div class="col-9 my-4">
        <!-- Text -->
        <div>
            {{$t("cropfields.details.defineNewCrop_modal_info")}}
        </div>
      </div>
    </div>
    <template v-slot:modal-footer>
      <div class="w-100 d-flex justify-content-end align-items-center">
        <b-button
          class="btn btn-primary mr-2"
          @click="hideModal()"
          >{{ $t('cancel') }}</b-button
        >

        <b-button
          class="btn btn-success"
          @click="defineNewCrop"
          >{{ $t('ok') }}</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { EventBus } from "@/eventBus.js";

import doubleCropIcon from "@/assets/images/number_two.svg";

export default {
  data() {
    return {
      doubleCropIcon: doubleCropIcon,
      canShowModal: true,
    };
  },
  created() {
    this.getNewCropCookie();
    EventBus.$on("showDoubleCropModal", this.showModal);
    EventBus.$on("hideDoubleCropModal", this.hideModal);
  },
  beforeDestroy(){
    EventBus.$off("showDoubleCropModal", this.showModal);
    EventBus.$off("hideDoubleCropModal", this.hideModal);
  },
  methods: {
    /**
     * Affiche la boite
     */
    showModal() {
      if(this.canShowModal == true)
        this.$refs.doubleCropConfirm.show();
      else
        this.$emit("defineNewCrop");

    },
    /**
     * @description Masque la boîte
     */
    hideModal() {
      this.$refs.doubleCropConfirm.hide();
    },

    /**
     * Active l'évènement pour déclencher la définition d'une nouvelle culture
     */
    defineNewCrop() {
      // on crée un cookie pour que la boîte ne soit pas affichée à chaque clic
      document.cookie = 'newCropDefined=true; max-age= 86400';
			this.$emit("defineNewCrop");
    },

    /**
     * Récupération du cookie permettant de savoir si la définition d'une 2e culture a déja été faite ou pas
     */
    getNewCropCookie() {
      if(document.cookie != ""){
      let cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].trim().split("=");
        if (cookie[0] == 'newCropDefined')
          this.canShowModal = cookie[1].toLowerCase() != 'true';
      }
    }
    }
  }
}
</script>