import { commonGetters, commonMutations, commonActions } from "./commons.js";

import InputStockApi from "@/api/inputstock.api";
import Vue from "vue";
export const INPUTSTOCKS_BY_FAMILY_CODE = "GET INPUTSTOCK BY FAMILY CODE";
export const FEATURES_INPUTSTOCK = "GET FEATURE INPUTSTOCK";
export const GET_STOCK_STATE = "GET THE STOCK STATE";
export const SET_REF_PRODUCT = "SET REF_PRODUCT";
export const SET_FARMSEED = "SET FARMSEED";

export default {
  namespaced: true,
  state: {
    _entries: {},
    sortField: "name",
    currentId: null,
    filterData: {},
    filterSelection: {},
    filters: [],
    newEntry: {},
    entryUpdated: {},
    editedEntry: {},
    entryLoaded: true,
    searchQuery: "",
    lastEntryDisplayed: null,
    page: 1,
    maxPage: 0,
    productTypes: [],
    productFamily: [],
    entriesByFamilyCode: [],
    stockState: {},
    refProduct_id: null,
    farmSeed: false,
    features: {},
  },
  getters: {
    ...commonGetters(),
    productTypes: state => state.productTypes,
    productFamily: state => state.productFamily,
    entriesByFamilyCode: state => state.entriesByFamilyCode,
    stockState: state => state.stockState,
    refProduct_id: state => state.refProduct_id,
    farmSeed: state => state.farmSeed,
    features: state => state.features,
  },
  mutations: {
    ...commonMutations(),
    [INPUTSTOCKS_BY_FAMILY_CODE]: (state, payload) => {
      state.entriesByFamilyCode = payload;
    },
    [FEATURES_INPUTSTOCK]: (state, payload) => {
      state.features = payload.form;
    },
    [GET_STOCK_STATE]: (state, stockState) => {
      state.stockState = stockState;
    },
    [SET_REF_PRODUCT]: (state, refProduct_id) => {
      state.refProduct_id = refProduct_id;
    },
    [SET_FARMSEED]: (state, farmSeed) => {
      state.farmSeed = farmSeed;
    } 
  },
  actions: {
    ...commonActions(),
    async fetchEntries({ state, getters, commit, dispatch, rootGetters }) {
      //commit(INPUTSTOCK_LOADING, true);
      const productType = await dispatch("getFilterValueId", "productType");
      const productFamily = await dispatch("getFilterValueId", "productFamily");
      const available = await dispatch("getFilterValueId", "available");
      
      let payload = {
        ...(productType != null && { productType: productType }),
        ...(productFamily != null && { productType__productFamily: productFamily }),
        ...(available != null && { available: available }),
      };
      if (rootGetters["farm/currentFarm"]) {
        payload["farm_id"] = rootGetters["farm/currentFarm"].id;
      }
      const response = await InputStockApi.getInputStocksFiltered({
        ...payload,
        limit: 5000,
      });
      commit("setEntries", { entries: response.data.results });

      //commit(INPUTSTOCK_LOADING, false);
    },

    async getInputStocksOrdered({ commit }, payload) {
      //commit(INPUTSTOCK_LOADING, true);
      const response = await InputStockApi.getInputStocks(payload);
      commit(GET_INPUTSTOCKS_ORDERED, response.data);
      //commit(INPUTSTOCK_LOADING, false);
      return response.data;
    },

    async getAllFiltersInputStock({ commit }, { param, prodFamily }) {
      //commit(INPUTSTOCK_LOADING, true);
      const response = await InputStockApi.getFiltersInputStock(param, prodFamily);
      commit("setFilterData", {
        name: param,
        elements: response.data,
      });
      //commit(INPUTSTOCK_LOADING, false);
      return response.data;
    },

    async getOneInputStock({ commit }, payload) {
      //commit(INPUTSTOCK_LOADING, true);
      const response = await InputStockApi.getInputStock(payload.id);

      commit("setCurrentId", { id: payload.id });
      commit("updateEntry", { id: payload.id, data: response.data });

      //commit(INPUTSTOCK_LOADING, false);
      return response.data;
    },

    async createEntry({ commit }, { entry }) {
      //commit(INPUTSTOCK_LOADING, true);
      const response = await InputStockApi.createInputStock(entry);

      commit("setCurrentId", { id: response.data.id });
      commit("updateEntry", { id: response.data.id, data: response.data });

      //commit(INPUTSTOCK_LOADING, false);
      return response.data;
    },

    async updateEntry({ state, commit }, { id, data }) {
      //commit(INPUTSTOCK_LOADING, true);
      const response = await InputStockApi.updateInputStock(data, id);
      // Gestion du cas où le produit à modifier à été supprimé et remplacé par un autre
      if (response.data.id != id){
        commit("deleteEntry", { id });
        commit("setCurrentId", { id: response.data.id });
      }
      commit("updateEntry", { id: response.data.id, data: response.data });
      //commit(INPUTSTOCK_LOADING, false);
      return response.data;
    },

    async deleteEntry({ commit }, { id }) {
      //commit(INPUTSTOCK_LOADING, true);
      const response = await InputStockApi.deleteInputStock(id);
      commit("resetCurrentId");
      commit("deleteEntry", { id });
      //commit(INPUTSTOCK_LOADING, false);
      return response;
    },

    async getInputStokByFamilyCode({ commit }, payload) {
      const response = await InputStockApi.getInputStocksFiltered(payload);
      commit(INPUTSTOCKS_BY_FAMILY_CODE, response.data);
    },

    async updateFilterSelection({ state, commit, dispatch }, { filterName, elementId }) {
      // met à jour les filtres dans le store
      await commit("setFilter", {
        filterName: filterName,
        elementId: elementId,
      });

      if (filterName == "productFamily") {
        const currentElementId = state.filterSelection[filterName];

        commit("setFilter", {
          filterName: "productType",
          elementId: null,
        });

        let family = null;
        if (currentElementId != null) family = state.filterData[filterName].find(e => e.id === state.filterSelection[filterName])?.value;

        // on récupère les filtres correspondant au product family
        await dispatch("getAllFiltersInputStock", {
          param: "productType",
          prodFamily: currentElementId,
        });
      }
    },

    async initFilters({ dispatch, commit }) {
      await dispatch("getAllFiltersInputStock", { param: "productFamily", prodFamily: "" });
      await dispatch("getAllFiltersInputStock", { param: "productType", prodFamily: "" });

      commit("setFilterData", {
        name: "available",
        elements: [
          ["true", "Disponible"],
          ["false", "Non disponible"],
        ],
      });
    },

    async getInputStockFeatures({ commit }, payload) {
      const response = await InputStockApi.getInputStockFeatures(payload.id);
      commit(FEATURES_INPUTSTOCK, { form: response.data });
      return response.data;
    },

    async getInputStockByCropType({ commit }, payload) {
      const response = await InputStockApi.getInputStocks(payload);
      return response.data.results;
    },

    async getMixedDoses({ commit }, payload) {
      const response = await InputStockApi.getMixedDoses(payload.products, payload.percents);
      return response.data;
    },

    async getMixedName({ commit }, payload) {
      const response = await InputStockApi.getMixedName(payload.products, payload.percents);
      return response.data.name;
    },

    async getInputStockDoseUnits({commit}, payload) {
      const response = await InputStockApi.getInputStockUnits(payload.id);
      return response.data.doseUnits;
    },

    async getInputStockQuantityUnits({commit}, payload) {
      const response = await InputStockApi.getInputStockUnits(payload.id);
      return response.data.quantityUnits;
    },

    async getStockState({commit}, payload) {
      const response = await InputStockApi.getStockState(payload.id);
      commit(GET_STOCK_STATE, response.data);
      return response.data;
    },

    setRefProduct({commit}, refProduct_id){
      commit(SET_REF_PRODUCT, refProduct_id);
    },

    setFarmSeed({commit}, farmSeed){
      commit(SET_FARMSEED, farmSeed);
    }
  },
};
