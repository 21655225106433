<template>
  <div>
    <!-- Treatment Component : Campaign -->
    <!-- An implicit component, that does not show a campaign selector, but add the current campaign name to treatment form -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import TreatmentFormPartMixin from "@/components/commons/treatments/TreatmentFormPartMixin";
import { TreatmentFormPart } from "@/models/treatments/Treatment.js";

export default {
  mixins: [TreatmentFormPartMixin],
  methods: {
    validateForm() {
      this.isValid = true;

      return new TreatmentFormPart("campaign", this.currentCampaign.name);
    },
  },
  computed: {
    ...mapGetters({
      currentCampaign: "getCampaign",
    }),
  },
};
</script>

<style></style>
