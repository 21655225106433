import Gateway from "./base";

export default class ProductsAliasesApi {
  /**
   * Get products Aliases by productType ID and search by name
   *
   * @param id
   * @param name
   */
  static getProductsAliases(idProductType = "", idProductFamily = "", name = undefined) {
    const params = { productType_id: idProductType, productType__productFamily_id: idProductFamily };
    if (typeof name !== "undefined") params.search = name;
    return Gateway.get(`/refapi/productAliases/`, params);
  }

  static getOtherFeatures(id){
    return Gateway.get(`/refapi/productAliases/${id}/otherFeatures/`);
  }
}
