import Gateway from "./base";

export default class NonTreatedZoneApi {
  /**
   * Get All non treated zones
   *
   */
  static getNonTreatedZones(id) {
    return Gateway.get(`/api/cropfields/${id}/nonTreatedZones/`);
  }

  /**
   * Get All non treated zones types
   *
   */
  static getNonTreatedZoneTypes(params) {
    return Gateway.get("/refapi/nonTreatedZoneTypes/", params);
  }

  /**
   * Get All non treated zone widths
   *
   */
  static getNonTreatedZoneWidths(params) {
    return Gateway.get("/refapi/nonTreatedZoneWidths/", params);
  }

  /**
   * Create non treated zone
   *
   * @param data
   */
  static createNonTreatedZone(id, data) {
    return Gateway.put(`/api/cropfields/${id}/nonTreatedZones/`, data);
  }

}
