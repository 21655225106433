import _ from "lodash";

import { commonGetters, commonMutations, commonActions } from "./commons.js";

import { i18n } from "@/i18n.js";

import TourApi from "@/api/tour.api";

export default {
  namespaced: true,
  state: {
    _entries: {},
    currentId: null,
    editedEntry: {},
    entryLoaded: true,
    filterData: {},
    filterSelection: {},
    filters: [],
    inputForm: {},
    searchQuery: "",
    // initial fetch status
    isInitialFetchDone: false,
    // initial fetch methods that are called when the fetch is done
    initialFetchCallbacks: []
  },
  getters: {
    ...commonGetters(),
    // return a unique list of crops from the tour cropfields
    crops: (state, getters) => _.uniqBy(
      (getters.editedEntry.cropFields || getters.currentEntry.cropFields || [])
      .filter(c => c.crop)
      .map((c) => {
        return {
          id: c.crop,
          name: c.crop_name
        }
      }), 'id')
  },
  mutations: {
    ...commonMutations(),
    clearInputForm(state) {
      state.inputForm = {}
    },

    resetSpecificInputs(state) {
      state.editedEntry.cropFields?.forEach(cf => {
        cf.inputs = [];
        cf.mixtureVolume = null;
      });
    }
  },
  actions: {
    ...commonActions(),
    async fetchEntries({ state, getters, commit, dispatch, rootGetters }) {
      let filters = await dispatch("getAllFiltersValues")

      // campaign & farms
      filters["campaign"] = rootGetters.getCampaign.id;
      if (rootGetters["farm/currentFarm"]) {
        filters["farm_id"] = rootGetters["farm/currentFarm"].id;
      }

      const tours = await TourApi.getTours(filters);
      commit("setEntries", { entries: tours.data })
    },
    async getOneTour({ commit }, { id }) {
      const response = await TourApi.getTour(id);
      commit("setCurrentId", { id });
      commit("updateEntry", { id, data: response.data });
      return response.data;
    },
    async createEntry({ commit }, { entry }) {
      const response = await TourApi.createTour(entry);
      commit("setCurrentId", { id: response.data.id });
      commit("updateEntry", { id: response.data.id, data: response.data });
      return response.data;
    },

    async updateEntry({ state, commit }, { id, data }) {
      const response = await TourApi.updateTour(data, id);
      commit("updateEntry", { id: id, data: response.data });
      return response.data;
    },

    async deleteEntry({ commit }, { id }) {
      const response = await TourApi.deleteTour(id);
      commit("resetCurrentId");
      commit("deleteEntry", { id });
      return response;
    },

    async initFilters({ commit, dispatch, state, rootGetters }) {
      state.filters.forEach(async (filter) => {
        if(filter.name == "isTemplate") {
          commit("setFilterData", {
            name: "isTemplate",
            elements: [
              [true, i18n.t("tours.filters.isTemplate")],
            ],
          });
        } else {
          let filters = await dispatch("getAllFiltersValues")

          // campaign & farms
          filters["campaign"] = rootGetters.getCampaign.id;
          if (rootGetters["farm/currentFarm"]) {
            filters["farm_id"] = rootGetters["farm/currentFarm"].id;
          }
          await dispatch("getFiltersTours", {
            query: filter.name,
            params: filters
          });
        }
      });
    },

    async getFiltersTours({ commit }, { query, params }) {
      const response = await TourApi.getFiltersTours(query, params);

      commit("setFilterData", {
        name: query,
        elements: response.data,
      });

      return response.data;
    },

    updateFilterSelection({ commit, dispatch }, { filterName, elementId }) {
      commit("setFilter", {
        filterName: filterName,
        elementId: elementId,
      });
      dispatch("initFilters")
    },

    async generateActivities({}, id) {
      const response = await TourApi.generateActivities(id);
      return response.data?.count;
    }
  },
};
