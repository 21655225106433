<template>
  <div v-if="getValue">
     <b-row class="mb-2" v-for="otherFeature in currentEntry.otherFeatures" v-bind:key="otherFeature.type">
      <b-col cols="4">
        <h4 class="subtitle-form">{{ otherFeature.type }}</h4>
      </b-col>
      <b-col cols="8" class="d-flex align-items-start">
        <div class="bg-text-color text-infos ">
          {{ otherFeature.designation || "N/A" }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import RefProductMixin from "@/mixins/RefProductMixin";

import { mapGetters } from "vuex";

export default {
  mixins: [DetailUpdateMixin, RefProductMixin],
  computed: {
     ...mapGetters({
      inputsStock: "inputStock/entries",
      currentEntry: "inputStock/currentEntry",
      currentEditedEntry: "inputStock/editedEntry",
    }),
    getValue() {
      return this.currentEntry.otherFeatures && this.currentEntry.otherFeatures.length > 0;
    },
  },
};
</script>
