// locales
import Vue from "vue";
import VueI18n from "vue-i18n";

import en from "./locale/en.json";
import fr from "./locale/fr.json";

Vue.use(VueI18n);

export const userLanguage = window.navigator.language.slice(0, 2);

// const messages = { en, fr };
const messages = { fr };


export const i18n = new VueI18n({
  // set locale
  locale: userLanguage,
  fallbackLocale: 'fr',

  numberFormats: {
    fr: {
      currency: {
        style: "currency",
        currency: "EUR",
        currencyDisplay: "symbol",
      },
    },
  },
  // set locale messages
  messages,
});