import { TreatmentFormPart } from "@/models/treatments/Treatment";

export default {
  data() {
    return {
      isValid: false,
      treatment: {},
    };
  },
  inject: ["treatmentData"],
  mounted() {
    this.treatment = this.treatmentData.treatment;

    this.treatment.formParts.push(this);
  },
  computed: {},
  methods: {},
};
