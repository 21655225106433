<template>
  <div>
    <b-row class="m-0 p-0" no-gutters>
      <b-col
        v-for="(col, rowIndex) in rows"
        :key="rowIndex"
        :cols="col.cols || 12" class="px-1">
        <div v-if="col.condition($userCan)">
          <h4 v-if="col.title" class="subtitle-form">{{ col.title }}</h4>
          <component
            v-if="col.component"
            :ref="'detail-component-' + rowIndex"
            :key="'component-' + rowIndex"
            :is="col.component.is"
            :data="col.component.data"
            v-bind="col.component.props"
            :storeEditAction="storeEditAction"
            :storeReadGetter="storeReadGetter"
            :storeEditedGetter="storeEditedGetter"
            v-on="col.component.click ? { click: () => col.component.click() } : {}"
            :class="[col.class && col.class.join(' '), { 'mt-1': !col.title }]"
            >{{ col.component.content }}</component
          >
          <span v-else :class="col.class && col.class.join(' ')">{{ display(col.label) }}</span>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";

export default {
  mixins: [DetailUpdateMixin],
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      hideComponent: {}
    }
  },
  updated() {
    this.handleComponentVisibility()
  },
  methods: {
    display(element) {
      if (typeof element === "function") {
        return element();
      }
      return element;
    },
    /*
      for each component, add a watcher on the hideComponent computed property
      as by default $refs are not reactive in vue, it's a bit tricky
    */
    handleComponentVisibility() {
      // for each component
      for(let i = 0; i < this.rows.length; i++) {
        const component = this.$refs['detail-component-' + i]

        // if it does not exist yet or already watched -> ignore
        if(!component || component.length == 0 || i in this.hideComponent) continue
        
        // add the watcher
        this.$watch(() => component[0]?.hideComponent, (value) => {
          this.$set(this.hideComponent, i, value)
        })

        // and set the initial value
        this.$set(this.hideComponent, i, component[0]?.hideComponent || false)
      }
  }
  },
};
</script>
