import CropTypesApi from "@/api/cropTypes.api";
import { commonMutations } from "./commons.js";

export const ALL_CROPTYPES = "GET ALL CROPTYPES";
export const ONE_CROPTYPE = "GET ONE CROPTYPE";
export const CROPTYPE_LOADING = "LOAD CROPTYPE";
export const CROPTYPES_LOADING = "LOAD CROPTYPES";

export default {
  state: {
    cropTypes: [],
    currentCropType: {},
    cropType_loaded: true,
    cropTypes_loaded: true,
  },
  getters: {
    currentCropType: state => state.currentCropType,
    cropTypes: state => state.cropTypes,
    cropTypeLoaded: state => state.cropType_loaded,
    cropTypesLoaded: state => state.cropTypes_loaded
  },
  mutations: {
    ...commonMutations,
    [ALL_CROPTYPES]: (state, cropTypes) => {
      state.cropTypes = cropTypes;
    },

    [ONE_CROPTYPE]: (state, cropType) => {
      state.currentCropType = cropType;
    },

    [CROPTYPE_LOADING]: (state, status) => {
      state.cropType_loaded = !status;
    },

    [CROPTYPES_LOADING]: (state, status) => {
      state.cropTypes_loaded = !status;
    },
  },
  actions: {
    async getAllCropTypes({ commit, state }) {
      if (state.cropTypes.length > 0)
        return state.cropTypes;
      commit(CROPTYPES_LOADING, true);
      const response = await CropTypesApi.getCropTypes();
      commit(ALL_CROPTYPES, response.data);
      commit(CROPTYPES_LOADING, false);
      return response.data;
    },
    async getOneCropType({ commit }, payload) {
      commit(CROPTYPE_LOADING, true);
      const response = await CropTypesApi.getOneCropType(payload.id);
      commit(ONE_CROPTYPE, response.data);
      commit(CROPTYPE_LOADING, false);
      return response.data;
    },
    async resetCurrentCropType({commit}) {
      commit(ONE_CROPTYPE, {});
    },
    async checkIfFallowCrop({ commit }, payload) {
      commit(CROPTYPE_LOADING, true);
      const response = await CropTypesApi.getOneCropType(payload.id);
      commit(CROPTYPE_LOADING, false);
      return response.data;
    }
  },
};
