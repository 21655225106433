<template>
  <div v-if="this.loadPage">
    <table>
      <tr style="background-color:transparent;">
        <td style="width:300px;">
          <!-- Return to menu select family -->
          <backButton :on-click="onBack" v-show="familySelected !== 0 && this.$route.name === 'inputStock.new'" />
        </td>
        <td>
          <!-- Close button to return on details form -->
          <closeButton :on-click="onClose" />
        </td>
      </tr>
    </table>

    <!-- Select product family -->
    <div v-if="this.$route.name === 'inputStock.new' && familySelected === 0">
      <h3 class="title-form">Enregistrer un nouvel intrant</h3>

      <br />

      <table class="table-family">
        <tr
          class="text-infos line-family"
          v-for="family in productFamilies"
          v-bind:key="family.id"
          :value="family.code"
          @click="selectFamily(family.code)"
        >
          <td class="shape-cell text-center">
            {{ family.designation }}
          </td>
        </tr>
      </table>
    </div>

    <div v-else>
      <table>
        <tr class="transparent-bg">
          <!-- Input name and Family product -->
          <td>
            <h3 class="title-form">{{ currentInputStock.name }}</h3>
            <span v-if="currentInputStock.productFamily_designation" class="text-infos">{{ currentInputStock.productFamily_designation }} </span>
          </td>
        </tr>
      </table>
    </div>

    <!-- Back button to go on the select family -->
    <div v-show="familySelected !== 0 && this.$route.name === 'inputStock.new'">
      <h3 class="title-form" style="padding-top:15px;">{{ titleNewInput() }}</h3>
      <br />
    </div>

    <div v-show="familySelected !== 0">
      <!-- Main product informations -->
      <product :key="'product-' + familySelected" ref="productRef" />

      <!-- Fertilizer informations -->
      <FertilizerForm
        ref="fertilizerForm"
        v-if="
          ['engsy', 'engor'].includes(this.currentInputStock.productFamily_code) ||
          ['engsy', 'engor'].includes(this.familySelected)
        "
      />

      <!-- Phyto informations -->
      <PhytoForm v-else-if="this.currentInputStock.productFamily_code === 'phyto'" />

      <!-- Amendement -->
      <EditAmendement
        ref="editAmendement"
        v-else-if="
          ['amend', 'irrig'].includes(this.currentInputStock.productFamily_code) ||
          ['amend', 'irrig'].includes(this.familySelected)
        "
      />

      <!-- Semance informations -->
      <SemanceForm
        ref="semenceForm"
        v-else-if="this.currentInputStock.productFamily_code === 'sem' || this.familySelected === 'sem'"
      />

      <br />

      <!-- Save input -->
      <button class="btn align-bottom button-save-input" @click="onSave(true)">
        Enregistrer
      </button>

      <br />
      <br />

      <!-- Delete inputStock -->
      <button
        v-show="this.$route.name === 'inputStock.edit'"
        class="btn align-bottom btn-danger button-delete-inputStock"
        @click="onDelete"
      >
        Supprimer
      </button>
    </div>

    <!-- Confirmation modal to delete a inputStock -->
    <DeleteInput ref="deleteInput" :deleteFunction="modalDelInput"></DeleteInput>
  </div>
</template>

<script>
import closeButton from "@/components/commons/CloseButton.vue";
import PhytoForm from "@/components/inputstock/edit/PhytoForm.vue";
import SemanceForm from "@/components/inputstock/edit/SemenceForm.vue";
import FertilizerForm from "@/components/inputstock/edit/FertilizerForm.vue";
import product from "@/components/inputstock/edit/Product.vue";
import { CLEAN_PRODUCT, CLEAN_PRODUCT_INFO, SET_READ_ONLY } from "@/store/modules/products";
import { CLEAN_PRODUCT_TYPE } from "@/store/modules/productType";
import { CLEAN_RESEARCH_RESULTS } from "@/store/modules/productsAliases";
import DeleteInput from "@/components/inputstock/modals/DeleteInput.vue";
import backButton from "@/components/commons/BackButton.vue";
import EditAmendement from "@/components/inputstock/edit/EditAmendement.vue";
import { SET_CURRENT_FAMILY } from "@/store/modules/productFamily";
import { mapGetters, mapMutations } from "vuex";
import { i18n } from "../../i18n.js";
import { EventBus } from "../../eventBus.js";
import { askConfirm } from "@/utils/commons";

export default {
  created: async function() {
    this.loadPage = false;
    this.familySelected = 0;

    if(this.newMode) {
      this.resetCurrentId()
    }
    this.$store.commit(CLEAN_RESEARCH_RESULTS);
    this.$store.commit(CLEAN_PRODUCT_INFO);

    if (this.$route.name === "inputStock.edit") {
      await this.$store.dispatch("getProductTypesByFamily", {
        idProdFamily: this.currentInputStock.productFamily_code,
      });

      this.familySelected = this.currentInputStock.productFamily_code;
    } else if (this.$route.name === "inputStock.new") {
      await this.$store.dispatch("getProductFamilies");
      // await this.$store.dispatch("getinputFormByProductType", { id: 27 });
      this.$store.commit(SET_READ_ONLY, { value: true });
      this.$store.commit(CLEAN_PRODUCT);
      this.$store.commit(CLEAN_PRODUCT_TYPE);
    }

    this.loadPage = true;
  },
  data() {
    return {
      page: this.$route.name,
      loadPage: false,
      toSend: true,
      familySelected: "",
    };
  },
  methods: {
    ...mapMutations({
      resetCurrentId: "inputStock/resetCurrentId",
    }),
    titleNewInput() {
      if (["engsy", "phyto", "sem", "engor", "amend"].includes(this.familySelected))
        return this.$t("inputStock.add.title." + this.familySelected);
    },
    /**
     * Description: Supprime l'inputStock.
     */
    async modalDelInput() {
      this.$refs.deleteInput.modalDeleteInput();
      await this.$store.dispatch("inputStock/deleteEntry", { id: this.$route.params.inputStock_id });
      await this.$store.dispatch("inputStock/getAllInputStocks");
      this.$router.push({ name: "inputStock.list" });
    },

    /**
     * Description: Open modal for delete confirmation.
     */
    onDelete() {
      this.$refs.deleteInput.modalDeleteInput();
    },

    /**
     * Description: Display the corresponding form.
     */
    async selectFamily(code) {
      this.familySelected = code;
      this.$store.commit(SET_CURRENT_FAMILY, { family: code });
      await this.$store.dispatch("getProductTypesByFamily", { idProdFamily: code });
    },

    /**
     * Description: Ferme le formulaire.
     */
    async onClose() {
      if(await askConfirm()) {
        await this.onSave();
      }
      this.goPrevious();
    },

    goPrevious() {
      this.$router.go(-1);
      this.resetCurrentId();
    },

    /**
     * Description: Go back to the select family.
     */
    async onBack() {
      if(await askConfirm()) {
        await this.onSave();
      }
      this.familySelected = 0;
      this.resetCurrentId();
    },

    /**
     * Description: Sauvegarde l'intrant.
     */
    async onSave(goPrevious = false) {
      if (this.$route.name === "inputStock.edit") {
        await this.patchInputStock();
      } else {
        await this.postInputStock();
      }

      let id = this.$route.params.inputStock_id;
      EventBus.$emit("selectOnList", id)

      if(goPrevious)
        this.goPrevious();
    },

    /**
     * Description: Retrieve all informations for patch or post the current inputStock.
     */
    async RetrieveInputStockInfo() {
      let infoProduct = this.$refs.productRef.getProdInfo();
      let compoAndDoseInfo = {};
      let inputStockToPatchOrPost = {};

      if (infoProduct === false) return false;

      if (this.familySelected === "sem") {
        compoAndDoseInfo = this.$refs.semenceForm.getSemenceInfo();

        if (!this.readOnly)
          inputStockToPatchOrPost = {
            productType: infoProduct.productType,
            name: infoProduct.productName,
            refProduct: null,
            available: infoProduct.productAvailable,
            minDoseValue: compoAndDoseInfo.min,
            maxDoseValue: compoAndDoseInfo.max,
            doseUnit: compoAndDoseInfo.unit,
            seedDensity: compoAndDoseInfo.seedDensity,
            seedDensityUnit: compoAndDoseInfo.seedDensityUnit,
            otherFeature: compoAndDoseInfo.otherFeature,
            composition: [],
          };
        else
          inputStockToPatchOrPost = {
            productType: infoProduct.productType,
            name: infoProduct.productName,
            refProduct: infoProduct.productCatalog,
            available: infoProduct.productAvailable,
            composition: [],
          };
      } else if (this.familySelected === "engsy" || this.familySelected === "engor") {
        compoAndDoseInfo = this.$refs.fertilizerForm.getFertilizerInfo();

        if (!this.readOnly)
          inputStockToPatchOrPost = {
            productType: infoProduct.productType,
            name: infoProduct.productName,
            refProduct: null,
            available: infoProduct.productAvailable,
            minDoseValue: compoAndDoseInfo.min,
            maxDoseValue: compoAndDoseInfo.max,
            doseUnit: compoAndDoseInfo.unit,
            otherFeature: compoAndDoseInfo.otherFeature,
            composition: compoAndDoseInfo.composition,
          };
        else
          inputStockToPatchOrPost = {
            productType: infoProduct.productType,
            name: infoProduct.productName,
            refProduct: infoProduct.productCatalog,
            available: infoProduct.productAvailable,
            composition: [],
          };
      } else if (this.familySelected === "amend") {
        compoAndDoseInfo = this.$refs.editAmendement.getAmendementInfo();

        if (!this.readOnly)
          inputStockToPatchOrPost = {
            productType: infoProduct.productType,
            name: infoProduct.productName,
            refProduct: null,
            available: infoProduct.productAvailable,
            minDoseValue: compoAndDoseInfo.min,
            maxDoseValue: compoAndDoseInfo.max,
            doseUnit: compoAndDoseInfo.unit,
            otherFeature: compoAndDoseInfo.otherFeature,
            composition: compoAndDoseInfo.composition,
          };
        else
          inputStockToPatchOrPost = {
            productType: infoProduct.productType,
            name: infoProduct.productName,
            refProduct: infoProduct.productCatalog,
            available: infoProduct.productAvailable,
            composition: [],
          };
      } else {
        inputStockToPatchOrPost = {
          productType: infoProduct.productType,
          name: infoProduct.productName,
          refProduct: infoProduct.productCatalog,
          available: infoProduct.productAvailable,
          composition: [],
        };
      }

      return inputStockToPatchOrPost;
    },

    /**
     * Description: Create a inputStock.
     */
    async postInputStock() {
      let inputStockToPost = await this.RetrieveInputStockInfo();

      if (inputStockToPost === false) {
        EventBus.$emit("toast", {
          message: i18n.t("toasts.information_missing"),
          variant: "warning",
        });
        return false;
      }
      await this.$store.dispatch("inputStock/createInputStock", {
        data: inputStockToPost,
      });
    },

    /**
     * Description: Patch a inputStock.
     */
    async patchInputStock() {
      let inputStockToPatch = await this.RetrieveInputStockInfo();

      if (inputStockToPatch === false) {
        EventBus.$emit("toast", {
          message: i18n.t("toasts.information_missing"),
          variant: "warning",
        });
        return false;
      }

      await this.$store.dispatch("inputStock/updateInputStock", {
        data: inputStockToPatch,
        id: this.$route.params.inputStock_id,
      });

      await this.$store.dispatch("inputStock/fetchEntries");

    },
  },
  components: {
    closeButton,
    PhytoForm,
    SemanceForm,
    FertilizerForm,
    product,
    DeleteInput,
    backButton,
    EditAmendement,
  },
  computed: {
    ...mapGetters({
      inputStocks: "inputStock/entries",
      currentInputStock: "inputStock/currentEntry",
      currentId: "inputStock/currentId",
    }),
    ...mapGetters([
      "inputStock/productFamily",
      "currentProduct",
      "productFamilies",
      "readOnly",
      "productTypesByFamily",
    ]),
    newMode() {
      return this.$route.name === "inputStock.new";
    }
  },
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: separate;
  border-spacing: 0 5px;
}

tr {
  background-color: $secondary-light-color;
}

.table-family {
  margin-left: 20%;
}

.line-family:hover {
  background-color: $secondary-color;
}

.line-family {
  cursor: pointer;
}

/**
 * Shape <td>  cell.
 */
.shape-cell {
  border-radius: 3px 3px 3px 3px;
  padding-right: 50px;
  padding-left: 50px;
  height: 50px;
}

.vue-js-switch {
  &.toggled {
    .v-switch-core {
      border-radius: 0 !important;
    }
  }
}

.dispo-day {
  border: 1px solid #e9ecef;
  border-radius: 2px;
  padding: 8px 0;

  &.o {
    border-bottom: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.c {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .open,
  .closed {
    font-size: 11px;
  }
}

.vdatetime-input {
  height: 30px;
  width: 200px;
  font-size: 19px;
  border: none;
  border-bottom: 1px solid black;
  margin-left: 20px;
}

/**
 * Close Button align right.
 */
.position-close-button {
  text-align: right;
}

.position-add {
  position: fixed;
  right: 50px;
  top: 117px;
}

/**
 * Params button save input.
 */
.button-save-input {
  background-color: $background-text-color;
  width: 100%;
}

.button-save-input:hover {
  background: $secondary-light-color;
}

/**
 * Params button delete inputStock.
 */
.button-delete-inputStock {
  width: 100%;
}
</style>
