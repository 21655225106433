import { i18n } from "@/i18n.js";
import { t, comp, choice, container, storeGet, storeDispatch, storeCommit } from "@/views/common/details/commons";

export const machineDetail = ({ entry }) => {

  storeCommit("machine/setLastEntryDisplayed", entry.id);

  return {
    title: entry.model,
    // subtitle: new Date(entry.startDate).toLocaleDateString(),
    // Dé-commenter les 3 lignes ci-dessous pour afficher le bouton supprimer sur le panneau de détail
    // actions: {
    //   delete: ({ entry }) =>storeDispatch("machine/deleteEntry", { id: entry.id }),
    // },
    rows: [
      comp("MachineDetails"),
    ],
  };
};


export const machineEdit = ({ entry, title }) => {

  if (entry.id) storeCommit("machine/setLastEntryDisplayed", entry.id);

  return {
    title: i18n.t(title) || entry.model,
    actions: {
      save: () => storeDispatch("machine/updateEditedEntry"),
      delete: ({ entry }) =>storeDispatch("machine/deleteEntry", { id: entry.id }),
    },
    rows: [
      comp("MachineEdit"),
    ],
  };
};

export const machineNew = ({ entry, editedEntry }) => {
  storeCommit("machine/resetEditedEntry");
  storeCommit("machine/resetCurrentId");
  const title = "machines.details.create_new_machine";
  return machineEdit({ entry, editedEntry, title});
};

