import { storeDispatch, storeCommit } from "@/views/common/details/commons";

import {
  inputStockDetail,
  inputStockEdit,
  inputStockNew,
  inputStockNewEdit,
  inputStockProductRisks,
  inputStockProductInfos,
} from "./views";

const routes = {
  detail: "inputStock.details",
  edit: "inputStock.edit",
  list: "inputStock.list",
};

const init = async ({ route }) => {
  let id = route.params.inputStock_id;
  if (id) await storeDispatch("inputStock/getOneInputStock", { id: id });
};

const storeEditAction = "inputStock/setEditedEntryValue";
const storeReadGetter = "inputStock/currentEntry";
const storeEditedGetter = "inputStock/editedEntry";

const onBack = ({ entry, router, isEditMode }) => {
  if (router.history.current.name == "inputStock.risks") {
    router.go(-1);
    return;
  }
  if (router.history.current.name == "inputStock.new.edit") {
    router.push({ name: "inputStock.new" });
    return;
  }
  if (router.history.current.name == "inputStock.info") {
    router.go(-1);
    return;
  }
  if (router.history.current.name == "inputStock.edit") {
    router.push({ name: "inputStock.details", params: { inputStock_id: entry.id } });
    return;
  }
  if (isEditMode()) {
    onClose({ entry, router });
  } else goToList({ router });
};
const onEdit = ({ entry, router }) => router.push({ name: routes.edit, params: { inputStock_id: entry.id } });
const onClose = ({ entry, router }) => {
  storeCommit("inputStock/resetLastEntryDisplayed");
  router.push({ name: routes.list });
}
const goToDetail = ({ entry, router }) => router.push({ name: routes.detail, params: { inputStock_id: entry.id } });
const goToList = ({ router }) => router.push({ name: routes.list });

const commonParams = {
  storeReadGetter: storeReadGetter,
  storeEditAction: storeEditAction,
  storeEditedGetter: storeEditedGetter,
  onInit: init,
  onBack: onBack,
  onEdit: onEdit,
  onClose: onClose,
  goToList: goToList,
  goToDetail: goToDetail,
};

export const views = [
  {
    path: ":inputStock_id",
    name: "inputStock.details",
    view: inputStockDetail,
    isEditMode: () => false,
    showBackButton: () => false,
    showCloseButton: () => true,
    ...commonParams,
  },
  {
    path: ":inputStock_id/edit",
    name: "inputStock.edit",
    view: inputStockEdit,
    isEditMode: () => true,
    showBackButton: () => true,
    showCloseButton: () => false,
    ...commonParams,
  },
  {
    path: "new",
    name: "inputStock.new",
    view: inputStockNew,
    isEditMode: () => null,
    showBackButton: () => true,
    showCloseButton: () => false,
    ...commonParams,
  },
  {
    path: "new/:family",
    name: "inputStock.new.edit",
    view: inputStockNewEdit,
    isEditMode: () => true,
    showBackButton: () => true,
    showCloseButton: () => false,
    ...commonParams,
  },
  {
    path: ":product_id/risks",
    name: "inputStock.risks",
    view: inputStockProductRisks,
    isEditMode: () => null,
    showBackButton: () => true,
    showCloseButton: () => false,
    ...commonParams,
  },
  {
    path: ":product_id/info",
    name: "inputStock.info",
    view: inputStockProductInfos,
    isEditMode: () => null,
    showBackButton: () => true,
    showCloseButton: () => false,
    ...commonParams,
  },
];
