import ProductFamilyApi from "@/api/productFamily.api";

export const GET_ALL_PRODUCT_FAMILIES = "GET ALL PRODUCT FAMILIES";
export const PRODUCT_FAMILIES_LOADING = "LOAD PRODUCT FAMILIES";
export const SET_CURRENT_FAMILY = "SET CURRENT FAMILY";

export default {
  state: {
    currentFamily: "",
    productFamilies: [],
    productFamilies_loaded: true,
  },
  getters: {
    productFamilies: state => state.productFamilies,
    currentFamily: state => state.currentFamily,
  },
  mutations: {
    [GET_ALL_PRODUCT_FAMILIES]: (state, payload) => {
      state.productFamilies = payload;
    },

    [SET_CURRENT_FAMILY]: (state, payload) => {
      state.currentFamily = payload.family;
    },

    [PRODUCT_FAMILIES_LOADING]: (state, status) => {
      state.productFamilies_loaded = !status;
    },
  },
  actions: {
    async getProductFamilies({ commit }) {
      commit(PRODUCT_FAMILIES_LOADING, true);
      const response = await ProductFamilyApi.getProductFamilies();
      commit(GET_ALL_PRODUCT_FAMILIES, response.data);
      commit(PRODUCT_FAMILIES_LOADING, false);
      return response.data;
    },
  },
};
