import Gateway from "./base";

export default class MachineTypesApi {
  /**
   * Get All Machines Types
   */
  static getMachineTypes() {
    return Gateway.get("/refapi/machineTypes/");
  }

  /**
   * Get one machine by id
   * @param id
   */
  static getOneMachineType(id) {
    return Gateway.get(`/refapi/machineTypes/${id}/`);
  }

}
