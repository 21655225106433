<template>
  <!-- Button to switch between yields and harvests-->
  <div class="position-relative cursor-pointer" @click="checked = !checked">
    <b-form-checkbox
      class="overlay duotone-switch"
      v-model="checked"
      @input="onChange"
      switch>
      {{ $t("yield.button") }}
    </b-form-checkbox>
  </div>
</template>

<script>
import { config as yieldConfig } from "@/views/yield/config.js"
import { config as harvestConfig } from "@/views/harvestStorage/config.js"

export default {
  data() {
    return {
      checked: false
    }
  },
  methods: {
    onChange(checked) {
      const route = checked ? harvestConfig.routes.list.name : yieldConfig.routes.list.name;
      if(this.$route.name == route) return;
      this.$router.push({ name: route })
    }
  },
  mounted() {
    this.checked = this.$route.name.includes("harvestStorage");
  }
};
</script>

<style lang="scss" scoped>
.overlay::before {
  content: "";
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>

<style lang="scss">
.duotone-switch .custom-control-label::before {
  background: $secondary-color;
  border: 0;
  box-shadow: 0px 0px 0px 2px $color-light;
}

.duotone-switch .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $color-light;
}

.duotone-switch.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: $secondary-color;
}

.duotone-switch.custom-switch .custom-control-label::after {
  background-color: $color-light;
}
</style>