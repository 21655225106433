<template>
  <div>
    <div v-if="!readOnly">
      <CropAndDose ref="cropAndDose" />

      <br />

      <OthersElements ref="othersElements" />

      <br />

      <h4 class="subtitle-form">{{ inputFormByPtype.otherFeature_label }}</h4>
      <select class="shape-select" v-model="otherFeatureSelected">
        <option v-for="feature in inputFormByPtype.otherFeatures" v-bind:key="feature.id" :value="feature.id">{{
          feature.designation
        }}</option>
      </select>
    </div>

    <Amendement v-else-if="readOnly && this.$route.name === 'inputStock.edit'" />
  </div>
</template>

<script>
import CropAndDose from "@/components/inputstock/edit/CropAndDose.vue";
import OthersElements from "@/components/inputstock/edit/OthersElements.vue";
import Amendement from "@/components/inputstock/details/Amendement.vue";
import { mapGetters } from "vuex";

export default {
  name: "edit-amendement",
  created() {
    if (this.$route.name === "inputStock.edit") {
      this.otherFeatureSelected = this.currentInputStock.otherFeature;
    }
  },
  data() {
    return {
      otherFeatureSelected: "",
    };
  },
  components: {
    OthersElements,
    Amendement,
    CropAndDose,
  },
  methods: {
    getAmendementInfo() {
      let othersElems = !this.readOnly ? this.$refs.othersElements.getOthersElements() : [];
      let amendementInfo = !this.readOnly
        ? this.$refs.cropAndDose.getCropAndDose()
        : {
            unit: this.currentInputStock.doseUnit,
            min: this.currentInputStock.minDoseValue,
            max: this.currentInputStock.maxDoseValue,
          };

      amendementInfo["composition"] = [];
      amendementInfo["otherFeature"] = this.otherFeatureSelected;

      othersElems.map(comp => amendementInfo.composition.push(comp));

      return amendementInfo;
    },
  },
  computed: {
    ...mapGetters({
      currentInputStock: "inputStock/currentEntry",
      readOnly: "readOnly",
      currentProduct: "currentProduct",
    }),
    ...mapGetters(["currentProductType", "inputFormByPtype"]),
  },
  currentProductType: function() {
    // GET density SEMANCE
    // this.density = this.$store.dispatch("getDensity", { id: this.currentProductType });
  },
};
</script>

<style lang="scss" scoped></style>
