<template>
  <div>
    <v-select
      ref="select"
      name="select"
      class="product-select"
      :label="label"
      :options="filtered"
      :filterable="false"
      :clearable="clearable"
      :value="selected || initialValue"
      @input="onInput"
      @search="queueOnSearch"
      :required="required"
      :selectable="option => ! option.hasOwnProperty('group')"
      :placeholder="placeholder"
      :disabled="disabled"
    >
      <template #search="{attributes, events}">
        <input
          class="vs__search"
          :required="required && (!selected || Object.keys(selected).length === 0)"
          v-bind="attributes"
          v-on="events"
        />
      </template>
      <template slot="option" slot-scope="option">
        <span :class="{warning: option['notAuthorized']}">{{ option[label] }}</span>
      </template>
      <template #no-options>{{ $t("dropdown.no_option") }}</template>
    </v-select>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  data: () => ({
    selected: {},
    initialValue: {},
    query: "",
    initialLimit: 20,
    limit: 0,
    ul: null,
  }),
  mounted() {
    this.limit = this.initialLimit;
    this.ul = this.$refs.select.$el.querySelector("ul");
    this.ul.addEventListener("scroll", this.onScroll);

    this.queueOnSearch = _.debounce(this.onSearch, 500);

    this.initialValue = this.value;
    this.selected = this.value;

    if(this.clearOnLoad) {
      this.clear()
    }
  },
  computed: {
    filtered() {
      return this.options.filter(e => e && e[this.label].toLowerCase().includes(this.query));
    },
    paginated() {
      return this.filtered;
    },
    hasNextPage() {
      return this.paginated.length < this.filtered.length;
    },
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    clearSelection: {
      type: Boolean,
      default: false,
    },
    clearOnLoad: {
      type: Boolean,
      default: false
    },
    clearOnSelect: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    queueOnSearch() {},
    clear() {
      this.selected = null;
    },
    setValue(value) {
      this.selected = value;
    },
    onSearch(query) {
      query = query.toLowerCase();

      this.query = query;
      this.$emit("search", query);
    },
    onInput(choice) {
      this.$emit("input", choice);
      this.selected = choice;
      if(this.clearSelection == true) this.selected = null;
      if(this.clearOnSelect) this.clear()
    },
    async onScroll() {
      let scrollTop = this.ul.scrollTop;
      if (this.ul.scrollTop + this.ul.offsetHeight > this.ul.scrollHeight) {
        this.limit += 10;
        await this.$nextTick();
        this.ul.scrollTop = scrollTop;
      }
    },
  },
  watch: {
    value(newValue, oldValue) {
      this.initialValue = newValue;
      this.selected = newValue;
    },
    selected(newValue, oldValue) {
      if (!newValue) this.initialValue = null;

      if (this.options.length == 1) this.$emit("input", newValue);
    },
  },
};
</script>

<style lang="scss">
.vs__selected {
  word-break: keep-all;
  white-space: nowrap;

  max-width: 60px;
}

.vs__selected-options {
  overflow: hidden;
}

.vs__dropdown-toggle {
  height: calc(1.5em + 0.5rem + 2px);
  // padding: 0.25rem 0.5rem;
  padding: 0 0 4px;
  font-size: 0.9rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.vs__dropdown-option {
  font-size: 0.9rem;
  padding: 2px 5px !important;
  white-space: normal !important;
  border-bottom: 1px solid #ededed;
}

.vs__dropdown-option--disabled {
  background: #ededed !important;
  color: #2d2d2d;
  font-weight: bold;
  padding: 4px 5px !important;
}

.vs--disabled .vs__selected {
  color: $dark-grey;
  font-style: italic;
}

.vs--disabled input {
  opacity: 0;
}


.vs-inline .v-select,
.vs-inline .vs__dropdown-toggle,
.vs-inline .vs__search:focus {
  width: 100% !important;
}

::placeholder {
  color: $dark-grey !important;
}

.large {
  .vs__selected {
    max-width: 100%;
  }
  .vs__dropdown-toggle {
    font-size: 1rem !important;
  }

  .vs__dropdown-option {
    font-size: 1rem;
  }
}

.warning {
  color: $danger-color;
}
</style>