<template>
    <div>
      <Treatment
        :endpoint="CropfieldApi.reportCropRotation"
        :async="false"
        :title="$t('cropfields.reports.crop_rotation_report')"
        :startButton="$t('commons.exports_reports.export_button')"
        :finishButton="$t('commons.exports_reports.finish')"
        :exportAllowedFormats="['html', 'pdf']"
        :isDirectDownload="true"
        :onBack="onBack"
        :onClose="onClose"
      >
        <TC_CropField />
        <TC_Farm_NoTemplate />
        <TC_Campaign />
        <TC_FileFormat />
      </Treatment>
    </div>
  </template>

  <script>
  import Treatment from "@/components/commons/treatments//TreatmentExecution";
  import TC_CropField from "@/components/commons/treatments/TC_CropField_NoTemplate";
  import TC_Campaign from "@/components/commons/treatments/TC_Campaign_NoTemplate";
  import TC_Farm_NoTemplate from "@/components/commons/treatments/TC_Farm_NoTemplate";
  import TC_FileFormat from "@/components/commons/treatments/TC_FileFormat";

  import CropfieldApi from "@/api/cropfield.api";

  export default {
    data() {
      return {
        CropfieldApi: CropfieldApi,
        groupOptions:[
          { value: "exploitation", text: "Exploitation" },
        ],
        returnedOption: 'option',
        titleOption: "commons.exports_reports.which_grouping"
      };
    },
    methods: {
      onBack() {
        this.$router.push({ name: this.isOnMap ? "cropfield.map.reports" : "cropfield.reports" });
      },
      onClose() {
        this.$router.push({ name: this.isOnMap ? "cropfield.map.reports" : "cropfield.reports" });
      },
    },
    components: { Treatment, TC_CropField, TC_Farm_NoTemplate, TC_FileFormat, TC_Campaign },
    computed: {
      isOnMap() {
        return this.$route.name.includes("map");
      },
    }
  };
  </script>
