import { commonMutations } from "./commons.js";

import FertilizationPlanInputsApi from "@/api/fertilizationPlanInputs.api";
import Vue from "vue";

export const ALL_INTERVENTIONS = "GET ALL INTERVENTIONS";
export const ONE_INTERVENTION = "GET INTERVENTION BY ID";
export const INTERVENTIONS_BY_CROPFIELD = "GET INTERVENTION BY CROPFIELD";
export const INIT_NEW_INTERVENTION = "INIT NEW INTERVENTION";
export const UPDATE_INTERVENTION = "UPDATE INTERVENTION";
export const INTERVENTION_LOADING = "LOAD INTERVENTION";
export const INTERVENTIONS_LOADING = "LOAD INTERVENTIONS";
export const CLEAN_INTERVENTION = "CLEAN INTERVENTION";
export const DELETE_ONE_INTERVENTION = "DELETE ONE INTERVENTION";

export default {
  state: {
    interventions: [],
    currentIntervention: {},
    intervention_loaded: true,
    interventions_loaded: true,
    currentEntry: {},
    newIntervention: {},
    interventionUpdated: {},
    indexIntervention: {}
  },
  getters: {
    currentIntervention: state => state.currentIntervention,
    interventions: state => state.interventions,
    intervention: state => state.intervention,
    interventionLoaded: state => state.intervention_loaded,
    interventionsLoaded: state => state.interventions_loaded,
    newIntervention: state => state.newIntervention,
  },
  mutations: {
    ...commonMutations(),
    [ALL_INTERVENTIONS]: (state, interventions) => {
      state.interventions = interventions;
    },

    [INTERVENTIONS_BY_CROPFIELD]: (state, interventions) => {
      state.interventions = interventions;
    },

    [ONE_INTERVENTION]: (state, payload) => {
      const fieldIndex = state.interventions.findIndex(element => element.id == payload.id );
      state.currentIntervention = state.interventions[fieldIndex];
    },

    [INTERVENTION_LOADING]: (state, status) => {
      state.intervention_loaded = !status;
    },

    [INTERVENTIONS_LOADING]: (state, status) => {
      state.interventions_loaded = !status;
    },
    [INIT_NEW_INTERVENTION]: state => {
      state.interventions.push({
        id: null,
        date: "",
        quantity: null,
        input: null,
        fertilizationPlan: null,
        inputsStock_id: null,
        doseUnit: null
      });
    },

    [UPDATE_INTERVENTION]: (state, payload) => {
      state.interventionLoaded = payload.intervention;
    },

    [DELETE_ONE_INTERVENTION]: (state, payload) => {
      state.interventions.splice(payload.index, 1);
    },

    updateIntervention: (state, { field, index, intervention }) => {
      Vue.set(state.interventions[index], field, intervention)
    },
  },
  actions: {
    async getAllInterventions({ commit }) {
      commit(INTERVENTION_LOADING, true);
      const response = await FertilizationPlanInputsApi.getInterventions();
      commit(ALL_INTERVENTIONS, response.data);
      commit(INTERVENTION_LOADING, false);
      return response.data;
    },
    async getInterventionByCropfield({ commit }, payload) {
      commit(INTERVENTION_LOADING, true);
      const response = await FertilizationPlanInputsApi.getInterventionByCropfield(payload);
      commit(INTERVENTIONS_BY_CROPFIELD, response.data);
      commit(INTERVENTION_LOADING, false);
      return response.data;
    },
    async getOneIntervention({ commit }, payload) {
      commit(INTERVENTION_LOADING, true);
      commit(ONE_INTERVENTION, payload);
      commit(INTERVENTION_LOADING, false);
    },
    async deleteIntervention({ commit }, { id }) {
      commit(INTERVENTION_LOADING, true);
      const response = await FertilizationPlanInputsApi.deleteIntervention(id);
      commit(INTERVENTION_LOADING, false);
      return response;
    },
    async createIntervention({ commit }, payload) {
      commit(INTERVENTION_LOADING, true);
      const response = await FertilizationPlanInputsApi.createIntervention(payload.data);
      commit(INTERVENTION_LOADING, false);
      return response;
    },
    async updateIntervention({ commit, state }, payload) {
      commit(INTERVENTION_LOADING, true);
      const response = await FertilizationPlanInputsApi.updateIntervention(state.interventions[payload.index], state.interventions[payload.index].id);
      commit(INTERVENTION_LOADING, false);
      return response.data;
    },
  },
};
