<template>
  <b-container class="m-0 p-0" v-if="composition.length">
    <h4 class="subtitle-form pt-3 pb-1">{{ $t("inputStock.details.product_composition") }}</h4>
    <b-row v-for="(compound, index) in composition" :key="index" class="mb-2">
      <b-col cols="4">
        <h4 class="subtitle-form">{{ compound.compound_symbol || compound.compound }}</h4>
      </b-col>
      <b-col cols="8" class="d-flex align-items-start">
        <div class="bg-text-color text-infos config-density-semence">
          {{ compound.dose || 0 }}
          {{ compound.unit_unit || compound.unit || "N/A" }}
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// import ActivityModule from "@/store/modules/activity";
import { mapGetters } from "vuex";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import RefProductMixin from "@/mixins/RefProductMixin";

export default {
  mixins: [DetailUpdateMixin, RefProductMixin],
  async mounted() {
    await Promise.all([
      this.$store.dispatch("getinputFormByProductType", {
        id: this.currentEditedEntry.productType || this.currentEntry.productType,
      }),
    ]);
  },
  methods: {

  },
  computed: {
    ...mapGetters(["inputFormByPtype"]),
     ...mapGetters({
      inputsStock: "inputStock/entries",
      currentEntry: "inputStock/currentEntry",
      currentEditedEntry: "inputStock/editedEntry",
    }),
    composition() {
      return (
        (this.useRefProduct ? this.currentProduct && this.currentProduct.composition : this.currentEntry.composition) ||
        []
      );
    },
  },
};
</script>
