import { storeDispatch, storeCommit, storeGet } from "@/views/common/details/commons";
import { EventBus } from "@/eventBus.js";

import {
  activityDetail,
  activityEdit,
  activityNew,
  activityNewEdit,
//inputStockNewEdit,
//inputStockProductRisks,
//inputStockProductInfos,
} from "./views";

const routes = {
  detail: "activity.details",
  edit: "activity.edit",
  list: "activity.list",
};

const init = async ({ route }) => {
  let lastEntryDisplayed = storeGet("activity/lastEntryDisplayed")
  let current_id = storeGet("activity/currentId");
  let id = route.params.activity_id;
  let entriesChecked = storeGet("activity/entriesChecked")
  storeCommit("activity/resetEditedEntry");
  storeCommit("activity/resetSelectedCropfieldId");

  if (entriesChecked.length > 1) {
    for (const entry of entriesChecked) {
      await storeDispatch("activity/getOneActivity", { id: entry });
    }
  }
  else if (id && ( id != current_id || id == lastEntryDisplayed && route.params.fertilizationPlan != undefined )) {
      await storeDispatch("activity/getOneActivity", { id: id });
  }
};

const storeEditAction = "activity/setEditedEntryValue";
const storeReadGetter = "activity/currentEntry";
const storeEditedGetter = "activity/editedEntry";

const onBack = ({ entry, router, isEditMode }) => {
  if (isEditMode()) {
    onClose({ entry, router });
  } else goToList({ router });
};
const onEdit = ({ entry, router }) => {
  // Si l'activité est déjà exportée, on affiche une modal d'avertissement
  // sinon on va directement en mode édition
  if(entry.exported) {
    EventBus.$emit("showExportedActivityWarningModal");
  }else{
    router.push({ name: routes.edit, params: { activity_id: entry.id } });
  }
}
const onClose = ({ entry, router }) => {
  storeCommit("activity/resetLastEntryDisplayed");
  router.push({ name: routes.list });
}
const goToDetail = ({ entry, router }) => router.push({ name: routes.detail, params: { activity_id: entry.id } });
const goToList = ({ router }) => router.push({ name: routes.list });

// Référence du texte à afficher lorsqu'on survole le crayon de modification
// cas d'une activité déja exportée dans smag farmer
// const onHoverEditButton = ({ entry }) => entry.exported ? "activities.details.is_already_exported_smag" : "";

const commonParams = {
  storeReadGetter: storeReadGetter,
  storeEditAction: storeEditAction,
  storeEditedGetter: storeEditedGetter,
  onInit: init,
  onBack: onBack,
  onEdit: onEdit,
  onClose: onClose,
  goToList: goToList,
  goToDetail: goToDetail,
  // onHoverEditButton: onHoverEditButton
};

export const views = [
  {
    path: ":activity_id",
    name: "activity.details",
    view: activityDetail,
    isEditMode: () => false,
    showBackButton: () => false,
    showCloseButton: () => true,
    ...commonParams,
  },
  {
    path: ":activity_id/edit",
    name: "activity.edit",
    view: activityEdit,
    isEditMode: () => true,
    showBackButton: () => true,
    showCloseButton: () => false,
    ...commonParams,
  },
  {
    path: "new",
    name: "activity.new",
    view: activityNewEdit,
    isNewMode: () => true,
    isEditMode: () => null,
    showBackButton: () => true,
    ...commonParams,
  },
  {
    path: "new/:groupCode",
    name: "activity.new.edit",
    view: activityNew,
    isNewMode: () => true,
    isEditMode: () => null,
    showBackButton: () => true,
    ...commonParams,
  },
//   {
//     path: "new/:family",
//     name: "inputStock.new.edit",
//     view: inputStockNewEdit,
//     isEditMode: () => true,
//     ...commonParams,
//   },
//   {
//     path: ":inputStock_id/risks",
//     name: "inputStock.risks",
//     view: inputStockProductRisks,
//     isEditMode: () => null,
//     ...commonParams,
//   },
//   {
//     path: ":product_id/info",
//     name: "inputStock.info",
//     view: inputStockProductInfos,
//     isEditMode: () => null,
//     ...commonParams,
//   },
];
