<template>
  <div>
    <Treatment
      taskName="export_activities"
      :async="true"
      :title="$t('activities.export.isoxml.title')"
      :startButton="$t('commons.exports_reports.export_button')"
      :downloadButton="$t('commons.exports_reports.download_export')"
      :finishButton="$t('commons.exports_reports.finish')"
      :onBack="onBack"
      :onClose="onClose"
    >
      <TC_Campaign />
      <TC_Farm />
      <!-- 
        for component TC_ActivityChoice; List of options to display.
        - 'newOnly' means "only the new activities". It will send 'newOnly = true' to the API
        - 'onPeriod' means "all activities on given period". In that case it will send a startDate and endDate to the API
        - 'all' means "all activities". It will send 'all = true' to the API
      -->
      <TC_ActivityChoice :activityOptions="['newOnly', 'onPeriod']" />
      <TC_ClientId_NoTemplate />
    </Treatment>
  </div>
</template>

<script>
import Treatment from "@/components/commons/treatments/TreatmentExecution";
import TC_Campaign from "@/components/commons/treatments/TC_Campaign";
import TC_Farm from "@/components/commons/treatments/TC_Farm";
import TC_ActivityChoice from "@/components/commons/treatments/TC_ActivityChoice";
import TC_ClientId_NoTemplate from "@/components/commons/treatments/TC_ClientId_NoTemplate";

export default {
  methods: {
    onBack() {
      this.$router.push({ name: "activity.export" });
    },
    onClose() {
      this.$router.push({ name: "activity.export" });
    },
  },
  components: { Treatment, TC_Campaign, TC_Farm, TC_ActivityChoice, TC_ClientId_NoTemplate },
};
</script>
