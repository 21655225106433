<template>
  <button class="btn align-bottom" @click="onClick">
    <img :src="icon" alt="icon close action" />
    {{ text }}
  </button>
</template>

<script>
import closeIcon from "@/assets/images/close.svg";

export default {
  name: "CloseButton",
  data() {
    return {
      icon: closeIcon,
    };
  },
  props: {
    text: {
      type: String,
      require: true,
    },
    onClick: {
      type: Function,
      require: true,
    },
  },
};
</script>
<style lang="scss" scoped>
button {
  background-color: $background-text-color;
  font-size: 0.8em;
  font-weight: bold;

  img {
    width: 0.8em;
  }
}

button:hover {
  background: $secondary-light-color;
}

button.close-button-grey {
  border-radius: 0;
  background-color: $secondary-light-grey;
}

button.close-button-grey:hover {
  background-color: $grey;
}
</style>
