import { i18n } from "@/i18n.js";
import { dateToSlashString } from "@/utils/commons";

export const config = {
	title: i18n.t("harvestStorage.title"),
	// le nom marqué dans le comptage des groupe : XX intrants
	countingElements: i18n.t("yield.list.countingElements"),
	// "Ajouter un rendement"
	addButton: i18n.t("yield.list.add"),
	// le module du store
	module: "yield",
	scrollToSelection: true,
	// sur quel champs faire la recherche
	searchField: "product_name",
	// les principales routes
	routes: {
		index: {
			name: "yield",
		},
		list: {
			name: "yield.list",
		},
		new: {
			name: "yield.new",
		},
		details: {
			name: "yield.details",
		},
		params: {
			id: "yield_id",
		},
	},
	// sur quelle colonne groupe-t-on par défaut ?
	defaultPivot: 1,
	// la liste des colonnes
	columns: {
		0: {
			name: "cropfield_name",
			width: 12,
			sort: true,
			title: i18n.t("harvestStorage.list.cropField"),
			hideOnDetails: false,
			pivot: {
				enable: true,
				sortHeaderField: [(group) => group.name.toLowerCase()],
				sortHeaderOrder: ["asc"],
				sortDataFields: [(theYield) => theYield.variety],
				sortDataOrders: ["desc"],
				groupDisplay: {},
				displayHeader: true,
			},
			show: true,
			priority: 1,
		},
		1: {
			name: "crop_name",
			width: 9,
			sort: true,
			title: i18n.t("harvestStorage.list.crop"),
			hideOnDetails: false,
			pivot: {
				enable: true,
				sortHeaderField: [(group) => group.name.toLowerCase()],
				sortHeaderOrder: ["asc"],
				sortDataFields: [(theYield) => theYield.variety],
				sortDataOrders: ["desc"],
				groupDisplay: {},
				displayHeader: true,
			},
			show: true,
			priority: 1,
		},
		2: {
			name: "variety_name",
			width: 8,
			sort: true,
			title: i18n.t("yield.list.product"),
			hideOnDetails: false,
			pivot: {
				enable: true,
				sortHeaderField: [(group) => group.name.toLowerCase()],
				sortHeaderOrder: ["asc"],
				sortDataFields: [(theYield) => theYield.variety],
				sortDataOrders: ["desc"],
				groupDisplay: {},
				displayHeader: true,
			},
			show: true,
			priority: 1,
		},
		3: {
			name: "theYield",
			width: 9,
			sort: false,
			title: i18n.t("yield.list.yield"),
			hideOnDetails: false,
			cellComponent: "TheYieldCell",
			// les infos sur le groupe
			pivot: {
				enable: false,
			},
			show: true,
			priority: 2,
		},
		4: {
			name: "surface",
			width: 8,
			sort: false,
			title: i18n.t("yield.list.harvested_surface"),
			hideOnDetails: false,
			pivot: { enable: false},
			show: true,
			priority: 3,
		},
		5: {
			name: "harvestDate",
			width: 8,
			sort: true,
			title: i18n.t("yield.list.harvest_date"),
			isDate: true,
			hideOnDetails: false,
			cellDisplay: {
				mode: "function",
				method: (value) => {
					if (!value) return i18n.t("is_empty_feminine");

					return dateToSlashString(new Date(value));
				},
			},
			pivot: {
				enable: true,
				groupDisplay: {},
				displayHeader: true,
				sortHeaderField: [(group) => group.rows.length > 0 && group.rows[0].harvestDate],
				sortHeaderOrder: ["desc"],
				sortDataFields: [(theYield) => theYield.variety],
				sortDataOrders: ["desc"],
			},
			show: true,
			priority: 4,
		},
	},
	// les filtres
	filters: [
		{
			name: "cropField",
			title: i18n.t("commons.filter.cropfield"),
			show: true,
		},
		{
			name: "variety",
			title: i18n.t("commons.filter.product"),
			show: true,
		},
	],
};
