<template>
  <!-- Boîte contenant les informations sur une activité ou un intrant -->
  <b-modal ref="newsDetail" header-bg-variant="success" centered>
    <template v-slot:modal-header>
      <div>
        <b-form-row>
          <b-col class="px-1 news-title">
            {{ news.length == 1 ? news[0].title : $t('components.NewsModal.title_multiple') }}
          </b-col>
        </b-form-row>

      </div>
    </template>
    <b-row align-v="center">
      <b-col cols="3" class="px-4 py-2 my-1 d-flex justify-content-center">
        <!-- case when we display only one news -->
        <div v-if="news.length == 1">
          <img v-if="news[0].criticity === 'A' " :src="alertModal" width="100%">
          <img v-if="news[0].criticity === 'W' " :src="infoModal" width="100%">
        </div>
        <!-- case when we display multiple news -->
        <div v-else>
          <!-- if at least one alert, show alert icon, otherwise show info icon -->
          <img v-if="news && news.find(n => n.criticity == 'A')" :src="alertModal" width="100%">
          <img v-else :src="infoModal" width="100%">
        </div>
      </b-col>
      <b-col cols="9" class="py-2">
        <!-- case when we display only one news -->
        <div v-if="news.length == 1">
          <div
            v-for="(line, index) in news[0].body.split('\n')"
            :key="index"
          >
              {{ line }}
          </div>
          <div v-if="news[0].details !== null">
            <div
              v-for="(line, index) in news[0].details.split('\n')"
              :key="index"
            >
              {{ line }}
            </div>
          </div>
        </div>

        <!-- case when we display multiple news -->
        <div v-else>
          <div v-for="(item, i) in news" :key="i">
            <h4 class="mt-0 mb-1">{{ news[i].title }}</h4>
              <div
                v-for="(line, index) in news[i].body.split('\n')"
                :key="index"
              >
                  {{ line }}
              </div>
            <hr>
          </div>
        </div>
      </b-col>
    </b-row>
    <template v-slot:modal-footer>
      <div class="w-100 d-flex justify-content-end align-items-center">
        <!-- Bouton de redirection vers l'activité -->
        <router-link
          v-if="showRouterButton && news.length == 1 && news[0].activity"
          class="btn btn-success mr-2"
          :to="{ name: 'activity.details', params: { activity_id: news[0].activity } }"
          >{{ $t('home.notifications.go_activity') }}</router-link
        >
        <!-- Bouton de redirection vers l'intrant -->
        <router-link
          v-if="showRouterButton && news.length == 1 && news[0].inputsStock"
          class="btn btn-success mr-2"
          :to="{ name: 'inputStock.details', params: { inputStock_id: news[0].inputsStock } }"
          >{{ $t('home.notifications.go_inputstock') }}</router-link
        >
        <b-button
          v-if="showModifyButton"
          class="btn btn-success mr-2"
          @click="hideModal()"
          >{{ $t('components.NewsModal.modify') }}</b-button
        >
        <b-button
          class="mr-2"
          v-if="showRouterButton && news.length == 1 && news[0].criticity === 'W'"
          @click="markAsClosed"
        >
          {{ $t('home.notifications.do_not_display') }}
        </b-button>

        <b-button
          class="btn btn-primary"
          @click="() => {goToDetail(); showGenerateActivitiesModal()}"
          >{{ $t('components.NewsModal.ok') }}</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { EventBus } from "@/eventBus.js";
import { mapGetters } from 'vuex';

import alertModal from "@/assets/images/alert_modal.svg";
import infoModal from "@/assets/images/warning_modal.svg";
import alertPanel from "@/assets/images/alert_panel.svg";
import infoPanel from "@/assets/images/warning_panel.svg";


export default {
  data() {
    return {
      news: [],
      goToDetail: () => {},
      openGenerateActivitiesModal: () => {},
      alertModal: alertModal,
      infoModal: infoModal,
      alertPanel: alertPanel,
      infoPanel: infoPanel,
      showModifyButton: true,
      showRouterButton: false,
    };
  },
  computed: {
		...mapGetters({
      currentTour: 'tour/currentEntry',
    }),
	},
  created() {
    EventBus.$on("showNewsModal", this.showModal);
    EventBus.$on("hideModal", this.hideModal);
  },
  beforeDestroy(){
    EventBus.$off("showNewsModal", this.showModal);
    EventBus.$off("hideModal", this.hideModal);
  },
  methods: {
    /**
     * @description Affiche la boîte contenant les informations sur une activité ou un intrant
     * news: an array with all the news (even if it's just one)
     * goToDetail: the function use to redirect to entry detail page
     */
    showModal({ news, goToDetail, openGenerateActivitiesModal = () => {}, showModifyButton = true, showRouterButton = false }) {
      this.news = news;
      this.goToDetail = goToDetail;
      this.openGenerateActivitiesModal = openGenerateActivitiesModal;
      this.showModifyButton = showModifyButton;
      this.showRouterButton = showRouterButton;
      if (this.$refs.newsDetail.isHidden)
        this.$refs.newsDetail.show();

    },
    /**
     * @description Masque la boîte contenant les informations sur une activité ou un intrant\agrinity-backend\django-root\-\merge_requests
     */
    hideModal() {
      this.news = []

      this.$refs.newsDetail.hide();
    },

    /**
     * Marque une news comme étant close
     */
    markAsClosed() {
      this.$emit("markAsClosed");
    },

    /**
     * Affiche la modal permettant de générer des activities à partir d'une tournée
     */
    showGenerateActivitiesModal() {
      if(this.$route.name.includes("tour")) {
        this.openGenerateActivitiesModal(this.currentTour);
      }
    },
  }
}
</script>
<style lang="scss" scoped>
  .news-title {
    font-weight: bold;
  }
</style>