<template>
  <b-form-input size="sm" v-model="value" type="number" step="any" @change="$emit('updateRow', rowId, payload)"
    :required="required"></b-form-input>
</template>

<script>
import { EventBus } from "@/eventBus.js";
import { mapGetters } from "vuex";

export default {
  props: ["rowId", "field", "cellData", "required"],
  data() {
    return {
      value: null,
      modalShown: false,
      modalOpion: false,
    };
  },
  created() {
    this.value = this.cellData;
    EventBus.$on("updateSurfaceValue", this.updateValue)
    EventBus.$on("modalOption", this.handleModal)
  },
  computed: {
    payload() {
      let payload = {};
      payload[this.field] = this.value ? this.value : null;

      // Gérer le cas spécial où surface et declaredSurface sont tous deux à 0
      if (this.currentEntry.surface == 0 && this.currentEntry.declaredSurface == 0) {
        if (this.field == "surface") {
          EventBus.$emit("updateSurfaceValue", { name: "declaredSurface", value: this.value });
        } else if (this.field == "declaredSurface") {
          EventBus.$emit("updateSurfaceValue", { name: "surface", value: this.value });
        }
      }

      // Gérer le cas où surface et declaredSurface sont égaux
      if (this.field == "surface" &&
        (this.currentEntry.surface == this.currentEntry.declaredSurface) &&
        (this.currentEntry.surface != 0 && this.currentEntry.declaredSurface != 0)
      ) {
        if (!this.modalShown) {
          // Afficher la modal et demander confirmation
          let alert = "La surface déclarée est-elle identique à la surface cultivée ?"
          EventBus.$emit("showAlertModal", {
            message: alert,
            okBtn: () => this.handleOk({ name: "declaredSurface", value: this.value }),
            cancelBtn: () => this.handleCancel(),
            okTitle: "yes",
            cancelTitle: "non",
          });
          this.modalShown = true; // Met à jour la variable pour indiquer que la modal a été montrée
        } else if (this.modalShown && this.modalOpion) {
          // Mise à jour automatique sans afficher la modal
          EventBus.$emit("updateSurfaceValue", { name: "declaredSurface", value: this.value });
          this.modalOpion = true
          this.modalShown = true;
        }
      }

      return payload;
    },
    ...mapGetters({
      editedEntry: "cropfield/editedEntry",
      currentEntry: "cropfield/currentEntry",
    }),
  },
  watch: {
    cellData() {
      this.value = this.cellData;
    },
  },
  methods: {
    updateValue({ name, value }) {
      if (this.field == name) {
        //mis a jour la valeur sur l'affichage
        this.value = value
        //mis a jour dans le store 
        let payload = {};
        payload[name] = this.value
        this.$emit('updateRow', this.rowId, payload)
      }
    },
    handleModal({ copy }) {
      if (copy) {
        this.modalOpion = true
      }
    },
    handleOk({ name, value }) {
      EventBus.$emit("updateSurfaceValue", { name: name, value: value });
      EventBus.$emit("modalOption", { copy: true })
    },
    handleCancel() {
      EventBus.$emit("modalOption", { copy: false })
    },
  },
};
</script>

<style lang="scss"></style>
