<template>
  <!-- Treatment Component : TextField -->
  <!-- Displays a text field -->
  <div class="mt-2">
    <h4 class="subtitle-form mb-2">{{ $t(this.fieldTitle) }}</h4>
		<b-row>
			<b-col>
				<b-input type="text" v-model="value" size="sm" required></b-input>
			</b-col>
		</b-row>
  </div>
</template>

<script>
import TreatmentFormPartMixin from "@/components/commons/treatments/TreatmentFormPartMixin";
import { TreatmentFormPart } from "@/models/treatments/Treatment.js";
import { cookieIsDefined } from "@/utils/commons";

export default {
  mixins: [TreatmentFormPartMixin],
  data() {
    return {
      value: null,
    };
  },
  mounted() {
		// Stockage dans un cookie pour le reproposer à l'utilisateur
		if (this.storeCookie && cookieIsDefined(this.fieldName)) {
      this.value = this.$cookies.get(this.fieldName);
    } else {
			if (this.defaultValue != "") this.value = this.defaultValue;
		}
  },
  methods: {
    validateForm() {
			// Le champ est obligatoire
			if (!this.value) {
				this.isValid = false;
				return
			}
      this.isValid = true;
			if (this.storeCookie) {
				this.$cookies.set(this.fieldName, this.value);
			}
      return new TreatmentFormPart(this.fieldName, this.value);
    },
  },
  props: {
    fieldTitle: {
      type: String,
      default: "",
    },
		defaultValue: {
			type: String,
      default: "",
		},
		fieldName: {
			type: String,
			default: ""
		},
		storeCookie: {
			type: Boolean,
			default: false
		},
  },
};
</script>

<style></style>
