import Gateway from "./base";

export default class AgroEdiApi {

  /**
   * Get all potential receivers for an agro edi export
   */
  static getAgroEdiReceivers() {
    return Gateway.get(`/agroedi/receivers/`);
  }

}
