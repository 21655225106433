<template>
  <div
		:class="plan_class"
		class="ml-5"
		v-b-tooltip.hover
			:title="$t(plan_title)">
  </div>
</template>

<script>

export default {
  props: ["rowId", "rowData"],
  computed: {
	plan_class() {
		if(this.rowData.status == 3)
			return 'circle-green'
		else if (this.rowData.status == 2)
			return 'circle-yellow'
		else if (this.rowData.status == 1)
			return 'circle-orange'
		else
			return 'circle-red'
	},
	plan_title() {
		if(this.rowData.status == 3)
			return 'fertilization_plan.list.complete'
		else if (this.rowData.status == 2)
			return 'fertilization_plan.list.calculated'
		else if (this.rowData.status == 1)
			return 'fertilization_plan.list.initialized'
		else
			return 'fertilization_plan.list.not_initialized'
	}

  },
};
</script>

<style lang="scss" scoped>
.circle-red {
	width: 30px;
	height: 30px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	background: #DC0D0D;
}

.circle-green {
	width: 30px;
	height: 30px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	background: #13670E;
}

.circle-orange {
	width: 30px;
	height: 30px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	background: #F68A09;
}

.circle-yellow {
	width: 30px;
	height: 30px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	background: #FAEF5D;
}
</style>
