<template>
	<!-- Ce composant permet d'afficher une image sur laquelle on peut cliquer pour l'afficher dans une visionneuse -->
  <div>
    <img
      ref="image"
      @click="openViewer"
      :src="imageSrc"
      class="thumbnail"
      alt="Photo"
    />
  </div>
</template>

<script>
import Viewer from 'viewerjs';
import { EventBus } from "@/eventBus.js";
import 'viewerjs/dist/viewer.css';

export default {
  mounted() {
    EventBus.$on("view_note_image", this.openViewerFromEmit)
  },
  beforeDestroy() {
    if (this.viewer) {
      this.viewer.destroy();
      this.viewer = null;
    }
    EventBus.$off("view_note_image", this.openViewerFromEmit)
  },
  data() {
    return {
      viewer: null,
    };
  },
  props: {
    imageSrc: {
      type: String,
      required: true,
    },
  },
  methods: {
		openViewer() {
      if (!this.viewer) {
        this.viewer = new Viewer(this.$refs.image, {
          toolbar: {
            rotateLeft: true,
            rotateRight: true,
          },
        });
      }
      this.viewer.show();
    },

    openViewerFromEmit(imageFile) {
      if (imageFile === this.imageSrc) {
        this.openViewer();
      }
    }
  },
};
</script>

<style lang="scss" scoped>

.thumbnail {
  cursor: pointer;
  max-width: 100px;
  transition: transform 0.2s;
}
</style>
