import { commonMutations } from "./commons.js";

import InputsApi from "@/api/inputs.api";
import Vue from "vue";

export const INPUTS_BY_CROPFIELD = "INPUTS BY CROPFIELD";
export const INPUTS_LOADING = "LOAD INPUTS";
export const GET_ACTIVITIES_BY_INPUTSTOCK = "GET ACTIVITIES BY INPUTSTOCK";

export default {
  state: {
    inputsByCropfield: [],
    inputsLoaded: true,
    sumProvidedN: 0,
    activitiesByInputsStock: [],
    lastInputsStockId: null,
  },
  getters: {
    inputsByCropfield: state => state.inputsByCropfield,
    inputsLoaded: state => state.inputsLoaded,
    sumProvidedN: state => state.sumProvidedN,
    activitiesByInputsStock: (state) => state.activitiesByInputsStock,
    lastInputsStockId: (state) => state.lastInputsStockId,
  },
  mutations: {
    ...commonMutations(),
    [INPUTS_LOADING]: (state, status) => {
        state.inputsLoaded = !status;
    },

    updateNitrogenProvided: (state, value )=> {
        state.sumProvidedN = value;
    },

    [GET_ACTIVITIES_BY_INPUTSTOCK]: (state, activities) => {
      state.activitiesByInputsStock = activities;
    },

    _setLastInputsStockId: (state, id) => {
      state.lastInputsStockId = id;
    },

    resetLastInputsStockId: (state) => {
      state.lastInputsStockId = null;
    },

  },
  actions: {
    async getActivitiesByInputsStock({ commit, state, rootGetters }, {idInputsStock}) {
      commit(INPUTS_LOADING, true);
      if(state.lastInputsStockId == idInputsStock)
        return;

      let params = {inputsStock: idInputsStock, campaign: rootGetters.getCampaign.id}

      let response = await InputsApi.getActivitiesByInputsStock(params);

      commit("_setLastInputsStockId", idInputsStock);
      commit(GET_ACTIVITIES_BY_INPUTSTOCK, response.data);
      commit(INPUTS_LOADING, false);
      return response.data;
    },
  },
};
