import Gateway from "./base";

export default class AnalysisApi {
	/**
	 * Provides the data to supply the IFT graphs
	 */
	static getIFTChartData(params) {
		return Gateway.get(`/api/cropfields/get_data_for_visualization/`, params);
	}

	/**
   * Provides the data to supply the elapsed time graphs
   */
	 static getElapsedTimeChartData(params) {
    return Gateway.get(`/api/activities/get_data_for_visualization/`, params);
  }
}