<template>
    <div>
      <Treatment
        :endpoint="CropfieldApi.reportIFT"
        :async="false"
        :title="$t('cropfields.reports.ift')"
        :startButton="$t('commons.exports_reports.export_button')"
        :finishButton="$t('commons.exports_reports.finish')"
        :exportAllowedFormats="['pdf']"
        :isDirectDownload="true"
        :onBack="onBack"
        :onClose="onClose"
      >
        <TC_CropField />
        <TC_Farm v-if="((!entriesChecked || entriesChecked.length == 0) && (!filterSelectionLabels || filterSelectionLabels.length == 0))" />
        <TC_Campaign />
      </Treatment>
    </div>
  </template>

  <script>
  import Treatment from "@/components/commons/treatments//TreatmentExecution";
  import TC_CropField from "@/components/commons/treatments/TC_CropField_NoTemplate";
  import TC_Campaign from "@/components/commons/treatments/TC_Campaign_NoTemplate";
  import TC_Farm from "@/components/commons/treatments/TC_Farm";
  import TC_CheckBox from "@/components/commons/treatments/TC_CheckBox";

  import CropfieldApi from "@/api/cropfield.api";

  import { mapGetters } from "vuex";

  export default {
    data() {
      return {
        CropfieldApi: CropfieldApi,
        groupOptions:[
          { value: "exploitation", text: "Exploitation" },
        ],
        returnedOption: 'option',
        titleOption: "commons.exports_reports.which_grouping"
      };
    },
    methods: {
      onBack() {
        this.$router.push({ name: this.isOnMap ? "cropfield.map.reports" : "cropfield.reports" });
      },
      onClose() {
        this.$router.push({ name: this.isOnMap ? "cropfield.map.reports" : "cropfield.reports" });
      },
    },
    components: { Treatment, TC_CropField, TC_Farm, TC_Campaign, TC_CheckBox },
    computed: {
      ...mapGetters({
        entriesChecked: "cropfield/entriesChecked",
        filterSelectionLabels: "cropfield/filterSelectionLabels"
      }),
      isOnMap() {
        return this.$route.name.includes("map");
      },
    },
  };
  </script>
