import AgroEdiApi from "@/api/agroedi.api";

export const RECEIVERS_LIST = "GET RECEIVERS LIST";
export const AGRO_EDI_LOADING = "LOAD AGRO EDI";

export default {
  state: {
    agroEdiReceivers: [],
    agroedi_loaded: false
  },
  getters: {
    agroEdiReceivers: state => state.agroEdiReceivers,
  },
  mutations: {
    [RECEIVERS_LIST]: (state, payload) => {
      state.agroEdiReceivers = payload.receivers;
    },

    [AGRO_EDI_LOADING]: (state, status) => {
      state.agroedi_loaded = !status;
    },
  },
  actions: {
    async getAgroEdiReceivers({ commit }) {
      commit(AGRO_EDI_LOADING, true);
      const response = await AgroEdiApi.getAgroEdiReceivers();
      commit(RECEIVERS_LIST, { receivers: response.data });
      commit(AGRO_EDI_LOADING, false);
      return response.data;
    },
  },
};
