import Gateway from "./base";

export default class inputInventoryApi {
  /**
   * Get All input inventories
   *
   */
  static getAllInputInventories() {
    return Gateway.get("/api/inputInventory/");
  }

  /**
   * Get one input inventory by id
   *
   * @param id
   */
  static getInputInventory(id) {
    return Gateway.get(`/api/inputInventory/${id}/`);
  }

  /**
   * Get input inventories by inputStock id
   *
   * @param param
   */
  static getInputInventoriesByInputStock(param) {
    return Gateway.get(`/api/inputInventory/`, param);
  }

  /**
   * Create input inventory
   *
   * @param data
   */
  static createInputInventory(data) {
    return Gateway.post(`/api/inputInventory/`, data);
  }

  /**
   * Update input inventory
   *
   * @param data
   * @param id

   */
  static updateInputInventory(data, id) {
    return Gateway.patch(`/api/inputInventory/${id}/`, data);
  }

  /**
   * delete input inventory
   *
   * @param id
   */
  static deleteInputInventory(id) {
    return Gateway.delete(`/api/inputInventory/${id}/`);
  }
}
