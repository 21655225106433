import MachineTypesApi from "@/api/machineTypes.api";
import { commonMutations } from "./commons.js";

export const ALL_MACHINETYPES = "GET ALL MACHINETYPES";
export const ONE_MACHINETYPE = "GET ONE MACHINETYPE";
export const MACHINETYPE_LOADING = "LOAD MACHINETYPE";
export const MACHINETYPES_LOADING = "LOAD MACHINETYPES";

export default {
  state: {
    machineTypes: [],
    currentMachineType: {},
    machineType_loaded: true,
    machineTypes_loaded: true,
  },
  getters: {
    currentMachineType: state => state.currentMachineType,
    machineTypes: state => state.machineTypes,
    machineTypeLoaded: state => state.machineType_loaded,
    machineTypesLoaded: state => state.machineTypes_loaded
  },
  mutations: {
    ...commonMutations,
    [ALL_MACHINETYPES]: (state, machineTypes) => {
      state.machineTypes = machineTypes;
    },

    [ONE_MACHINETYPE]: (state, machineType) => {
      state.currentMachineType = machineType;
    },

    [MACHINETYPE_LOADING]: (state, status) => {
      state.machineType_loaded = !status;
    },

    [MACHINETYPES_LOADING]: (state, status) => {
      state.machineTypes_loaded = !status;
    },
  },
  actions: {
    async getAllMachineTypes({ commit }) {
      commit(MACHINETYPES_LOADING, true);
      const response = await MachineTypesApi.getMachineTypes();
      commit(ALL_MACHINETYPES, response.data);
      commit(MACHINETYPES_LOADING, false);
      return response.data;
    },
    async getOneMachineType({ commit }, payload) {
      commit(MACHINETYPE_LOADING, true);
      const response = await MachineTypesApi.getOneMachineType(payload.id);
      commit(ONE_MACHINETYPE, response.data);
      commit(MACHINETYPE_LOADING, false);
      return response.data;
    },
    async checkIfFallow({ commit }, payload) {
      commit(MACHINETYPE_LOADING, true);
      const response = await MachineTypesApi.getOneMachineType(payload.id);
      commit(MACHINETYPE_LOADING, false);
      return response.data;
    }
  },
};
