import Gateway from "./base";

export default class VarietyApi {

  /**
   * Get varieties
   *
   * @param param
   */
  static getAllVarieties(param) {
    return Gateway.get(`/refapi/varieties/`, param);
  }
}
