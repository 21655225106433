<template>
  <div v-if="currentEntry.productFamily_code == 'phyto'">
    <b-row class="mb-2" v-if="publicId">
      <b-col cols="4">
        <h4 class="subtitle-form">{{ $t('inputStock.details.legal_id') }}</h4>
      </b-col>
      <b-col cols="8" class="d-flex align-items-start">
        <div class="bg-text-color text-infos config-density-semence">
          {{ publicId }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import RefProductMixin from "@/mixins/RefProductMixin";

import { mapGetters } from "vuex";

export default {
  mixins: [DetailUpdateMixin, RefProductMixin],

  computed: {
     ...mapGetters({
      inputsStock: "inputStock/entries",
      currentEntry: "inputStock/currentEntry",
      currentEditedEntry: "inputStock/editedEntry",
    }),
    publicId(){
      if(this.currentProduct != null)
        return this.currentProduct.publicId;
    }
  },
};
</script>

<style lang="scss" scoped></style>
