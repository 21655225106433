<template>
<!-- Ce composant affiche la culture précédente dans le panneau d'édition d'une parcelle -->
  <div>
    <div class="bg-white text-infos" v-if="currentEntry.previousCropField && currentEntry.previousCropField_crop_name">
      <div>
        {{ currentEntry.previousCropField_crop_name }}
      </div>
    </div>
    <div v-else class="bg-white text-infos">
      {{ $t("is_empty") }}
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      currentEntry: "cropfield/currentEntry",
    }),
  },
};
</script>

<style lang="scss"></style>
