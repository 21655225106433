import Gateway from "./base";

export default class ActivityFamiliesApi {
  /**
   * Get All Activity Families
   */
  static getActivityFamilies() {
    return Gateway.get("/refapi/activityFamilies/");
  }

  /**
   * Get one activity family by id
   * @param id
   */
  static getOneActivityFamily(id) {
    return Gateway.get(`/refapi/activityFamilies/${id}/`);
  }

  /**
   * Get All activityTypeGroups
   */
  static getActivityTypeGroups() {
    return Gateway.get("/refapi/activityTypeGroups/");
  }

  /**
   * Get activityTypeGroups by code
   * @param code
   */
  static getOneActivityTypeGroup(code) {
    return Gateway.get(`/refapi/activityTypeGroups/${code}/`);
  }

}
