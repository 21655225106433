<template>
  <b-modal ref="splashScreen" centered hide-footer hide-header :size="modalSize" class="custom-modal">
    <div class="modal-top">
      <div class="responsive-iframe-container">
        <template v-if="isImageOrGif">
          <img :src="currentNews.mediaFile" class="responsive-image" alt="News media">
        </template>
        <template v-else>
          <iframe :src="currentNews.mediaFile" width="100%" height="100%" style="position:absolute" frameborder="0" class="giphy-embed" allowfullscreen></iframe>
        </template>
      </div>
    </div>

    <div class="modal-bottom">
      <h3 class="modal-title">{{ currentNews.title }}</h3>
      <p class="modal-text"><span v-html="currentNews.body" style="font-size: 0.8em;"></span></p>
    </div>

    <div class="modal-footer" v-if="news.length > 1">
      <b-button variant="success" @click="previousPage" :style="{ visibility: currentPage != 0 ? 'visible' : 'hidden' }" class="nav-button">{{ $t('home.news.precedent') }}</b-button>
      <div class="pagination-dots">
        <span v-for="(item, index) in news" :key="index" :class="{'dot': true, 'active': index === currentPage}"></span>
      </div>
      <b-button variant="success" @click="nextPage" v-if="currentPage < news.length - 1" class="nav-button">{{ $t('home.news.next') }}</b-button>
    </div>

    <div class="modal-checkbox">
      <b-form-checkbox @change="markAllAsClosed">{{ $t('home.news.not_show_anymore') }}</b-form-checkbox>
    </div>
  </b-modal>
</template>

<script>
import { EventBus } from "@/eventBus.js";

export default {
  created() {
    EventBus.$on("showSplashScreen", this.showModal);
    EventBus.$on("hideSplashScreen", this.hideModal);
    this.updateModalSize();
    window.addEventListener('resize', this.updateModalSize);
  },
  beforeDestroy() {
    EventBus.$off("showSplashScreen", this.showModal);
    EventBus.$off("hideSplashScreen", this.hideModal);
    window.removeEventListener('resize', this.updateModalSize);
  },
  data() {
    return {
      news: [],
      currentPage: 0,
      modalSize: 'xl',
    };
  },
  computed: {
    currentNews() {
      const current = this.news[this.currentPage] || { title: '', body: '', mediaFile: '' };
      if (current.id) {
        this.markAsRead(current.id);
      }
      return current;
    },
    isImageOrGif() {
      // Vérifie si l'extension du fichier est .jpg, .jpeg, .png ou .gif (ignorer les paramètres après ?)
      return /\.(jpg|jpeg|png|gif)(?=\?|$)/i.test(this.currentNews.mediaFile);
    },
  },
  methods: {
    showModal({ news }) {
      let n = news.filter(n => n && n.closed == false)
      this.news = n.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      this.currentPage = 0;
      this.$refs.splashScreen.show();
    },
    updateModalSize() {
      // Changer la taille en fonction de la largeur de l'écran
      if (window.innerWidth < 1600) {  // Pour les mobiles
        this.modalSize = 'lg';
      } else {
        this.modalSize = 'xl';
      }
    },
    hideModal() {
      this.news = [];
      this.$refs.splashScreen.hide();
    },
    previousPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.news.length - 1) {
        this.currentPage++;
      }
    },
    async markAsRead(newsId) {
      const index = this.news.findIndex(n => n.id === newsId);
      if (index !== -1 && !this.news[index].readed) {
        await this.$store.dispatch("setVueNews", newsId)
      }
    },
    async markAllAsClosed() {
      for (const news of this.news) {
        if (!news.closed) {
          await this.$store.dispatch("setClosedNews", news.id)
        }
      }
      this.hideModal();
    },
  },
};
</script>

<style lang="scss" >
.custom-modal .modal-dialog {
  margin-top: 8px !important;
}

@media (max-width: 576px) {
  .custom-modal .modal-dialog {
    margin-top: 8px !important;
  }
}

.modal-body {
  padding: 0px !important;
}

.responsive-iframe-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* Ratio 16:9 pour les images */
  overflow: hidden;
}

.responsive-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain; /* Garantit que l'image s'adapte sans déformation */
}

.modal-top {
  width: 100%;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
}

.modal-bottom {
  height: auto;
  max-height: 60%;
  position: relative;
  border: none;
  padding: 0 12px !important;
  overflow-y: auto; /* Permet le défilement du contenu */
}

.modal-title {
  text-align: left;
  margin-bottom: 10px;
  font-size: 1.5em;
}

.modal-text {
  max-height: 140px;
  overflow-y: auto;
  font-size: 1em;

  @media (max-width: 1600px) {
    max-height: 120px;
  }
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  bottom: 5%;
  width: 100%;
  padding: 0 20px !important; /* Ajout d'un padding pour espacer légèrement les éléments des bords */
  border: none;
}

.pagination-dots {
  display: flex;
  justify-content: center; /* Centre les points de pagination */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  gap: 10px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ddd;
}

.dot.active {
  background-color: #333;
}

.nav-button {
  min-width: 80px; /* Donne une largeur minimum pour que les boutons occupent de l'espace même quand ils sont cachés */
}

.modal-checkbox {
  position: absolute;
  bottom: -25px;
  left: 0px;
  color: #fff;
}
</style>
