<template>
  <div>
    <b-row no-gutters class="mb-0">
      <b-col class="pr-3 col-7">
        <h4 class="subtitle-form mb-2">{{$t('cropfields.fertilization.intervention.interventionDate')}}</h4>
      </b-col>
      <b-col class="pr-0 col-5">
        <h4 class="subtitle-form mb-2">{{$t('cropfields.fertilization.intervention.input')}}</h4>
      </b-col>
    </b-row>

    <b-row no-gutters class="mb-0">
      <!-- Date de l'intervention -->
      <b-col class="pr-3 col-7">
        <p class="text-infos mb-2 bg-white">
            <span> {{ interventionDate }} </span>
        </p>
      </b-col>
      <!-- Apport de l'intervention -->
      <b-col class="pr-0 col-4 flex-container">
        <p class="text-infos mb-2 bg-white">
            <span> {{ interventionInput ? "cible : " + parseFloat(interventionInput).toFixed(2) : "cible : /" }} </span>
        </p>
      </b-col>
    </b-row>
    <b-row no-gutters class="mb-0">
      <!-- Produit utilisé -->
      <b-col class="pr-3 col-7">
        <p class="text-infos mb-0 bg-white">
            <span> {{ interventionInputsStock ? interventionInputsStock : "/" }} </span>
        </p>
      </b-col>
      <!-- La quantité  -->
      <b-col class="pr-0 col-5 flex-container">
        <p class="text-infos mb-0 bg-white">
            <span> {{ interventionDose ? "dose : " + parseFloat(interventionDose).toFixed(2) : "dose : /" }} </span>
        </p>
        <h4 class="text-form pl-1 mb-2">{{interventionDoseUnit ? interventionDoseUnit : ""}}</h4>
      </b-col>
    </b-row>
    <b-row no-gutters class="mb-3 mt-1">
      <!-- Azote brut -->
      <b-col class="pr-0 col-4">
        <div class="resize-padding-bottom">
          <h4 class="subtitle-form mb-2">
            {{$t('cropfields.fertilization.intervention.rawNitrogen')}}:
            <span class="text-form mb-2 resize-font">{{brutN ? brutN : 0}}{{unit_unit ? unit_unit : ""}}</span>
          </h4>
        </div>
      </b-col>
      <!-- Coefficient d'efficacité -->
      <b-col class="pr-0 col-4">
        <div class="resize-padding-bottom">
          <h4 class="subtitle-form mb-2">
            {{$t('cropfields.fertilization.intervention.efficiencyCoeff')}}:
            <span class="text-form mb-2 resize-font">
              {{percentMineralizableNitrogen ? percentMineralizableNitrogen : ""}}
              <span v-show="percentMineralizableNitrogen != '' ">%</span>
            </span>
          </h4>
        </div>
      </b-col>
      <!-- Azote efficace -->
      <b-col class="pr-0 col-4">
        <div class="resize-padding-bottom">
          <h4 class="subtitle-form mb-2">
            {{$t('cropfields.fertilization.intervention.effectiveN')}}:
            <span class="text-form mb-2 resize-font">
              {{effectiveNitrogen ? effectiveNitrogen : 0}}
              <span v-show="effectiveNitrogen != '' ">{{unit_unit ? unit_unit : ""}}</span>
            </span>
          </h4>
        </div>
      </b-col>
    </b-row>
    <hr />
  </div>
</template>

<script>
import { dateToSlashString } from "@/utils/commons";
import { mapGetters } from "vuex";

export default {
  name: "intervention",
  data() {
    return {
      intervention: null,
      interventionInput: null,
      interventionInputsStock: null,
      interventionDate: null,
      interventionDose: null,
      interventionDoseUnit: null,
      brutN: null,
      unit_unit : null,
      effectiveNitrogen: null,
      percentMineralizableNitrogen: null,
      interventionInputsStockId: null,
    };
  },
  components: {},
  created() {
    this.init();
  },
  methods: {
    init() {
      this.intervention = this.interventions[this.intervention_index];
      this.interventionDate = this.intervention.date ? dateToSlashString(new Date(this.intervention.date)) : "/";
      this.interventionInput = this.intervention.input;
      this.interventionInputsStock = this.intervention.inputsStock.name;
      this.interventionDose = this.intervention.dose;
      this.percentMineralizableNitrogen = this.intervention.inputsStock.percentMineralizableNitrogen;

      this.intervention.inputsStock.composition.map( element => {
        if(element.compound_symbol === 'N')
          {
            this.brutN = element.dose;
            this.unit_unit = element.unit_unit;
            this.effectiveNitrogen = this.brutN != "" && this.percentMineralizableNitrogen != "" ? this.brutN * (this.percentMineralizableNitrogen/100): '';
          }})
      this.interventionDoseUnit = (this.intervention.inputsStock.usages[0].doseUnit_unit);
    },
  },
  props: ['intervention_index'],
  computed: {
    ...mapGetters({
      interventions : "interventions",
     }),
  },
};
</script>

 <style lang="scss" scoped>
/**
 * text form.
 */
.text-form {
  font-size: 12px;
  font-weight: bold;
  margin: 5px 0 5px;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

.resize-padding-bottom {
  height: 30px;
}

.resize-input {
  padding-top: 0.7rem;
}

.resize-font {
  font-size: 11px;
  color: $primary-color;
}

button {
  font-size: 0.8em;
  font-weight: bold;

  img {
    width: 0.88em;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.flex-container {
  display: flex;
}

.resize-width {
  width:70%
}
</style>