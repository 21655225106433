  <template>
  <div>
      <button v-if="!loadPage" class="btn btn-primary" type="button" disabled>
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Chargement...
      </button>

    <b-container class="p-0" v-if="loadPage && !loadOption && !loadToolTip && (this.ferti.status == 0 || entriesCheckedTrue) && !this.ModelShow" >

      <div class="mb-3" v-if="!severalFertilizationPlan && this.checkAllStatus(0)">
        <button type="button" class="btn align-bottom button-begin-plan" @click="beginFertilizationPlan()">
          {{$t('cropfields.fertilization.planFromZero')}}
        </button>
      </div>
      <div class="mb-3" v-if="this.checkAllStatus(0)">
        <button type="button" class="btn align-bottom button-begin-plan" @click="initializeFromModel()">
           {{$t('cropfields.fertilization.planFromModele')}}
        </button>
      </div>
      <div class="mb-3" v-if="entriesCheckedTrue">
        <button type="button" class="btn align-bottom button-begin-plan" @click="generateReport()">
          {{ $t("cropfields.reports.fertilization_report") }}<br />
        </button>
      </div>
      <div class="mb-3" v-if="entriesCheckedTrue && this.checkAllStatus(3)">
        <button type="button" class="btn align-bottom button-begin-plan" @click="generateTours()">
           Créer des Tourneés
        </button>
      </div>

    </b-container>

    <div class="mb-3" v-if="cropfieldsModel.length > 0 && this.ModelShow">
      <h4 class="mb-3">{{ $t("fertilization_plan.edit.select_cropfield_model") }} </h4>
      <Dropdown
        ref="dropdown"
        class="shape-custom-select mb-2"
        label="name_soilType_previousCrop"
        :options="cropfieldsModel"
        :value="this.cropfieldModelSelected"
        @input="(selectedInput) => {this.cropfieldModelSelected = selectedInput}"
        required
      />
      <b-button
        class="button-default button-default-resize"
        type="button"
        @click="checkBeforeDuplicate()"
      >{{ $t("fertilization_plan.edit.confirm") }}</b-button>
    </div>

    <div class="mb-3 text-center" v-if="cropfieldsModel.length === 0 && this.modelError">
        {{ $t("cropfields.fertilization.modal.no_plan_initialized") }}
    </div>

    <div v-if="ferti">
      <button v-if="loadOption && loadToolTip && !loadPlan && !loadList" class="btn btn-primary" type="button" disabled>
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Chargement des données en cours...
      </button>


      <div v-if="loadPage && loadOption && loadToolTip && loadPlan && onlyOneFertilizationPlan">

        <!--Header-->
        <div>
          <b-container class="p-0">

            <b-row>
              <b-col>
                <h4 class="title-form">{{ this.ferti.crop_name }}</h4>
              </b-col>
            </b-row>

            <br />

            <div>
              <EditFertilizationInfos
              v-if="!this.fertiOption.ceilingDose_show"
              :editMode="this.editMode"
              :fertilisationNeedsCalculation = "this.ferti"
              :fertilisationNeedsCalculationForm ="this.fertiList"
              :fertilisationNeedsCalculationOption = "this.fertiOption"
              :fertiToolTips = "this.fertiTooltip"
              />
            </div>

            <hr v-if="!this.fertiOption.ceilingDose_show"/>

            <!-- 3 Case -->
          <div>
            <b-container class="p-0">

              <!--Calcul Besoin-->
              <b-card no-body class="mb-1 resize-card background-grey">
                <div>
                  <b-button block class="d-flex" @click="switchButton1">
                    <span class="mr-auto">Calculs des besoins</span>
                    <strong class="button-display-info">{{ this.ferti.Xauthorized  !== null ? this.ferti.Xauthorized  + " kgN/ha" : "Non calculé" }}</strong>
                    <strong v-if="isVisible1" aria-hidden="true" >-</strong>
                    <strong v-else aria-hidden="true" >+</strong>
                  </b-button>
                </div>
                <b-collapse id="accordion-1" v-model="isVisible1" accordion="my-accordion" role="tabpanel">
                  <b-card-body class="resize-card-body">
                    <b-card-text>
                      <!-- Le calcul des besoins -->
                      <EditNeedsCalculation
                      :ferti = "this.ferti"
                      :fertiList ="this.fertiList"
                      :fertiOption = "this.fertiOption"
                      :fertiToolTips = "this.fertiTooltip"
                      />
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <!--Prévision d'épandage-->
              <b-card no-body class="mb-1 resize-card background-grey">
                <div>
                  <b-button block class="d-flex" @click="switchButton2(false)">
                    <span>Prévision d'épandage</span>
                    <strong v-if="isVisible2" aria-hidden="true" class="ml-auto">-</strong>
                    <strong v-else aria-hidden="true" class="ml-auto">+</strong>
                  </b-button>
                </div>
                <b-collapse id="accordion-2" v-model="isVisible2" accordion="my-accordion" role="tabpanel">

                  <!--Body-->
                  <b-card-body
                    class="resize-card-body"
                    v-for="(intervention,index) in interventionsToPatch"
                    v-bind:key="index"
                  >
                    <b-card-text class="mt-0" v-if="editMode">
                      <b-form ref="intervention">
                        <!-- Les interventions -->
                        <EditIntervention
                          :intervention_index="index"
                          :entriesByFamilyCode="entriesByFamilyCode"
                          @removeClicked="onDelete"
                        />
                      </b-form>
                    </b-card-text>
                    <b-card-text class="mt-0" v-else>
                      <Intervention
                        :intervention_index="index"
                      />
                    </b-card-text>
                  </b-card-body>

                  <!--Add-->
                  <div align="center" class="mt-3 mb-3" v-if="editMode">
                    <b-button
                      class="button-default button-default-resize"
                      type="submit"
                      @click="onAdd"
                    >Ajouter une intervention</b-button>
                  </div>

                  <!--result-->
                  <div>
                    <div class="ml-5 mb-3 mt-3">
                      <h6>
                        Total azote apporté :
                        <strong>{{ totalNitrogen }} kg/ha</strong>
                      </h6>
                      <h6>
                        Reste d'azote à apporter :
                        <strong>{{ planNeedsToBeCovered }} kg/ha</strong>
                      </h6>
                    </div>
                  </div>

                  <!--Save-->
                  <div align="center" v-if="editMode">
                    <div class="mt-1 mb-2">
                      <b-button
                        class="button-default button-default-resize"
                        type="submit"
                        @click="onSaveIntervention()"
                      >Enregistrer</b-button>
                    </div>
                  </div>

                </b-collapse>
              </b-card>

              <!-- Apports -->
              <b-card no-body class="mb-1 resize-card background-grey">
                <div>
                  <b-button block class="d-flex" @click="switchButton3">
                    <span class="mr-auto">Suivi des apports</span>
                    <strong class="button-display-info">
                      {{ fertilizersSinceAssessmentOpeningDate.total_n_bilan?
                      fertilizersSinceAssessmentOpeningDate.total_n_bilan +  " kg/ha" : "Non calculé"}}</strong>
                    <strong v-if="isVisible3" aria-hidden="true" >-</strong>
                    <strong v-else aria-hidden="true">+</strong>
                  </b-button>
                </div>
                <b-collapse id="accordion-3" v-model="isVisible3" accordion="my-accordion" role="tabpanel">
                  <b-card-body
                    class="resize-card-body"
                    v-for="input in fertilizersSinceAssessmentOpeningDate.inputs"
                    v-bind:key="input.id"
                  >
                    <b-card-text class="mt-0">
                      <FertilizationExecution :input="input" />
                      <hr />
                    </b-card-text>
                  </b-card-body>

                  <div>
                    <div class="ml-5 mb-3 mt-3">
                      <h6>
                        {{ fertilizersSinceAssessmentOpeningDate.total_label }} :
                        <strong>{{ fertilizersSinceAssessmentOpeningDate.total_n_bilan? fertilizersSinceAssessmentOpeningDate.total_n_bilan : 0 }} kg/ha</strong>
                      </h6>
                      <h6>
                        Reste d'azote à apporter :
                        <strong>{{ realNeedsToBeCovered }} kg/ha</strong>
                      </h6>
                    </div>
                  </div>
                </b-collapse>
              </b-card>
            </b-container>
          </div>

          </b-container>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EditFertilizationInfos from "@/components/fertilization/edit/EditFertilizationInfos.vue";
import { FERTILIZATION_EDIT_MODE } from "@/store/modules/fertilization"
import EditNeedsCalculation from "@/components/fertilization/edit/EditNeedsCalculation.vue";
import NeedsCalculation from "@/components/cropfields/NeedsCalculation.vue";
import FertilizationExecution from "@/components/cropfields/edit/FertilizationExecution.vue";
import EditIntervention from "@/components/cropfields/edit/EditFertilizationIntervention.vue";
import Intervention from "@/components/cropfields/FertilizationIntervention.vue";
import { INIT_NEW_INTERVENTION } from "@/store/modules/fertilizationPlanInputs";
import { DELETE_ONE_INTERVENTION } from "@/store/modules/fertilizationPlanInputs";
import Dropdown from "@/components/harvestStorage/edit/Dropdown.vue";
import editButton from "@/components/commons/EditButton.vue";
import backButton from "@/components/commons/BackButton.vue";
import { EventBus } from "@/eventBus.js";
import { formatServerUrl } from "@/utils/commons.js";

export default {
  name: "fertilization-plan",
  data() {
    return {
      loadPage: false,
      loadOption: false,
      loadToolTip: false,
      loadList: false,
      loadPlan: false,
      isVisible1: false,
      isVisible2: false,
      isVisible3: false,
      deletedInterventions: [],
      cropfieldsModel: [],
      cropfieldModelSelected: {},
      ModelShow: false,
      modelError: false,
    };
  },
  props: {
    fertilization_id: {
      type: Number,
      default: null
    },
    fertilizationPlanChecked : {
      type: Array,
      default: null
    },
  },
  created: function() {
    this.init(this.fertilization_id);
  },
  mounted() {
    EventBus.$on("loadFertilizationData", this.loadData);
    EventBus.$on("InitFertilizationData", this.init);
    EventBus.$on("saveFertilizationIntervention", this.onSaveIntervention);
    EventBus.$on("switch2", this.switchButton2);
  },
  beforeDestroy(){
    EventBus.$off("loadFertilizationData", this.loadData);
    EventBus.$off("InitFertilizationData", this.init);
    EventBus.$off("saveFertilizationIntervention", this.onSaveIntervention);
    EventBus.$off("switch2", this.switchButton2);
  },
  methods: {
    async init(fertilization_id) {
      if(fertilization_id == null || fertilization_id != this.$route.params.fertilization_id){
        fertilization_id = this.$route.params.fertilization_id;
      }

      await this.$store.commit("fertilization/setCurrentId", fertilization_id);

      await this.$store.dispatch("getInterventionByCropfield",{
        fertilizationPlan : fertilization_id
      });

      await this.$store.dispatch("fertilization/getFertilizersSinceAssessmentOpeningDate", {id: this.fertilization_id});

      if(this.ferti.status != 0 && !this.entriesCheckedTrue){
        this.loadOptions()
        this.loadToolTips()
      }

      this.loadPlan = true
      this.loadPage = true
      this.isVisible1 = true
    },
    async loadData(){
      this.loadPage = false

      await this.$store.commit("fertilization/setCurrentId", this.fertilization_id);

      await this.$store.dispatch("fertilization/getfertilizationPlan", { id: this.fertilization_id });

      await this.$store.dispatch("getInterventionByCropfield",{
        fertilizationPlan : this.fertilization_id
      });

      await this.$store.dispatch("fertilization/getFertilizersSinceAssessmentOpeningDate", {id: this.fertilization_id});

      if(this.ferti.status != 0){
        this.loadOptions()
        this.loadToolTips()
      }

      this.loadPlan = true
      this.loadPage = true
      this.isVisible1 = true

    },
    async initializeFromModel() {
      if(!this.checkAllStatus(0)){
        EventBus.$emit("showAlertModal", {
        title: this.$t("information"),
        message: this.$t("cropfields.fertilization.modal.no_all_initialized"),
        okBtn: () => {},
        cancelBtn: () => {},
        okTitle: "ok",
        cancelTitle: "",
      });
      return
      }

      this.ModelShow = true
      // On vérifie d'abord que toute les parcelles sélectionnées ont la même culture
      // On passe la requête pour avoir la liste de parcelles modèles
        let cropfields = await this.$store.dispatch('fertilization/getDuplicableFertilizationPlan', {crop: this.ferti.crop});
        if(cropfields.length < 1) {
          this.modelError = true

          setTimeout(() => {
            this.$router.push({ name: "fertilization.list" });
            this.$store.commit("fertilization/resetEntriesChecked");
            this.$store.dispatch("fertilization/resetCurrentId");
          }, 5000);
        }
        let cropfields_added = [];
        let cropfield_update = {}
        console.log(cropfields)


        cropfields.map(obj => {
          if(!cropfields_added.includes(obj.id)) {
            cropfields_added.push(obj.id);
            cropfield_update = obj;
            cropfield_update['name_soilType_previousCrop'] = obj.name + " - " + obj.previousCrop_name + " - " + obj.soilType_designation;
            this.cropfieldsModel.push(cropfield_update);
          }
        });
        // Si la liste est vide, on en informe l'utilisateur
        if(this.cropfieldsModel.length == 0) {
          this.modelError = true;
          this.cropfieldModelSelected = {};
        }else{
          this.modelError = false;
          this.cropfieldModelSelected = this.cropfieldsModel[0];
        }
    },
    async checkBeforeDuplicate() {
        this.duplicateFertilizationPlan();
    },
    async loadOptions(){
      await this.$store.dispatch("fertilization/getfertilizationPlanOptions", { id: this.fertilization_id });
      this.loadOption = true
    },
    async loadToolTips(){
      await this.$store.dispatch("fertilization/getfertilizationPlanToolTip", { id: this.fertilization_id });
      this.loadToolTip = true
    },
    async loadLists(){
      if(this.editMode){
        await this.$store.dispatch("fertilization/getfertilizationPlanList", { id: this.fertilization_id });
        await this.$store.dispatch("inputStock/getInputStokByFamilyCode", {
          productFamily_code: "engsy,engor",
          no_page: true,
        });
        await this.$store.dispatch("getAllCropTypes");
      }
      this.loadList = true
    },
    async beginFertilizationPlan() {
      this.$store.commit("fertilization/" + FERTILIZATION_EDIT_MODE, true);
      this.isVisible1 = true;
      this.loadOptions()
      this.loadToolTips()
      this.loadPlan = true
    },
    async duplicateFertilizationPlan(){
      // combinant fertilization_id et entriesChecked
      let targetPlans = [this.fertilization_id, ...this.entriesChecked];

      // Filtrer les doublons
      let uniqueTargetPlans = Array.from(new Set(targetPlans));

      // Création de l'objet target avec la liste filtrée
      let target = {
        targetPlans: uniqueTargetPlans
      };

      await this.$store.dispatch("fertilization/duplicateFertilizationPlan", {
        id: this.cropfieldModelSelected.id,
        target: target
      });

      this.ModelShow = false

      if(this.entriesChecked.lenght > 1){
        this.$router.push({ name: "fertilization.list" });
        this.$store.commit("fertilization/resetEntriesChecked");
        this.$store.dispatch("fertilization/resetCurrentId");
      } else {
        this.$store.commit("fertilization/" + FERTILIZATION_EDIT_MODE, true);
        this.loadData()
      }
    },
    async onSaveIntervention() {
      // Check if there is interventions to delete
      if(this.deletedInterventions.length != 0)
      {
        var i;
        for(i=0; i<this.deletedInterventions.length; i++) {
          //Si null alors il s'agit d'une intervention qu'on a ajouté puis supprimé et qui ne contient donc pas d'id
          if(this.deletedInterventions[i] != null) {
            await this.$store.dispatch("deleteIntervention", {
              id: this.deletedInterventions[i],
            })
          }
        }
      }

      // Update interventions[] if there is changes
      for(i=0; i<this.interventions.length; i++) {
        if(this.interventions[i].id != null)
          await this.$store.dispatch("updateIntervention", {
              index: i,
            })
        else {
          // permet de mettre à jour l'id du champ cropfield ds le store pour l'intervention qu'on doit créer
          this.$store.commit("updateIntervention", { field: "fertilizationPlan", index:i,  intervention: this.fertilization_id });
          await this.$store.dispatch("createIntervention", {
              data: this.interventions[i]
          });
        }
      }
      //On recharge les intervention après la création
      await this.$store.dispatch("getInterventionByCropfield",{
        fertilizationPlan : this.fertilization_id
      });

      await this.$store.dispatch("fertilization/getfertilizationPlan", { id: this.fertilization_id });

      this.deletedInterventions = [];
      //permet de gérer le chargement et le changmeent de mode. On change cela lorsque les deux composants
      //pouvant être sauvegardé ont fini leur sauvegarde
      this.$store.commit("fertilization/" + FERTILIZATION_EDIT_MODE, false);
    },
    switchButton1() {
        this.isVisible1 = !this.isVisible1;
        this.isVisible2 = false;
        this.isVisible3 = false;
    },
    switchButton2(forceOpen = false) {
      this.isVisible2 = forceOpen ? forceOpen : !this.isVisible2;
      this.isVisible1 = false;
      this.isVisible3 = false;
    },
    switchButton3() {
      this.isVisible3 = !this.isVisible3;
      this.isVisible1 = false;
      this.isVisible2 = false;
    },
    prettyListToList(prettyList) {
      if (prettyList.length) {
        if (Object.keys(prettyList[0]).includes("name")) {
          return prettyList.map(e => {
            return {
              text: e.name,
              value: e.id,
            };
          });
        }
        return prettyList.map(e => {
          return {
            text: toString(e.id),
            value: e.id,
          };
        });
      }
    },
    async onAdd() {
        this.$store.commit(INIT_NEW_INTERVENTION);
    },
    onDelete(value) {
      let id = this.interventions[value].id;
      this.deletedInterventions.push(id);
      this.$store.commit(DELETE_ONE_INTERVENTION, { index: value });
    },
    generateReport() {
      let queryParams = {};
      if (this.severalFertilizationPlan) {
        queryParams["fertilizationPlan_id"] = this.selectedAsString;
      } else if (this.areEntriesFiltered) {
        queryParams["fertilizationPlan_id"] = this.fertilizationPlanIdAsString;
      } else {
        queryParams["campaign"] = this.currentCampaign.id;
      }
      queryParams["farm_id"] = (this.currentFarm && this.currentFarm.id) || null;
      queryParams = new URLSearchParams(queryParams).toString();
      window.open(formatServerUrl(`/reports/fertilizationplanpdf/`) + "?" + queryParams, "_blank");
    },
    checkAllStatus(status) {
      const selectedFertilization = this.fertilization.filter(fertilizationItem => this.entriesChecked.includes(fertilizationItem.id));
      const allHaveCorrectStatus = selectedFertilization.every(item => item.status === status);

      return allHaveCorrectStatus;
    },
    generateTours(){
      if(!this.checkAllStatus(3)){
        EventBus.$emit("showAlertModal", {
        title: this.$t("information"),
        message: this.$t("cropfields.fertilization.modal.all_initialized"),
        okBtn: () => {},
        cancelBtn: () => {},
        okTitle: "ok",
        cancelTitle: "",
      });
      return
      } else {
        EventBus.$emit("showAlertModal", {
        title: this.$t("information"),
        message: this.$t("cropfields.fertilization.modal.tour_not_ready"),
        okBtn: () => {},
        cancelBtn: () => {},
        okTitle: "ok",
        cancelTitle: "",
      });
      }
    },
  },
  computed: {
    ...mapGetters({
      entriesChecked: "fertilization/entriesChecked",
      fertilization: "fertilization/entries",
      currentEntry: 'fertilization/currentEntry',
      editMode: "fertilization/editMode",
      ferti: "fertilization/getFertilisationNeedsCalculation",
      fertiOption: "fertilization/getFertilisationNeedsCalculationSa",
      fertiList: "fertilization/getFertilisationNeedsCalculationForm",
      fertiTooltip: "fertilization/getFertilisationNeedsCalculationToolTip",
      interventions : "interventions",
      entriesByFamilyCodePrettyList : "inputStock/entriesByFamilyCode",
      fertilizersSinceAssessmentOpeningDate: "fertilization/fertilizersSinceAssessmentOpeningDate",
      fertilisationLoading: "fertilization/getfertilisationLoading",
      filterSelectionLabels: "fertilization/filterSelectionLabels",
      currentCampaign: "getCampaign",
      currentFarm: "farm/currentFarm",
    }),
    ...mapActions({
      updateEntry: "fertilization/updateEntry",
    }),
    entriesByFamilyCode: function() {
      return this.prettyListToList(this.entriesByFamilyCodePrettyList);
    },
    totalNitrogen: {
      get: function() {
        let totalInputs = this.interventions.filter(a => {
          return (a.input != null && a.input != "")
        })
        if(this.ferti.assessmentOpeningDate != "") {
          totalInputs = totalInputs.filter(e => {
            if(e.date >= this.ferti.assessmentOpeningDate) return e;
          });
        }
        return (totalInputs.map(b => b.input).reduce((a,b) => parseFloat(a) + parseFloat(b),0)).toFixed(1);
      },
      set: function() {},
    },
    planNeedsToBeCovered: {
      get: function() {
        if(this.ferti.Xauthorized  == null) {
          return
        }
        return (this.ferti.Xauthorized  - this.totalNitrogen).toFixed(1);
      },
      set: function() {},
    },
    realNeedsToBeCovered: {
      get: function(){
        if(this.ferti.Xauthorized  == null) {
          return
        }

        return (this.ferti.Xauthorized  - this.fertilizersSinceAssessmentOpeningDate.total_n_bilan).toFixed(1);
      },
      set: function(){}
    },
    interventionsToPatch() {
        return this.interventions;
    },
    onlyOneFertilizationPlan() {
      return this.entriesChecked.length <= 1;
    },
    severalFertilizationPlan() {
      return this.entriesChecked.length > 1;
    },
    entriesCheckedTrue() {
      return this.entriesChecked.length >= 1;
    },
    selectedAsString() {
      return this.entriesChecked.join(",");
    },
    areEntriesFiltered() {
      return this.filterSelectionLabels.length > 0;
    },
    fertilizationPlanIdAsString() {
      return this.fertilization.map(cf => cf.id).join(",");
    },
  },
  watch: {
    editMode: {
      handler: 'loadLists',
      immediate: false
    },
    interventionsToPatch: {
        handler() {
          if(this.interventions.length > 0 && this.interventions[this.interventions.length - 1].input == null && this.planNeedsToBeCovered > 0 && this.planNeedsToBeCovered < this.interventions[0].input * 1.5) {
            this.$store.commit("updateIntervention", { field: "input", index:this.interventions.length - 1,  intervention: this.planNeedsToBeCovered});
          }
        }
      },
    entriesChecked: {
      immediate: false,
      handler: 'checkedTest'
    }
  },
  components: {
    editButton,
    backButton,
    EditFertilizationInfos,
    EditNeedsCalculation,
    NeedsCalculation,
    FertilizationExecution,
    EditIntervention,
    Intervention,
    Dropdown,
  },
};
</script>

<style lang="scss" scoped>
/**
  * resize the body card.
  */
.resize-card-body {
  padding-top: 0.5rem;
  padding-right: 1rem;
  padding-bottom: 0rem;
  padding-left: 1rem;
}

/**
  * resize the card.
  */
.button-begin-plan {
  background-color: $background-text-color;
  width: 100%;
}

.button-begin-plan:hover {
  background: $secondary-light-color;
}
.resize-card {
  position: relative;
  left: -12px;
  width: 107%;
}

.button-default-resize {
  max-width: 200px;
}
.background-grey{
  background-color: #f7f7f7;
}
.error-message {
  color: $danger-color;
  text-align: center;
}
.warning {
  color: $danger-color;
  font-family: $secondary-font;
  font-size: 0.9rem;
  margin-bottom: 5px;
  font-weight: bold;
  text-align: center;
}
.button-display-info{
  margin-right: 10%;
}
</style>