<template>
  <div>
    <Treatment
      :endpoint="MachineApi.reportMachinesUsages"
      :async="false"
      :title="$t('machines.reports.machines_usage')"
      :startButton="$t('commons.exports_reports.export_button')"
      :finishButton="$t('commons.exports_reports.finish')"
      :exportAllowedFormats="['xlsx']"
      :exportAllowedOptions="['machine', 'exploitation']"
      :isDirectDownload="true"
      :onBack="onBack"
      :onClose="onClose"
    >
      <TC_Campaign />
      <TC_Farm />
      <!--
        for component TC_ActivityChoice; List of options to display.
        - 'newOnly' means "only the new activities". It will send 'newOnly = true' to the API
        - 'onPeriod' means "all activities on given period". In that case it will send a startDate and endDate to the API
        - 'all' means "all activities". It will send 'all = true' to the API
      -->
      <TC_ActivityChoice :activityOptions="['all', 'onPeriod']" />
      <TC_Option :groupOptions="this.groupOptions" :returnedOption="this.returnedOption" :titleOption="this.titleOption" />
      <TC_FileFormat />
    </Treatment>
  </div>
</template>

<script>
import Treatment from "@/components/commons/treatments//TreatmentExecution";
import TC_Campaign from "@/components/commons/treatments/TC_Campaign";
import TC_Farm from "@/components/commons/treatments/TC_Farm";
import TC_ActivityChoice from "@/components/commons/treatments/TC_ActivityChoice";
import TC_Option from "@/components/commons/treatments/TC_Option";
import TC_FileFormat from "@/components/commons/treatments/TC_FileFormat";

import MachineApi from "@/api/machine.api";

export default {
  data() {
    return {
      MachineApi: MachineApi,
      groupOptions:[
        { value: "machine", text: "Machine" },
        { value: "exploitation", text: "Exploitation" },
      ],
      returnedOption: 'option',
      titleOption: "commons.exports_reports.which_grouping"
    };
  },
  methods: {
    onBack() {
      this.$router.push({ name: "machines.reports" });
    },
    onClose() {
      this.$router.push({ name: "machines.reports" });
    },
  },
  components: { Treatment, TC_Campaign, TC_Farm, TC_ActivityChoice, TC_Option, TC_FileFormat },
};
</script>
