import { EventBus } from "@/eventBus.js";

import { mapActions, mapGetters } from "vuex";

export default {
  methods: {
    // trigger modify geometry state in map
    async modifyGeometry() {
      EventBus.$emit("enableModifyGeometry");

      this.$emit("update:modifyMode", true);
    },
  },
};
