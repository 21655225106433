import Vue from "vue";
import { DateTime } from "luxon";

Vue.filter("datetime", (value, format, locale, timezone) => {
  if (!value) {
    return "";
  }

  return DateTime.fromISO(value)
    .setZone(timezone ? timezone : "Europe/Paris")
    .setLocale(locale)
    .toFormat(format);
});
