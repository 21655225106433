import DoseUnitsApi from "@/api/doseunits.api";

export const ALL_DOSE_UNITS = "GET ALL DOSE UNITS";
export const DOSE_UNITS_FILTERED = "GET DOSE UNITS FILTERED";
export const DOSE_UNITS_LOADING = "LOAD DOSE UNITS";

export default {
  state: {
    doseUnits: [],
    doseUnitsFiltered: [],
    doseUnits_loaded: true,
  },
  getters: {
    doseUnits: state => state.doseUnits,
    doseUnitsLoaded: state => state.doseUnits_loaded,
    doseUnitsFiltered: state => state.doseUnitsFiltered
  },
  mutations: {
    [ALL_DOSE_UNITS]: (state, doseUnits) => {
      state.doseUnits = doseUnits;
    },

    [DOSE_UNITS_LOADING]: (state, status) => {
      state.doseUnits_loaded = !status;
    },

    [DOSE_UNITS_FILTERED]: (state, doseUnits) => {
      state.doseUnitsFiltered = doseUnits;
    },
  },
  actions: {
    async getDoseUnits({ commit }) {
      commit(DOSE_UNITS_LOADING, true);
      const response = await DoseUnitsApi.getDoseUnits();
      commit(ALL_DOSE_UNITS, response.data);
      commit(DOSE_UNITS_LOADING, false);
      return response.data;
    },
    async getNitrogenDoseUnits({ commit }, payload) {
      commit(DOSE_UNITS_LOADING, true);
      const response = await DoseUnitsApi.getNitrogenDoseUnits(payload);
      commit(DOSE_UNITS_FILTERED, response.data);
      commit(DOSE_UNITS_LOADING, false);
      return response.data;
    },
  },
};
