<template>
  <div>
    <!-- Variété -->
		<!-- On affiche le sélecteur uniquement s'il y a au plus 1 variété définie sur la parcelle -->
		<!-- Le sélecteur est desactivé s'il s'agit d'une variété avec date de semis  -->
		<div v-if="!disableDropDown">
			<v-select
				@input="$emit('updateRow', rowId, payload)"
				:options="products"
				label="name"
				v-model="productSelected"
				:appendToBody="true"
				:clearable="true"
				:disabled="disableDropDown"
				v-b-tooltip.hover
				:title="disableDropDown ? $t('cropfields.details.cantChangeVariety') : ''"
			>
				<template #no-options>
					{{ $t("dropdown.no_option") }}
				</template>
			</v-select>
		</div>
		<!-- S'il y'a plus d'une variété, on affiche une liste -->
		<div v-else
			class="bg-white text-infos"
			v-b-tooltip.hover
			:title="$t('cropfields.details.cantChangeVariety')"
		>
			<div v-for="cv in this.allVarieties" :key="cv.id">
				{{ cv.variety_name }}
			</div>
		</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProductsModule from "@/store/modules/products";

export default {
  data() {
    return {
      productSelected: {},
      products: [],
    };
  },
  props: ["rowId", "field", "cellData", "disabled"],
  async beforeCreated() {
    const store = this.$store;
    if (store && store.state) {
      if (!store.state["products"]) {
        store.registerModule("products", ProductsModule);
      }
    }
  },
  async mounted() {
	// Récupérer toutes les variétés (via allVarieties)
	const varieties = this.allVarieties;
	const hasEmptyVarieties = this.checkedEntries.some(entry => !entry.varieties || entry.varieties.length === 0);
	// Si on a une seule variété, on la sélectionne
	if (varieties.length === 1 && !hasEmptyVarieties) {
		this.productSelected = { ...varieties[0], name: varieties[0].variety_name };
	} else {
		// Si plusieurs variétés ou aucune, on vide la sélection
		this.productSelected = {};
	}

	// Charger la liste des variétés disponibles à partir de l'API selon l'espèce
	this.products = Object.keys(this.currentCropType).length > 0
		? JSON.parse(
			JSON.stringify(
			await this.$store.dispatch("getAllVarieties", {
				species__code: this.currentCropType.species,
			})
			)
		)
		: [];

	// Si le type de culture n'est pas défini ou a changé, on récupère le type de culture actuel
	if (Object.keys(this.currentCropType).length === 0 || (this.currentEntry?.crop && this.currentCropType.id !== this.currentEntry.crop)) {
		await this.$store.dispatch("getOneCropType", { id: this.currentEntry.crop });
	}
  },
  computed: {
    ...mapGetters({
		currentCropType: "currentCropType",
		currentEntry: "cropfield/currentEntry",
		checkedEntries: "cropfield/checkedEntries",
    }),
	payload() {
      let payload = {};
      if (this.productSelected){
			let variety = {variety: this.productSelected.id, variety_name: this.productSelected.name}
        payload["varieties"] = [variety];
      } else {
			payload["varieties"] = [];
		}

      return payload;
    },
	disableDropDown() {
		const varieties = this.allVarieties;

		return varieties.some(variety => variety.sowingDate !== null);
	},
	allVarieties() {
	let varieties = [];

	// Si plusieurs entries sont cochées
	if (this.checkedEntries.length > 1) {
		this.checkedEntries.forEach(entry => {
			if (entry.varieties && entry.varieties.length > 0) {
				varieties = varieties.concat(entry.varieties);
			}
		});

		const uniqueVarieties = varieties.reduce((acc, currentVariety) => {
		const existingVariety = acc.find(v => v.variety_name === currentVariety.variety_name);

		if (!existingVariety) {
			acc.push(currentVariety);
		} else if (!existingVariety.sowingDate && currentVariety.sowingDate) {
			const index = acc.findIndex(v => v.variety_name === currentVariety.variety_name);
			acc[index] = currentVariety;
		}
		return acc;
		}, []);

		return uniqueVarieties;
	}

		// S'il n'y a qu'une seule entrée
		if (this.currentEntry && this.currentEntry.varieties.length != 0) {
			return this.currentEntry.varieties;
		}

		return [];
	}
  },
  watch: {
 	currentCropType: {
	  handler: async function() {
		this.products = Object.keys(this.currentCropType).length > 0
			? JSON.parse(
					JSON.stringify(
						await this.$store.dispatch("getAllVarieties", {
							species__code: this.currentCropType.species,
						}),
					),
				)
			: [];
		}
	}
}
  
};
</script>

<style></style>
