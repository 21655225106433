<template>
  <div class="d-flex flex-column justify-content-center">
    <div class="d-block font-12" style="font-weight: normal">
      {{ firstInputs(3) }}
    </div>
    <span class="d-inline-block font-12" v-if="cropfields && cropfields.length > 3">
      <b-button :id="'popover-cropfields-' + rowId" type="button" class="badge badge-pill badge-secondary">
        <span>+{{ cropfields.length - 2 }}</span>
      </b-button>
    </span>

    <b-popover :target="'popover-cropfields-' + rowId" triggers="hover" placement="auto" boundary="window">
      <template #title>{{ cropfields.length }} {{ $t("cropfields.list.countingElements") }} ({{ totalSurface }} ha)</template>
      <div v-for="cropfield in cropfields" :key="cropfield.id">
        {{ cropfield.full_name }}
      </div>
    </b-popover>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: ["rowId", "rowData"],
  computed: {
    cropfields() {
      return this.rowData.cropFields;
    },
    totalSurface() {
      return _.round(this.cropfields.map((c) => c.cropField_surface).reduce((a, b) => a + b, 0), 2);
    }
  },
  methods: {
    firstInputs(n) {
      if (!this.cropfields) return "";
      return this.cropfields
        .slice(0, n)
        .map((i) => i.cropField_name)
        .join(", ");
    },
  },
};
</script>

<style lang="scss" scoped></style>
