<template>
  <b-container :class="['px-0', 'mh-100', 'pt-1', 'h-100', 'd-flex', 'flex-column']" fluid>
    <b-row ref="mapTools" class="h-auto px-4 py-2 justify-content-between" align-v="end" no-gutters>
      <b-col cols="4">
        <FilterTopBar module="location" />
      </b-col>
      <b-col cols="6" class="d-flex align-items-end justify-content-end">
        <div class="mr-4 green-variant">
          <button
            v-if="oneSelectedLocation"
            class="btn btn-sm btn-secondary"
            @click="editGeometry"
          >
            <span v-if="currentLocationHasGeometry">
              {{ $t("cropfields.details.edit_geometry") }}
            </span>
            <span v-else>{{ $t("cropfields.details.define_geometry") }}</span>
          </button>

        </div>
        <b-dropdown
          id="dropdown-dropup"
          size="sm"
          dropup
          :text="$t('cropfields.map.layers.title')"
          class="mr-2 green-variant"
        >
          <b-dropdown-form v-for="filter in layerFilters" :key="filter.name">
            <b-form-checkbox v-model="filter.value">{{ filter.name }}</b-form-checkbox>
          </b-dropdown-form>
        </b-dropdown>
        <LocationsTabs v-if="module == 'location'" />
      </b-col>
    </b-row>
   <b-row
      :class="['mh-100', 'overflow-hidden', 'p-4', 'flex-grow-1']"
      no-gutters
    >
      <b-col cols="12" class="mh-100">
        <div id="map" class="w-100 h-100 mh-100"></div>
        <div class="lengthElement d-none" ref="lengthElement"></div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import FilterTopBar from "@/components/FilterTopBar.vue";

import LocationsTabs from "@/components/locations/LocationsTabs.vue";

import LocationModule from "@/store/modules/location";

import CropfieldMapStyleMixin from "@/views/cropfields/map/CropfieldMapStyleMixin";
import LocationMapHoleModeMixin from "@/views/locations/map/LocationMapHoleModeMixin";

import LocationMapBaseMixin from "@/views/locations/map/LocationMapBaseMixin";
import LocationMapStyleMixin from "@/views/locations/map/LocationMapStyleMixin";
import LocationMapModifyGeometryMixin from "@/views/locations/map/LocationMapModifyGeometryMixin";

import { mapGetters, mapMutations, mapActions } from "vuex";

import { EventBus } from "@/eventBus.js";

export default {
  mixins: [
    LocationMapBaseMixin,
    LocationMapStyleMixin,
    LocationMapModifyGeometryMixin,
    CropfieldMapStyleMixin,
    LocationMapHoleModeMixin,
  ],
  data() {
    return {
      module: "location",
      publicPath: process.env.BASE_URL,
      disableInteraction: false,
    };
  },
  props: {
    routes: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    LocationsTabs,
    FilterTopBar,
  },
  async mounted() {
    // charge les données des parcelles et des lieux
    if (!this.$store.state["location"]) {
      this.$store.registerModule("location", LocationModule);
    }
    if (this.globalFiltersLoaded) this.fetchAndInit();
    
  },
  methods: {
    ...mapMutations({
      locSelectHole: "location/selectHole",
      locResetSelectedHole: "location/resetSelectedHole",
      resetCurrentId: "location/resetCurrentId",
    }),
    ...mapActions({
      fetchEntries: "cropfield/fetchEntries",
      updateGeometry: "cropfield/updateGeometry",
      updateEntry: "cropfield/updateEntry",
      fetchIslandGeometries: "cropfield/fetchIslandGeometries",
      setEditedEntryValue: "cropfield/setEditedEntryValue",

      fetchLocEntries: "location/fetchEntries",
      updateLocEntry: "location/updateEntry",
      updateLocGeometry: "location/updateGeometry",
    }),
  },
  computed: {
    ...mapGetters({
      currentFarm: "farm/currentFarm",
      currentCampaign: "getCampaign",
      hasCurrentCampaign: "hasCurrentCampaign",
      globalFiltersLoaded: "user/globalFiltersLoaded",
      _entries: "cropfield/_entries",
      geometries: "cropfield/geometries",
      geometries3857: "cropfield/geometries3857",
      checkedGeometries3857: "cropfield/checkedGeometries3857",
      checkedGeometries: "cropfield/checkedGeometries",
      checkedEntries: "cropfield/checkedEntries",
      islandGeometries3857: "cropfield/islandGeometries3857",
      currentCropfieldHasGeometry: "cropfield/currentCropfieldHasGeometry",
      entries: "cropfield/entries",
      entriesChecked: "cropfield/entriesChecked",
      mapCutMode: "cropfield/mapCutMode",
      currentEntry: "cropfield/currentEntry",
      editedEntry: "cropfield/editedEntry",
      mergeAllowed: "cropfield/mergeAllowed",
      selectedHole: "cropfield/selectedHole",

      locGeometries: "location/geometries",
      locGeometries3857: "location/geometries3857",
      checkedLocGeometries3857: "location/checkedGeometries3857",
      locCheckedGeometries: "location/checkedGeometries",
      _locEntries: "location/_entries",
      locCurrentEntry: "location/currentEntry",
      currentLocationHasGeometry: "location/currentLocationHasGeometry",
      locEntries: "location/entries",
      locCheckedEntries: "location/checkedEntries",
      currentEditedEntry: "location/editedEntry",
      locEntriesChecked: "location/entriesChecked",
      locSelectedHole: "location/selectedHole",
      customer: "user/customer",
    }),

    currentLocation() {
      return this.locCurrentEntry;
    },
    oneSelectedLocation() {
      return this.locCurrentEntry && this.locCurrentEntry.id;
    },
    rowsChecked: {
      get() {
        return this.$store.getters[this.module + "/entriesChecked"] || [];
      },
      set(entries) {
        this.$store.dispatch(this.module + "/setEntriesChecked", { entries });
      },
    },
  },
  watch: {
    layerFilters: {
      handler() {
        this.refreshLayers();
      },
      deep: true,
    },
    globalFiltersLoaded(newValue) {
      if (newValue) this.fetchAndInit();
    },
    currentFarm() {
      if (!this.hasCurrentCampaign) return;
      this.fetchAndResetMap();
    },
    currentCampaign() {
      this.fetchAndResetMap();
    },
    $route: {
      deep: true,
      handler() {
        let t = this;
        setTimeout(function () {
          if (!t.map) return;
          t.map.updateSize();
        }, 200);
        
        if (this.$route.params.mode == "editGeometry") this.plotFeatureHoles();
        else this.resetFeatureHoles();
      }
    },

  },
};
</script>

<style lang="scss" scoped>
</style>

<style>
.GPwidget label {
  margin-bottom: 0 !important;
}
</style>
