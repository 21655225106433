export const TILE_UPDATE = "UPDATE TILE PAGE";

export default {
  state: {
    headerTitle: "",
    // list of buttons
    // a button is an object with :
    // - a 'text' attribute, for the button text display
    // - a 'isMinor' to say if is minor (small) or big button
    // - a 'action' function, which is ran when the button is clicked
    headerButtons: [],
  },
  getters: {
    headerTitle: state => state.headerTitle,
    headerButtons: state => state.headerButtons,
  },
  mutations: {
    [TILE_UPDATE]: (state, { title, buttons }) => {
      state.headerTitle = title;
      state.headerButtons = buttons;
    },
  },
};
