<template>
  <div class="d-flex">
    <div class="d-flex flex-column justify-content-center">
      <div class="font-14 position-relative">
        {{ cropfieldName }}
        <span class="tracker-icon">
          <img
            v-if="showSecondCropIcon"
            v-b-tooltip.hover
            :title="$t('cropfields.list.second_crop')"
            src="@/assets/images/number_two.svg"
					/>
        </span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ["rowId", "rowData"],
  computed: {
		cropfieldName() {
      return this.rowData.name;
    },
    showSecondCropIcon() {
      return this.rowData.cropOrder && this.rowData.cropOrder > 1;
    }
  },
};
</script>

<style lang="scss" scoped>
.tracker-icon img {
  width: 18px;
  margin-right: 3px;
  margin-top: -20px
}
</style>
