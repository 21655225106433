<template>
  <div class="app-page-title">
    <div class="d-flex align-items-center">
      <h1 class="p-1 mr-5">{{ headerTitle }}</h1>
      <TourSwitchButton v-if="$route.path.includes('activity')" />
      <YieldSwitchButton v-if="$route.path.includes('yield')" />
      <FertilizationSwitchButton v-if="$userCan('cropfields.fertilization') && ($route.path.includes('cropfields') || $route.path.includes('fertilization'))"/>
    </div>

    <div class="d-flex justify-content-end align-items-center" v-if="!$route.path.includes('fertilization')">
      <div v-for="(button, index) in headerButtons" :key="index" class="mr-2">
        <a v-if="button.isSmall" href="javascript:void(0)" @click="button.action" class="mr-4">
          {{ button.text }}
        </a>
        <Button v-else :text="button.text" :icon="button.icon" :on-click="button.action"></Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button from "@/components/commons/HeaderButton.vue";
import TourSwitchButton from "@/components/activity/TourSwitchButton.vue";
import YieldSwitchButton from "@/components/harvestStorage/YieldSwitchButton.vue";
import FertilizationSwitchButton from "@/components/cropfields/FertilizationSwitchButton.vue";

export default {
  components: {
    Button,
    TourSwitchButton,
    YieldSwitchButton,
    FertilizationSwitchButton,
  },
  computed: {
    ...mapGetters(["headerTitle", "headerButtons"]),
  },
};
</script>
<style lang="scss" scoped>
.app-page-title {
  height: $titleHeight;
  flex-direction: row;
  box-shadow: 3px 2px 3px 1px #00000029;
  color: $reverse-color;
  background-color: $secondary-color;
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding: 0 1rem;
  position: relative;
  // useful to display it above some modals
  z-index: 100;

  h1 {
    font-size: 1.3em;
  }

  a {
    color: $color-light;
  }
}
</style>
