import Gateway from "./base";

export default class YieldApi {
  /**
   * Get All yields
   */
  static getYields({campaign, farmId, cropField, variety, yieldId, activityId}) {
    return Gateway.get("/api/yields/", {
      campaign: campaign,
      ...{
        ...(farmId && {farm_id: farmId}),
        ...(cropField && {cropField: cropField }),
        ...(variety && {variety: variety}),
        ...(yieldId && {id: yieldId }),
        ...(activityId && {activity: activityId}),
      },
    });
  }

  /**
   * Get one yield by id
   *
   * @param id
   */
   static getYield(id) {
    return Gateway.get(`/api/yields/${id}/`);
  }

  /**
   * Create yield
   *
   * @param data
   */
   static createYield(data) {
    return Gateway.post(`/api/yields/`, data);
  }

  /**
   * Update yield
   *
   * @param data
   * @param id
   */
  static updateYield(data, id) {
    return Gateway.patch(`/api/yields/${id}/`, data);
  }

  /**
   * delete yield
   *
   * @param id
   */
  static deleteYield(id) {
    return Gateway.delete(`/api/yields/${id}/`);
  }

  /**
   * Get All filters yields
   *
   */
   static getFiltersYields(query, campaign, farmId, params) {
    return Gateway.get("/api/yields/filtre/", { attrib: query, campaign: campaign, farm_id: farmId, ...params});
  }

  /**
   * Get yield form
   *
   * @param activityId
   */
   static getYieldForm(activityId ) {
    return Gateway.get(`/api/activities/${activityId}/yieldForm/`);
  }
}
